import React, {useEffect, useState, useRef} from 'react';
import {
  Button,
  Image,
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  TextInput,
  KeyboardAvoidingView,
  Platform,
  Linking,
} from 'react-native';
import {ScrollView} from 'react-native-gesture-handler';
import {SafeAreaView} from 'react-native-safe-area-context';
import SelectDropdown from 'react-native-select-dropdown';
import styles from '../common/styles';
import api from '../common/api';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {padStart} from 'lodash';
import Colors from '../common/colors';
import {title} from '../common/util';

const SignUpView = ({navigation, route}) => {
  const {user} = route.params;

  const processing = useRef();

  const [emailWarning, setEmailWarning] = useState(false);
  const [passwordWarning, setPasswordWarning] = useState(false);
  const [passwordConfirmWarning, setPasswordConfirmWarning] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [passwordConfirmVisible, setPasswordConfirmVisible] = useState(false);
  const [passwordChecked, setPasswordChecked] = useState(false);
  const [passwordConfirmChecked, setPasswordConfirmChecked] = useState(false);

  const [inputs, setInputs] = useState({
    name: user?.name,
    email: user?.email,
    password: user?.password,
    passwordConfirm: user?.passwordConfirm,
    birthYear: user?.birthYear,
    birthMonth: user?.birthMonth,
    birthDay: user?.birthDay,
    gender: user?.gender,
    school: user?.school,
    pitchingAndHitting: user?.pitchingAndHitting,
    formerAthlete: user?.formerAthlete,
    position: user?.position,
    cellphone: user?.cellphone,
    code: '',
    authType: user?.authType,
    thirdPartyUserId: user?.thirdPartyUserId,
    certification: user?.certification,
  });

  const auth = body => {
    api
      .post('/users/authentications', body)
      .then(function (response) {
        console.log(response.data, response.headers);
        try {
          AsyncStorage.setItem(
            '@accessToken',
            response.headers['access-token'],
          );
          AsyncStorage.setItem(
            '@userInfo',
            JSON.stringify(response.data),
            () => {
              navigation.reset({
                index: 0,
                routes: [{name: 'Main'}],
              });
            },
          );
        } catch (e) {
          // saving error
        }
      })
      .catch(function (error) {
        console.log(error.response);
        if (error.response.status === 401) {
          alert('이메일 비밀번호가 일치하지 않습니다.');
        } else {
          alert('요청에 실패했습니다.');
        }
      });
  };

  useEffect(() => {
    console.log('params', route.params);
    console.log('cert key', inputs?.certification?.unique_key);
    if (route.params?.user?.certification) {
      api
        .post('/users/exists/check', {
          certificationId: inputs.certification.unique_key,
        })
        .then(function (response) {
          if (response.data) {
            alert(
              '동일 정보로 인증받은 이력이 있습니다. 기존 인증 받은 계정으로 로그인 합니다.',
            );
            const uid = response.data;
            api
              .post('/users/auth', {
                email: user?.email,
                thirdPartyUserId: user?.thirdPartyUserId,
                authType: user?.authType,
                password: user?.password,
                uid: uid,
              })
              .then(function (authResponse) {
                console.log('user', user);
                if (user?.thirdPartyUserId) {
                  auth({
                    thirdPartyUserId: user?.thirdPartyUserId,
                    authType: user?.authType,
                    email: user?.email,
                  });
                } else {
                  auth({
                    email: user?.email,
                    password: user?.password,
                    authType: '이메일',
                  });
                }
              })
              .catch(function (error) {
                console.log(error.response);
              });
          }
        })
        .catch(function (error) {
          console.log(error.response);
        });
    }
  }, []);
  const validateEmail = e => {
    const filter =
      /^\s*[\w\-\+_]+(\.[\w\-\+_]+)*\@[\w\-\+_]+\.[\w\-\+_]+(\.[\w\-\+_]+)*\s*$/;
    return String(e).search(filter) != -1;
  };

  const validatePassword = v => {
    const regex =
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
    return v.match(regex);
  };

  const onChangeEmail = value => {
    setEmailWarning(!validateEmail(value));
    setInputs({
      ...inputs,
      ['email']: value,
    });
  };

  const onPasswordChange = value => {
    setPasswordWarning(!validatePassword(value));
    setPasswordChecked(validatePassword(value));

    setInputs({
      ...inputs,
      ['password']: value,
    });
  };

  const onPasswordConfirmChange = value => {
    setPasswordConfirmWarning(!validatePassword(value));
    setPasswordConfirmChecked(validatePassword(value));

    setInputs({
      ...inputs,
      ['passwordConfirm']: value,
    });
  };

  const validateInputs = () => {
    if (
      user.authType === '이메일' &&
      (!inputs.password || !inputs.passwordConfirm)
    ) {
      alert('필수 값을 입력해 주세요.');
      return false;
    }
    if (
      !inputs.email ||
      !inputs.birthYear ||
      !inputs.birthMonth ||
      !inputs.birthDay ||
      !inputs.gender ||
      !inputs.pitchingAndHitting ||
      !inputs.formerAthlete ||
      !inputs.position ||
      !inputs.cellphone
    ) {
      alert('필수 값을 입력해 주세요.');
      return false;
    }

    if (inputs.password !== inputs.passwordConfirm) {
      alert('비밀번호가 일치하지 않습니다.');
      return false;
    }

    if (inputs.birthYear.length < 4) {
      alert('올바른 생년월일을 입력해 주세요.');
      return false;
    }

    if (!inputs.certification) {
      alert('본인인증을 완료해 주세요.');
      return false;
    }

    if (!agree) {
      alert('약관에 동의해 주세요.');
      return;
    }

    if (!agree2) {
      alert('개인정보처리방침에 동의해 주세요.');
      return;
    }

    return true;
  };

  function certifyCallback(response) {
    const {success, merchant_uid, error_msg} = response;

    if (success) {
      alert('본인인증 성공');
    } else {
      alert(`본인인증 실패: ${error_msg}`);
    }
  }

  const requestCertify = () => {
    /* 2. 본인인증 데이터 정의하기 */
    if (user.authType === '이메일') {
      if (!inputs.email || !inputs.password || !inputs.passwordConfirm) {
        alert('본인인증 진행 전 이메일과 비밀번호를 설정해 주세요.');
        return false;
      }
      if (inputs.password !== inputs.passwordConfirm) {
        alert('비밀번호가 일치하지 않습니다.');
        return false;
      }
      if (!validatePassword(inputs.password)) {
        alert(
          '영소문자, 숫자, 특수문자 최소 1개씩의 조합. 8자 이상의 비밀번호를 입력해 주세요.',
        );
        return false;
      }
    }
    const data = {
      merchant_uid: `mid_${new Date().getTime()}`, // 주문번호
      company: '(주) 유니크플레이',
    };
    navigation.navigate('Certification', {
      userCode: 'imp66015233',
      // userCode: 'iamport',
      data: data,
      callback: {
        screen: 'SignUp',
        data: {
          thirdPartyUserId: inputs.thirdPartyUserId,
          email: inputs.email,
          authType: user.authType,
          password: inputs.password,
          passwordConfirm: inputs.passwordConfirm,
          pitchingAndHitting: inputs.pitchingAndHitting,
          formerAthlete: inputs.formerAthlete,
          position: inputs.position,
        },
      },
    });
  };

  const signUp = () => {
    if (processing.current) {
      return;
    }
    if (!validateInputs()) {
      return;
    }
    processing.current = true;
    const body = JSON.parse(JSON.stringify(inputs));
    if (!body.authType) {
      body.authType = '이메일';
    }
    body.birth = `${body.birthYear}-${padStart(
      body.birthMonth,
      2,
      0,
    )}-${padStart(body.birthDay, 2, 0)}`;

    api
      .post('/users', body)
      .then(function (response) {
        alert('가입이 완료되었습니다.');
        try {
          AsyncStorage.setItem(
            '@accessToken',
            response.headers['access-token'],
          );
          AsyncStorage.setItem(
            '@userInfo',
            JSON.stringify(response.data),
            () => {
              navigation.reset({
                index: 0,
                routes: [{name: 'Main'}],
              });
            },
          );
        } catch (e) {
          // saving error
        }
      })
      .catch(function (error) {
        console.log(error.response, error);
        alert(error.response.data.message);
      })
      .finally(() => {
        processing.current = false;
      });
  };

  const [agree, setAgree] = useState(false);
  const [agree2, setAgree2] = useState(false);
  return (
    <SafeAreaView nativeID="common-page-container" style={pageStyle.container}>
      <KeyboardAvoidingView
        behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
        style={pageStyle.container}>
        <ScrollView style={pageStyle.scrollView}>
          <View style={[styles.headerCommon, {backgroundColor: 'transparent'}]}>
            <TouchableOpacity
              onPress={() =>
                Platform.OS === 'web' ? history.back() : navigation.goBack()
              }>
              <Image
                source={require('../assets/images/icon_back_wh.png')}
                style={[styles.headerBackButton]}
              />
            </TouchableOpacity>
          </View>
          <View style={[pageStyle.scrollViewContainer]}>
            <Image
              style={pageStyle.logo}
              source={require('../assets/images/logo.png')}
            />
            <View style={pageStyle.formContainer}>
              <>
                <View style={[pageStyle.subtitleContainer]}>
                  <Text style={[pageStyle.subtitle]}>이름</Text>
                </View>
                <TextInput
                  editable={false}
                  style={[pageStyle.input]}
                  type="text"
                  placeholder="이름"
                  value={inputs.name}
                  placeholderTextColor={'#aaa'}
                  onChangeText={value =>
                    setInputs({
                      ...inputs,
                      ['name']: value,
                    })
                  }
                />
                <View style={[pageStyle.subtitleContainer, pageStyle.mt16]}>
                  <Text style={[pageStyle.subtitle]}>이메일</Text>
                  {emailWarning && (
                    <Text style={[pageStyle.warningTxt]}>
                      *올바른 이메일 주소가 아닙니다.
                    </Text>
                  )}
                </View>
                <TextInput
                  style={[pageStyle.input]}
                  type="email"
                  placeholder="이메일"
                  placeholderTextColor={'#aaa'}
                  value={inputs.email}
                  onChangeText={value => onChangeEmail(value)}
                />
              </>
              {user.authType === '이메일' && (
                <>
                  <View style={[pageStyle.subtitleContainer, pageStyle.mt16]}>
                    <Text style={[pageStyle.subtitle]}>비밀번호</Text>
                    {passwordWarning && (
                      <Text style={[pageStyle.warningTxt]}>
                        *영소문자, 숫자, 특수문자 최소 1개씩의 조합. 8자 이상
                      </Text>
                    )}
                  </View>
                  <View style={[pageStyle.passwordInputContainer]}>
                    <TextInput
                      style={[pageStyle.input]}
                      placeholder="비밀번호"
                      placeholderTextColor={'#aaa'}
                      type="password"
                      value={inputs.password}
                      onChangeText={onPasswordChange}
                      secureTextEntry={!passwordVisible}
                    />
                    <View style={[pageStyle.inputIconContainer]}>
                      <TouchableOpacity
                        onPress={() => setPasswordVisible(!passwordVisible)}>
                        <Image
                          style={[pageStyle.invisibleIcn]}
                          source={
                            !passwordVisible
                              ? require('../assets/images/invisible.png')
                              : require('../assets/images/visible.png')
                          }
                        />
                      </TouchableOpacity>
                      <Image
                        style={[
                          pageStyle.checkIcn,
                          !passwordChecked && {display: 'none'},
                        ]}
                        source={require('../assets/images/checked.png')}
                      />
                    </View>
                  </View>
                  <View style={[pageStyle.subtitleContainer, pageStyle.mt16]}>
                    <Text style={[pageStyle.subtitle]}>비밀번호 재확인</Text>
                    {passwordConfirmWarning && (
                      <Text style={[pageStyle.warningTxt]}>
                        *영소문자, 숫자, 특수문자 최소 1개씩의 조합. 8자 이상
                      </Text>
                    )}
                  </View>
                  <View style={[pageStyle.passwordInputContainer]}>
                    <TextInput
                      style={[pageStyle.input]}
                      placeholder="비밀번호 재확인"
                      placeholderTextColor={'#aaa'}
                      type="password"
                      value={inputs.passwordConfirm}
                      onChangeText={onPasswordConfirmChange}
                      secureTextEntry={!passwordConfirmVisible}
                    />
                    <View style={[pageStyle.inputIconContainer]}>
                      <TouchableOpacity
                        onPress={() =>
                          setPasswordConfirmVisible(!passwordConfirmVisible)
                        }>
                        <Image
                          style={[pageStyle.invisibleIcn]}
                          source={
                            !passwordConfirmVisible
                              ? require('../assets/images/invisible.png')
                              : require('../assets/images/visible.png')
                          }
                        />
                      </TouchableOpacity>
                      <Image
                        style={[
                          pageStyle.checkIcn,
                          !passwordConfirmChecked && {display: 'none'},
                        ]}
                        source={require('../assets/images/checked.png')}
                      />
                    </View>
                  </View>
                </>
              )}
              <View style={[pageStyle.mt18]}>
                <Text style={[pageStyle.subtitle]}>휴대전화</Text>
                <View style={[pageStyle.row, {alignItems: 'flex-end'}]}>
                  <View style={[pageStyle.flex1]}>
                    <TextInput
                      editable={false}
                      style={[pageStyle.input]}
                      placeholder="휴대전화번호"
                      placeholderTextColor={'#aaa'}
                      maxLength={11}
                      value={inputs.cellphone}
                    />
                  </View>
                  <TouchableOpacity
                    style={[pageStyle.requestCodeBtn]}
                    onPress={requestCertify}>
                    <Text style={[pageStyle.buttonSmallTxt]}>본인인증하기</Text>
                  </TouchableOpacity>
                </View>
                <View style={[pageStyle.row, pageStyle.mt18]}>
                  <View style={[pageStyle.col]}>
                    <Text style={[pageStyle.subtitle]}>생년</Text>
                    <TextInput
                      style={[pageStyle.input]}
                      maxLength={4}
                      editable={false}
                      name={'birthYear'}
                      placeholder="생년"
                      placeholderTextColor={'#aaa'}
                      value={inputs.birthYear}
                    />
                  </View>
                  <View style={[pageStyle.col, pageStyle.mh7]}>
                    <Text style={[pageStyle.subtitle]}>월</Text>
                    <SelectDropdown
                      disabled={true}
                      defaultButtonText="월"
                      buttonStyle={pageStyle.dropDownMonth}
                      buttonTextStyle={pageStyle.downDownText}
                      rowTextStyle={styles.dropDownRowText}
                      renderDropdownIcon={() => (
                        <Image
                          source={require('../assets/images/select_arr.png')}
                        />
                      )}
                      data={[
                        '1월',
                        '2월',
                        '3월',
                        '4월',
                        '5월',
                        '6월',
                        '7월',
                        '8월',
                        '9월',
                        '10월',
                        '11월',
                        '12월',
                      ]}
                      defaultValue={`${parseInt(inputs.birthMonth, 10)}월`}
                      onSelect={(selectedItem, index) => {
                        console.log(selectedItem, index);
                        setInputs({
                          ...inputs,
                          ['birthMonth']: index + 1,
                        });
                      }}
                      buttonTextAfterSelection={(selectedItem, index) => {
                        return selectedItem;
                      }}
                      rowTextForSelection={(item, index) => {
                        return item;
                      }}
                    />
                  </View>
                  <View style={[pageStyle.col]}>
                    <Text style={[pageStyle.subtitle]}>일</Text>
                    <TextInput
                      style={[pageStyle.input]}
                      editable={false}
                      maxLength={2}
                      placeholder="일"
                      placeholderTextColor={'#aaa'}
                      value={inputs.birthDay}
                    />
                  </View>
                </View>
                <View style={[pageStyle.row, pageStyle.mt18, {marginTop: 18}]}>
                  <View style={[pageStyle.col]}>
                    <Text style={[pageStyle.subtitle]}>성별</Text>
                    <View style={[pageStyle.dropDownContainer]}>
                      <SelectDropdown
                        defaultValue={inputs.gender}
                        defaultButtonText="성별"
                        buttonStyle={pageStyle.dropDown}
                        buttonTextStyle={pageStyle.downDownText}
                        rowTextStyle={styles.dropDownRowText}
                        renderDropdownIcon={() => (
                          <Image
                            source={require('../assets/images/select_arr.png')}
                          />
                        )}
                        disabled={true}
                        data={['남성', '여성']}
                        onSelect={(selectedItem, index) => {
                          console.log(selectedItem, index);
                          setInputs({
                            ...inputs,
                            ['gender']: selectedItem,
                          });
                        }}
                        buttonTextAfterSelection={(selectedItem, index) => {
                          return selectedItem;
                        }}
                        rowTextForSelection={(item, index) => {
                          return item;
                        }}
                      />
                    </View>
                  </View>
                  <View style={[pageStyle.col2, pageStyle.ms7]}>
                    <Text style={[pageStyle.subtitle]}>투타</Text>
                    <View style={[pageStyle.dropDownContainer]}>
                      <SelectDropdown
                        defaultButtonText="선택"
                        buttonStyle={pageStyle.dropDown}
                        buttonTextStyle={pageStyle.downDownText}
                        rowTextStyle={styles.dropDownRowText}
                        renderDropdownIcon={() => (
                          <Image
                            source={require('../assets/images/select_arr.png')}
                          />
                        )}
                        data={['우투우타', '우투좌타', '좌투우타', '좌투좌타']}
                        onSelect={(selectedItem, index) => {
                          console.log(selectedItem, index);
                          setInputs({
                            ...inputs,
                            ['pitchingAndHitting']: selectedItem,
                          });
                        }}
                        buttonTextAfterSelection={(selectedItem, index) => {
                          return selectedItem;
                        }}
                        rowTextForSelection={(item, index) => {
                          return item;
                        }}
                      />
                    </View>
                  </View>
                </View>
                <View style={[pageStyle.row, pageStyle.mt18]}>
                  <View style={[pageStyle.col]}>
                    <Text style={[pageStyle.subtitle]}>선출여부</Text>
                    <View style={[pageStyle.dropDownContainer]}>
                      <SelectDropdown
                        defaultButtonText="선택"
                        buttonStyle={pageStyle.dropDown}
                        buttonTextStyle={pageStyle.downDownText}
                        rowTextStyle={styles.dropDownRowText}
                        renderDropdownIcon={() => (
                          <Image
                            source={require('../assets/images/select_arr.png')}
                          />
                        )}
                        data={['비선출', '중출', '고출이상']}
                        onSelect={(selectedItem, index) => {
                          console.log(selectedItem, index);
                          setInputs({
                            ...inputs,
                            ['formerAthlete']: selectedItem,
                          });
                        }}
                        buttonTextAfterSelection={(selectedItem, index) => {
                          return selectedItem;
                        }}
                        rowTextForSelection={(item, index) => {
                          return item;
                        }}
                      />
                    </View>
                  </View>
                  <View style={[pageStyle.col2, pageStyle.ms7]}>
                    <Text style={[pageStyle.subtitle]}>선호 포지션</Text>
                    <View style={[pageStyle.dropDownContainer]}>
                      <SelectDropdown
                        defaultButtonText="선택"
                        buttonStyle={pageStyle.dropDown}
                        buttonTextStyle={pageStyle.downDownText}
                        rowTextStyle={styles.dropDownRowText}
                        renderDropdownIcon={() => (
                          <Image
                            source={require('../assets/images/select_arr.png')}
                          />
                        )}
                        data={[
                          '투수',
                          '포수',
                          '1루수',
                          '2루수',
                          '유격수',
                          '3루수',
                          '좌익수',
                          '중견수',
                          '우익수',
                        ]}
                        onSelect={(selectedItem, index) => {
                          console.log(selectedItem, index);
                          setInputs({
                            ...inputs,
                            ['position']: selectedItem,
                          });
                        }}
                        buttonTextAfterSelection={(selectedItem, index) => {
                          return selectedItem;
                        }}
                        rowTextForSelection={(item, index) => {
                          return item;
                        }}
                      />
                    </View>
                  </View>
                </View>
                <View style={[pageStyle.row, {alignItems: 'flex-end'}]}>
                  <View style={[pageStyle.flex1, pageStyle.mt16]}>
                    <Text style={[pageStyle.subtitle]}>출신학교</Text>
                    <TextInput
                      style={[pageStyle.input]}
                      placeholder="출신학교"
                      maxLength={8}
                      value={inputs.school}
                      onChangeText={value =>
                        setInputs({
                          ...inputs,
                          ['school']: value,
                        })
                      }
                    />
                  </View>
                </View>
                <Text style={[pageStyle.certificationTxt]}>
                  이름, 휴대전화번호, 생년월일, 성별은 본인인증 후 자동으로 설정
                  됩니다.
                </Text>
                <View
                  style={[
                    {
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      alignContent: 'center',
                      marginTop: 12,
                    },
                  ]}>
                  <TouchableOpacity
                    onPress={() => setAgree(!agree)}
                    style={[
                      styles.row,
                      pageStyle.row,
                      {
                        alignContent: 'center',
                        alignItems: 'center',
                      },
                    ]}>
                    <Image
                      style={[pageStyle.icnCheck]}
                      source={
                        agree
                          ? require('../assets/images/icn_checked.png')
                          : require('../assets/images/icn_unchecked.png')
                      }
                    />
                    <Text style={[pageStyle.agreeTxt]}>
                      이용약관에 동의합니다.
                    </Text>
                  </TouchableOpacity>
                  <TouchableOpacity
                    onPress={() =>
                      Linking.openURL('https://unique-play.com/terms')
                    }>
                    <Text style={[pageStyle.agreeTxt]}>보기</Text>
                  </TouchableOpacity>
                </View>
                <View
                  style={[
                    {
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      alignContent: 'center',
                      marginTop: 6,
                    },
                  ]}>
                  <TouchableOpacity
                    onPress={() => setAgree2(!agree2)}
                    style={[
                      styles.row,
                      pageStyle.row,
                      {
                        alignContent: 'center',
                        alignItems: 'center',
                      },
                    ]}>
                    <Image
                      style={[pageStyle.icnCheck]}
                      source={
                        agree2
                          ? require('../assets/images/icn_checked.png')
                          : require('../assets/images/icn_unchecked.png')
                      }
                    />
                    <Text style={[pageStyle.agreeTxt]}>
                      개인정보처리방침에 동의합니다.
                    </Text>
                  </TouchableOpacity>
                  <TouchableOpacity
                    onPress={() =>
                      Linking.openURL('https://unique-play.com/privacy')
                    }>
                    <Text style={[pageStyle.agreeTxt]}>보기</Text>
                  </TouchableOpacity>
                </View>
              </View>
              <TouchableOpacity style={[pageStyle.commonBtn]} onPress={signUp}>
                <Text style={[pageStyle.buttonTxt]}>가입하기</Text>
              </TouchableOpacity>
            </View>
          </View>
        </ScrollView>
      </KeyboardAvoidingView>
    </SafeAreaView>
  );
};

const pageStyle = StyleSheet.create({
  container: {
    flex: 1,
    width: '100%',
    alignItems: 'center',
    backgroundColor: 'rgb(23, 53, 100)',
  },
  scrollView: {
    width: '100%',
  },
  scrollViewContainer: {
    paddingHorizontal: 32,
    paddingBottom: 37,
    alignItems: 'center',
  },
  logo: {},
  formContainer: {
    width: '100%',
    backgroundColor: 'white',
    marginTop: 23,
    borderRadius: 20,
    padding: 25,
  },
  subtitleContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  subtitle: {
    fontFamily: 'Pretendard',
    fontSize: 13,
    color: 'black',
    lineHeight: 15,
  },
  warningTxt: {
    paddingTop: 2,
    color: '#ff0000',
    fontFamily: 'Pretendard',
    fontSize: 8,
    paddingStart: 5,
  },
  input: {
    fontFamily: 'Pretendard',
    fontSize: 14,
    borderBottomColor: '#909090',
    borderBottomWidth: 1,
    marginTop: 8,
    paddingBottom: 4,
  },
  mt16: {
    marginTop: 16,
  },
  mt18: {
    marginTop: 18,
  },
  mh7: {
    marginHorizontal: 7,
  },
  ms7: {
    marginStart: 7,
  },
  passwordInputContainer: {
    position: 'relative',
  },
  inputIconContainer: {
    display: 'flex',
    position: 'absolute',
    alignContent: 'center',
    alignItems: 'center',
    right: 8,
    flexDirection: 'row',
    bottom: 6,
  },
  invisibleIcn: {
    marginEnd: 6,
    width: 20,
    height: 15,
    resizeMode: 'cover',
  },
  certificationTxt: {
    marginTop: 12,
    color: Colors.main,
    fontFamily: 'Pretendard',
    fontSize: 12,
  },
  commonBtn: {
    marginTop: 25,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 15,
    height: 30,
    backgroundColor: 'rgb(61, 80, 183)',
  },
  buttonTxt: {
    fontFamily: 'Pretendard',
    fontSize: 13,
    color: 'white',
  },
  buttonSmallTxt: {
    fontFamily: 'Pretendard',
    fontSize: 13,
    color: 'white',
  },
  dropDownContainer: {
    position: 'relative',
  },
  dropDownMonth: {
    width: '100%',
    backgroundColor: 'white',
    borderWidth: 1,
    borderColor: 'rgb(61, 80, 183)',
    height: 30,
    position: 'absolute',
    bottom: 0,
    marginTop: 10,
  },
  dropDown: {
    width: '100%',
    backgroundColor: 'white',
    borderWidth: 1,
    borderColor: 'rgb(61, 80, 183)',
    height: 30,
    marginTop: 10,
  },
  downDownText: {
    color: 'black',
    fontFamily: 'Pretendard',
    fontSize: 11,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
  col: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  col2: {
    flex: 2,
    display: 'flex',
    flexDirection: 'column',
  },
  flex1: {
    flex: 1,
  },
  requestCodeBtn: {
    width: 94,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 15,
    height: 25,
    backgroundColor: 'rgb(61, 80, 183)',
  },
  icnCheck: {
    width: 18,
    height: 18,
  },
  agreeTxt: {
    fontFamily: 'Pretendard',
    fontSize: 12,
    color: 'black',
    lineHeight: 15,
    marginStart: 4,
  },
});

export default SignUpView;

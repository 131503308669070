import React, {useState, useEffect, useRef, useMemo} from 'react';
import {
  Image,
  SafeAreaView,
  ScrollView,
  Text,
  TouchableOpacity,
  View,
  StyleSheet,
  KeyboardAvoidingView,
  FlatList,
  TextInput,
  Platform,
  Dimensions,
  Alert,
  DeviceEventEmitter,
} from 'react-native';
import {Shadow} from 'react-native-shadow-2';
import styles from '../common/styles';
import AsyncStorage from '@react-native-async-storage/async-storage';
import api from '../common/api';
import {RichEditor as RichEditorWeb} from '../component/react-native-pell-rich-editor-custom';
import {RichEditor} from 'react-native-pell-rich-editor';
import {launchImageLibrary} from 'react-native-image-picker';
import SelectDropdown from 'react-native-select-dropdown';
import Colors from '../common/colors';
import alert from '../component/alert';
const CommunityWriteView = ({navigation, route}) => {
  const {leagueId, item, admin} = route.params;
  const richText = useRef();
  const accessToken = useRef();
  const [title, setTitle] = useState(item?.title);
  const [contents, setContents] = useState(item?.contents);
  const [communityType, setCommunityType] = useState(item?.type);
  const [existsTemp, setExistsTemp] = useState(false);
  var DomParser = require('react-native-html-parser').DOMParser;

  const openImageLibrary = () => {
    if (numberOfImages >= 5) {
      alert('이미지 첨부는 5개 까지 가능합니다.');
      return;
    }
    launchImageLibrary(
      {
        mediaType: 'photo',
        includeBase64: true,
        maxWidth: 720,
      },
      response => {
        if (
          !response.errorCode &&
          response.assets &&
          response.assets.length > 0 &&
          response.assets[0] &&
          response.assets[0].base64
        ) {
          richText.current?.insertImage(
            'data:image/png;base64,' + response.assets[0].base64,
          );
        }
      },
    );
  };

  const numberOfImages = useMemo(() => {
    if (!contents) {
      return 0;
    }
    let doc = null;
    try {
      doc = new DomParser().parseFromString(contents, 'text/html');
    } catch (e) {
      return 0;
    }
    return doc?.getElementsByTagName('img')?.length;
  }, [DomParser, contents]);

  const firstImage = useMemo(() => {
    if (!contents) {
      return null;
    }
    let doc = null;
    try {
      doc = new DomParser().parseFromString(contents, 'text/html');
    } catch (e) {
      return null;
    }
    if (doc?.getElementsByTagName('img')?.length > 0) {
      return doc.getElementsByTagName('img')[0].getAttribute('src');
    }
    return null;
  }, [DomParser, contents]);

  const getData = token => {
    // api
    //   .get(
    //     `/app-league/leagues/${item.id}/boards?type=${encodeURIComponent(
    //       '자유게시판',
    //     )}&pageNo=1&pageSize=20`,
    //     {
    //       headers: {
    //         Authorization: `Bearer ${accessToken.current}`,
    //       },
    //     },
    //   )
    //   .then(function (response) {
    //     console.log('response', response.data);
    //     setBoardPage(response.data);
    //   })
    //   .catch(function (error) {
    //     console.log(error.response);
    //     alert('요청에 실패했습니다.');
    //   });
  };

  const callWriteApi = () => {
    const body = {
      type: communityType,
      title: title,
      contents: contents,
      firstImageUrl: firstImage,
    };
    if (item && item.id) {
      body.id = item.id;
    }
    api
      .post('/app-board/boards', body, {
        headers: {
          Authorization: `Bearer ${accessToken.current}`,
        },
      })
      .then(function (response) {
        DeviceEventEmitter.emit('communityTabPress', {id: response.data.id});
        DeviceEventEmitter.emit('communityListRefresh', {id: response.data.id});
        alert('게시글이 등록되었습니다.');
        AsyncStorage.removeItem('@tempCommunityDetail');
        Platform.OS === 'web' ? history.back() : navigation.goBack();
      })
      .catch(function (error) {
        console.log(error.response);
        alert('요청에 실패했습니다.');
      });
  };

  const richTextHandle = descriptionText => {
    if (descriptionText) {
      try {
        // descriptionText = descriptionText.replaceAll(/<a.*?<\/a>/g, '$1');
        descriptionText = descriptionText.replaceAll(/<\/?a[^>]*>/g, '');
        descriptionText = descriptionText.replaceAll('&nbsp;', ' ');
        descriptionText = descriptionText.replaceAll('&nbsp', ' ');
        const replacePattern1 =
          /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;
        const replacedText = descriptionText.replace(
          replacePattern1,
          '<a href="$1" target="_blank">$1</a>',
        );
        setContents(replacedText);
      } catch (e) {
        console.error(e);
        setContents(descriptionText);
      }
    } else {
      setContents('');
    }
  };

  const handleDeleteTemp = () => {
    alert('글쓰기', '임시저장 게시글을 삭제하겠습니까?', [
      {
        text: '예',
        onPress: () => {
          AsyncStorage.removeItem('@tempCommunityDetail', () => {
            alert('임시저장 게시글을 삭제하였습니다.');
            Platform.OS === 'web' ? history.back() : navigation.goBack();
          });
        },
      },
      {
        text: '아니오',
      },
    ]);
  };

  const handleWriteTemp = () => {
    alert('글쓰기', '임시저장 하시겠습니까?', [
      {
        text: '예',
        onPress: () => {
          const body = {
            type: communityType,
            title: title,
            contents: contents,
          };
          AsyncStorage.setItem(
            '@tempCommunityDetail',
            JSON.stringify(body),
            () => {
              alert('임시저장 되었습니다.');
              Platform.OS === 'web' ? history.back() : navigation.goBack();
            },
          );
        },
      },
      {
        text: '아니오',
      },
    ]);
  };

  const handleWrite = () => {
    alert('글쓰기', '작성 완료 하시겠습니까?', [
      {
        text: '예',
        onPress: () => {
          if (!communityType || !title || !contents) {
            alert('필수 값을 입력해 주세요.');
            return;
          }
          callWriteApi();
        },
      },
      {
        text: '아니오',
      },
    ]);
  };

  useEffect(() => {
    AsyncStorage.getItem('@accessToken', (err, result) => {
      if (err) {
        console.error(err);
        return;
      }
      accessToken.current = result;
      getData(result);
    });
    AsyncStorage.getItem('@tempCommunityDetail', (err, result) => {
      if (err) {
        console.error(err);
        return;
      }
      if (!result) {
        return;
      }
      const body = JSON.parse(result);
      setTitle(body.title);
      setContents(body.contents);
      setCommunityType(body.type);
      console.log('body', body);
      alert('임시저장 게시글을 불러왔습니다.');
      setExistsTemp(true);
    });
  }, []);

  const boardTypeList = useMemo(() => {
    return ['자유게시판', '선수모집', '팀모집'];
  }, []);

  return (
    <>
      <SafeAreaView nativeID="common-page-container" style={[styles.container]}>
        <Shadow
          offset={[0, 2]}
          style={[{width: '100%'}]}
          containerStyle={[{marginTop: 0}]}
          distance={7}
          sides={{bottom: true, top: false, start: false, end: false}}>
          <View style={[styles.headerCommon]}>
            <View style={[styles.row, styles.verticalAlign]}>
              <TouchableOpacity
                onPress={() =>
                  Platform.OS === 'web' ? history.back() : navigation.goBack()
                }>
                <Image
                  source={require('../assets/images/icon_back.png')}
                  style={[styles.headerBackButton]}
                />
              </TouchableOpacity>
            </View>
            <View style={[styles.row, styles.verticalAlign]}>
              {existsTemp && (
                <TouchableOpacity
                  onPress={handleDeleteTemp}
                  style={[pageStyle.controlBtn3]}>
                  <Text style={[pageStyle.controlBtnTxt3]}>임시저장삭제</Text>
                </TouchableOpacity>
              )}
              <TouchableOpacity
                onPress={handleWriteTemp}
                style={[pageStyle.controlBtn1]}>
                <Text style={[pageStyle.controlBtnTxt1]}>임시저장</Text>
              </TouchableOpacity>
              <TouchableOpacity
                onPress={handleWrite}
                style={[pageStyle.controlBtn2]}>
                <Text style={[pageStyle.controlBtnTxt2]}>등록</Text>
              </TouchableOpacity>
            </View>
          </View>
        </Shadow>
        <KeyboardAvoidingView
          keyboardVerticalOffset={Platform.OS === 'ios' ? 0 : 30}
          behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
          style={{flexGrow: 1}}>
          <ScrollView
            style={[
              {
                marginTop: 10,
                flex: 1,
              },
            ]}
            nestedScrollEnabled={true}>
            <View style={{flex: 1}}>
              <View style={[pageStyle.dropDownComponent]}>
                <SelectDropdown
                  defaultButtonText="게시판 선택"
                  buttonStyle={pageStyle.dropDown}
                  buttonTextStyle={pageStyle.downDownText}
                  rowTextStyle={styles.dropDownRowText}
                  renderDropdownIcon={() => (
                    <Image
                      source={require('../assets/images/select_arr.png')}
                    />
                  )}
                  defaultValue={communityType}
                  data={boardTypeList}
                  onSelect={(selectedItem, index) => {
                    setCommunityType(selectedItem);
                  }}
                />
              </View>
              <TextInput
                placeholder={'제목'}
                placeholderTextColor={'#aaa'}
                style={[pageStyle.titleInput]}
                defaultValue={title}
                onChangeText={value => setTitle(value)}
              />
              {Platform.OS !== 'web' && (
                <View>
                  <RichEditor
                    initialContentHTML={contents}
                    androidHardwareAccelerationDisabled={true}
                    useContainer={Platform.OS === 'android'}
                    onChange={richTextHandle}
                    initialHeight={Dimensions.get('window').height - 300}
                    contentContainerStyle={{
                      flexGrow: 1,
                    }}
                    scrollEnabled={true}
                    placeholder={'내용을 입력하세요.'}
                    placeholderTextColor={'#aaa'}
                    style={[
                      pageStyle.editor,
                      {
                        minHeight: Dimensions.get('window').height - 300,
                      },
                    ]}
                    ref={richText}
                  />
                </View>
              )}
              {Platform.OS === 'web' && (
                <View>
                  <RichEditorWeb
                    initialContentHTML={contents}
                    androidHardwareAccelerationDisabled={true}
                    useContainer={false}
                    onChange={richTextHandle}
                    initialHeight={Dimensions.get('window').height - 250}
                    contentContainerStyle={{
                      flexGrow: 1,
                    }}
                    scrollEnabled={true}
                    placeholder={'내용을 입력하세요.'}
                    placeholderTextColor={'#aaa'}
                    style={[
                      pageStyle.editor,
                      {
                        minHeight: Dimensions.get('window').height - 250,
                      },
                    ]}
                    ref={richText}
                  />
                </View>
              )}
            </View>
          </ScrollView>
          <TouchableOpacity
            style={[pageStyle.addImageContainer]}
            onPress={openImageLibrary}>
            <Image
              style={[pageStyle.icnCamera]}
              source={require('../assets/images/icn_camera.png')}
            />
            <Text style={[pageStyle.addImageTxt]}>이미지첨부</Text>
          </TouchableOpacity>
        </KeyboardAvoidingView>
      </SafeAreaView>
    </>
  );
};

export default CommunityWriteView;

const pageStyle = StyleSheet.create({
  headerCommon: {},
  container: {
    borderWidth: 5,
    borderColor: '#ddd',
    opacity: 0.8,
  },
  titleInput: {
    fontFamily: 'Pretendard',
    fontSize: 16,
    paddingHorizontal: 10,
    paddingTop: 24,
    paddingBottom: 10,
    borderTopColor: '#ddd',
    borderTopWidth: 1,
    borderBottomColor: '#ddd',
    borderBottomWidth: 1,
    color: 'black',
  },
  controlBtn1: {
    borderWidth: 1,
    borderColor: Colors.main,
    width: 70,
    height: 26,
    borderRadius: 15,
    alignItems: 'center',
    justifyContent: 'center',
    marginEnd: 12,
  },
  controlBtnTxt1: {
    fontFamily: 'Pretendard',
    fontSize: 13,
    color: Colors.main,
  },
  controlBtn2: {
    backgroundColor: Colors.main,
    width: 70,
    height: 26,
    borderRadius: 15,
    alignItems: 'center',
    justifyContent: 'center',
  },
  controlBtnTxt2: {
    fontFamily: 'Pretendard',
    fontSize: 13,
    color: 'white',
  },
  controlBtn3: {
    borderWidth: 1,
    borderColor: 'red',
    width: 85,
    height: 26,
    borderRadius: 15,
    alignItems: 'center',
    justifyContent: 'center',
    marginEnd: 12,
  },
  controlBtnTxt3: {
    fontFamily: 'Pretendard',
    fontSize: 13,
    color: 'red',
  },
  cancelItemTxt: {
    color: '#555',
  },
  editor: {
    flex: 1,
    height: '100%',
  },
  scrollViewContainer: {},
  title: {color: 'black', fontFamily: 'Pretendard', fontSize: 13},
  list: {
    width: '100%',
    display: 'flex',
    marginTop: 6,
  },
  dropDownComponent: {
    marginEnd: 12,
  },
  dropDown: {
    width: '100%',
    backgroundColor: 'white',
    height: 32,
  },
  downDownText: {
    color: 'black',
    fontFamily: 'Pretendard',
    fontSize: 14,
    textAlign: 'left',
  },
  addImageContainer: {
    borderTopColor: '#ddd',
    borderTopWidth: 1,
    flexDirection: 'row',
    paddingHorizontal: 24,
    paddingVertical: 12,
    alignContent: 'center',
    alignItems: 'center',
    height: 50,
  },
  icnCamera: {
    width: 25,
    height: 23,
    opacity: 0.6,
  },
  addImageTxt: {
    fontFamily: 'Pretendard',
    fontSize: 14,
    marginStart: 12,
  },
});

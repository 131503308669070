import {Image, Platform, StyleSheet, Text, TextInput, View} from 'react-native';
import {Dimensions} from 'react-native';
import styles from '../common/styles';
import {SafeAreaView} from 'react-native-safe-area-context';
import {Shadow} from 'react-native-shadow-2';
import {TouchableOpacity} from 'react-native-gesture-handler';
import {useEffect, useRef, useState} from 'react';
import Colors from '../common/colors';
import api from '../common/api';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {title} from '../common/util';

const ChangePasswordView = ({navigation}) => {
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [currentPasswordChecked, setCurrentPasswordChecked] = useState(false);

  let accessToken = useRef();

  useEffect(() => {
    AsyncStorage.getItem('@accessToken', (err, result) => {
      if (err) {
        console.error(err);
        return;
      }
      accessToken.current = result;
    });
  }, []);

  const checkPassword = () => {
    if (!password || !passwordConfirm) {
      return;
    }
    if (password !== passwordConfirm) {
      alert('비밀번호가 일치하지 않습니다.');
      return;
    }
    api
      .post(
        '/users/password/check',
        {password: password},
        {
          headers: {
            Authorization: `Bearer ${accessToken.current}`,
          },
        },
      )
      .then(function (response) {
        if (response.data) {
          setPassword('');
          setPasswordConfirm('');
          setCurrentPasswordChecked(true);
        } else {
          alert('현재 비밀번호와 일치하지 않습니다.');
        }
      })
      .catch(function (error) {
        console.log(error.response);
        alert('요청에 실패했습니다.');
      });
  };

  const validatePassword = v => {
    const regex =
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
    return v.match(regex);
  };

  const confirm = () => {
    if (!password || !passwordConfirm) {
      return;
    }
    if (password !== passwordConfirm) {
      alert('비밀번호가 일치하지 않습니다.');
      return;
    }
    if (!validatePassword(password)) {
      alert(
        '영소문자, 숫자, 특수문자 최소 1개씩의 조합. 8자 이상의 비밀번호를 입력해 주세요.',
      );
      return;
    }
    api
      .post(
        '/users/change/password',
        {password: password},
        {
          headers: {
            Authorization: `Bearer ${accessToken.current}`,
          },
        },
      )
      .then(function (response) {
        alert('비밀번호가 변경되었습니다.');
        Platform.OS === 'web' ? history.back() : navigation.goBack();
      })
      .catch(function (error) {
        console.log(error.response);
        alert('요청에 실패했습니다.');
      });
  };
  return (
    <SafeAreaView nativeID="common-page-container" style={[styles.container]}>
      <Shadow
        offset={[0, 2]}
        style={[{width: '100%'}]}
        containerStyle={[{marginTop: 0}]}
        distance={7}
        sides={{bottom: true, top: false, start: false, end: false}}>
        <View style={[styles.headerCommon]}>
          <View style={[styles.row, styles.verticalAlign]}>
            <TouchableOpacity
              onPress={() =>
                Platform.OS === 'web' ? history.back() : navigation.goBack()
              }>
              <Image
                source={require('../assets/images/icon_back.png')}
                style={[styles.headerBackButton]}
              />
            </TouchableOpacity>
            <Text style={[styles.headerText]}>비밀번호 재설정</Text>
          </View>
          <View style={[styles.row, styles.verticalAlign]} />
        </View>
      </Shadow>
      <View style={[pageStyle.container]}>
        {!currentPasswordChecked && (
          <View>
            <Text style={[pageStyle.subtitle]}>
              현재 비밀번호를 확인해주세요.
            </Text>
            <View>
              <TextInput
                style={[pageStyle.input]}
                placeholder="비밀번호"
                placeholderTextColor={'#aaa'}
                value={password}
                onChangeText={setPassword}
                secureTextEntry={true}
              />
              <TextInput
                style={[pageStyle.input]}
                placeholder="비밀번호 확인"
                placeholderTextColor={'#aaa'}
                value={passwordConfirm}
                onChangeText={setPasswordConfirm}
                secureTextEntry={true}
              />
            </View>
            <TouchableOpacity onPress={() => navigation.navigate('FindPwd')}>
              <Text style={[pageStyle.forgotPasswordTxt]}>
                비밀번호가 기억나지 않으세요?
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={[pageStyle.button]}
              onPress={checkPassword}>
              <Text style={[pageStyle.buttonTxt]}>확인</Text>
            </TouchableOpacity>
          </View>
        )}
        {currentPasswordChecked && (
          <View>
            <Text style={[pageStyle.subtitle]}>
              새로운 비밀번호를 입력해주세요.
            </Text>
            <View>
              <TextInput
                style={[pageStyle.input]}
                placeholder="비밀번호 입력"
                placeholderTextColor={'#aaa'}
                value={password}
                onChangeText={setPassword}
                secureTextEntry={true}
              />
              <TextInput
                style={[pageStyle.input]}
                placeholder="비밀번호 재확인"
                placeholderTextColor={'#aaa'}
                value={passwordConfirm}
                onChangeText={setPasswordConfirm}
                secureTextEntry={true}
              />
            </View>
            <Text style={[pageStyle.passwordHelp]}>
              비밀번호는 영소문자, 숫자, 특수문자 최소 1개씩의 조합 8자 이상으로
              설정해주세요.
            </Text>
            <TouchableOpacity style={[pageStyle.button]} onPress={confirm}>
              <Text style={[pageStyle.buttonTxt]}>확인</Text>
            </TouchableOpacity>
          </View>
        )}
      </View>
    </SafeAreaView>
  );
};

const pageStyle = StyleSheet.create({
  headerCommon: {},
  headerText: {color: 'white'},
  container: {
    paddingVertical: 24,
    paddingHorizontal: 32,
  },
  row: {
    width: '100%',
    flexDirection: 'row',
    alignContent: 'center',
    alignItems: 'center',
    paddingVertical: 12,
    paddingHorizontal: 36,
    justifyContent: 'space-between',
  },
  subtitle: {
    fontSize: 16,
    fontFamily: 'Pretendard',
    color: 'black',
  },
  input: {
    marginTop: 24,
    borderBottomColor: '#e6e6e6',
    borderBottomWidth: 1,
    paddingVertical: 4,
    paddingHorizontal: 8,
  },
  forgotPasswordTxt: {
    fontFamily: 'Pretendard',
    fontSize: 14,
    textDecorationLine: 'underline',
    marginTop: 32,
  },
  button: {
    marginTop: 42,
    height: 50,
    borderRadius: 25,
    backgroundColor: Colors.main,
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonTxt: {
    color: 'white',
    fontSize: 18,
    fontFamily: 'Pretendard',
  },
  passwordHelp: {
    fontFamily: 'Pretendard',
    fontSize: 14,
    color: '#ff0000',
    marginTop: 32,
  },
});

export default ChangePasswordView;

import React, {useState, useEffect, useRef, useMemo} from 'react';
import {
  Image,
  SafeAreaView,
  Text,
  TouchableOpacity,
  View,
  StyleSheet,
  ImageBackground,
  Dimensions,
  useWindowDimensions,
  Platform,
} from 'react-native';
import styles from '../common/styles';
import {Shadow} from 'react-native-shadow-2';
import {FlatList} from 'react-native-gesture-handler';
import AsyncStorage from '@react-native-async-storage/async-storage';
import api from '../common/api';
import moment from 'moment';

const MyAlarmListView = ({navigation, route}) => {
  const [page, setPage] = useState({});
  const [progressShown, setProgressShown] = useState(false);
  const {width} = useWindowDimensions();

  let pageLoading = useRef(false);
  let accessToken = useRef('');

  const getData = token => {
    setProgressShown(true);
    console.log('/app-user/alarms?pageNo=1&pageSize=20');
    api
      .get('/app-user/alarms?pageNo=1&pageSize=20', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(function (response) {
        console.log(response.data);
        setPage(response.data);
        setProgressShown(false);
      })
      .catch(function (error) {
        console.log(error);
        setProgressShown(false);
      });
  };

  const handlePageLoadMore = () => {
    if (pageLoading.current) {
      return;
    }
    if (page.currentPage && page.totalPage <= page.currentPage) {
      return;
    }
    pageLoading.current = true;
    api
      .get(
        `/app-user/alarms?pageNo=${
          page.currentPage ? parseInt(page.currentPage, 10) + 1 : 1
        }&pageSize=20`,
        {
          headers: {
            Authorization: `Bearer ${accessToken.current}`,
          },
        },
      )
      .then(function (response) {
        response.data.items = [
          ...(page.items ? page.items : []),
          ...response.data.items,
        ];
        setPage(response.data);
        pageLoading.current = false;
        setProgressShown(false);
      })
      .catch(function (error) {
        console.log(error);
        pageLoading.current = false;
        setProgressShown(false);
      });
  };

  useEffect(() => {
    AsyncStorage.getItem('@accessToken', (err, result) => {
      accessToken.current = result;
      getData(result);
    });
  }, []);

  const clickRow = item => {
    if (!item.screenName) {
      return;
    }
    if (!item.paramName) {
      navigation.navigate(item.screenName, {itemId: item.relatedId});
    } else {
      const params = {};
      params[item.paramName] = item.relatedId;
      navigation.navigate(item.screenName, params);
    }
  };

  const dateFormatted = str => {
    if (!str) {
      return '';
    }
    return moment(str).format('YY/MM/DD');
  };

  return (
    <SafeAreaView nativeID="common-page-container" style={[styles.container]}>
      <Shadow
        offset={[0, 2]}
        style={[{width: '100%'}]}
        containerStyle={[{marginTop: 0}]}
        distance={7}
        sides={{bottom: true, top: false, start: false, end: false}}>
        <View style={[styles.headerCommon]}>
          <View style={[styles.row, styles.verticalAlign]}>
            <TouchableOpacity
              onPress={() =>
                Platform.OS === 'web' ? history.back() : navigation.goBack()
              }>
              <Image
                source={require('../assets/images/icon_back.png')}
                style={[styles.headerBackButton]}
              />
            </TouchableOpacity>
            <Text style={[styles.headerText]}>내 소식</Text>
          </View>
          <View style={[styles.row, styles.verticalAlign]} />
        </View>
      </Shadow>
      <View style={[pageStyle.container]}>
        <FlatList
          style={[pageStyle.list]}
          nestedScrollEnabled={true}
          keyExtractor={item => `alarm_${item.id.toString()}`}
          data={page.items}
          onEndReachedThreshold={1}
          onEndReached={handlePageLoadMore}
          renderItem={({item}) => (
            <View style={[pageStyle.row]}>
              <TouchableOpacity
                onPress={() => clickRow(item)}
                style={[styles.row, pageStyle.topRow]}>
                <View>
                  <Text style={[pageStyle.date]}>{item.title}</Text>
                  <Text style={[pageStyle.titleTxt]}>{item.contents}</Text>
                  <Text style={[pageStyle.type]}>
                    {item.type} · {moment(item.createdAt).format('YY.MM.DD')}
                  </Text>
                </View>
              </TouchableOpacity>
            </View>
          )}
          ListEmptyComponent={() => (
            <View style={[styles.emptyContainer]}>
              <Image source={require('../assets/images/logo2.png')} />
            </View>
          )}
        />
      </View>
    </SafeAreaView>
  );
};

const pageStyle = StyleSheet.create({
  headerCommon: {},
  navBgImage: {
    marginStart: 0,
    position: 'absolute',
    width: Dimensions.get('window').width,
    height: '100%',
  },
  headerText: {color: 'white'},
  container: {
    paddingBottom: 80,
  },
  list: {
    height: '100%',
  },
  row: {
    alignContent: 'center',
    alignItems: 'center',
    borderBottomColor: '#e6e6e6',
    borderBottomWidth: 1,
    paddingVertical: 20,
    paddingHorizontal: 24,
  },
  topRow: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  icnImg: {
    width: 30,
    height: 40,
    marginEnd: 18,
  },
  txt: {
    fontFamily: 'Pretendard',
    fontSize: 17,
    color: 'black',
  },
  date: {
    fontFamily: 'Pretendard',
    color: '#7c7c7c',
    fontSize: 12,
  },
  type: {
    color: '#7c7c7c',
    fontFamily: 'Pretendard',
    fontSize: 12,
    marginTop: 6,
  },
  titleTxt: {
    color: 'black',
    fontFamily: 'Pretendard',
    fontSize: 15,
    lineHeight: 15,
    marginTop: 8,
  },
  contentsContainer: {
    marginTop: 12,
  },
});

export default MyAlarmListView;

import React, {useState, useEffect, useRef, useMemo} from 'react';
import {
  Image,
  SafeAreaView,
  Text,
  TouchableOpacity,
  View,
  StyleSheet,
  FlatList,
  Platform,
  Dimensions,
  useWindowDimensions,
  DeviceEventEmitter,
  Linking,
} from 'react-native';
import {Shadow} from 'react-native-shadow-2';
import styles from '../common/styles';
import SelectDropdown from 'react-native-select-dropdown';
import AsyncStorage from '@react-native-async-storage/async-storage';
import api from '../common/api';
import * as Progress from 'react-native-progress';
import moment from 'moment';
import 'moment/locale/ko';
import Colors from '../common/colors';
import {title} from '../common/util';

const MyVideoView = ({navigation, route}) => {
  const {user} = route.params;

  const [seasonYear, setSeasonYear] = useState();
  const [month, setMonth] = useState();
  const [teamId, setTeamId] = useState();

  const [seasonOptions, setSeasonOptions] = useState([]);
  const monthOptions = ['', 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  const [teamOptions, setTeamOptions] = useState([]);

  const [gamePage, setGamePage] = useState({items: []});

  const [progressShown, setProgressShown] = useState(false);

  let accessToken = useRef();

  let seasonRef = useRef();
  let monthRef = useRef();
  let teamRef = useRef();

  let gamePageLoading = useRef();

  const getSeasonList = () => {
    api
      .get(`app-user/my-seasons?uid=${user.uid}`, {
        headers: {
          Authorization: `Bearer ${accessToken.current}`,
        },
      })
      .then(function (response) {
        let seasonOptionList = [];
        seasonOptionList.push('전체 시즌');
        for (let i = 0; i < response.data.length; i++) {
          seasonOptionList.push(response.data[i].year);
        }
        setSeasonOptions(seasonOptionList);
      })
      .catch(function (error) {
        console.log(error.response);
        alert('요청에 실패했습니다.');
      });
  };

  const getMyTeams = token => {
    if (!token) {
      return;
    }
    let requestUri = `/app-team/my-teams?pageSize=100&uid=${user.uid}`;
    api
      .get(requestUri, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(function (response) {
        const teamOptionCandidate = [];
        const teams = response.data.items;
        teamOptionCandidate.push({value: '', text: '전체 팀'});
        for (let i = 0; i < teams.length; i++) {
          teamOptionCandidate.push({value: teams[i].id, text: teams[i].name});
        }
        setTeamOptions(teamOptionCandidate);
      })
      .catch(function (error) {
        console.log(error.response);
      });
  };

  const getGameData = token => {
    if (!token) {
      return;
    }
    callGamePage(token, false);
  };

  const callGamePage = (token, loadMore) => {
    if (gamePageLoading.current) {
      return;
    }
    if (
      loadMore &&
      gamePage.currentPage &&
      gamePage.totalPage <= gamePage.currentPage
    ) {
      return;
    }
    gamePageLoading.current = true;

    setProgressShown(true);
    let requestUri = `app-game/my?seasonYear=${
      seasonYear && seasonYear !== '전체 시즌' ? seasonYear : ''
    }&pageNo=${
      gamePage.currentPage && loadMore
        ? parseInt(gamePage.currentPage, 10) + 1
        : 1
    }&pageSize=20`;
    if (teamId) {
      requestUri += `&teamId=${teamId}`;
    }
    if (month) {
      requestUri += `&month=${month}`;
    }
    if (user.uid) {
      requestUri += `&userId=${user.uid}`;
    }
    requestUri += '&battingPitchingType=BATTING';
    console.log(requestUri);
    api
      .get(requestUri, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(function (response) {
        response.data.items = [
          ...(gamePage.items && loadMore ? gamePage.items : []),
          ...response.data.items,
        ];
        setGamePage(response.data);
      })
      .catch(function (error) {
        console.log(error);
      })
      .finally(() => {
        setProgressShown(false);
        gamePageLoading.current = false;
      });
  };

  useEffect(() => {
    if (seasonOptions.length > 0) {
      setSeasonYear(seasonOptions[0]);
    }
  }, [seasonOptions]);

  useEffect(() => {
    setTeamId('');
    teamRef.current.reset();
  }, [seasonYear]);

  useEffect(() => {
    AsyncStorage.getItem('@accessToken', (err, result) => {
      accessToken.current = result;
      getMyTeams(result);
      getSeasonList(result);
      setGamePage({items: []});
      getGameData(result);
    });
  }, []);

  useEffect(() => {
    setGamePage({items: []});
    getGameData(accessToken.current);
  }, [seasonYear, month, teamId]);

  useEffect(() => {
    setMonth('');
    setTeamId('');
    teamRef.current.reset();
    monthRef.current.reset();
    teamRef.current.reset();
  }, [seasonYear]);

  const getFirstTeamScore = item => {
    return item?.records && JSON.parse(item?.records)?.batFirstTeam?.score;
  };

  const getLastTeamScore = item => {
    return item?.records && JSON.parse(item?.records)?.batLastTeam?.score;
  };

  const parseTime = time => {
    if (time && time.indexOf(':', 4) > -1) {
      return time.substring(0, time.indexOf(':', 4));
    }
    return time;
  };

  const videoUrlIsChannel = function (fullVideoUrl) {
    if (fullVideoUrl && fullVideoUrl.indexOf('channel') > -1) {
      return true;
    }
    return false;
  };

  return (
    <>
      <SafeAreaView
        nativeID="common-page-container"
        style={[styles.container, pageStyle.container]}>
        <Shadow
          offset={[0, 2]}
          style={[{width: '100%'}]}
          containerStyle={[{marginTop: 0}]}
          distance={7}
          sides={{bottom: true, top: false, start: false, end: false}}>
          <View style={[styles.headerCommon]}>
            <View style={[styles.row, styles.verticalAlign]}>
              <TouchableOpacity
                onPress={() =>
                  Platform.OS === 'web' ? history.back() : navigation.goBack()
                }>
                <Image
                  source={require('../assets/images/icon_back.png')}
                  style={[styles.headerBackButton]}
                />
              </TouchableOpacity>
              <Text style={[styles.headerText]}>나의 플레이</Text>
            </View>
            <View style={[styles.row, styles.verticalAlign]} />
          </View>
        </Shadow>
        <View style={[styles.row, styles.mt18, pageStyle.dropdownContainer]}>
          <View style={[styles.col, styles.mr7]}>
            <SelectDropdown
              ref={seasonRef}
              defaultValue={seasonYear}
              defaultButtonText="시즌"
              buttonStyle={styles.dropDown}
              buttonTextStyle={styles.downDownText}
              rowTextStyle={styles.dropDownRowText}
              data={seasonOptions}
              onSelect={(selectedItem, index) => {
                if (selectedItem === '전체 시즌') {
                  setSeasonYear('');
                } else {
                  setSeasonYear(selectedItem);
                }
              }}
              renderDropdownIcon={() => (
                <Image source={require('../assets/images/select_arr.png')} />
              )}
            />
          </View>
          <View style={[styles.col, styles.mr7]}>
            <SelectDropdown
              ref={monthRef}
              defaultValue={month}
              defaultButtonText="월"
              buttonStyle={styles.dropDown}
              buttonTextStyle={styles.downDownText}
              rowTextStyle={styles.dropDownRowText}
              data={monthOptions}
              onSelect={(selectedItem, index) => {
                setMonth(selectedItem);
              }}
              buttonTextAfterSelection={(selectedItem, index) => {
                return selectedItem ? `${selectedItem}월` : '전체 월';
              }}
              rowTextForSelection={(item, index) => {
                return item ? `${item}월` : '전체 월';
              }}
              renderDropdownIcon={() => (
                <Image source={require('../assets/images/select_arr.png')} />
              )}
            />
          </View>
          <View style={[styles.col]}>
            <SelectDropdown
              ref={teamRef}
              defaultValue={teamId}
              defaultButtonText="팀"
              buttonStyle={styles.dropDown}
              buttonTextStyle={styles.downDownText}
              rowTextStyle={styles.dropDownRowText}
              data={teamOptions}
              onSelect={(selectedItem, index) => {
                if (selectedItem === '전체 팀') {
                  setTeamId('');
                } else {
                  setTeamId(selectedItem.value);
                }
              }}
              buttonTextAfterSelection={(selectedItem, index) => {
                return selectedItem.text;
              }}
              rowTextForSelection={(item, index) => {
                return item.text;
              }}
              renderDropdownIcon={() => (
                <Image source={require('../assets/images/select_arr.png')} />
              )}
            />
          </View>
        </View>
        <FlatList
          showsVerticalScrollIndicator={false}
          style={[pageStyle.list]}
          keyExtractor={item => `game_${item.id.toString()}`}
          onEndReachedThreshold={1}
          onEndReached={() => callGamePage(accessToken.current, true, teamId)}
          ListEmptyComponent={() => (
            <View style={[styles.emptyContainer]}>
              <Image source={require('../assets/images/logo2.png')} />
            </View>
          )}
          data={gamePage.items}
          renderItem={({item, index}) => (
            <View style={[pageStyle.rowItem]}>
              <View stlye={[pageStyle.leftArea]}>
                <View style={[pageStyle.topArea]}>
                  <Text style={[pageStyle.dateTxt]}>
                    {moment(item.date, 'YYYY-MM-DD').format('YY.MM.DD')}
                  </Text>
                  <Text style={[pageStyle.timeTxt]}>
                    {' '}
                    / {parseTime(item.time)}
                  </Text>
                  <Text style={[pageStyle.timeTxt]}>{item.leagueName}</Text>
                </View>
                <TouchableOpacity
                  onPress={() =>
                    navigation.navigate('BoxScore', {
                      data: item,
                      itemId: item?.id,
                    })
                  }
                  style={[pageStyle.bottomArea]}>
                  <Text style={[pageStyle.teamName]} numberOfLines={1}>
                    {item.batFirstTeamName}
                  </Text>
                  <Text
                    style={[
                      pageStyle.score,
                      getFirstTeamScore(item) >= getLastTeamScore(item) &&
                        pageStyle.scoreWin,
                    ]}>
                    {getFirstTeamScore(item)}
                  </Text>
                  <Text>:</Text>
                  <Text
                    style={[
                      pageStyle.score,
                      getFirstTeamScore(item) <= getLastTeamScore(item) &&
                        pageStyle.scoreWin,
                    ]}>
                    {getLastTeamScore(item)}
                  </Text>
                  <Text style={[pageStyle.teamName]} numberOfLines={1}>
                    {item.batLastTeamName}
                  </Text>
                </TouchableOpacity>
              </View>
              <View style={[pageStyle.rightArea]}>
                <TouchableOpacity
                  onPress={() =>
                    videoUrlIsChannel(item.fullVideoUrl)
                      ? Linking.openURL(item?.fullVideoUrl)
                      : navigation.navigate('Video', {
                          itemId: item.id,
                          game: item,
                          mode: 'PERSONAL',
                          user: user,
                        })
                  }
                  style={[
                    pageStyle.btnContainer,
                    item.fullVideoUrl && pageStyle.btnContainerActive,
                  ]}
                  disabled={!item.fullVideoUrl}>
                  <Text style={[pageStyle.btnTxt]}>경기 영상</Text>
                </TouchableOpacity>
              </View>
            </View>
          )}
        />
        {progressShown && (
          <View style={[styles.progressContainer]}>
            <Progress.Circle
              fill="transparent"
              size={70}
              borderWidth={3}
              indeterminate={true}
            />
          </View>
        )}
      </SafeAreaView>
    </>
  );
};

const pageStyle = StyleSheet.create({
  container: {},
  header: {width: '100%'},
  scrollViewContainer: {},
  dropdownContainer: {
    paddingHorizontal: 30,
  },
  list: {
    paddingStart: 26,
    paddingEnd: 26,
    paddingTop: 10,
    paddingBottom: 50,
  },
  rowItem: {
    paddingVertical: 13,
    borderTopWidth: 0.5,
    borderTopColor: '#eee',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  leftArea: {
    flex: 1,
  },
  rightArea: {
    width: 120,
    alignItems: 'center',
    alignContent: 'center',
    justifyContent: 'center',
  },
  topArea: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  dateTxt: {
    fontFamily: 'Pretendard',
    fontSize: 12,
    color: '#606060',
  },
  timeTxt: {
    fontFamily: 'Pretendard',
    fontSize: 11,
    color: '#606060',
    marginStart: 2,
  },
  bottomArea: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 5,
    maxWidth: Dimensions.get('window').width - 26 * 2 - 120,
  },
  teamName: {
    fontFamily: 'Pretendard',
    fontSize: 13,
    color: '#606060',
    maxWidth: 70,
  },
  score: {
    fontFamily: 'Pretendard-Bold',
    fontWeight: 'bold',
    fontSize: 15,
    paddingHorizontal: 6,
    color: '#939393',
  },
  scoreWin: {
    color: '#f80302',
  },
  btnContainer: {
    backgroundColor: '#ccc',
    width: 90,
    height: 25,
    borderRadius: 12,
    textAlign: 'center',
    justifyContent: 'center',
  },
  btnContainerActive: {
    backgroundColor: Colors.main,
  },
  btnTxt: {
    color: 'white',
    textAlign: 'center',
    fontFamily: 'Pretendard',
    fontSize: 15,
  },
});

export default MyVideoView;

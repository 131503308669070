import React, {useEffect, useState, useRef, useCallback, useMemo} from 'react';
import {
  Image,
  SafeAreaView,
  DeviceEventEmitter,
  Text,
  TouchableOpacity,
  View,
  StyleSheet,
  FlatList,
  TextInput,
  Modal,
  Platform,
  Alert,
} from 'react-native';
import styles from '../common/styles';
import {Shadow} from 'react-native-shadow-2';
import BoardMetaItem from '../component/BoardMetaItem';
import AsyncStorage from '@react-native-async-storage/async-storage';
import api from '../common/api';
import * as Progress from 'react-native-progress';
import {debounce} from 'lodash';
import Colors from '../common/colors';
import {title} from '../common/util';
import alert from '../component/alert';

const CommunityView = ({navigation}) => {
  const [tabIndex, setTabIndex] = useState(0);
  const [overallPage, setOverallPage] = useState({});
  const [freePage, setFreePage] = useState({});
  const [recruitPlayerPage, setRecruitPlayerPage] = useState({});
  const [recruitTeamPage, setRecruitTeamPage] = useState({});
  const [progressShown, setProgressShown] = useState(false);

  const [alarmUnread, setAlarmUnread] = useState(false);
  const [tourMode, setTourMode] = useState();

  let pageLoading = useRef(false);

  const tabList = [
    {index: 0, text: '전체'},
    {index: 1, text: '자유게시판'},
    {index: 2, text: '선수모집'},
    {index: 3, text: '팀모집'},
  ];

  let accessToken = useRef();
  let keyword = useRef();

  const [currentKeyword, setCurrentKeyword] = useState('');

  useEffect(() => {
    const unsubscribe = DeviceEventEmitter.addListener(
      'communityListRefresh',
      function (data) {
        getData();
      },
    );
    return () => {
      unsubscribe.remove();
    };
  }, []);

  useEffect(() => {
    AsyncStorage.getItem('@accessToken', (err, result) => {
      if (err) {
        return;
      }
      accessToken.current = result;
      setTourMode(!result);
      keyword.current = null;
      getData();
      checkAlarmUnRead();
    });

    const unsubscribe = DeviceEventEmitter.addListener(
      'communityTabPress',
      function (data) {
        keyword.current = null;
        setCurrentKeyword(null);
        getData();
        checkAlarmUnRead();
      },
    );

    const unsubscribe2 = DeviceEventEmitter.addListener(
      '@communitySearchInput',
      function (data) {
        keyword.current = data.keyword;
        setCurrentKeyword(data.keyword);
        getData();
      },
    );

    return () => {
      unsubscribe.remove();
      unsubscribe2.remove();
    };
  }, []);

  const boardType = useMemo(() => {
    if (tabIndex === 0) {
      return '';
    } else if (tabIndex === 1) {
      return '자유게시판';
    } else if (tabIndex === 2) {
      return '선수모집';
    } else if (tabIndex === 3) {
      return '팀모집';
    }
    return '';
  }, [tabIndex]);

  const activePage = useMemo(() => {
    if (tabIndex === 0) {
      return overallPage;
    } else if (tabIndex === 1) {
      return freePage;
    } else if (tabIndex === 2) {
      return recruitPlayerPage;
    } else if (tabIndex === 3) {
      return recruitTeamPage;
    }
    return overallPage;
  }, [tabIndex, overallPage, freePage, recruitPlayerPage, recruitTeamPage]);

  useEffect(() => {
    getData();
    checkAlarmUnRead();
  }, [tabIndex]);

  const removeKeywordAndRefresh = () => {
    keyword.current = null;
    setCurrentKeyword(null);
    getData();
  };

  const getData = () => {
    if (pageLoading.current) {
      return;
    }
    pageLoading.current = true;
    setProgressShown(true);
    api
      .get(
        `/app-board/list?pageNo=1&type=${boardType}&keyword=${encodeURIComponent(
          keyword.current ? keyword.current : '',
        )}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken.current}`,
          },
        },
      )
      .then(function (response) {
        if (tabIndex === 0) {
          setOverallPage(response.data);
        } else if (tabIndex === 1) {
          setFreePage(response.data);
        } else if (tabIndex === 2) {
          setRecruitPlayerPage(response.data);
        } else if (tabIndex === 3) {
          setRecruitTeamPage(response.data);
        }
      })
      .catch(function (error) {
        console.error(error);
      })
      .finally(() => {
        pageLoading.current = false;
        setProgressShown(false);
      });
  };

  const handlePageLoadMore = () => {
    if (pageLoading.current) {
      return;
    }
    if (!activePage.currentPage || !activePage.totalPage) {
      return;
    }
    if (
      activePage.currentPage &&
      activePage.totalPage <= activePage.currentPage
    ) {
      return;
    }
    pageLoading.current = true;
    api
      .get(
        `/app-board/list?type=${boardType}&keyword=${encodeURIComponent(
          keyword.current ? keyword.current : '',
        )}&pageNo=${
          activePage.currentPage ? parseInt(activePage.currentPage, 10) + 1 : 1
        }&pageSize=20`,
        {
          headers: {
            Authorization: `Bearer ${accessToken.current}`,
          },
        },
      )
      .then(function (response) {
        response.data.items = [
          ...(activePage.items ? activePage.items : []),
          ...response.data.items,
        ];
        if (tabIndex === 0) {
          setOverallPage(response.data);
        } else if (tabIndex === 1) {
          setFreePage(response.data);
        } else if (tabIndex === 2) {
          setRecruitPlayerPage(response.data);
        } else if (tabIndex === 3) {
          setRecruitTeamPage(response.data);
        }
        pageLoading.current = false;
        setProgressShown(false);
      })
      .catch(function (error) {
        console.error(error);
        pageLoading.current = false;
        setProgressShown(false);
      });
  };

  const checkAlarmUnRead = () => {
    if (!accessToken.current) {
      return;
    }
    api
      .get('/users/exists-unread-alarm', {
        headers: {
          Authorization: `Bearer ${accessToken.current}`,
        },
      })
      .then(function (response) {
        setAlarmUnread(response.data);
      })
      .catch(function (error) {
        console.error(error.response);
      });
  };

  return (
    <>
      <SafeAreaView style={[styles.container]}>
        <Shadow
          offset={[0, 2]}
          style={[{width: '100%'}]}
          containerStyle={[{marginTop: 0}]}
          distance={7}
          sides={{bottom: true, top: false, start: false, end: false}}>
          <View style={[styles.headerCommon]}>
            <View style={[styles.row, styles.verticalAlign]}>
              <TouchableOpacity
                onPress={() => {
                  if (!accessToken.current) {
                    return;
                  }
                  Platform.OS === 'web' ? history.back() : navigation.goBack();
                }}>
                <Image
                  source={require('../assets/images/icon_back.png')}
                  style={[styles.headerBackButton]}
                />
              </TouchableOpacity>
              <Text style={[styles.headerText]}>커뮤니티</Text>
            </View>
            <View style={[styles.row, styles.verticalAlign]}>
              <TouchableOpacity
                onPress={() => navigation.navigate('SearchKeywordHistory')}>
                <Image
                  resizeMode="contain"
                  style={[pageStyle.searchIcon]}
                  source={require('../assets/images/icn_search_2.png')}
                />
              </TouchableOpacity>
              <TouchableOpacity
                style={[styles.alarmContainer]}
                onPress={() => {
                  if (!accessToken.current) {
                    alert(
                      '',
                      '해당 페이지는 로그인 후 이용해주세요',
                      [
                        {
                          text: '계속 둘러보기',
                          onPress: () => {},
                          style: 'cancel',
                        },
                        {
                          text: '확인',
                          onPress: () => {
                            navigation?.reset({
                              index: 0,
                              routes: [{name: 'Login'}],
                            });
                          },
                        },
                      ],
                      {
                        cancelable: true,
                        onDismiss: () => {},
                      },
                    );
                    return;
                  }
                  navigation.navigate('MyAlarmList');
                }}>
                <Image
                  resizeMode="contain"
                  style={[styles.imgAlarm]}
                  source={require('../assets/images/icn_alarm.png')}
                />
                {alarmUnread && <View style={[styles.alarmDot]} />}
              </TouchableOpacity>
            </View>
          </View>
        </Shadow>
        <View
          style={[
            {
              marginTop: 10,
              position: 'relative',
              height: '90%',
            },
          ]}>
          <View
            style={[styles.scrollViewContainer, pageStyle.scrollViewContainer]}>
            {currentKeyword && (
              <View style={[styles.row, {alignItems: 'center'}]}>
                <Text style={[pageStyle.keywordTxt]}>
                  현재 키워드:{' '}
                  <Text
                    style={{
                      color: Colors.main,
                      fontWeight: 'bold',
                      fontFamily: 'Pretendard-Bold',
                    }}>
                    {currentKeyword}
                  </Text>
                </Text>
                <TouchableOpacity onPress={removeKeywordAndRefresh}>
                  <Image
                    style={[{opacity: 0.4, marginStart: 8}]}
                    source={require('../assets/images/close.png')}
                  />
                </TouchableOpacity>
              </View>
            )}
            <View style={[styles.tabContainer]}>
              {tabList.map(tab => (
                <TouchableOpacity
                  key={`tab_${tab.index}`}
                  onPress={() => setTabIndex(tab.index)}
                  style={[
                    tabIndex == tab.index && styles.tabItemSelected,
                    tabIndex != tab.index && styles.tabItem,
                  ]}>
                  <Text
                    style={[
                      tabIndex == tab.index && styles.tabItemTextSelected,
                      tabIndex != tab.index && styles.tabItemText,
                    ]}>
                    {tab.text}
                  </Text>
                </TouchableOpacity>
              ))}
            </View>
            {tabIndex === 0 && (
              <FlatList
                nestedScrollEnabled={true}
                style={[pageStyle.list]}
                keyExtractor={(item, index) => `overall_${index.toString()}`}
                data={overallPage.items}
                onEndReachedThreshold={1}
                onEndReached={handlePageLoadMore}
                renderItem={({item}) => (
                  <TouchableOpacity
                    onPress={() => {
                      navigation.navigate('CommunityDetail', {
                        item: item,
                        itemId: item.id,
                      });
                    }}>
                    <BoardMetaItem data={item} />
                  </TouchableOpacity>
                )}
                ListEmptyComponent={() => (
                  <View style={[styles.emptyContainer]}>
                    <Image source={require('../assets/images/logo2.png')} />
                  </View>
                )}
                ListFooterComponent={() => (
                  <View
                    style={[
                      {
                        height:
                          Platform.OS === 'web'
                            ? 50
                            : Platform.OS === 'ios'
                            ? 80
                            : 120,
                      },
                    ]}
                  />
                )}
              />
            )}
            {tabIndex === 1 && (
              <View>
                <FlatList
                  nestedScrollEnabled={true}
                  style={[pageStyle.list]}
                  keyExtractor={(item, index) => `free_${index.toString()}`}
                  data={freePage.items}
                  onEndReachedThreshold={1}
                  onEndReached={handlePageLoadMore}
                  renderItem={({item}) => (
                    <TouchableOpacity
                      onPress={() => {
                        navigation.navigate('CommunityDetail', {
                          item: item,
                          itemId: item.id,
                        });
                      }}>
                      <BoardMetaItem data={item} />
                    </TouchableOpacity>
                  )}
                  ListEmptyComponent={() => (
                    <View style={[styles.emptyContainer]}>
                      <Image source={require('../assets/images/logo2.png')} />
                    </View>
                  )}
                  ListFooterComponent={() => (
                    <View
                      style={[
                        {
                          height:
                            Platform.OS === 'web'
                              ? 50
                              : Platform.OS === 'ios'
                              ? 80
                              : 120,
                        },
                      ]}
                    />
                  )}
                />
              </View>
            )}
            {tabIndex === 2 && (
              <FlatList
                nestedScrollEnabled={true}
                style={[pageStyle.list]}
                keyExtractor={(item, index) =>
                  `recruit_player_${index.toString()}`
                }
                data={recruitPlayerPage.items}
                onEndReachedThreshold={1}
                onEndReached={handlePageLoadMore}
                renderItem={({item}) => (
                  <TouchableOpacity
                    onPress={() => {
                      navigation.navigate('CommunityDetail', {
                        item: item,
                        itemId: item.id,
                      });
                    }}>
                    <BoardMetaItem data={item} />
                  </TouchableOpacity>
                )}
                ListEmptyComponent={() => (
                  <View style={[styles.emptyContainer]}>
                    <Image source={require('../assets/images/logo2.png')} />
                  </View>
                )}
                ListFooterComponent={() => (
                  <View
                    style={[
                      {
                        height:
                          Platform.OS === 'web'
                            ? 50
                            : Platform.OS === 'ios'
                            ? 80
                            : 120,
                      },
                    ]}
                  />
                )}
              />
            )}
            {tabIndex === 3 && (
              <FlatList
                nestedScrollEnabled={true}
                style={[pageStyle.list]}
                keyExtractor={(item, index) =>
                  `recruit_team_${index.toString()}`
                }
                data={recruitTeamPage.items}
                onEndReachedThreshold={1}
                onEndReached={handlePageLoadMore}
                renderItem={({item}) => (
                  <TouchableOpacity
                    onPress={() => {
                      navigation.navigate('CommunityDetail', {
                        item: item,
                        itemId: item.id,
                      });
                    }}>
                    <BoardMetaItem data={item} />
                  </TouchableOpacity>
                )}
                ListEmptyComponent={() => (
                  <View style={[styles.emptyContainer]}>
                    <Image source={require('../assets/images/logo2.png')} />
                  </View>
                )}
                ListFooterComponent={() => (
                  <View
                    style={[
                      {
                        height:
                          Platform.OS === 'web'
                            ? 50
                            : Platform.OS === 'ios'
                            ? 80
                            : 120,
                      },
                    ]}
                  />
                )}
              />
            )}
          </View>
          {progressShown && (
            <View style={[styles.progressContainer]}>
              <Progress.Circle
                fill="transparent"
                size={70}
                borderWidth={3}
                indeterminate={true}
              />
            </View>
          )}
          <TouchableOpacity
            onPress={() => {
              if (!accessToken.current) {
                alert(
                  '',
                  '해당 페이지는 로그인 후 이용해주세요',
                  [
                    {
                      text: '계속 둘러보기',
                      onPress: () => {},
                      style: 'cancel',
                    },
                    {
                      text: '확인',
                      onPress: () => {
                        navigation?.reset({
                          index: 0,
                          routes: [{name: 'Login'}],
                        });
                      },
                    },
                  ],
                  {
                    cancelable: true,
                    onDismiss: () => {},
                  },
                );
                return;
              }
              navigation.navigate('CommunityWrite', {
                admin: false,
                type: boardType,
              });
            }}
            style={[pageStyle.writeBtnWrapper]}>
            <Image
              resizeMode="contain"
              style={[pageStyle.writeBtn]}
              source={require('../assets/images/btn_write.png')}
            />
          </TouchableOpacity>
        </View>
      </SafeAreaView>
    </>
  );
};

const pageStyle = StyleSheet.create({
  scrollViewContainer: {
    height: '100%',
  },
  list: {
    paddingTop: 10,
    paddingBottom: Platform.OS === 'web' ? 0 : 80,
  },
  keywordTxt: {
    color: '#666',
    fontFamily: 'Pretendard',
    fontSize: 14,
    marginStart: 12,
  },
  filterContainer: {
    flexDirection: 'row',
  },
  dropDownContainer: {
    flexDirection: 'row',
    paddingStart: 22,
    marginEnd: 8,
    marginTop: 10,
    flex: 3,
  },
  dropDownItemContainer: {
    marginHorizontal: 3,
    flex: 1,
  },
  dropDown: {
    width: '100%',
    backgroundColor: 'white',
    borderWidth: 1,
    borderColor: 'rgb(61, 80, 183)',
    height: 25,
  },
  downDownText: {
    color: 'black',
    fontSize: 11,
    fontFamily: 'Pretendard',
  },
  searchInput: {
    marginTop: 9,
    borderWidth: 1,
    borderColor: '#ccc',
    borderRadius: 13,
    height: 28,
    fontFamily: 'Pretendard',
    fontSize: 12,
    padding: 4.5,
    color: 'black',
    textAlign: 'left',
    paddingStart: 30,
  },
  searchInputContainer: {
    flex: 2,
    position: 'relative',
    marginEnd: 27,
  },
  searchIcon: {
    width: 48,
    height: 48,
    marginEnd: 4,
  },
  writeBtnWrapper: {
    position: 'absolute',
    bottom: 20,
    end: 10,
  },
  writeBtn: {
    width: 96,
    height: 44,
  },
});

export default CommunityView;

import React, {useState, useEffect, useRef} from 'react';
import {FlatList, Image, Text, TouchableOpacity, View} from 'react-native';
import GameMetaItem from '../GameMetaItem';
import AsyncStorage from '@react-native-async-storage/async-storage';
import api from '../../common/api';
import styles from '../../common/styles';
import moment from 'moment';
import * as Progress from 'react-native-progress';

const RecentGameList = props => {
  const [gamePage, setGamePage] = useState({});
  let accessToken = useRef();
  let loading = useRef(false);
  const [progressShown, setProgressShown] = useState(false);
  const getData = token => {
    api
      .get(
        `/app-team/games?teamId=${props.teamData.id}&toDate=${moment()
          .add(2, 'w')
          .format('YYYY-MM-DD')}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then(function (response) {
        setGamePage(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    AsyncStorage.getItem('@accessToken', (err, result) => {
      accessToken.current = result;
      // getData(result);
      handleLoadMore();
    });
  }, [props.teamData.id]);

  const handleLoadMore = () => {
    if (loading.current) {
      return;
    }
    if (gamePage.currentPage && gamePage.totalPage <= gamePage.currentPage) {
      return;
    }
    setProgressShown(true);
    loading.current = true;
    api
      .get(
        `/app-team/games?teamId=${props.teamData.id}&fromDate=${moment().format(
          'YYYY-MM-DD',
        )}&toDate=${moment().add(2, 'w').format('YYYY-MM-DD')}&pageNo=${
          gamePage.currentPage ? parseInt(gamePage.currentPage, 10) + 1 : 1
        }&pageSize=10`,
        {
          headers: {
            Authorization: `Bearer ${accessToken.current}`,
          },
        },
      )
      .then(function (response) {
        response.data.items = [
          ...(gamePage.items ? gamePage.items : []),
          ...response.data.items,
        ];
        setGamePage(response.data);
        loading.current = false;
      })
      .catch(function (error) {
        console.log(error);
      })
      .finally(() => {
        setProgressShown(false);
      });
  };

  return (
    <>
      <FlatList
        keyExtractor={item => `game_${item.id.toString()}`}
        data={gamePage.items}
        onEndReached={handleLoadMore}
        onEndReachedThreshold={1}
        renderItem={({item, index}) => (
          <GameMetaItem
            data={item}
            index={index}
            myGrade={props.myGrade}
            navigation={props.navigation}
          />
        )}
        ListEmptyComponent={() => (
          <View style={[styles.emptyContainer]}>
            <Image source={require('../../assets/images/logo2.png')} />
          </View>
        )}
      />
      {progressShown && (
        <View style={[styles.progressContainer]}>
          <Progress.Circle
            fill="transparent"
            size={70}
            borderWidth={3}
            indeterminate={true}
          />
        </View>
      )}
    </>
  );
};

export default RecentGameList;

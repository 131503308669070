import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {
  DeviceEventEmitter,
  Linking,
  Text,
  StyleSheet,
  Platform,
} from 'react-native';
import {NavigationContainer} from '@react-navigation/native';
import {createStackNavigator} from '@react-navigation/stack';
import SplashView from './view/SplashView';
import LoginView from './view/LoginView';
import MainView from './view/MainView';
import SignUpView from './view/SignUpView';
import CertificationView from './view/CertificationView';
import FindIdView from './view/FindIdView';
import FindPwdView from './view/FindPwdView';
import TeamDetailView from './view/TeamDetailView';
import LeagueDetailView from './view/LeagueDetailView';
import BoxScoreView from './view/BoxScoreView';
import BoardWriteView from './view/BoardWriteView';
import CommunityWriteView from './view/CommunityWriteView';
import BoardDetailView from './view/BoardDetailView';
import LeagueInfoEditView from './view/LeagueInfoEditView';
import MemberPageView from './view/MemberPageView';
import SettingView from './view/SettingView';
import CustomerServiceView from './view/CustomerServiceView';
import NoticeView from './view/NoticeView';
import SettingAlarmView from './view/SettingAlarmView';
import MyAccountView from './view/MyAccountView';
import ChangePasswordView from './view/ChangePasswordView';
import PlayerInfoView from './view/PlayerInfoView';
import MyAlarmListView from './view/MyAlarmListView';
import MyBoardView from './view/MyBoardView';
import PlayerCardListView from './view/PlayerCardListView';
import PlayerCardDetailView from './view/PlayerCardDetailView';
import CommunityDetailView from './view/CommunityDetailView';
import {
  SafeAreaProvider,
  useSafeAreaInsets,
} from 'react-native-safe-area-context';
import AsyncStorage from '@react-native-async-storage/async-storage';
import VideoView from './view/VideoView';
import MyPlayView from './view/MyPlayView';
import SearchKeywordHistoryView from './view/SearchKeywordHistoryView';
import CommentView from './view/CommentView';
import CommentChildView from './view/CommentChildView';
import EditCommentView from './view/EditCommentView';
import ReportView from './view/ReportView';
import MemberApproveListView from './view/MemberApproveListView';
import ManagementMemberList from './component/team/ManagementMemberList';
import HomeView from './view/HomeView';
import LeagueView from './view/LeagueView';
import WebMainView from './view/WebMainView';
import WebApplyView from './view/WebApplyView';
import TermsView from './view/TermsView';
import PrivacyView from './view/PrivacyView';
import BlockUserView from './view/BlockUserView';
import {title} from './common/util';
import FaqView from './view/FaqView';
import ExternalRecordView from './view/ExternalRecordView';
const Stack = createStackNavigator();

const useMount = func => useEffect(() => func(), []);

const handleUrl = url => {
  try {
    if (url.indexOf('uniqueplay://video') <= -1) {
      return;
    }
    const queries = url
      .split('uniqueplay://video')[1]
      .replace('?', '')
      .split('&');
    console.log('queries', queries);
    AsyncStorage.setItem(
      '@deeplink',
      JSON.stringify({
        gameId: queries[0].split('=')[1],
        time: queries[1].split('=')[1],
        cam: queries[2].split('=')[1],
      }),
      () => {
        console.log('딥 링크 저장 완료');
        DeviceEventEmitter.emit('@deepLinkUpdated');
      },
    );
  } catch (e) {
    console.error(e);
  }

  try {
    if (url.indexOf('uniqueplay://community') <= -1) {
      return;
    }
    const queries = url
      .split('uniqueplay://community')[1]
      .replace('?', '')
      .split('&');
    console.log('queries', queries);
    AsyncStorage.setItem(
      '@deeplink',
      JSON.stringify({
        communityId: queries[0].split('=')[1],
      }),
      () => {
        console.log('딥 링크 저장 완료');
        DeviceEventEmitter.emit('@deepLinkUpdated');
      },
    );
  } catch (e) {
    console.error(e);
  }

  try {
    if (url.indexOf('uniqueplay://playerCard') <= -1) {
      return;
    }
    const queries = url
      .split('uniqueplay://playerCard')[1]
      .replace('?', '')
      .split('&');
    console.log('queries', queries);
    AsyncStorage.setItem(
      '@deeplink',
      JSON.stringify({
        playerCardId: queries[0].split('=')[1],
      }),
      () => {
        console.log('딥 링크 저장 완료');
        DeviceEventEmitter.emit('@deepLinkUpdated');
      },
    );
  } catch (e) {
    console.error(e);
  }
};

const useInitialURL = () => {
  const [url, setUrl] = useState(null);
  const [processing, setProcessing] = useState(true);

  useMount(() => {
    const getUrlAsync = async () => {
      // Get the deep link used to open the app
      const url = await Linking.getInitialURL();
      console.log('initialUrl', url);
      if (url) {
        handleUrl(url);
      }
    };

    getUrlAsync();
  });

  return {url, processing};
};

export default function App() {
  const navigationRef = React.createRef();
  const {url: initialUrl, processing} = useInitialURL();

  const linkingSubscription = useRef();

  useEffect(() => {
    Text.defaultProps = Text.defaultProps || {};
    Text.defaultProps.allowFontScaling = false;

    const onReceiveURL = event => {
      console.log('onReceiveURL');
      const {url} = event;
      return handleUrl(url);
    };

    linkingSubscription.current = Linking.addEventListener('url', onReceiveURL);
    return () => {
      try {
        linkingSubscription.current.remove();
      } catch (e) {
        console.error(e);
      }
    };
  }, []);

  const linkingOption = useMemo(() => {
    if (Platform.OS !== 'web') {
      return {};
    }
    return {
      screens: {
        WebMain: '/',
        WebApply: '/apply',
        Terms: '/terms',
        Privacy: '/privacy',
        Login: '/login',
        Splash: '/splash',
        SignUp: '/signUp',
        FindId: '/findId',
        FindPwd: '/findPwd',
        Certification: '/certification',
        SettingAlarm: '/settingAlarm',
        Notice: '/notice',
        PlayerInfo: '/playerInfo',
        ChangePassword: '/changePassword',
        MyAccount: '/myAccount',
        MyAlarmList: '/myAlarmList',
        MyPlay: '/myPlay',
        Main: {
          initialRouteName: 'home',
          screens: {
            Home: 'home',
            League: 'league',
            Team: 'team',
            Community: 'community',
            MyPage: 'myPage',
          },
        },
        LeagueDetail: {
          path: 'league/:itemId',
          parse: {
            itemId: itemId => `${itemId}`,
          },
        },
        LeagueInfoEdit: {
          path: 'league/:leagueId/edit',
          parse: {
            leagueId: leagueId => `${leagueId}`,
          },
        },
        TeamDetail: {
          path: 'team/:itemId',
          parse: {
            itemId: itemId => `${itemId}`,
          },
        },
        TeamMemberList: {
          path: 'team/:itemId/mangement/members',
          parse: {
            itemId: itemId => `${itemId}`,
          },
        },
        MemberApproveList: {
          path: 'team/:itemId/mangement/requests',
          parse: {
            itemId: itemId => `${itemId}`,
          },
        },
        Video: {
          path: 'game/:itemId/video',
          parse: {
            itemId: itemId => `${itemId}`,
          },
        },
        BoxScore: {
          path: 'game/:itemId/boxscore',
          parse: {
            itemId: itemId => `${itemId}`,
          },
        },
        BoardWrite: '/boardWrite',
        BoardDetail: {
          path: 'board/:itemId',
          parse: {
            itemId: itemId => `${itemId}`,
          },
        },
        CommunityWrite: '/communityWrite',
        CommunityDetail: {
          path: 'community/:itemId',
          parse: {
            itemId: itemId => `${itemId}`,
          },
        },
        Comment: {
          path: 'community/:boardId/comment',
          parse: {
            boardId: boardId => `${boardId}`,
          },
        },
        CommentChild: {
          path: 'community/comments/:parentId/commentChild',
          parse: {
            itemId: parentId => `${parentId}`,
          },
        },
        EditComment: {
          path: 'community/:itemId/edit',
          parse: {
            itemId: itemId => `${itemId}`,
          },
        },
        Report: {
          path: 'community/report',
        },
        MemberPage: {
          path: 'user/:itemId',
          parse: {
            itemId: itemId => `${itemId}`,
          },
        },
        PlayerCardList: {
          path: 'user/:itemId/playerCardList',
          parse: {
            itemId: itemId => `${itemId}`,
          },
        },
        PlayerCardDetail: {
          path: 'playerCard/:itemId',
          parse: {
            itemId: itemId => `${itemId}`,
          },
        },
        Setting: '/setting',
        CustomerService: '/customerService',
        SearchKeywordHistory: '/searchKeywordHistory',
      },
    };
  }, []);

  return (
    <SafeAreaProvider>
      <NavigationContainer
        linking={{
          config: linkingOption,
        }}
        ref={navigationRef}>
        <Stack.Navigator initialRouteName="Splash">
          <Stack.Screen
            name="Splash"
            component={SplashView}
            options={{
              headerShown: false,
              title: Platform.select({
                web: `유니크플레이 | 로딩`,
                default: '로딩',
              }),
            }}
          />
          <Stack.Screen
            name="Login"
            component={LoginView}
            options={{headerShown: false, title: title('로그인')}}
          />
          <Stack.Screen
            name="Main"
            component={MainView}
            options={{headerShown: false}}
          />
          <Stack.Screen
            name="TeamDetail"
            component={TeamDetailView}
            options={{headerShown: false}}
          />
          <Stack.Screen
            name="TeamMemberList"
            component={ManagementMemberList}
            options={{headerShown: false}}
          />
          <Stack.Screen
            name="MemberApproveList"
            component={MemberApproveListView}
            options={{headerShown: false}}
          />
          <Stack.Screen
            name="LeagueDetail"
            component={LeagueDetailView}
            options={{headerShown: false}}
          />
          <Stack.Screen
            name="BoxScore"
            component={BoxScoreView}
            options={{headerShown: false}}
          />
          <Stack.Screen
            name="BoardWrite"
            component={BoardWriteView}
            options={{headerShown: false}}
          />
          <Stack.Screen
            name="CommunityWrite"
            component={CommunityWriteView}
            options={{
              headerShown: false,
              title: Platform.select({
                web: `유니크플레이 | ${'커뮤니티 글 작성'}`,
                default: '커뮤니티 글 작성',
              }),
            }}
          />
          <Stack.Screen
            name="CommunityDetail"
            component={CommunityDetailView}
            options={{headerShown: false, title: title('커뮤니티')}}
          />
          <Stack.Screen
            name="BoardDetail"
            component={BoardDetailView}
            options={{headerShown: false}}
          />
          <Stack.Screen
            name="LeagueInfoEdit"
            component={LeagueInfoEditView}
            options={{headerShown: false}}
          />
          <Stack.Screen
            name="Video"
            component={VideoView}
            options={{headerShown: false}}
          />
          <Stack.Screen
            name="MyPlay"
            component={MyPlayView}
            options={{
              headerShown: false,
              title: Platform.select({
                web: `유니크플레이 | 가입`,
                default: '가입',
              }),
            }}
          />
          <Stack.Screen
            name="SignUp"
            component={SignUpView}
            options={{headerShown: false}}
          />
          <Stack.Screen
            name="FindId"
            component={FindIdView}
            options={{headerShown: false}}
          />
          <Stack.Screen
            name="Certification"
            component={CertificationView}
            options={{
              headerShown: false,
              title: Platform.select({
                web: `유니크플레이 | 본인인증`,
                default: '본인인증',
              }),
            }}
          />
          <Stack.Screen
            name="FindPwd"
            component={FindPwdView}
            options={{headerShown: false}}
          />
          <Stack.Screen
            name="MemberPage"
            component={MemberPageView}
            options={{headerShown: false}}
          />
          <Stack.Screen
            name="Setting"
            component={SettingView}
            options={{headerShown: false, title: title('설정')}}
          />
          <Stack.Screen
            name="MyAccount"
            component={MyAccountView}
            options={{headerShown: false}}
          />
          <Stack.Screen
            name="ChangePassword"
            component={ChangePasswordView}
            options={{headerShown: false}}
          />
          <Stack.Screen
            name="PlayerInfo"
            component={PlayerInfoView}
            options={{headerShown: false}}
          />
          <Stack.Screen
            name="CustomerService"
            component={CustomerServiceView}
            options={{headerShown: false}}
          />
          <Stack.Screen
            name="SettingAlarm"
            component={SettingAlarmView}
            options={{headerShown: false}}
          />
          <Stack.Screen
            name="Notice"
            component={NoticeView}
            options={{headerShown: false, title: title('공지사항')}}
          />
          <Stack.Screen
            name="MyAlarmList"
            component={MyAlarmListView}
            options={{headerShown: false}}
          />
          <Stack.Screen
            name="MyBoard"
            component={MyBoardView}
            options={{headerShown: false}}
          />
          <Stack.Screen
            name="PlayerCardList"
            component={PlayerCardListView}
            options={{headerShown: false}}
          />
          <Stack.Screen
            name="PlayerCardDetail"
            component={PlayerCardDetailView}
            options={{headerShown: false}}
          />
          <Stack.Screen
            name="SearchKeywordHistory"
            component={SearchKeywordHistoryView}
            options={{headerShown: false}}
          />
          <Stack.Screen
            name="Comment"
            component={CommentView}
            options={{headerShown: false}}
          />
          <Stack.Screen
            name="CommentChild"
            component={CommentChildView}
            options={{headerShown: false, title: title('커뮤니티 댓글 쓰기')}}
          />
          <Stack.Screen
            name="EditComment"
            component={EditCommentView}
            options={{headerShown: false}}
          />
          <Stack.Screen
            name="Report"
            component={ReportView}
            options={{headerShown: false}}
          />
          <Stack.Screen
            name="Home"
            component={HomeView}
            options={{headerShown: false}}
          />
          <Stack.Screen
            name="League"
            component={LeagueView}
            options={{headerShown: false}}
          />
          <Stack.Screen
            name="Terms"
            component={TermsView}
            options={{headerShown: false}}
          />
          <Stack.Screen
            name="Faq"
            component={FaqView}
            options={{
              headerShown: false,
              title: Platform.select({
                web: `유니크플레이 | FAQ`,
                default: 'FAQ',
              }),
            }}
          />
          <Stack.Screen
            name="Privacy"
            component={PrivacyView}
            options={{headerShown: false}}
          />
          <Stack.Screen
            name="BlockUser"
            component={BlockUserView}
            options={{
              headerShown: false,
              title: Platform.select({
                web: `유니크플레이 | 차단 사용자 관리`,
                default: '차단 사용자 관리',
              }),
            }}
          />
          <Stack.Screen
            name="WebMain"
            component={WebMainView}
            options={{
              headerShown: false,
              title: Platform.select({
                web: `유니크플레이 | 메인`,
                default: '메인',
              }),
            }}
          />
          <Stack.Screen
            name="WebApply"
            component={WebApplyView}
            options={{
              headerShown: false,
              title: Platform.select({
                web: `유니크플레이 | 영상신청`,
                default: '영상신청',
              }),
            }}
          />
          <Stack.Screen
            name="ExternalRecord"
            component={ExternalRecordView}
            options={{headerShown: false}}
          />
        </Stack.Navigator>
      </NavigationContainer>
    </SafeAreaProvider>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

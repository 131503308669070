import React, {useEffect, useState} from 'react';
import {Image, SafeAreaView, Linking, Platform} from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import api from '../common/api';
import Constants from 'expo-constants';
import {title} from '../common/util';

function SplashView({children, navigation}) {
  useEffect(() => {
    if (Platform.OS === 'web') {
      goToMain();
      return;
    }
    api
      .get('/home/app-version')
      .then(function (response) {
        console.log(response.data, response.headers);
        const lastVersion = response.data;
        console.log('lastVersion', lastVersion);
        console.log('lastVersion', lastVersion[Platform.OS]);
        let deviceVersion = Constants.version;
        if (lastVersion[Platform.OS] > deviceVersion) {
          alert(
            '앱 업데이트가 필요한 버전을 사용 중입니다. 앱스토어에서 업데이트를 진행해 주세요.',
          );
          if (Platform.OS === 'android') {
            Linking.openURL(
              'https://play.google.com/store/apps/details?id=com.uniqueplay',
            );
          } else if (Platform.OS === 'ios') {
            Linking.openURL('https://itunes.apple.com/app/1660453329');
          }
        } else {
          goToMain();
        }
      })
      .catch(function (error) {
        console.error(error);
      });
  }, []);

  const goToMain = () => {
    AsyncStorage.getItem('@accessToken', (err, result) => {
      if (Platform.OS === 'web') {
        if (result) {
          navigation.reset({
            index: 0,
            routes: [{name: 'Main'}],
          });
        } else {
          navigation.reset({
            index: 0,
            routes: [{name: 'WebMain'}],
          });
        }
        return;
      }
      setTimeout(() => {
        if (result) {
          navigation.reset({
            index: 0,
            routes: [{name: 'Main'}],
          });
        } else {
          navigation.reset({
            index: 0,
            routes: [{name: 'Login'}],
          });
        }
      }, 2000);
    });
  };

  useEffect(() => {}, []);

  return (
    <SafeAreaView
      nativeID="common-page-container"
      style={{
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'white',
      }}>
      <Image
        source={require('../assets/images/appicon.png')}
        style={{width: 180, height: 180, resizeMode: 'contain'}}
      />
    </SafeAreaView>
  );
}

export default SplashView;

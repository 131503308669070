import React, {useState, useEffect, useRef} from 'react';
import {
  Image,
  SafeAreaView,
  Text,
  TouchableOpacity,
  View,
  StyleSheet,
  ImageBackground,
  Dimensions,
  Linking,
  Platform,
} from 'react-native';
import styles from '../common/styles';
import {Shadow} from 'react-native-shadow-2';
import {ScrollView} from 'react-native-gesture-handler';
import AsyncStorage from '@react-native-async-storage/async-storage';
import api from '../common/api';
import Colors from '../common/colors';
import {title} from '../common/util';

const SettingAlarmView = ({navigation, route}) => {
  const [alarmSetting, setAlarmSetting] = useState({});
  let accessToken = useRef();

  const getData = () => {
    api
      .get('/app-user/my-setting-alarms', {
        headers: {
          Authorization: `Bearer ${accessToken.current}`,
        },
      })
      .then(function (response) {
        setAlarmSetting(response.data ? response.data : {});
      })
      .catch(function (error) {
        console.log(error.response);
      });
  };

  useEffect(() => {
    AsyncStorage.getItem('@accessToken', (err, result) => {
      if (err) {
        console.error(err);
        return;
      }
      accessToken.current = result;
      getData();
    });
  }, []);

  const toggleAlarm = type => {
    const prior = JSON.parse(JSON.stringify(alarmSetting));
    if (prior[type]) {
      prior[type] = false;
    } else {
      prior[type] = true;
    }
    if (type === 'agree') {
      for (var key in prior) {
        if (key === 'agree') {
          continue;
        }
        prior[key] = prior[type];
      }
    } else if (type === 'communityOverall') {
      prior.communityFree = prior[type];
      prior.communityRecruitPlayer = prior[type];
      prior.communityRecruitTeam = prior[type];
    } else if (type === 'leagueOverall') {
      prior.leagueNotice = prior[type];
      prior.leagueModifyRecord = prior[type];
      prior.leagueFreeBoard = prior[type];
    } else if (type === 'myRecordOverall') {
      prior.myRecordUpdate = prior[type];
      prior.myBattingVideoUpdate = prior[type];
    } else if (type === 'favoriteOverall') {
      prior.favoriteGameEnd = prior[type];
      prior.favoritePlayerRecordUpdate = prior[type];
    }
    setAlarmSetting(prior);
    api
      .post('/app-user/setting-alarm', prior, {
        headers: {
          Authorization: `Bearer ${accessToken.current}`,
        },
      })
      .then(function (response) {
        setAlarmSetting(response.data);
      })
      .catch(function (error) {
        console.log(error.response);
      });
  };

  return (
    <SafeAreaView nativeID="common-page-container" style={[styles.container]}>
      <Shadow
        offset={[0, 2]}
        style={[{width: '100%'}]}
        containerStyle={[{marginTop: 0}]}
        distance={7}
        sides={{bottom: true, top: false, start: false, end: false}}>
        <View style={[styles.headerCommon]}>
          <View style={[styles.row, styles.verticalAlign]}>
            <TouchableOpacity
              onPress={() =>
                Platform.OS === 'web' ? history.back() : navigation.goBack()
              }>
              <Image
                source={require('../assets/images/icon_back.png')}
                style={[styles.headerBackButton]}
              />
            </TouchableOpacity>
            <Text style={[styles.headerText]}>알림</Text>
          </View>
          <View style={[styles.row, styles.verticalAlign]} />
        </View>
      </Shadow>
      <ScrollView style={[pageStyle.container]}>
        <View style={[pageStyle.innerContainer]}>
          <TouchableOpacity
            onPress={() => toggleAlarm('agree')}
            style={[pageStyle.row, pageStyle.bottomBorder]}>
            <Text style={[pageStyle.subtitle]}>푸시 알림 동의</Text>
            <View
              style={[
                pageStyle.switch,
                alarmSetting?.agree && pageStyle.switchOn,
              ]}>
              <View
                style={[
                  pageStyle.switchToggle,
                  !alarmSetting?.agree && pageStyle.switchToggleOff,
                  alarmSetting?.agree && pageStyle.switchToggleOn,
                ]}
              />
            </View>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => toggleAlarm('communityOverall')}
            style={[pageStyle.row, pageStyle.bottomBorder]}>
            <Text style={[pageStyle.subtitle]}>커뮤니티 나의 글 알림 설정</Text>
            <View
              style={[
                pageStyle.switch,
                alarmSetting?.communityOverall && pageStyle.switchOn,
              ]}>
              <View
                style={[
                  pageStyle.switchToggle,
                  !alarmSetting?.communityOverall && pageStyle.switchToggleOff,
                  alarmSetting?.communityOverall && pageStyle.switchToggleOn,
                ]}
              />
            </View>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => toggleAlarm('communityFree')}
            style={[pageStyle.row]}>
            <Text style={[pageStyle.txt]}>자유게시판</Text>
            <View
              style={[
                pageStyle.switch,
                alarmSetting?.communityFree && pageStyle.switchOn,
              ]}>
              <View
                style={[
                  pageStyle.switchToggle,
                  !alarmSetting?.communityFree && pageStyle.switchToggleOff,
                  alarmSetting?.communityFree && pageStyle.switchToggleOn,
                ]}
              />
            </View>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => toggleAlarm('communityRecruitPlayer')}
            style={[pageStyle.row]}>
            <Text style={[pageStyle.txt]}>선수모집</Text>
            <View
              style={[
                pageStyle.switch,
                alarmSetting?.communityRecruitPlayer && pageStyle.switchOn,
              ]}>
              <View
                style={[
                  pageStyle.switchToggle,
                  !alarmSetting?.communityRecruitPlayer &&
                    pageStyle.switchToggleOff,
                  alarmSetting?.communityRecruitPlayer &&
                    pageStyle.switchToggleOn,
                ]}
              />
            </View>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => toggleAlarm('communityRecruitTeam')}
            style={[pageStyle.row]}>
            <Text style={[pageStyle.txt]}>팀모집</Text>
            <View
              style={[
                pageStyle.switch,
                alarmSetting?.communityRecruitTeam && pageStyle.switchOn,
              ]}>
              <View
                style={[
                  pageStyle.switchToggle,
                  !alarmSetting?.communityRecruitTeam &&
                    pageStyle.switchToggleOff,
                  alarmSetting?.communityRecruitTeam &&
                    pageStyle.switchToggleOn,
                ]}
              />
            </View>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => toggleAlarm('leagueOverall')}
            style={[pageStyle.row, pageStyle.bottomBorder]}>
            <Text style={[pageStyle.subtitle]}>나의 리그 알림 설정</Text>
            <View
              style={[
                pageStyle.switch,
                alarmSetting?.leagueOverall && pageStyle.switchOn,
              ]}>
              <View
                style={[
                  pageStyle.switchToggle,
                  !alarmSetting?.leagueOverall && pageStyle.switchToggleOff,
                  alarmSetting?.leagueOverall && pageStyle.switchToggleOn,
                ]}
              />
            </View>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => toggleAlarm('leagueNotice')}
            style={[pageStyle.row]}>
            <Text style={[pageStyle.txt]}>공지사항</Text>
            <View
              style={[
                pageStyle.switch,
                alarmSetting?.leagueNotice && pageStyle.switchOn,
              ]}>
              <View
                style={[
                  pageStyle.switchToggle,
                  !alarmSetting?.leagueNotice && pageStyle.switchToggleOff,
                  alarmSetting?.leagueNotice && pageStyle.switchToggleOn,
                ]}
              />
            </View>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => toggleAlarm('leagueModifyRecord')}
            style={[pageStyle.row]}>
            <Text style={[pageStyle.txt]}>기록수정 게시판</Text>
            <View
              style={[
                pageStyle.switch,
                alarmSetting?.leagueModifyRecord && pageStyle.switchOn,
              ]}>
              <View
                style={[
                  pageStyle.switchToggle,
                  !alarmSetting?.leagueModifyRecord &&
                    pageStyle.switchToggleOff,
                  alarmSetting?.leagueModifyRecord && pageStyle.switchToggleOn,
                ]}
              />
            </View>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => toggleAlarm('leagueFreeBoard')}
            style={[pageStyle.row]}>
            <Text style={[pageStyle.txt]}>자유게시판</Text>
            <View
              style={[
                pageStyle.switch,
                alarmSetting?.leagueFreeBoard && pageStyle.switchOn,
              ]}>
              <View
                style={[
                  pageStyle.switchToggle,
                  !alarmSetting?.leagueFreeBoard && pageStyle.switchToggleOff,
                  alarmSetting?.leagueFreeBoard && pageStyle.switchToggleOn,
                ]}
              />
            </View>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => toggleAlarm('myRecordOverall')}
            style={[pageStyle.row, pageStyle.bottomBorder]}>
            <Text style={[pageStyle.subtitle]}>나의 기록/영상 알림 설정</Text>
            <View
              style={[
                pageStyle.switch,
                alarmSetting?.myRecordOverall && pageStyle.switchOn,
              ]}>
              <View
                style={[
                  pageStyle.switchToggle,
                  !alarmSetting?.myRecordOverall && pageStyle.switchToggleOff,
                  alarmSetting?.myRecordOverall && pageStyle.switchToggleOn,
                ]}
              />
            </View>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => toggleAlarm('myRecordUpdate')}
            style={[pageStyle.row]}>
            <Text style={[pageStyle.txt]}>기록 업데이트</Text>
            <View
              style={[
                pageStyle.switch,
                alarmSetting?.myRecordUpdate && pageStyle.switchOn,
              ]}>
              <View
                style={[
                  pageStyle.switchToggle,
                  !alarmSetting?.myRecordUpdate && pageStyle.switchToggleOff,
                  alarmSetting?.myRecordUpdate && pageStyle.switchToggleOn,
                ]}
              />
            </View>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => toggleAlarm('myBattingVideoUpdate')}
            style={[pageStyle.row]}>
            <Text style={[pageStyle.txt]}>타석별 영상</Text>
            <View
              style={[
                pageStyle.switch,
                alarmSetting?.myBattingVideoUpdate && pageStyle.switchOn,
              ]}>
              <View
                style={[
                  pageStyle.switchToggle,
                  !alarmSetting?.myBattingVideoUpdate &&
                    pageStyle.switchToggleOff,
                  alarmSetting?.myBattingVideoUpdate &&
                    pageStyle.switchToggleOn,
                ]}
              />
            </View>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => toggleAlarm('favoriteOverall')}
            style={[pageStyle.row, pageStyle.bottomBorder]}>
            <Text style={[pageStyle.subtitle]}>관심팀/선수 알림 설정</Text>
            <View
              style={[
                pageStyle.switch,
                alarmSetting?.favoriteOverall && pageStyle.switchOn,
              ]}>
              <View
                style={[
                  pageStyle.switchToggle,
                  !alarmSetting?.favoriteOverall && pageStyle.switchToggleOff,
                  alarmSetting?.favoriteOverall && pageStyle.switchToggleOn,
                ]}
              />
            </View>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => toggleAlarm('favoriteGameEnd')}
            style={[pageStyle.row]}>
            <Text style={[pageStyle.txt]}>관심팀 경기 종료</Text>
            <View
              style={[
                pageStyle.switch,
                alarmSetting?.favoriteGameEnd && pageStyle.switchOn,
              ]}>
              <View
                style={[
                  pageStyle.switchToggle,
                  !alarmSetting?.favoriteGameEnd && pageStyle.switchToggleOff,
                  alarmSetting?.favoriteGameEnd && pageStyle.switchToggleOn,
                ]}
              />
            </View>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => toggleAlarm('favoritePlayerRecordUpdate')}
            style={[pageStyle.row]}>
            <Text style={[pageStyle.txt]}>관심선수 기록 업데이트</Text>
            <View
              style={[
                pageStyle.switch,
                alarmSetting?.favoritePlayerRecordUpdate && pageStyle.switchOn,
              ]}>
              <View
                style={[
                  pageStyle.switchToggle,
                  !alarmSetting?.favoritePlayerRecordUpdate &&
                    pageStyle.switchToggleOff,
                  alarmSetting?.favoritePlayerRecordUpdate &&
                    pageStyle.switchToggleOn,
                ]}
              />
            </View>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => toggleAlarm('service')}
            style={[pageStyle.row, pageStyle.bottomBorder]}>
            <Text style={[pageStyle.subtitle]}>서비스 알림 수신 설정</Text>
            <View
              style={[
                pageStyle.switch,
                alarmSetting?.service && pageStyle.switchOn,
              ]}>
              <View
                style={[
                  pageStyle.switchToggle,
                  !alarmSetting?.service && pageStyle.switchToggleOff,
                  alarmSetting?.service && pageStyle.switchToggleOn,
                ]}
              />
            </View>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => toggleAlarm('ad')}
            style={[pageStyle.row, pageStyle.bottomBorder]}>
            <Text style={[pageStyle.subtitle]}>광고성 정보 수신 설정</Text>
            <View
              style={[
                pageStyle.switch,
                alarmSetting?.ad && pageStyle.switchOn,
              ]}>
              <View
                style={[
                  pageStyle.switchToggle,
                  !alarmSetting?.ad && pageStyle.switchToggleOff,
                  alarmSetting?.ad && pageStyle.switchToggleOn,
                ]}
              />
            </View>
          </TouchableOpacity>
          <TouchableOpacity style={[pageStyle.btn]}>
            <Text style={[pageStyle.btnTxt]}>확인</Text>
          </TouchableOpacity>
        </View>
      </ScrollView>
    </SafeAreaView>
  );
};

const pageStyle = StyleSheet.create({
  headerCommon: {},
  navBgImage: {
    marginStart: 0,
    position: 'absolute',
    width: Dimensions.get('window').width,
    height: '100%',
  },
  headerText: {color: 'white'},
  manageTeamBtn: {
    width: 60,
    height: 22,
    borderRadius: 12,
    borderWidth: 1,
    borderColor: 'white',
    backgroundColor: 'transparent',
    justifyContent: 'center',
    alignItems: 'center',
    marginEnd: 14,
  },
  container: {
    paddingBottom: 80,
    height: '100%',
  },
  innerContainer: {
    paddingBottom: 45,
  },
  kakaoRow: {
    borderBottomColor: '#e6e6e6',
    borderBottomWidth: 1,
  },
  kakaoBtn: {
    width: 300,
    height: 100,
    alignSelf: 'center',
    marginVertical: 24,
  },
  row: {
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignContent: 'center',
    alignItems: 'center',
    paddingVertical: 12,
    paddingHorizontal: 36,
  },
  bottomBorder: {
    borderBottomColor: '#e6e6e6',
    borderBottomWidth: 1,
    paddingVertical: 20,
    paddingTop: 32,
  },
  subtitle: {
    fontFamily: 'Pretendard-SemiBold',
    fontSize: 18,
    fontWeight: '600',
    color: 'black',
  },
  txt: {
    fontFamily: 'Pretendard',
    fontSize: 16,
    color: 'black',
    marginStart: 20,
  },
  logoTxt: {
    fontFamily: 'Pretendard-Bold',
    fontWeight: 'bold',
    color: 'black',
    fontSize: 13,
    marginBottom: 6,
  },
  switch: {
    width: 55,
    height: 26,
    borderRadius: 12,
    backgroundColor: '#d8d8d8',
    position: 'relative',
  },
  switchOn: {
    backgroundColor: '#3f51b5',
  },
  switchToggle: {
    height: 20,
    width: 20,
    backgroundColor: 'white',
    borderRadius: 10,
    position: 'absolute',
    top: 3,
  },
  switchToggleOff: {
    start: 3,
  },
  switchToggleOn: {
    end: 3,
  },
  btn: {
    backgroundColor: Colors.main,
    height: 50,
    marginHorizontal: 24,
    borderRadius: 22.5,
    marginTop: 36,
  },
  btnTxt: {
    color: 'white',
    alignSelf: 'center',
    fontFamily: 'Pretendard',
    fontSize: 18,
    lineHeight: 50,
  },
});

export default SettingAlarmView;

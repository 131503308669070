import React, {useState, useEffect, useRef} from 'react';
import {
  StyleSheet,
  View,
  Dimensions,
  Modal,
  Text,
  Image,
  TouchableOpacity,
  TextInput,
  ImageBackground,
  DeviceEventEmitter,
  KeyboardAvoidingView,
  Platform,
} from 'react-native';
import styles from '../common/styles';
import {useSafeAreaInsets} from 'react-native-safe-area-context';
import Colors from '../common/colors';
import SelectDropdown from 'react-native-select-dropdown';
import AsyncStorage from '@react-native-async-storage/async-storage';
import api from '../common/api';
import {launchImageLibrary} from 'react-native-image-picker';

const CreateTeamPopup = props => {
  const insets = useSafeAreaInsets();
  const [createdTeam, setCreatedTeam] = useState({});
  const [teamCreated, setTeamCreated] = useState(false);
  const [topRegions, setTopRegions] = useState([]);
  const [secondRegions, setSecondRegions] = useState([]);

  const [profileImageUri, setProfileImageUri] = useState('');
  const [profileImageData, setProfileImageData] = useState();

  const [name, setName] = useState();

  const processing = useRef();
  const accessToken = useRef();
  const selectedRegion = useRef();

  const openImageLibrary = () => {
    launchImageLibrary(
      {
        mediaType: 'photo',
        includeBase64: true,
        maxHeight: 500,
        maxWidth: 500,
      },
      response => {
        if (
          !response.errorCode &&
          response.assets &&
          response.assets.length > 0 &&
          response.assets[0] &&
          response.assets[0].base64
        ) {
          setProfileImageUri(response.assets[0].uri);
          setProfileImageData(
            'data:image/png;base64,' + response.assets[0].base64,
          );
        }
      },
    );
  };

  const createTeam = () => {
    if (!name.trim()) {
      alert('이름을 입력해 주세요.');
      return;
    }

    if (name.length > 30) {
      alert('팀명은 30자를 초과할 수 없습니다.');
      return;
    }
    if (!selectedRegion.value) {
      alert('팀 연고를 선택해 주세요.');
      return;
    }

    if (processing.current) {
      return;
    }

    processing.current = true;
    api
      .post(
        '/teams',
        {
          name: name.trim(),
          regionId: selectedRegion.value.id,
          imageData: profileImageData,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken.current}`,
          },
        },
      )
      .then(function (response) {
        setCreatedTeam(response.data);
        setTeamCreated(true);
        DeviceEventEmitter.emit('teamTabInfoRefresh', {});
      })
      .catch(function (error) {
        console.log(error.response.data);
        if (error.response?.data?.message) {
          alert(error.response.data.message);
        } else {
          alert('요청에 실패했습니다.');
        }
      })
      .finally(() => {
        processing.current = false;
      });
  };

  const getData = token => {
    api
      .get('/regions', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(function (response) {
        setTopRegions(response.data);
        setSecondRegions([]);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getRegionData = parentId => {
    api
      .get(`/regions?parentId=${parentId}`, {
        headers: {
          Authorization: `Bearer ${accessToken.current}`,
        },
      })
      .then(function (response) {
        setSecondRegions(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    AsyncStorage.getItem('@accessToken', (err, result) => {
      accessToken.current = result;
      getData(result);
    });
  }, []);

  return (
    <Modal transparent={true} overFullScreen={true}>
      <View style={[pageStyle.container]}>
        <View style={[pageStyle.innerContainer, {marginTop: insets.top + 67}]}>
          <View style={[styles.headerCommon]}>
            <View style={[styles.row, styles.verticalAlign]}>
              <TouchableOpacity onPress={() => props.closePopup()}>
                <Image
                  source={require('../assets/images/icon_back.png')}
                  style={[styles.headerBackButton]}
                />
              </TouchableOpacity>
            </View>
          </View>
          <View style={[pageStyle.contentsContainer]}>
            {!teamCreated && (
              <View style={[pageStyle.contentsInnerContainer]}>
                <TouchableOpacity
                  onPress={() => openImageLibrary()}
                  style={[pageStyle.logoContainer]}>
                  <ImageBackground
                    v-if={!!profileImageData}
                    resizeMode={'cover'}
                    source={{uri: profileImageData}}
                    style={[pageStyle.imageBg]}
                  />
                  {!profileImageData && (
                    <>
                      <Image
                        resizeMode={'cover'}
                        source={require('../assets/images/icn_plus.png')}
                        style={[pageStyle.plusIcon]}
                      />
                      <Text style={[pageStyle.registerBtnText]}>
                        팀 로고 등록
                      </Text>
                    </>
                  )}
                </TouchableOpacity>
                <TextInput
                  placeholder={'팀명 입력'}
                  placeholderTextColor={'#aaa'}
                  style={[pageStyle.input]}
                  onChangeText={value => setName(value)}
                />
                <Text style={[pageStyle.homeTownTxt]}>팀 연고</Text>
                <View style={[pageStyle.dropdownRowContainer]}>
                  <View style={[pageStyle.dropDownItemContainer]}>
                    <SelectDropdown
                      defaultButtonText="광역시/도"
                      buttonStyle={pageStyle.dropDown}
                      buttonTextStyle={pageStyle.downDownText}
                      rowTextStyle={styles.dropDownRowText}
                      renderDropdownIcon={() => (
                        <Image
                          source={require('../assets/images/select_arr.png')}
                        />
                      )}
                      data={topRegions}
                      onSelect={(selectedItem, index) => {
                        getRegionData(selectedItem.id);
                      }}
                      buttonTextAfterSelection={(selectedItem, index) => {
                        return selectedItem.sido;
                      }}
                      rowTextForSelection={(item, index) => {
                        return item.sido;
                      }}
                    />
                  </View>
                  <View style={[pageStyle.dropDownItemContainer]}>
                    <SelectDropdown
                      defaultButtonText="시/군/구"
                      buttonStyle={pageStyle.dropDown}
                      buttonTextStyle={pageStyle.downDownText}
                      rowTextStyle={styles.dropDownRowText}
                      renderDropdownIcon={() => (
                        <Image
                          source={require('../assets/images/select_arr.png')}
                        />
                      )}
                      data={secondRegions}
                      onSelect={(selectedItem, index) => {
                        selectedRegion.value = selectedItem;
                      }}
                      buttonTextAfterSelection={(selectedItem, index) => {
                        return selectedItem.sigungu;
                      }}
                      rowTextForSelection={(item, index) => {
                        return item.sigungu;
                      }}
                    />
                  </View>
                </View>
                <TouchableOpacity
                  onPress={() => createTeam()}
                  style={[pageStyle.createBtn]}>
                  <Text style={[pageStyle.btnText]}>팀 생성</Text>
                </TouchableOpacity>
              </View>
            )}
            {teamCreated && (
              <View style={[pageStyle.contentsInnerContainer]}>
                <Image
                  resizeMode={'cover'}
                  style={[pageStyle.newTeamIcn]}
                  source={require('../assets/images/new_team.png')}
                />
                <Text style={[pageStyle.teamCreatedTxt]}>
                  팀 개설을 축하드립니다!
                </Text>
                <TouchableOpacity
                  style={[pageStyle.moveBtn]}
                  onPress={() => {
                    props.navigation.navigate('TeamDetail', {
                      item: createdTeam,
                      itemId: createdTeam?.id,
                    });
                    props.closePopup();
                  }}>
                  <Text style={[pageStyle.moveBtnTxt]}>팀 페이지로 이동</Text>
                </TouchableOpacity>
              </View>
            )}
          </View>
        </View>
      </View>
    </Modal>
  );
};

export default CreateTeamPopup;
const pageStyle = StyleSheet.create({
  container: {
    zIndex: 100,
    width: '100%',
    height: Dimensions.get('window').height,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
  },
  innerContainer: {
    width: Platform.OS === 'web' ? 500 : '100%',
    backgroundColor: 'white',
    flex: 1,
    marginTop: 67,
  },
  contentsContainer: {
    alignItems: 'center',
    alignContent: 'center',
    flexDirection: 'column',
    display: 'flex',
    justifyContent: 'center',
    flex: 1,
  },
  contentsInnerContainer: {
    alignItems: 'center',
    marginTop: -100,
  },
  logoContainer: {
    width: 80,
    height: 80,
    backgroundColor: '#777',
    borderRadius: 40,
    alignItems: 'center',
  },
  imageBg: {
    backgroundColor: 'transparent',
    width: 80,
    height: 80,
    borderRadius: 40,
    overflow: 'hidden',
    position: 'absolute',
    top: 0,
  },
  plusIcon: {
    marginTop: 28,
    width: 21,
    height: 21,
  },
  registerBtnText: {
    color: 'white',
    fontSize: 10,
    marginTop: 6,
    fontWeight: '500',
    fontFamily: 'Pretendard-Medium',
  },
  input: {
    width: 132,
    borderBottomWidth: 1,
    borderBottomColor: 'black',
    textAlign: 'center',
    marginTop: 25,
    fontFamily: 'Pretendard',
    fontSize: 12,
    paddingBottom: 5,
  },
  homeTownTxt: {
    fontFamily: 'Pretendard',
    fontSize: 12,
    color: 'black',
    marginTop: 17,
  },
  createBtn: {
    width: 96,
    height: 34,
    backgroundColor: Colors.main,
    borderRadius: 17,
    alignItems: 'center',
    alignContent: 'center',
    justifyContent: 'center',
    marginTop: 65,
  },
  btnText: {
    color: 'white',
    fontSize: 18,
    fontFamily: 'Pretendard',
  },
  dropdownRowContainer: {
    display: 'flex',
    width: 222,
    marginTop: 6,
    flexDirection: 'row',
  },
  dropDownItemContainer: {
    marginHorizontal: 3,
    flex: 1,
  },
  dropDown: {
    width: '100%',
    backgroundColor: 'white',
    borderWidth: 1,
    borderColor: 'rgb(61, 80, 183)',
    height: 30,
  },
  downDownText: {
    color: 'black',
    fontSize: 11,
    fontFamily: 'Pretendard',
  },
  newTeamIcn: {
    width: 162,
    height: 162,
  },
  teamCreatedTxt: {
    color: '#3f51b5',
    fontSize: 21,
    fontWeight: '600',
    fontFamily: 'Pretendard-SemiBold',
    marginTop: 28,
  },
  moveBtn: {
    marginTop: 57,
    backgroundColor: '#3f51b5',
    paddingVertical: 8,
    paddingHorizontal: 24,
    borderRadius: 17,
  },
  moveBtnTxt: {
    color: 'white',
    fontSize: 18,
    fontFamily: 'Pretendard',
  },
});

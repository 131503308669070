import {
  DeviceEventEmitter,
  Image,
  Keyboard,
  Platform,
  StyleSheet,
  Text,
  TextInput,
  View,
} from 'react-native';
import {Dimensions} from 'react-native';
import styles from '../common/styles';
import {SafeAreaView} from 'react-native-safe-area-context';
import {Shadow} from 'react-native-shadow-2';
import {FlatList, TouchableOpacity} from 'react-native-gesture-handler';
import {useEffect, useRef, useState} from 'react';
import Colors from '../common/colors';
import SelectDropdown from 'react-native-select-dropdown';
import api from '../common/api';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {title} from '../common/util';

const SearchKeywordHistoryView = ({navigation}) => {
  let accessToken = useRef();

  useEffect(() => {
    AsyncStorage.getItem('@accessToken', (err, result) => {
      accessToken.current = result;
    });
    // AsyncStorage.removeItem('keywords');
    getKeywordList();
  }, []);

  const [keywordList, setKeywordList] = useState([]);
  const [keyword, setKeyword] = useState('');

  const getKeywordList = () => {
    AsyncStorage.getItem('keywords', (err, result) => {
      if (result != null) {
        setKeywordList(JSON.parse(result).reverse());
      } else {
        setKeywordList([]);
      }
    });
  };

  const insertKeywordHistory = keyword => {
    const item = [keyword];
    AsyncStorage.getItem('keywords', (err, result) => {
      if (result !== null) {
        if (!JSON.parse(result).includes(keyword)) {
          var newKeywords = JSON.parse(result).concat(item);
          if (newKeywords.length >= 10) {
            newKeywords = newKeywords.slice(1);
          }
          AsyncStorage.setItem('keywords', JSON.stringify(newKeywords));
        }
      } else {
        AsyncStorage.setItem('keywords', JSON.stringify(item));
      }
    });
  };

  const deleteKeyword = keyword => {
    AsyncStorage.getItem('keywords', (err, result) => {
      if (result !== null) {
        var keywords = JSON.parse(result);
        var filtered = keywords.filter(function (value, index, arr) {
          return value != keyword;
        });
        AsyncStorage.setItem('keywords', JSON.stringify(filtered));
        setKeywordList(filtered);
      }
    });
  };

  const validateInputs = keywordParam => {
    if (!keyword && !keywordParam) {
      alert('필수 값을 입력해 주세요.');
      return false;
    }

    return true;
  };

  const search = keywordParam => {
    if (!validateInputs(keywordParam)) {
      return;
    }
    console.log('keywordParam', keywordParam);
    console.log('keyword', keywordParam ? keywordParam : keyword);
    insertKeywordHistory(keywordParam ? keywordParam : keyword);
    Keyboard.dismiss();
    DeviceEventEmitter.emit('@communitySearchInput', {
      keyword: keywordParam ? keywordParam : keyword,
    });
    Platform.OS === 'web' ? history.back() : navigation.goBack();
  };

  const handleCancel = () => {};

  return (
    <SafeAreaView nativeID="common-page-container" style={[styles.container]}>
      <Shadow
        offset={[0, 2]}
        style={[{width: '100%'}]}
        containerStyle={[{marginTop: 0}]}
        distance={7}
        sides={{bottom: true, top: false, start: false, end: false}}>
        <View style={[styles.headerCommon]}>
          <View style={[styles.row, styles.verticalAlign]}>
            <TouchableOpacity
              onPress={() =>
                Platform.OS === 'web' ? history.back() : navigation.goBack()
              }>
              <Image
                source={require('../assets/images/icon_back.png')}
                style={[styles.headerBackButton]}
              />
            </TouchableOpacity>
            <View style={[pageStyle.searchInputContainer]}>
              <Image
                style={[pageStyle.icnSearchWhite]}
                source={require('../assets/images/icn_search_white.png')}
              />
              <TextInput
                style={[pageStyle.searchInput]}
                value={keyword}
                onChangeText={setKeyword}
                onSubmitEditing={({text, eventCount, target}) => search(text)}
                placeholder="검색어를 입력해 주세요"
                placeholderTextColor={'black'}
              />
            </View>
          </View>
          <View style={[styles.row, styles.verticalAlign]}>
            <TouchableOpacity onPress={handleCancel}>
              <Text style={[pageStyle.cancelTxt]}>취소</Text>
            </TouchableOpacity>
          </View>
        </View>
      </Shadow>
      <View style={[pageStyle.container]}>
        <FlatList
          data={keywordList}
          renderItem={({item, index}) => (
            <TouchableOpacity
              onPress={() => {
                setKeyword(item);
                search(item);
              }}>
              <View
                style={{
                  borderBottomColor: '#ddd',
                  borderBottomWidth: 1,
                  paddingHorizontal: 20,
                  paddingVertical: 10,
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}>
                <Text
                  style={{
                    fontFamily: 'Pretendard',
                    fontSize: 15,
                    color: '#424547',
                  }}>
                  {item}
                </Text>
                <TouchableOpacity onPress={() => deleteKeyword(item)}>
                  <Image
                    style={[{opacity: 0.4}]}
                    source={require('../assets/images/close.png')}
                  />
                </TouchableOpacity>
              </View>
            </TouchableOpacity>
          )}
          ListHeaderComponent={
            <View style={[pageStyle.headerContainer]}>
              <Text style={[pageStyle.headerTxt]}>최근 검색어</Text>
            </View>
          }
          ListEmptyComponent={() => (
            <View style={[styles.emptyContainer]}>
              <Image source={require('../assets/images/logo2.png')} />
            </View>
          )}
        />
      </View>
    </SafeAreaView>
  );
};

const pageStyle = StyleSheet.create({
  headerCommon: {},
  headerText: {color: 'white'},
  container: {
    paddingVertical: 24,
  },
  headerContainer: {
    borderBottomColor: '#ddd',
    borderBottomWidth: 1,
    paddingBottom: 12,
  },
  headerTxt: {
    color: '#999',
    marginHorizontal: 20,
  },
  row: {
    width: '100%',
    flexDirection: 'row',
    alignContent: 'center',
    alignItems: 'center',
    paddingVertical: 12,
    paddingHorizontal: 36,
    justifyContent: 'space-between',
  },
  subtitle: {
    fontFamily: 'Pretendard-Bold',
    fontSize: 16,
    color: 'black',
    fontWeight: 'bold',
  },
  dropDownContainer: {
    marginTop: 12,
    marginBottom: 24,
  },
  button: {
    marginTop: 42,
    height: 50,
    borderRadius: 25,
    backgroundColor: Colors.main,
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonTxt: {
    color: 'white',
    fontFamily: 'Pretendard',
    fontSize: 18,
  },
  searchInputContainer: {
    paddingHorizontal: 12,
    backgroundColor: '#ddd',
    width: (Platform.OS === 'web' ? 550 : Dimensions.get('window').width) - 105,
    paddingVertical: 8,
    borderRadius: 20,
    flexDirection: 'row',
    alignContent: 'center',
    alignItems: 'center',
  },
  icnSearchWhite: {
    width: 20,
    height: 20,
  },
  searchInput: {
    flex: 1,
    marginStart: 12,
    color: 'black',
    fontFamily: 'Pretendard',
    fontSize: 14,
  },
  cancelTxt: {
    color: Colors.main,
    fontFamily: 'Pretendard',
    fontSize: 14,
  },
});

export default SearchKeywordHistoryView;

import React, {useEffect, useMemo, useRef, useState} from 'react';
import {
  Text,
  TouchableOpacity,
  View,
  StyleSheet,
  Image,
  Platform,
} from 'react-native';
import Colors from '../common/colors';
import SelectDropdown from 'react-native-select-dropdown';
import styles from '../common/styles';
import api from '../common/api';
import AsyncStorage from '@react-native-async-storage/async-storage';
import * as Progress from 'react-native-progress';
import moment from 'moment';
import {FlatList} from 'react-native-gesture-handler';

const MyRecordComponent = ({userInfo, navigation}) => {
  const [seasonYear, setSeasonYear] = useState('');
  const [subLeagueId, setSubLeagueId] = useState('');
  const [teamId, setTeamId] = useState('');
  const [external, setExternal] = useState(false);
  const [externalInternalOverall, setExternalInternalOverall] = useState(false);
  const [seasonOptions, setSeasonOptions] = useState([]);
  const [externalSeasonOptions, setExternalSeasonOptions] = useState([]);
  const [subLeagueOptions, setSubLeagueOptions] = useState([]);
  const [teamOptions, setTeamOptions] = useState([]);
  const [toggle, setToggle] = useState(1);
  const [data, setData] = useState({});
  const [directionList, setDirectionList] = useState({});
  const [gamePage, setGamePage] = useState({items: []});
  const [progressShown, setProgressShown] = useState(false);
  const [expandGameId, setExpandGameId] = useState('');

  let accessToken = useRef();

  let seasonRef = useRef();
  let leagueSubLeagueRef = useRef();
  let teamRef = useRef();
  let recordTypeRef = useRef();

  let gamePageLoading = useRef();

  const getRecord = token => {
    if (!token) {
      return;
    }
    let requestUri = `/games/records/by-user?seasonYear=${
      seasonYear && seasonYear !== '전체 시즌' ? seasonYear : ''
    }&teamId=${teamId ? teamId : ''}&uid=${userInfo.uid}`;
    if (subLeagueId) {
      requestUri += '&subLeagueId=' + subLeagueId;
    }
    if (external) {
      requestUri += '&external=' + external;
    }
    if (externalInternalOverall) {
      requestUri += '&externalInternalOverall=' + externalInternalOverall;
    }
    console.log('requestUri', requestUri);
    api
      .get(requestUri, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(function (response) {
        if (response.data) {
          const localDirectionList = [
            parseInt(response.data.bDirRateC, 10),
            parseInt(response.data.bDirRateP, 10),
            parseInt(response.data.bDirRate1B, 10),
            parseInt(response.data.bDirRate2B, 10),
            parseInt(response.data.bDirRate3B, 10),
            parseInt(response.data.bDirRateSS, 10),
            parseInt(response.data.bDirRateLF, 10),
            parseInt(response.data.bDirRateCF, 10),
            parseInt(response.data.bDirRateRF, 10),
          ];
          localDirectionList.sort((i, j) => (i - j > 0 ? -1 : 1));
          setDirectionList(localDirectionList);
          setData(response.data);
        } else {
          setData({
            hgame: 0,
            htb: 0,
            hab: 0,
            her: 0,
            h: 0,
            h1: 0,
            h2: 0,
            h3: 0,
            totalBaseHit: 0,
            havg: '.000',
            hr: 0,
            rbi: 0,
            sb: 0,
            sbo: 0,
            hsacb: 0,
            hsacf: 0,
            hbb: 0,
            hibb: 0,
            hhitByPitch: 0,
            do: 0,
            hso: 0,
            srate: '0.000',
            ops: '0.000',
            obrate: '0.000',
            muitihit: 0,
            bbk: '0.00',
            oh: '0/0',
            pgame: 0,
            win: 0,
            lose: 0,
            save: 0,
            hold: 0,
            wrate: '0.00',
            ptb: 0,
            pab: 0,
            numberOfPitches: 0,
            ph: 0,
            phr: 0,
            psacb: 0,
            psacf: 0,
            pbb: 0,
            pibb: 0,
            phitByPitch: 0,
            boke: 0,
            wp: 0,
            r: 0,
            er: 0,
            era: '0.00',
            innings: 0,
            so: 0,
            whip: '0.00',
            pavg: '.000',
            sorate: '.000',
          });
        }
      })
      .catch(function (error) {
        console.log(error.response);
      });
  };

  const getMyTeams = token => {
    if (!token) {
      return;
    }
    let requestUri = `/app-team/my-teams?pageSize=100&uid=${userInfo.uid}`;
    if (subLeagueId) {
      requestUri += '&subLeagueId=' + subLeagueId;
    }
    api
      .get(requestUri, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(function (response) {
        const teamOptionCandidate = [];
        const teams = response.data.items;
        teamOptionCandidate.push({value: '', text: '전체 팀'});
        for (let i = 0; i < teams.length; i++) {
          teamOptionCandidate.push({value: teams[i].id, text: teams[i].name});
        }
        setTeamOptions(teamOptionCandidate);
      })
      .catch(function (error) {
        console.log(error.response);
      });
  };

  const getMySubLeagues = token => {
    if (!token) {
      return;
    }
    let requestUri = `/app-user/my-sub-leagues?uid=${userInfo.uid}`;
    if (seasonYear && seasonYear !== '전체 시즌') {
      requestUri += `&seasonYear=${seasonYear}`;
    }
    if (teamId) {
      requestUri += `&teamId=${teamId}`;
    }
    api
      .get(requestUri, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(function (response) {
        const subLeagueOptionCandidate = [];
        const subLeagues = response.data;
        subLeagueOptionCandidate.push({value: '', text: '전체 조리그'});
        for (let i = 0; i < subLeagues.length; i++) {
          subLeagueOptionCandidate.push({
            value: subLeagues[i].id,
            text: `${subLeagues[i].league_name} - ${subLeagues[i].name}`,
          });
        }
        setSubLeagueOptions(subLeagueOptionCandidate);
      })
      .catch(function (error) {
        console.log(error.response);
      });
  };

  const getSeasonList = () => {
    api
      .get(`app-user/my-seasons?uid=${userInfo.uid}`, {
        headers: {
          Authorization: `Bearer ${accessToken.current}`,
        },
      })
      .then(function (response) {
        let seasonOptionList = [];
        seasonOptionList.push('전체 시즌');
        for (let i = 0; i < response.data.length; i++) {
          seasonOptionList.push(response.data[i].year);
        }
        setSeasonOptions(seasonOptionList);
      })
      .catch(function (error) {
        console.log(error.response);
        alert('요청에 실패했습니다.');
      });
  };

  const getGameData = token => {
    if (!token) {
      return;
    }
    callGamePage(token, false);
  };

  const callGamePage = (token, loadMore) => {
    if (gamePageLoading.current) {
      return;
    }
    if (
      loadMore &&
      gamePage.currentPage &&
      gamePage.totalPage <= gamePage.currentPage
    ) {
      return;
    }
    gamePageLoading.current = true;

    setProgressShown(true);
    let requestUri = `app-game/my?seasonYear=${
      seasonYear && seasonYear !== '전체 시즌' ? seasonYear : ''
    }&pageNo=${
      gamePage.currentPage && loadMore
        ? parseInt(gamePage.currentPage, 10) + 1
        : 1
    }&pageSize=4`;
    if (subLeagueId) {
      requestUri += '&subLeagueId=' + subLeagueId;
    }
    if (teamId) {
      requestUri += `&teamId=${teamId}`;
    }
    if (userInfo.uid) {
      requestUri += `&userId=${userInfo.uid}`;
    }
    if (toggle === 1) {
      requestUri += '&battingPitchingType=BATTING';
    } else if (toggle === 0) {
      requestUri += '&battingPitchingType=PITCHING';
    }
    // if (firstCall) {
    //   requestUri +=
    //     '&fromDate=' + moment().startOf('month').format('YYYY-MM-DD');
    //   requestUri += '&fromDate=' + moment().endOf('month').format('YYYY-MM-DD');
    // }
    console.log(requestUri);
    api
      .get(requestUri, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(function (response) {
        response.data.items = [
          ...(gamePage.items && loadMore ? gamePage.items : []),
          ...response.data.items,
        ];
        setGamePage(response.data);
      })
      .catch(function (error) {
        console.log(error);
      })
      .finally(() => {
        setProgressShown(false);
        gamePageLoading.current = false;
      });
  };
  useEffect(() => {
    let tempSeasonOptions = [];
    tempSeasonOptions.push('전체 시즌');
    for (let i = 2000; i < moment().year(); i++) {
      tempSeasonOptions.push(i);
    }
    setExternalSeasonOptions(tempSeasonOptions);
  }, []);
  useEffect(() => {
    if (seasonOptions.length > 0) {
      setSeasonYear(seasonOptions[0]);
    }
  }, [seasonOptions]);
  useEffect(() => {
    getRecord(accessToken.current);
    setGamePage({items: []});
    getGameData(accessToken.current);
    getMySubLeagues(accessToken.current);
  }, [seasonYear, subLeagueId, teamId, external, externalInternalOverall]);
  useEffect(() => {
    setGamePage({items: []});
    getGameData(accessToken.current);
  }, [toggle]);
  useEffect(() => {
    getMySubLeagues(accessToken.current);
    setSubLeagueId('');
    setTeamId('');
    leagueSubLeagueRef?.current?.reset();
    teamRef?.current?.reset();
  }, [seasonYear]);
  useEffect(() => {
    getMyTeams(accessToken.current);
    // teamRef.current.reset();
  }, [subLeagueId]);
  useEffect(() => {
    AsyncStorage.getItem('@accessToken', (err, result) => {
      accessToken.current = result;
      getRecord(result);
      getMyTeams(result);
      getSeasonList(result);
      setGamePage({items: []});
      getGameData(result);
    });
  }, []);

  const parseDate = date => {
    if (date.indexOf('T') > -1) {
      return moment(date.substring(0, date.indexOf('T')), 'YYYY-MM-DD').format(
        'MM/DD',
      );
    }
    return moment(date, 'YYYY-MM-DD').locale('ko').format('MM/DD(dd)');
  };

  const parseTime = time => {
    if (time.indexOf(':', 4) > -1) {
      return time.substring(0, time.indexOf(':', 4));
    }
    return time;
  };
  return (
    <View style={[pageStyle.innerContainer]}>
      <>
        <View style={[styles.row, styles.mt18]}>
          <View
            style={[styles.col, !external && external != null && styles.mr7]}>
            <SelectDropdown
              ref={seasonRef}
              defaultValue={seasonYear}
              defaultButtonText="시즌"
              buttonStyle={styles.dropDown}
              buttonTextStyle={styles.downDownText}
              rowTextStyle={styles.dropDownRowText}
              data={
                external || externalInternalOverall
                  ? externalSeasonOptions
                  : seasonOptions
              }
              onSelect={(selectedItem, index) => {
                if (selectedItem === '전체' || selectedItem === '전체 시즌') {
                  setSeasonYear('');
                } else {
                  setSeasonYear(selectedItem);
                }
              }}
              renderDropdownIcon={() => (
                <Image source={require('../assets/images/select_arr.png')} />
              )}
            />
          </View>

          {!external && external != null && (
            <View style={[styles.col]}>
              <SelectDropdown
                ref={teamRef}
                defaultValue={teamId}
                defaultButtonText="팀"
                buttonStyle={styles.dropDown}
                buttonTextStyle={styles.downDownText}
                rowTextStyle={styles.dropDownRowText}
                data={teamOptions}
                onSelect={(selectedItem, index) => {
                  if (selectedItem === '전체') {
                    setTeamId('');
                  } else {
                    setTeamId(selectedItem.value);
                  }
                }}
                buttonTextAfterSelection={(selectedItem, index) => {
                  return selectedItem.text;
                }}
                rowTextForSelection={(item, index) => {
                  return item.text;
                }}
                renderDropdownIcon={() => (
                  <Image source={require('../assets/images/select_arr.png')} />
                )}
              />
            </View>
          )}
        </View>
        <View style={[styles.row, styles.mt7]}>
          {!external && external != null && (
            <View style={[styles.col, styles.mr7]}>
              <SelectDropdown
                ref={leagueSubLeagueRef}
                defaultValue={subLeagueId}
                defaultButtonText="리그, 조"
                buttonStyle={styles.dropDown}
                buttonTextStyle={styles.downDownText}
                rowTextStyle={styles.dropDownRowText}
                data={subLeagueOptions}
                onSelect={(selectedItem, index) => {
                  if (selectedItem === '전체') {
                    setSubLeagueId('');
                  } else {
                    setSubLeagueId(selectedItem.value);
                  }
                }}
                buttonTextAfterSelection={(selectedItem, index) => {
                  return selectedItem.text;
                }}
                rowTextForSelection={(item, index) => {
                  return `${item.text}`;
                }}
                renderDropdownIcon={() => (
                  <Image source={require('../assets/images/select_arr.png')} />
                )}
              />
            </View>
          )}
          <View style={[styles.col]}>
            <SelectDropdown
              ref={recordTypeRef}
              defaultValue={{
                text: '공식기록',
                external: false,
                externalInternalOverall: false,
              }}
              defaultButtonText="기록종류"
              buttonStyle={styles.dropDown}
              buttonTextStyle={styles.downDownText}
              rowTextStyle={styles.dropDownRowText}
              data={[
                {
                  text: '공식기록',
                  external: false,
                  externalInternalOverall: false,
                },
                {
                  text: '기록전체',
                  external: null,
                  externalInternalOverall: true,
                },
                {
                  text: '외부기록',
                  external: true,
                  externalInternalOverall: false,
                },
              ]}
              onSelect={(selectedItem, index) => {
                setExternal(selectedItem.external);
                setExternalInternalOverall(
                  selectedItem.externalInternalOverall,
                );
              }}
              buttonTextAfterSelection={(selectedItem, index) => {
                return selectedItem.text;
              }}
              rowTextForSelection={(item, index) => {
                return `${item.text}`;
              }}
              renderDropdownIcon={() => (
                <Image source={require('../assets/images/select_arr.png')} />
              )}
            />
          </View>
        </View>
        <View style={[pageStyle.toggleContainer]}>
          <View style={[styles.row]}>
            <TouchableOpacity
              onPress={() => setToggle(1)}
              style={[
                pageStyle.toggleItem,
                pageStyle.toggleLeft,
                toggle === 1 && pageStyle.toggleLeftSelected,
              ]}>
              <Text
                style={[
                  pageStyle.toggleTxt,
                  toggle === 1 && pageStyle.toggleTxtSelected,
                ]}>
                타자
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => setToggle(0)}
              style={[
                pageStyle.toggleItem,
                pageStyle.toggleRight,
                toggle === 0 && pageStyle.toggleRightSelected,
              ]}>
              <Text
                style={[
                  pageStyle.toggleTxt,
                  toggle === 0 && pageStyle.toggleTxtSelected,
                ]}>
                투수
              </Text>
            </TouchableOpacity>
          </View>
        </View>
        {toggle === 0 && (
          <>
            <View style={[pageStyle.row, pageStyle.firstRow]}>
              <View style={[pageStyle.col]}>
                <Text style={[pageStyle.th]} numberOfLines={1}>
                  경기수
                </Text>
                <Text style={[pageStyle.td]} numberOfLines={1}>
                  {data?.pgame}
                </Text>
              </View>
              <View style={[pageStyle.col]}>
                <Text style={[pageStyle.th]} numberOfLines={1}>
                  ERA
                </Text>
                <Text style={[pageStyle.td, pageStyle.even]} numberOfLines={1}>
                  {data?.era ? parseFloat(data?.era).toFixed(2) : '0.00'}
                </Text>
              </View>
              <View style={[pageStyle.col]}>
                <Text style={[pageStyle.th]} numberOfLines={1}>
                  승
                </Text>
                <Text style={[pageStyle.td]} numberOfLines={1}>
                  {data?.win}
                </Text>
              </View>
              <View style={[pageStyle.col]}>
                <Text style={[pageStyle.th]}>패</Text>
                <Text style={[pageStyle.td, pageStyle.even]} numberOfLines={1}>
                  {data?.lose}
                </Text>
              </View>
              <View style={[pageStyle.col]}>
                <Text style={[pageStyle.th]} numberOfLines={1}>
                  세이브
                </Text>
                <Text style={[pageStyle.td]} numberOfLines={1}>
                  {data?.save}
                </Text>
              </View>
              <View style={[pageStyle.col]}>
                <Text style={[pageStyle.th]} numberOfLines={1}>
                  홀드
                </Text>
                <Text style={[pageStyle.td, pageStyle.even]} numberOfLines={1}>
                  {data?.hold}
                </Text>
              </View>
            </View>
            <View style={[pageStyle.row]}>
              <View style={[pageStyle.col]}>
                <Text style={[pageStyle.th]} numberOfLines={1}>
                  승률
                </Text>
                <Text style={[pageStyle.td]} numberOfLines={1}>
                  {data?.wrate ? parseFloat(data?.wrate).toFixed(2) : '0.00'}
                </Text>
              </View>
              <View style={[pageStyle.col]}>
                <Text style={[pageStyle.th]} numberOfLines={1}>
                  타자
                </Text>
                <Text style={[pageStyle.td, pageStyle.even]} numberOfLines={1}>
                  {data?.ptb}
                </Text>
              </View>
              <View style={[pageStyle.col]}>
                <Text style={[pageStyle.th]} numberOfLines={1}>
                  타수
                </Text>
                <Text style={[pageStyle.td]} numberOfLines={1}>
                  {data?.pab}
                </Text>
              </View>
              <View style={[pageStyle.col]}>
                <Text style={[pageStyle.th]}>투구수</Text>
                <Text style={[pageStyle.td, pageStyle.even]} numberOfLines={1}>
                  {data?.numberOfPitches}
                </Text>
              </View>
              <View style={[pageStyle.col]}>
                <Text style={[pageStyle.th]} numberOfLines={1}>
                  이닝
                </Text>
                <Text style={[pageStyle.td]} numberOfLines={1}>
                  {data?.innings}
                </Text>
              </View>
              <View style={[pageStyle.col]}>
                <Text style={[pageStyle.th]} numberOfLines={1}>
                  피안타
                </Text>
                <Text style={[pageStyle.td, pageStyle.even]} numberOfLines={1}>
                  {data?.ph}
                </Text>
              </View>
            </View>
            <View style={[pageStyle.row]}>
              <View style={[pageStyle.col]}>
                <Text style={[pageStyle.th]} numberOfLines={1}>
                  피홈런
                </Text>
                <Text style={[pageStyle.td]} numberOfLines={1}>
                  {data?.phr}
                </Text>
              </View>
              <View style={[pageStyle.col]}>
                <Text style={[pageStyle.th]} numberOfLines={1}>
                  희타
                </Text>
                <Text style={[pageStyle.td, pageStyle.even]} numberOfLines={1}>
                  {data?.psacb}
                </Text>
              </View>
              <View style={[pageStyle.col]}>
                <Text style={[pageStyle.th]} numberOfLines={1}>
                  희비
                </Text>
                <Text style={[pageStyle.td]} numberOfLines={1}>
                  {data?.psacf}
                </Text>
              </View>
              <View style={[pageStyle.col]}>
                <Text style={[pageStyle.th]}>볼넷</Text>
                <Text style={[pageStyle.td, pageStyle.even]} numberOfLines={1}>
                  {data?.pbb}
                </Text>
              </View>
              <View style={[pageStyle.col]}>
                <Text style={[pageStyle.th]} numberOfLines={1}>
                  고의4구
                </Text>
                <Text style={[pageStyle.td]} numberOfLines={1}>
                  {data?.pibb}
                </Text>
              </View>
              <View style={[pageStyle.col]}>
                <Text style={[pageStyle.th]} numberOfLines={1}>
                  사구
                </Text>
                <Text style={[pageStyle.td, pageStyle.even]} numberOfLines={1}>
                  {data?.phitByPitch}
                </Text>
              </View>
            </View>
            <View style={[pageStyle.row]}>
              <View style={[pageStyle.col]}>
                <Text style={[pageStyle.th]} numberOfLines={1}>
                  탈삼진
                </Text>
                <Text style={[pageStyle.td]} numberOfLines={1}>
                  {data?.so}
                </Text>
              </View>
              <View style={[pageStyle.col]}>
                <Text style={[pageStyle.th]} numberOfLines={1}>
                  폭투
                </Text>
                <Text style={[pageStyle.td, pageStyle.even]} numberOfLines={1}>
                  {data?.wp}
                </Text>
              </View>
              <View style={[pageStyle.col]}>
                <Text style={[pageStyle.th]} numberOfLines={1}>
                  보크
                </Text>
                <Text style={[pageStyle.td]} numberOfLines={1}>
                  {data?.boke}
                </Text>
              </View>
              <View style={[pageStyle.col]}>
                <Text style={[pageStyle.th]}>실점</Text>
                <Text style={[pageStyle.td, pageStyle.even]} numberOfLines={1}>
                  {data?.r}
                </Text>
              </View>
              <View style={[pageStyle.col]}>
                <Text style={[pageStyle.th]} numberOfLines={1}>
                  자책점
                </Text>
                <Text style={[pageStyle.td]} numberOfLines={1}>
                  {data?.er}
                </Text>
              </View>
              <View style={[pageStyle.col]}>
                <Text style={[pageStyle.th]} numberOfLines={1}>
                  WHIP
                </Text>
                <Text style={[pageStyle.td, pageStyle.even]} numberOfLines={1}>
                  {data?.whip ? parseFloat(data?.whip).toFixed(2) : '0.00'}
                </Text>
              </View>
            </View>
            <View style={[pageStyle.row]}>
              <View style={[pageStyle.col]}>
                <Text style={[pageStyle.th]} numberOfLines={1}>
                  피안타율
                </Text>
                <Text style={[pageStyle.td]} numberOfLines={1}>
                  {data?.pavg ? parseFloat(data?.pavg).toFixed(3) : '0.000'}
                </Text>
              </View>
              <View style={[pageStyle.col]}>
                <Text style={[pageStyle.th]} numberOfLines={1}>
                  탈삼진율
                </Text>
                <Text style={[pageStyle.td, pageStyle.even]} numberOfLines={1}>
                  {data?.sorate ? parseFloat(data?.sorate).toFixed(3) : '0.000'}
                </Text>
              </View>
              <View style={[pageStyle.col]} />
              <View style={[pageStyle.col]} />
              <View style={[pageStyle.col]} />
              <View style={[pageStyle.col]} />
            </View>
          </>
        )}
        {toggle === 1 && (
          <>
            <View style={[pageStyle.row, pageStyle.firstRow]}>
              <View style={[pageStyle.col]}>
                <Text style={[pageStyle.th]} numberOfLines={1}>
                  경기수
                </Text>
                <Text style={[pageStyle.td]} numberOfLines={1}>
                  {data?.hgame}
                </Text>
              </View>
              <View style={[pageStyle.col]}>
                <Text style={[pageStyle.th]} numberOfLines={1}>
                  타율
                </Text>
                <Text style={[pageStyle.td, pageStyle.even]} numberOfLines={1}>
                  {data?.havg ? parseFloat(data?.havg).toFixed(3) : '0.000'}
                </Text>
              </View>
              <View style={[pageStyle.col]}>
                <Text style={[pageStyle.th]} numberOfLines={1}>
                  타석
                </Text>
                <Text style={[pageStyle.td]} numberOfLines={1}>
                  {data?.htb}
                </Text>
              </View>
              <View style={[pageStyle.col]}>
                <Text style={[pageStyle.th]} numberOfLines={1}>
                  타수
                </Text>
                <Text style={[pageStyle.td, pageStyle.even]} numberOfLines={1}>
                  {data?.hab}
                </Text>
              </View>
              <View style={[pageStyle.col]}>
                <Text style={[pageStyle.th]} numberOfLines={1}>
                  득점
                </Text>
                <Text style={[pageStyle.td]} numberOfLines={1}>
                  {data?.her}
                </Text>
              </View>
              <View style={[pageStyle.col]}>
                <Text style={[pageStyle.th]} numberOfLines={1}>
                  안타수
                </Text>
                <Text style={[pageStyle.td, pageStyle.even]} numberOfLines={1}>
                  {data?.h}
                </Text>
              </View>
            </View>
            <View style={[pageStyle.row]}>
              <View style={[pageStyle.col]}>
                <Text style={[pageStyle.th]} numberOfLines={1}>
                  1루타
                </Text>
                <Text style={[pageStyle.td]} numberOfLines={1}>
                  {data?.h1}
                </Text>
              </View>
              <View style={[pageStyle.col]}>
                <Text style={[pageStyle.th]} numberOfLines={1}>
                  2루타
                </Text>
                <Text style={[pageStyle.td, pageStyle.even]} numberOfLines={1}>
                  {data?.h2}
                </Text>
              </View>
              <View style={[pageStyle.col]}>
                <Text style={[pageStyle.th]} numberOfLines={1}>
                  3루타
                </Text>
                <Text style={[pageStyle.td]} numberOfLines={1}>
                  {data?.h3}
                </Text>
              </View>
              <View style={[pageStyle.col]}>
                <Text style={[pageStyle.th]} numberOfLines={1}>
                  홈런
                </Text>
                <Text style={[pageStyle.td, pageStyle.even]} numberOfLines={1}>
                  {data?.hr}
                </Text>
              </View>
              <View style={[pageStyle.col]}>
                <Text style={[pageStyle.th]} numberOfLines={1}>
                  루타
                </Text>
                <Text style={[pageStyle.td]} numberOfLines={1}>
                  {data?.totalBaseHit}
                </Text>
              </View>
              <View style={[pageStyle.col]}>
                <Text style={[pageStyle.th]} numberOfLines={1}>
                  타점
                </Text>
                <Text style={[pageStyle.td, pageStyle.even]} numberOfLines={1}>
                  {data?.rbi}
                </Text>
              </View>
            </View>
            <View style={[pageStyle.row]}>
              <View style={[pageStyle.col]}>
                <Text style={[pageStyle.th]} numberOfLines={1}>
                  도루
                </Text>
                <Text style={[pageStyle.td]} numberOfLines={1}>
                  {data?.sb}
                </Text>
              </View>
              <View style={[pageStyle.col]}>
                <Text style={[pageStyle.th]} numberOfLines={1}>
                  도루자
                </Text>
                <Text style={[pageStyle.td, pageStyle.even]} numberOfLines={1}>
                  {data?.sbo}
                </Text>
              </View>
              <View style={[pageStyle.col]}>
                <Text style={[pageStyle.th]} numberOfLines={1}>
                  희타
                </Text>
                <Text style={[pageStyle.td]} numberOfLines={1}>
                  {data?.hsacb}
                </Text>
              </View>
              <View style={[pageStyle.col]}>
                <Text style={[pageStyle.th]} numberOfLines={1}>
                  희비
                </Text>
                <Text style={[pageStyle.td, pageStyle.even]} numberOfLines={1}>
                  {data?.hsacf}
                </Text>
              </View>
              <View style={[pageStyle.col]}>
                <Text style={[pageStyle.th]} numberOfLines={1}>
                  볼넷
                </Text>
                <Text style={[pageStyle.td]} numberOfLines={1}>
                  {data?.hbb}
                </Text>
              </View>
              <View style={[pageStyle.col]}>
                <Text style={[pageStyle.th]} numberOfLines={1}>
                  고의사구
                </Text>
                <Text style={[pageStyle.td, pageStyle.even]} numberOfLines={1}>
                  {data?.hibb}
                </Text>
              </View>
            </View>
            <View style={[pageStyle.row]}>
              <View style={[pageStyle.col]}>
                <Text style={[pageStyle.th]} numberOfLines={1}>
                  사구
                </Text>
                <Text style={[pageStyle.td]} numberOfLines={1}>
                  {data?.hhitByPitch}
                </Text>
              </View>
              <View style={[pageStyle.col]}>
                <Text style={[pageStyle.th]} numberOfLines={1}>
                  삼진
                </Text>
                <Text style={[pageStyle.td, pageStyle.even]} numberOfLines={1}>
                  {data?.hso}
                </Text>
              </View>
              <View style={[pageStyle.col]}>
                <Text style={[pageStyle.th]} numberOfLines={1}>
                  병살
                </Text>
                <Text style={[pageStyle.td]} numberOfLines={1}>
                  {data?.do}
                </Text>
              </View>
              <View style={[pageStyle.col]}>
                <Text style={[pageStyle.th]} numberOfLines={1}>
                  장타율
                </Text>
                <Text style={[pageStyle.td, pageStyle.even]} numberOfLines={1}>
                  {data?.srate ? parseFloat(data?.srate).toFixed(3) : '0.000'}
                </Text>
              </View>
              <View style={[pageStyle.col]}>
                <Text style={[pageStyle.th]} numberOfLines={1}>
                  출루율
                </Text>
                <Text style={[pageStyle.td]} numberOfLines={1}>
                  {data?.obrate ? parseFloat(data?.obrate).toFixed(3) : '0.000'}
                </Text>
              </View>
              <View style={[pageStyle.col]}>
                <Text style={[pageStyle.th]} numberOfLines={1}>
                  도루성공률
                </Text>
                <Text style={[pageStyle.td, pageStyle.even]} numberOfLines={1}>
                  {data?.sbrate ? parseFloat(data?.sbrate).toFixed(3) : '0.000'}
                </Text>
              </View>
            </View>
            <View style={[pageStyle.row]}>
              <View style={[pageStyle.col]}>
                <Text style={[pageStyle.th]} numberOfLines={1}>
                  멀티히트
                </Text>
                <Text style={[pageStyle.td]} numberOfLines={1}>
                  {data?.muitihit}
                </Text>
              </View>
              <View style={[pageStyle.col]}>
                <Text style={[pageStyle.th]} numberOfLines={1}>
                  OPS
                </Text>
                <Text style={[pageStyle.td, pageStyle.even]} numberOfLines={1}>
                  {data?.ops ? parseFloat(data?.ops).toFixed(3) : '0.000'}
                </Text>
              </View>
              <View style={[pageStyle.col]}>
                <Text style={[pageStyle.th]} numberOfLines={1}>
                  BB/K
                </Text>
                <Text style={[pageStyle.td]} numberOfLines={1}>
                  {data?.bbk ? parseFloat(data?.bbk).toFixed(3) : '0.000'}
                </Text>
              </View>
              <View style={[pageStyle.col]}>
                <Text style={[pageStyle.th]} numberOfLines={1}>
                  장타/안타
                </Text>
                <Text style={[pageStyle.td, pageStyle.even]} numberOfLines={1}>
                  {data?.ohRate ? parseFloat(data?.ohRate).toFixed(3) : '0.000'}
                </Text>
              </View>
              <View style={[pageStyle.col]}>
                <Text style={[pageStyle.th]} numberOfLines={1} />
                <Text style={[pageStyle.td]} numberOfLines={1} />
              </View>
              <View style={[pageStyle.col]}>
                <Text style={[pageStyle.th]} numberOfLines={1} />
                <Text
                  style={[pageStyle.td, pageStyle.even]}
                  numberOfLines={1}
                />
              </View>
            </View>
            <View>
              <Text style={[pageStyle.subtitle]}>타구방향</Text>
              <View style={[pageStyle.stadiumBg]}>
                <View style={[pageStyle.stadiumBg1]}>
                  <View style={[pageStyle.stadiumBg2]} />
                </View>
              </View>
              <View style={[pageStyle.percentageContainer]}>
                <Text
                  style={[
                    pageStyle.percentage,
                    pageStyle.pc,
                    data?.bDirRateC !== '0' &&
                      directionList[1] <= parseInt(data?.bDirRateC, 10) && {
                        backgroundColor: 'orange',
                      },
                  ]}>
                  {data?.bDirRateC}%
                </Text>
                <Text
                  style={[
                    pageStyle.percentage,
                    pageStyle.pp,
                    data?.bDirRateP !== '0' &&
                      directionList[1] <= parseInt(data?.bDirRateP, 10) && {
                        backgroundColor: 'orange',
                      },
                  ]}>
                  {data?.bDirRateP}%
                </Text>
                <Text
                  style={[
                    pageStyle.percentage,
                    pageStyle.b1,
                    data?.bDirRate1B !== '0' &&
                      directionList[1] <= parseInt(data?.bDirRate1B, 10) && {
                        backgroundColor: 'orange',
                      },
                  ]}>
                  {data?.bDirRate1B}%
                </Text>
                <Text
                  style={[
                    pageStyle.percentage,
                    pageStyle.b2,
                    data?.bDirRate2B !== '0' &&
                      directionList[1] <= parseInt(data?.bDirRate2B, 10) && {
                        backgroundColor: 'orange',
                      },
                  ]}>
                  {data?.bDirRate2B}%
                </Text>
                <Text
                  style={[
                    pageStyle.percentage,
                    pageStyle.ss,
                    data?.bDirRateSS !== '0' &&
                      directionList[1] <= parseInt(data?.bDirRateSS, 10) && {
                        backgroundColor: 'orange',
                      },
                  ]}>
                  {data?.bDirRateSS}%
                </Text>
                <Text
                  style={[
                    pageStyle.percentage,
                    pageStyle.b3,
                    data?.bDirRate3B !== '0' &&
                      directionList[1] <= parseInt(data?.bDirRate3B, 10) && {
                        backgroundColor: 'orange',
                      },
                  ]}>
                  {data?.bDirRate3B}%
                </Text>
                <Text
                  style={[
                    pageStyle.percentage,
                    pageStyle.lf,
                    data?.bDirRateLF !== '0' &&
                      directionList[1] <= parseInt(data?.bDirRateLF, 10) && {
                        backgroundColor: 'orange',
                      },
                  ]}>
                  {data?.bDirRateLF}%
                </Text>
                <Text
                  style={[
                    pageStyle.percentage,
                    pageStyle.cf,
                    data?.bDirRateCF !== '0' &&
                      directionList[1] <= parseInt(data?.bDirRateCF, 10) && {
                        backgroundColor: 'orange',
                      },
                  ]}>
                  {data?.bDirRateCF}%
                </Text>
                <Text
                  style={[
                    pageStyle.percentage,
                    pageStyle.rf,
                    data?.bDirRateRF !== '0' &&
                      directionList[1] <= parseInt(data?.bDirRateRF, 10) && {
                        backgroundColor: 'orange',
                      },
                  ]}>
                  {data?.bDirRateRF}%
                </Text>
              </View>
            </View>
          </>
        )}
      </>
      <View>
        <Text style={[pageStyle.subtitle]}>게임별기록</Text>
      </View>
      <FlatList
        showsVerticalScrollIndicator={false}
        style={[pageStyle.list]}
        keyExtractor={item => `game_${item.id.toString()}`}
        data={gamePage.items}
        renderItem={({item, index}) => (
          <View>
            <View style={{flexDirection: 'row', padding: 12}}>
              <Text
                style={{
                  color: 'black',
                  fontSize: 12,
                  fontFamily: 'Pretendard',
                  width: 60,
                  paddingTop: 3,
                }}>
                {parseDate(item?.date)}
              </Text>
              <View
                style={{
                  flexDirection: 'row',
                  alignContent: 'center',
                  alignItems: 'center',
                  flex: 1,
                  justifyContent: 'space-between',
                }}>
                <TouchableOpacity
                  style={[{flex: 1}]}
                  onPress={() =>
                    navigation.navigate('BoxScore', {
                      data: item,
                      itemId: item?.id,
                    })
                  }>
                  <View
                    style={{
                      flex: 1,
                      flexDirection: 'row',
                      alignContent: 'center',
                      alignItems: 'center',
                    }}>
                    <Text
                      numberOfLines={1}
                      style={[
                        {color: '#aaa', fontSize: 13, fontFamily: 'Pretendard'},
                        JSON.parse(item?.records)?.batFirstTeam?.score >
                          JSON.parse(item?.records)?.batLastTeam?.score && {
                          color: Colors.purple,
                        },
                      ]}>
                      {item?.batFirstTeamName}
                    </Text>
                    <Text
                      style={[
                        {
                          color: '#aaa',
                          fontSize: 13,
                          fontFamily: 'Pretendard',
                          marginHorizontal: 4,
                        },
                        JSON.parse(item?.records)?.batFirstTeam?.score >
                          JSON.parse(item?.records)?.batLastTeam?.score && {
                          color: Colors.purple,
                        },
                      ]}>
                      {item?.records &&
                        JSON.parse(item?.records)?.batFirstTeam?.score}
                    </Text>
                    <Text
                      style={{
                        color: '#aaa',
                        fontFamily: 'Pretendard',
                        fontSize: 13,
                      }}>
                      VS
                    </Text>
                    <Text
                      style={[
                        {
                          color: '#aaa',
                          fontSize: 13,
                          fontFamily: 'Pretendard',
                          marginHorizontal: 4,
                        },
                        JSON.parse(item?.records)?.batFirstTeam?.score <
                          JSON.parse(item?.records)?.batLastTeam?.score && {
                          color: Colors.purple,
                        },
                      ]}>
                      {item?.records &&
                        JSON.parse(item?.records)?.batLastTeam?.score}
                    </Text>
                    <Text
                      numberOfLines={1}
                      style={[
                        {
                          color: '#aaa',
                          fontSize: 13,
                          fontFamily: 'Pretendard',
                          flex: 1,
                        },
                        JSON.parse(item?.records)?.batFirstTeam?.score <
                          JSON.parse(item?.records)?.batLastTeam?.score && {
                          color: Colors.purple,
                        },
                      ]}>
                      {item?.batLastTeamName}
                    </Text>
                  </View>
                  {toggle === 1 && item.batRecord && (
                    <Text
                      style={{
                        fontSize: 11,
                        fontFamily: 'Pretendard',
                        color: '#222',
                      }}>
                      {item.batRecord?.tb}타석 {item.batRecord?.ab}타수{' '}
                      {item.batRecord?.hit}안타{' '}
                      {item.batRecord?.r ? item.batRecord?.r : '0'}득점{' '}
                      {item.batRecord?.rbi ? item.batRecord?.rbi : '0'}타점
                    </Text>
                  )}
                  {toggle === 1 && !item.batRecord && (
                    <Text
                      style={{
                        fontSize: 11,
                        fontFamily: 'Pretendard',
                        color: '#222',
                      }}>
                      0타석 0타수 0안타 0득점 0타점
                    </Text>
                  )}
                  {toggle === 0 && item.pitchingRecord && (
                    <Text
                      style={{
                        fontSize: 11,
                        fontFamily: 'Pretendard',
                        color: '#222',
                      }}>
                      {item.pitchingRecord?.inning}이닝{' '}
                      {item.pitchingRecord?.hits
                        ? item.pitchingRecord?.hits
                        : '0'}
                      피안타{' '}
                      {item.pitchingRecord?.er ? item.pitchingRecord?.er : '0'}
                      자책
                    </Text>
                  )}
                </TouchableOpacity>
                <TouchableOpacity
                  style={{padding: 12}}
                  onPress={() =>
                    item.id === expandGameId
                      ? setExpandGameId('')
                      : setExpandGameId(item.id)
                  }>
                  <Image source={require('../assets/images/select_arr.png')} />
                </TouchableOpacity>
              </View>
            </View>
            {expandGameId === item.id && toggle === 0 && (
              <>
                <View style={[pageStyle.row, pageStyle.firstRow]}>
                  <View style={[pageStyle.col]}>
                    <Text style={[pageStyle.th]} numberOfLines={1}>
                      -
                    </Text>
                    <Text style={[pageStyle.td]} numberOfLines={1}>
                      -
                    </Text>
                  </View>
                  <View style={[pageStyle.col]}>
                    <Text style={[pageStyle.th]} numberOfLines={1}>
                      ERA
                    </Text>
                    <Text
                      style={[pageStyle.td, pageStyle.even]}
                      numberOfLines={1}>
                      {item?.userRecord?.era
                        ? parseFloat(item?.userRecord?.era).toFixed(2)
                        : '0.00'}
                    </Text>
                  </View>
                  <View style={[pageStyle.col]}>
                    <Text style={[pageStyle.th]} numberOfLines={1}>
                      승
                    </Text>
                    <Text style={[pageStyle.td]} numberOfLines={1}>
                      {item?.userRecord?.win}
                    </Text>
                  </View>
                  <View style={[pageStyle.col]}>
                    <Text style={[pageStyle.th]}>패</Text>
                    <Text
                      style={[pageStyle.td, pageStyle.even]}
                      numberOfLines={1}>
                      {item?.userRecord?.lose}
                    </Text>
                  </View>
                  <View style={[pageStyle.col]}>
                    <Text style={[pageStyle.th]} numberOfLines={1}>
                      세이브
                    </Text>
                    <Text style={[pageStyle.td]} numberOfLines={1}>
                      {item?.userRecord?.save}
                    </Text>
                  </View>
                  <View style={[pageStyle.col]}>
                    <Text style={[pageStyle.th]} numberOfLines={1}>
                      홀드
                    </Text>
                    <Text
                      style={[pageStyle.td, pageStyle.even]}
                      numberOfLines={1}>
                      {item?.userRecord?.hold}
                    </Text>
                  </View>
                </View>
                <View style={[pageStyle.row]}>
                  <View style={[pageStyle.col]}>
                    <Text style={[pageStyle.th]} numberOfLines={1}>
                      승률
                    </Text>
                    <Text style={[pageStyle.td]} numberOfLines={1}>
                      {item?.userRecord?.wrate
                        ? parseFloat(item?.userRecord?.wrate).toFixed(2)
                        : '0.00'}
                    </Text>
                  </View>
                  <View style={[pageStyle.col]}>
                    <Text style={[pageStyle.th]} numberOfLines={1}>
                      타자
                    </Text>
                    <Text
                      style={[pageStyle.td, pageStyle.even]}
                      numberOfLines={1}>
                      {item?.userRecord?.ptb}
                    </Text>
                  </View>
                  <View style={[pageStyle.col]}>
                    <Text style={[pageStyle.th]} numberOfLines={1}>
                      타수
                    </Text>
                    <Text style={[pageStyle.td]} numberOfLines={1}>
                      {item?.userRecord?.pab}
                    </Text>
                  </View>
                  <View style={[pageStyle.col]}>
                    <Text style={[pageStyle.th]}>투구수</Text>
                    <Text
                      style={[pageStyle.td, pageStyle.even]}
                      numberOfLines={1}>
                      {item?.userRecord?.numberOfPitches}
                    </Text>
                  </View>
                  <View style={[pageStyle.col]}>
                    <Text style={[pageStyle.th]} numberOfLines={1}>
                      이닝
                    </Text>
                    <Text style={[pageStyle.td]} numberOfLines={1}>
                      {item?.userRecord?.innings}
                    </Text>
                  </View>
                  <View style={[pageStyle.col]}>
                    <Text style={[pageStyle.th]} numberOfLines={1}>
                      피안타
                    </Text>
                    <Text
                      style={[pageStyle.td, pageStyle.even]}
                      numberOfLines={1}>
                      {item?.userRecord?.ph}
                    </Text>
                  </View>
                </View>
                <View style={[pageStyle.row]}>
                  <View style={[pageStyle.col]}>
                    <Text style={[pageStyle.th]} numberOfLines={1}>
                      피홈런
                    </Text>
                    <Text style={[pageStyle.td]} numberOfLines={1}>
                      {item?.userRecord?.phr}
                    </Text>
                  </View>
                  <View style={[pageStyle.col]}>
                    <Text style={[pageStyle.th]} numberOfLines={1}>
                      희타
                    </Text>
                    <Text
                      style={[pageStyle.td, pageStyle.even]}
                      numberOfLines={1}>
                      {item?.userRecord?.psacb}
                    </Text>
                  </View>
                  <View style={[pageStyle.col]}>
                    <Text style={[pageStyle.th]} numberOfLines={1}>
                      희비
                    </Text>
                    <Text style={[pageStyle.td]} numberOfLines={1}>
                      {item?.userRecord?.psacf}
                    </Text>
                  </View>
                  <View style={[pageStyle.col]}>
                    <Text style={[pageStyle.th]}>볼넷</Text>
                    <Text
                      style={[pageStyle.td, pageStyle.even]}
                      numberOfLines={1}>
                      {item?.userRecord?.pbb}
                    </Text>
                  </View>
                  <View style={[pageStyle.col]}>
                    <Text style={[pageStyle.th]} numberOfLines={1}>
                      고의4구
                    </Text>
                    <Text style={[pageStyle.td]} numberOfLines={1}>
                      {item?.userRecord?.pibb}
                    </Text>
                  </View>
                  <View style={[pageStyle.col]}>
                    <Text style={[pageStyle.th]} numberOfLines={1}>
                      사구
                    </Text>
                    <Text
                      style={[pageStyle.td, pageStyle.even]}
                      numberOfLines={1}>
                      {item?.userRecord?.phitByPitch}
                    </Text>
                  </View>
                </View>
                <View style={[pageStyle.row]}>
                  <View style={[pageStyle.col]}>
                    <Text style={[pageStyle.th]} numberOfLines={1}>
                      탈삼진
                    </Text>
                    <Text style={[pageStyle.td]} numberOfLines={1}>
                      {item?.userRecord?.so}
                    </Text>
                  </View>
                  <View style={[pageStyle.col]}>
                    <Text style={[pageStyle.th]} numberOfLines={1}>
                      폭투
                    </Text>
                    <Text
                      style={[pageStyle.td, pageStyle.even]}
                      numberOfLines={1}>
                      {item?.userRecord?.wp}
                    </Text>
                  </View>
                  <View style={[pageStyle.col]}>
                    <Text style={[pageStyle.th]} numberOfLines={1}>
                      보크
                    </Text>
                    <Text style={[pageStyle.td]} numberOfLines={1}>
                      {item?.userRecord?.boke}
                    </Text>
                  </View>
                  <View style={[pageStyle.col]}>
                    <Text style={[pageStyle.th]}>실점</Text>
                    <Text
                      style={[pageStyle.td, pageStyle.even]}
                      numberOfLines={1}>
                      {item?.userRecord?.r}
                    </Text>
                  </View>
                  <View style={[pageStyle.col]}>
                    <Text style={[pageStyle.th]} numberOfLines={1}>
                      자책점
                    </Text>
                    <Text style={[pageStyle.td]} numberOfLines={1}>
                      {item?.userRecord?.er}
                    </Text>
                  </View>
                  <View style={[pageStyle.col]}>
                    <Text style={[pageStyle.th]} numberOfLines={1}>
                      WHIP
                    </Text>
                    <Text
                      style={[pageStyle.td, pageStyle.even]}
                      numberOfLines={1}>
                      {item?.userRecord?.whip
                        ? parseFloat(item?.userRecord?.whip).toFixed(2)
                        : '0.00'}
                    </Text>
                  </View>
                </View>
                <View style={[pageStyle.row]}>
                  <View style={[pageStyle.col]}>
                    <Text style={[pageStyle.th]} numberOfLines={1}>
                      피안타율
                    </Text>
                    <Text style={[pageStyle.td]} numberOfLines={1}>
                      {item?.userRecord?.pavg
                        ? parseFloat(item?.userRecord?.pavg).toFixed(3)
                        : '0.000'}
                    </Text>
                  </View>
                  <View style={[pageStyle.col]}>
                    <Text style={[pageStyle.th]} numberOfLines={1}>
                      탈삼진율
                    </Text>
                    <Text
                      style={[pageStyle.td, pageStyle.even]}
                      numberOfLines={1}>
                      {item?.userRecord?.sorate
                        ? parseFloat(item?.userRecord?.sorate).toFixed(3)
                        : '0.000'}
                    </Text>
                  </View>
                  <View style={[pageStyle.col]} />
                  <View style={[pageStyle.col]} />
                  <View style={[pageStyle.col]} />
                  <View style={[pageStyle.col]} />
                </View>
              </>
            )}

            {expandGameId === item.id && toggle === 1 && (
              <>
                <Text>{JSON.stringify(data?.userRecord)}</Text>
                <View style={[pageStyle.row, pageStyle.firstRow]}>
                  <View style={[pageStyle.col]}>
                    <Text style={[pageStyle.th]} numberOfLines={1}>
                      포지션
                    </Text>
                    <Text style={[pageStyle.td]} numberOfLines={1}>
                      {item?.userRecord?.position}
                    </Text>
                  </View>
                  <View style={[pageStyle.col]}>
                    <Text style={[pageStyle.th]} numberOfLines={1}>
                      타율
                    </Text>
                    <Text
                      style={[pageStyle.td, pageStyle.even]}
                      numberOfLines={1}>
                      {item?.userRecord?.havg
                        ? parseFloat(item?.userRecord?.havg).toFixed(3)
                        : '0.000'}
                    </Text>
                  </View>
                  <View style={[pageStyle.col]}>
                    <Text style={[pageStyle.th]} numberOfLines={1}>
                      타석
                    </Text>
                    <Text style={[pageStyle.td]} numberOfLines={1}>
                      {item?.userRecord?.htb}
                    </Text>
                  </View>
                  <View style={[pageStyle.col]}>
                    <Text style={[pageStyle.th]} numberOfLines={1}>
                      타수
                    </Text>
                    <Text
                      style={[pageStyle.td, pageStyle.even]}
                      numberOfLines={1}>
                      {item?.userRecord?.hab}
                    </Text>
                  </View>
                  <View style={[pageStyle.col]}>
                    <Text style={[pageStyle.th]} numberOfLines={1}>
                      득점
                    </Text>
                    <Text style={[pageStyle.td]} numberOfLines={1}>
                      {item?.userRecord?.her}
                    </Text>
                  </View>
                  <View style={[pageStyle.col]}>
                    <Text style={[pageStyle.th]} numberOfLines={1}>
                      안타수
                    </Text>
                    <Text
                      style={[pageStyle.td, pageStyle.even]}
                      numberOfLines={1}>
                      {item?.userRecord?.h}
                    </Text>
                  </View>
                </View>
                <View style={[pageStyle.row]}>
                  <View style={[pageStyle.col]}>
                    <Text style={[pageStyle.th]} numberOfLines={1}>
                      1루타
                    </Text>
                    <Text style={[pageStyle.td]} numberOfLines={1}>
                      {item?.userRecord?.h1}
                    </Text>
                  </View>
                  <View style={[pageStyle.col]}>
                    <Text style={[pageStyle.th]} numberOfLines={1}>
                      2루타
                    </Text>
                    <Text
                      style={[pageStyle.td, pageStyle.even]}
                      numberOfLines={1}>
                      {item?.userRecord?.h2}
                    </Text>
                  </View>
                  <View style={[pageStyle.col]}>
                    <Text style={[pageStyle.th]} numberOfLines={1}>
                      3루타
                    </Text>
                    <Text style={[pageStyle.td]} numberOfLines={1}>
                      {item?.userRecord?.h3}
                    </Text>
                  </View>
                  <View style={[pageStyle.col]}>
                    <Text style={[pageStyle.th]} numberOfLines={1}>
                      홈런
                    </Text>
                    <Text
                      style={[pageStyle.td, pageStyle.even]}
                      numberOfLines={1}>
                      {item?.userRecord?.hr}
                    </Text>
                  </View>
                  <View style={[pageStyle.col]}>
                    <Text style={[pageStyle.th]} numberOfLines={1}>
                      루타
                    </Text>
                    <Text style={[pageStyle.td]} numberOfLines={1}>
                      {item?.userRecord?.totalBaseHit}
                    </Text>
                  </View>
                  <View style={[pageStyle.col]}>
                    <Text style={[pageStyle.th]} numberOfLines={1}>
                      타점
                    </Text>
                    <Text
                      style={[pageStyle.td, pageStyle.even]}
                      numberOfLines={1}>
                      {item?.userRecord?.rbi}
                    </Text>
                  </View>
                </View>
                <View style={[pageStyle.row]}>
                  <View style={[pageStyle.col]}>
                    <Text style={[pageStyle.th]} numberOfLines={1}>
                      도루
                    </Text>
                    <Text style={[pageStyle.td]} numberOfLines={1}>
                      {item?.userRecord?.sb}
                    </Text>
                  </View>
                  <View style={[pageStyle.col]}>
                    <Text style={[pageStyle.th]} numberOfLines={1}>
                      도루자
                    </Text>
                    <Text
                      style={[pageStyle.td, pageStyle.even]}
                      numberOfLines={1}>
                      {item?.userRecord?.sbo}
                    </Text>
                  </View>
                  <View style={[pageStyle.col]}>
                    <Text style={[pageStyle.th]} numberOfLines={1}>
                      희타
                    </Text>
                    <Text style={[pageStyle.td]} numberOfLines={1}>
                      {item?.userRecord?.hsacb}
                    </Text>
                  </View>
                  <View style={[pageStyle.col]}>
                    <Text style={[pageStyle.th]} numberOfLines={1}>
                      희비
                    </Text>
                    <Text
                      style={[pageStyle.td, pageStyle.even]}
                      numberOfLines={1}>
                      {item?.userRecord?.hsacf}
                    </Text>
                  </View>
                  <View style={[pageStyle.col]}>
                    <Text style={[pageStyle.th]} numberOfLines={1}>
                      볼넷
                    </Text>
                    <Text style={[pageStyle.td]} numberOfLines={1}>
                      {item?.userRecord?.hbb}
                    </Text>
                  </View>
                  <View style={[pageStyle.col]}>
                    <Text style={[pageStyle.th]} numberOfLines={1}>
                      고의사구
                    </Text>
                    <Text
                      style={[pageStyle.td, pageStyle.even]}
                      numberOfLines={1}>
                      {item?.userRecord?.hibb}
                    </Text>
                  </View>
                </View>
                <View style={[pageStyle.row]}>
                  <View style={[pageStyle.col]}>
                    <Text style={[pageStyle.th]} numberOfLines={1}>
                      사구
                    </Text>
                    <Text style={[pageStyle.td]} numberOfLines={1}>
                      {item?.userRecord?.hhitByPitch}
                    </Text>
                  </View>
                  <View style={[pageStyle.col]}>
                    <Text style={[pageStyle.th]} numberOfLines={1}>
                      삼진
                    </Text>
                    <Text
                      style={[pageStyle.td, pageStyle.even]}
                      numberOfLines={1}>
                      {item?.userRecord?.hso}
                    </Text>
                  </View>
                  <View style={[pageStyle.col]}>
                    <Text style={[pageStyle.th]} numberOfLines={1}>
                      병살
                    </Text>
                    <Text style={[pageStyle.td]} numberOfLines={1}>
                      {item?.userRecord?.do}
                    </Text>
                  </View>
                  <View style={[pageStyle.col]}>
                    <Text style={[pageStyle.th]} numberOfLines={1}>
                      장타율
                    </Text>
                    <Text
                      style={[pageStyle.td, pageStyle.even]}
                      numberOfLines={1}>
                      {item?.userRecord?.srate
                        ? parseFloat(item?.userRecord?.srate).toFixed(3)
                        : '0.000'}
                    </Text>
                  </View>
                  <View style={[pageStyle.col]}>
                    <Text style={[pageStyle.th]} numberOfLines={1}>
                      출루율
                    </Text>
                    <Text style={[pageStyle.td]} numberOfLines={1}>
                      {item?.userRecord?.obrate
                        ? parseFloat(item?.userRecord?.obrate).toFixed(3)
                        : '0.000'}
                    </Text>
                  </View>
                  <View style={[pageStyle.col]}>
                    <Text style={[pageStyle.th]} numberOfLines={1}>
                      도루성공률
                    </Text>
                    <Text
                      style={[pageStyle.td, pageStyle.even]}
                      numberOfLines={1}>
                      {item?.userRecord?.sbrate
                        ? parseFloat(item?.userRecord?.sbrate).toFixed(3)
                        : '0.000'}
                    </Text>
                  </View>
                </View>
                <View style={[pageStyle.row]}>
                  <View style={[pageStyle.col]}>
                    <Text style={[pageStyle.th]} numberOfLines={1}>
                      멀티히트
                    </Text>
                    <Text style={[pageStyle.td]} numberOfLines={1}>
                      {item?.userRecord?.muitihit}
                    </Text>
                  </View>
                  <View style={[pageStyle.col]}>
                    <Text style={[pageStyle.th]} numberOfLines={1}>
                      OPS
                    </Text>
                    <Text
                      style={[pageStyle.td, pageStyle.even]}
                      numberOfLines={1}>
                      {item?.userRecord?.ops
                        ? parseFloat(item?.userRecord?.ops).toFixed(3)
                        : '0.000'}
                    </Text>
                  </View>
                  <View style={[pageStyle.col]}>
                    <Text style={[pageStyle.th]} numberOfLines={1}>
                      BB/K
                    </Text>
                    <Text style={[pageStyle.td]} numberOfLines={1}>
                      {item?.userRecord?.bbk
                        ? parseFloat(item?.userRecord?.bbk).toFixed(3)
                        : '0.000'}
                    </Text>
                  </View>
                  <View style={[pageStyle.col]}>
                    <Text style={[pageStyle.th]} numberOfLines={1}>
                      장타/안타
                    </Text>
                    <Text
                      style={[pageStyle.td, pageStyle.even]}
                      numberOfLines={1}>
                      {item?.userRecord?.ohRate
                        ? parseFloat(item?.userRecord?.ohRate).toFixed(3)
                        : '0.000'}
                    </Text>
                  </View>
                  <View style={[pageStyle.col]}>
                    <Text style={[pageStyle.th]} numberOfLines={1} />
                    <Text style={[pageStyle.td]} numberOfLines={1} />
                  </View>
                  <View style={[pageStyle.col]}>
                    <Text style={[pageStyle.th]} numberOfLines={1} />
                    <Text
                      style={[pageStyle.td, pageStyle.even]}
                      numberOfLines={1}
                    />
                  </View>
                </View>
              </>
            )}
          </View>
        )}
        ListEmptyComponent={() => (
          <View style={[styles.emptyContainer]}>
            <Image source={require('../assets/images/logo2.png')} />
          </View>
        )}
      />
      {parseInt(gamePage.totalPage) > parseInt(gamePage.currentPage) &&
        gamePage.items &&
        gamePage.items.length > 0 && (
          <TouchableOpacity
            style={[{height: 80}]}
            onPress={() => callGamePage(accessToken.current, true, teamId)}>
            <Text
              style={[
                {
                  fontSize: 13,
                  fontFamily: 'Pretendard',
                  color: 'black',
                  textAlign: 'center',
                },
              ]}>
              더 보기
            </Text>
          </TouchableOpacity>
        )}
      {progressShown && (
        <View style={[styles.progressContainer]}>
          <Progress.Circle
            fill="transparent"
            size={70}
            borderWidth={3}
            indeterminate={true}
          />
        </View>
      )}
    </View>
  );
};

const pageStyle = StyleSheet.create({
  innerContainer: {
    paddingHorizontal: 29,
    paddingBottom: 50,
  },
  subtitle: {
    marginTop: 40,
    marginStart: 10,
    color: 'black',
    fontFamily: 'Pretendard-Bold',
    fontSize: 16,
    fontWeight: '700',
  },
  subtitleFirst: {
    marginTop: -10,
  },
  toggleContainer: {
    flexDirection: 'row',
    marginTop: 20,
    justifyContent: 'flex-end',
  },
  toggleItem: {
    width: 42,
    height: 24,
    borderWidth: 1,
    borderColor: '#ccc',
    justifyContent: 'center',
    display: 'flex',
  },
  toggleLeft: {
    borderTopStartRadius: 12,
    borderBottomStartRadius: 12,
    borderEndWidth: 0,
  },
  toggleRight: {
    borderTopEndRadius: 12,
    borderBottomEndRadius: 12,
  },
  toggleLeftSelected: {
    border: 0,
    backgroundColor: Colors.main,
  },
  toggleRightSelected: {
    border: 0,
    backgroundColor: Colors.main,
  },
  toggleTxt: {
    textAlign: 'center',
    alignSelf: 'center',
    color: 'black',
    fontWeight: '600',
    fontSize: 12,
    fontFamily: 'Pretendard-SemiBold',
  },
  toggleTxtSelected: {
    color: 'white',
  },
  row: {
    flexDirection: 'row',
  },
  firstRow: {
    marginTop: 10,
  },
  col: {
    flex: 1,
  },
  th: {
    backgroundColor: '#3f51b5',
    paddingVertical: 4,
    height: 22,
    lineHeight: 13,
    fontSize: 13,
    color: 'white',
    fontWeight: '700',
    fontFamily: 'Pretendard-Bold',
    textAlign: 'center',
  },
  td: {
    padding: 4,
    paddingVertical: 10,
    lineHeight: 13,
    fontSize: 13,
    color: 'black',
    fontWeight: '700',
    fontFamily: 'Pretendard-Bold',
    textAlign: 'center',
  },
  even: {
    backgroundColor: 'rgb(236, 236, 236)',
  },
  label: {
    width: 55,
    color: 'black',
    fontSize: 13,
    fontWeight: '600',
    fontFamily: 'Pretendard-SemiBold',
    alignSelf: 'center',
  },
  value: {
    color: 'black',
    fontFamily: 'Pretendard',
    fontSize: 13,
    alignSelf: 'center',
  },
  stadiumBg: {
    width: 140,
    height: 140,
    overflow: 'hidden',
    alignSelf: 'center',
    transform: [{scaleX: 1}, {rotate: '45deg'}],
  },
  stadiumBg1: {
    width: 220,
    height: 220,
    borderRadius: 110,
    backgroundColor: 'rgb(160, 169, 219)',
    justifyContent: 'center',
    alignItems: 'center',
  },
  stadiumBg2: {
    width: 104,
    height: 104,
    borderRadius: 52,
    backgroundColor: 'rgb(95, 119, 172)',
  },
  percentageContainer: {
    bottom: 0,
    position: 'absolute',
    alignSelf: 'center',
    width: 220,
    height: 220,
  },
  percentage: {
    position: 'absolute',
    bottom: 0,
    width: 40,
    height: 19,
    lineHeight: 19,
    borderRadius: 10,
    fontFamily: 'Pretendard',
    fontSize: 12,
    overflow: 'hidden',
    backgroundColor: '#ddd',
    textAlign: 'center',
  },
  pc: {
    bottom: -30,
    left: 87,
  },
  pp: {
    bottom: 10,
    left: 87,
  },
  b1: {
    bottom: 5,
    right: 20,
  },
  b2: {
    bottom: 45,
    right: 55,
  },
  ss: {
    bottom: 45,
    left: 55,
  },
  b3: {
    bottom: 5,
    left: 20,
  },
  lf: {
    bottom: 65,
    right: 25,
  },
  cf: {
    bottom: 90,
    left: 87,
  },
  rf: {
    bottom: 65,
    left: 25,
  },
});

export default MyRecordComponent;

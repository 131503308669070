import React, {useEffect, useMemo} from 'react';
import {Image, StyleSheet, Text, View} from 'react-native';
import moment from 'moment';
import 'moment/locale/ko';
import styles from '../common/styles';

const BoardCommentMetaItem = props => {
  useEffect(() => {}, [props.data]);

  const getImageUrl = data => {
    if (data.firstImageThumbUrl) {
      return {uri: data.firstImageThumbUrl};
    } else if (data.firstImageUrl) {
      return {uri: data.firstImageUrl};
    }
    return require('../assets/images/nologo.png');
  };

  const timeFormatted = useMemo(() => {
    const now = moment();
    const target = moment(props.data.createdAt);
    const seconds = now.diff(target, 'seconds');
    const minutes = now.diff(target, 'minutes');
    const hours = now.diff(target, 'hours');
    if (seconds < 60) {
      return '방금전';
    } else if (minutes < 60) {
      return `${minutes}분전`;
    } else if (hours < 24) {
      return `${hours}시간전`;
    }
    return moment(props.data.createdAt).format('YY.MM.DD  HH:mm');
  }, [props.data]);

  return (
    <View style={[pageStyle.itemContainer]}>
      <View style={[pageStyle.leftContainer]}>
        <View style={[pageStyle.contentsContainer]}>
          <Text style={[pageStyle.title]}>{props.data.contents}</Text>
          <Text style={[pageStyle.smallTxt]}>{timeFormatted}</Text>
          <Text style={[pageStyle.board]}>{props.data.board?.title}</Text>
        </View>
      </View>
    </View>
  );
};

const pageStyle = StyleSheet.create({
  itemContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 0,
    backgroundColor: 'white',
    position: 'relative',
    borderBottomColor: '#e6e6e6',
    borderBottomWidth: 1,
    paddingVertical: 12,
    paddingHorizontal: 12,
  },
  title: {
    color: '#000000',
    fontFamily: 'Pretendard',
    fontSize: 14,
    lineHeight: 14,
  },
  leftContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingEnd: 12,
  },
  rightContainer: {
    borderRadius: 5,
    width: 40,
    backgroundColor: '#ececec',
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'center',
  },
  commentCntTxt: {
    fontFamily: 'Pretendard-SemiBold',
    fontWeight: '600',
    color: '#000000',
    fontSize: 16,
  },
  commentTxt: {
    color: '#7c7c7c',
    fontFamily: 'Pretendard',
    fontSize: 12,
    marginTop: 0,
  },
  smallTxt: {
    color: '#7c7c7c',
    fontFamily: 'Pretendard',
    fontSize: 11,
    marginTop: 4,
  },
  board: {
    color: 'black',
    fontFamily: 'Pretendard',
    fontSize: 11,
    marginTop: 4,
  },
  contentsContainer: {
    paddingTop: 1,
  },
});

export default BoardCommentMetaItem;

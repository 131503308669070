import {useState} from 'react';
import React, {
  Image,
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
} from 'react-native';
import Colors from '../common/colors';

const RecordItem = ({
  data,
  currentRank,
  pitcherBatterToggle,
  exclude,
  showIndicator,
}) => {
  return (
    <View>
      <View style={[pageStyle.container]}>
        {pitcherBatterToggle === 0 && (
          <>
            <Text
              style={[pageStyle.td, currentRank === 'era' && pageStyle.blue]}>
              {data.era}
            </Text>
            {(!exclude || !exclude.includes('tgame')) && (
              <Text
                style={[
                  pageStyle.td,
                  currentRank === 'tgame' && pageStyle.blue,
                ]}>
                {data.tgame}
              </Text>
            )}
            <Text
              style={[pageStyle.td, currentRank === 'pgame' && pageStyle.blue]}>
              {data.pgame}
            </Text>
            <Text
              style={[
                pageStyle.td,
                currentRank === 'innings' && pageStyle.blue,
              ]}>
              {data.innings}
            </Text>
            <Text
              style={[pageStyle.td, currentRank === 'win' && pageStyle.blue]}>
              {data.win}
            </Text>
            <Text
              style={[pageStyle.td, currentRank === 'lose' && pageStyle.blue]}>
              {data.lose}
            </Text>
            <Text
              style={[pageStyle.td, currentRank === 'save' && pageStyle.blue]}>
              {data.save}
            </Text>
            <Text
              style={[pageStyle.td, currentRank === 'hold' && pageStyle.blue]}>
              {data.hold}
            </Text>
            <Text
              style={[pageStyle.td, currentRank === 'so' && pageStyle.blue]}>
              {data.so}
            </Text>
            <Text
              style={[pageStyle.td, currentRank === 'ph' && pageStyle.blue]}>
              {data.ph}
            </Text>
            <Text
              style={[pageStyle.td, currentRank === 'phr' && pageStyle.blue]}>
              {data.phr}
            </Text>
            <Text style={[pageStyle.td, currentRank === 'r' && pageStyle.blue]}>
              {data.r}
            </Text>
            <Text
              style={[pageStyle.td, currentRank === 'pbb' && pageStyle.blue]}>
              {data.pbb}
            </Text>
            <Text
              style={[
                pageStyle.td,
                currentRank === 'phitByPitch' && pageStyle.blue,
              ]}>
              {data.phitByPitch}
            </Text>
            <Text
              style={[pageStyle.td, currentRank === 'wrate' && pageStyle.blue]}>
              {data.wrate}
            </Text>
            <Text
              style={[pageStyle.td, currentRank === 'whip' && pageStyle.blue]}>
              {data.whip}
            </Text>
          </>
        )}
        {pitcherBatterToggle === 1 && (
          <>
            <Text
              style={[pageStyle.td, currentRank === 'havg' && pageStyle.blue]}>
              {data.havg}
            </Text>
            {(!exclude || !exclude.includes('tgame')) && (
              <Text
                style={[
                  pageStyle.td,
                  currentRank === 'tgame' && pageStyle.blue,
                ]}>
                {data.tgame}
              </Text>
            )}
            <Text
              style={[pageStyle.td, currentRank === 'hgame' && pageStyle.blue]}>
              {data.hgame}
            </Text>
            <Text
              style={[pageStyle.td, currentRank === 'htb' && pageStyle.blue]}>
              {data.htb}
            </Text>
            <Text
              style={[pageStyle.td, currentRank === 'hab' && pageStyle.blue]}>
              {data.hab}
            </Text>
            <Text style={[pageStyle.td, currentRank === 'h' && pageStyle.blue]}>
              {data.h}
            </Text>
            <Text
              style={[pageStyle.td, currentRank === 'h1' && pageStyle.blue]}>
              {data.h1}
            </Text>
            <Text
              style={[pageStyle.td, currentRank === 'h2' && pageStyle.blue]}>
              {data.h2}
            </Text>
            <Text
              style={[pageStyle.td, currentRank === 'h3' && pageStyle.blue]}>
              {data.h3}
            </Text>
            <Text
              style={[pageStyle.td, currentRank === 'hr' && pageStyle.blue]}>
              {data.hr}
            </Text>
            <Text
              style={[pageStyle.td, currentRank === 'rbi' && pageStyle.blue]}>
              {data.rbi}
            </Text>
            <Text
              style={[pageStyle.td, currentRank === 'her' && pageStyle.blue]}>
              {data.her}
            </Text>
            <Text
              style={[pageStyle.td, currentRank === 'sb' && pageStyle.blue]}>
              {data.sb}
            </Text>
            <Text
              style={[pageStyle.td, currentRank === 'hbb' && pageStyle.blue]}>
              {data.hbb}
            </Text>
            <Text
              style={[pageStyle.td, currentRank === 'hso' && pageStyle.blue]}>
              {data.hso}
            </Text>
            <Text
              style={[
                pageStyle.td,
                currentRank === 'obrate' && pageStyle.blue,
              ]}>
              {data.obrate}
            </Text>
            <Text
              style={[pageStyle.td, currentRank === 'srate' && pageStyle.blue]}>
              {data.srate}
            </Text>
            <Text
              style={[pageStyle.td, currentRank === 'ops' && pageStyle.blue]}>
              {data.ops}
            </Text>
          </>
        )}
      </View>
      <View style={[pageStyle.indicatorContainer]}>
        {showIndicator && pitcherBatterToggle === 0 && (
          <>
            <Text style={[pageStyle.tdIndicator]}>방어율</Text>
            {(!exclude || !exclude.includes('tgame')) && (
              <Text style={[pageStyle.tdIndicator]}>팀게임</Text>
            )}
            <Text style={[pageStyle.tdIndicator]}>선수게임</Text>
            <Text style={[pageStyle.tdIndicator]}>이닝</Text>
            <Text style={[pageStyle.tdIndicator]}>승</Text>
            <Text style={[pageStyle.tdIndicator]}>패</Text>
            <Text style={[pageStyle.tdIndicator]}>세이브</Text>
            <Text style={[pageStyle.tdIndicator]}>홀드</Text>
            <Text style={[pageStyle.tdIndicator]}>삼진</Text>
            <Text style={[pageStyle.tdIndicator]}>피안타</Text>
            <Text style={[pageStyle.tdIndicator]}>피홈런</Text>
            <Text style={[pageStyle.tdIndicator]}>실점</Text>
            <Text style={[pageStyle.tdIndicator]}>볼넷</Text>
            <Text style={[pageStyle.tdIndicator]}>사구</Text>
            <Text style={[pageStyle.tdIndicator]}>승률</Text>
            <Text style={[pageStyle.tdIndicator]}>WHIP</Text>
          </>
        )}
        {showIndicator && pitcherBatterToggle === 1 && (
          <>
            <Text style={[pageStyle.tdIndicator]}>타율</Text>
            {(!exclude || !exclude.includes('tgame')) && (
              <Text style={[pageStyle.tdIndicator]}>팀게임</Text>
            )}
            <Text style={[pageStyle.tdIndicator]}>선수게임</Text>
            <Text style={[pageStyle.tdIndicator]}>타석</Text>
            <Text style={[pageStyle.tdIndicator]}>타수</Text>
            <Text style={[pageStyle.tdIndicator]}>총안타</Text>
            <Text style={[pageStyle.tdIndicator]}>1루타</Text>
            <Text style={[pageStyle.tdIndicator]}>2루타</Text>
            <Text style={[pageStyle.tdIndicator]}>3루타</Text>
            <Text style={[pageStyle.tdIndicator]}>홈런</Text>
            <Text style={[pageStyle.tdIndicator]}>타점</Text>
            <Text style={[pageStyle.tdIndicator]}>득점</Text>
            <Text style={[pageStyle.tdIndicator]}>도루</Text>
            <Text style={[pageStyle.tdIndicator]}>볼넷</Text>
            <Text style={[pageStyle.tdIndicator]}>삼진</Text>
            <Text style={[pageStyle.tdIndicator]}>출루율</Text>
            <Text style={[pageStyle.tdIndicator]}>장타율</Text>
            <Text style={[pageStyle.tdIndicator]}>OPS</Text>
          </>
        )}
      </View>
    </View>
  );
};
export default RecordItem;

const pageStyle = StyleSheet.create({
  container: {
    position: 'relative',
    height: 45,
    flexDirection: 'row',
    paddingVertical: 7,
    borderBottomWidth: 1,
    borderBottomColor: 'rgba(0, 0, 0, 0.1)',
  },
  indicatorContainer: {
    position: 'absolute',
    flexDirection: 'row',
  },
  rank: {
    width: 45,
    paddingStart: 10,
    alignItems: 'center',
    justifyContent: 'center',
    alignContent: 'center',
    color: 'black',
    alignSelf: 'center',
    textAlign: 'center',
  },
  td: {
    width: 47,
    textAlign: 'center',
    color: 'black',
    fontFamily: 'Pretendard-Light',
    fontSize: 12,
    fontWeight: '300',
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'center',
  },
  td2: {
    width: 65,
    color: 'black',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'center',
  },
  tdIndicator: {
    top: -5,
    width: 47,
    height: 18,
    textAlign: 'center',
    color: 'white',
    fontSize: 9.5,
    lineHeight: 15.5,
    fontWeight: 'bold',
    fontFamily: 'Pretendard-Bold',
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'center',
    backgroundColor: Colors.main,
  },
  blue: {
    color: Colors.main,
    fontWeight: 'bold',
    fontFamily: 'Pretendard-Bold',
  },
});

import React, {useEffect, useState, useRef} from 'react';
import {
  Button,
  Image,
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  TextInput,
  Dimensions,
  Platform,
} from 'react-native';
import {ScrollView} from 'react-native-gesture-handler';
import {SafeAreaView} from 'react-native-safe-area-context';
import SelectDropdown from 'react-native-select-dropdown';
import styles from '../common/styles';
import api from '../common/api';
import {useSafeAreaInsets} from 'react-native-safe-area-context';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {padStart} from 'lodash';
import {Modal} from 'react-native';

const FindPwdView = ({navigation, route}) => {
  const insets = useSafeAreaInsets();
  const [modalOpened, setModalOpened] = useState(false);
  const [authType, setAuthType] = useState();
  const [email, setEmail] = useState();
  const [inputs, setInputs] = useState({
    email: '',
    cellphone: '',
    code: '',
  });
  const [uid, setUid] = useState('');
  const [emailWarning, setEmailWarning] = useState(false);
  const [passwordWarning, setPasswordWarning] = useState(false);
  const [passwordConfirmWarning, setPasswordConfirmWarning] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [passwordConfirmVisible, setPasswordConfirmVisible] = useState(false);
  const [passwordChecked, setPasswordChecked] = useState(false);
  const [passwordConfirmChecked, setPasswordConfirmChecked] = useState(false);

  useEffect(() => {}, []);
  const validateEmail = e => {
    const filter =
      /^\s*[\w\-\+_]+(\.[\w\-\+_]+)*\@[\w\-\+_]+\.[\w\-\+_]+(\.[\w\-\+_]+)*\s*$/;
    return String(e).search(filter) != -1;
  };

  const onChangeEmail = value => {
    setEmailWarning(!validateEmail(value));
    setInputs({
      ...inputs,
      ['email']: value,
    });
  };

  const onChangeCellphone = value => {
    let digit = value.replace(/[^0-9]/g, '');
    setInputs({
      ...inputs,
      ['cellphone']: digit,
    });
  };

  const onChangeCode = value => {
    let digit = value.replace(/[^0-9]/g, '');
    setInputs({
      ...inputs,
      ['code']: digit,
    });
  };

  const validatePassword = v => {
    const regex =
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
    return v.match(regex);
  };

  const onPasswordChange = value => {
    setPasswordWarning(!validatePassword(value));
    setPasswordChecked(validatePassword(value));

    setInputs({
      ...inputs,
      ['password']: value,
    });
  };

  const onPasswordConfirmChange = value => {
    setPasswordConfirmWarning(!validatePassword(value));
    setPasswordConfirmChecked(validatePassword(value));

    setInputs({
      ...inputs,
      ['passwordConfirm']: value,
    });
  };

  const requestCode = () => {
    if (
      !inputs.cellphone ||
      (inputs.cellphone.length !== 11 && inputs.cellphone.length !== 10)
    ) {
      return;
    }

    api
      .post('/certification/code', {cellphone: inputs.cellphone})
      .then(function (response) {
        alert('요청에 성공했습니다.');
      })
      .catch(function (error) {
        console.log(error.response);
        alert('요청에 실패했습니다.');
      });
  };

  const validateInputs = () => {
    if (!inputs.email || !inputs.cellphone || !inputs.code) {
      alert('필수 값을 입력해 주세요.');
      return false;
    }

    return true;
  };

  const findPwd = () => {
    if (!validateInputs()) {
      return;
    }
    api
      .get(
        `/users/find-uid?email=${inputs.email}&cellphone=${inputs.cellphone}&code=${inputs.code}`,
      )
      .then(function (response) {
        if (!response.data) {
          alert('일치하는 계정 정보가 없습니다.');
          setUid('');
        } else {
          setUid(response.data.uid);
          setModalOpened(true);
        }
      })
      .catch(function (error) {
        console.log(error.response, error);
        alert(error.response.data.message);
      });
  };

  const changePwd = () => {
    if (!inputs.password || !inputs.passwordConfirm) {
      alert('필수 값을 입력해 주세요.');
      return false;
    }
    if (inputs.password !== inputs.passwordConfirm) {
      alert('비밀번호가 일치하지 않습니다.');
      return false;
    }
    api
      .post('/users/pwd', {password: inputs.password, uid: uid})
      .then(function (response) {
        if (!response.data) {
          alert('일치하는 계정 정보가 없습니다.');
        } else {
          alert('요청에 성공했습니다.');
          setModalOpened(false);
          Platform.OS === 'web' ? history.back() : navigation.goBack();
        }
      })
      .catch(function (error) {
        console.log(error.response, error);
        alert(error.response.data.message);
      });
  };
  return (
    <SafeAreaView nativeID="common-page-container" style={pageStyle.container}>
      <ScrollView style={pageStyle.scrollView}>
        <View style={[styles.headerCommon, {backgroundColor: 'transparent'}]}>
          <TouchableOpacity
            onPress={() =>
              Platform.OS === 'web' ? history.back() : navigation.goBack()
            }>
            <Image
              source={require('../assets/images/icon_back_wh.png')}
              style={[styles.headerBackButton]}
            />
          </TouchableOpacity>
        </View>
        <View style={[pageStyle.scrollViewContainer]}>
          <Image
            style={pageStyle.logo}
            source={require('../assets/images/logo.png')}
          />
          <View style={pageStyle.formContainer}>
            <View>
              <View style={[pageStyle.subtitleContainer, pageStyle.mt16]}>
                <Text style={[pageStyle.subtitle]}>이메일</Text>
                {emailWarning && (
                  <Text style={[pageStyle.warningTxt]}>
                    *올바른 이메일 주소가 아닙니다.
                  </Text>
                )}
              </View>
              <TextInput
                style={[pageStyle.input]}
                type="email"
                placeholder="이메일"
                placeholderTextColor={'#aaa'}
                value={inputs.email}
                onChangeText={value => onChangeEmail(value)}
              />
              <Text style={[pageStyle.subtitle]}>휴대전화</Text>
              <View style={[pageStyle.row, {alignItems: 'flex-end'}]}>
                <View style={[pageStyle.flex1]}>
                  <TextInput
                    style={[pageStyle.input]}
                    placeholder="전화번호입력"
                    placeholderTextColor={'#aaa'}
                    maxLength={11}
                    value={inputs.cellphone}
                    onChangeText={onChangeCellphone}
                  />
                </View>
                <TouchableOpacity
                  style={[pageStyle.requestCodeBtn]}
                  onPress={requestCode}>
                  <Text style={[pageStyle.buttonSmallTxt]}>인증번호받기</Text>
                </TouchableOpacity>
              </View>
              <View style={[pageStyle.row, {alignItems: 'flex-end'}]}>
                <View style={[pageStyle.flex1]}>
                  <TextInput
                    style={[pageStyle.input, pageStyle.mt16]}
                    placeholder="인증번호 입력"
                    placeholderTextColor={'#aaa'}
                    maxLength={8}
                    value={inputs.code}
                    onChangeText={onChangeCode}
                  />
                </View>
              </View>
            </View>
            <TouchableOpacity style={[pageStyle.commonBtn]} onPress={findPwd}>
              <Text style={[pageStyle.buttonSmallTxt]}>비밀번호찾기</Text>
            </TouchableOpacity>
          </View>
        </View>
        {modalOpened && (
          <Modal transparent={true} overFullScreen={true}>
            <View style={[pageStyle.popupContainer]}>
              <View
                style={[
                  pageStyle.innerContainer,
                  {marginTop: insets.top + 67},
                ]}>
                <View style={[pageStyle.formContainer, {marginTop: 0}]}>
                  <View style={[pageStyle.subtitleContainer, pageStyle.mt16]}>
                    <Text style={[pageStyle.subtitle]}>비밀번호</Text>
                    {passwordWarning && (
                      <Text style={[pageStyle.warningTxt]}>
                        *영소문자, 숫자, 특수문자 최소 1개씩의 조합. 8자 이상
                      </Text>
                    )}
                  </View>
                  <View style={[pageStyle.passwordInputContainer]}>
                    <TextInput
                      style={[pageStyle.input]}
                      placeholder="비밀번호"
                      placeholderTextColor={'#aaa'}
                      type="password"
                      value={inputs.password}
                      onChangeText={onPasswordChange}
                      secureTextEntry={!passwordVisible}
                    />
                    <View style={[pageStyle.inputIconContainer]}>
                      <TouchableOpacity
                        onPress={() => setPasswordVisible(!passwordVisible)}>
                        <Image
                          style={[pageStyle.invisibleIcn]}
                          source={
                            !passwordVisible
                              ? require('../assets/images/invisible.png')
                              : require('../assets/images/visible.png')
                          }
                        />
                      </TouchableOpacity>
                      <Image
                        style={[
                          pageStyle.checkIcn,
                          !passwordChecked && {display: 'none'},
                        ]}
                        source={require('../assets/images/checked.png')}
                      />
                    </View>
                  </View>
                  <View style={[pageStyle.subtitleContainer, pageStyle.mt16]}>
                    <Text style={[pageStyle.subtitle]}>비밀번호 재확인</Text>
                    {passwordConfirmWarning && (
                      <Text style={[pageStyle.warningTxt]}>
                        *영소문자, 숫자, 특수문자 최소 1개씩의 조합. 8자 이상
                      </Text>
                    )}
                  </View>
                  <View style={[pageStyle.passwordInputContainer]}>
                    <TextInput
                      style={[pageStyle.input]}
                      placeholder="비밀번호 재확인"
                      placeholderTextColor={'#aaa'}
                      type="password"
                      value={inputs.passwordConfirm}
                      onChangeText={onPasswordConfirmChange}
                      secureTextEntry={!passwordConfirmVisible}
                    />
                    <View style={[pageStyle.inputIconContainer]}>
                      <TouchableOpacity
                        onPress={() =>
                          setPasswordConfirmVisible(!passwordConfirmVisible)
                        }>
                        <Image
                          style={[pageStyle.invisibleIcn]}
                          source={
                            !passwordConfirmVisible
                              ? require('../assets/images/invisible.png')
                              : require('../assets/images/visible.png')
                          }
                        />
                      </TouchableOpacity>
                      <Image
                        style={[
                          pageStyle.checkIcn,
                          !passwordConfirmChecked && {display: 'none'},
                        ]}
                        source={require('../assets/images/checked.png')}
                      />
                    </View>
                  </View>
                </View>
                <TouchableOpacity
                  onPress={changePwd}
                  style={[pageStyle.commonBtn, {width: 120}]}>
                  <Text style={[pageStyle.buttonTxt]}>비밀번호 변경</Text>
                </TouchableOpacity>
              </View>
            </View>
          </Modal>
        )}
      </ScrollView>
    </SafeAreaView>
  );
};

const pageStyle = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    backgroundColor: 'rgb(23, 53, 100)',
  },
  scrollView: {
    width: '100%',
  },
  scrollViewContainer: {
    paddingHorizontal: 32,
    paddingBottom: 37,
    alignItems: 'center',
  },
  popupContainer: {
    zIndex: 100,
    width: '100%',
    height: Dimensions.get('window').height,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  innerContainer: {
    backgroundColor: 'white',
    width: 300,
    marginHorizontal: Dimensions.get('window').width / 2 - 150,
    top: Dimensions.get('window').height / 2 - 150,
    textAlign: 'center',
    padding: 11,
    alignItems: 'center',
    paddingBottom: 40,
  },
  logo: {},
  formContainer: {
    width: '100%',
    backgroundColor: 'white',
    marginTop: 23,
    borderRadius: 20,
    padding: 25,
  },
  subtitleContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  subtitle: {
    fontFamily: 'Pretendard',
    fontSize: 13,
    color: 'black',
    lineHeight: 15,
    marginTop: 8,
  },
  value: {
    fontFamily: 'Pretendard-Bold',
    fontSize: 15,
    color: 'black',
    marginTop: 3,
    fontWeight: 'bold',
  },
  warningTxt: {
    paddingTop: 2,
    color: '#ff0000',
    fontFamily: 'Pretendard',
    fontSize: 8,
    paddingStart: 5,
  },
  input: {
    fontFamily: 'Pretendard',
    fontSize: 14,
    borderBottomColor: '#909090',
    borderBottomWidth: 1,
    marginTop: 8,
    paddingBottom: 4,
  },
  mt16: {
    marginTop: 16,
  },
  mt18: {
    marginTop: 18,
  },
  mh7: {
    marginHorizontal: 7,
  },
  ms7: {
    marginStart: 7,
  },
  passwordInputContainer: {
    position: 'relative',
  },
  inputIconContainer: {
    display: 'flex',
    position: 'absolute',
    alignContent: 'center',
    alignItems: 'center',
    right: 8,
    flexDirection: 'row',
    bottom: 6,
  },
  invisibleIcn: {
    marginEnd: 6,
    width: 20,
    height: 15,
    resizeMode: 'cover',
  },
  commonBtn: {
    marginTop: 25,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 15,
    height: 30,
    backgroundColor: 'rgb(61, 80, 183)',
  },
  buttonTxt: {
    fontFamily: 'Pretendard',
    fontSize: 13,
    color: 'white',
  },
  buttonSmallTxt: {
    fontFamily: 'Pretendard',
    fontSize: 10,
    color: 'white',
  },
  dropDownContainer: {
    position: 'relative',
  },
  dropDownMonth: {
    width: '100%',
    backgroundColor: 'white',
    borderWidth: 1,
    borderColor: 'rgb(61, 80, 183)',
    height: 24,
    position: 'absolute',
    bottom: 0,
    marginTop: 10,
  },
  dropDown: {
    width: '100%',
    backgroundColor: 'white',
    borderWidth: 1,
    borderColor: 'rgb(61, 80, 183)',
    height: 24,
    marginTop: 10,
  },
  downDownText: {
    color: 'black',
    fontFamily: 'Pretendard',
    fontSize: 11,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
  col: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  col2: {
    flex: 2,
    display: 'flex',
    flexDirection: 'column',
  },
  flex1: {
    flex: 1,
  },
  requestCodeBtn: {
    width: 94,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 15,
    height: 25,
    backgroundColor: 'rgb(61, 80, 183)',
  },
});

export default FindPwdView;

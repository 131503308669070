import React, {
  Image,
  StyleSheet,
  Text,
  View,
  TextInput,
  TouchableOpacity,
} from 'react-native';
import Colors from '../common/colors';
import styles from '../common/styles';

const BoardSummaryItem = props => {
  return (
    <View style={[pageStyle.itemContainer]}>
      <Text style={[pageStyle.type]}>[{props.data.type}]</Text>
      <Text style={[pageStyle.title]}>{props.data.title}</Text>
    </View>
  );
};
export default BoardSummaryItem;
const pageStyle = StyleSheet.create({
  itemContainer: {
    display: 'flex',
    flexDirection: 'row',
    paddingVertical: 5,
  },
  type: {
    color: Colors.main,
    fontSize: 12,
    fontFamily: 'Pretendard',
    marginEnd: 3,
  },
  title: {color: 'black', fontFamily: 'Pretendard', fontSize: 12},
});

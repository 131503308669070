import React, {useEffect, useState} from 'react';
import IMP from 'iamport-react-native';
import IMPWeb from '../component/iamport-react-native-custom';

import Loading from './Loading';
import styles from '../common/styles';
import {
  Dimensions,
  Image,
  Modal,
  SafeAreaView,
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  Platform,
} from 'react-native';
import axios from 'axios';
import Colors from '../common/colors';
import api from '../common/api';
import AsyncStorage from '@react-native-async-storage/async-storage';
import moment from 'moment';

const CertificationView = ({navigation, route}) => {
  /* 가맹점 식별코드, 본인인증 데이터 추출 */
  const {userCode, data, accessToken, callback} = route.params;

  const [modelOpened, setModalOpened] = useState(true);

  const authByUidAndCertificationId = (uid, certificationId) => {
    api
      .post('/users/authentications-by-certification-id', {
        uid: uid,
        certificationId: certificationId,
      })
      .then(function (response) {
        try {
          AsyncStorage.setItem(
            '@accessToken',
            response.headers['access-token'],
          );
          AsyncStorage.setItem('@userInfo', JSON.stringify(response.data));
          navigation.reset({
            index: 0,
            routes: [{name: 'Main'}],
          });
        } catch (e) {
          // saving error
        }
      })
      .catch(function (error) {
        console.log(error.response);
        if (error.response.status === 401) {
          alert('이메일 비밀번호가 일치하지 않습니다.');
        } else if (error.response.data?.message) {
          alert(error.response.data?.message);
        } else {
          alert('요청에 실패했습니다.');
        }
      });
  };
  /* 본인인증 후 실행될 콜백 함수 입력 */
  const certificationCallback = async response => {
    console.log(response);
    console.log('certification response');
    // {"imp_uid": "imp_322156800746", "merchant_uid": "mid_1694236155529", "request_id": "req_1694236156499", "success": "true"}
    const isSuccessed = getIsSuccessed(response);
    if (isSuccessed) {
      const imp_uid = response.imp_uid;
      /* 본인인증 성공한 경우, 리디렉션 위해 홈으로 이동한다 */
      let certificationsData = null;
      console.log('#1', moment().format('YYYY-MM-DD HH:mm:ss'));
      if (Platform.OS === 'web') {
        certificationsData = await api.get(
          `/certification/danal-certification?impUid=${imp_uid}`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          },
        );
        certificationsData = certificationsData.data;
      } else {
        const getToken = await axios({
          url: 'https://api.iamport.kr/users/getToken',
          method: 'post',
          headers: {'Content-Type': 'application/json'},
          data: {
            imp_key: '4324112415341833', // REST API키
            imp_secret:
              'NbxbgFgHpps9uVmIAO8Aen2OGN0SxYPlesugQlsaYKbEtCQ6EbRdouKvoSpKWoiML7Sgt15EzEhFZ0Da', // REST API Secret
          },
        });
        console.log('#2', moment().format('YYYY-MM-DD HH:mm:ss'));
        const {access_token} = getToken.data.response; // 인증 토큰
        console.log('access_token', access_token, 'imp_uid', imp_uid);
        const getCertifications = await axios({
          url: `https://api.iamport.kr/certifications/${imp_uid}`,
          method: 'get',
          headers: {Authorization: access_token},
        });
        certificationsData = getCertifications.data; // 조회한 인증 정보
        console.log('#3', moment().format('YYYY-MM-DD HH:mm:ss'));
      }
      console.log('certificationData', certificationsData);

      const {
        unique_key,
        unique_in_site,
        name,
        gender,
        birth,
        birthday,
        phone,
        carrier,
      } = certificationsData.response;
      if (callback.screen === 'Main') {
        api
          .post(
            '/app-user/certifications',
            {
              unique_key,
              unique_in_site,
              name,
              gender,
              birth,
              birthday,
              phone,
              carrier,
            },
            {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            },
          )
          .then(function (response) {
            AsyncStorage.setItem(
              '@accessToken',
              response.headers['access-token'],
            );
            AsyncStorage.setItem(
              '@userInfo',
              JSON.stringify(response.data),
              () => {
                navigation.reset({
                  index: 0,
                  routes: [{name: 'Main'}],
                });
              },
            );
          })
          .catch(function (error) {
            console.log(error.response);
            alert('요청에 실패했습니다.');
          });
      } else {
        api
          .post('/users/exists/check', {
            certificationId: unique_key,
          })
          .then(function (response) {
            console.log('#4', moment().format('YYYY-MM-DD HH:mm:ss'));
            console.log('response.data', response.data);
            if (response.data) {
              alert(
                '동일 정보로 인증받은 이력이 있습니다. 기존 인증 받은 계정으로 로그인 합니다.',
              );
              const uid = response.data;
              api
                .post('/users/auth', {
                  thirdPartyUserId: callback?.data?.thirdPartyUserId,
                  authType: callback?.data?.authType,
                  uid: uid,
                })
                .then(function (authResponse) {
                  authByUidAndCertificationId(uid, unique_key);
                })
                .catch(function (error) {
                  console.error(error);
                });
            } else {
              callback.data.name = name;
              callback.data.birthYear = birthday.split('-')[0];
              callback.data.birthMonth = birthday.split('-')[1];
              callback.data.birthDay = birthday.split('-')[2];
              if (gender !== 'male') {
                callback.data.gender = '여성';
              } else {
                callback.data.gender = '남성';
              }
              callback.data.cellphone = phone;
              callback.data.danalCertified = true;
              callback.data.certification = {
                unique_key: unique_key,
                unique_in_site: unique_in_site,
                name: name,
                gender: gender,
                birth: birth,
                birthday: birthday,
                phone: phone,
                carrier: carrier,
              };
              navigation.replace(callback.screen, {user: callback.data});
            }
          })
          .catch(function (error) {
            console.log(error.response);
          });
      }
    } else {
      /* 본인인증 실패한 경우, 이전 화면으로 돌아간다 */
      navigation.goBack();
    }
  };

  function getIsSuccessed(response) {
    const {success} = response;

    if (typeof success === 'string') {
      return success === 'true';
    }
    if (typeof success === 'boolean') {
      return success === true;
    }
  }

  const startCertification = () => {
    setModalOpened(false);
  };

  useEffect(() => {
    console.log('callback', JSON.stringify(callback));
    if (!callback || !callback.data) {
      Platform.OS === 'web'
        ? navigation.reset({
            index: 0,
            routes: [{name: 'Splash'}],
          })
        : navigation.goBack();
    }
  }, [callback]);

  return (
    <SafeAreaView style={[pageStyle.safeArea]}>
      <View style={[pageStyle.container]}>
        <View style={[pageStyle.innerContainer]}>
          <View
            style={{
              justifyContent: 'flex-end',
              flexDirection: 'row',
              height: 34,
              paddingRight: 20,
              backgroundColor: 'transparent',
            }}>
            <TouchableOpacity
              style={{}}
              onPress={() =>
                Platform.OS === 'web'
                  ? navigation.reset({
                      index: 0,
                      routes: [{name: 'Splash'}],
                    })
                  : navigation.goBack()
              }>
              <Image
                source={require('../assets/images/close.png')}
                style={[{width: 34, height: 34}]}
              />
            </TouchableOpacity>
          </View>
          <View style={[pageStyle.contentsContainer]}>
            {Platform.OS !== 'web' && (
              <IMP.Certification
                userCode={userCode}
                loading={<Loading />}
                data={{
                  ...data,
                  app_scheme: 'uniqueplay',
                }}
                callback={certificationCallback}
              />
            )}
            {Platform.OS === 'web' && (
              <IMPWeb.Certification
                userCode={userCode}
                loading={<Loading />}
                data={{
                  ...data,
                  app_scheme: 'uniqueplay',
                }}
                callback={certificationCallback}
              />
            )}
          </View>
        </View>
      </View>
      {modelOpened && (
        <Modal transparent={true} overFullScreen={true}>
          <View style={[pageStyle.popupContainer]}>
            <View style={[pageStyle.popupInnerContainer]}>
              <Text style={[pageStyle.popupTxt]}>
                유니크 플레이 서비스 사용을 위해 본인 인증이 최초 1회
                필요합니다.
                {'\n\n'}
                (사유: 선수 계정 통합, 나이 제한 등 리그 가입 정책 확인)
                {'\n\n\n'}
                이전에{' '}
                <Text style={[pageStyle.strong]}>
                  동일 이름, 생년월일 등으로 가입된 여러 개의 동일 유니크플레이
                  계정
                </Text>
                이 있다면,{' '}
                <Text style={[pageStyle.strong]}>
                  본인 인증 후에는 하나의 계정으로 통합
                </Text>{' '}
                됩니다.
                {'\n\n\n'}
                인증 완료 후 관련 문의가 있다면 유니크플레이 고객센터로 문의해
                주세요.
              </Text>
              <TouchableOpacity
                style={[pageStyle.btn]}
                onPress={startCertification}>
                <Text style={[pageStyle.btnText]}>인증 시작하기</Text>
              </TouchableOpacity>
            </View>
          </View>
        </Modal>
      )}
    </SafeAreaView>
  );
};

export default CertificationView;

const pageStyle = StyleSheet.create({
  safeArea: {
    flex: 1,
  },
  container: {
    flex: 1,
    zIndex: 100,
    width: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  innerContainer: {
    width: '100%',
    backgroundColor: 'white',
    flex: 1,
  },
  contentsContainer: {
    flex: 1,
    position: 'relative',
  },
  popupContainer: {
    zIndex: 100,
    width: '100%',
    height: Dimensions.get('window').height,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
  },
  popupInnerContainer: {
    backgroundColor: 'white',
    width: 300,
    marginHorizontal:
      (Platform.OS === 'web' ? 550 : Dimensions.get('window').width) / 2 - 150,
    textAlign: 'center',
    padding: 14,
    alignItems: 'center',
    paddingBottom: 40,
  },
  popupTxt: {
    color: 'black',
    fontSize: 16,
    fontFamily: 'Pretendard',
  },
  strong: {
    fontFamily: 'Pretendard-Bold',
    fontWeight: 'bold',
    color: Colors.main,
    textDecorationLine: 'underline',
  },
  btn: {
    width: 130,
    height: 34,
    backgroundColor: Colors.main,
    borderRadius: 17,
    alignItems: 'center',
    alignContent: 'center',
    justifyContent: 'center',
    marginTop: 65,
  },
  btnText: {
    color: 'white',
    fontSize: 18,
    fontWeight: 'bold',
    fontFamily: 'Pretendard-Bold',
  },
});

import {useState, useEffect, useMemo} from 'react';
import React, {
  Image,
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  ImageBackground,
  Dimensions,
  Linking,
  Platform,
  Alert,
  DeviceEventEmitter,
} from 'react-native';
import {Shadow} from 'react-native-shadow-2';
import Colors from '../common/colors';
import styles from '../common/styles';
import VideoSelectionPopup from '../component/VideoSelectionPopup';
import LineUpPopup from '../component/team/LineUpPopup';
import LineUpFormPopup from '../component/team/LineUpFormPopup';
import moment from 'moment';
import 'moment/locale/ko';
import alert from './alert';

const GameMetaItem = props => {
  const [videoPopupOpened, setVideoPopupOpened] = useState(false);
  const [lineUpFormPopupOpened, setLineUpFormPopupOpened] = useState(false);
  const [lineUpPopupOpened, setLineUpPopupOpened] = useState(false);

  useEffect(() => {}, [props.data.id]);

  useEffect(() => {
    const unsubscribe = DeviceEventEmitter.addListener(
      '@showLineUpPopup',
      function (data) {
        if (data?.gameId === props.data.id) {
          setLineUpPopupOpened(true);
        }
      },
    );

    return () => {
      unsubscribe.remove();
    };
  }, []);

  const showNeedLoginAlert = () => {
    alert(
      '',
      '해당 페이지는 로그인 후 이용해주세요',
      [
        {
          text: '계속 둘러보기',
          onPress: () => {},
          style: 'cancel',
        },
        {
          text: '확인',
          onPress: () => {
            props?.navigation?.reset({
              index: 0,
              routes: [{name: 'Login'}],
            });
          },
        },
      ],
      {
        cancelable: true,
        onDismiss: () => {},
      },
    );
  };

  const parseDate = date => {
    if (date.indexOf('T') > -1) {
      return moment(date.substring(0, date.indexOf('T')), 'YYYY-MM-DD').format(
        'MM/DD',
      );
    }
    return moment(date, 'YYYY-MM-DD').locale('ko').format('MM/DD dd');
  };

  const parseTime = time => {
    if (time.indexOf(':', 4) > -1) {
      return time.substring(0, time.indexOf(':', 4));
    }
    return time;
  };

  const getLineUpText = item => {
    if (item.batFirstTeamManagedByMe || item.batLastTeamManagedByMe) {
      if (['경기전', '게임연기'].includes(item.gameStatus)) {
        return item.lineUpAvailable ? '라인업 수정' : '라인업 제출';
      }
    }

    return '라인업 확인';
  };

  const getLineUpActiveStatus = item => {
    if (item.batFirstTeamManagedByMe || item.batLastTeamManagedByMe) {
      if (['경기전', '게임연기'].includes(item.gameStatus)) {
        return true;
      }
    }

    return item.lineUpAvailable;
  };

  const handleClickLineUp = item => {
    if (
      ['게임종료', '콜드승'].includes(item.gameStatus) &&
      item.lineUpAvailable
    ) {
      setLineUpPopupOpened(true);
      return;
    }
    if (item.batFirstTeamManagedByMe || item.batLastTeamManagedByMe) {
      if (['경기전', '게임연기'].includes(item.gameStatus)) {
        setLineUpFormPopupOpened(true);
        return;
      }
    }
    if (item.lineUpAvailable) {
      setLineUpPopupOpened(true);
    }
  };

  const getBackgroundImage = gameStatus => {
    if (['경기전', '게임연기'].includes(gameStatus)) {
      return require('../assets/images/game_meta_bg_blue.png');
    } else if (gameStatus === '경기중') {
      return require('../assets/images/game_meta_bg_purple.png');
    } else if (['콜드승', '게임종료'].includes(gameStatus)) {
      return require('../assets/images/game_meta_bg_grey.png');
    } else if (['몰수게임', '추첨승', '기권승'].includes(gameStatus)) {
      return require('../assets/images/game_meta_bg_pink.png');
    }
    return require('../assets/images/game_meta_bg_grey.png');
  };

  const getStatusBg = gameStatus => {
    if (['경기전', '게임연기'].includes(gameStatus)) {
      return pageStyle.bgStatusBefore;
    } else if (gameStatus === '경기중') {
      return pageStyle.bgStatusInProgress;
    } else if (['콜드승', '게임종료'].includes(gameStatus)) {
      return pageStyle.bgStatusEnd;
    } else if (['몰수게임', '추첨승', '기권승'].includes(gameStatus)) {
      return pageStyle.bgStatusForfeit;
    }
    return pageStyle.bgStatusEnd;
  };

  useEffect(() => {
    // console.log(props.data);
  }, [props.data?.id]);

  const gameStatusTxt = useMemo(() => {
    if (
      ['플레이오프'].includes(props.data?.resultType) &&
      '게임연기' === props.data?.gameStatus
    ) {
      return '게임연기';
    } else if (['콜드승'].includes(props.data?.resultType)) {
      return '콜드승';
    } else if (props.data?.playoff) {
      return '플옵경기';
    }
    return props.data?.gameStatus;
  }, [props.data?.gameStatus, props.data?.resultType, props.data?.playoff]);

  const goToVideoView = () => {
    if (props?.tourMode) {
      showNeedLoginAlert();
      return;
    }
    props.navigation.navigate('Video', {
      game: props.data,
      itemId: props.data.id,
    });
  };

  const videoUrlIsChannel = useMemo(() => {
    if (
      props.data?.fullVideoUrl &&
      props.data?.fullVideoUrl.indexOf('channel') > -1
    ) {
      return true;
    }
    return false;
  }, [props.data?.fullVideoUrl]);

  const existsVideo = useMemo(() => {
    if (!props.data?.fullVideoUrl) {
      return false;
    }
    if (props.data?.fullVideoUrl.indexOf('channel') > -1) {
      return true;
    }
    return true;
  }, [props.data?.fullVideoUrl]);

  const fullWidth = useMemo(() => {
    if (Platform.OS === 'web') {
      const windowWidth = Dimensions.get('window').width;
      if (windowWidth < 550) {
        return windowWidth;
      } else {
        return 550;
      }
    }
    return '100%';
  }, []);

  const pageStyle = StyleSheet.create({
    container: {
      paddingTop: 9.5,
      paddingBottom: 11,
    },
    bgImage: {
      position: 'absolute',
      top: 0,
      width: '100%',
      height:
        Platform.OS === 'web'
          ? fullWidth / 2.565
          : Dimensions.get('window').width / 2.565,
    },
    row: {
      display: 'flex',
      flexDirection: 'row',
      backgroundColor: 'transparent',
    },
    topRowContainer: {
      justifyContent: 'space-between',
      paddingStart: 20,
      paddingEnd: 20,
      paddingBottom: fullWidth >= 550 ? 18 : 11,
      marginTop: fullWidth >= 550 ? 3 : 0,
    },
    date: {
      color: 'white',
      fontSize: 15,
      lineHeight: 15,
      fontWeight: '500',
      fontFamily: 'Pretendard-Medium',
    },
    subLeagueName: {
      fontSize: 15,
      lineHeight: 15,
      fontWeight: '600',
      fontFamily: 'Pretendard-SemiBold',
      paddingTop: 2,
    },
    subLeagueNameStatusForfeit: {
      color: 'rgb(229, 0, 128)',
    },
    subLeagueNameStatusBefore: {
      color: 'rgb(61, 80, 183)',
    },
    subLeagueNameStatusDelay: {
      color: 'rgb(255, 232, 0)',
    },
    subLeagueNameStatusInProgress: {
      color: 'rgb(128, 0, 128)',
    },
    subLeagueNameStatusEnd: {
      color: 'rgb(96, 96, 96)',
    },
    bgStatusForfeit: {
      // backgroundColor: 'rgb(229, 0, 128)',
      backgroundColor: 'transparent',
      lineHeight: 18,
      height: 18,
    },
    bgStatusBefore: {
      // backgroundColor: 'rgb(61, 80, 183)',
      backgroundColor: 'transparent',
      lineHeight: 18,
      height: 18,
    },
    bgStatusInProgress: {
      // backgroundColor: 'rgb(128, 0, 128)',
      backgroundColor: 'transparent',
      lineHeight: 18,
      height: 18,
    },
    bgStatusEnd: {
      // backgroundColor: 'rgb(96, 96, 96)',
      backgroundColor: 'transparent',
      lineHeight: 18,
      height: 18,
    },
    teamContainer: {
      flex: 1,
    },
    meta: {
      justifyContent: 'center',
      alignItems: 'center',
      marginHorizontal: 4.7,
    },
    metaLeagueName: {
      fontSize: 13,
      color: 'black',
      fontWeight: '400',
      fontFamily: 'Pretendard-Regular',
      textAlign: 'center',
    },
    metaTxt: {
      marginTop: 2,
      fontSize: 12,
      color: 'black',
      fontWeight: '300',
      fontFamily: 'Pretendard-Light',
      textAlign: 'center',
    },
    scoreContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      marginTop: 7,
    },
    scoreItemContainer: {
      marginHorizontal: 7,
      fontSize: 17,
      fontWeight: '600',
      fontFamily: 'Pretendard-SemiBold',
    },
    batFirstTeamContainer: {
      justifyContent: 'flex-end',
      alignItems: 'flex-end',
    },
    batLastTeamContainer: {
      justifyContent: 'flex-end',
      alignItems: 'flex-start',
    },
    logoContainer: {
      width: 50,
      height: 50,
      borderRadius: 25,
      overflow: 'hidden',
      backgroundColor: 'white',
      // borderColor: '#eee',
      // borderWidth: 1,
    },
    logoImg: {
      width: 50,
      height: 50,
    },
    central: {
      marginTop: 6,
    },
    teamName: {
      color: 'black',
      fontFamily: 'Pretendard-SemiBold',
      fontWeight: '600',
      fontSize: 15,
      width: 135,
      textAlign: 'center',
    },
    batFirstTeamName: {
      marginEnd: -20,
      textAlign: 'center',
    },
    batLastTeamName: {
      marginStart: -20,
      textAlign: 'center',
    },
    statusBox: {
      width: 49,
      height: 18,
      fontSize: 10.5,
      color: 'white',
      fontWeight: '300',
      fontFamily: 'Pretendard-Light',
      borderRadius: 9,
      textAlign: 'center',
      lineHeight: 17.5,
    },
    statusBoxDelay: {
      color: '#371D1E',
      fontFamily: 'Pretendard-Medium',
    },
    statusNormal: {
      backgroundColor: '#bbb',
      borderRadius: 7.5,
      borderWidth: 0,
    },
    statusForfeit: {
      backgroundColor: 'rgb(229, 0, 128)',
    },
    statusBefore: {
      backgroundColor: 'rgb(61, 80, 183)',
      borderRadius: 7.5,
      borderWidth: 0,
    },
    statusDelay: {
      backgroundColor: 'rgb(255, 232, 0)',
      borderRadius: 7.5,
      borderWidth: 0,
    },
    statusInProgress: {
      backgroundColor: 'rgb(128, 0, 128)',
      borderRadius: 7.5,
      borderWidth: 0,
    },
    statusEnd: {
      backgroundColor: 'rgb(96, 96, 96)',
      borderRadius: 7.5,
      borderWidth: 0,
    },
    buttonContainer: {
      marginTop: 8,
    },
    button: {
      height: 28,
      borderWidth: 1,
      borderColor: '#ddd',
      alignItems: 'center',
      borderRadius: 13.5,
      display: 'flex',
      justifyContent: 'center',
    },
    buttonText: {
      color: 'black',
      fontSize: 14,
      fontFamily: 'Pretendard',
      textAlign: 'center',
    },
    buttonActive: {
      borderWidth: 1,
      borderColor: 'black',
    },
    buttonActiveTxt: {
      color: 'black',
    },
    buttonNeedSubmit: {
      borderWidth: 0,
      backgroundColor: Colors.main,
    },
    buttonNeedSubmitTxt: {
      color: 'white',
    },
  });

  return (
    <View style={[pageStyle.container]}>
      <ImageBackground
        resizeMode={Platform.OS === 'web' ? 'contain' : 'cover'}
        source={getBackgroundImage(props.data?.gameStatus)}
        style={[pageStyle.bgImage, props.index === 0 && {borderBottomWidth: 0}]}
      />
      <View
        style={[
          {
            backgroundColor: 'white',
            position: 'absolute',
            bottom: 0,
            width: '100%',
            height: 9,
          },
        ]}
      />
      <View style={[pageStyle.row, pageStyle.topRowContainer]}>
        <View style={[getStatusBg(props.data?.gameStatus), {flex: 1}]}>
          <Text style={[pageStyle.date]} numberOfLines={1}>
            {parseDate(props.data?.date)} {parseTime(props.data?.time)}
          </Text>
        </View>
        <View style={[{flex: 1, alignItems: 'flex-end'}]}>
          <Text
            style={[
              pageStyle.subLeagueName,
              ['몰수게임', '추첨승', '기권승'].includes(
                props.data?.gameStatus,
              ) && pageStyle.subLeagueNameStatusForfeit,
              ['경기전', '게임연기'].includes(props.data?.gameStatus) &&
                pageStyle.subLeagueNameStatusBefore,
              props.data?.gameStatus === '경기중' &&
                pageStyle.subLeagueNameStatusInProgress,
              ['게임종료', '콜드승'].includes(props.data?.gameStatus) &&
                pageStyle.subLeagueNameStatusEnd,
            ]}>
            {props.data?.subLeagueName}
          </Text>
        </View>
      </View>
      <View style={[pageStyle.row]}>
        <TouchableOpacity
          onPress={() => {
            if (props?.tourMode) {
              showNeedLoginAlert();
              return;
            }
            props.navigation.navigate('TeamDetail', {
              itemId: props.data?.batFirstTeamId,
            });
          }}
          style={[
            pageStyle.teamContainer,
            pageStyle.batFirstTeamContainer,
            Platform.OS !== 'web' && {
              width: Dimensions.get('window').width / 3.42,
            },
            Platform.OS === 'web' && {flex: 1},
            {paddingEnd: 24},
          ]}>
          <View style={[pageStyle.logoContainer]}>
            <Image
              style={[
                pageStyle.logoImg,
                !props.data.batFirstTeamLogoImageUrl && {opacity: 0.4},
              ]}
              source={
                !props.data?.batFirstTeamLogoImageUrl
                  ? require('../assets/images/nologo.png')
                  : {uri: props.data?.batFirstTeamLogoImageUrl}
              }
              resizeMode={'cover'}
            />
          </View>
        </TouchableOpacity>
        <View
          style={[
            pageStyle.meta,
            Platform.OS !== 'web' && {
              width: Dimensions.get('window').width / 3.42,
            },
            Platform.OS === 'web' && {flex: 1},
          ]}>
          <View>
            <Text style={[pageStyle.metaLeagueName]}>
              {props.data?.leagueName}
            </Text>
            <Text style={[pageStyle.metaTxt]}>{props.data?.stadiumName}</Text>
            <View style={[pageStyle.scoreContainer]}>
              <Text style={[pageStyle.scoreItemContainer]}>
                {props.data?.records &&
                JSON.parse(props.data?.records)?.batFirstTeam
                  ? JSON.parse(props.data?.records)?.batFirstTeam?.score
                  : '-'}
              </Text>
              <Text>:</Text>
              <Text style={[pageStyle.scoreItemContainer]}>
                {props.data?.records &&
                JSON.parse(props.data?.records)?.batLastTeam
                  ? JSON.parse(props.data?.records)?.batLastTeam?.score
                  : '-'}
              </Text>
            </View>
          </View>
        </View>
        <TouchableOpacity
          onPress={() => {
            if (props?.tourMode) {
              showNeedLoginAlert();
              return;
            }
            props.navigation.navigate('TeamDetail', {
              itemId: props.data?.batLastTeamId,
            });
          }}
          style={[
            pageStyle.teamContainer,
            pageStyle.batLastTeamContainer,
            Platform.OS !== 'web' && {
              width: Dimensions.get('window').width / 3.42,
            },
            Platform.OS === 'web' && {flex: 1},
            {paddingStart: 24},
          ]}>
          <View style={[pageStyle.logoContainer]}>
            <Image
              style={[
                pageStyle.logoImg,
                !props.data.batLastTeamLogoImageUrl && {opacity: 0.4},
              ]}
              source={
                !props.data?.batLastTeamLogoImageUrl
                  ? require('../assets/images/nologo.png')
                  : {uri: props.data?.batLastTeamLogoImageUrl}
              }
              resizeMode={'cover'}
            />
          </View>
        </TouchableOpacity>
      </View>
      <View style={[pageStyle.row, pageStyle.central]}>
        <View
          style={[
            pageStyle.teamContainer,
            pageStyle.batFirstTeamContainer,
            Platform.OS !== 'web' && {
              width: Dimensions.get('window').width / 3.42,
            },
            Platform.OS === 'web' && {flex: 1},
          ]}>
          <Text
            style={[pageStyle.teamName, pageStyle.batFirstTeamName]}
            numberOfLines={1}>
            {props.data?.batFirstTeamName}
          </Text>
        </View>
        <View
          style={[
            pageStyle.meta,
            Platform.OS !== 'web' && {
              width: Dimensions.get('window').width / 3.42,
            },
            Platform.OS === 'web' && {flex: 1},
          ]}>
          <View
            style={[
              pageStyle.statusNormal,
              ['몰수게임', '추첨승', '기권승'].includes(
                props.data?.gameStatus,
              ) && pageStyle.statusForfeit,
              ['경기전'].includes(props.data?.gameStatus) &&
                pageStyle.statusBefore,
              ['게임연기'].includes(props.data?.gameStatus) &&
                pageStyle.statusDelay,
              props.data?.gameStatus === '경기중' && pageStyle.statusInProgress,
              ['게임종료', '콜드승'].includes(props.data?.gameStatus) &&
                pageStyle.statusEnd,
            ]}>
            <Text
              style={[
                pageStyle.statusBox,
                ['게임연기'].includes(props.data?.gameStatus) &&
                  pageStyle.statusBoxDelay,
              ]}>
              {gameStatusTxt}
            </Text>
          </View>
        </View>
        <View
          style={[
            pageStyle.teamContainer,
            pageStyle.batLastTeamContainer,
            Platform.OS !== 'web' && {
              width: Dimensions.get('window').width / 3.42,
            },
            Platform.OS === 'web' && {flex: 1},
          ]}>
          <Text
            style={[pageStyle.teamName, pageStyle.batLastTeamName]}
            numberOfLines={1}>
            {props.data?.batLastTeamName}
          </Text>
        </View>
      </View>
      <View style={[pageStyle.row, pageStyle.buttonContainer]}>
        <View
          style={[
            pageStyle.teamContainer,
            pageStyle.batFirstTeamContainer,
            Platform.OS !== 'web' && {
              width: Dimensions.get('window').width / 3.42,
            },
            Platform.OS === 'web' && {
              flex: 1,
              flexDirection: 'row',
              marginHorizontal: 8,
            },
          ]}>
          <TouchableOpacity
            onPress={() => handleClickLineUp(props.data)}
            style={[
              pageStyle.button,
              Platform.OS !== 'web' && {
                width: Dimensions.get('window').width / 3.42,
              },
              Platform.OS === 'web' && {flex: 1},
              ['라인업 제출', '라인업 수정'].includes(
                getLineUpText(props.data),
              ) && pageStyle.buttonNeedSubmit,
              getLineUpActiveStatus(props.data) && pageStyle.buttonActive,
            ]}>
            <Text
              style={[
                pageStyle.buttonText,
                ['라인업 제출', '라인업 수정'].includes(
                  getLineUpText(props.data),
                ) && pageStyle.buttonNeedSubmitTxt,
                !['라인업 제출', '라인업 수정'].includes(
                  getLineUpText(props.data),
                ) &&
                  getLineUpActiveStatus(props.data) &&
                  pageStyle.buttonActiveTxt,
              ]}>
              {getLineUpText(props.data)}
            </Text>
          </TouchableOpacity>
        </View>
        <View
          style={[
            pageStyle.meta,
            Platform.OS !== 'web' && {
              width: Dimensions.get('window').width / 3.42,
            },
            Platform.OS === 'web' && {
              flex: 1,
              flexDirection: 'row',
              marginHorizontal: 8,
            },
          ]}>
          <TouchableOpacity
            onPress={() => {
              if (props?.tourMode) {
                showNeedLoginAlert();
                return;
              }
              (props.data?.gameStatus === '게임종료' ||
                props.data?.gameStatus === '콜드승' ||
                props.data?.gameStatus === '몰수게임' ||
                props.data?.gameStatus === '경기중') &&
                props.navigation.navigate('BoxScore', {
                  data: props.data,
                  itemId: props.data.id,
                });
            }}
            disabled={
              props.data?.gameStatus !== '게임종료' &&
              props.data?.gameStatus !== '콜드승' &&
              props.data?.gameStatus !== '몰수게임' &&
              props.data?.gameStatus !== '경기중'
            }
            style={[
              pageStyle.button,
              Platform.OS !== 'web' && {
                width: Dimensions.get('window').width / 3.42,
              },
              Platform.OS === 'web' && {flex: 1},
              (props.data?.gameStatus === '게임종료' ||
                props.data?.gameStatus === '콜드승' ||
                props.data?.gameStatus === '몰수게임' ||
                props.data?.gameStatus === '경기중') &&
                pageStyle.buttonActive,
            ]}>
            <Text
              style={[
                pageStyle.buttonText,
                (props.data?.gameStatus === '게임종료' ||
                  props.data?.gameStatus === '콜드승' ||
                  props.data?.gameStatus === '몰수게임' ||
                  props.data?.gameStatus === '경기중') &&
                  pageStyle.buttonActiveTxt,
              ]}>
              박스스코어
            </Text>
          </TouchableOpacity>
        </View>
        <View
          style={[
            pageStyle.teamContainer,
            pageStyle.batLastTeamContainer,
            Platform.OS !== 'web' && {
              width: Dimensions.get('window').width / 3.42,
            },
            Platform.OS === 'web' && {
              flex: 1,
              flexDirection: 'row',
              marginHorizontal: 8,
            },
          ]}>
          <TouchableOpacity
            onPress={() =>
              !videoUrlIsChannel
                ? goToVideoView()
                : Linking.openURL(props.data?.fullVideoUrl)
            }
            disabled={!props.data?.fullVideoUploaded && !existsVideo}
            style={[
              Platform.OS !== 'web' && {
                width: Dimensions.get('window').width / 3.42,
              },
              Platform.OS === 'web' && {flex: 1},
              pageStyle.button,
              existsVideo && pageStyle.buttonActive,
            ]}>
            <Text
              style={[
                pageStyle.buttonText,
                existsVideo && pageStyle.buttonActiveTxt,
              ]}>
              경기 영상
            </Text>
          </TouchableOpacity>
        </View>
      </View>
      {videoPopupOpened && (
        <VideoSelectionPopup
          hotClipVideoUrl={props.data?.hotClipVideoUrl}
          fullVideoUrl={props.data?.fullVideoUrl}
          closePopup={() => setVideoPopupOpened(false)}
        />
      )}
      {lineUpPopupOpened && (
        <LineUpPopup
          navigateToMemberPage={(item, itemId) => {
            props.navigation.navigate('MemberPage', {
              item: item,
              itemId: itemId,
              from: 'lineUpPopup',
              gameId: props.data.id,
            });
            setLineUpPopupOpened(false);
          }}
          navigation={props?.navigation}
          data={props.data}
          closePopup={() => setLineUpPopupOpened(false)}
        />
      )}
      {lineUpFormPopupOpened && (
        <LineUpFormPopup
          data={props.data}
          closePopup={() => setLineUpFormPopupOpened(false)}
        />
      )}
    </View>
  );
};

export default GameMetaItem;

import React, {useState, useEffect, useRef} from 'react';
import {
  Image,
  SafeAreaView,
  Text,
  TouchableOpacity,
  View,
  StyleSheet,
  ImageBackground,
  Dimensions,
  Alert,
  Platform,
  Modal,
  ScrollView,
} from 'react-native';
import styles from '../common/styles';
import {Shadow} from 'react-native-shadow-2';
import AsyncStorage from '@react-native-async-storage/async-storage';
import alert from '../component/alert';
import {title} from '../common/util';

const SettingView = ({navigation, route}) => {
  const handleLogout = () => {
    alert('로그아웃', '로그아웃 하시겠습니까?', [
      {
        text: '예',
        onPress: () => {
          AsyncStorage.removeItem('@accessToken', () => {
            navigation.reset({
              index: 0,
              routes: [{name: 'Login'}],
            });
          });
        },
      },
      {
        text: '아니오',
      },
    ]);
  };

  return (
    <SafeAreaView nativeID="common-page-container" style={[styles.container]}>
      <Shadow
        offset={[0, 2]}
        style={[{width: '100%'}]}
        containerStyle={[{marginTop: 0}]}
        distance={7}
        sides={{bottom: true, top: false, start: false, end: false}}>
        <View style={[styles.headerCommon]}>
          <View style={[styles.row, styles.verticalAlign]}>
            <TouchableOpacity
              onPress={() =>
                Platform.OS === 'web' ? history.back() : navigation.goBack()
              }>
              <Image
                source={require('../assets/images/icon_back.png')}
                style={[styles.headerBackButton]}
              />
            </TouchableOpacity>
            <Text style={[styles.headerText]}>설정</Text>
          </View>
          <View style={[styles.row, styles.verticalAlign]} />
        </View>
      </Shadow>
      <ScrollView>
        <View style={[pageStyle.container]}>
          <TouchableOpacity
            onPress={() => navigation.navigate('MyAccount')}
            style={[pageStyle.row]}>
            <Image
              style={[pageStyle.icnImg]}
              resizeMode="contain"
              source={require('../assets/images/ic_my_account.png')}
            />
            <Text style={[pageStyle.txt]}>내 계정</Text>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => navigation.navigate('ChangePassword')}
            style={[pageStyle.row]}>
            <Image
              style={[pageStyle.icnImg]}
              resizeMode="contain"
              source={require('../assets/images/ic_my_password.png')}
            />
            <Text style={[pageStyle.txt]}>계정 비밀번호 변경</Text>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => navigation.navigate('PlayerInfo')}
            style={[pageStyle.row]}>
            <Image
              style={[pageStyle.icnImg]}
              resizeMode="contain"
              source={require('../assets/images/ic_player_info.png')}
            />
            <Text style={[pageStyle.txt]}>선수 정보 관리</Text>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => navigation.navigate('ExternalRecord')}
            style={[pageStyle.row]}>
            <Image
              style={[pageStyle.icnImg]}
              resizeMode="contain"
              source={require('../assets/images/pencil-outline.png')}
            />
            <Text style={[pageStyle.txt]}>외부 기록 추가</Text>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => navigation.navigate('MyBoard')}
            style={[pageStyle.row]}>
            <Image
              style={[pageStyle.icnImg]}
              resizeMode="contain"
              source={require('../assets/images/note-edit-outline.png')}
            />
            <Text style={[pageStyle.txt]}>내 글 보기</Text>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => navigation.navigate('SettingAlarm')}
            style={[pageStyle.row]}>
            <Image
              style={[pageStyle.icnImg]}
              resizeMode="contain"
              source={require('../assets/images/ic_my_alarm.png')}
            />
            <Text style={[pageStyle.txt]}>알림</Text>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => navigation.navigate('Notice')}
            style={[pageStyle.row]}>
            <Image
              style={[pageStyle.icnImg]}
              resizeMode="contain"
              source={require('../assets/images/ic_notice.png')}
            />
            <Text style={[pageStyle.txt]}>공지사항</Text>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => navigation.navigate('CustomerService')}
            style={[pageStyle.row]}>
            <Image
              style={[pageStyle.icnImg]}
              resizeMode="contain"
              source={require('../assets/images/ic_customer_service.png')}
            />
            <Text style={[pageStyle.txt]}>고객센터/운영정책</Text>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => navigation.navigate('Faq')}
            style={[pageStyle.row]}>
            <Image
              style={[pageStyle.icnImg]}
              resizeMode="contain"
              source={require('../assets/images/icn_faq.png')}
            />
            <Text style={[pageStyle.txt]}>FAQ</Text>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => navigation.navigate('BlockUser')}
            style={[pageStyle.row]}>
            <Image
              style={[pageStyle.icnImg]}
              resizeMode="contain"
              source={require('../assets/images/account-cancel.png')}
            />
            <Text style={[pageStyle.txt]}>차단 사용자 관리</Text>
          </TouchableOpacity>
          <TouchableOpacity onPress={handleLogout} style={[pageStyle.row]}>
            <Image
              style={[pageStyle.icnImg]}
              resizeMode="contain"
              source={require('../assets/images/logout.png')}
            />
            <Text style={[pageStyle.txt]}>로그아웃</Text>
          </TouchableOpacity>
        </View>
      </ScrollView>
    </SafeAreaView>
  );
};

const pageStyle = StyleSheet.create({
  headerCommon: {},
  navBgImage: {
    marginStart: 0,
    position: 'absolute',
    width: Dimensions.get('window').width,
    height: '100%',
  },
  headerText: {color: 'white'},
  manageTeamBtn: {
    width: 60,
    height: 22,
    borderRadius: 12,
    borderWidth: 1,
    borderColor: 'white',
    backgroundColor: 'transparent',
    justifyContent: 'center',
    alignItems: 'center',
    marginEnd: 14,
  },
  container: {
    paddingBottom: 80,
  },
  row: {
    flexDirection: 'row',
    alignContent: 'center',
    alignItems: 'center',
    borderBottomColor: '#e6e6e6',
    borderBottomWidth: 1,
    paddingVertical: 12,
    paddingHorizontal: 36,
  },
  icnImg: {
    width: 30,
    height: 40,
    marginEnd: 18,
  },
  txt: {
    fontFamily: 'Pretendard',
    fontSize: 17,
    color: 'black',
  },
});

export default SettingView;

import React, {useEffect, useRef, useState} from 'react';
import {useSafeAreaInsets} from 'react-native-safe-area-context';
import {createBottomTabNavigator} from '@react-navigation/bottom-tabs';
import {
  Image,
  Text,
  DeviceEventEmitter,
  Platform,
  View,
  StyleSheet,
  Dimensions,
  Modal,
} from 'react-native';
import LeagueView from './LeagueView';
import HomeView from './HomeView';
import TeamView from './TeamView';
import MyPageView from './MyPageView';
import CommunityView from './CommunityView';
import AsyncStorage from '@react-native-async-storage/async-storage';
import api from '../common/api';
import {title} from '../common/util';
import 'moment/locale/ko';
import alert from '../component/alert';

const Tab = createBottomTabNavigator();

const MainView = props => {
  const insets = useSafeAreaInsets();
  const accessToken = useRef();
  const [tourMode, setTourMode] = useState(props?.route?.params?.tourMode);
  useEffect(() => {
    checkCertification();
  }, []);

  const requestCertify = () => {
    console.log('requestCertify in MainView');
    /* 2. 본인인증 데이터 정의하기 */
    const data = {
      merchant_uid: `mid_${new Date().getTime()}`, // 주문번호
      company: '(주) 유니크플레이', // 회사명 또는 URL
    };
    props.navigation?.navigate('Certification', {
      userCode: 'imp66015233',
      // userCode: 'iamport',
      data: data,
      accessToken: accessToken.current,
      callback: {
        screen: 'Main',
        data: {},
      },
    });
  };

  const checkCertification = () => {
    AsyncStorage.getItem('@accessToken', (err, result) => {
      if (err) {
        return;
      }
      setTourMode(!result);
      if (result) {
        accessToken.current = result;

        savePushToken();

        api
          .get('/app-user/me', {
            headers: {
              Authorization: `Bearer ${result}`,
            },
          })
          .then(function (response) {
            console.log(response.data.name, response.data.certificationId);
            if (!response.data.certificationId) {
              console.log('in MainView');
              requestCertify();
            }
          })
          .catch(function (error) {
            console.log(error.response);
            if (error.response.status === 401) {
              alert('탈퇴처리된 계정입니다.');
              AsyncStorage.removeItem('@accessToken', () => {
                navigation.reset({
                  index: 0,
                  routes: [{name: 'Login'}],
                });
              });
            }
          });
      }
    });
  };

  const savePushToken = () => {
    AsyncStorage.getItem('@pushToken', (err, result) => {
      if (err) {
        return;
      }
      if (!result) {
        return;
      }
      if (!accessToken.current) {
        return;
      }
      const fcmToken = result;
      const postData = {token: fcmToken};
      api
        .post('/users/me/push-token', postData, {
          headers: {
            Authorization: `Bearer ${accessToken.current}`,
          },
        })
        .then(function (response) {
          console.log('푸시토큰 저장 완료');
        })
        .catch(function (error) {
          console.error('error update push token');
          console.error(error);
        });
    });
  };

  const showNeedLoginAlert = () => {
    alert(
      '',
      '해당 페이지는 로그인 후 이용해주세요',
      [
        {text: '계속 둘러보기', onPress: () => {}, style: 'cancel'},
        {
          text: '확인',
          onPress: () => {
            props?.navigation?.reset({
              index: 0,
              routes: [{name: 'Login'}],
            });
          },
        },
      ],
      {
        cancelable: true,
        onDismiss: () => {},
      },
    );
  };

  return (
    <View style={{flex: 1}} nativeID="common-page-container">
      <Tab.Navigator
        initialRouteName={tourMode ? 'League' : 'Home'}
        screenOptions={{
          headerShown: false,
        }}>
        <Tab.Screen
          name="Team"
          component={TeamView}
          options={{
            title: title('팀'),
            tabBarLabel: ({focused, color, size}) => (
              <Text
                style={[
                  {
                    fontFamily: focused ? 'Pretendard-SemiBold' : 'Pretendard',
                    fontSize: 10,
                    marginTop: 4,
                    color: focused ? '#3f51b5' : color,
                  },
                  Platform.OS === 'web' && {position: 'absolute', bottom: 0},
                ]}>
                팀
              </Text>
            ),
            tabBarStyle: {
              height: insets.bottom + 68,
              paddingTop: 6,
              paddingBottom: insets.bottom + 10,
              position: 'relative',
            },
            tabBarIcon: ({focused, color, size}) =>
              focused ? (
                <Image
                  name="team"
                  style={[
                    {width: 35, height: 29},
                    Platform.OS === 'web' && {position: 'absolute', top: 0},
                  ]}
                  resizeMode={'contain'}
                  source={require('../assets/images/icn_team_on.png')}
                />
              ) : (
                <Image
                  name="team"
                  style={[
                    {width: 35, height: 29},
                    Platform.OS === 'web' && {position: 'absolute', top: 0},
                  ]}
                  resizeMode={'contain'}
                  source={require('../assets/images/icn_team.png')}
                />
              ),
          }}
          listeners={({navigation, route}) => ({
            tabPress: e => {
              if (tourMode) {
                e.preventDefault();
                showNeedLoginAlert();
                return;
              }
              DeviceEventEmitter.emit('teamTabInfoRefresh', {});
            },
          })}
        />
        <Tab.Screen
          name="League"
          component={LeagueView}
          options={{
            title: title('리그'),
            tabBarLabel: ({focused, color, size}) => (
              <Text
                style={[
                  {
                    fontFamily: focused ? 'Pretendard-SemiBold' : 'Pretendard',
                    justifyContent: 'center',
                    fontSize: 10,
                    marginTop: 4,
                    color: focused ? '#3f51b5' : color,
                  },
                  Platform.OS === 'web' && {position: 'absolute', bottom: 0},
                ]}>
                리그
              </Text>
            ),
            tabBarStyle: {
              height: insets.bottom + 68,
              paddingTop: 6,
              paddingBottom: insets.bottom + 10,
              position: 'relative',
            },
            tabBarIcon: ({focused, color, size}) =>
              focused ? (
                <Image
                  name="league"
                  style={[
                    {width: 32.6, height: 29},
                    Platform.OS === 'web' && {position: 'absolute', top: 0},
                  ]}
                  resizeMode={'contain'}
                  source={require('../assets/images/icn_league_on.png')}
                />
              ) : (
                <Image
                  name="league"
                  style={[
                    {width: 32.6, height: 29},
                    Platform.OS === 'web' && {position: 'absolute', top: 0},
                  ]}
                  resizeMode={'contain'}
                  source={require('../assets/images/icn_league.png')}
                />
              ),
          }}
          listeners={({navigation, route}) => ({
            tabPress: e => {
              DeviceEventEmitter.emit('leagueTabPress', {});
            },
          })}
        />
        <Tab.Screen
          name="Home"
          component={HomeView}
          options={{
            title: title('홈'),
            tabBarLabel: ({focused, color, size}) => (
              <Text
                style={[
                  {
                    fontFamily: focused ? 'Pretendard-SemiBold' : 'Pretendard',
                    fontSize: 10,
                    marginTop: 4,
                    color: focused ? '#3f51b5' : color,
                  },
                  Platform.OS === 'web' && {position: 'absolute', bottom: 0},
                ]}>
                홈
              </Text>
            ),
            tabBarStyle: {
              height: insets.bottom + 68,
              paddingTop: 6,
              paddingBottom: insets.bottom + 10,
              position: 'relative',
            },
            tabBarIcon: ({focused, color, size}) =>
              focused ? (
                <Image
                  name="home"
                  style={[
                    {width: 30.3, height: 29},
                    Platform.OS === 'web' && {position: 'absolute', top: 0},
                  ]}
                  resizeMode={'contain'}
                  source={require('../assets/images/icn_home_selected.png')}
                />
              ) : (
                <Image
                  name="home"
                  style={[
                    {width: 30.3, height: 29},
                    Platform.OS === 'web' && {position: 'absolute', top: 0},
                  ]}
                  resizeMode={'contain'}
                  source={require('../assets/images/icn_home.png')}
                />
              ),
          }}
          listeners={({navigation, route}) => ({
            tabPress: e => {
              if (tourMode) {
                e.preventDefault();
                showNeedLoginAlert();
                return;
              }
              DeviceEventEmitter.emit('homeTabPress', {});
            },
          })}
        />
        <Tab.Screen
          name="Community"
          component={CommunityView}
          options={{
            title: title('커뮤니티'),
            tabBarLabel: ({focused, color, size}) => (
              <Text
                style={[
                  {
                    fontFamily: focused ? 'Pretendard-SemiBold' : 'Pretendard',
                    fontSize: 10,
                    marginTop: 4,
                    color: focused ? '#3f51b5' : color,
                  },
                  Platform.OS === 'web' && {position: 'absolute', bottom: 0},
                ]}>
                커뮤니티
              </Text>
            ),
            tabBarStyle: {
              height: insets.bottom + 68,
              paddingTop: 6,
              paddingBottom: insets.bottom + 10,
              position: 'relative',
            },
            tabBarIcon: ({focused, color, size}) =>
              focused ? (
                <Image
                  name="commu"
                  style={[
                    {width: 39.5, height: 29},
                    Platform.OS === 'web' && {position: 'absolute', top: 0},
                  ]}
                  resizeMode={'contain'}
                  source={require('../assets/images/icn_commu_on.png')}
                />
              ) : (
                <Image
                  name="commu"
                  style={[
                    {width: 39.5, height: 29},
                    Platform.OS === 'web' && {position: 'absolute', top: 0},
                  ]}
                  resizeMode={'contain'}
                  source={require('../assets/images/icn_commu.png')}
                />
              ),
          }}
          listeners={({navigation, route}) => ({
            tabPress: e => {
              DeviceEventEmitter.emit('communityTabPress', {});
            },
          })}
        />
        <Tab.Screen
          name="MyPage"
          component={MyPageView}
          options={{
            title: title('마이페이지'),
            tabBarLabel: ({focused, color, size}) => (
              <Text
                style={[
                  {
                    fontFamily: focused ? 'Pretendard-SemiBold' : 'Pretendard',
                    fontSize: 10,
                    marginTop: 4,
                    color: focused ? '#3f51b5' : color,
                  },
                  Platform.OS === 'web' && {position: 'absolute', bottom: 0},
                ]}>
                마이페이지
              </Text>
            ),
            tabBarStyle: {
              height: insets.bottom + 68,
              paddingTop: 6,
              paddingBottom: insets.bottom + 10,
              position: 'relative',
            },
            tabBarIcon: ({focused, color, size}) =>
              focused ? (
                <Image
                  name="myPage"
                  style={[
                    {width: 29, height: 29},
                    Platform.OS === 'web' && {position: 'absolute', top: 0},
                  ]}
                  resizeMode={'contain'}
                  source={require('../assets/images/icn_mypage_on.png')}
                />
              ) : (
                <Image
                  name="myPage"
                  style={[
                    {width: 29, height: 29},
                    Platform.OS === 'web' && {position: 'absolute', top: 0},
                  ]}
                  resizeMode={'contain'}
                  source={require('../assets/images/icn_mypage.png')}
                />
              ),
          }}
          listeners={({navigation, route}) => ({
            tabPress: e => {
              if (tourMode) {
                e.preventDefault();
                showNeedLoginAlert();
                return;
              }
              DeviceEventEmitter.emit('mypageInfoRefresh', {});
            },
          })}
        />
      </Tab.Navigator>
    </View>
  );
};

export default MainView;

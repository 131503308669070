import {useEffect, useMemo, useState} from 'react';
import {
  Dimensions,
  Image,
  ImageBackground,
  Platform,
  StyleSheet,
  Text,
  View,
  useWindowDimensions,
} from 'react-native';
import * as Font from 'expo-font';
import styles from '../common/styles';

const PlayerCard = ({item, userInfo, cardWidth, margin, hideBottom}) => {
  const {height, width} = useWindowDimensions();

  const getBackgroundImageResource = item => {
    if (item.grade === '골드') {
      return require('../assets/images/card_gold.png');
    } else if (item.grade === '실버') {
      return require('../assets/images/card_silver.png');
    } else if (item.grade === '브론즈') {
      return require('../assets/images/card_bronze.png');
    }
    return require('../assets/images/card_normal.png');
  };

  const profileImageUrl = useMemo(() => {
    if (item.profileImageUrl) {
      return {uri: item.profileImageUrl};
    }
    if (userInfo.profileImageThumbUrl && userInfo.profileImageThumbUrl) {
      return {uri: userInfo.profileImageThumbUrl};
    }
    if (userInfo.profileImageUrl && userInfo.profileImageUrl) {
      return {uri: userInfo.profileImageUrl};
    }
    return require('../assets/images/userCard.png');
  }, [item, userInfo]);

  const getScore1 = item => {
    if (!item.type) {
      return 0;
    }
    return item.type === '투수'
      ? parseInt(item.pitchingControl, 10)
      : parseInt(item.battingPower, 10);
  };

  const getScore2 = item => {
    if (!item.type) {
      return 0;
    }
    return item.type === '투수'
      ? parseInt(item.pitchingPower, 10)
      : parseInt(item.battingContact, 10);
  };

  const getScore3 = item => {
    if (!item.type) {
      return 0;
    }
    return item.type === '투수'
      ? parseInt(item.pitchingStrength, 10)
      : parseInt(item.battingClutch, 10);
  };

  const getScore4 = item => {
    if (!item.type) {
      return 0;
    }
    return item.type === '투수'
      ? parseInt(item.pitchingTechnique, 10)
      : parseInt(item.baseRunning, 10);
  };

  const getScore5 = item => {
    if (!item.type) {
      return 0;
    }
    return item.type === '투수'
      ? parseInt(item.pitchingManage, 10)
      : parseInt(item.baseEye, 10);
  };

  const getTypeTxt = (item, number) => {
    if (item.type === '투수') {
      if (number === 1) {
        return '제구';
      } else if (number === 2) {
        return '구위';
      } else if (number === 3) {
        return '체력';
      } else if (number === 4) {
        return '기교';
      } else if (number === 5) {
        return '관리';
      }
    } else {
      if (number === 1) {
        return '파워';
      } else if (number === 2) {
        return '컨택';
      } else if (number === 3) {
        return '클러치';
      } else if (number === 4) {
        return '주루';
      } else if (number === 5) {
        return '선구';
      }
    }
  };

  const fullWidth = useMemo(() => {
    if (Platform.OS === 'web') {
      const windowWidth = Dimensions.get('window').width;
      if (windowWidth < 550) {
        return windowWidth;
      } else {
        return 550;
      }
    }
    return '100%';
  }, []);

  const pageStyle = StyleSheet.create({
    leagueName: {
      fontSize: Platform.OS === 'web' ? (fullWidth >= 550 ? 17 : 12) : 12,
      fontWeight: 'bold',
      color: 'white',
      textAlign: 'center',
    },
    subLeagueName: {
      fontSize: Platform.OS === 'web' ? (fullWidth >= 550 ? 16 : 11) : 11,
      color: 'black',
      textAlign: 'center',
    },
    leagueLogoImage: {
      width: 30,
      height: 30,
      borderRadius: 15,
      position: 'absolute',
      top: 10,
      right: 10,
      zIndex: 1,
    },
    teamLogoImage: {
      width: Platform.OS === 'web' ? (fullWidth >= 550 ? 30 : 24) : 24,
      height: Platform.OS === 'web' ? (fullWidth >= 550 ? 30 : 24) : 24,
      borderRadius: 15,
      position: 'absolute',
      bottom: fullWidth >= 550 ? 75 : (40.5 / 130) * cardWidth,
      start: 10,
      zIndex: 1,
    },
    scoreTxt: {
      position: 'absolute',
      top: 10,
      start: 10,
      fontSize: fullWidth >= 550 ? 36 : (20 / 130) * cardWidth,
      textShadowColor: 'black',
      textShadowOffset: {width: 2, height: 2},
      textShadowRadius: 0,
    },
    userInfo: {
      fontSize: fullWidth >= 550 ? 18 : (14 / 130) * cardWidth,
      color: 'white',
      position: 'absolute',
      bottom: fullWidth >= 550 ? 80 : (40.5 / 130) * cardWidth,
      start: 44,
      zIndex: 1,
      fontWeight: '800',
    },
    typeContainer: {
      flexDirection: 'row',
      position: 'absolute',
      bottom: fullWidth >= 550 ? 45 : (25.5 / 130) * cardWidth,
      width: '100%',
      paddingHorizontal:
        Platform.OS === 'web' ? (fullWidth >= 550 ? 10 : 6) : 6,
    },
    typeTxt: {
      fontSize: fullWidth >= 550 ? 16 : (9 / 130) * cardWidth,
      flex: 1,
      color: 'white',
      textAlign: 'center',
      fontWeight: 'bold',
    },
    statContainer: {
      flexDirection: 'row',
      position: 'absolute',
      bottom: fullWidth >= 550 ? 16 : (6.5 / 130) * cardWidth,
      width: '100%',
      paddingHorizontal:
        Platform.OS === 'web' ? (fullWidth >= 550 ? 10 : 8) : 8,
    },
    statTxt: {
      fontSize: fullWidth >= 550 ? 20 : (14 / 130) * cardWidth,
      flex: 1,
      color: 'white',
      textAlign: 'center',
      textShadowColor: 'black',
      textShadowOffset: {width: 2, height: 2},
      textShadowRadius: 0,
      fontWeight: 'bold',
    },
    goldTxt: {
      color: '#FFD700',
    },
    silverTxt: {
      color: '#C0C0C0',
    },
    bronzeTxt: {
      color: '#cc6633',
    },
    navyTxt: {
      color: '#000080',
    },
    scoreImg: {
      width: Platform.OS === 'web' ? (fullWidth >= 550 ? 58 : 34) : 34,
      height: ((fullWidth >= 550 ? 58 : 34) / 240) * 303,
    },
    scoreImgLeft: {},
    scoreImgRight: {
      marginStart: Platform.OS === 'web' ? (fullWidth >= 550 ? -30 : -17) : -17,
    },
    scoreContainer: {
      alignContent: 'center',
      alignItems: 'center',
    },
  });

  const scoreImages = useMemo(() => {
    const score = parseInt(item.score ? item.score : 0, 10);
    const grade = item.grade;

    let firstLetter = null;
    let SecondLetter = null;
    if (item.score) {
      firstLetter = String(score).substring(0, 1);
      SecondLetter = String(score).substring(1, 2);
    } else {
      return (
        <>
          <Image
            style={[pageStyle.scoreImg, pageStyle.scoreImgLeft]}
            source={require('../assets/images/normal_0.png')}
          />
          <Image
            style={[pageStyle.scoreImg, pageStyle.scoreImgRight]}
            source={require('../assets/images/normal_0.png')}
          />
        </>
      );
    }
    if (grade === '네이비') {
      let component = [];
      switch (firstLetter) {
        case '0':
          component.push(
            <Image
              style={[pageStyle.scoreImg, pageStyle.scoreImgLeft]}
              source={require(`../assets/images/normal_0.png`)}
            />,
          );
          break;
        case '1':
          component.push(
            <Image
              style={[pageStyle.scoreImg, pageStyle.scoreImgLeft]}
              source={require(`../assets/images/normal_1.png`)}
            />,
          );
          break;
        case '2':
          component.push(
            <Image
              style={[pageStyle.scoreImg, pageStyle.scoreImgLeft]}
              source={require(`../assets/images/normal_2.png`)}
            />,
          );
          break;
        case '3':
          component.push(
            <Image
              style={[pageStyle.scoreImg, pageStyle.scoreImgLeft]}
              source={require(`../assets/images/normal_3.png`)}
            />,
          );
          break;
        case '4':
          component.push(
            <Image
              style={[pageStyle.scoreImg, pageStyle.scoreImgLeft]}
              source={require(`../assets/images/normal_4.png`)}
            />,
          );
          break;
        case '5':
          component.push(
            <Image
              style={[pageStyle.scoreImg, pageStyle.scoreImgLeft]}
              source={require(`../assets/images/normal_5.png`)}
            />,
          );
          break;
        case '6':
          component.push(
            <Image
              style={[pageStyle.scoreImg, pageStyle.scoreImgLeft]}
              source={require(`../assets/images/normal_6.png`)}
            />,
          );
          break;
        case '7':
          component.push(
            <Image
              style={[pageStyle.scoreImg, pageStyle.scoreImgLeft]}
              source={require(`../assets/images/normal_7.png`)}
            />,
          );
          break;
        case '8':
          component.push(
            <Image
              style={[pageStyle.scoreImg, pageStyle.scoreImgLeft]}
              source={require(`../assets/images/normal_8.png`)}
            />,
          );
          break;
        case '9':
          component.push(
            <Image
              style={[pageStyle.scoreImg, pageStyle.scoreImgLeft]}
              source={require(`../assets/images/normal_9.png`)}
            />,
          );
          break;
      }
      switch (SecondLetter) {
        case '0':
          component.push(
            <Image
              style={[pageStyle.scoreImg, pageStyle.scoreImgRight]}
              source={require(`../assets/images/normal_0.png`)}
            />,
          );
          break;
        case '1':
          component.push(
            <Image
              style={[pageStyle.scoreImg, pageStyle.scoreImgRight]}
              source={require(`../assets/images/normal_1.png`)}
            />,
          );
          break;
        case '2':
          component.push(
            <Image
              style={[pageStyle.scoreImg, pageStyle.scoreImgRight]}
              source={require(`../assets/images/normal_2.png`)}
            />,
          );
          break;
        case '3':
          component.push(
            <Image
              style={[pageStyle.scoreImg, pageStyle.scoreImgRight]}
              source={require(`../assets/images/normal_3.png`)}
            />,
          );
          break;
        case '4':
          component.push(
            <Image
              style={[pageStyle.scoreImg, pageStyle.scoreImgRight]}
              source={require(`../assets/images/normal_4.png`)}
            />,
          );
          break;
        case '5':
          component.push(
            <Image
              style={[pageStyle.scoreImg, pageStyle.scoreImgRight]}
              source={require(`../assets/images/normal_5.png`)}
            />,
          );
          break;
        case '6':
          component.push(
            <Image
              style={[pageStyle.scoreImg, pageStyle.scoreImgRight]}
              source={require(`../assets/images/normal_6.png`)}
            />,
          );
          break;
        case '7':
          component.push(
            <Image
              style={[pageStyle.scoreImg, pageStyle.scoreImgRight]}
              source={require(`../assets/images/normal_7.png`)}
            />,
          );
          break;
        case '8':
          component.push(
            <Image
              style={[pageStyle.scoreImg, pageStyle.scoreImgRight]}
              source={require(`../assets/images/normal_8.png`)}
            />,
          );
          break;
        case '9':
          component.push(
            <Image
              style={[pageStyle.scoreImg, pageStyle.scoreImgRight]}
              source={require(`../assets/images/normal_9.png`)}
            />,
          );
          break;
      }
      return component;
    } else if (grade === '브론즈') {
      let component = [];
      switch (firstLetter) {
        case '0':
          component.push(
            <Image
              style={[pageStyle.scoreImg, pageStyle.scoreImgLeft]}
              source={require(`../assets/images/bronze_0.png`)}
            />,
          );
          break;
        case '1':
          component.push(
            <Image
              style={[pageStyle.scoreImg, pageStyle.scoreImgLeft]}
              source={require(`../assets/images/bronze_1.png`)}
            />,
          );
          break;
        case '2':
          component.push(
            <Image
              style={[pageStyle.scoreImg, pageStyle.scoreImgLeft]}
              source={require(`../assets/images/bronze_2.png`)}
            />,
          );
          break;
        case '3':
          component.push(
            <Image
              style={[pageStyle.scoreImg, pageStyle.scoreImgLeft]}
              source={require(`../assets/images/bronze_3.png`)}
            />,
          );
          break;
        case '4':
          component.push(
            <Image
              style={[pageStyle.scoreImg, pageStyle.scoreImgLeft]}
              source={require(`../assets/images/bronze_4.png`)}
            />,
          );
          break;
        case '5':
          component.push(
            <Image
              style={[pageStyle.scoreImg, pageStyle.scoreImgLeft]}
              source={require(`../assets/images/bronze_5.png`)}
            />,
          );
          break;
        case '6':
          component.push(
            <Image
              style={[pageStyle.scoreImg, pageStyle.scoreImgLeft]}
              source={require(`../assets/images/bronze_6.png`)}
            />,
          );
          break;
        case '7':
          component.push(
            <Image
              style={[pageStyle.scoreImg, pageStyle.scoreImgLeft]}
              source={require(`../assets/images/bronze_7.png`)}
            />,
          );
          break;
        case '8':
          component.push(
            <Image
              style={[pageStyle.scoreImg, pageStyle.scoreImgLeft]}
              source={require(`../assets/images/bronze_8.png`)}
            />,
          );
          break;
        case '9':
          component.push(
            <Image
              style={[pageStyle.scoreImg, pageStyle.scoreImgLeft]}
              source={require(`../assets/images/bronze_9.png`)}
            />,
          );
          break;
      }
      switch (SecondLetter) {
        case '0':
          component.push(
            <Image
              style={[pageStyle.scoreImg, pageStyle.scoreImgRight]}
              source={require(`../assets/images/bronze_0.png`)}
            />,
          );
          break;
        case '1':
          component.push(
            <Image
              style={[pageStyle.scoreImg, pageStyle.scoreImgRight]}
              source={require(`../assets/images/bronze_1.png`)}
            />,
          );
          break;
        case '2':
          component.push(
            <Image
              style={[pageStyle.scoreImg, pageStyle.scoreImgRight]}
              source={require(`../assets/images/bronze_2.png`)}
            />,
          );
          break;
        case '3':
          component.push(
            <Image
              style={[pageStyle.scoreImg, pageStyle.scoreImgRight]}
              source={require(`../assets/images/bronze_3.png`)}
            />,
          );
          break;
        case '4':
          component.push(
            <Image
              style={[pageStyle.scoreImg, pageStyle.scoreImgRight]}
              source={require(`../assets/images/bronze_4.png`)}
            />,
          );
          break;
        case '5':
          component.push(
            <Image
              style={[pageStyle.scoreImg, pageStyle.scoreImgRight]}
              source={require(`../assets/images/bronze_5.png`)}
            />,
          );
          break;
        case '6':
          component.push(
            <Image
              style={[pageStyle.scoreImg, pageStyle.scoreImgRight]}
              source={require(`../assets/images/bronze_6.png`)}
            />,
          );
          break;
        case '7':
          component.push(
            <Image
              style={[pageStyle.scoreImg, pageStyle.scoreImgRight]}
              source={require(`../assets/images/bronze_7.png`)}
            />,
          );
          break;
        case '8':
          component.push(
            <Image
              style={[pageStyle.scoreImg, pageStyle.scoreImgRight]}
              source={require(`../assets/images/bronze_8.png`)}
            />,
          );
          break;
        case '9':
          component.push(
            <Image
              style={[pageStyle.scoreImg, pageStyle.scoreImgRight]}
              source={require(`../assets/images/bronze_9.png`)}
            />,
          );
          break;
      }
      return component;
    } else if (grade === '실버') {
      let component = [];
      switch (firstLetter) {
        case '0':
          component.push(
            <Image
              style={[pageStyle.scoreImg, pageStyle.scoreImgLeft]}
              source={require(`../assets/images/silver_0.png`)}
            />,
          );
          break;
        case '1':
          component.push(
            <Image
              style={[pageStyle.scoreImg, pageStyle.scoreImgLeft]}
              source={require(`../assets/images/silver_1.png`)}
            />,
          );
          break;
        case '2':
          component.push(
            <Image
              style={[pageStyle.scoreImg, pageStyle.scoreImgLeft]}
              source={require(`../assets/images/silver_2.png`)}
            />,
          );
          break;
        case '3':
          component.push(
            <Image
              style={[pageStyle.scoreImg, pageStyle.scoreImgLeft]}
              source={require(`../assets/images/silver_3.png`)}
            />,
          );
          break;
        case '4':
          component.push(
            <Image
              style={[pageStyle.scoreImg, pageStyle.scoreImgLeft]}
              source={require(`../assets/images/silver_4.png`)}
            />,
          );
          break;
        case '5':
          component.push(
            <Image
              style={[pageStyle.scoreImg, pageStyle.scoreImgLeft]}
              source={require(`../assets/images/silver_5.png`)}
            />,
          );
          break;
        case '6':
          component.push(
            <Image
              style={[pageStyle.scoreImg, pageStyle.scoreImgLeft]}
              source={require(`../assets/images/silver_6.png`)}
            />,
          );
          break;
        case '7':
          component.push(
            <Image
              style={[pageStyle.scoreImg, pageStyle.scoreImgLeft]}
              source={require(`../assets/images/silver_7.png`)}
            />,
          );
          break;
        case '8':
          component.push(
            <Image
              style={[pageStyle.scoreImg, pageStyle.scoreImgLeft]}
              source={require(`../assets/images/silver_8.png`)}
            />,
          );
          break;
        case '9':
          component.push(
            <Image
              style={[pageStyle.scoreImg, pageStyle.scoreImgLeft]}
              source={require(`../assets/images/silver_9.png`)}
            />,
          );
          break;
      }
      switch (SecondLetter) {
        case '0':
          component.push(
            <Image
              style={[pageStyle.scoreImg, pageStyle.scoreImgRight]}
              source={require(`../assets/images/silver_0.png`)}
            />,
          );
          break;
        case '1':
          component.push(
            <Image
              style={[pageStyle.scoreImg, pageStyle.scoreImgRight]}
              source={require(`../assets/images/silver_1.png`)}
            />,
          );
          break;
        case '2':
          component.push(
            <Image
              style={[pageStyle.scoreImg, pageStyle.scoreImgRight]}
              source={require(`../assets/images/silver_2.png`)}
            />,
          );
          break;
        case '3':
          component.push(
            <Image
              style={[pageStyle.scoreImg, pageStyle.scoreImgRight]}
              source={require(`../assets/images/silver_3.png`)}
            />,
          );
          break;
        case '4':
          component.push(
            <Image
              style={[pageStyle.scoreImg, pageStyle.scoreImgRight]}
              source={require(`../assets/images/silver_4.png`)}
            />,
          );
          break;
        case '5':
          component.push(
            <Image
              style={[pageStyle.scoreImg, pageStyle.scoreImgRight]}
              source={require(`../assets/images/silver_5.png`)}
            />,
          );
          break;
        case '6':
          component.push(
            <Image
              style={[pageStyle.scoreImg, pageStyle.scoreImgRight]}
              source={require(`../assets/images/silver_6.png`)}
            />,
          );
          break;
        case '7':
          component.push(
            <Image
              style={[pageStyle.scoreImg, pageStyle.scoreImgRight]}
              source={require(`../assets/images/silver_7.png`)}
            />,
          );
          break;
        case '8':
          component.push(
            <Image
              style={[pageStyle.scoreImg, pageStyle.scoreImgRight]}
              source={require(`../assets/images/silver_8.png`)}
            />,
          );
          break;
        case '9':
          component.push(
            <Image
              style={[pageStyle.scoreImg, pageStyle.scoreImgRight]}
              source={require(`../assets/images/silver_9.png`)}
            />,
          );
          break;
      }
      return component;
    } else if (grade === '골드') {
      let component = [];
      switch (firstLetter) {
        case '0':
          component.push(
            <Image
              style={[pageStyle.scoreImg, pageStyle.scoreImgLeft]}
              source={require(`../assets/images/gold_0.png`)}
            />,
          );
          break;
        case '1':
          component.push(
            <Image
              style={[pageStyle.scoreImg, pageStyle.scoreImgLeft]}
              source={require(`../assets/images/gold_1.png`)}
            />,
          );
          break;
        case '2':
          component.push(
            <Image
              style={[pageStyle.scoreImg, pageStyle.scoreImgLeft]}
              source={require(`../assets/images/gold_2.png`)}
            />,
          );
          break;
        case '3':
          component.push(
            <Image
              style={[pageStyle.scoreImg, pageStyle.scoreImgLeft]}
              source={require(`../assets/images/gold_3.png`)}
            />,
          );
          break;
        case '4':
          component.push(
            <Image
              style={[pageStyle.scoreImg, pageStyle.scoreImgLeft]}
              source={require(`../assets/images/gold_4.png`)}
            />,
          );
          break;
        case '5':
          component.push(
            <Image
              style={[pageStyle.scoreImg, pageStyle.scoreImgLeft]}
              source={require(`../assets/images/gold_5.png`)}
            />,
          );
          break;
        case '6':
          component.push(
            <Image
              style={[pageStyle.scoreImg, pageStyle.scoreImgLeft]}
              source={require(`../assets/images/gold_6.png`)}
            />,
          );
          break;
        case '7':
          component.push(
            <Image
              style={[pageStyle.scoreImg, pageStyle.scoreImgLeft]}
              source={require(`../assets/images/gold_7.png`)}
            />,
          );
          break;
        case '8':
          component.push(
            <Image
              style={[pageStyle.scoreImg, pageStyle.scoreImgLeft]}
              source={require(`../assets/images/gold_8.png`)}
            />,
          );
          break;
        case '9':
          component.push(
            <Image
              style={[pageStyle.scoreImg, pageStyle.scoreImgLeft]}
              source={require(`../assets/images/gold_9.png`)}
            />,
          );
          break;
      }
      switch (SecondLetter) {
        case '0':
          component.push(
            <Image
              style={[pageStyle.scoreImg, pageStyle.scoreImgRight]}
              source={require(`../assets/images/gold_0.png`)}
            />,
          );
          break;
        case '1':
          component.push(
            <Image
              style={[pageStyle.scoreImg, pageStyle.scoreImgRight]}
              source={require(`../assets/images/gold_1.png`)}
            />,
          );
          break;
        case '2':
          component.push(
            <Image
              style={[pageStyle.scoreImg, pageStyle.scoreImgRight]}
              source={require(`../assets/images/gold_2.png`)}
            />,
          );
          break;
        case '3':
          component.push(
            <Image
              style={[pageStyle.scoreImg, pageStyle.scoreImgRight]}
              source={require(`../assets/images/gold_3.png`)}
            />,
          );
          break;
        case '4':
          component.push(
            <Image
              style={[pageStyle.scoreImg, pageStyle.scoreImgRight]}
              source={require(`../assets/images/gold_4.png`)}
            />,
          );
          break;
        case '5':
          component.push(
            <Image
              style={[pageStyle.scoreImg, pageStyle.scoreImgRight]}
              source={require(`../assets/images/gold_5.png`)}
            />,
          );
          break;
        case '6':
          component.push(
            <Image
              style={[pageStyle.scoreImg, pageStyle.scoreImgRight]}
              source={require(`../assets/images/gold_6.png`)}
            />,
          );
          break;
        case '7':
          component.push(
            <Image
              style={[pageStyle.scoreImg, pageStyle.scoreImgRight]}
              source={require(`../assets/images/gold_7.png`)}
            />,
          );
          break;
        case '8':
          component.push(
            <Image
              style={[pageStyle.scoreImg, pageStyle.scoreImgRight]}
              source={require(`../assets/images/gold_8.png`)}
            />,
          );
          break;
        case '9':
          component.push(
            <Image
              style={[pageStyle.scoreImg, pageStyle.scoreImgRight]}
              source={require(`../assets/images/gold_9.png`)}
            />,
          );
          break;
      }
      return component;
    }
  }, [item.grade]);
  return (
    <View>
      <ImageBackground
        style={[
          {
            position: 'relative',
            marginHorizontal: margin
              ? margin
              : (width - cardWidth * 2 - 25 * 2) / 4,
            width: cardWidth,
            height: (cardWidth * 80) / 56,
          },
        ]}
        source={profileImageUrl}>
        <Image
          resizeMode={'cover'}
          source={getBackgroundImageResource(item)}
          style={[
            {
              top: 0,
              start: 0,
              position: 'absolute',
              width: cardWidth,
              height: (cardWidth * 80) / 56,
            },
          ]}
        />
        <Image
          resizeMode="cover"
          source={{uri: item.leagueLogoImageUrl}}
          style={[pageStyle.leagueLogoImage]}
        />
        <Image
          resizeMode="cover"
          source={{uri: item.teamLogoImageUrl}}
          style={[pageStyle.teamLogoImage]}
        />
        <View style={[styles.row, pageStyle.scoreContainer]}>
          {scoreImages}
        </View>
        <Text style={[pageStyle.userInfo]}>
          {userInfo.name}`{item.backNumber}
        </Text>
        <View style={[pageStyle.typeContainer]}>
          <Text
            numberOfLines={1}
            style={[pageStyle.typeTxt]}
            adjustsFontSizeToFit={true}>
            {getTypeTxt(item, 1)}
          </Text>
          <Text
            numberOfLines={1}
            style={[pageStyle.typeTxt]}
            adjustsFontSizeToFit={true}>
            {getTypeTxt(item, 2)}
          </Text>
          <Text
            numberOfLines={1}
            style={[pageStyle.typeTxt]}
            adjustsFontSizeToFit={true}>
            {getTypeTxt(item, 3)}
          </Text>
          <Text
            numberOfLines={1}
            style={[pageStyle.typeTxt]}
            adjustsFontSizeToFit={true}>
            {getTypeTxt(item, 4)}
          </Text>
          <Text
            numberOfLines={1}
            style={[pageStyle.typeTxt]}
            adjustsFontSizeToFit={true}>
            {getTypeTxt(item, 5)}
          </Text>
        </View>
        <View style={[pageStyle.statContainer]}>
          <Text
            numberOfLines={1}
            adjustsFontSizeToFit={true}
            nativeID=""
            style={[
              pageStyle.statTxt,
              getScore1(item) >= 90 && {color: 'red'},
            ]}>
            {getScore1(item)}
          </Text>
          <Text
            numberOfLines={1}
            adjustsFontSizeToFit={true}
            style={[
              pageStyle.statTxt,
              getScore2(item) >= 90 && {color: 'red'},
            ]}>
            {getScore2(item)}
          </Text>
          <Text
            numberOfLines={1}
            adjustsFontSizeToFit={true}
            style={[
              pageStyle.statTxt,
              getScore3(item) >= 90 && {color: 'red'},
            ]}>
            {getScore3(item)}
          </Text>
          <Text
            numberOfLines={1}
            adjustsFontSizeToFit={true}
            style={[
              pageStyle.statTxt,
              getScore4(item) >= 90 && {color: 'red'},
            ]}>
            {getScore4(item)}
          </Text>
          <Text
            numberOfLines={1}
            adjustsFontSizeToFit={true}
            style={[
              pageStyle.statTxt,
              getScore5(item) >= 90 && {color: 'red'},
            ]}>
            {getScore5(item)}
          </Text>
        </View>
      </ImageBackground>
      {!hideBottom && (
        <ImageBackground
          style={[
            {
              marginHorizontal: margin
                ? margin
                : (width - cardWidth * 2 - 25 * 2) / 4,
              width: cardWidth,
              height: (cardWidth * 21) / 56,
            },
          ]}
          source={require('../assets/images/leagueNameBg.png')}>
          {!hideBottom && (
            <>
              <Text
                numberOfLines={1}
                style={[
                  pageStyle.leagueName,
                  {
                    height: (cardWidth * 21) / 56 / 2,
                    lineHeight: (cardWidth * 21) / 56 / 2,
                  },
                ]}>
                {item.leagueName}
              </Text>
              <Text
                numberOfLines={1}
                style={[
                  pageStyle.subLeagueName,
                  {
                    height: (cardWidth * 21) / 56 / 2,
                    lineHeight: (cardWidth * 21) / 56 / 2,
                  },
                ]}>
                {item.subLeagueName}
              </Text>
            </>
          )}
        </ImageBackground>
      )}
    </View>
  );
};

export default PlayerCard;

import {
  Alert,
  FlatList,
  Image,
  Platform,
  StyleSheet,
  Text,
  View,
} from 'react-native';
import {SafeAreaView} from 'react-native-safe-area-context';
import styles from '../common/styles';
import {Shadow} from 'react-native-shadow-2';
import {TouchableOpacity} from 'react-native-gesture-handler';
import {useEffect, useRef, useState} from 'react';
import api from '../common/api';
import * as Progress from 'react-native-progress';
import AsyncStorage from '@react-native-async-storage/async-storage';

const BlockUserView = ({navigation}) => {
  const [page, setPage] = useState({});
  const [progressShown, setProgressShown] = useState(false);

  let pageLoading = useRef(false);
  let accessToken = useRef('');

  const getData = () => {
    if (!accessToken.current) {
      return;
    }
    api
      .get(`/users/block-users/list?pageNo=1&pageSize=20`, {
        headers: {
          Authorization: `Bearer ${accessToken.current}`,
        },
      })
      .then(function (response) {
        setPage(response.data);
      })
      .catch(function (error) {
        console.error(error.response);
      });
  };

  useEffect(() => {
    AsyncStorage.getItem('@accessToken', (err, result) => {
      accessToken.current = result;
      getData(result);
    });
  }, []);

  const handlePageLoadMore = () => {
    if (pageLoading.current) {
      return;
    }
    if (page.currentPage && page.totalPage <= page.currentPage) {
      return;
    }
    pageLoading.current = true;
    api
      .get(
        `/users/block-user/list?pageNo=${
          page.currentPage ? parseInt(page.currentPage, 10) + 1 : 1
        }&pageSize=20`,
        {
          headers: {
            Authorization: `Bearer ${accessToken.current}`,
          },
        },
      )
      .then(function (response) {
        response.data.items = [
          ...(page.items ? page.items : []),
          ...response.data.items,
        ];
        setPage(response.data);
        pageLoading.current = false;
        setProgressShown(false);
      })
      .catch(function (error) {
        console.log(error);
        pageLoading.current = false;
        setProgressShown(false);
      });
  };

  const unBlockUser = targetUserId => {
    Alert.alert('사용자 차단', '사용자 차단 해제 하시겠습니까?', [
      {
        text: '예',
        onPress: () => {
          api
            .delete(`/users/block-users/${targetUserId}`, {
              headers: {
                Authorization: `Bearer ${accessToken.current}`,
              },
            })
            .then(function (response) {
              alert('차단 해제가 완료되었습니다.');
              getData();
            })
            .catch(function (error) {
              console.log(error);
            });
        },
      },
      {
        text: '아니오',
      },
    ]);
  };

  return (
    <SafeAreaView nativeID="common-page-container" style={[styles.container]}>
      <Shadow
        offset={[0, 2]}
        style={[{width: '100%'}]}
        containerStyle={[{marginTop: 0}]}
        distance={7}
        sides={{bottom: true, top: false, start: false, end: false}}>
        <View style={[styles.headerCommon]}>
          <View style={[styles.row, styles.verticalAlign]}>
            <TouchableOpacity
              onPress={() =>
                Platform.OS === 'web' ? history.back() : navigation.goBack()
              }>
              <Image
                source={require('../assets/images/icon_back.png')}
                style={[styles.headerBackButton]}
              />
            </TouchableOpacity>
            <Text style={[styles.headerText]}>차단 사용자 관리</Text>
          </View>
          <View style={[styles.row, styles.verticalAlign]}>
            <Image source={require('../assets/images/icon_uniqueplay.png')} />
          </View>
        </View>
      </Shadow>
      <FlatList
        keyExtractor={item => `item_${item.id.toString()}`}
        data={page.items}
        ListEmptyComponent={() => (
          <View style={[styles.emptyContainer]}>
            <Image source={require('../assets/images/logo2.png')} />
          </View>
        )}
        onEndReachedThreshold={1}
        onEndReached={handlePageLoadMore}
        style={[pageStyle.list]}
        renderItem={({item, index}) => (
          <View style={[styles.row, pageStyle.row]}>
            <View
              style={[
                styles.row,
                {alignContent: 'center', alignItems: 'center'},
              ]}>
              <Image
                style={[pageStyle.profileImage]}
                resizeMode="cover"
                source={
                  item.targetUser?.profileImageThumbUrl
                    ? {uri: item.targetUser?.profileImageThumbUrl}
                    : require('../assets/images/icn_profile_img.png')
                }
              />
              <Text style={[pageStyle.name]}>{item.targetUser?.name}</Text>
            </View>
            <TouchableOpacity
              style={[pageStyle.btn]}
              onPress={() => unBlockUser(item.targetUser?.uid)}>
              <Text style={[pageStyle.btnTxt]}>차단 해제</Text>
            </TouchableOpacity>
          </View>
        )}
      />
      {progressShown && (
        <View style={[styles.progressContainer]}>
          <Progress.Circle
            fill="transparent"
            size={70}
            borderWidth={3}
            indeterminate={true}
          />
        </View>
      )}
    </SafeAreaView>
  );
};

export default BlockUserView;

const pageStyle = StyleSheet.create({
  logoImage: {
    width: 55,
    height: 55,
    borderRadius: 27,
  },
  profileImage: {
    width: 45,
    height: 45,
    borderRadius: 23,
  },
  row: {
    borderBottomWidth: 1,
    borderBottomColor: '#e6e6e6',
    paddingVertical: 12,
    paddingHorizontal: 24,
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  name: {
    fontSize: 14,
    fontFamily: 'Pretendard',
    color: 'black',
    marginStart: 8,
  },
});

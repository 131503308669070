import React, {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  Platform,
} from 'react-native';
import Colors from '../../common/colors';
import styles from '../../common/styles';
import CheckBox from '@react-native-community/checkbox';
import moment from 'moment';
import AsyncStorage from '@react-native-async-storage/async-storage';
import api from '../../common/api';
import {useEffect, useState} from 'react';
import Checkbox from 'expo-checkbox';

const ApproveMemberItem = props => {
  useEffect(() => {}, []);
  const [checked, setChecked] = useState(false);
  const approve = () => {
    api
      .post(
        '/teams/member/approve',
        {requestId: [props.data.id]},
        {
          headers: {
            Authorization: `Bearer ${props.accessToken}`,
          },
        },
      )
      .then(function (response) {
        console.log(response.data, response.headers);
        alert('요청에 성공했습니다.');
        setTimeout(() => {
          props.refreshData();
        }, 1500);
      })
      .catch(function (error) {
        console.log(error.response);
        alert('요청에 실패했습니다.');
      });
  };

  const deny = () => {
    api
      .post(
        '/teams/member/deny',
        {requestId: [props.data.id]},
        {
          headers: {
            Authorization: `Bearer ${props.accessToken}`,
          },
        },
      )
      .then(function (response) {
        console.log(response.data, response.headers);
        alert('요청에 성공했습니다.');
        setTimeout(() => {
          props.refreshData();
        }, 1500);
      })
      .catch(function (error) {
        console.log(error.response);
        alert('요청에 실패했습니다.');
      });
  };
  return (
    <View style={[pageStyle.itemContainer]}>
      <View style={[pageStyle.checkboxCol]}>
        {Platform.OS !== 'web' && (
          <CheckBox
            value={checked || props.checked}
            boxType={'square'}
            style={[{height: 25, width: 25, zIndex: 10}]}
            onValueChange={newValue => {
              console.log('onValueChange', newValue);
              props.handleCheck(props.data.id, newValue);
              setChecked(newValue);
            }}
          />
        )}
        {Platform.OS === 'web' && (
          <Checkbox
            style={[{height: 25, width: 25, zIndex: 10}]}
            value={checked || props.checked}
            onValueChange={newValue => {
              console.log('onValueChange', newValue);
              props.handleCheck(props.data.id, newValue);
              setChecked(newValue);
            }}
            color={checked || props.checked ? Colors.main : '#ddd'}
          />
        )}
      </View>
      <View style={[pageStyle.col]}>
        <Text style={[pageStyle.label]}>{props.data.user.name}</Text>
      </View>
      <View style={[pageStyle.colGender]}>
        <Text style={[pageStyle.label]}>{props.data.user.gender}</Text>
      </View>
      <View style={[pageStyle.colAge]}>
        <Text style={[pageStyle.label]}>
          {moment().diff(moment(props.data.user.birth, 'YYYY-MM-DD'), 'y')}
        </Text>
      </View>
      <View style={[pageStyle.col3]}>
        <Text style={[pageStyle.label]}>
          {props.data.user.hideContact ? '비공개' : props.data.user.cellphone}
        </Text>
      </View>
      <View style={[pageStyle.col2]}>
        <View style={[styles.row]}>
          <TouchableOpacity
            onPress={approve}
            style={[pageStyle.btn, pageStyle.approveBtn]}>
            <Text style={[pageStyle.btnTxt]}>승인</Text>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={deny}
            style={[pageStyle.btn, pageStyle.denyBtn]}>
            <Text style={[pageStyle.btnTxt]}>거절</Text>
          </TouchableOpacity>
        </View>
      </View>
    </View>
  );
};
export default ApproveMemberItem;
const pageStyle = StyleSheet.create({
  itemContainer: {
    display: 'flex',
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: 'rgba(0, 0, 0, 0.15)',
    paddingVertical: 12,
  },
  checkboxCol: {
    textAlign: 'left',
    justifyContent: 'center',
    width: 30,
  },
  col: {
    flex: 1,
    paddingHorizontal: 4,
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
  },
  col2: {
    flex: 2,
    paddingHorizontal: 4,
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
  },
  col3: {
    flex: 3,
    paddingHorizontal: 2,
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
  },
  colGender: {
    width: 30,
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
  },
  colAge: {
    width: 30,
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
  },
  label: {
    color: 'black',
    fontSize: 12,
    fontWeight: '300',
  },
  btn: {
    width: 43,
    height: 28,
    borderRadius: 14,
    alignItems: 'center',
    justifyContent: 'center',
  },
  btnTxt: {
    color: 'white',
    fontSize: 12,
    fontWeight: '300',
  },
  approveBtn: {
    backgroundColor: Colors.lred,
  },
  denyBtn: {
    marginStart: 3,
    backgroundColor: Colors.sky,
  },
});

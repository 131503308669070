import {useMemo, useState} from 'react';
import React, {
  Image,
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  Platform,
} from 'react-native';
import {Shadow} from 'react-native-shadow-2';
import Colors from '../common/colors';
import styles from '../common/styles';
const CategoryRecordItem = props => {
  const profileImageUrl = useMemo(() => {
    if (props.data.list && props.data.list[0]?.profileImageThumbUrl) {
      return {uri: props.data.list[0]?.profileImageThumbUrl};
    }
    if (props.data.list && props.data.list[0]?.profileImageUrl) {
      return {uri: props.data.list[0]?.profileImageUrl};
    }
    return require('../assets/images/icn_profile_img.png');
  }, [props.data.list]);

  return (
    <View style={[pageStyle.container]}>
      <TouchableOpacity
        onPress={() =>
          props.navigation.navigate('MemberPage', {
            item: {uid: props.data.list[0].userUid},
            itemId: props.data.list[0].userUid,
          })
        }
        disabled={!props.data.list || props.data.list.length === 0}
        style={[pageStyle.topContainer]}>
        {props.data.list && props.data.list.length > 0 && (
          <Text style={[pageStyle.rank1]}>1</Text>
        )}
        <Text style={[pageStyle.category]}>{props.data.category}</Text>
        <Image
          style={[
            {
              width: 36,
              height: 36,
              resizeMode: 'cover',
              borderRadius: 18,
              marginTop: 3,
            },
          ]}
          source={profileImageUrl}
        />
        <Text style={[pageStyle.name, styles.mt3]}>
          {props.data.list &&
            props.data.list.length > 0 &&
            props.data.list[0].user}
        </Text>
        <Text style={[pageStyle.value, styles.mt3]}>
          {props.data.list &&
            props.data.list.length > 0 &&
            props.data.list[0].value}
        </Text>
      </TouchableOpacity>
      {props.data.list &&
        props.data.list.map((item, index) => {
          if (index === 0) {
            return;
          }
          return (
            <TouchableOpacity
              onPress={() =>
                props.navigation.navigate('MemberPage', {
                  item: {uid: item.userUid},
                  itemId: item.userUid,
                })
              }
              style={[pageStyle.rankItem]}>
              <View style={[styles.row, pageStyle.alignCenter]}>
                <Text style={[pageStyle.rank, styles.mr7]}>{item.rank}</Text>
                <Text style={[pageStyle.name]} numberOfLines={1}>
                  {item.user}
                </Text>
              </View>
              <Text style={[pageStyle.value]}>{item.value}</Text>
            </TouchableOpacity>
          );
        })}
      {(!props.data.list || props.data.list.length === 0) && (
        <Text
          style={[
            {
              fontSize: 10,
              color: 'black',
              fontFamily: 'Pretendard',
              textAlign: 'center',
              marginTop: 12,
            },
          ]}>
          기록 정보가 없습니다.
        </Text>
      )}
    </View>
  );
};

export default CategoryRecordItem;

const pageStyle = StyleSheet.create({
  container: {
    width: 121,
    height: Platform.OS === 'web' ? 205 : 185,
    marginVertical: 7,
    marginHorizontal: 3.5,
    borderWidth: 1,
    borderColor: 'rgba(0, 0, 0, 0.2)',
    overflow: 'hidden',
  },
  topContainer: {
    position: 'relative',
    padding: 7.5,
    alignItems: 'center',
    backgroundColor: 'white',
  },
  category: {
    color: Colors.main,
    fontSize: 10,
    fontWeight: '600',
    fontFamily: 'Pretendard-SemiBold',
  },
  rank1: {
    color: Colors.main,
    fontSize: 18,
    fontWeight: '600',
    fontFamily: 'Pretendard-SemiBold',
    position: 'absolute',
    top: 8,
    left: 8,
  },
  rankItem: {
    paddingHorizontal: 13,
    paddingVertical: 3.5,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  alignCenter: {alignItems: 'center'},
  rank: {
    color: 'black',
    fontSize: 12,
    fontWeight: '600',
    fontFamily: 'Pretendard-SemiBold',
  },
  name: {
    color: 'black',
    fontFamily: 'Pretendard-Light',
    fontSize: 12,
    fontWeight: '300',
    width: 45,
    textAlign: 'center',
  },
  value: {
    color: Colors.main,
    fontSize: 12,
    fontWeight: '600',
    fontFamily: 'Pretendard-SemiBold',
  },
});

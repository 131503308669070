import React, {useState, useEffect, useRef} from 'react';
import {
  Image,
  SafeAreaView,
  ScrollView,
  Text,
  TouchableOpacity,
  View,
  StyleSheet,
  KeyboardAvoidingView,
  FlatList,
  TextInput,
  Platform,
  Dimensions,
  Alert,
  DeviceEventEmitter,
} from 'react-native';
import {Shadow} from 'react-native-shadow-2';
import styles from '../common/styles';
import AsyncStorage from '@react-native-async-storage/async-storage';
import api from '../common/api';
import {actions, RichEditor, RichToolbar} from 'react-native-pell-rich-editor';
import {launchImageLibrary} from 'react-native-image-picker';
import alert from '../component/alert';
import {title} from '../common/util';
const LeagueInfoEditView = ({navigation, route}) => {
  const {leagueId, type, content, infoKey} = route.params;
  const richText = useRef();
  const accessToken = useRef();
  const [contents, setContents] = useState(content);

  const openImageLibrary = () => {
    launchImageLibrary(
      {
        mediaType: 'photo',
        includeBase64: true,
        maxHeight: 500,
        maxWidth: 500,
      },
      response => {
        if (
          !response.errorCode &&
          response.assets &&
          response.assets.length > 0 &&
          response.assets[0] &&
          response.assets[0].base64
        ) {
          richText.current?.insertImage(
            'data:image/png;base64,' + response.assets[0].base64,
          );
        }
      },
    );
  };

  const callWriteApi = () => {
    const body = {};
    body[infoKey] = contents;
    api
      .post(`/leagues/${leagueId}/info`, body, {
        headers: {
          Authorization: `Bearer ${accessToken.current}`,
        },
      })
      .then(function (response) {
        DeviceEventEmitter.emit('leagueInfoRefresh', {id: response.data.id});
        alert('요청에 성공하였습니다.');
        Platform.OS === 'web' ? history.back() : navigation.goBack();
      })
      .catch(function (error) {
        console.log(error.response);
        alert('요청에 실패했습니다.');
      });
  };

  const richTextHandle = descriptionText => {
    if (descriptionText) {
      try {
        // descriptionText = descriptionText.replaceAll(/<a.*?<\/a>/g, '$1');
        descriptionText = descriptionText.replaceAll(/<\/?a[^>]*>/g, '');
        descriptionText = descriptionText.replaceAll('&nbsp;', ' ');
        descriptionText = descriptionText.replaceAll('&nbsp', ' ');
        const replacePattern1 =
          /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;
        const replacedText = descriptionText.replace(
          replacePattern1,
          '<a href="$1" target="_blank">$1</a>',
        );
        setContents(replacedText);
      } catch (e) {
        console.error(e);
        setContents(descriptionText);
      }
    } else {
      setContents('');
    }
  };

  const handleCancel = () => {
    alert('작성', '취소 하시겠습니까?', [
      {
        text: '예',
        onPress: () => {
          if (!contents) {
            Platform.OS === 'web' ? history.back() : navigation.goBack();
            return;
          }
        },
      },
      {
        text: '아니오',
      },
    ]);
  };

  const handleWrite = () => {
    alert('작성', '작성 완료 하시겠습니까?', [
      {
        text: '예',
        onPress: () => {
          if (!contents) {
            alert('필수 값을 입력해 주세요.');
            return;
          }
          callWriteApi();
        },
      },
      {
        text: '아니오',
      },
    ]);
  };

  useEffect(() => {
    AsyncStorage.getItem('@accessToken', (err, result) => {
      accessToken.current = result;
    });
  }, []);

  return (
    <>
      <SafeAreaView style={[styles.container]}>
        <Shadow
          offset={[0, 2]}
          style={[{width: '100%'}]}
          containerStyle={[{marginTop: 0}]}
          distance={7}
          sides={{bottom: true, top: false, start: false, end: false}}>
          <View style={[styles.headerCommon]}>
            <View style={[styles.row, styles.verticalAlign]}>
              <TouchableOpacity
                onPress={() =>
                  Platform.OS === 'web' ? history.back() : navigation.goBack()
                }>
                <Image
                  source={require('../assets/images/icon_back.png')}
                  style={[styles.headerBackButton]}
                />
              </TouchableOpacity>
              <Text style={[styles.headerText]}>리그 정보</Text>
            </View>
            <View style={[styles.row, styles.verticalAlign]}>
              <Image source={require('../assets/images/icon_uniqueplay.png')} />
            </View>
          </View>
        </Shadow>
        <KeyboardAvoidingView
          behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
          style={{flex: 1}}>
          <ScrollView
            style={[
              {
                marginTop: 10,
              },
            ]}
            nestedScrollEnabled={true}>
            <View
              behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
              style={{flex: 1}}>
              <Text style={[pageStyle.titleContainer, pageStyle.title]}>
                {type}
              </Text>
              <RichEditor
                initialContentHTML={contents}
                androidHardwareAccelerationDisabled={true}
                useContainer={false}
                onChange={richTextHandle}
                containerStyle={{
                  minHeight: Dimensions.get('window').height - 200,
                }}
                scrollEnabled={true}
                placeholder={'내용'}
                placeholderTextColor={'#aaa'}
                style={[pageStyle.editor]}
                ref={richText}
              />
            </View>
          </ScrollView>
          <RichToolbar
            editor={richText}
            actions={[
              actions.insertImage,
              actions.setBold,
              actions.setItalic,
              actions.insertBulletsList,
              actions.insertOrderedList,
            ]}
            onPressAddImage={() => openImageLibrary()}
            iconMap={{
              [actions.heading1]: ({tintColor}) => (
                <Text style={[{color: tintColor}]}>H1</Text>
              ),
            }}
          />
          <View style={[pageStyle.buttonContainer]}>
            <TouchableOpacity
              onPress={handleCancel}
              style={[pageStyle.buttonItem, pageStyle.cancelBtn]}>
              <Text style={[pageStyle.buttonItemTxt, pageStyle.cancelItemTxt]}>
                취소
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={handleWrite}
              style={[pageStyle.buttonItem, pageStyle.commonBtn]}>
              <Text style={[pageStyle.buttonItemTxt]}>작성완료</Text>
            </TouchableOpacity>
          </View>
        </KeyboardAvoidingView>
      </SafeAreaView>
    </>
  );
};

export default LeagueInfoEditView;

const pageStyle = StyleSheet.create({
  headerCommon: {},
  container: {
    borderWidth: 5,
    borderColor: '#ddd',
    opacity: 0.8,
    height: 200,
  },
  titleInput: {
    height: 35,
    fontFamily: 'Pretendard',
    fontSize: 16,
    paddingHorizontal: 10,
    borderBottomColor: '#ddd',
    borderBottomWidth: 1,
    color: 'black',
  },
  buttonContainer: {
    flexDirection: 'row',
    marginBottom: 12,
  },
  buttonItem: {
    flex: 1,
    height: 30,
    alignItems: 'center',
    alignContent: 'center',
  },
  buttonItemTxt: {
    textAlign: 'center',
    fontFamily: 'Pretendard',
    fontSize: 13,
    color: 'white',
  },
  cancelItemTxt: {
    color: '#555',
  },
  commonBtn: {
    marginTop: 25,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 17,
    height: 35,
    marginHorizontal: 15,
    backgroundColor: 'rgb(61, 80, 183)',
  },
  cancelBtn: {
    marginTop: 25,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 17,
    height: 35,
    marginHorizontal: 15,
    backgroundColor: '#ccc',
  },
  editor: {},
  writeBtnContainer: {
    position: 'absolute',
    bottom: 20,
    right: 20,
  },
  writeIcn: {
    width: 80,
    height: 80,
  },
  scrollViewContainer: {},
  titleContainer: {
    paddingHorizontal: 12,
    paddingVertical: 5,
    borderBottomWidth: 1,
    borderBottomColor: 'rgba(0, 0, 0, 0.15)',
  },
  title: {color: 'black', fontSize: 16, fontFamily: 'Pretendard'},
  list: {
    width: '100%',
    display: 'flex',
    marginTop: 6,
  },
});

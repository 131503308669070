import React, {useEffect, useRef, useState, useWindowDimensions} from 'react';
import {
  Image,
  SafeAreaView,
  Text,
  TouchableOpacity,
  View,
  StyleSheet,
  ImageBackground,
  Dimensions,
  ScrollView,
  DeviceEventEmitter,
  Platform,
} from 'react-native';
import api from '../../common/api';
import RenderHtml from 'react-native-render-html';
import AsyncStorage from '@react-native-async-storage/async-storage';
const LeagueIntroduction = props => {
  let accessToken = useRef();
  const [league, setLeague] = useState({});
  useEffect(() => {
    const unsubscribe = DeviceEventEmitter.addListener(
      'leagueInfoRefresh',
      function (data) {
        if (data.id == league.id) {
          getData(accessToken.current);
        }
      },
    );
    return () => {
      unsubscribe.remove();
    };
  }, [props?.item.id]);

  const getData = token => {
    api
      .get(`/leagues/${props?.item.id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(function (response) {
        setLeague(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  useEffect(() => {
    AsyncStorage.getItem('@accessToken', (err, result) => {
      accessToken.current = result;
      getData(result);
    });
  }, []);

  return (
    <ScrollView style={[pageStyle.container]}>
      <View style={[pageStyle.innerContainer]}>
        <Text style={[pageStyle.headerTxt]}>리그 소개</Text>
        <View style={[pageStyle.contentsContainer]}>
          {!league.introduction && (
            <ImageBackground
              resizeMode={'cover'}
              source={require('../../assets/images/logo2.png')}
              style={[pageStyle.bgImage]}
            />
          )}
          <RenderHtml
            tagsStyles={contentHtmlStyles}
            contentWidth={
              Platform.OS === 'web' ? 550 : Dimensions.get('window').width
            }
            source={{html: league.introduction}}
          />
          {props.league.myAuthorities &&
            props.league.myAuthorities.length > 0 && (
              <TouchableOpacity
                onPress={() =>
                  props.navigation.navigate('LeagueInfoEdit', {
                    leagueId: league.id,
                    type: '리그 소개',
                    infoKey: 'introduction',
                    content: league.introduction,
                  })
                }
                style={[pageStyle.button]}>
                <Text style={[pageStyle.btnTxt]}>내용수정</Text>
              </TouchableOpacity>
            )}
        </View>
        <Text style={[pageStyle.headerTxt]}>구장 정보</Text>
        <View style={[pageStyle.contentsContainer]}>
          {!league.stadiumInfo && (
            <ImageBackground
              resizeMode={'cover'}
              source={require('../../assets/images/logo2.png')}
              style={[pageStyle.bgImage]}
            />
          )}
          <RenderHtml
            tagsStyles={contentHtmlStyles}
            contentWidth={
              Platform.OS === 'web' ? 550 : Dimensions.get('window').width
            }
            source={{html: league.stadiumInfo}}
          />
          {props.league.myAuthorities &&
            props.league.myAuthorities.length > 0 && (
              <TouchableOpacity
                onPress={() =>
                  props.navigation.navigate('LeagueInfoEdit', {
                    leagueId: league.id,
                    type: '구장 정보',
                    infoKey: 'stadiumInfo',
                    content: league.stadiumInfo,
                  })
                }
                style={[pageStyle.button]}>
                <Text style={[pageStyle.btnTxt]}>내용수정</Text>
              </TouchableOpacity>
            )}
        </View>
        <Text style={[pageStyle.headerTxt]}>리그 규정</Text>
        <View style={[pageStyle.contentsContainer]}>
          {!league.ruleInfo && (
            <ImageBackground
              resizeMode={'cover'}
              source={require('../../assets/images/logo2.png')}
              style={[pageStyle.bgImage]}
            />
          )}
          <RenderHtml
            tagsStyles={contentHtmlStyles}
            contentWidth={
              Platform.OS === 'web' ? 550 : Dimensions.get('window').width
            }
            source={{html: league.ruleInfo}}
          />
          {props.league.myAuthorities &&
            props.league.myAuthorities.length > 0 && (
              <TouchableOpacity
                onPress={() =>
                  props.navigation.navigate('LeagueInfoEdit', {
                    leagueId: league.id,
                    type: '리그 규정',
                    infoKey: 'ruleInfo',
                    content: league.ruleInfo,
                  })
                }
                style={[pageStyle.button]}>
                <Text style={[pageStyle.btnTxt]}>내용수정</Text>
              </TouchableOpacity>
            )}
        </View>
      </View>
    </ScrollView>
  );
};

export default LeagueIntroduction;

const contentHtmlStyles = StyleSheet.create({
  table: {
    borderTopWidth: 1,
    borderLeftWidth: 1,
    borderColor: '#ccc',
    marginBottom: 7,
  },
  tr: {
    borderBottomWidth: 1,
    borderColor: '#ccc',
  },
  td: {
    borderRightWidth: 1,
    borderColor: '#ccc',
    padding: 5,
  },
});

const pageStyle = StyleSheet.create({
  headerCommon: {},
  container: {},
  headerTxt: {
    backgroundColor: 'rgb(48, 75, 116)',
    color: 'white',
    fontSize: 15,
    paddingVertical: 10,
    textAlign: 'center',
  },
  titleContainer: {
    paddingHorizontal: 12,
    paddingVertical: 5,
    borderBottomWidth: 1,
    borderBottomColor: 'rgba(0, 0, 0, 0.15)',
  },
  title: {color: 'black', fontSize: 13},
  innerContainer: {paddingBottom: 100},
  contentsContainer: {
    minHeight: 150,
    padding: 8,
  },
  bgImage: {
    width: 120,
    height: 80,
    alignSelf: 'center',
    marginTop: 24,
    position: 'absolute',
  },
  infoTxt: {marginVertical: 12, marginHorizontal: 27, textAlign: 'left'},
  logo: {},
  button: {
    marginTop: 39,
    marginBottom: 39,
    borderColor: '#1d3864',
    borderWidth: 1,
    width: 61,
    heght: 23,
    paddingVertical: 5,
    borderRadius: 15,
    textAlign: 'center',
    alignItems: 'center',
    alignSelf: 'center',
  },
  btnTxt: {
    color: '#1d3864',
    fontSize: 11,
    fontWeight: '500',
  },
  w100: {width: '100%'},
});

import {Platform} from 'react-native';

const isHr = recordName => {
  return [
    '좌월홈',
    '중월홈',
    '우월홈',
    '좌중월홈',
    '우중월홈',
    '좌그홈',
    '중그홈',
    '우그홈',
    '좌중그홈',
    '우중그홈',
  ].includes(recordName);
};

const isH2 = recordName => {
  return [
    '좌2',
    '중2',
    '우2',
    '좌중2',
    '우중2',
    '인좌2',
    '인중2',
    '인우2',
    '인좌중2',
    '인우중2',
    '좌월2',
    '중월2',
    '우월2',
  ].includes(recordName);
};

const isH3 = recordName => {
  return [
    '좌3',
    '중3',
    '우3',
    '좌전3',
    '좌중3',
    '우중3',
    '인좌3',
    '인중3',
    '인우3',
    '인좌중3',
    '인우중3',
    '좌월3',
    '중월3',
    '우월3',
  ].includes(recordName);
};
const isSb = recordName => {
  return ['도루'].includes(recordName);
};

const isDo = recordName => {
  return [
    '투병',
    '포병',
    '1병',
    '2병',
    '3병',
    '유병',
    '좌병',
    '중병',
    '우병',
    '투직병',
    '포직병',
    '1직병',
    '2직병',
    '3직병',
    '유직병',
    '좌직병',
    '중직병',
    '우직병',
    '투삼중살',
    '포삼중살',
    '1삼중살',
    '2삼중살',
    '3삼중살',
    '유삼중살',
    '좌삼중살',
    '중삼중살',
    '우삼중살',
  ].includes(recordName);
};

const filterNaN = (value, defaultValue) => {
  if (!value || isNaN(value)) {
    return defaultValue;
  }
  return value;
};

const getOrDefault = (condition, value, defaultValue) => {
  if (!condition) {
    return defaultValue;
  }
  return value;
};

const title = text =>
  Platform.select({web: `유니크플레이 | ${text}`, default: text});

export {isHr, isH2, isH3, isSb, isDo, filterNaN, getOrDefault, title};

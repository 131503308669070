import React, {useEffect, useMemo, useRef, useState} from 'react';
import {
  Text,
  View,
  StyleSheet,
  ImageBackground,
  Image,
  TouchableOpacity,
  Dimensions,
  Platform,
  DeviceEventEmitter,
} from 'react-native';
import Colors from '../common/colors';
import styles from '../common/styles';
import api from '../common/api';
import AsyncStorage from '@react-native-async-storage/async-storage';
import moment from 'moment';
import PlayerCard from '../component/PlayerCard';

const MyInfoSummary = ({userInfo, navigation}) => {
  const [toggle, setToggle] = useState(1);
  const [thisYearData, setThisYearData] = useState({});
  const [overallData, setOverallData] = useState({});
  const [gameCard, setGameCard] = useState({});

  let accessToken = useRef();

  const getRecord = token => {
    if (!token || !userInfo) {
      return;
    }

    api
      .get(
        `/games/records/by-user?seasonYear=${moment().year()}&uid=${
          userInfo.uid
        }`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then(function (response) {
        if (response.data) {
          setThisYearData(response.data);
        } else {
          setThisYearData({
            hab: 0,
            havg: '.000',
            hr: 0,
            rbi: 0,
            sb: 0,
            ops: '0.000',
            pgame: 0,
            win: 0,
            lose: 0,
            era: '0.00',
            innings: 0,
            so: 0,
            whip: '0.00',
          });
        }
      })
      .catch(function (error) {
        console.error(error.response);
      });

    api
      .get(`/games/records/by-user?uid=${userInfo.uid}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(function (response) {
        if (response.data) {
          setOverallData(response.data);
        } else {
          setOverallData({
            hab: 0,
            havg: '.000',
            hr: 0,
            rbi: 0,
            sb: 0,
            ops: '0.000',
            pgame: 0,
            win: 0,
            lose: 0,
            era: '0.00',
            innings: 0,
            so: 0,
            whip: '0.00',
          });
        }
      })
      .catch(function (error) {
        console.error(error.response);
      });
  };
  useEffect(() => {
    if (!userInfo || !userInfo.uid) {
      return;
    }
    AsyncStorage.getItem('@accessToken', (err, result) => {
      accessToken.current = result;
      getRecord(result);
      getGameCard();
    });
  }, [userInfo]);

  const profileImageUrl = useMemo(() => {
    if (userInfo.profileImageThumbUrl && userInfo.profileImageThumbUrl) {
      return {uri: userInfo.profileImageThumbUrl};
    }
    if (userInfo.profileImageUrl && userInfo.profileImageUrl) {
      return {uri: userInfo.profileImageUrl};
    }
    return require('../assets/images/userCard.png');
  }, [userInfo]);

  const getGameCard = () => {
    api
      .get(`/app-user/game-cards/representative?targetUid=${userInfo.uid}`, {
        headers: {
          Authorization: `Bearer ${accessToken.current}`,
        },
      })
      .then(function (response) {
        if (response.data) {
          setGameCard(response.data);
        } else {
        }
      })
      .catch(function (error) {
        console.error(error.response);
      });
  };

  const teamNames = useMemo(() => {
    if (!userInfo.teamName) {
      return [];
    }
    const arr = userInfo.teamName.split(',');
    const result = [];
    let item = [];
    for (let i = 0; i < arr.length; i++) {
      if (i % 2 === 0) {
        item = [];
      }
      item.push(arr[i].trim());
      if (i % 2 === 1) {
        result.push(item);
      }
      if (i % 2 === 0 && i === arr.length - 1) {
        item.push('');
        result.push(item);
      }
    }
    return result;
  }, [userInfo.teamName]);

  useEffect(() => {
    const unsubscribe = DeviceEventEmitter.addListener(
      '@playerCardRefresh',
      function (data) {
        getGameCard();
      },
    );
    return () => {
      unsubscribe.remove();
    };
  }, []);

  const fullWidth = useMemo(() => {
    if (Platform.OS === 'web') {
      const windowWidth = Dimensions.get('window').width;
      if (windowWidth < 550) {
        return windowWidth;
      } else {
        return 550;
      }
    }
    return '100%';
  }, []);

  const pageStyle = StyleSheet.create({
    innerContainer: {
      paddingHorizontal: 29,
    },
    subtitle: {
      marginTop: 16,
      marginStart: 10,
      color: 'black',
      fontSize: 16,
      fontWeight: '700',
      fontFamily: 'Pretendard-Bold',
    },
    subtitleFirst: {
      marginTop: -19,
    },
    toggleContainer: {
      flexDirection: 'row',
      marginTop: 20,
      justifyContent: 'flex-end',
      zIndex: 10,
    },
    toggleItem: {
      width: 42,
      height: 24,
      borderWidth: 1,
      borderColor: '#ccc',
      justifyContent: 'center',
      display: 'flex',
    },
    toggleLeft: {
      borderTopStartRadius: 12,
      borderBottomStartRadius: 12,
      borderEndWidth: 0,
    },
    toggleRight: {
      borderTopEndRadius: 12,
      borderBottomEndRadius: 12,
    },
    toggleLeftSelected: {
      border: 0,
      backgroundColor: Colors.main,
    },
    toggleRightSelected: {
      border: 0,
      backgroundColor: Colors.main,
    },
    toggleTxt: {
      textAlign: 'center',
      color: 'black',
      fontFamily: 'Pretendard-SemiBold',
      fontWeight: '600',
      fontSize: 12,
    },
    toggleTxtSelected: {
      color: 'white',
    },
    row: {
      flexDirection: 'row',
    },
    firstRow: {
      marginTop: 10,
    },
    mainColorBg: {
      backgroundColor: Colors.main,
    },
    col: {
      flex: 1,
    },
    th: {
      backgroundColor: '#3f51b5',
      paddingVertical: 4,
      lineHeight: 13,
      fontSize: 13,
      color: 'white',
      fontWeight: '700',
      fontFamily: 'Pretendard-Bold',
      textAlign: 'center',
    },
    td: {
      height: 30,
      lineHeight: 28,
      fontSize: 13,
      color: 'black',
      fontWeight: '700',
      fontFamily: 'Pretendard-Bold',
      textAlign: 'center',
    },
    odd: {
      backgroundColor: 'white',
    },
    even: {
      backgroundColor: 'rgb(236, 236, 236)',
    },
    cardContainer: {
      marginTop: 33,
    },
    cardItemContainer: {
      width:
        Platform.OS === 'web'
          ? (fullWidth - 29 * 2) / 2
          : (Dimensions.get('window').width - 29 * 2) / 2,
      marginEnd: 18,
      paddingBottom: 12,
    },
    cardBg: {
      position: 'relative',
      height: 161,
    },
    cardBall: {
      position: 'absolute',
      top: -2,
      left: -2,
    },
    gotoPlayerCardTxt: {
      textAlign: 'center',
      marginTop: 4,
      color: 'black',
      fontSize: 13,
      fontFamily: 'Pretendard',
    },
    usernameContainer: {
      width: '100%',
      height: 20,
      backgroundColor: 'rgba(255, 255, 255, 0.5)',
      position: 'absolute',
      bottom: 38,
      left: 0,
      zIndex: 1,
    },
    username: {
      color: '#404040',
      letterSpacing: 1.5,
      lineHeight: 20,
      fontWeight: '800',
      fontFamily: 'Pretendard-ExtraBold',
      position: 'absolute',
      right: 10,
    },
    label: {
      width: 60,
      color: 'rgb(0, 0, 79)',
      fontSize: 14,
      fontWeight: 'bold',
      fontFamily: 'Pretendard-Bold',
      alignSelf: 'center',
    },
    value: {
      color: 'black',
      fontFamily: 'Pretendard',
      fontSize: 13,
      alignSelf: 'center',
      flex: 1,
    },
    value2: {
      color: 'black',
      fontFamily: 'Pretendard',
      fontSize: 13,
      alignSelf: 'center',
    },
    cardInfoRow: {
      marginTop: 15,
    },
    userCard: {
      width: 115,
      height: 161,
      position: 'absolute',
      top: 0,
    },
    userInCard: {
      width: 115,
      height: 123,
      position: 'absolute',
      top: 0,
    },
    recordTable: {
      position: 'absolute',
      width: 115,
      bottom: 0,
      left: 0,
      zIndex: 1,
    },
    cardTr: {
      display: 'flex',
      flexDirection: 'row',
    },
    cardCol: {
      flex: 1,
      height: 40,
      display: 'flex',
      flexDirection: 'column',
      alignContent: 'center',
      alignItems: 'center',
    },
    cardTh: {
      lineHeight: 20,
      height: 20,
      color: 'white',
      fontFamily: 'Pretendard-SemiBold',
      fontWeight: '600',
      fontSize: 8,
      textAlign: 'center',
    },
    cardTd: {
      lineHeight: 20,
      height: 20,
      color: '#1d3864',
      fontFamily: 'Pretendard-SemiBold',
      fontWeight: '600',
      fontSize: 8,
    },
  });

  return (
    <View style={[pageStyle.innerContainer]}>
      <View style={[pageStyle.toggleContainer]}>
        <View style={[styles.row]}>
          <TouchableOpacity
            onPress={() => setToggle(1)}
            style={[
              pageStyle.toggleItem,
              pageStyle.toggleLeft,
              toggle === 1 && pageStyle.toggleLeftSelected,
            ]}>
            <Text
              style={[
                pageStyle.toggleTxt,
                toggle === 1 && pageStyle.toggleTxtSelected,
              ]}>
              타자
            </Text>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => setToggle(0)}
            style={[
              pageStyle.toggleItem,
              pageStyle.toggleRight,
              toggle === 0 && pageStyle.toggleRightSelected,
            ]}>
            <Text
              style={[
                pageStyle.toggleTxt,
                toggle === 0 && pageStyle.toggleTxtSelected,
              ]}>
              투수
            </Text>
          </TouchableOpacity>
        </View>
      </View>
      <Text style={[pageStyle.subtitle, pageStyle.subtitleFirst]}>
        이번 시즌
      </Text>
      {toggle === 0 && (
        <View>
          <View
            style={[pageStyle.row, pageStyle.firstRow, pageStyle.mainColorBg]}>
            <View style={[pageStyle.col]}>
              <Text style={[pageStyle.th]} numberOfLines={1}>
                경기
              </Text>
            </View>
            <View style={[pageStyle.col]}>
              <Text style={[pageStyle.th]} numberOfLines={1}>
                승-패
              </Text>
            </View>
            <View style={[pageStyle.col]}>
              <Text style={[pageStyle.th]} numberOfLines={1}>
                ERA
              </Text>
            </View>
            <View style={[pageStyle.col]}>
              <Text style={[pageStyle.th]}>이닝</Text>
            </View>
            <View style={[pageStyle.col]}>
              <Text style={[pageStyle.th]} numberOfLines={1}>
                탈삼진
              </Text>
            </View>
            <View style={[pageStyle.col]}>
              <Text style={[pageStyle.th]} numberOfLines={1}>
                WHIP
              </Text>
            </View>
          </View>
          <View style={[pageStyle.row]}>
            <View style={[pageStyle.col]}>
              <Text style={[pageStyle.td]} numberOfLines={1}>
                {thisYearData.pgame}
              </Text>
            </View>
            <View style={[pageStyle.col]}>
              <Text style={[pageStyle.td, pageStyle.even]} numberOfLines={1}>
                {thisYearData.win}-{thisYearData.lose}
              </Text>
            </View>
            <View style={[pageStyle.col]}>
              <Text style={[pageStyle.td]} numberOfLines={1}>
                {thisYearData?.era}
              </Text>
            </View>
            <View style={[pageStyle.col]}>
              <Text style={[pageStyle.td, pageStyle.even]} numberOfLines={1}>
                {thisYearData?.innings}
              </Text>
            </View>
            <View style={[pageStyle.col]}>
              <Text style={[pageStyle.td]} numberOfLines={1}>
                {thisYearData?.so}
              </Text>
            </View>
            <View style={[pageStyle.col]}>
              <Text style={[pageStyle.td, pageStyle.even]} numberOfLines={1}>
                {thisYearData?.whip}
              </Text>
            </View>
          </View>
        </View>
      )}
      {toggle === 1 && (
        <View>
          <View
            style={[pageStyle.row, pageStyle.firstRow, pageStyle.mainColorBg]}>
            <View style={[pageStyle.col]}>
              <Text style={[pageStyle.th]} numberOfLines={1}>
                타수
              </Text>
            </View>
            <View style={[pageStyle.col]}>
              <Text style={[pageStyle.th]} numberOfLines={1}>
                타율
              </Text>
            </View>
            <View style={[pageStyle.col]}>
              <Text style={[pageStyle.th]} numberOfLines={1}>
                홈런
              </Text>
            </View>
            <View style={[pageStyle.col]}>
              <Text style={[pageStyle.th]} numberOfLines={1}>
                타점
              </Text>
            </View>
            <View style={[pageStyle.col]}>
              <Text style={[pageStyle.th]} numberOfLines={1}>
                도루
              </Text>
            </View>
            <View style={[pageStyle.col]}>
              <Text style={[pageStyle.th]} numberOfLines={1}>
                OPS
              </Text>
            </View>
          </View>
          <View style={[pageStyle.row]}>
            <View style={[pageStyle.col]}>
              <Text style={[pageStyle.td, pageStyle.odd]} numberOfLines={1}>
                {thisYearData.hab}
              </Text>
            </View>
            <View style={[pageStyle.col]}>
              <Text style={[pageStyle.td, pageStyle.even]} numberOfLines={1}>
                {thisYearData.havg}
              </Text>
            </View>
            <View style={[pageStyle.col]}>
              <Text style={[pageStyle.td, pageStyle.odd]} numberOfLines={1}>
                {thisYearData.hr}
              </Text>
            </View>
            <View style={[pageStyle.col]}>
              <Text style={[pageStyle.td, pageStyle.even]} numberOfLines={1}>
                {thisYearData.rbi}
              </Text>
            </View>
            <View style={[pageStyle.col]}>
              <Text style={[pageStyle.td, pageStyle.odd]} numberOfLines={1}>
                {thisYearData.sb}
              </Text>
            </View>
            <View style={[pageStyle.col]}>
              <Text style={[pageStyle.td, pageStyle.even]} numberOfLines={1}>
                {thisYearData.ops}
              </Text>
            </View>
          </View>
        </View>
      )}
      <Text style={[pageStyle.subtitle]}>통산 시즌</Text>
      {toggle === 0 && (
        <View>
          <View
            style={[pageStyle.row, pageStyle.firstRow, pageStyle.mainColorBg]}>
            <View style={[pageStyle.col]}>
              <Text style={[pageStyle.th]} numberOfLines={1}>
                경기
              </Text>
            </View>
            <View style={[pageStyle.col]}>
              <Text style={[pageStyle.th]} numberOfLines={1}>
                승-패
              </Text>
            </View>
            <View style={[pageStyle.col]}>
              <Text style={[pageStyle.th]} numberOfLines={1}>
                ERA
              </Text>
            </View>
            <View style={[pageStyle.col]}>
              <Text style={[pageStyle.th]}>이닝</Text>
            </View>
            <View style={[pageStyle.col]}>
              <Text style={[pageStyle.th]} numberOfLines={1}>
                탈삼진
              </Text>
            </View>
            <View style={[pageStyle.col]}>
              <Text style={[pageStyle.th]} numberOfLines={1}>
                WHIP
              </Text>
            </View>
          </View>
          <View style={[pageStyle.row]}>
            <View style={[pageStyle.col]}>
              <Text style={[pageStyle.td]} numberOfLines={1}>
                {overallData.pgame}
              </Text>
            </View>
            <View style={[pageStyle.col]}>
              <Text style={[pageStyle.td, pageStyle.even]} numberOfLines={1}>
                {overallData.win}-{overallData.lose}
              </Text>
            </View>
            <View style={[pageStyle.col]}>
              <Text style={[pageStyle.td]} numberOfLines={1}>
                {overallData.era}
              </Text>
            </View>
            <View style={[pageStyle.col]}>
              <Text style={[pageStyle.td, pageStyle.even]} numberOfLines={1}>
                {overallData.innings}
              </Text>
            </View>
            <View style={[pageStyle.col]}>
              <Text style={[pageStyle.td]} numberOfLines={1}>
                {overallData.so}
              </Text>
            </View>
            <View style={[pageStyle.col]}>
              <Text style={[pageStyle.td, pageStyle.even]} numberOfLines={1}>
                {overallData.whip}
              </Text>
            </View>
          </View>
        </View>
      )}
      {toggle === 1 && (
        <View>
          <View
            style={[pageStyle.row, pageStyle.firstRow, pageStyle.mainColorBg]}>
            <View style={[pageStyle.col]}>
              <Text style={[pageStyle.th]} numberOfLines={1}>
                타수
              </Text>
            </View>
            <View style={[pageStyle.col]}>
              <Text style={[pageStyle.th]} numberOfLines={1}>
                타율
              </Text>
            </View>
            <View style={[pageStyle.col]}>
              <Text style={[pageStyle.th]} numberOfLines={1}>
                홈런
              </Text>
            </View>
            <View style={[pageStyle.col]}>
              <Text style={[pageStyle.th]} numberOfLines={1}>
                타점
              </Text>
            </View>
            <View style={[pageStyle.col]}>
              <Text style={[pageStyle.th]} numberOfLines={1}>
                도루
              </Text>
            </View>
            <View style={[pageStyle.col]}>
              <Text style={[pageStyle.th]} numberOfLines={1}>
                OPS
              </Text>
            </View>
          </View>
          <View style={[pageStyle.row]}>
            <View style={[pageStyle.col]}>
              <Text style={[pageStyle.td]} numberOfLines={1}>
                {overallData.hab}
              </Text>
            </View>
            <View style={[pageStyle.col]}>
              <Text style={[pageStyle.td, pageStyle.even]} numberOfLines={1}>
                {overallData.havg}
              </Text>
            </View>
            <View style={[pageStyle.col]}>
              <Text style={[pageStyle.td]} numberOfLines={1}>
                {overallData.hr}
              </Text>
            </View>
            <View style={[pageStyle.col]}>
              <Text style={[pageStyle.td, pageStyle.even]} numberOfLines={1}>
                {overallData.rbi}
              </Text>
            </View>
            <View style={[pageStyle.col]}>
              <Text style={[pageStyle.td]} numberOfLines={1}>
                {overallData.sb}
              </Text>
            </View>
            <View style={[pageStyle.col]}>
              <Text style={[pageStyle.td, pageStyle.even]} numberOfLines={1}>
                {overallData.ops}
              </Text>
            </View>
          </View>
        </View>
      )}
      <View style={[pageStyle.cardContainer]}>
        <View style={[styles.row]}>
          <View style={[pageStyle.cardItemContainer]}>
            <TouchableOpacity
              onPress={() =>
                navigation.navigate('PlayerCardList', {
                  userInfo: userInfo,
                  itemId: userInfo.uid,
                })
              }>
              <PlayerCard
                item={gameCard}
                userInfo={userInfo}
                cardWidth={
                  Platform.OS === 'web'
                    ? (fullWidth - 29 * 2) / 2
                    : (Dimensions.get('window').width - 29 * 2) / 2
                }
                margin={1}
                hideBottom={true}
              />
              <Text style={[pageStyle.gotoPlayerCardTxt]}>선수카드 보기</Text>
            </TouchableOpacity>
          </View>
          <View style={[styles.col, {paddingBottom: 24}]}>
            <View style={[styles.row]}>
              <Text style={[pageStyle.label]}>소속팀</Text>
            </View>
            {teamNames.map((teamNameArr, index) => (
              <View style={[styles.row, styles.mt3]}>
                <Text
                  style={[pageStyle.value2, {minWidth: '50%', maxWidth: '50%'}]}
                  numberOfLines={1}>
                  {teamNameArr[0]}
                </Text>
                <Text
                  style={[pageStyle.value2, {minWidth: '50%', maxWidth: '50%'}]}
                  numberOfLines={1}>
                  {teamNameArr[1]}
                </Text>
              </View>
            ))}
            <View style={[styles.row, pageStyle.cardInfoRow]}>
              <Text style={[pageStyle.label]}>생년월일</Text>
              <Text style={[pageStyle.value]}>
                {!userInfo.hideBirth ? userInfo.birth : '비공개'}
              </Text>
            </View>
            <View style={[styles.row, pageStyle.cardInfoRow]}>
              <Text style={[pageStyle.label]}>투타</Text>
              <Text style={[pageStyle.value]}>
                {userInfo.pitchingAndHitting}
              </Text>
            </View>
            <View style={[styles.row, pageStyle.cardInfoRow]}>
              <Text style={[pageStyle.label]}>선출여부</Text>
              <Text style={[pageStyle.value]}>{userInfo.formerAthlete}</Text>
            </View>
            <View style={[styles.row, pageStyle.cardInfoRow]}>
              <Text style={[pageStyle.label]}>출신학교</Text>
              <Text style={[pageStyle.value]}>{userInfo.school}</Text>
            </View>
          </View>
        </View>
      </View>
    </View>
  );
};

export default MyInfoSummary;

import {Image, Platform, StyleSheet, Text, TextInput, View} from 'react-native';
import {Dimensions} from 'react-native';
import styles from '../common/styles';
import {SafeAreaView} from 'react-native-safe-area-context';
import {Shadow} from 'react-native-shadow-2';
import {TouchableOpacity} from 'react-native-gesture-handler';
import {useEffect, useRef, useState} from 'react';
import Colors from '../common/colors';
import api from '../common/api';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {title} from '../common/util';

const ReportView = ({navigation, route}) => {
  const {userName, boardTitle, boardId, commentId, commentContents} =
    route.params;

  const [ids, setIds] = useState([]);

  let accessToken = useRef();

  useEffect(() => {
    console.log(userName, boardTitle, boardId, commentId, commentContents);
  }, []);

  useEffect(() => {
    AsyncStorage.getItem('@accessToken', (err, result) => {
      if (err) {
        console.error(err);
        return;
      }
      accessToken.current = result;
    });
  }, []);

  const toggle = id => {
    if (ids.includes(id)) {
      const index = ids.indexOf(id);
      var array = [...ids];
      array.splice(index, 1);
      setIds(array);
    } else {
      setIds([...ids, id]);
    }
  };

  const confirm = () => {
    if (!ids || ids.length === 0) {
      alert('사유를 선택해 주세요.');
      return;
    }
    const contents = [];
    if (ids.includes(1)) {
      contents.push('스팸홍보/도배글입니다.');
    }
    if (ids.includes(2)) {
      contents.push('음란물입니다.');
    }
    if (ids.includes(3)) {
      contents.push('불법정보를 포함하고 있습니다.');
    }
    if (ids.includes(4)) {
      contents.push('청소년에게 유해한 내용입니다.');
    }
    if (ids.includes(5)) {
      contents.push('욕설/생명경시/혐오/차별적 표현입니다.');
    }
    if (ids.includes(6)) {
      contents.push('개인정보 노출 게시물입니다.');
    }
    if (ids.includes(7)) {
      contents.push('불쾌한 표현이 있습니다.');
    }
    api
      .post(
        '/boards/reports',
        {
          boardId: boardId ? boardId : null,
          commentId: commentId ? commentId : null,
          contents: contents.join(','),
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken.current}`,
          },
        },
      )
      .then(function (response) {
        alert('신고가 접수되었습니다.');
        Platform.OS === 'web' ? history.back() : navigation.goBack();
      })
      .catch(function (error) {
        console.error(error.response);
        alert('요청에 실패했습니다.');
      });
  };
  return (
    <SafeAreaView nativeID="common-page-container" style={[styles.container]}>
      <Shadow
        offset={[0, 2]}
        style={[{width: '100%'}]}
        containerStyle={[{marginTop: 0}]}
        distance={7}
        sides={{bottom: true, top: false, start: false, end: false}}>
        <View style={[styles.headerCommon]}>
          <View style={[styles.row, styles.verticalAlign]}>
            <TouchableOpacity
              onPress={() =>
                Platform.OS === 'web' ? history.back() : navigation.goBack()
              }>
              <Image
                source={require('../assets/images/icon_back.png')}
                style={[styles.headerBackButton]}
              />
            </TouchableOpacity>
            <Text style={[styles.headerText]}>신고하기</Text>
          </View>
          <View style={[styles.row, styles.verticalAlign]} />
        </View>
      </Shadow>
      <View style={[pageStyle.container]}>
        <View style={[pageStyle.headerContainer]}>
          <View style={[styles.row, pageStyle.headerRow]}>
            <Text style={[pageStyle.subtitle]}>작성자</Text>
            <Text style={[pageStyle.verticalBar]}>|</Text>
            <Text style={[pageStyle.titleValue]}>{userName}</Text>
          </View>
          <View style={[styles.row, pageStyle.headerRow]}>
            <Text style={[pageStyle.subtitle]}>내용</Text>
            <Text style={[pageStyle.verticalBar]}>|</Text>
            <Text style={[pageStyle.titleValue]}>
              {boardId ? boardTitle : commentContents}
            </Text>
          </View>
        </View>
        <View style={[pageStyle.contentsContainer]}>
          <Text style={[pageStyle.subtitle, {width: 120}]}>사유선택</Text>
          <TouchableOpacity
            onPress={() => toggle(1)}
            style={[styles.row, pageStyle.row, {marginTop: 24}]}>
            <Image
              style={[pageStyle.icnCheck]}
              source={
                ids.includes(1)
                  ? require('../assets/images/icn_checked.png')
                  : require('../assets/images/icn_unchecked.png')
              }
            />
            <Text style={[pageStyle.txt]}>스팸홍보/도배글입니다.</Text>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => toggle(2)}
            style={[styles.row, pageStyle.row]}>
            <Image
              style={[pageStyle.icnCheck]}
              source={
                ids.includes(2)
                  ? require('../assets/images/icn_checked.png')
                  : require('../assets/images/icn_unchecked.png')
              }
            />
            <Text style={[pageStyle.txt]}>음란물입니다.</Text>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => toggle(3)}
            style={[styles.row, pageStyle.row]}>
            <Image
              style={[pageStyle.icnCheck]}
              source={
                ids.includes(3)
                  ? require('../assets/images/icn_checked.png')
                  : require('../assets/images/icn_unchecked.png')
              }
            />
            <Text style={[pageStyle.txt]}>불법정보를 포함하고 있습니다.</Text>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => toggle(4)}
            style={[styles.row, pageStyle.row]}>
            <Image
              style={[pageStyle.icnCheck]}
              source={
                ids.includes(4)
                  ? require('../assets/images/icn_checked.png')
                  : require('../assets/images/icn_unchecked.png')
              }
            />
            <Text style={[pageStyle.txt]}>청소년에게 유해한 내용입니다.</Text>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => toggle(5)}
            style={[styles.row, pageStyle.row]}>
            <Image
              style={[pageStyle.icnCheck]}
              source={
                ids.includes(5)
                  ? require('../assets/images/icn_checked.png')
                  : require('../assets/images/icn_unchecked.png')
              }
            />
            <Text style={[pageStyle.txt]}>
              욕설/생명경시/혐오/차별적 표현입니다.
            </Text>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => toggle(6)}
            style={[styles.row, pageStyle.row]}>
            <Image
              style={[pageStyle.icnCheck]}
              source={
                ids.includes(6)
                  ? require('../assets/images/icn_checked.png')
                  : require('../assets/images/icn_unchecked.png')
              }
            />
            <Text style={[pageStyle.txt]}>개인정보 노출 게시물입니다.</Text>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => toggle(7)}
            style={[styles.row, pageStyle.row]}>
            <Image
              style={[pageStyle.icnCheck]}
              source={
                ids.includes(7)
                  ? require('../assets/images/icn_checked.png')
                  : require('../assets/images/icn_unchecked.png')
              }
            />
            <Text style={[pageStyle.txt]}>불쾌한 표현이 있습니다.</Text>
          </TouchableOpacity>
          <TouchableOpacity onPress={confirm} style={[pageStyle.button]}>
            <Text style={[pageStyle.buttonTxt]}>신고하기</Text>
          </TouchableOpacity>
        </View>
      </View>
    </SafeAreaView>
  );
};

const pageStyle = StyleSheet.create({
  headerCommon: {},
  headerText: {color: 'white'},
  container: {},
  headerContainer: {
    paddingHorizontal: 24,
    paddingTop: 24,
    paddingBottom: 12,
    borderBottomColor: '#ccc',
    borderBottomWidth: 1,
  },
  headerRow: {
    marginBottom: 10,
    alignItems: 'center',
  },
  verticalBar: {
    color: '#999',
    fontFamily: 'Pretendard-Bold',
    fontWeight: 'bold',
  },
  subtitle: {
    color: 'black',
    fontFamily: 'Pretendard-Bold',
    fontSize: 15,
    fontWeight: 'bold',
    width: 50,
  },
  titleValue: {
    color: 'black',
    marginStart: 12,
  },
  contentsContainer: {
    padding: 24,
  },
  row: {
    marginVertical: 6,
    alignItems: 'center',
  },
  icnCheck: {
    width: 24,
    height: 24,
    marginStart: 16,
  },
  txt: {
    fontFamily: 'Pretendard',
    fontSize: 15,
    color: 'black',
    marginStart: 18,
  },
  button: {
    marginTop: 42,
    height: 50,
    borderRadius: 25,
    backgroundColor: Colors.main,
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonTxt: {
    color: 'white',
    fontFamily: 'Pretendard-Bold',
    fontSize: 18,
    fontWeight: 'bold',
  },
});

export default ReportView;

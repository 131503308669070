import * as React from 'react';
import {Shadow} from 'react-native-shadow-2';
import {
  Text,
  View,
  StyleSheet,
  Animated,
  TouchableOpacity,
  Image,
  Platform,
} from 'react-native';
import styles from '../common/styles';

export default function DynamicHeader(props) {
  const {children, animHeaderValue, header} = props;

  const animateHeaderTopHeight = animHeaderValue.interpolate({
    inputRange: [0, 67],
    outputRange: [67, 0],
    extrapolate: 'clamp',
  });

  const animateHeaderHeight = animHeaderValue.interpolate({
    inputRange: [0, 67],
    outputRange: [
      (Platform.OS === 'ios' ? 220 : Platform.OS === 'android' ? 240 : 310) +
        67,
      Platform.OS === 'ios' ? 220 : Platform.OS === 'android' ? 240 : 310,
    ],
    extrapolate: 'clamp',
  });

  return (
    <View>
      <Animated.View
        style={[
          styles.header,
          {
            height: animateHeaderHeight,
          },
        ]}>
        <Animated.View
          style={[
            styles.header,
            {
              height: animateHeaderTopHeight,
            },
          ]}>
          {header}
        </Animated.View>
        {children}
      </Animated.View>
    </View>
  );
}

const pageStyle = StyleSheet.create({
  header: {},
  gameDate: {
    fontFamily: 'Pretendard-Medium',
    fontWeight: '500',
    fontSize: 14,
    color: 'black',
  },
  leagueName: {
    marginTop: 3,
    fontSize: 14,
    fontFamily: 'Pretendard',
    color: 'black',
  },
});

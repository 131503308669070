import React, {useState, useEffect, useRef, useMemo} from 'react';
import {
  Image,
  SafeAreaView,
  ScrollView,
  Text,
  TouchableOpacity,
  View,
  StyleSheet,
  ImageBackground,
  Platform,
  Dimensions,
  useWindowDimensions,
  Animated,
} from 'react-native';
import {useSafeAreaInsets} from 'react-native-safe-area-context';
import {Shadow} from 'react-native-shadow-2';
import styles from '../common/styles';
import AsyncStorage from '@react-native-async-storage/async-storage';
import api from '../common/api';
import * as Progress from 'react-native-progress';
import moment from 'moment';
import 'moment/locale/ko';
import YoutubePlayer, {PLAYER_STATES} from 'react-native-youtube-iframe';
import Colors from '../common/colors';
import DynamicHeader from '../component/DynanicHeader';
import Clipboard from '@react-native-clipboard/clipboard';
import * as ScreenOrientation from 'expo-screen-orientation';
import {title} from '../common/util';

const VideoView = ({navigation, route}) => {
  const insets = useSafeAreaInsets();

  const accessToken = useRef();
  const youtubeRef = useRef();

  const {itemId, mode, user, time, cam} = route.params;
  const [game, setGame] = useState(route.params.game);

  const [userInfo, setUserInfo] = useState(user);
  const [viewMode, setViewMode] = useState(mode ? mode : 'FULL');

  const [activeCamNumber, setActiveCamNumber] = useState(
    cam ? parseInt(cam, 10) : 1,
  );
  const [activeInning, setActiveInning] = useState(''); // 1회초: 1_f, 1회말: 1_l

  const [teamBatterLineUps, setTeamBatterLineUps] = useState([]);

  const [refreshing, setRefreshing] = useState(false);
  const [progressShown, setProgressShown] = useState(false);

  const [shareModeOpened, setShareModeOpened] = useState(false);

  const [selectedUserName, setSelectedUserName] = useState();

  const [firstTeamBatterLineUps, setFirstTeamBatterLineUps] = useState([]);
  const [lastTeamBatterLineUps, setLastTeamBatterLineUps] = useState([]);

  const [videoState, setVideoState] = useState(1);

  const loading = useRef();

  useEffect(() => {
    AsyncStorage.getItem('@accessToken', (err, result) => {
      accessToken.current = result;
      getData(result);
    });
  }, [game]);

  useEffect(() => {
    AsyncStorage.getItem('@accessToken', (err, result) => {
      accessToken.current = result;
      refreshGameData(accessToken.current);
    });
  }, [itemId]);

  const movedFromSharedVideo = useRef(false);

  useEffect(() => {
    console.log('videoState', videoState, PLAYER_STATES.PLAYING);
    console.log('camChanged.current', camChanged.current, lastTime.current);
    console.log(
      'movedFromSharedVideo.current',
      movedFromSharedVideo.current,
      time,
    );
    if (camChanged.current && videoState == PLAYER_STATES.PLAYING) {
      if (Platform.OS === 'web') {
        player.seekTo(lastTime.current, true);
      } else {
        youtubeRef.current.seekTo(lastTime.current);
      }
      camChanged.current = false;
    } else if (
      !movedFromSharedVideo.current &&
      time > 0 &&
      videoState == PLAYER_STATES.PLAYING
    ) {
      if (Platform.OS === 'web') {
        player.seekTo(time);
      } else {
        youtubeRef.current.seekTo(time);
      }
      movedFromSharedVideo.current = true;
      console.log('seekTo');
    }
  }, [videoState]);

  const refreshGameData = token => {
    console.log(`/games/${itemId}`);
    api
      .get(`/games/${itemId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(function (response) {
        setGame(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const youtubeVideoId = useMemo(() => {
    if (viewMode === 'HOTCLIP') {
      if (
        !game?.hotClipVideoUrl ||
        game?.hotClipVideoUrl?.indexOf('channel') > -1
      ) {
        return null;
      }
      if (!game?.hotClipVideoUrl?.indexOf('watch?v=') > -1) {
        const splited = game?.hotClipVideoUrl?.split('/');
        return splited[splited.length - 1];
      } else {
        const splited = game?.hotClipVideoUrl?.split('watch?v=');
        if (!splited || splited.length === 0) {
          return null;
        }
        return splited[splited.length - 1].replace('watch?v=', '');
      }
    }
    console.log('game full video url', game?.fullVideoUrl);
    if (activeCamNumber === 1) {
      if (!game?.fullVideoUrl?.indexOf('watch?v=') > -1) {
        const splited = game?.fullVideoUrl?.split('/');
        if (!splited || splited.length === 0) {
          return null;
        }
        return splited[splited.length - 1].replace('watch?v=', '');
      } else {
        const splited = game?.fullVideoUrl?.split('watch?v=');
        if (!splited || splited.length === 0) {
          return null;
        }
        return splited[splited.length - 1];
      }
    }
    if (
      game?.batterVideoUrl &&
      !game?.batterVideoUrl?.indexOf('channel') > -1
    ) {
      if (!game?.batterVideoUrl?.indexOf('watch?v=') > -1) {
        const splited = game?.batterVideoUrl?.split('/');
        if (!splited || splited.length === 0) {
          return null;
        }
        return splited[splited.length - 1];
      } else {
        const splited = game?.batterVideoUrl?.split('watch?v=');
        if (!splited || splited.length === 0) {
          return null;
        }
        return splited[splited.length - 1].replace('watch?v=', '');
      }
    }
    if (
      game?.batteryVideoUrl &&
      !game?.batteryVideoUrl?.indexOf('channel') > -1
    ) {
      if (!game?.batteryVideoUrl?.indexOf('watch?v=') > -1) {
        const splited = game?.batteryVideoUrl?.split('/');
        if (!splited || splited.length === 0) {
          return null;
        }
        return splited[splited.length - 1];
      } else {
        const splited = game?.batteryVideoUrl?.split('watch?v=');
        if (!splited || splited.length === 0) {
          return null;
        }
        return splited[splited.length - 1].replace('watch?v=', '');
      }
    }
    // if (viewMode === 'HOTCLIP') {
    //   return 'Nk-LRbkZWY8';
    // }
    // return activeCamNumber === 1 ? '_NQeg4oPjwc' : 'D_CankBEGN0';
  }, [game, activeCamNumber, viewMode]);

  useEffect(() => {
    if (!youtubeVideoId) {
      return;
    }
    if (Platform.OS === 'web') {
      if (!document.getElementsByTagName('iframe')[0].contentWindow.player) {
        return;
      }
      console.log('lastTime.current', lastTime.current);
      document
        .getElementsByTagName('iframe')[0]
        .contentWindow.player.loadVideoById(youtubeVideoId);
      setTimeout(() => {
        document
          .getElementsByTagName('iframe')[0]
          .contentWindow.player.seekTo(lastTime.current);
      }, 500);
    }
  }, [youtubeVideoId]);

  const lastTime = useRef(0);
  const camChanged = useRef(false);
  const toggleCam = async camNumber => {
    if (Platform.OS !== 'web') {
      lastTime.current = await youtubeRef.current.getCurrentTime();
    } else {
      lastTime.current = document
        .getElementsByTagName('iframe')[0]
        .contentWindow.player.getCurrentTime();
    }
    setActiveCamNumber(camNumber);
    if (viewMode === 'HOTCLIP') {
      setViewMode('FULL');
    }
    camChanged.current = true;
  };

  const getFirstTeamScore = item => {
    return item?.records && JSON.parse(item?.records)?.batFirstTeam?.score;
  };

  const getLastTeamScore = item => {
    return item?.records && JSON.parse(item?.records)?.batLastTeam?.score;
  };

  const getSerializedRecords = useMemo(() => {
    if (
      !activeInning ||
      activeInning.split('_').length === 0 ||
      !game ||
      !game.records
    ) {
      return [];
    }
    const splited = activeInning.split('_');
    const inning = parseInt(splited[0], 10);
    const serializedRecords =
      splited[1] === 'f'
        ? JSON.parse(game?.records).batFirstTeam.serializedRecords
        : JSON.parse(game?.records).batLastTeam.serializedRecords;
    const filtered = serializedRecords.filter(
      i => parseInt(i.inning, 10) === inning,
    );
    const keys = {};
    for (let i = 0; i < filtered.length; i++) {
      const item = filtered[i];
      const key = `${item.inning}|${item.battingOrder}`;
      if (keys[key]) {
        keys[key]++;
        filtered[i].batterIndex = keys[key];
      } else {
        keys[key] = 0;
        filtered[i].batterIndex = 0;
      }
    }
    return filtered;
  }, [activeInning, game]);

  const getBatterTimes = useMemo(() => {
    if (!game?.batterTime) {
      return [];
    }
    const splited = activeInning.split('_');
    const batterTime = !game?.batterTime
      ? []
      : splited[1] === 'f'
      ? JSON.parse(game?.batterTime).batFirstTeam
      : JSON.parse(game?.batterTime).batLastTeam;
    return batterTime;
  }, [activeInning, game]);

  const lineUps = useMemo(() => {
    if (!activeInning || activeInning.split('_').length === 0) {
      return [];
    }
    const splited = activeInning.split('_');
    return splited[1] === 'f' ? firstTeamBatterLineUps : lastTeamBatterLineUps;
  }, [activeInning, firstTeamBatterLineUps, lastTeamBatterLineUps]);

  const getLineUp = battingOrder => {
    if (lineUps == null) {
      return null;
    }
    for (let i = 0; i < lineUps.length; i++) {
      const lineUp = lineUps[i];
      if (
        !!lineUp.battingSubOrder &&
        battingOrder === `${lineUp.battingOrder}_${lineUp.battingSubOrder}`
      ) {
        return lineUp;
      } else if (
        !!lineUp.battingOrder &&
        battingOrder === `${lineUp.battingOrder}`
      ) {
        return lineUp;
      }
    }
    return null;
  };

  const getMainBattingOrder = battingOrder => {
    if (!battingOrder) {
      return null;
    }
    if (battingOrder.indexOf('_') > -1) {
      return battingOrder.split('_')[0];
    }
    return battingOrder;
  };

  const getData = token => {
    if (loading.current || !game?.id) {
      return;
    }
    loading.current = true;
    setProgressShown(true);
    api
      .get(`/lineups?gameId=${itemId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(function (response) {
        const lineUps = response.data;
        let localBatFirstTeamBatterLineUps = [];
        let localBatLastTeamBatterLineUps = [];
        for (let i = 0; i < lineUps.length; i++) {
          const item = lineUps[i];
          if (item.team.id === game.batFirstTeamId) {
            if (item.battingOrder) {
              localBatFirstTeamBatterLineUps.push(item);
            } else if (item.pitchingOrder) {
              // do nothing
            }
          } else if (item.team.id === game.batLastTeamId) {
            if (item.battingOrder) {
              localBatLastTeamBatterLineUps.push(item);
            } else if (item.pitchingOrder) {
              // do nothing
            }
          }

          if ('PERSONAL' === viewMode && user?.id === item?.member?.user?.id) {
            if (item.team.id === game.batFirstTeamId) {
              setActiveInning('1_f');
            } else if (item.team.id === game.batLastTeamId) {
              setActiveInning('1_l');
            }
          }
        }
        localBatFirstTeamBatterLineUps.sort((i, j) => {
          return i.battingOrder !== j.battingOrder
            ? parseInt(i.battingOrder, 10) > parseInt(j.battingOrder, 10)
              ? 1
              : -1
            : i.battingSubOrder > j.battingSubOrder
            ? 1
            : -1;
        });
        localBatLastTeamBatterLineUps.sort((i, j) => {
          return i.battingOrder !== j.battingOrder
            ? parseInt(i.battingOrder, 10) > parseInt(j.battingOrder, 10)
              ? 1
              : -1
            : i.battingSubOrder > j.battingSubOrder
            ? 1
            : -1;
        });
        setFirstTeamBatterLineUps(localBatFirstTeamBatterLineUps);
        setLastTeamBatterLineUps(localBatLastTeamBatterLineUps);
        if ('PERSONAL' !== viewMode) {
          setActiveInning('1_f');
        }
      })
      .catch(function (error) {
        console.log(error);
      })
      .finally(function () {
        setRefreshing(false);
        setProgressShown(false);
        loading.current = false;
      });
  };

  const toSeconds = hhmmss => {
    console.log('hhmmss', hhmmss);
    return (
      parseInt(hhmmss.split(':')[2], 10) +
      parseInt(hhmmss.split(':')[1], 10) * 60 +
      parseInt(hhmmss.split(':')[0], 10) * 60 * 60
    );
  };

  const currentTimeRef = useRef(time);

  const openModalMode = (
    userName,
    index,
    inning,
    battingOrder,
    batterIndex,
  ) => {
    setSelectedUserName(userName);
    setShareModeOpened(true);

    // youtubeRef.current.seekTo(time);
    const batterTimes = getBatterTimes;
    // console.log('batterTimes', batterTimes);
    const filtered = batterTimes.filter(
      i => i.inning == inning && i.battingOrder == battingOrder,
    );
    if (filtered && filtered.length > 0 && filtered.length > batterIndex) {
      if (Platform.OS === 'web') {
        document
          .getElementsByTagName('iframe')[0]
          .contentWindow.player.seekTo(
            toSeconds(filtered[batterIndex].batterTime),
          );
      } else {
        youtubeRef.current.seekTo(toSeconds(filtered[batterIndex].batterTime));
      }
      // player.seekTo(toSeconds(filtered[batterIndex].batterTime), true);
      // } else {
      // youtubeRef.current.seekTo(toSeconds(filtered[batterIndex].batterTime));
      // }
      console.log('seekTo', toSeconds(filtered[batterIndex].batterTime));
      currentTimeRef.current = toSeconds(filtered[batterIndex].batterTime);
    } else {
    }
  };

  const goToTime = (inning, index, battingOrder, batterIndex) => {
    // youtubeRef.current.seekTo(time);
    const batterTimes = getBatterTimes;
    const filtered = batterTimes.filter(
      i => i.inning == inning && i.battingOrder == battingOrder,
    );
    console.log(inning, index, battingOrder, batterIndex, filtered);
    if (filtered && filtered.length > 0 && filtered.length > batterIndex) {
      if (Platform.OS === 'web') {
        player.seekTo(toSeconds(filtered[batterIndex].batterTime), true);
      } else {
        youtubeRef.current.seekTo(toSeconds(filtered[batterIndex].batterTime));
      }
      currentTimeRef.current = toSeconds(filtered[batterIndex].batterTime);
    } else {
    }
  };

  let scrollOffsetY = useRef(new Animated.Value(0)).current;

  const pageTitle = useMemo(() => {
    switch (viewMode) {
      default:
      case 'FULL':
        return '풀영상';
      case 'HOTCLIP':
        return '핫클립';
      case 'PERSONAL':
        return '개인별 영상';
    }
  }, [viewMode]);

  const myLineUp = useMemo(() => {
    if (viewMode !== 'PERSONAL') {
      return null;
    }
    if (firstTeamBatterLineUps == null || lastTeamBatterLineUps == null) {
      return null;
    }
    for (let i = 0; i < firstTeamBatterLineUps.length; i++) {
      const lineUp = firstTeamBatterLineUps[i];
      if (lineUp?.member?.user?.id === userInfo?.id) {
        return lineUp;
      }
    }
    for (let i = 0; i < lastTeamBatterLineUps.length; i++) {
      const lineUp = lastTeamBatterLineUps[i];
      if (lineUp?.member?.user?.id === userInfo?.id) {
        return lineUp;
      }
    }
    return null;
  }, [userInfo, firstTeamBatterLineUps, lastTeamBatterLineUps, viewMode]);

  const IsBatFirstTeamMe = useMemo(() => {
    if (viewMode !== 'PERSONAL') {
      return {};
    }
    if (firstTeamBatterLineUps == null || lastTeamBatterLineUps == null) {
      return {};
    }
    if (myLineUp == null) {
      return {};
    }
    let batFirstTeam = false;
    for (let i = 0; i < firstTeamBatterLineUps.length; i++) {
      const lineUp = firstTeamBatterLineUps[i];
      if (lineUp?.member?.user?.id === userInfo?.id) {
        batFirstTeam = true;
      }
    }
    for (let i = 0; i < lastTeamBatterLineUps.length; i++) {
      const lineUp = lastTeamBatterLineUps[i];
      if (lineUp?.member?.user?.id === userInfo?.id) {
        batFirstTeam = false;
      }
    }
    return batFirstTeam;
  }, [
    viewMode,
    firstTeamBatterLineUps,
    lastTeamBatterLineUps,
    myLineUp,
    userInfo,
  ]);

  const myRecord = useMemo(() => {
    if (viewMode !== 'PERSONAL') {
      return {};
    }
    if (firstTeamBatterLineUps == null || lastTeamBatterLineUps == null) {
      return {};
    }
    if (myLineUp == null) {
      return {};
    }
    let batFirstTeam = IsBatFirstTeamMe;
    const battingRecords = batFirstTeam
      ? JSON.parse(game?.records)?.batFirstTeam?.battingRecords
      : JSON.parse(game?.records)?.batLastTeam?.battingRecords;
    const battingOrder = myLineUp.battingSubOrder
      ? `${myLineUp.battingOrder}_${myLineUp.battingSubOrder}`
      : `${myLineUp.battingOrder}`;

    const records = battingRecords[battingOrder];
    const batterTimes = !game?.batterTime
      ? []
      : batFirstTeam
      ? JSON.parse(game?.batterTime).batFirstTeam.batterTime
      : JSON.parse(game?.batterTime).batLastTeam.batterTime;

    const filtered = batterTimes?.filter(i => i.battingOrder == battingOrder);
    for (let i = 0; i < records.length; i++) {
      if (filtered && filtered.length > i) {
        records[i].batterIndex = filtered[i].batterIndex;
      }
    }
    return records;
  }, [
    game,
    viewMode,
    myLineUp,
    firstTeamBatterLineUps,
    lastTeamBatterLineUps,
    IsBatFirstTeamMe,
  ]);

  const shareVideo = () => {
    Clipboard.setString(
      `https://service-api.unique-play.com/videos/${youtubeVideoId}/${itemId}?time=${currentTimeRef.current}&cam=${activeCamNumber}`,
    );
    alert('공유 링크가 복사되었습니다!');
  };

  const listCount = useMemo(() => {
    if (viewMode === 'FULL') {
      return getSerializedRecords?.length;
    } else if (viewMode === 'PERSONAL') {
      let totalCount = 0;
      for (let i = 0; i < Object.keys(myRecord).length; i++) {
        const key = myRecord[i];
        if (!Number.isNaN(key) && Array.isArray(myRecord[key])) {
          totalCount += myRecord[key].length;
        }
      }
      return totalCount;
    }
    return 0;
  }, [viewMode, myRecord, getSerializedRecords]);

  const fullWidth = useMemo(() => {
    if (Platform.OS === 'web') {
      const windowWidth = Dimensions.get('window').width;
      if (windowWidth < 550) {
        return windowWidth;
      } else {
        return 550;
      }
    }
    return '100%';
  }, []);

  const pageStyle = StyleSheet.create({
    container: {
      backgroundColor: 'white',
    },
    header: {width: '100%'},
    scrollViewContainer: {},
    gameDate: {
      fontFamily: 'Pretendard-Medium',
      fontWeight: '500',
      fontSize: 14,
      color: 'black',
    },
    leagueName: {
      marginTop: 3,
      fontFamily: 'Pretendard',
      fontSize: 14,
      color: 'black',
    },
    toggleArea: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      paddingHorizontal: 30,
      paddingVertical: 10,
      borderBottomColor: '#ccc',
      borderBottomWidth: 0.4,
    },
    toggleLeftArea: {
      flexDirection: 'row',
      alignItems: 'center',
    },
    switchIcn: {
      width: 28,
      height: (28 * 48) / 39,
    },
    toggleCamBtn: {
      width: 70,
      height: 28,
      borderRadius: 14,
      justifyContent: 'center',
      marginStart: 12,
      backgroundColor: 'rgb(116, 135, 209)',
    },
    toggleCamActiveBtn: {
      backgroundColor: 'rgb(67, 80, 175)',
    },
    camTxt: {
      textAlign: 'center',
      fontFamily: 'Pretendard',
      fontSize: 16,
      color: 'white',
    },
    fullClipBtn: {
      width: 80,
      height: 28,
      borderRadius: 14,
      justifyContent: 'center',
      backgroundColor: Colors.main,
    },
    hotClipBtn: {
      width: 80,
      height: 28,
      borderRadius: 14,
      justifyContent: 'center',
      backgroundColor: 'rgb(162, 32, 171)',
    },
    scoreContainer: {
      borderBottomColor: '#ccc',
      borderBottomWidth: 0.4,
      paddingVertical: 15,
    },
    scoreRowContainer: {
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
    },
    teamName: {
      color: '#606060',
      fontSize: 15,
      fontWeight: '500',
      fontFamily: 'Pretendard-Medium',
      marginHorizontal: 7,
      flex: 1,
    },
    logoContainer: {
      width: 40,
      height: 40,
      borderRadius: 20,
      overflow: 'hidden',
      backgroundColor: 'white',
    },
    logoImg: {
      width: 40,
      height: 40,
    },
    scoreTxt: {
      fontSize: 18,
      color: '#939393',
      marginHorizontal: 8,
      fontFamily: 'Pretendard-Bold',
      fontWeight: 'bold',
    },
    scoreWin: {
      color: '#f80302',
    },
    scoreDivider: {
      color: '#606060',
      fontFamily: 'Pretendard',
      fontSize: 13,
    },
    scoreTableList: {
      marginTop: 12,
      width: '100%',
      // width: ((Dimensions.get('window').width - 30) / 7) * 7,
      flexGrow: 1,
    },
    scoreTableTh: {
      backgroundColor: 'rgb(2, 8, 82)',
      color: 'white',
      height: 22,
    },
    scoreTableThInactive: {
      color: '#ccc',
    },
    scoreTableThTxt: {
      color: 'white',
      fontFamily: 'Pretendard',
      fontSize: 10,
      textAlign: 'center',
    },
    tdContainer: {
      width:
        ((Platform.OS === 'web' ? fullWidth : Dimensions.get('window').width) -
          30) /
        7,
    },
    tdInning: {
      fontFamily: 'Pretendard-SemiBold',
      fontWeight: '600',
      textAlign: 'center',
      color: '#18253b',
      fontSize: 11,
      height: 22,
      lineHeight: 22,
    },
    tdFirstContainer: {
      marginStart: 20,
    },
    scoreTd: {
      width: '100%',
      height: 25,
      borderEndColor: '#ccc',
      borderEndWidth: 1,
      borderBottomColor: '#ccc',
      borderBottomWidth: 1,
    },
    td: {
      color: '#a5a5a5',
      fontFamily: 'Pretendard-Medium',
      fontSize: 15,
      fontWeight: '500',
      lineHeight: 25,
      textAlign: 'center',
      alignContent: 'center',
      alignItems: 'center',
    },
    tdActive: {
      color: 'white',
    },
    recordTopArea: {
      flexDirection: 'row',
      alignItems: 'center',
    },
    battingOrder: {
      fontSize: 13,
      color: '#c93232',
      fontWeight: '500',
      fontFamily: 'Pretendard-Medium',
      marginEnd: 5,
    },
    txtM: {
      fontFamily: 'Pretendard',
      fontSize: 15,
    },
    position: {
      fontFamily: 'Pretendard',
      fontSize: 13,
      color: '#606060',
      marginEnd: 5,
    },
    summary: {
      marginStart: 4,
      fontFamily: 'Pretendard',
      fontSize: 13,
      color: '#606060',
    },
    username: {
      fontFamily: 'Pretendard-Bold',
      fontSize: 13,
      color: '#606060',
      fontWeight: 'bold',
    },
    backNumber: {
      fontFamily: 'Pretendard',
      fontSize: 11,
      color: '#606060',
    },
    batterItem: {
      flexDirection: 'row',
      height: 87,
      marginHorizontal: 20,
      borderBottomColor: '#ddd',
      borderBottomWidth: 1,
      paddingVertical: 18,
      justifyContent: 'space-between',
    },
    batterLeftArea: {
      flexDirection: 'row',
    },
    profileImg: {
      width: 50,
      height: 50,
      borderRadius: 25,
      marginEnd: 12,
    },
    profileTxtArea: {
      justifyContent: 'center',
    },
    recordNames: {
      fontFamily: 'Pretendard-Bold',
      fontSize: 16,
      color: '#606060',
      marginTop: 5,
      fontWeight: 'bold',
    },
    rightArea: {
      justifyContent: 'center',
    },
    btnContainer: {
      backgroundColor: '#ccc',
      width: 90,
      borderRadius: 12,
      textAlign: 'center',
      justifyContent: 'center',
      alignItems: 'center',
      alignSelf: 'center',
      paddingVertical: 3,
    },
    btnContainerActive: {
      backgroundColor: Colors.main,
    },
    btnTxt: {
      color: 'white',
      textAlign: 'center',
      fontFamily: 'Pretendard',
      fontSize: 15,
      alignSelf: 'center',
    },
    shareBg: {
      backgroundColor: 'rgb(227, 234, 253)',
      paddingVertical: 15,
      paddingHorizontal: 20,
      alignItems: 'center',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    shareTxt: {
      color: '#001156',
      fontFamily: 'Pretendard',
      fontSize: 13,
    },
    shareStrongTxt: {
      color: '#001156',
      fontFamily: 'Pretendard-Bold',
      fontSize: 13,
      fontWeight: 'bold',
    },
    shareBtnIcn: {
      height: 25,
      width: (163 * 25) / 50,
    },
  });

  return (
    <>
      <SafeAreaView
        nativeID="common-page-container"
        style={[styles.container, pageStyle.container]}>
        <DynamicHeader
          header={
            <Shadow
              offset={[0, 2]}
              style={[{width: '100%'}]}
              containerStyle={[{marginTop: 0}]}
              distance={7}
              sides={{bottom: true, top: false, start: false, end: false}}>
              <View style={[styles.headerCommon]}>
                <View style={[styles.row, styles.verticalAlign]}>
                  <TouchableOpacity
                    onPress={() =>
                      Platform.OS === 'web'
                        ? history.back()
                        : navigation.goBack()
                    }>
                    <Image
                      source={require('../assets/images/icon_back.png')}
                      style={[styles.headerBackButton]}
                    />
                  </TouchableOpacity>
                  <Text style={[styles.headerText]}>{pageTitle}</Text>
                </View>
                <View
                  style={[
                    styles.verticalAlign,
                    {alignContent: 'flex-end', alignItems: 'flex-end'},
                  ]}>
                  <Text style={[pageStyle.gameDate]}>
                    {moment(game?.date, 'YYYY-MM-DD').format('YY.MM.DD')} /{' '}
                    {moment(game?.time, 'HH:mm:ss').format('HH:mm')}
                  </Text>
                  <Text style={[pageStyle.leagueName]}>{game?.leagueName}</Text>
                </View>
              </View>
            </Shadow>
          }
          game={game}
          animHeaderValue={scrollOffsetY}
          navigation={navigation}>
          <YoutubePlayer
            apiKey="AIzaSyCWW2izAo6FdSIozEmuZUMzFeHB4SftJuM"
            showinfo={false}
            controls={1}
            ref={youtubeRef}
            videoId={youtubeVideoId} // The YouTube video ID
            play // control playback of video with true/false
            fullscreen // control whether the video should play in fullscreen or inline
            onChangeState={e => setVideoState(e)}
            onReady={() => console.log('onready')}
            // onChangeQuality={e => this.setState({quality: e.quality})}
            // onError={e => this.setState({error: e.error})}
            height={
              Platform.OS === 'ios'
                ? 220
                : Platform.OS === 'android'
                ? 240
                : 310
            }
            width={
              Platform.OS === 'web' ? fullWidth : Dimensions.get('window').width
            }
            style={{
              alignSelf: 'stretch',
              height:
                Platform.OS === 'ios'
                  ? 220
                  : Platform.OS === 'android'
                  ? 240
                  : 310,
            }}
            onFullScreenChange={isFullscreen => {
              console.log('isFullscreen', isFullscreen);
              if (isFullscreen) {
                ScreenOrientation.lockAsync(
                  ScreenOrientation.OrientationLock.LANDSCAPE,
                );
              } else {
                ScreenOrientation.lockAsync(
                  ScreenOrientation.OrientationLock.PORTRAIT,
                );
              }
            }}
          />
          {/* {controlVisible && (
              <>
                <TouchableOpacity
                  style={[{position: 'absolute', top: 100, start: 80}]}
                  onPress={backward5Seconds}>
                  <Image
                    resizeMode="cover"
                    style={[{width: 30, height: 30}]}
                    source={require('../assets/images/backward-5-seconds.png')}
                  />
                </TouchableOpacity>
                <TouchableOpacity
                  style={[{position: 'absolute', top: 100, right: 80}]}
                  onPress={forward5Seconds}>
                  <Image
                    resizeMode="cover"
                    style={[{width: 30, height: 30}]}
                    source={require('../assets/images/forward-5-seconds.png')}
                  />
                </TouchableOpacity>
              </>
            )} */}
        </DynamicHeader>
        <ScrollView
          scrollEventThrottle={30}
          onScroll={
            ({nativeEvent}) => {
              // console.log(
              //   Dimensions.get('window').height -
              //     220 -
              //     67 -
              //     210 -
              //     insets.top -
              //     insets.bottom,
              //   87 * listCount,
              // );
              if (
                Dimensions.get('window').height -
                  (Platform.OS === 'ios' ? 220 : 240) -
                  67 -
                  210 -
                  insets.top -
                  insets.bottom >
                87 * listCount
              ) {
                return;
              }
              scrollOffsetY.setValue(nativeEvent.contentOffset.y);
            }
            // Animated.event(
            //   [event],
            //   {useNativeDriver: false},
            // )
          }>
          <View>
            {viewMode === 'FULL' && shareModeOpened && (
              <View style={[pageStyle.shareBg]}>
                <Text style={[pageStyle.shareTxt]}>
                  <Text style={[pageStyle.shareStrongTxt]}>
                    {selectedUserName}
                  </Text>{' '}
                  선수의 타석 영상을 공유해 보세요
                </Text>
                <TouchableOpacity onPress={() => shareVideo()}>
                  <Image
                    style={[pageStyle.shareBtnIcn]}
                    source={require('../assets/images/share_btn.png')}
                  />
                </TouchableOpacity>
              </View>
            )}
            {viewMode === 'HOTCLIP' && (
              <View style={[pageStyle.shareBg]}>
                <Text style={[pageStyle.shareTxt]}>
                  <Text style={[pageStyle.shareStrongTxt]}>핫클립</Text> 영상을
                  공유해 보세요
                </Text>
                <TouchableOpacity onPress={() => shareVideo()}>
                  <Image
                    style={[pageStyle.shareBtnIcn]}
                    source={require('../assets/images/share_btn.png')}
                  />
                </TouchableOpacity>
              </View>
            )}
            <View style={[pageStyle.toggleArea]}>
              <View style={[pageStyle.toggleLeftArea]}>
                <Image
                  resizeMode="contain"
                  source={require('../assets/images/switch_camera.png')}
                  style={[pageStyle.switchIcn]}
                />
                <TouchableOpacity
                  disabled={viewMode === 'HOTCLIP'}
                  style={[
                    pageStyle.toggleCamBtn,
                    activeCamNumber === 1 && pageStyle.toggleCamActiveBtn,
                    viewMode === 'HOTCLIP' && {backgroundColor: '#ccc'},
                  ]}
                  onPress={() => toggleCam(1)}>
                  <Text style={[pageStyle.camTxt]}>1 CAM</Text>
                </TouchableOpacity>
                <TouchableOpacity
                  disabled={
                    viewMode === 'HOTCLIP' ||
                    (!game?.batterVideoUrl && !game?.batteryVideoUrl)
                  }
                  style={[
                    pageStyle.toggleCamBtn,
                    activeCamNumber === 2 && pageStyle.toggleCamActiveBtn,
                    (viewMode === 'HOTCLIP' ||
                      (!game?.batterVideoUrl && !game?.batteryVideoUrl)) && {
                      backgroundColor: '#ccc',
                    },
                  ]}
                  onPress={() => toggleCam(2)}>
                  <Text style={[pageStyle.camTxt]}>2 CAM</Text>
                </TouchableOpacity>
              </View>
              <View>
                {viewMode === 'FULL' && (
                  <TouchableOpacity
                    disabled={!game?.hotClipVideoUrl}
                    onPress={() => setViewMode('HOTCLIP')}
                    style={[
                      pageStyle.hotClipBtn,
                      !game?.hotClipVideoUrl && {backgroundColor: '#ccc'},
                    ]}>
                    <Text style={[pageStyle.camTxt]}>핫클립</Text>
                  </TouchableOpacity>
                )}
                {viewMode !== 'FULL' && (
                  <TouchableOpacity
                    disabled={!game?.fullVideoUrl}
                    onPress={() => setViewMode('FULL')}
                    style={[
                      pageStyle.fullClipBtn,
                      !game?.fullVideoUrl && {backgroundColor: '#ccc'},
                    ]}>
                    <Text style={[pageStyle.camTxt]}>풀영상</Text>
                  </TouchableOpacity>
                )}
              </View>
            </View>
            <View style={[pageStyle.scoreContainer]}>
              <TouchableOpacity
                onPress={() =>
                  navigation.navigate('BoxScore', {
                    data: game,
                    itemId: game?.id,
                  })
                }
                style={[pageStyle.scoreRowContainer]}>
                <View
                  style={[
                    {
                      flex: 1,
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'flex-end',
                    },
                  ]}>
                  <Text
                    numberOfLines={1}
                    style={[pageStyle.teamName, {textAlign: 'right'}]}>
                    {game?.batFirstTeamName}
                  </Text>
                  <TouchableOpacity
                    disabled={true}
                    onPress={() =>
                      navigation.navigate('TeamDetail', {
                        itemId: game?.batFirstTeamId,
                      })
                    }>
                    <Shadow offset={[3, 4]} distance={4}>
                      <View style={[pageStyle.logoContainer]}>
                        <Image
                          style={[pageStyle.logoImg]}
                          source={
                            !game?.batFirstTeamLogoImageUrl
                              ? require('../assets/images/nologo.png')
                              : {uri: game?.batFirstTeamLogoImageUrl}
                          }
                          resizeMode={'cover'}
                        />
                      </View>
                    </Shadow>
                  </TouchableOpacity>
                  <Text
                    style={[
                      pageStyle.scoreTxt,
                      getFirstTeamScore(game) >= getLastTeamScore(game) &&
                        pageStyle.scoreWin,
                    ]}>
                    {getFirstTeamScore(game)}
                  </Text>
                </View>
                <Text style={[pageStyle.scoreDivider]}>:</Text>
                <View
                  style={[
                    {flex: 1, flexDirection: 'row', alignItems: 'center'},
                  ]}>
                  <Text
                    style={[
                      pageStyle.scoreTxt,
                      getFirstTeamScore(game) <= getLastTeamScore(game) &&
                        pageStyle.scoreWin,
                    ]}>
                    {getLastTeamScore(game)}
                  </Text>
                  <TouchableOpacity
                    disabled={true}
                    onPress={() =>
                      navigation.navigate('TeamDetail', {
                        itemId: game?.batLastTeamId,
                      })
                    }>
                    <Shadow offset={[3, 4]} distance={4}>
                      <View style={[pageStyle.logoContainer]}>
                        <Image
                          style={[pageStyle.logoImg]}
                          source={
                            !game?.batLastTeamLogoImageUrl
                              ? require('../assets/images/nologo.png')
                              : {uri: game?.batLastTeamLogoImageUrl}
                          }
                          resizeMode={'cover'}
                        />
                      </View>
                    </Shadow>
                  </TouchableOpacity>
                  <Text numberOfLines={1} style={[pageStyle.teamName]}>
                    {game?.batLastTeamName}
                  </Text>
                </View>
              </TouchableOpacity>
              <ScrollView
                horizontal={true}
                showsHorizontalScrollIndicator={Platform.OS === 'web'}
                style={[pageStyle.scoreTableList]}>
                {game &&
                  game.records &&
                  JSON.parse(game?.records)?.batFirstTeam?.inningScore.map(
                    (item, index) => {
                      return (
                        <View
                          style={[
                            index === 0 && pageStyle.tdFirstContainer,
                            pageStyle.tdContainer,
                            pageStyle.tdInnerContainer,
                          ]}>
                          <Text
                            numberOfLines={1}
                            style={[
                              pageStyle.tdInning,
                              pageStyle.scoreTableTh,
                            ]}>
                            {index + 1}회
                          </Text>
                          <TouchableOpacity
                            disabled={viewMode !== 'FULL'}
                            onPress={() => setActiveInning(`${index + 1}_f`)}>
                            <ImageBackground
                              resizeMode={'cover'}
                              source={
                                viewMode === 'FULL' &&
                                activeInning === `${index + 1}_f`
                                  ? require('../assets/images/scoreTdActive.png')
                                  : require('../assets/images/scoreTd.png')
                              }
                              style={[pageStyle.scoreTd]}>
                              <Text
                                style={[
                                  pageStyle.td,
                                  viewMode === 'FULL' &&
                                    activeInning === `${index + 1}_f` &&
                                    pageStyle.tdActive,
                                ]}>
                                {item}
                              </Text>
                            </ImageBackground>
                          </TouchableOpacity>
                          <TouchableOpacity
                            disabled={viewMode !== 'FULL'}
                            onPress={() => setActiveInning(`${index + 1}_l`)}>
                            <ImageBackground
                              resizeMode={'cover'}
                              source={
                                viewMode === 'FULL' &&
                                activeInning === `${index + 1}_l`
                                  ? require('../assets/images/scoreTdActive.png')
                                  : require('../assets/images/scoreTd.png')
                              }
                              style={[pageStyle.scoreTd]}>
                              <Text
                                style={[
                                  pageStyle.td,
                                  viewMode === 'FULL' &&
                                    activeInning === `${index + 1}_l` &&
                                    pageStyle.tdActive,
                                ]}>
                                {
                                  JSON.parse(game?.records)?.batLastTeam
                                    ?.inningScore[index]
                                }
                              </Text>
                            </ImageBackground>
                          </TouchableOpacity>
                        </View>
                      );
                    },
                  )}
                {game &&
                  game.records &&
                  JSON.parse(game?.records)?.batFirstTeam?.inningScore?.length <
                    7 &&
                  new Array(
                    7 -
                      JSON.parse(game?.records)?.batFirstTeam?.inningScore
                        ?.length,
                  )
                    .fill()
                    .map((_, index) => {
                      return (
                        <View
                          style={[
                            pageStyle.tdContainer,
                            pageStyle.tdInnerContainer,
                          ]}>
                          <Text
                            numberOfLines={1}
                            style={[
                              pageStyle.tdInning,
                              pageStyle.scoreTableTh,
                              pageStyle.scoreTableThInactive,
                            ]}>
                            {JSON.parse(game?.records)?.batFirstTeam
                              ?.inningScore?.length +
                              index +
                              1}{' '}
                            회
                          </Text>
                          <ImageBackground
                            resizeMode={'cover'}
                            source={require('../assets/images/scoreTd.png')}
                            style={[pageStyle.scoreTd]}
                          />
                          <ImageBackground
                            resizeMode={'cover'}
                            source={require('../assets/images/scoreTd.png')}
                            style={[pageStyle.scoreTd]}
                          />
                        </View>
                      );
                    })}
              </ScrollView>
            </View>
            {viewMode === 'FULL' &&
              getSerializedRecords.map((item, index) => {
                return (
                  <View style={[pageStyle.batterItem]}>
                    <TouchableOpacity
                      onPress={() => {
                        setUserInfo(getLineUp(item.battingOrder)?.member?.user);
                        setViewMode('PERSONAL');
                      }}
                      style={[pageStyle.batterLeftArea]}>
                      <Image
                        resizeMode="cover"
                        source={
                          getLineUp(item.battingOrder)?.member?.user
                            ?.profileImageUrl
                            ? {
                                uri: getLineUp(item.battingOrder)?.member?.user
                                  ?.profileImageUrl,
                              }
                            : require('../assets/images/icn_profile_img.png')
                        }
                        style={[pageStyle.profileImg]}
                      />
                      <View style={[pageStyle.profileTxtArea]}>
                        <View style={[pageStyle.recordTopArea]}>
                          <Text style={[pageStyle.battingOrder]}>
                            {getMainBattingOrder(
                              getLineUp(item.battingOrder)?.battingOrder,
                            )}
                            .
                          </Text>
                          <Text style={[pageStyle.position]}>
                            {getLineUp(item.battingOrder)?.position}
                          </Text>
                          <Text style={[pageStyle.username]}>
                            {getLineUp(item.battingOrder)?.member?.user?.name}{' '}
                            <Text style={[pageStyle.backNumber]}>
                              ({getLineUp(item.battingOrder)?.member?.number})
                            </Text>
                          </Text>
                        </View>
                        <Text style={[pageStyle.recordNames]}>
                          {item.record?.name}
                        </Text>
                      </View>
                    </TouchableOpacity>
                    <View style={[pageStyle.rightArea]}>
                      <TouchableOpacity
                        onPress={() =>
                          openModalMode(
                            getLineUp(item.battingOrder)?.member?.user?.name,
                            index,
                            item.inning,
                            item.battingOrder,
                            item.batterIndex,
                          )
                        }
                        style={[
                          pageStyle.btnContainer,
                          pageStyle.btnContainerActive,
                        ]}>
                        <Text style={[pageStyle.btnTxt]}>타석영상</Text>
                      </TouchableOpacity>
                    </View>
                  </View>
                );
              })}
            {viewMode === 'PERSONAL' && (
              <View>
                <View style={[pageStyle.batterItem]}>
                  <View style={[pageStyle.batterLeftArea]}>
                    <Image
                      resizeMode="cover"
                      source={
                        myLineUp?.member?.user?.profileImageUrl
                          ? {
                              uri: myLineUp?.member?.user?.profileImageUrl,
                            }
                          : require('../assets/images/icn_profile_img.png')
                      }
                      style={[pageStyle.profileImg]}
                    />
                    <View style={[pageStyle.profileTxtArea]}>
                      <View style={[pageStyle.recordTopArea]}>
                        <Text style={[pageStyle.battingOrder, pageStyle.txtM]}>
                          {getMainBattingOrder(myLineUp?.battingOrder)}.
                        </Text>
                        <Text style={[pageStyle.position, pageStyle.txtM]}>
                          {myLineUp?.position}
                        </Text>
                        <Text style={[pageStyle.username, pageStyle.txtM]}>
                          {myLineUp?.member?.user?.name}
                          <Text style={[pageStyle.backNumber]}>
                            ({myLineUp?.member?.number})
                          </Text>
                        </Text>
                        <Text style={[pageStyle.summary, pageStyle.txtM]}>
                          / {myRecord?.tb}타수 {myRecord?.hit}안타
                        </Text>
                      </View>
                    </View>
                  </View>
                </View>
                {Object.keys(myRecord).map((item, index) => {
                  return (
                    Array.isArray(myRecord[item]) &&
                    myRecord[item]?.map((innerItem, innerIndex) => {
                      if (
                        [
                          '도루',
                          '도루자',
                          '주자아웃',
                          '견제사',
                          '런다운',
                          '포일',
                          '폭투',
                          '보크',
                          '송구실책',
                          '포구실책',
                          '주루방해',
                          '승부주자',
                          '수비방해',
                          '타구맞음',
                        ].includes(innerItem.name)
                      ) {
                        return <></>;
                      }
                      return (
                        <View
                          style={[
                            pageStyle.batterItem,
                            {paddingHorizontal: 12, paddingVertical: 10},
                          ]}>
                          <View style={[styles.row, {alignItems: 'center'}]}>
                            <Text
                              style={[
                                [
                                  {
                                    color: '#c93232',
                                    fontSize: 16,
                                    fontFamily: 'Pretendard',
                                  },
                                ],
                              ]}>
                              {item}회{IsBatFirstTeamMe ? '초' : '말'}
                            </Text>
                            <Text
                              style={[
                                [
                                  {
                                    marginStart: 8,
                                    color: '#606060',
                                    fontFamily: 'Pretendard-Bold',
                                    fontSize: 16,
                                    fontWeight: 'bold',
                                  },
                                ],
                              ]}>
                              {innerItem.name}
                            </Text>
                          </View>
                          <View style={[pageStyle.rightArea]}>
                            <TouchableOpacity
                              onPress={() =>
                                goToTime(
                                  item,
                                  index,
                                  getMainBattingOrder(myLineUp?.battingOrder),
                                  innerItem.index,
                                  myRecord[item],
                                  innerItem,
                                )
                              }
                              style={[
                                pageStyle.btnContainer,
                                pageStyle.btnContainerActive,
                              ]}>
                              <Text style={[pageStyle.btnTxt]}>타석영상</Text>
                            </TouchableOpacity>
                          </View>
                        </View>
                      );
                    })
                  );
                })}
              </View>
            )}
          </View>
        </ScrollView>
      </SafeAreaView>
    </>
  );
};

export default VideoView;

import React, {useState, useRef, useEffect} from 'react';
import {
  SafeAreaView,
  StyleSheet,
  View,
  Dimensions,
  Modal,
  Text,
  Image,
  TouchableOpacity,
  FlatList,
  DeviceEventEmitter,
  Alert,
  Platform,
} from 'react-native';
import styles from '../../common/styles';
import Colors, {red} from '../../common/colors';
import MemberItem from './MemberItem';
import AsyncStorage from '@react-native-async-storage/async-storage';
import api from '../../common/api';
import AppleStyleSwipeableRow from '../AppleStyleSwipeableRow';
import alert from '../alert';
import {KeyboardAwareScrollView} from 'react-native-keyboard-aware-scroll-view';

const ManagementMemberList = ({props, navigation, route}) => {
  const {itemId} = route.params;
  const [myGrade, setMyGrade] = useState('');
  const [teamData, setTeamData] = useState({});
  const [memberPage, setMemberPage] = useState({});
  const changedMembers = useRef([]);
  let accessToken = useRef();

  const getTeamData = token => {
    api
      .get(`/app-team/teams/${itemId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(function (response) {
        setTeamData(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
    api
      .get(`/teams/${itemId}/my-grade`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(function (response) {
        console.log('setMyGrade', response.data);
        setMyGrade(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getData = token => {
    api
      .get(`/teams/${itemId}/members?pageSize=200`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(function (response) {
        setMemberPage(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    AsyncStorage.getItem('@accessToken', (err, result) => {
      accessToken.current = result;
      getData(result);
      getTeamData(result);
    });

    const unsubscribe = DeviceEventEmitter.addListener(
      'memberListRefresh',
      function (data) {
        getData(accessToken.current);
      },
    );

    return () => {
      unsubscribe.remove();
    };
  }, []);

  const moveToUserManagement = () => {
    navigation.navigate('MemberApproveList', {
      itemId: teamData.id,
      teamData: teamData,
      myGrade: myGrade,
    });
  };

  const checkMaster = () => {
    let count = 0;
    for (let i = 0; i < memberPage.items.length; i++) {
      let item = memberPage.items[i];
      if (changedMembers.current.map(j => j.id).includes(item.id)) {
        continue;
      }
      if (item.grade === '팀마스터') {
        count++;
      }
    }
    count += changedMembers.current.filter(i => i.grade === '팀마스터').length;
    return count === 1;
  };

  const memberDataChange = value => {
    console.log(value);
    if (!changedMembers.current) {
      changedMembers.current = [];
    }
    if (!changedMembers.current.map(i => i.id).includes(value.id)) {
      changedMembers.current.push(value);
    } else {
      changedMembers.current = changedMembers.current.filter(
        i => i.id !== value.id,
      );
      changedMembers.current.push(value);
    }
    console.log('changedMembers', changedMembers.current);
  };
  const save = () => {
    if (changedMembers.current.length === 0) {
      alert('변경된 사항이 없습니다.');
      return;
    }
    if (!checkMaster()) {
      alert('팀마스터는 1명이 지정되어야 합니다.');
      return;
    }
    api
      .post('/teams/member/modifications', changedMembers.current, {
        headers: {
          Authorization: `Bearer ${accessToken.current}`,
        },
      })
      .then(function (response) {
        console.log(response.data, response.headers);
        DeviceEventEmitter.emit('memberListRefresh', {});
        alert('요청에 성공했습니다.');
      })
      .catch(function (error) {
        console.log(error.response);
        alert('요청에 실패했습니다.');
      });
  };

  const cancel = () => {
    Platform.OS === 'web' ? history.back() : navigation.goBack();
  };

  const handleDeleteItem = (id, grade) => {
    if (myGrade !== '팀마스터') {
      alert('회원 삭제는 팀마스터만 가능합니다.');
      return;
    }
    if (grade !== '선수') {
      alert(
        '선수이외의 등급의 회원님은 회원 등급을 "선수" 등급으로 변경해야 탈퇴 가능하며, 팀마스터 등급의 회원님은 팀마스터를 다른 분에게 양도한 후 탈퇴가 가능합니다.',
      );
      return;
    }
    if (memberPage.totalCount === 1) {
      alert(
        '회원이 0명 될 수 없습니다. 마지막 회원 탈퇴 전 팀을 삭제해 주세요.',
      );
      return;
    }
    alert('회원 삭제', '회원을 삭제 하시겠습니까?', [
      {
        text: '예',
        onPress: () => {
          api
            .delete(`/teams/${teamData?.id}/members/${id}`, {
              headers: {
                Authorization: `Bearer ${accessToken.current}`,
              },
            })
            .then(function (response) {
              setMemberPage(response.data);
              getData(accessToken.current);
            })
            .catch(function (error) {
              console.log(error);
            });
        },
      },
      {
        text: '아니오',
      },
    ]);
  };
  return (
    <SafeAreaView nativeID="common-page-container" style={[pageStyle.safeArea]}>
      <View style={[pageStyle.container]}>
        <View style={[pageStyle.innerContainer]}>
          <View style={[styles.headerCommon, pageStyle.header]}>
            <View style={[styles.row, styles.verticalAlign, pageStyle.back]}>
              <TouchableOpacity
                onPress={() =>
                  Platform.OS === 'web' ? history.back() : navigation.goBack()
                }>
                <Image
                  source={require('../../assets/images/icon_back.png')}
                  style={[styles.headerBackButton]}
                />
              </TouchableOpacity>
            </View>
            <Text style={[pageStyle.title]}>회원 관리</Text>
            <TouchableOpacity
              style={[pageStyle.createTeamBtn]}
              onPress={() => moveToUserManagement(true)}>
              <Text style={[pageStyle.createTeamBtnTxt]}>가입승인</Text>
            </TouchableOpacity>
          </View>
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'flex-end',
              paddingEnd: 12,
            }}>
            <Text
              style={[{fontSize: 14, color: '#9C303C', fontStyle: 'italic'}]}>
              당겨서 탈퇴하기 &lt;-
            </Text>
          </View>
          <View style={[pageStyle.contentsContainer]}>
            <KeyboardAwareScrollView
              enableOnAndroid={true}
              enableAutomaticScroll={Platform.OS === 'ios'}
              keyboardVerticalOffset={Platform.OS === 'ios' ? 40 : -10}
              behavior={Platform.OS === 'ios' ? 'padding' : undefined}>
              <FlatList
                ListHeaderComponent={
                  <View>
                    <Text style={[pageStyle.summary]}>
                      총 {memberPage.totalCount}명
                    </Text>
                    <View style={[styles.row, pageStyle.tableHeader]}>
                      <Text style={[styles.col, pageStyle.th]}>이름</Text>
                      <Text style={[styles.col, pageStyle.th]}>성별</Text>
                      <Text style={[styles.col, pageStyle.th]}>나이</Text>
                      <Text style={[styles.col2, pageStyle.th]}>등급</Text>
                      <Text style={[styles.col, pageStyle.th]}>배번</Text>
                      <Text style={[styles.col, pageStyle.th]}>연락처</Text>
                    </View>
                  </View>
                }
                ListFooterComponent={
                  <View>
                    {/* <PagingComponent style={[{marginTop: 13}]} /> */}
                    <View
                      style={[
                        styles.row,
                        {marginHorizontal: 22, marginBottom: 120},
                      ]}>
                      <TouchableOpacity
                        onPress={() => save()}
                        style={[styles.col, pageStyle.saveBtn]}>
                        <Text style={[pageStyle.btnText]}>저장</Text>
                      </TouchableOpacity>
                      <TouchableOpacity
                        onPress={cancel}
                        style={[styles.col, pageStyle.cancelBtn]}>
                        <Text
                          style={[pageStyle.btnText, pageStyle.cancelBtnTxt]}>
                          취소
                        </Text>
                      </TouchableOpacity>
                    </View>
                  </View>
                }
                style={[pageStyle.list]}
                keyExtractor={item => `member_${item.id.toString()}`}
                data={memberPage.items}
                ListEmptyComponent={() => (
                  <View style={[styles.emptyContainer]}>
                    <Image source={require('../../assets/images/logo2.png')} />
                  </View>
                )}
                renderItem={({item}) => (
                  <AppleStyleSwipeableRow
                    onDelete={() => handleDeleteItem(item.id, item.grade)}>
                    <MemberItem
                      data={item}
                      myGrade={myGrade}
                      memberDataChange={memberDataChange}
                    />
                  </AppleStyleSwipeableRow>
                )}
              />
            </KeyboardAwareScrollView>
          </View>
        </View>
      </View>
    </SafeAreaView>
  );
};

export default ManagementMemberList;

const pageStyle = StyleSheet.create({
  safeArea: {
    backgroundColor: 'white',
    flex: 1,
  },
  container: {
    zIndex: 100,
    width: Platform.OS === 'web' ? 500 : '100%',
    flex: 1,
  },
  innerContainer: {
    width: Platform.OS === 'web' ? 500 : '100%',
    flex: 1,
  },
  summary: {
    marginTop: 30,
    paddingStart: 20,
    color: 'black',
    fontSize: 12,
    fontWeight: '600',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  back: {
    zIndex: 2,
  },
  tableHeader: {
    marginTop: 13,
    borderBottomWidth: 1,
    borderBottomColor: 'rgba(0, 0, 0, 0.15)',
    paddingBottom: 10,
  },
  th: {
    textAlign: 'center',
    fontSize: 10,
    fontWeight: '300',
    color: 'black',
  },
  title: {
    color: Colors.main,
    fontSize: 24,
    fontWeight: '600',
    position: 'absolute',
    start: 0,
    textAlign: 'center',
    width: Platform.OS === 'web' ? 500 : '100%',
  },
  imageInfoTxt: {
    color: '#ff0000',
    fontSize: 10,
    fontWeight: '500',
    marginTop: 5,
  },
  list: {
    paddingHorizontal: 22,
  },
  createTeamBtn: {
    width: 60,
    height: 22,
    borderRadius: 12,
    backgroundColor: Colors.main,
    justifyContent: 'center',
    alignItems: 'center',
    marginEnd: 14,
  },
  createTeamBtnTxt: {
    color: 'white',
    fontSize: 11,
  },
  contentsContainer: {},
  saveBtn: {
    flex: 1,
    height: 34,
    backgroundColor: Colors.main,
    borderRadius: 17,
    alignItems: 'center',
    alignContent: 'center',
    justifyContent: 'center',
    marginTop: 65,
    marginEnd: 8,
  },
  cancelBtn: {
    flex: 1,
    height: 34,
    backgroundColor: '#ccc',
    borderRadius: 17,
    alignItems: 'center',
    alignContent: 'center',
    justifyContent: 'center',
    marginTop: 65,
    marginStart: 8,
  },
  cancelBtnTxt: {
    color: '#555',
  },
  btnText: {
    color: 'white',
    fontSize: 18,
  },
  newTeamIcn: {
    width: 162,
    height: 162,
  },
  teamCreatedTxt: {
    color: '#3f51b5',
    fontSize: 21,
    fontWeight: '600',
    marginTop: 28,
  },
  moveBtn: {
    marginTop: 57,
    backgroundColor: '#3f51b5',
    paddingVertical: 8,
    paddingHorizontal: 24,
    borderRadius: 17,
  },
  moveBtnTxt: {
    color: 'white',
    fontSize: 18,
  },
});

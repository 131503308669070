import React, {useEffect, useState, useRef} from 'react';
import {
  StyleSheet,
  View,
  Dimensions,
  Modal,
  Text,
  Image,
  TouchableOpacity,
  TextInput,
  Platform,
} from 'react-native';
import styles from '../../common/styles';
import {useSafeAreaInsets} from 'react-native-safe-area-context';
import Colors from '../../common/colors';
import AsyncStorage from '@react-native-async-storage/async-storage';
import api from '../../common/api';

const TeamLeavuePopup = props => {
  const insets = useSafeAreaInsets();
  let accessToken = useRef();
  useEffect(() => {
    AsyncStorage.getItem('@accessToken', (err, result) => {
      accessToken.current = result;
    });
  }, []);

  const save = () => {
    api
      .delete(
        `/teams/${props.teamData.id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${accessToken.current}`,
          },
        },
      )
      .then(function (response) {
        alert('성공적으로 요청하였습니다.');
        props.closePopup();
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getLogoImageUrl = item => {
    if (item?.logoImageThumbUrl) {
      return {uri: item?.logoImageThumbUrl};
    }
    if (item?.logoImageUrl) {
      return {uri: item?.logoImageUrl};
    }
    return require('../../assets/images/nologo.png');
  };

  return (
    <Modal transparent={true} overFullScreen={true}>
      <View style={[pageStyle.container]}>
        <View style={[pageStyle.innerContainer, {marginTop: insets.top + 67}]}>
          <View style={[styles.headerCommon, pageStyle.header]}>
            <View style={[styles.row, styles.verticalAlign, pageStyle.back]}>
              <TouchableOpacity onPress={() => props.closePopup()}>
                <Image
                  source={require('../../assets/images/icon_back.png')}
                  style={[styles.headerBackButton]}
                />
              </TouchableOpacity>
            </View>
          </View>
          <View style={[pageStyle.contentsContainer]}>
            <View style={[pageStyle.contentsInnerContainer]}>
              <Image
                style={[
                  pageStyle.logoImg,
                  !props.teamData.logoImageUrl && {
                    opacity: 0.4,
                    borderWidth: 0,
                  },
                ]}
                source={getLogoImageUrl(props.teamData)}
                resizeMode={'cover'}
              />
              <Text style={[pageStyle.teamName]}>{props.teamData.name}</Text>
              <Text style={[pageStyle.homeTownTxt]}>
                {props.teamData.hometown?.sido} {props.teamData.hometown?.name}
              </Text>
              <Text style={[pageStyle.masterLabel]}>
                {'팀관리자'}{' '}
                <Text style={[pageStyle.masterValue]}>
                  {props.teamData.masterUser?.name}
                </Text>
              </Text>
              <View style={[styles.row]}>
                <TouchableOpacity
                  onPress={() => save()}
                  style={[pageStyle.saveBtn]}>
                  <Text style={[pageStyle.btnText]}>팀 탈퇴</Text>
                </TouchableOpacity>
              </View>
            </View>
          </View>
        </View>
      </View>
    </Modal>
  );
};

export default TeamLeavuePopup;
const pageStyle = StyleSheet.create({
  container: {
    zIndex: 100,
    width: '100%',
    height: Dimensions.get('window').height,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
  },
  innerContainer: {
    width: '100%',
    backgroundColor: 'white',
    flex: 1,
    marginTop: 67,
    width: Platform.OS === 'web' ? 500 : '100%',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  back: {
    zIndex: 2,
  },
  logoImg: {
    width: 100,
    height: 100,
    borderRadius: 50,
    backgroundColor: 'black',
  },
  title: {
    color: Colors.main,
    fontSize: 24,
    fontWeight: '600',
    position: 'absolute',
    start: 0,
    textAlign: 'center',
    width: Platform.OS === 'web' ? 500 : Dimensions.get('window').width,
  },
  imageInfoTxt: {
    color: '#ff0000',
    fontSize: 10,
    fontWeight: '500',
    marginTop: 5,
  },
  createTeamBtn: {
    width: 60,
    height: 22,
    borderRadius: 12,
    backgroundColor: Colors.main,
    justifyContent: 'center',
    alignItems: 'center',
    marginEnd: 14,
  },
  createTeamBtnTxt: {
    color: 'white',
    fontSize: 11,
  },
  contentsContainer: {
    alignItems: 'center',
    alignContent: 'center',
    flexDirection: 'column',
    display: 'flex',
    justifyContent: 'center',
    flex: 1,
  },
  contentsInnerContainer: {
    alignItems: 'center',
    marginTop: -100,
    paddingHorizontal: 51,
  },
  teamName: {
    marginTop: 13,
    color: 'black',
    fontWeight: '600',
    fontSize: 16,
  },
  registerBtnText: {
    color: 'white',
    fontSize: 10,
    marginTop: 6,
    fontWeight: '500',
  },
  homeTownTxt: {
    fontSize: 12,
    color: '#9fa0a0',
    marginTop: 9,
    fontWeight: '300',
  },
  masterLabel: {
    fontSize: 11,
    color: '#9fa0a0',
    marginTop: 9,
  },
  masterValue: {
    fontSize: 11,
    color: 'black',
    marginTop: 9,
    fontWeight: '500',
  },
  saveBtn: {
    width: 100,
    height: 35,
    borderColor: 'black',
    borderWidth: 1,
    color: 'black',
    borderRadius: 17,
    alignItems: 'center',
    alignContent: 'center',
    justifyContent: 'center',
    marginTop: 25,
    marginEnd: 8,
  },
  cancelBtn: {
    flex: 1,
    height: 34,
    backgroundColor: '#ccc',
    borderRadius: 17,
    alignItems: 'center',
    alignContent: 'center',
    justifyContent: 'center',
    marginTop: 65,
    marginStart: 8,
  },
  cancelBtnTxt: {
    color: '#555',
  },
  btnText: {
    color: 'black',
    fontSize: 18,
  },
  dropdownRowContainer: {
    display: 'flex',
    width: 222,
    marginTop: 6,
    flexDirection: 'row',
  },
  dropDownItemContainer: {
    marginHorizontal: 3,
    flex: 1,
  },
  dropDown: {
    width: '100%',
    backgroundColor: 'white',
    borderWidth: 1,
    borderColor: 'rgb(61, 80, 183)',
    height: 24,
  },
  downDownText: {
    color: 'black',
    fontSize: 11,
  },
  newTeamIcn: {
    width: 162,
    height: 162,
  },
  teamCreatedTxt: {
    color: '#3f51b5',
    fontSize: 21,
    fontWeight: '600',
    marginTop: 28,
  },
  moveBtn: {
    marginTop: 57,
    backgroundColor: '#3f51b5',
    paddingVertical: 8,
    paddingHorizontal: 24,
    borderRadius: 17,
  },
  moveBtnTxt: {
    color: 'white',
    fontSize: 18,
  },
});

import React, {useState, useEffect, useRef, useMemo} from 'react';
import {
  Image,
  SafeAreaView,
  Text,
  TouchableOpacity,
  View,
  StyleSheet,
  ImageBackground,
  Dimensions,
  useWindowDimensions,
  Platform,
  ScrollView,
  Alert,
} from 'react-native';
import {useSafeAreaInsets} from 'react-native-safe-area-context';
import styles from '../common/styles';
import {title} from '../common/util';
import AsyncStorage from '@react-native-async-storage/async-storage';
import api from '../common/api';
import * as Font from 'expo-font';
import ViewShot from 'react-native-view-shot';
import {launchImageLibrary} from 'react-native-image-picker';
import Clipboard from '@react-native-clipboard/clipboard';
import * as MediaLibrary from 'expo-media-library';

const PlayerCardDetailView = ({navigation, route}) => {
  const {height, width} = useWindowDimensions();
  const {userInfo, itemId} = route.params;
  const [item, setItem] = useState(route.params.item);
  const [progressShown, setProgressShown] = useState(false);
  const [userData, setUserData] = useState({});

  const accessToken = useRef();
  const pageLoading = useRef();

  const insets = useSafeAreaInsets();

  const [newCardImageUri, setNewCardImageUri] = useState(null);
  const [newCardImageData, setNewCardImageData] = useState(null);

  useEffect(() => {
    AsyncStorage.getItem('@accessToken', (err, result) => {
      accessToken.current = result;
      getData();
    });

    AsyncStorage.getItem('@userInfo', (err, result) => {
      setUserData(JSON.parse(result));
    });
  }, []);

  const getBackgroundImageResource = item => {
    if (item?.grade === '골드') {
      return require('../assets/images/card_gold.png');
    } else if (item?.grade === '실버') {
      return require('../assets/images/card_silver.png');
    } else if (item?.grade === '브론즈') {
      return require('../assets/images/card_bronze.png');
    }
    return require('../assets/images/card_normal.png');
  };

  const profileImageUrl = useMemo(() => {
    if (newCardImageData) {
      return {uri: newCardImageData};
    }
    if (item?.profileImageUrl) {
      return {uri: item?.profileImageUrl};
    }
    if (userInfo?.profileImageThumbUrl && userInfo?.profileImageThumbUrl) {
      return {uri: userInfo?.profileImageThumbUrl};
    }
    if (userInfo?.profileImageUrl && userInfo?.profileImageUrl) {
      return {uri: userInfo?.profileImageUrl};
    }
    return require('../assets/images/userCard.png');
  }, [item, userInfo, newCardImageData]);

  const getScore1 = item => {
    return item?.type === '투수'
      ? parseInt(item?.pitchingControl, 10)
      : parseInt(item?.battingPower, 10);
  };

  const getScore2 = item => {
    return item?.type === '투수'
      ? parseInt(item?.pitchingPower, 10)
      : parseInt(item?.battingContact, 10);
  };

  const getScore3 = item => {
    return item?.type === '투수'
      ? parseInt(item?.pitchingStrength, 10)
      : parseInt(item?.battingClutch, 10);
  };

  const getScore4 = item => {
    return item?.type === '투수'
      ? parseInt(item?.pitchingTechnique, 10)
      : parseInt(item?.baseRunning, 10);
  };

  const getScore5 = item => {
    return item?.type === '투수'
      ? parseInt(item?.pitchingManage, 10)
      : parseInt(item?.baseEye, 10);
  };

  const getScoreType1 = item => {
    return item?.type === '투수' ? '제구' : '파워';
  };

  const getScoreType2 = item => {
    return item?.type === '투수' ? '구위' : '컨택';
  };

  const getScoreType3 = item => {
    return item?.type === '투수' ? '체력' : '클러치';
  };

  const getScoreType4 = item => {
    return item?.type === '투수' ? '기교' : '주루';
  };

  const getScoreType5 = item => {
    return item?.type === '투수' ? '관리' : '선구';
  };

  const getRecordType = (index, item) => {
    const pitcherRecordTypes = [
      '다승',
      '평균자책',
      '탈삼진',
      '세이브',
      '이닝',
      'WHIP',
    ];
    const hitterRecordTypes = ['타수', '타율', '홈런', '타점', '도루', 'OPS'];
    if (item?.type === '투수') {
      return pitcherRecordTypes[index];
    } else {
      return hitterRecordTypes[index];
    }
  };

  const getRecordValue = (index, item) => {
    if (item?.type === '투수') {
      if (index === 0) {
        return item?.win;
      } else if (index === 1) {
        return item?.era;
      } else if (index === 2) {
        return item?.so;
      } else if (index === 3) {
        return item?.save;
      } else if (index === 4) {
        return item?.innings;
      } else if (index === 5) {
        return item?.whip;
      }
    } else {
      if (index === 0) {
        return item?.ab;
      } else if (index === 1) {
        return parseFloat(item?.avg).toFixed(3);
      } else if (index === 2) {
        return item?.hr;
      } else if (index === 3) {
        return item?.rbi;
      } else if (index === 4) {
        return item?.sb;
      } else if (index === 5) {
        return parseFloat(item?.ops).toFixed(3);
      }
    }
    return null;
  };

  const getData = () => {
    console.log('getData', accessToken.current, pageLoading.current);
    if (!accessToken.current) {
      return;
    }
    if (pageLoading.current) {
      return;
    }
    setProgressShown(true);
    api
      .get(`/app-user/game-cards/${itemId}`, {
        headers: {
          Authorization: `Bearer ${accessToken.current}`,
        },
      })
      .then(function (response) {
        setItem(response.data);
      })
      .catch(function (error) {
        console.error(error);
        setProgressShown(false);
      })
      .finally(() => {
        pageLoading.current = false;
      });
  };

  const postGameCardImage = profileImageUrl => {
    if (!accessToken.current) {
      return;
    }
    setProgressShown(true);
    api
      .post(
        `/app-user/game-cards/${itemId}/profile-image-url`,
        {
          profileImageUrl: profileImageUrl,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken.current}`,
          },
        },
      )
      .then(function (response) {
        DeviceEventEmitter.emit('@playerCardRefresh');
      })
      .catch(function (error) {
        console.error(error);
      })
      .finally(() => {
        setProgressShown(false);
      });
  };

  const fullWidth = useMemo(() => {
    if (Platform.OS === 'web') {
      const windowWidth = Dimensions.get('window').width;
      if (windowWidth < 550) {
        return windowWidth;
      } else {
        return 550;
      }
    }
    return '100%';
  }, []);

  const pageStyle = StyleSheet.create({
    headerCommon: {},
    navBgImage: {
      marginStart: 0,
      position: 'absolute',
      width: fullWidth,
      height: '100%',
    },
    headerText: {color: 'white'},
    container: {
      paddingBottom: 80,
      paddingHorizontal: 25,
    },
    row: {
      flexDirection: 'row',
      alignContent: 'center',
      alignItems: 'center',
      borderBottomColor: '#e6e6e6',
      borderBottomWidth: 1,
      paddingVertical: 12,
      paddingHorizontal: 36,
    },
    cardBg: {
      top: 0,
      start: 0,
      position: 'relative',
      width: '100%',
      paddingBottom: 40,
    },
    seasonYear: {
      start: 50,
      top: 3,
      color: 'white',
      fontSize: 24,
      position: 'absolute',
      fontFamily: 'Pretendard-Bold',
    },
    leagueName: {
      width: 150,
      marginTop: 6,
      lineHeight: 20,
      fontFamily: 'Pretendard-Bold',
      fontSize: 18,
      fontWeight: 'bold',
      color: 'white',
      textAlign: 'center',
      alignSelf: 'center',
    },
    subLeagueName: {
      fontFamily: 'Pretendard-Bold',
      fontSize: 14,
      fontWeight: 'bold',
      color: 'black',
      textAlign: 'center',
      marginTop: 7,
    },
    teamName: {
      color: 'black',
      fontFamily: 'Pretendard-Bold',
      fontSize: 13,
      lineHeight: 22,
      alignSelf: 'center',
      fontWeight: 'bold',
      maxWidth: 120,
    },
    leagueLogoImage: {
      width: 42,
      height: 42,
      borderRadius: 26,
      position: 'absolute',
      top: 15,
      right: 18,
      zIndex: 1,
    },
    teamLogoImage: {
      width: 42,
      height: 42,
      borderRadius: 26,
      position: 'absolute',
      bottom: 80,
      start: 15,
      zIndex: 1,
    },
    uniqueplayLogo: {
      width: 250,
      alignSelf: 'center',
    },
    scoreTxt: {
      position: 'absolute',
      top: 10,
      start: 16,
      fontFamily: 'Pretendard',
      fontSize: 36,
      textShadowColor: 'black',
      textShadowOffset: {width: 2, height: 2},
      textShadowRadius: 0,
    },
    userInfo: {
      fontFamily: 'Pretendard-ExtraBold',
      fontSize: 30,
      color: 'white',
      position: 'absolute',
      bottom: 80,
      start: 65,
      zIndex: 1,
      fontFamily: 'S-CoreDream-7ExtraBold',
      fontWeight: '800',
    },
    statMetaContainer: {
      flexDirection: 'row',
      position: 'absolute',
      bottom: 52,
      width: '100%',
      paddingHorizontal: 15,
    },
    statContainer: {
      flexDirection: 'row',
      position: 'absolute',
      bottom: 17,
      width: '100%',
      paddingHorizontal: 17.5,
    },
    statMetaTxt: {
      fontFamily: 'Pretendard-Bold',
      fontSize: 17,
      flex: 1,
      color: 'white',
      textAlign: 'center',
      fontWeight: 'bold',
    },
    statTxt: {
      fontFamily: 'Pretendard-Bold',
      fontSize: 24,
      flex: 1,
      color: 'white',
      textAlign: 'center',
      textShadowColor: 'black',
      textShadowOffset: {width: 3, height: 3},
      textShadowRadius: 0,
      fontWeight: 'bold',
    },
    goldTxt: {
      color: '#FFD700',
    },
    silverTxt: {
      color: '#C0C0C0',
    },
    bronzeTxt: {
      color: '#cc6633',
    },
    navyTxt: {
      color: '#000080',
    },
    leagueInfoBg: {
      marginTop: 20,
      width: 320,
      height: (320 / 138) * 22,
      alignSelf: 'center',
      alignItems: 'center',
    },
    teamInfoBg: {
      marginTop: 12,
      width: 220,
      height: (220 / 85) * 9,
      alignSelf: 'center',
    },
    statInfoBg: {
      marginTop: 30,
      width: 280,
      height: (280 / 120) * 19,
      alignSelf: 'center',
    },
    recordType: {
      fontFamily: 'Pretendard-Bold',
      fontSize: 12,
      lineHeight: 18,
      fontWeight: 'bold',
      flex: 1,
      color: 'white',
      textAlign: 'center',
    },
    recordValue: {
      fontFamily: 'Pretendard-Bold',
      marginTop: 2,
      fontSize: 13,
      lineHeight: 20,
      fontWeight: 'bold',
      flex: 1,
      color: 'black',
      textAlign: 'center',
    },
    buttonContainer: {
      marginTop: 25,
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: 280,
      alignSelf: 'center',
      position: 'absolute',
      bottom: 15 + insets.bottom,
    },
    btnItem: {},
    controlImg: {
      width: 30,
      height: 30,
    },
    scoreImg: {
      width: fullWidth >= 550 ? 62 : 60,
      height: ((fullWidth >= 550 ? 62 : 60) / 240) * 303,
    },
    scoreImgLeft: {},
    scoreImgRight: {
      marginStart: Platform.OS === 'web' ? (fullWidth >= 550 ? -30 : -30) : -30,
    },
    scoreContainer: {
      alignContent: 'center',
      alignItems: 'center',
    },
  });

  const scoreImages = useMemo(() => {
    if (!item) {
      return (
        <>
          <Image
            style={[pageStyle.scoreImg, pageStyle.scoreImgLeft]}
            source={require('../assets/images/normal_0.png')}
          />
          <Image
            style={[pageStyle.scoreImg, pageStyle.scoreImgRight]}
            source={require('../assets/images/normal_0.png')}
          />
        </>
      );
    }
    const score = parseInt(item.score ? item.score : 0, 10);
    const grade = item.grade;

    let firstLetter = null;
    let SecondLetter = null;
    if (item.score) {
      firstLetter = String(score).substring(0, 1);
      SecondLetter = String(score).substring(1, 2);
    } else {
      return (
        <>
          <Image
            style={[pageStyle.scoreImg, pageStyle.scoreImgLeft]}
            source={require('../assets/images/normal_0.png')}
          />
          <Image
            style={[pageStyle.scoreImg, pageStyle.scoreImgRight]}
            source={require('../assets/images/normal_0.png')}
          />
        </>
      );
    }
    if (grade === '네이비') {
      let component = [];
      switch (firstLetter) {
        case '0':
          component.push(
            <Image
              style={[pageStyle.scoreImg, pageStyle.scoreImgLeft]}
              source={require(`../assets/images/normal_0.png`)}
            />,
          );
          break;
        case '1':
          component.push(
            <Image
              style={[pageStyle.scoreImg, pageStyle.scoreImgLeft]}
              source={require(`../assets/images/normal_1.png`)}
            />,
          );
          break;
        case '2':
          component.push(
            <Image
              style={[pageStyle.scoreImg, pageStyle.scoreImgLeft]}
              source={require(`../assets/images/normal_2.png`)}
            />,
          );
          break;
        case '3':
          component.push(
            <Image
              style={[pageStyle.scoreImg, pageStyle.scoreImgLeft]}
              source={require(`../assets/images/normal_3.png`)}
            />,
          );
          break;
        case '4':
          component.push(
            <Image
              style={[pageStyle.scoreImg, pageStyle.scoreImgLeft]}
              source={require(`../assets/images/normal_4.png`)}
            />,
          );
          break;
        case '5':
          component.push(
            <Image
              style={[pageStyle.scoreImg, pageStyle.scoreImgLeft]}
              source={require(`../assets/images/normal_5.png`)}
            />,
          );
          break;
        case '6':
          component.push(
            <Image
              style={[pageStyle.scoreImg, pageStyle.scoreImgLeft]}
              source={require(`../assets/images/normal_6.png`)}
            />,
          );
          break;
        case '7':
          component.push(
            <Image
              style={[pageStyle.scoreImg, pageStyle.scoreImgLeft]}
              source={require(`../assets/images/normal_7.png`)}
            />,
          );
          break;
        case '8':
          component.push(
            <Image
              style={[pageStyle.scoreImg, pageStyle.scoreImgLeft]}
              source={require(`../assets/images/normal_8.png`)}
            />,
          );
          break;
        case '9':
          component.push(
            <Image
              style={[pageStyle.scoreImg, pageStyle.scoreImgLeft]}
              source={require(`../assets/images/normal_9.png`)}
            />,
          );
          break;
      }
      switch (SecondLetter) {
        case '0':
          component.push(
            <Image
              style={[pageStyle.scoreImg, pageStyle.scoreImgRight]}
              source={require(`../assets/images/normal_0.png`)}
            />,
          );
          break;
        case '1':
          component.push(
            <Image
              style={[pageStyle.scoreImg, pageStyle.scoreImgRight]}
              source={require(`../assets/images/normal_1.png`)}
            />,
          );
          break;
        case '2':
          component.push(
            <Image
              style={[pageStyle.scoreImg, pageStyle.scoreImgRight]}
              source={require(`../assets/images/normal_2.png`)}
            />,
          );
          break;
        case '3':
          component.push(
            <Image
              style={[pageStyle.scoreImg, pageStyle.scoreImgRight]}
              source={require(`../assets/images/normal_3.png`)}
            />,
          );
          break;
        case '4':
          component.push(
            <Image
              style={[pageStyle.scoreImg, pageStyle.scoreImgRight]}
              source={require(`../assets/images/normal_4.png`)}
            />,
          );
          break;
        case '5':
          component.push(
            <Image
              style={[pageStyle.scoreImg, pageStyle.scoreImgRight]}
              source={require(`../assets/images/normal_5.png`)}
            />,
          );
          break;
        case '6':
          component.push(
            <Image
              style={[pageStyle.scoreImg, pageStyle.scoreImgRight]}
              source={require(`../assets/images/normal_6.png`)}
            />,
          );
          break;
        case '7':
          component.push(
            <Image
              style={[pageStyle.scoreImg, pageStyle.scoreImgRight]}
              source={require(`../assets/images/normal_7.png`)}
            />,
          );
          break;
        case '8':
          component.push(
            <Image
              style={[pageStyle.scoreImg, pageStyle.scoreImgRight]}
              source={require(`../assets/images/normal_8.png`)}
            />,
          );
          break;
        case '9':
          component.push(
            <Image
              style={[pageStyle.scoreImg, pageStyle.scoreImgRight]}
              source={require(`../assets/images/normal_9.png`)}
            />,
          );
          break;
      }
      return component;
    } else if (grade === '브론즈') {
      let component = [];
      switch (firstLetter) {
        case '0':
          component.push(
            <Image
              style={[pageStyle.scoreImg, pageStyle.scoreImgLeft]}
              source={require(`../assets/images/bronze_0.png`)}
            />,
          );
          break;
        case '1':
          component.push(
            <Image
              style={[pageStyle.scoreImg, pageStyle.scoreImgLeft]}
              source={require(`../assets/images/bronze_1.png`)}
            />,
          );
          break;
        case '2':
          component.push(
            <Image
              style={[pageStyle.scoreImg, pageStyle.scoreImgLeft]}
              source={require(`../assets/images/bronze_2.png`)}
            />,
          );
          break;
        case '3':
          component.push(
            <Image
              style={[pageStyle.scoreImg, pageStyle.scoreImgLeft]}
              source={require(`../assets/images/bronze_3.png`)}
            />,
          );
          break;
        case '4':
          component.push(
            <Image
              style={[pageStyle.scoreImg, pageStyle.scoreImgLeft]}
              source={require(`../assets/images/bronze_4.png`)}
            />,
          );
          break;
        case '5':
          component.push(
            <Image
              style={[pageStyle.scoreImg, pageStyle.scoreImgLeft]}
              source={require(`../assets/images/bronze_5.png`)}
            />,
          );
          break;
        case '6':
          component.push(
            <Image
              style={[pageStyle.scoreImg, pageStyle.scoreImgLeft]}
              source={require(`../assets/images/bronze_6.png`)}
            />,
          );
          break;
        case '7':
          component.push(
            <Image
              style={[pageStyle.scoreImg, pageStyle.scoreImgLeft]}
              source={require(`../assets/images/bronze_7.png`)}
            />,
          );
          break;
        case '8':
          component.push(
            <Image
              style={[pageStyle.scoreImg, pageStyle.scoreImgLeft]}
              source={require(`../assets/images/bronze_8.png`)}
            />,
          );
          break;
        case '9':
          component.push(
            <Image
              style={[pageStyle.scoreImg, pageStyle.scoreImgLeft]}
              source={require(`../assets/images/bronze_9.png`)}
            />,
          );
          break;
      }
      switch (SecondLetter) {
        case '0':
          component.push(
            <Image
              style={[pageStyle.scoreImg, pageStyle.scoreImgRight]}
              source={require(`../assets/images/bronze_0.png`)}
            />,
          );
          break;
        case '1':
          component.push(
            <Image
              style={[pageStyle.scoreImg, pageStyle.scoreImgRight]}
              source={require(`../assets/images/bronze_1.png`)}
            />,
          );
          break;
        case '2':
          component.push(
            <Image
              style={[pageStyle.scoreImg, pageStyle.scoreImgRight]}
              source={require(`../assets/images/bronze_2.png`)}
            />,
          );
          break;
        case '3':
          component.push(
            <Image
              style={[pageStyle.scoreImg, pageStyle.scoreImgRight]}
              source={require(`../assets/images/bronze_3.png`)}
            />,
          );
          break;
        case '4':
          component.push(
            <Image
              style={[pageStyle.scoreImg, pageStyle.scoreImgRight]}
              source={require(`../assets/images/bronze_4.png`)}
            />,
          );
          break;
        case '5':
          component.push(
            <Image
              style={[pageStyle.scoreImg, pageStyle.scoreImgRight]}
              source={require(`../assets/images/bronze_5.png`)}
            />,
          );
          break;
        case '6':
          component.push(
            <Image
              style={[pageStyle.scoreImg, pageStyle.scoreImgRight]}
              source={require(`../assets/images/bronze_6.png`)}
            />,
          );
          break;
        case '7':
          component.push(
            <Image
              style={[pageStyle.scoreImg, pageStyle.scoreImgRight]}
              source={require(`../assets/images/bronze_7.png`)}
            />,
          );
          break;
        case '8':
          component.push(
            <Image
              style={[pageStyle.scoreImg, pageStyle.scoreImgRight]}
              source={require(`../assets/images/bronze_8.png`)}
            />,
          );
          break;
        case '9':
          component.push(
            <Image
              style={[pageStyle.scoreImg, pageStyle.scoreImgRight]}
              source={require(`../assets/images/bronze_9.png`)}
            />,
          );
          break;
      }
      return component;
    } else if (grade === '실버') {
      let component = [];
      switch (firstLetter) {
        case '0':
          component.push(
            <Image
              style={[pageStyle.scoreImg, pageStyle.scoreImgLeft]}
              source={require(`../assets/images/silver_0.png`)}
            />,
          );
          break;
        case '1':
          component.push(
            <Image
              style={[pageStyle.scoreImg, pageStyle.scoreImgLeft]}
              source={require(`../assets/images/silver_1.png`)}
            />,
          );
          break;
        case '2':
          component.push(
            <Image
              style={[pageStyle.scoreImg, pageStyle.scoreImgLeft]}
              source={require(`../assets/images/silver_2.png`)}
            />,
          );
          break;
        case '3':
          component.push(
            <Image
              style={[pageStyle.scoreImg, pageStyle.scoreImgLeft]}
              source={require(`../assets/images/silver_3.png`)}
            />,
          );
          break;
        case '4':
          component.push(
            <Image
              style={[pageStyle.scoreImg, pageStyle.scoreImgLeft]}
              source={require(`../assets/images/silver_4.png`)}
            />,
          );
          break;
        case '5':
          component.push(
            <Image
              style={[pageStyle.scoreImg, pageStyle.scoreImgLeft]}
              source={require(`../assets/images/silver_5.png`)}
            />,
          );
          break;
        case '6':
          component.push(
            <Image
              style={[pageStyle.scoreImg, pageStyle.scoreImgLeft]}
              source={require(`../assets/images/silver_6.png`)}
            />,
          );
          break;
        case '7':
          component.push(
            <Image
              style={[pageStyle.scoreImg, pageStyle.scoreImgLeft]}
              source={require(`../assets/images/silver_7.png`)}
            />,
          );
          break;
        case '8':
          component.push(
            <Image
              style={[pageStyle.scoreImg, pageStyle.scoreImgLeft]}
              source={require(`../assets/images/silver_8.png`)}
            />,
          );
          break;
        case '9':
          component.push(
            <Image
              style={[pageStyle.scoreImg, pageStyle.scoreImgLeft]}
              source={require(`../assets/images/silver_9.png`)}
            />,
          );
          break;
      }
      switch (SecondLetter) {
        case '0':
          component.push(
            <Image
              style={[pageStyle.scoreImg, pageStyle.scoreImgRight]}
              source={require(`../assets/images/silver_0.png`)}
            />,
          );
          break;
        case '1':
          component.push(
            <Image
              style={[pageStyle.scoreImg, pageStyle.scoreImgRight]}
              source={require(`../assets/images/silver_1.png`)}
            />,
          );
          break;
        case '2':
          component.push(
            <Image
              style={[pageStyle.scoreImg, pageStyle.scoreImgRight]}
              source={require(`../assets/images/silver_2.png`)}
            />,
          );
          break;
        case '3':
          component.push(
            <Image
              style={[pageStyle.scoreImg, pageStyle.scoreImgRight]}
              source={require(`../assets/images/silver_3.png`)}
            />,
          );
          break;
        case '4':
          component.push(
            <Image
              style={[pageStyle.scoreImg, pageStyle.scoreImgRight]}
              source={require(`../assets/images/silver_4.png`)}
            />,
          );
          break;
        case '5':
          component.push(
            <Image
              style={[pageStyle.scoreImg, pageStyle.scoreImgRight]}
              source={require(`../assets/images/silver_5.png`)}
            />,
          );
          break;
        case '6':
          component.push(
            <Image
              style={[pageStyle.scoreImg, pageStyle.scoreImgRight]}
              source={require(`../assets/images/silver_6.png`)}
            />,
          );
          break;
        case '7':
          component.push(
            <Image
              style={[pageStyle.scoreImg, pageStyle.scoreImgRight]}
              source={require(`../assets/images/silver_7.png`)}
            />,
          );
          break;
        case '8':
          component.push(
            <Image
              style={[pageStyle.scoreImg, pageStyle.scoreImgRight]}
              source={require(`../assets/images/silver_8.png`)}
            />,
          );
          break;
        case '9':
          component.push(
            <Image
              style={[pageStyle.scoreImg, pageStyle.scoreImgRight]}
              source={require(`../assets/images/silver_9.png`)}
            />,
          );
          break;
      }
      return component;
    } else if (grade === '골드') {
      let component = [];
      switch (firstLetter) {
        case '0':
          component.push(
            <Image
              style={[pageStyle.scoreImg, pageStyle.scoreImgLeft]}
              source={require(`../assets/images/gold_0.png`)}
            />,
          );
          break;
        case '1':
          component.push(
            <Image
              style={[pageStyle.scoreImg, pageStyle.scoreImgLeft]}
              source={require(`../assets/images/gold_1.png`)}
            />,
          );
          break;
        case '2':
          component.push(
            <Image
              style={[pageStyle.scoreImg, pageStyle.scoreImgLeft]}
              source={require(`../assets/images/gold_2.png`)}
            />,
          );
          break;
        case '3':
          component.push(
            <Image
              style={[pageStyle.scoreImg, pageStyle.scoreImgLeft]}
              source={require(`../assets/images/gold_3.png`)}
            />,
          );
          break;
        case '4':
          component.push(
            <Image
              style={[pageStyle.scoreImg, pageStyle.scoreImgLeft]}
              source={require(`../assets/images/gold_4.png`)}
            />,
          );
          break;
        case '5':
          component.push(
            <Image
              style={[pageStyle.scoreImg, pageStyle.scoreImgLeft]}
              source={require(`../assets/images/gold_5.png`)}
            />,
          );
          break;
        case '6':
          component.push(
            <Image
              style={[pageStyle.scoreImg, pageStyle.scoreImgLeft]}
              source={require(`../assets/images/gold_6.png`)}
            />,
          );
          break;
        case '7':
          component.push(
            <Image
              style={[pageStyle.scoreImg, pageStyle.scoreImgLeft]}
              source={require(`../assets/images/gold_7.png`)}
            />,
          );
          break;
        case '8':
          component.push(
            <Image
              style={[pageStyle.scoreImg, pageStyle.scoreImgLeft]}
              source={require(`../assets/images/gold_8.png`)}
            />,
          );
          break;
        case '9':
          component.push(
            <Image
              style={[pageStyle.scoreImg, pageStyle.scoreImgLeft]}
              source={require(`../assets/images/gold_9.png`)}
            />,
          );
          break;
      }
      switch (SecondLetter) {
        case '0':
          component.push(
            <Image
              style={[pageStyle.scoreImg, pageStyle.scoreImgRight]}
              source={require(`../assets/images/gold_0.png`)}
            />,
          );
          break;
        case '1':
          component.push(
            <Image
              style={[pageStyle.scoreImg, pageStyle.scoreImgRight]}
              source={require(`../assets/images/gold_1.png`)}
            />,
          );
          break;
        case '2':
          component.push(
            <Image
              style={[pageStyle.scoreImg, pageStyle.scoreImgRight]}
              source={require(`../assets/images/gold_2.png`)}
            />,
          );
          break;
        case '3':
          component.push(
            <Image
              style={[pageStyle.scoreImg, pageStyle.scoreImgRight]}
              source={require(`../assets/images/gold_3.png`)}
            />,
          );
          break;
        case '4':
          component.push(
            <Image
              style={[pageStyle.scoreImg, pageStyle.scoreImgRight]}
              source={require(`../assets/images/gold_4.png`)}
            />,
          );
          break;
        case '5':
          component.push(
            <Image
              style={[pageStyle.scoreImg, pageStyle.scoreImgRight]}
              source={require(`../assets/images/gold_5.png`)}
            />,
          );
          break;
        case '6':
          component.push(
            <Image
              style={[pageStyle.scoreImg, pageStyle.scoreImgRight]}
              source={require(`../assets/images/gold_6.png`)}
            />,
          );
          break;
        case '7':
          component.push(
            <Image
              style={[pageStyle.scoreImg, pageStyle.scoreImgRight]}
              source={require(`../assets/images/gold_7.png`)}
            />,
          );
          break;
        case '8':
          component.push(
            <Image
              style={[pageStyle.scoreImg, pageStyle.scoreImgRight]}
              source={require(`../assets/images/gold_8.png`)}
            />,
          );
          break;
        case '9':
          component.push(
            <Image
              style={[pageStyle.scoreImg, pageStyle.scoreImgRight]}
              source={require(`../assets/images/gold_9.png`)}
            />,
          );
          break;
      }
      return component;
    }
  }, [item?.grade]);

  const snapshotRef = useRef();

  const handleEditCard = () => {
    launchImageLibrary(
      {
        mediaType: 'photo',
        includeBase64: true,
        maxHeight: 500,
        maxWidth: 500,
      },
      response => {
        if (
          !response.errorCode &&
          response.assets &&
          response.assets.length > 0 &&
          response.assets[0] &&
          response.assets[0].base64
        ) {
          Alert.alert(
            '게임카드',
            '게임카드 이미지를 선택한 이미지로 변경하시겠습니까?',
            [
              {
                text: '예',
                onPress: () => {
                  setNewCardImageUri(response.assets[0].uri);
                  setNewCardImageData(
                    'data:image/png;base64,' + response.assets[0].base64,
                  );
                  postGameCardImage(
                    'data:image/png;base64,' + response.assets[0].base64,
                  );
                },
              },
              {
                text: '아니오',
              },
            ],
          );
        } else {
          console.error(response);
        }
      },
    );
  };

  const handleDownload = () => {
    snapshotRef.current.capture().then(uri => {
      console.log('do something with ', uri);
      if (Platform.OS === 'web') {
        var a = document.createElement('a'); //Create <a>
        a.href = uri; //Image Base64 Goes here
        a.download = `[유니크플레이]플레이어카드_${item.userName}_${item.teamName}_${item.subLeagueName}`; //File name Here
        a.click(); //Downloaded file
      } else {
        MediaLibrary.saveToLibraryAsync(uri).then(res => {
          alert('선수카드가 저장되었습니다.');
        });
      }
    });
  };

  const handleShare = () => {
    if (Platform.OS === 'web') {
      window.navigator.clipboard
        .writeText(
          `https://service-api.unique-play.com/share/player-card/${itemId}`,
        )
        .then(() => {
          alert('공유 링크가 복사되었습니다!');
        });
    } else {
      Clipboard.setString(
        `https://service-api.unique-play.com/share/player-card/${itemId}`,
      );
      alert('공유 링크가 복사되었습니다!');
    }
  };

  const [componentHeight, setComponentHeight] = useState();

  return (
    <SafeAreaView
      nativeID="common-page-container"
      style={[styles.container, {backgroundColor: 'rgb(15, 23, 53)'}]}>
      <ViewShot
        ref={snapshotRef}
        options={{
          fileName: `[유니크플레이]플레이어카드_${item?.userName}_${item?.teamName}_${item?.subLeagueName}`,
          quality: 0.6,
          // width: Dimensions.get('window').width,
          // height: componentHeight,
        }}>
        <ImageBackground
          resizeMode="cover"
          style={[pageStyle.cardBg]}
          source={require('../assets/images/card_bg.png')}>
          <ScrollView>
            <View
              onLayout={event => {
                var {x, y, width, height} = event.nativeEvent.layout;
                setComponentHeight(height);
              }}
              style={[
                {
                  paddingTop: Platform.OS == 'ios' ? 40 : 30,
                },
              ]}>
              <Image
                resizeMode="contain"
                style={[pageStyle.uniqueplayLogo]}
                source={require('../assets/images/card_uniqueplay_logo.png')}
              />
              <ImageBackground
                style={[
                  {
                    position: 'relative',
                    marginHorizontal:
                      ((Platform.OS === 'web'
                        ? fullWidth
                        : Dimensions.get('window').width) -
                        280) /
                      2,
                    width: 280,
                    height: (280 / 1022) * 1440,
                  },
                ]}
                source={profileImageUrl}>
                <Image
                  resizeMode={'cover'}
                  source={getBackgroundImageResource(item)}
                  style={[
                    {
                      top: 0,
                      start: 0,
                      position: 'absolute',
                      width: 280,
                      height: (280 / 1022) * 1440,
                    },
                  ]}
                />
                <Image
                  resizeMode="cover"
                  source={{uri: item?.leagueLogoImageUrl}}
                  style={[pageStyle.leagueLogoImage]}
                />
                <Image
                  resizeMode="cover"
                  source={{uri: item?.teamLogoImageUrl}}
                  style={[pageStyle.teamLogoImage]}
                />
                <View style={[styles.row, pageStyle.scoreContainer]}>
                  {scoreImages}
                </View>
                <Text style={[pageStyle.userInfo]}>
                  {item?.userName}`{item?.backNumber}
                </Text>
                <View style={[pageStyle.statMetaContainer]}>
                  <Text style={[pageStyle.statMetaTxt]}>
                    {getScoreType1(item)}
                  </Text>
                  <Text style={[pageStyle.statMetaTxt]}>
                    {getScoreType2(item)}
                  </Text>
                  <Text style={[pageStyle.statMetaTxt]}>
                    {getScoreType3(item)}
                  </Text>
                  <Text style={[pageStyle.statMetaTxt]}>
                    {getScoreType4(item)}
                  </Text>
                  <Text style={[pageStyle.statMetaTxt]}>
                    {getScoreType5(item)}
                  </Text>
                </View>
                <View style={[pageStyle.statContainer]}>
                  <Text
                    style={[
                      pageStyle.statTxt,
                      getScore1(item) >= 90 && {color: 'red'},
                    ]}>
                    {getScore1(item)}
                  </Text>
                  <Text
                    style={[
                      pageStyle.statTxt,
                      getScore2(item) >= 90 && {color: 'red'},
                    ]}>
                    {getScore2(item)}
                  </Text>
                  <Text
                    style={[
                      pageStyle.statTxt,
                      getScore3(item) >= 90 && {color: 'red'},
                    ]}>
                    {getScore3(item)}
                  </Text>
                  <Text
                    style={[
                      pageStyle.statTxt,
                      getScore4(item) >= 90 && {color: 'red'},
                    ]}>
                    {getScore4(item)}
                  </Text>
                  <Text
                    style={[
                      pageStyle.statTxt,
                      getScore5(item) >= 90 && {color: 'red'},
                    ]}>
                    {getScore5(item)}
                  </Text>
                </View>
              </ImageBackground>
              <ImageBackground
                resizeMode="contain"
                style={[pageStyle.leagueInfoBg]}
                source={require('../assets/images/card_league_bg.png')}>
                <Text style={[pageStyle.seasonYear]}>{item?.seasonYear}`</Text>
                <Text
                  numberOfLines={1}
                  adjustsFontSizeToFit={true}
                  style={[pageStyle.leagueName]}>
                  {item?.leagueName}
                </Text>
                <Text style={[pageStyle.subLeagueName]}>
                  {item?.subLeagueName}
                </Text>
              </ImageBackground>
              <ImageBackground
                resizeMode="contain"
                style={[pageStyle.teamInfoBg]}
                source={require('../assets/images/card_team_bg.png')}>
                <Text
                  style={[pageStyle.teamName]}
                  numberOfLines={1}
                  ellipsizeMode="tail">
                  {item?.teamName}
                </Text>
              </ImageBackground>
              <ImageBackground
                resizeMode="contain"
                style={[pageStyle.statInfoBg]}
                source={require('../assets/images/card_stat.png')}>
                <View style={[styles.row]}>
                  <Text numberOfLines={1} style={[pageStyle.recordType]}>
                    {getRecordType(0, item)}
                  </Text>
                  <Text numberOfLines={1} style={[pageStyle.recordType]}>
                    {getRecordType(1, item)}
                  </Text>
                  <Text numberOfLines={1} style={[pageStyle.recordType]}>
                    {getRecordType(2, item)}
                  </Text>
                  <Text numberOfLines={1} style={[pageStyle.recordType]}>
                    {getRecordType(3, item)}
                  </Text>
                  <Text numberOfLines={1} style={[pageStyle.recordType]}>
                    {getRecordType(4, item)}
                  </Text>
                  <Text numberOfLines={1} style={[pageStyle.recordType]}>
                    {getRecordType(5, item)}
                  </Text>
                </View>
                <View style={[styles.row]}>
                  <Text style={[pageStyle.recordValue]}>
                    {getRecordValue(0, item)}
                  </Text>
                  <Text style={[pageStyle.recordValue]}>
                    {getRecordValue(1, item)}
                  </Text>
                  <Text style={[pageStyle.recordValue]}>
                    {getRecordValue(2, item)}
                  </Text>
                  <Text style={[pageStyle.recordValue]}>
                    {getRecordValue(3, item)}
                  </Text>
                  <Text style={[pageStyle.recordValue]}>
                    {getRecordValue(4, item)}
                  </Text>
                  <Text style={[pageStyle.recordValue]}>
                    {getRecordValue(5, item)}
                  </Text>
                </View>
              </ImageBackground>
            </View>
          </ScrollView>
        </ImageBackground>
      </ViewShot>
      <View style={[pageStyle.buttonContainer]}>
        {item?.user?.id === userData.id && (
          <TouchableOpacity
            onPress={handleEditCard}
            style={[pageStyle.btnItem]}>
            <Image
              resizeMode="cover"
              style={[pageStyle.controlImg]}
              source={require('../assets/images/card_edit.png')}
            />
          </TouchableOpacity>
        )}
        <TouchableOpacity onPress={handleDownload} style={[pageStyle.btnItem]}>
          <Image
            resizeMode="cover"
            style={[pageStyle.controlImg]}
            source={require('../assets/images/card_download.png')}
          />
        </TouchableOpacity>
        <TouchableOpacity onPress={handleShare} style={[pageStyle.btnItem]}>
          <Image
            resizeMode="cover"
            style={[pageStyle.controlImg]}
            source={require('../assets/images/card_share.png')}
          />
        </TouchableOpacity>
      </View>
      <View
        style={[
          styles.headerCommon,
          {
            backgroundColor: 'transparent',
            zIndex: 1,
            position: 'absolute',
            top: 0 + insets.top,
          },
        ]}>
        <View style={[styles.row, styles.verticalAlign]}>
          <TouchableOpacity
            onPress={() =>
              Platform.OS === 'web' ? history.back() : navigation.goBack()
            }>
            <Image
              source={require('../assets/images/icon_back.png')}
              style={[styles.headerBackButton]}
            />
          </TouchableOpacity>
        </View>
      </View>
    </SafeAreaView>
  );
};

export default PlayerCardDetailView;

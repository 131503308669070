import React, {useState, useRef, useEffect, useMemo} from 'react';
import {
  StyleSheet,
  View,
  Dimensions,
  Modal,
  Text,
  Image,
  TouchableOpacity,
  TextInput,
  FlatList,
  ImageBackground,
  Platform,
  ScrollView,
} from 'react-native';
import {Shadow} from 'react-native-shadow-2';
import styles from '../../common/styles';
import {useSafeAreaInsets} from 'react-native-safe-area-context';
import Colors from '../../common/colors';
import {lineUp} from '../../common/dummyData';
import moment from 'moment';
import 'moment/locale/ko';
import AsyncStorage from '@react-native-async-storage/async-storage';
import api from '../../common/api';

const LineUpPopup = ({data, navigation, closePopup, navigateToMemberPage}) => {
  const accessToken = useRef();
  const insets = useSafeAreaInsets();
  const [currentTeamId, setCurrentTeamId] = useState('');
  const [teamLineUps, setTeamLineUps] = useState([]);
  const [teamCandidates, setTeamCandidates] = useState([]);

  const firstTeamLineUps = useRef();
  const lastTeamLineUps = useRef();
  const firstTeamCandidates = useRef();
  const lastTeamCandidates = useRef();

  useEffect(() => {
    setCurrentTeamId(data.batFirstTeam.id);
  }, [data.id]);
  useEffect(() => {
    AsyncStorage.getItem('@accessToken', (err, result) => {
      accessToken.current = result;
      getData(result);
    });
  }, []);

  const getData = token => {
    api
      .get(`/lineups?gameId=${data.id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(function (response) {
        const lineUps = response.data;
        let localBatFirstTeamLineUps = [];
        let localBatLastTeamLineUps = [];
        let localBatFirstTeamCandidates = [];
        let localBatLastTeamCandidates = [];
        for (let i = 0; i < lineUps.length; i++) {
          const item = lineUps[i];
          if (
            ((!item.battingOrder || item.battingSubOrder) &&
              !item.pitchingOrder) ||
            (item.pitchingOrder && item.position !== '선발투수')
          ) {
            if (
              item.team.id === data.batFirstTeam.id &&
              !localBatFirstTeamCandidates
                .map(i => i.member.id)
                .includes(item.member.id)
            ) {
              localBatFirstTeamCandidates.push(item);
            } else if (
              item.team.id === data.batLastTeam.id &&
              !localBatLastTeamCandidates
                .map(i => i.member.id)
                .includes(item.member.id)
            ) {
              localBatLastTeamCandidates.push(item);
            }
          } else {
            if (item.team.id === data.batFirstTeam.id) {
              localBatFirstTeamLineUps.push(item);
            } else if (item.team.id === data.batLastTeam.id) {
              localBatLastTeamLineUps.push(item);
            }
          }
        }
        localBatFirstTeamLineUps.sort((i, j) => {
          if (!!i.pitchingOrder && !j.pitchingOrder) {
            return 1;
          } else if (!i.pitchingOrder && j.pitchingOrder) {
            return -1;
          } else if (!!i.pitchingOrder && !!j.pitchingOrder) {
            return parseInt(i.pitchingOrder, 10) > parseInt(j.pitchingOrder, 10)
              ? 1
              : -1;
          }
          return i.battingOrder !== j.battingOrder
            ? parseInt(i.battingOrder, 10) > parseInt(j.battingOrder, 10)
              ? 1
              : -1
            : i.battingSubOrder > j.battingSubOrder
            ? 1
            : -1;
        });
        localBatLastTeamLineUps.sort((i, j) => {
          if (!!i.pitchingOrder && !j.pitchingOrder) {
            return 1;
          } else if (!i.pitchingOrder && j.pitchingOrder) {
            return -1;
          } else if (!!i.pitchingOrder && !!j.pitchingOrder) {
            return parseInt(i.pitchingOrder, 10) > parseInt(j.pitchingOrder, 10)
              ? 1
              : -1;
          }
          return i.battingOrder !== j.battingOrder
            ? parseInt(i.battingOrder, 10) > parseInt(j.battingOrder, 10)
              ? 1
              : -1
            : i.battingSubOrder > j.battingSubOrder
            ? 1
            : -1;
        });
        firstTeamLineUps.current = localBatFirstTeamLineUps;
        lastTeamLineUps.current = localBatLastTeamLineUps;
        firstTeamCandidates.current = localBatFirstTeamCandidates;
        lastTeamCandidates.current = localBatLastTeamCandidates;
        selectFirstBatTeam();
      })
      .catch(function (error) {
        console.log(error.response);
      });
  };

  const parseDate = date => {
    if (date.indexOf('T') > -1) {
      return moment(date.substring(0, date.indexOf('T')), 'YYYY-MM-DD').format(
        'MM/DD',
      );
    }
    return moment(date, 'YYYY-MM-DD').locale('ko').format('MM/DD(dd)');
  };

  const parseTime = time => {
    if (time.indexOf(':', 4) > -1) {
      return time.substring(0, time.indexOf(':', 4));
    }
    return time;
  };

  const selectFirstBatTeam = () => {
    setCurrentTeamId(data.batFirstTeam.id);
    setTeamLineUps(firstTeamLineUps.current);
    setTeamCandidates(firstTeamCandidates.current);
  };

  const selectLastBatTeam = () => {
    setCurrentTeamId(data.batLastTeam.id);
    setTeamLineUps(lastTeamLineUps.current);
    setTeamCandidates(lastTeamCandidates.current);
  };

  const cancel = () => {};

  const renderHeader = () => (
    <View>
      <View style={[styles.row, pageStyle.tableHeader]}>
        <Text style={[pageStyle.headerNumber]} />
        <Text style={[pageStyle.headerTxt, pageStyle.teamAway]}>
          {currentTeamId === data.batFirstTeam.id ? 'AWAY TEAM' : 'HOME TEAM'}
        </Text>
        <View />
      </View>
      <View style={[styles.row, {backgroundColor: Colors.main}]}>
        <Text style={[pageStyle.colHeader, pageStyle.colBattingOrder]}>
          타순
        </Text>
        <Text style={[pageStyle.colHeader, pageStyle.colPosition]}>포지션</Text>
        <Text style={[pageStyle.colHeader, pageStyle.colPlayerName]}>
          선수명
        </Text>
      </View>
    </View>
  );

  const renderFooter = () => (
    <View style={[{paddingBottom: 100}]}>
      <Text
        style={[
          {
            paddingVertical: 6,
            paddingHorizontal: 8,
            backgroundColor: Colors.main,
            color: 'white',
            fontSize: 14,
            marginTop: 12,
            fontWeight: '600',
            textAlign: 'center',
          },
        ]}>
        대기명단
      </Text>
      <View>
        {teamCandidates &&
          teamCandidates.map((item, index) => {
            return (
              <TouchableOpacity
                onPress={() =>
                  navigateToMemberPage(
                    item.member?.user,
                    item.member?.user?.uid,
                  )
                }
                style={[
                  styles.col,
                  pageStyle.td,
                  pageStyle.tdLeftFirst,
                  {
                    flexDirection: 'row',
                    justifyContent: 'center',
                    paddingVertical: 4,
                  },
                ]}>
                <Text style={[pageStyle.tdTxt]}>
                  {item?.member?.user?.name} ({item?.member?.number})
                </Text>
              </TouchableOpacity>
            );
          })}
      </View>
    </View>
  );

  const renderItem = ({item}) => (
    <View style={[styles.row]}>
      <View style={[styles.col, pageStyle.td, pageStyle.tdLeftFirst]}>
        <Text
          style={[pageStyle.number, item.pitchingOrder && pageStyle.pitcher]}>
          {item.pitchingOrder ? '투수' : item.battingOrder}
        </Text>
      </View>
      <View style={[styles.col2, pageStyle.td]}>
        <Text style={[pageStyle.tdTxt]}>{item.position}</Text>
      </View>
      <TouchableOpacity
        onPress={() =>
          navigateToMemberPage(item.member?.user, item.member?.user?.uid)
        }
        style={[styles.col3, pageStyle.td]}>
        {item.member && (
          <Text style={[pageStyle.tdTxt]}>
            {item.member?.user?.name} ({item.member?.number})
          </Text>
        )}
      </TouchableOpacity>
    </View>
  );

  const fullWidth = useMemo(() => {
    if (Platform.OS === 'web') {
      const windowWidth = Dimensions.get('window').width;
      if (windowWidth < 550) {
        return windowWidth;
      } else {
        return 550;
      }
    }
    return '100%';
  }, []);

  const fullPopupWidth = useMemo(() => {
    if (Platform.OS === 'web') {
      const windowWidth = Dimensions.get('window').width;
      if (windowWidth < 550) {
        return windowWidth;
      } else {
        return 500;
      }
    }
    return Dimensions.get('window').width;
  }, []);

  const pageStyle = StyleSheet.create({
    container: {
      zIndex: 100,
      width: '100%',
      height: Dimensions.get('window').height,
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      justifyContent: 'center',
      alignItems: 'center',
      alignContent: 'center',
    },
    innerContainer: {
      width: '100%',
      backgroundColor: 'white',
      flex: 1,
      marginTop: 67,
      width: fullPopupWidth,
    },
    header: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    back: {
      zIndex: 2,
    },
    title: {
      color: Colors.main,
      fontSize: 24,
      fontWeight: '600',
      position: 'absolute',
      start: 0,
      textAlign: 'center',
      width: fullPopupWidth,
      fontStyle: 'italic',
    },
    contentsContainer: {},
    contentsInnerContainer: {
      height: '100%',
    },
    date: {
      color: 'black',
      fontSize: 16,
      fontWeight: '600',
      textAlign: 'center',
    },
    locationInfo: {marginTop: 13, position: 'relative'},
    locationInfoTxt: {
      color: 'black',
      fontSize: 13,
      fontWeight: '300',
      textAlign: 'center',
    },
    teamContainer: {
      marginTop: 5,
      marginBottom: 20,
      flexDirection: 'row',
      height: 29,
      width: '100%',
      alignItems: 'center',
      alignContent: 'center',
    },
    teamLeft: {
      flexDirection: 'row',
      justifyContent: 'flex-end',
      marginTop: -8,
      position: 'relative',
      marginRight: -54,
    },
    rightMarker: {
      position: 'absolute',
      top: -13,
      left: 18,
    },
    leftMarker: {
      position: 'absolute',
      top: -13,
      right: 18,
    },
    teamRight: {
      flexDirection: 'row',
      justifyContent: 'flex-start',
      marginTop: -8,
      position: 'relative',
    },
    teamTextContainer: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      marginStart: 10,
      marginEnd: 10,
      alignItems: 'center',
    },
    teamTxt: {color: 'white', fontSize: 18, lineHeight: 18, fontWeight: '800'},
    inactive: {opacity: 0.4},
    teamVs: {
      fontSize: 23,
      fontWeight: 'bold',
      color: 'white',
      marginStart: 10,
      marginEnd: 10,
      width: 30,
      textAlign: 'center',
    },
    logoContainer: {
      width: 54,
      height: 54,
      borderRadius: 27,
      overflow: 'hidden',
      backgroundColor: 'white',
    },
    logoImg: {
      width: 54,
      height: 54,
    },
    homeAwayTxt: {
      color: 'white',
      fontSize: 14,
      marginHorizontal: 8,
      fontStyle: 'italic',
    },
    listContainer: {
      marginStart: 24,
      marginEnd: 24,
    },
    td: {
      paddingVertical: 4,
      borderEndColor: Colors.main,
      borderEndWidth: 1,
      borderBottomColor: Colors.main,
      borderBottomWidth: 1,
      textAlign: 'center',
      alignItems: 'center',
      justifyContent: 'center',
      height: 38,
    },
    tdLeftFirst: {
      borderStartColor: Colors.main,
      borderStartWidth: 1,
    },
    tdTxt: {
      display: 'flex',
      color: 'black',
      fontSize: 13,
      fontWeight: '500',
    },
    tableHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      paddingBottom: 15,
      marginTop: 25,
    },
    col0dot3: {
      flex: 0.3,
    },
    colHeader: {
      backgroundColor: Colors.main,
      color: 'white',
      paddingVertical: 5,
      fontWeight: '500',
    },
    colBattingOrder: {
      flex: 1,
      textAlign: 'center',
      borderStartWidth: 1,
      borderStartColor: Colors.main,
    },
    colPosition: {
      flex: 2,
      textAlign: 'center',
      borderStartWidth: 1,
      borderStartColor: Colors.main,
      borderEndWidth: 1,
      borderEndColor: Colors.main,
    },
    colPlayerName: {
      flex: 3,
      textAlign: 'center',
      borderStartWidth: 1,
      borderStartColor: Colors.main,
      borderEndWidth: 1,
      borderEndColor: Colors.main,
    },
    headerNumber: {
      color: 'black',
      fontSize: 13,
      fontWeight: '300',
    },
    headerTxt: {
      fontSize: 16,
      fontWeight: '600',
    },
    teamAway: {
      color: Colors.main,
    },
    teamHome: {
      color: Colors.red,
    },
    number: {
      alignItems: 'center',
      color: 'black',
      fontSize: 15,
      fontWeight: '600',
      justifyContent: 'center',
      lineHeight: 30,
    },
    pitcher: {
      fontSize: 14,
    },
    buttonContainer: {
      marginStart: 51,
      marginEnd: 51,
    },
    saveBtn: {
      flex: 1,
      height: 34,
      backgroundColor: Colors.main,
      borderRadius: 17,
      alignItems: 'center',
      alignContent: 'center',
      justifyContent: 'center',
      marginTop: 65,
      marginEnd: 8,
    },
    cancelBtn: {
      flex: 1,
      height: 34,
      backgroundColor: '#ccc',
      borderRadius: 17,
      alignItems: 'center',
      alignContent: 'center',
      justifyContent: 'center',
      marginTop: 65,
      marginStart: 8,
    },
    cancelBtnTxt: {
      color: '#555',
    },
    btnText: {
      color: 'white',
      fontSize: 18,
    },
  });

  return (
    <Modal transparent={true} overFullScreen={true}>
      <View style={[pageStyle.container]}>
        <ScrollView
          style={[
            Platform.OS === 'web' && {
              height: Dimensions.get('window').height - (insets.top + 67),
            },
          ]}
          showsVerticalScrollIndicator={Platform.OS === 'web'}>
          <View
            style={[
              pageStyle.innerContainer,
              {
                marginTop: insets.top + 67,
                height: Dimensions.get('window').height - (insets.top + 67),
              },
            ]}>
            <View style={[styles.headerCommon, pageStyle.header]}>
              <View style={[styles.row, styles.verticalAlign, pageStyle.back]}>
                <TouchableOpacity onPress={closePopup}>
                  <Image
                    source={require('../../assets/images/icon_back.png')}
                    style={[styles.headerBackButton]}
                  />
                </TouchableOpacity>
              </View>
              <Text style={[pageStyle.title]}>LINE-UP</Text>
            </View>
            <View style={[pageStyle.contentsContainer]}>
              <View style={[pageStyle.contentsInnerContainer]}>
                <Text style={[pageStyle.date]}>
                  {parseDate(data?.date)} {parseTime(data?.time)}
                </Text>

                <ImageBackground
                  resizeMode="cover"
                  style={[{height: 40, width: '100%', marginTop: 8}]}
                  imageStyle={[{width: '100%'}]}
                  source={require('../../assets/images/belt_big.png')}>
                  <View style={[pageStyle.teamContainer]}>
                    <Text style={[pageStyle.homeAwayTxt]}>AWAY</Text>
                    <TouchableOpacity
                      onPress={selectFirstBatTeam}
                      style={[styles.col, pageStyle.teamLeft]}>
                      <Image
                        style={[
                          pageStyle.leftMarker,
                          currentTeamId !== data.batFirstTeam.id && {
                            display: 'none',
                          },
                        ]}
                        source={require('../../assets/images/icn_current_team.png')}
                      />
                      <Shadow offset={[3, 4]} distance={4}>
                        <View style={[pageStyle.logoContainer]}>
                          <Image
                            style={[
                              pageStyle.logoImg,
                              !data.batFirstTeamLogoImageUrl && {
                                opacity: 0.4,
                              },
                            ]}
                            source={
                              !data?.batFirstTeamLogoImageUrl
                                ? require('../../assets/images/nologo.png')
                                : {uri: data?.batFirstTeamLogoImageUrl}
                            }
                            resizeMode={'cover'}
                          />
                        </View>
                      </Shadow>
                    </TouchableOpacity>
                    <View style={[{width: '50%'}]}>
                      <View style={[pageStyle.teamTextContainer]}>
                        <Text
                          numberOfLines={1}
                          style={[
                            pageStyle.teamTxt,
                            currentTeamId !== data.batFirstTeam.id &&
                              pageStyle.inactive,
                          ]}
                        />
                        <Text style={[pageStyle.teamVs]} />
                        <Text
                          numberOfLines={1}
                          style={[
                            pageStyle.teamTxt,
                            currentTeamId !== data.batLastTeam.id &&
                              pageStyle.inactive,
                          ]}
                        />
                      </View>
                    </View>
                    <TouchableOpacity
                      onPress={selectLastBatTeam}
                      style={[styles.col, pageStyle.teamRight]}>
                      <Image
                        style={[
                          pageStyle.rightMarker,
                          currentTeamId !== data.batLastTeam.id && {
                            display: 'none',
                          },
                        ]}
                        source={require('../../assets/images/icn_current_team.png')}
                      />
                      <Shadow offset={[3, 4]} distance={4}>
                        <View style={[pageStyle.logoContainer]}>
                          <Image
                            style={[
                              pageStyle.logoImg,
                              !data.batLastTeamLogoImageUrl && {opacity: 0.4},
                            ]}
                            source={
                              !data?.batLastTeamLogoImageUrl
                                ? require('../../assets/images/nologo.png')
                                : {uri: data?.batLastTeamLogoImageUrl}
                            }
                            resizeMode={'cover'}
                          />
                        </View>
                      </Shadow>
                    </TouchableOpacity>
                    <Text style={[pageStyle.homeAwayTxt]}>HOME</Text>
                  </View>
                </ImageBackground>
                <ImageBackground
                  resizeMode="cover"
                  style={[
                    {
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: 30,
                      marginTop: 10,
                    },
                  ]}
                  imageStyle={[{width: '100%'}]}
                  source={require('../../assets/images/belt_small.png')}>
                  <View style={[pageStyle.teamContainer]}>
                    <Text
                      numberOfLines={1}
                      style={[
                        {flex: 1, textAlign: 'center', marginTop: 12},
                        pageStyle.teamTxt,
                        currentTeamId !== data.batFirstTeam.id &&
                          pageStyle.inactive,
                      ]}>
                      {data?.batFirstTeamName}
                    </Text>
                    <Text
                      numberOfLines={1}
                      style={[
                        {flex: 1, textAlign: 'center', marginTop: 12},
                        pageStyle.teamTxt,
                        currentTeamId !== data.batLastTeam.id &&
                          pageStyle.inactive,
                      ]}>
                      {data?.batLastTeamName}
                    </Text>
                  </View>
                </ImageBackground>
                <View style={[pageStyle.locationInfo]}>
                  <Text style={[pageStyle.locationInfoTxt]}>
                    {data?.leagueName} {data?.subLeagueName}
                    {'\n'}
                    {data?.stadium?.name}
                  </Text>
                </View>
                <FlatList
                  showsVerticalScrollIndicator={false}
                  style={[
                    pageStyle.listContainer,
                    {
                      maxHeight:
                        Dimensions.get('window').height -
                        (insets.top + 67) -
                        160,
                    },
                  ]}
                  ListHeaderComponent={renderHeader}
                  ListFooterComponent={renderFooter}
                  keyExtractor={item => `item_${item.id}`}
                  data={teamLineUps}
                  renderItem={renderItem}
                />
              </View>
            </View>
          </View>
        </ScrollView>
      </View>
    </Modal>
  );
};

export default LineUpPopup;

import React, {useState, useEffect, useRef, useMemo} from 'react';
import {
  Image,
  SafeAreaView,
  ScrollView,
  Text,
  TouchableOpacity,
  View,
  StyleSheet,
  KeyboardAvoidingView,
  FlatList,
  TextInput,
  Platform,
  Dimensions,
  Alert,
  DeviceEventEmitter,
} from 'react-native';
import {Shadow} from 'react-native-shadow-2';
import styles from '../common/styles';
import AsyncStorage from '@react-native-async-storage/async-storage';
import api from '../common/api';
import {RichEditor as RichEditorWeb} from '../component/react-native-pell-rich-editor-custom';
import {actions, RichEditor, RichToolbar} from 'react-native-pell-rich-editor';
import {launchImageLibrary} from 'react-native-image-picker';
import SelectDropdown from 'react-native-select-dropdown';
import alert from '../component/alert';
const BoardWriteView = ({navigation, route}) => {
  const {leagueId, item, admin} = route.params;
  const richText = useRef();
  const accessToken = useRef();
  const [title, setTitle] = useState(item?.title);
  const [contents, setContents] = useState(item?.contents);
  const [communityType, setCommunityType] = useState(item?.type);

  navigation.setOptions({
    title: Platform.select({
      web: `유니크플레이 | ${'게시글 작성'}`,
      default: '게시글 작성',
    }),
  });

  const openImageLibrary = () => {
    launchImageLibrary(
      {
        mediaType: 'photo',
        includeBase64: true,
        maxWidth: 720,
      },
      response => {
        if (
          !response.errorCode &&
          response.assets &&
          response.assets.length > 0 &&
          response.assets[0] &&
          response.assets[0].base64
        ) {
          richText.current?.insertImage(
            'data:image/png;base64,' + response.assets[0].base64,
          );
        }
      },
    );
  };

  const getData = token => {
    // api
    //   .get(
    //     `/app-league/leagues/${item.id}/boards?type=${encodeURIComponent(
    //       '자유게시판',
    //     )}&pageNo=1&pageSize=20`,
    //     {
    //       headers: {
    //         Authorization: `Bearer ${accessToken.current}`,
    //       },
    //     },
    //   )
    //   .then(function (response) {
    //     console.log('response', response.data);
    //     setBoardPage(response.data);
    //   })
    //   .catch(function (error) {
    //     console.log(error.response);
    //     alert('요청에 실패했습니다.');
    //   });
  };

  const callWriteApi = () => {
    const body = {
      type: communityType,
      title: title,
      contents: contents,
    };
    if (item && item.id) {
      body.id = item.id;
    }
    api
      .post(`/app-league/leagues/${leagueId}/boards`, body, {
        headers: {
          Authorization: `Bearer ${accessToken.current}`,
        },
      })
      .then(function (response) {
        DeviceEventEmitter.emit('communityListRefresh', {id: response.data.id});
        alert('게시글이 등록되었습니다.');
        Platform.OS === 'web' ? history.back() : navigation.goBack();
      })
      .catch(function (error) {
        console.log(error.response);
        alert('요청에 실패했습니다.');
      });
  };

  const richTextHandle = descriptionText => {
    if (descriptionText) {
      try {
        // descriptionText = descriptionText.replaceAll(/<a.*?<\/a>/g, '$1');
        descriptionText = descriptionText.replaceAll(/<\/?a[^>]*>/g, '');
        descriptionText = descriptionText.replaceAll('&nbsp;', ' ');
        descriptionText = descriptionText.replaceAll('&nbsp', ' ');
        const replacePattern1 =
          /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;
        const replacedText = descriptionText.replace(
          replacePattern1,
          '<a href="$1" target="_blank">$1</a>',
        );
        setContents(replacedText);
      } catch (e) {
        console.error(e);
        setContents(descriptionText);
      }
    } else {
      setContents('');
    }
  };

  const handleCancel = () => {
    alert('글쓰기', '취소 하시겠습니까?', [
      {
        text: '예',
        onPress: () => {
          Platform.OS === 'web' ? history.back() : navigation.goBack();
        },
      },
      {
        text: '아니오',
      },
    ]);
  };

  const handleWrite = () => {
    alert('글쓰기', '작성 완료 하시겠습니까?', [
      {
        text: '예',
        onPress: () => {
          if (!communityType || !title || !contents) {
            alert('필수 값을 입력해 주세요.');
            return;
          }
          callWriteApi();
        },
      },
      {
        text: '아니오',
      },
    ]);
  };

  useEffect(() => {
    AsyncStorage.getItem('@accessToken', (err, result) => {
      accessToken.current = result;
      getData(result);
    });
  }, []);

  const boardTypeList = useMemo(() => {
    if (admin) {
      return ['리그 공지사항', '자유게시판', '기록수정게시판'];
    }
    return ['자유게시판', '기록수정게시판'];
  }, [admin]);

  return (
    <>
      <SafeAreaView nativeID="common-page-container" style={[styles.container]}>
        <Shadow
          offset={[0, 2]}
          style={[{width: '100%'}]}
          containerStyle={[{marginTop: 0}]}
          distance={7}
          sides={{bottom: true, top: false, start: false, end: false}}>
          <View style={[styles.headerCommon]}>
            <View style={[styles.row, styles.verticalAlign]}>
              <TouchableOpacity
                onPress={() =>
                  Platform.OS === 'web' ? history.back() : navigation.goBack()
                }>
                <Image
                  source={require('../assets/images/icon_back.png')}
                  style={[styles.headerBackButton]}
                />
              </TouchableOpacity>
              <Text style={[styles.headerText]}>글쓰기</Text>
            </View>
            <View style={[styles.row, styles.verticalAlign]}>
              <Image source={require('../assets/images/icon_uniqueplay.png')} />
            </View>
          </View>
        </Shadow>
        <KeyboardAvoidingView
          behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
          style={{flex: 1}}>
          <ScrollView
            style={[
              {
                marginTop: 10,
              },
            ]}
            nestedScrollEnabled={true}>
            <View
              behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
              style={{flex: 1}}>
              <View style={[pageStyle.dropDownComponent]}>
                <SelectDropdown
                  defaultButtonText="게시판 선택"
                  buttonStyle={pageStyle.dropDown}
                  buttonTextStyle={pageStyle.downDownText}
                  rowTextStyle={styles.dropDownRowText}
                  renderDropdownIcon={() => (
                    <Image
                      source={require('../assets/images/select_arr.png')}
                    />
                  )}
                  defaultValue={communityType}
                  data={boardTypeList}
                  onSelect={(selectedItem, index) => {
                    setCommunityType(selectedItem);
                  }}
                />
              </View>
              <TextInput
                placeholder={'제목'}
                placeholderTextColor={'#aaa'}
                style={[pageStyle.titleInput]}
                defaultValue={title}
                onChangeText={value => setTitle(value)}
              />
              {Platform.OS !== 'web' && (
                <View>
                  <RichEditor
                    initialContentHTML={contents}
                    androidHardwareAccelerationDisabled={true}
                    useContainer={false}
                    onChange={richTextHandle}
                    containerStyle={{
                      minHeight: Dimensions.get('window').height - 250,
                    }}
                    scrollEnabled={true}
                    placeholder={'내용'}
                    placeholderTextColor={'#aaa'}
                    style={[pageStyle.editor]}
                    ref={richText}
                  />
                </View>
              )}
              {Platform.OS === 'web' && (
                <View>
                  <RichEditorWeb
                    initialContentHTML={contents}
                    androidHardwareAccelerationDisabled={true}
                    useContainer={false}
                    onChange={richTextHandle}
                    initialHeight={Dimensions.get('window').height - 250}
                    contentContainerStyle={{
                      flexGrow: 1,
                    }}
                    scrollEnabled={true}
                    placeholder={'내용을 입력하세요.'}
                    placeholderTextColor={'#aaa'}
                    style={[
                      pageStyle.editor,
                      {
                        minHeight: Dimensions.get('window').height - 250,
                      },
                    ]}
                    ref={richText}
                  />
                </View>
              )}
            </View>
          </ScrollView>
          <RichToolbar
            editor={richText}
            actions={[
              actions.insertImage,
              actions.setBold,
              actions.setItalic,
              actions.insertBulletsList,
              actions.insertOrderedList,
            ]}
            onPressAddImage={() => openImageLibrary()}
            iconMap={{
              [actions.heading1]: ({tintColor}) => (
                <Text style={[{color: tintColor}]}>H1</Text>
              ),
            }}
          />
          <View style={[pageStyle.buttonContainer]}>
            <TouchableOpacity
              onPress={handleCancel}
              style={[pageStyle.buttonItem, pageStyle.cancelBtn]}>
              <Text style={[pageStyle.buttonItemTxt, pageStyle.cancelItemTxt]}>
                취소
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={handleWrite}
              style={[pageStyle.buttonItem, pageStyle.commonBtn]}>
              <Text style={[pageStyle.buttonItemTxt]}>글쓰기</Text>
            </TouchableOpacity>
          </View>
        </KeyboardAvoidingView>
      </SafeAreaView>
    </>
  );
};

export default BoardWriteView;

const pageStyle = StyleSheet.create({
  headerCommon: {},
  container: {
    borderWidth: 5,
    borderColor: '#ddd',
    opacity: 0.8,
  },
  titleInput: {
    height: 40,
    fontSize: 16,
    fontFamily: 'Pretendard',
    paddingHorizontal: 10,
    borderBottomColor: '#ddd',
    borderBottomWidth: 1,
    color: 'black',
  },
  buttonContainer: {
    flexDirection: 'row',
    marginBottom: 20,
  },
  buttonItem: {
    flex: 1,
    height: 30,
    alignItems: 'center',
    alignContent: 'center',
  },
  buttonItemTxt: {
    textAlign: 'center',
    fontSize: 13,
    fontFamily: 'Pretendard',
    color: 'white',
  },
  cancelItemTxt: {
    color: '#555',
  },
  commonBtn: {
    marginTop: 25,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 17,
    height: 35,
    marginHorizontal: 15,
    backgroundColor: 'rgb(61, 80, 183)',
  },
  cancelBtn: {
    marginTop: 25,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 17,
    height: 35,
    marginHorizontal: 15,
    backgroundColor: '#ccc',
  },
  editor: {},
  writeBtnContainer: {
    position: 'absolute',
    bottom: 20,
    right: 20,
  },
  writeIcn: {
    width: 80,
    height: 80,
  },
  scrollViewContainer: {},
  titleContainer: {
    paddingHorizontal: 12,
    paddingVertical: 5,
    borderBottomWidth: 1,
    borderBottomColor: 'rgba(0, 0, 0, 0.15)',
  },
  title: {color: 'black', fontSize: 13, fontFamily: 'Pretendard'},
  list: {
    width: '100%',
    display: 'flex',
    marginTop: 6,
  },
  dropDownComponent: {
    marginHorizontal: 12,
  },
  dropDown: {
    width: '100%',
    backgroundColor: 'white',
    borderWidth: 1,
    borderColor: 'rgb(61, 80, 183)',
    height: 32,
  },
  downDownText: {
    color: 'black',
    fontSize: 14,
    fontFamily: 'Pretendard',
  },
});

import {
  Image,
  Platform,
  SafeAreaView,
  ScrollView,
  StyleSheet,
  Text,
  View,
} from 'react-native';
import {TouchableOpacity} from 'react-native-gesture-handler';
import {Shadow} from 'react-native-shadow-2';
import styles from '../common/styles';
import {useSafeAreaInsets} from 'react-native-safe-area-context';
import Colors from '../common/colors';

const FaqView = ({navigation}) => {
  const insets = useSafeAreaInsets();

  const pageStyle = StyleSheet.create({
    headerCommon: {},
    container: {
      backgroundColor: 'white',
      paddingHorizontal: 18,
      paddingTop: 24,
      paddingBottom: 72 + insets.bottom,
    },
    subtitle: {
      color: 'black',
      fontSize: 20,
      fontWeight: '600',
      fontFamily: 'Pretendard-SemiBold',
    },
    subtitle2: {
      color: Colors.main,
      fontSize: 18,
      fontWeight: '500',
      marginTop: 8,
      fontFamily: 'Pretendard-Medium',
      marginStart: 12,
    },
    text: {
      color: 'black',
      fontSize: 16,
      fontFamily: 'Pretendard',
      marginTop: 16,
      marginBottom: 18,
      marginStart: 24,
    },
  });

  return (
    <SafeAreaView nativeID="common-page-container">
      <Shadow
        offset={[0, 2]}
        style={[{width: '100%'}]}
        containerStyle={[{marginTop: 0}]}
        distance={7}
        sides={{bottom: true, top: false, start: false, end: false}}>
        <View style={[styles.headerCommon]}>
          <View style={[styles.row, styles.verticalAlign]}>
            <TouchableOpacity
              onPress={() =>
                Platform.OS === 'web' ? history.back() : navigation.goBack()
              }>
              <Image
                source={require('../assets/images/icon_back.png')}
                style={[styles.headerBackButton]}
              />
            </TouchableOpacity>
            <Text style={[styles.headerText]}>FAQ</Text>
          </View>
          <View style={[styles.row, styles.verticalAlign]} />
        </View>
      </Shadow>
      <ScrollView>
        <View style={[pageStyle.container]}>
          <Text style={[pageStyle.subtitle]}>• 선수</Text>
          <Text style={[pageStyle.subtitle2]}>◦ 팀 찾기</Text>
          <Text style={[pageStyle.text]}>
            1. 하단의 팀에서 팀 찾기를 통해서 확인할 수 있어요.
          </Text>
          <Text style={[pageStyle.subtitle2]}>◦ 팀 가입 신청</Text>
          <Text style={[pageStyle.text]}>
            1. 해당 팀 페이지 우측 상단에 가입 신청 클릭!{'\n'}
            2. 해당 팀의 마스터 혹은 감독님이 승인을 해주시면 팀 가입이
            완료됩니다!
          </Text>
          <Text style={[pageStyle.subtitle]}>• 팀 마스터</Text>
          <Text style={[pageStyle.subtitle2]}>◦ 팀 관리</Text>
          <Text style={[pageStyle.text]}>
            1. 해당 팀 페이지 우측 상단에 팀 관리 클릭!{'\n'}
            2. 팀 정보를 수정 후 “저장하기”를 누르면 수정할 수 있어요!
          </Text>
          <Text style={[pageStyle.subtitle2]}>◦ 선수 등번호, 등급 수정</Text>
          <Text style={[pageStyle.text]}>
            1. 해당 팀 페이지 우측 상단에 “팀 관리” 클릭!{'\n'}
            2. 우측 상단에 “회원관리” 클릭!{'\n'}
            3. 팀원의 등급과 배번을 수정 후 “저장”을 누르면 수정할 수 있어요!
          </Text>
          <Text style={[pageStyle.subtitle2]}>◦ 선수 탈퇴 시키기</Text>
          <Text style={[pageStyle.text]}>
            1. 해당 팀 페이지 우측 상단에 “팀 관리” 클릭!{'\n'}
            2. 우측 상단에 “회원관리” 클릭!{'\n'}
            3. 탈퇴 시킬 팀원을 왼쪽으로 스와이프!{'\n'}
            4. “삭제” 를 누르면 팀원을 탈퇴 시킬 수 있어요!
          </Text>
          <Text style={[pageStyle.subtitle2]}>◦ 선수 가입 승인</Text>
          <Text style={[pageStyle.text]}>
            1. 해당 팀 페이지 우측 상단에 “팀 관리” 클릭!{'\n'}
            2. 우측 상단에 “회원관리” 클릭!{'\n'}
            3. 우측 상단에 “가입승인” 클릭!{'\n'}
            4. 가입을 승인할 선수 이름 우측 “승인”을 누르면 가입을 승인할 수
            있어요!
          </Text>
          <Text style={[pageStyle.subtitle2]}>◦ 리그 참가 신청</Text>
          <Text style={[pageStyle.text]}>
            1. 해당 리그 페이지 우측 상단에 “참가신청” 클릭!{'\n'}
            2. 참가를 원하는 시즌, 팀을 선택 후 “신청서 제출”을 누르면 참가
            신청을 할 수 있어요!
          </Text>
          <Text style={[pageStyle.subtitle]}>• 관심 팀/선수</Text>
          <Text style={[pageStyle.subtitle2]}>◦ 관심 팀 추가, 삭제</Text>
          <Text style={[pageStyle.text]}>
            1. 하단의 팀 에서 관심 팀을 검색!{'\n'}
            2. 해당 팀 페이지 우측 상단에 “팀 로고” 클릭!{'\n'}
            3. 우측 상단에 “관심 팀”을 클릭하면 관심팀을 추가 할 수 있어요!
            {'\n'}
            4. 다시 클릭하면 관심팀을 삭제를 할 수 있어요.
          </Text>
          <Text style={[pageStyle.subtitle2]}>◦ 관심 선수 추가, 삭제</Text>
          <Text style={[pageStyle.text]}>
            1. 하단의 팀 에서 관심 선수를 검색!{'\n'}
            2. 우측 상단에 “관심선수”를 클릭하면 관심선수를 추가 할 수 있어요!
            {'\n'}
            3. 다시 클릭하면 관심선수를 삭제를 할 수 있어요.
          </Text>
        </View>
      </ScrollView>
    </SafeAreaView>
  );
};

export default FaqView;

import React, {
  Image,
  StyleSheet,
  Text,
  View,
  TextInput,
  TouchableOpacity,
} from 'react-native';
import Colors from '../common/colors';
import styles from '../common/styles';
import moment from 'moment';
import 'moment/locale/ko';
import {useMemo} from 'react';

const BoardItem = props => {
  const profileImageUrl = useMemo(() => {
    if (props.data.user?.profileImageThumbUrl) {
      return {uri: props.data.user?.profileImageThumbUrl};
    }
    if (props.data.user?.profileImageUrl) {
      return {uri: props.data.user?.profileImageUrl};
    }
    return require('../assets/images/icn_profile_img.png');
  }, [props.data.user?.profileImageThumbUrl, props.data.user?.profileImageUrl]);

  return (
    <View style={[pageStyle.itemContainer]}>
      <View style={[styles.row]}>
        {props.data.type === '리그 공지사항' && (
          <Text style={[pageStyle.title, pageStyle.notice]}>[공지]</Text>
        )}
        <Text
          style={[
            pageStyle.title,
            props.data.type === '리그 공지사항' && pageStyle.noticeType,
            props.data.fixed && {fontWeight: 'bold'},
          ]}>
          {props.data.title}
        </Text>
      </View>
      <View style={[styles.row, pageStyle.row]}>
        <View style={[styles.row, pageStyle.center]}>
          <Image
            style={[
              {
                width: 30,
                height: 30,
                resizeMode: 'cover',
                borderRadius: 15,
              },
            ]}
            source={profileImageUrl}
          />
          <Text style={[pageStyle.label]}>{props.data.user?.name}</Text>
        </View>
        <View style={[styles.row, pageStyle.center]}>
          <Text style={[pageStyle.label]}>조회수</Text>
          <Text style={[pageStyle.hit]}>{props.data.hitCount}</Text>
        </View>
        <View style={[pageStyle.center]}>
          <Text style={[pageStyle.label]}>
            {moment(
              props.data.createdAt.substring(
                0,
                props.data.createdAt.indexOf('T'),
              ),
              'YYYY-MM-DD',
            ).format('YYYY.MM.DD')}
          </Text>
        </View>
      </View>
    </View>
  );
};
export default BoardItem;
const pageStyle = StyleSheet.create({
  itemContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    paddingVertical: 13,
    paddingHorizontal: 21,
    paddingEnd: 40,
    borderBottomWidth: 1,
    borderBottomColor: 'rgba(0, 0, 0, 0.2)',
  },
  noticeType: {
    fontWeight: 'bold',
    lineHeight: 15,
    fontFamily: 'Pretendard-Bold',
  },
  center: {alignItems: 'center'},
  row: {alignItems: 'center', marginTop: 8, justifyContent: 'space-between'},
  title: {color: 'black', fontSize: 12, fontFamily: 'Pretendard'},
  label: {
    color: 'black',
    fontFamily: 'Pretendard',
    fontSize: 11,
    marginHorizontal: 6,
  },
  hit: {color: 'black', fontFamily: 'Pretendard', fontSize: 11},
  notice: {
    color: Colors.main,
    fontFamily: 'Pretendard-Bold',
    fontWeight: 'bold',
    lineHeight: 15,
    paddingEnd: 4,
  },
});

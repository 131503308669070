import React, {useState, useEffect, useRef} from 'react';
import {
  Image,
  SafeAreaView,
  Text,
  TouchableOpacity,
  View,
  StyleSheet,
  ImageBackground,
  Dimensions,
  Platform,
} from 'react-native';
import styles from '../common/styles';
import RecentGameList from '../component/team/RecentGameList';
import GameList from '../component/team/GameList';
import TeamInfoPopup from '../component/team/TeamInfoPopup';
import TeamApplyPopup from '../component/team/TeamApplyPopup';
import TeamLeavePopup from '../component/team/TeamLeavePopup';
import TeamManagementPopup from '../component/team/TeamManagementPopup';
import MemberList from '../component/team/MemberList';
import TeamRecord from '../component/team/TeamRecord';
import AsyncStorage from '@react-native-async-storage/async-storage';
import api from '../common/api';
import Colors from '../common/colors';
import {title} from '../common/util';

const TeamDetailView = ({navigation, route}) => {
  let accessToken = useRef();
  const tabList = [
    {index: 0, text: '최근경기'},
    {index: 1, text: '일정/결과'},
    {index: 2, text: '선수명단'},
    {index: 3, text: '팀기록'},
  ];
  const {item, itemId} = route.params;
  const [teamData, setTeamData] = useState(item);
  const [myGrade, setMyGrade] = useState();
  const [scheduleMenuOpened, setScheduleMenuOpened] = useState(false);
  const [scheduleType, setScheduleType] = useState();

  const getTeamData = () => {
    api
      .get(`/app-team/teams/${itemId}`, {
        headers: {
          Authorization: `Bearer ${accessToken.current}`,
        },
      })
      .then(function (response) {
        setTeamData(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getData = () => {
    api
      .get(`/app-team/teams/${itemId ? itemId : item?.id}`, {
        headers: {
          Authorization: `Bearer ${accessToken.current}`,
        },
      })
      .then(function (response) {
        setTeamData(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
    api
      .get(`/teams/${itemId ? itemId : item?.id}/my-grade`, {
        headers: {
          Authorization: `Bearer ${accessToken.current}`,
        },
      })
      .then(function (response) {
        setMyGrade(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const selectTabIndex = index => {
    if (index === 1) {
      setScheduleMenuOpened(true);
    }
    setTabIndex(index);
  };

  const refreshData = () => {
    getData(accessToken.current);
  };

  useEffect(() => {
    AsyncStorage.getItem('@accessToken', (err, result) => {
      accessToken.current = result;
      getTeamData(result);
    });
  }, [itemId]);

  useEffect(() => {
    AsyncStorage.getItem('@accessToken', (err, result) => {
      accessToken.current = result;
      getData(result);
    });
  }, [item?.id]);

  const [tabIndex, setTabIndex] = useState(0);
  const [managePopupOpened, setManagePopupOpened] = useState(false);
  const [applyPopupOpened, setApplyPopupOpened] = useState(false);
  const [teamInfoPopupOpened, setTeamInfoPopupOpened] = useState(false);
  const [leavePopupOpened, setLeavePopupOpened] = useState(false);

  const getLogoImageUrl = item => {
    if (item?.logoImageThumbUrl) {
      return {uri: item?.logoImageThumbUrl};
    }
    if (item?.logoImageUrl) {
      return {uri: item?.logoImageUrl};
    }
    return require('../assets/images/nologo.png');
  };

  return (
    <SafeAreaView nativeID="common-page-container" style={[styles.container]}>
      <View
        style={[
          styles.headerCommon,
          pageStyle.headerCommon,
          Platform.OS === 'web' && {paddingStart: 0},
        ]}>
        <ImageBackground
          resizeMode={'cover'}
          source={require('../assets/images/nav_team_bg.png')}
          style={[pageStyle.navBgImage]}
        />
        <View
          style={[
            styles.row,
            styles.verticalAlign,
            Platform.OS === 'web' && {marginStart: 18},
          ]}>
          <TouchableOpacity
            onPress={() =>
              Platform.OS === 'web' ? history.back() : navigation.goBack()
            }>
            <Image
              resizeMode="cover"
              source={require('../assets/images/icon_back_wh.png')}
              style={[styles.headerBackButton]}
            />
          </TouchableOpacity>
          <Text style={[styles.headerText, pageStyle.headerText]}>
            {teamData?.name}
          </Text>
        </View>
        <View style={[styles.row, styles.verticalAlign]}>
          {(myGrade === '팀마스터' || myGrade === '감독') && (
            <TouchableOpacity
              style={[pageStyle.manageTeamBtn]}
              onPress={() => setManagePopupOpened(true)}>
              <Text style={[pageStyle.manageTeamBtnTxt]}>팀 관리</Text>
            </TouchableOpacity>
          )}
          {/* {myGrade === '선수' && !!myGrade && (
            <TouchableOpacity
              style={[pageStyle.manageTeamBtn]}
              onPress={() => setLeavePopupOpened(true)}>
              <Text style={[pageStyle.manageTeamBtnTxt]}>팀 탈퇴</Text>
            </TouchableOpacity>
          )} */}
          {!myGrade && (
            <TouchableOpacity
              style={[pageStyle.manageTeamBtn]}
              onPress={() => setApplyPopupOpened(true)}>
              <Text style={[pageStyle.manageTeamBtnTxt]}>가입신청</Text>
            </TouchableOpacity>
          )}
          {myGrade === '신청 대기 중' && (
            <TouchableOpacity style={[pageStyle.manageTeamBtn]}>
              <Text
                style={[
                  pageStyle.manageTeamBtnTxt,
                  {fontSize: 9.5, fontFamily: 'Pretendard'},
                ]}
                numberOfLines={1}>
                승인 대기중
              </Text>
            </TouchableOpacity>
          )}
          <TouchableOpacity onPress={() => setTeamInfoPopupOpened(true)}>
            <Image
              style={[
                styles.headerLogoImg,
                !teamData?.logoImageUrl && {opacity: 0.4},
              ]}
              source={getLogoImageUrl(teamData)}
            />
          </TouchableOpacity>
        </View>
      </View>
      <View style={[Platform.OS !== 'web' && pageStyle.container]}>
        <View style={[styles.tabContainer]}>
          {tabList.map(tab => (
            <TouchableOpacity
              onPress={() => selectTabIndex(tab.index)}
              style={[
                tabIndex === tab.index && styles.tabItemSelected,
                tabIndex !== tab.index && styles.tabItem,
              ]}>
              <Text
                style={[
                  tabIndex === tab.index && styles.tabItemTextSelected,
                  tabIndex !== tab.index && styles.tabItemText,
                ]}>
                {tab.text}
              </Text>
            </TouchableOpacity>
          ))}
        </View>
        {tabIndex === 0 && teamData && (
          <RecentGameList
            teamData={teamData}
            myGrade={myGrade}
            navigation={navigation}
          />
        )}
        {tabIndex === 1 && teamData && (
          <>
            <GameList
              teamData={teamData}
              myGrade={myGrade}
              navigation={navigation}
              scheduleType={scheduleType}
            />
            {scheduleMenuOpened && (
              <View style={[pageStyle.communityMenuContainer]}>
                <TouchableOpacity
                  onPress={() => {
                    setScheduleType('PENDING');
                    setScheduleMenuOpened(false);
                  }}
                  style={[pageStyle.communityItemMenuContainer]}>
                  <Text style={[pageStyle.communityItemMenuTxt]}>게임일정</Text>
                </TouchableOpacity>
                <TouchableOpacity
                  onPress={() => {
                    setScheduleType('COMPLETED');
                    setScheduleMenuOpened(false);
                  }}
                  style={[pageStyle.communityItemMenuContainer]}>
                  <Text style={[pageStyle.communityItemMenuTxt]}>게임결과</Text>
                </TouchableOpacity>
                <TouchableOpacity
                  onPress={() => {
                    setScheduleType('ALL');
                    setScheduleMenuOpened(false);
                  }}
                  style={[pageStyle.communityItemMenuContainer]}>
                  <Text style={[pageStyle.communityItemMenuTxt]}>전체보기</Text>
                </TouchableOpacity>
              </View>
            )}
          </>
        )}
        {tabIndex === 2 && teamData && (
          <MemberList
            teamData={teamData}
            myGrade={myGrade}
            navigation={navigation}
          />
        )}
        {tabIndex === 3 && teamData && (
          <TeamRecord
            teamData={teamData}
            myGrade={myGrade}
            navigation={navigation}
          />
        )}
      </View>
      {managePopupOpened && teamData && (
        <TeamManagementPopup
          teamData={teamData}
          myGrade={myGrade}
          closePopup={() => {
            refreshData();
            setManagePopupOpened(false);
          }}
          navigation={navigation}
        />
      )}
      {applyPopupOpened && (
        <TeamApplyPopup
          teamData={teamData}
          closePopup={() => {
            refreshData();
            setApplyPopupOpened(false);
          }}
          navigation={navigation}
        />
      )}
      {teamInfoPopupOpened && (
        <TeamInfoPopup
          teamData={teamData}
          closePopup={() => setTeamInfoPopupOpened(false)}
          navigation={navigation}
        />
      )}
      {leavePopupOpened && (
        <TeamLeavePopup
          teamData={teamData}
          closePopup={() => setLeavePopupOpened(false)}
          navigation={navigation}
        />
      )}
    </SafeAreaView>
  );
};

const pageStyle = StyleSheet.create({
  headerCommon: {},
  navBgImage: {
    marginStart: 0,
    position: 'absolute',
    width: Platform.OS === 'web' ? '100%' : Dimensions.get('window').width,
    height: '100%',
  },
  headerText: {color: 'white'},
  manageTeamBtn: {
    width: 60,
    height: 22,
    borderRadius: 12,
    borderWidth: 1,
    borderColor: 'white',
    backgroundColor: 'transparent',
    justifyContent: 'center',
    alignItems: 'center',
    marginEnd: 14,
  },
  manageTeamBtnTxt: {
    color: 'white',
    fontSize: 11,
    fontFamily: 'Pretendard',
  },
  container: {
    paddingBottom: 80,
  },
  communityMenuContainer: {
    position: 'absolute',
    top: 45,
    width: '100%',
  },
  communityItemMenuContainer: {
    backgroundColor: Colors.main,
    opacity: 0.9,
    paddingVertical: 12,
    borderTopWidth: 1,
    borderTopColor: 'rgba(255, 255, 255, 0.4)',
  },
  communityItemMenuTxt: {
    color: 'white',
    fontSize: 14,
    fontFamily: 'Pretendard',
    textAlign: 'center',
  },
});

export default TeamDetailView;

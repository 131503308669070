import React, {useState, useRef, useEffect} from 'react';
import {
  SafeAreaView,
  StyleSheet,
  View,
  Dimensions,
  DeviceEventEmitter,
  Text,
  Image,
  TouchableOpacity,
  FlatList,
  Platform,
} from 'react-native';
import CheckBox from '@react-native-community/checkbox';
import styles from '../common/styles';
import Colors from '../common/colors';
import ApproveMemberItem from '../component/team/ApproveMemberItem';
import AsyncStorage from '@react-native-async-storage/async-storage';
import api from '../common/api';
import * as Progress from 'react-native-progress';
import Checkbox from 'expo-checkbox';
import {title} from '../common/util';

const MemberApproveListView = ({props, navigation, route}) => {
  const {itemId} = route.params;
  const [progressShown, setProgressShown] = useState(false);
  const [memberPage, setMemberPage] = useState({});
  const [checkedAll, setCheckedAll] = useState(false);
  let accessToken = useRef();
  let memberPageLoading = useRef();

  const getData = token => {
    setProgressShown(true);
    api
      .get(`/teams/${itemId}/member-requests`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(function (response) {
        console.log(response.data);
        setMemberPage(response.data);
      })
      .catch(function (error) {
        console.log(error);
      })
      .finally(() => {
        setProgressShown(false);
      });
  };

  const refreshData = () => {
    getData(accessToken.current);
    DeviceEventEmitter.emit('memberListRefresh', {});
  };

  useEffect(() => {
    AsyncStorage.getItem('@accessToken', (err, result) => {
      accessToken.current = result;
      getData(result);
    });
  }, []);

  const handleMemberPageLoadMore = () => {
    if (memberPageLoading.current) {
      return;
    }
    if (
      memberPage.currentPage &&
      memberPage.totalPage <= memberPage.currentPage
    ) {
      return;
    }
    setProgressShown(true);
    memberPageLoading.current = true;
    api
      .get(
        `/teams/${itemId}/member-requests?pageNo=${
          memberPage.currentPage ? parseInt(memberPage.currentPage, 10) + 1 : 1
        }&pageSize=20`,
        {
          headers: {
            Authorization: `Bearer ${accessToken.current}`,
          },
        },
      )
      .then(function (response) {
        response.data.items = [
          ...(memberPage.items ? memberPage.items : []),
          ...response.data.items,
        ];
        setMemberPage(response.data);
      })
      .catch(function (error) {
        console.log(error);
      })
      .finally(() => {
        setProgressShown(false);
        memberPageLoading.current = false;
      });
  };
  const [selectedList, setSelectedList] = useState([]);

  const checkAll = checked => {
    setCheckedAll(checked);
    if (checked) {
      setSelectedList(memberPage.items.map(i => i.id));
    } else {
      setSelectedList([]);
    }
  };

  const handleCheck = (id, checked) => {
    if (!selectedList) {
      setSelectedList([]);
    }
    if (checked && !selectedList.includes(id)) {
      selectedList.push(id);
    } else {
      setSelectedList(selectedList.filter(i => i !== id));
    }
    console.log(selectedList);
  };

  const approve = () => {
    if (selectedList.length === 0) {
      alert('선택된 선수가 없습니다.');
      return;
    }
    api
      .post(
        '/teams/member/approve',
        {requestId: selectedList},
        {
          headers: {
            Authorization: `Bearer ${accessToken.current}`,
          },
        },
      )
      .then(function (response) {
        console.log(response.data, response.headers);
        setSelectedList([]);
        refreshData();
        alert('요청에 성공했습니다.');
      })
      .catch(function (error) {
        console.log(error.response);
        alert('요청에 실패했습니다.');
      });
  };

  const deny = () => {
    if (selectedList.length === 0) {
      alert('선택된 선수가 없습니다.');
      return;
    }
    api
      .post(
        '/teams/member/deny',
        {requestId: selectedList},
        {
          headers: {
            Authorization: `Bearer ${accessToken.current}`,
          },
        },
      )
      .then(function (response) {
        console.log(response.data, response.headers);
        setSelectedList([]);
        refreshData();
        alert('요청에 성공했습니다.');
      })
      .catch(function (error) {
        console.log(error.response);
        alert('요청에 실패했습니다.');
      });
  };
  return (
    <SafeAreaView nativeID="common-page-container" style={[pageStyle.safeArea]}>
      <View style={[pageStyle.container]}>
        <View style={[pageStyle.innerContainer]}>
          <View style={[styles.headerCommon, pageStyle.header]}>
            <View style={[styles.row, styles.verticalAlign, pageStyle.back]}>
              <TouchableOpacity
                onPress={() =>
                  Platform.OS === 'web' ? history.back() : navigation.goBack()
                }>
                <Image
                  source={require('../assets/images/icon_back.png')}
                  style={[styles.headerBackButton]}
                />
              </TouchableOpacity>
            </View>
            <Text style={[pageStyle.title]}>가입 승인</Text>
          </View>
          <View style={[pageStyle.contentsContainer]}>
            <View style={[pageStyle.list]}>
              <Text style={[pageStyle.summary]}>
                총 {memberPage.totalCount}명
              </Text>
              <View style={[styles.row, pageStyle.tableHeader]}>
                <Text
                  style={[
                    {
                      width: 30,
                      textAlign: 'left',
                      alignItems: 'flex-start',
                    },
                  ]}>
                  <View
                    style={[
                      {
                        height: 25,
                        width: 25,
                        paddingVertical: 4,
                        textAlign: 'left',
                        flexDirection: 'row',
                        alignItems: 'flex-start',
                        alignContent: 'flex-start',
                        alignSelf: 'flex-start',
                      },
                    ]}>
                    {Platform.OS !== 'web' && (
                      <CheckBox
                        value={checkedAll}
                        boxType={'square'}
                        style={[{height: 25, width: 25}]}
                        onValueChange={newValue => checkAll(newValue)}
                      />
                    )}
                    {Platform.OS === 'web' && (
                      <Checkbox
                        style={[{height: 25, width: 25}]}
                        value={checkedAll}
                        onValueChange={newValue => checkAll(newValue)}
                        color={checkedAll ? Colors.main : '#ddd'}
                      />
                    )}
                  </View>
                </Text>
                <Text style={[styles.col, pageStyle.th]}>이름</Text>
                <Text style={[pageStyle.thGender, pageStyle.th]}>성별</Text>
                <Text style={[pageStyle.thAge]}>나이</Text>
                <Text style={[styles.col3, pageStyle.th]}>연락처</Text>
                <Text style={[styles.col2, pageStyle.th]}>승인여부</Text>
              </View>
            </View>
            <FlatList
              ListFooterComponent={
                <View>
                  {/* <PagingComponent style={[{marginTop: 13}]} /> */}
                  <View style={[styles.row, {marginHorizontal: 22}]}>
                    <TouchableOpacity
                      onPress={approve}
                      style={[styles.col, pageStyle.saveBtn]}>
                      <Text style={[pageStyle.btnText]}>선택승인</Text>
                    </TouchableOpacity>
                    <TouchableOpacity
                      onPress={deny}
                      style={[styles.col, pageStyle.cancelBtn]}>
                      <Text style={[pageStyle.btnText, pageStyle.cancelBtnTxt]}>
                        선택거절
                      </Text>
                    </TouchableOpacity>
                  </View>
                </View>
              }
              onEndReachedThreshold={1}
              onEndReached={handleMemberPageLoadMore}
              style={[pageStyle.list]}
              keyExtractor={item => `member_${item.id.toString()}`}
              data={memberPage.items}
              ListEmptyComponent={() => (
                <View style={[styles.emptyContainer]}>
                  <Image source={require('../assets/images/logo2.png')} />
                </View>
              )}
              renderItem={({item}) => (
                <ApproveMemberItem
                  checked={selectedList.includes(item.id)}
                  data={item}
                  handleCheck={handleCheck}
                  refreshData={refreshData}
                  accessToken={accessToken.current}
                />
              )}
            />
          </View>
        </View>
      </View>
      {progressShown && (
        <View style={[styles.progressContainer]}>
          <Progress.Circle
            fill="transparent"
            size={70}
            borderWidth={3}
            indeterminate={true}
          />
        </View>
      )}
    </SafeAreaView>
  );
};

const pageStyle = StyleSheet.create({
  safeArea: {
    backgroundColor: 'white',
    flex: 1,
  },
  container: {
    flex: 1,
    zIndex: 100,
    width: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  innerContainer: {
    width: '100%',
    backgroundColor: 'white',
    flex: 1,
  },
  summary: {
    marginTop: 30,
    paddingStart: 20,
    color: 'black',
    fontFamily: 'Pretendard-SemiBold',
    fontSize: 12,
    fontWeight: '600',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  back: {
    zIndex: 2,
  },
  tableHeader: {
    marginTop: 13,
    borderBottomWidth: 1,
    borderBottomColor: 'rgba(0, 0, 0, 0.15)',
    paddingBottom: 10,
  },
  th: {
    textAlign: 'center',
    fontFamily: 'Pretendard-Light',
    fontSize: 10,
    fontWeight: '300',
    color: 'black',
  },
  thGender: {
    width: 30,
    textAlign: 'center',
    fontFamily: 'Pretendard-Light',
    fontSize: 10,
    fontWeight: '300',
    color: 'black',
  },
  thAge: {
    width: 30,
    textAlign: 'center',
    fontFamily: 'Pretendard-Light',
    fontSize: 10,
    fontWeight: '300',
    color: 'black',
  },
  title: {
    color: Colors.main,
    fontFamily: 'Pretendard-SemiBold',
    fontSize: 24,
    fontWeight: '600',
    position: 'absolute',
    start: 0,
    textAlign: 'center',
    width: Platform.OS === 'web' ? 550 : Dimensions.get('window').width,
  },
  imageInfoTxt: {
    color: '#ff0000',
    fontFamily: 'Pretendard-Medium',
    fontSize: 10,
    fontWeight: '500',
    marginTop: 5,
  },
  list: {
    paddingHorizontal: 22,
  },
  contentsContainer: {
    flex: 1,
  },
  saveBtn: {
    flex: 1,
    height: 34,
    backgroundColor: Colors.main,
    borderRadius: 17,
    alignItems: 'center',
    alignContent: 'center',
    justifyContent: 'center',
    marginTop: 65,
    marginEnd: 8,
  },
  cancelBtn: {
    flex: 1,
    height: 34,
    backgroundColor: '#ccc',
    borderRadius: 17,
    alignItems: 'center',
    alignContent: 'center',
    justifyContent: 'center',
    marginTop: 65,
    marginStart: 8,
  },
  cancelBtnTxt: {
    color: '#555',
  },
  btnText: {
    color: 'white',
    fontFamily: 'Pretendard',
    fontSize: 18,
  },
  newTeamIcn: {
    width: 162,
    height: 162,
  },
  teamCreatedTxt: {
    color: '#3f51b5',
    fontFamily: 'Pretendard-Medium',
    fontSize: 21,
    fontWeight: '600',
    marginTop: 28,
  },
  moveBtn: {
    marginTop: 57,
    backgroundColor: '#3f51b5',
    paddingVertical: 8,
    paddingHorizontal: 24,
    borderRadius: 17,
  },
  moveBtnTxt: {
    color: 'white',
    fontFamily: 'Pretendard',
    fontSize: 18,
  },
});

export default MemberApproveListView;

import {Dimensions} from 'react-native';
import Colors from './colors';
const styles = {
  headerCommon: {
    paddingStart: 18,
    paddingEnd: 18,
    height: 67,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    alignContent: 'center',
    backgroundColor: 'white',
    width: '100%',
  },
  headerBackButton: {
    marginEnd: 7,
    width: 22,
    height: 23,
  },
  headerBackButton2: {
    marginEnd: 7,
    width: 20,
    height: 32,
  },
  headerText: {
    fontSize: 17,
    color: '#1d3864',
    fontWeight: '600',
  },
  progressContainer: {
    position: 'absolute',
    alignContent: 'center',
    alignItems: 'center',
    backgroundColor: 'transparent',
    flex: 1,
    zIndex: 1,
    top: 200,
    width: '100%',
    height: '100%',
  },
  container: {
    flex: 1,
    backgroundColor: 'white',
  },
  scrollViewContainer: {
    backgroundColor: 'white',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
  coldot5: {
    flex: 0.5,
  },
  coldot8: {
    flex: 0.8,
  },
  col: {
    flex: 1,
  },
  col1dot2: {
    flex: 1.2,
  },
  col1dot5: {
    flex: 1.5,
  },
  col2: {
    flex: 2,
  },
  col3: {
    flex: 3,
  },
  verticalAlign: {
    alignItems: 'center',
  },
  imgAlarm: {
    width: 45,
    height: 45,
  },
  alarmContainer: {
    position: 'relative',
  },
  imgSetting: {
    width: 30,
    height: 30.5,
    marginEnd: 8,
  },
  alarmDot: {
    top: 10,
    end: 13,
    position: 'absolute',
    width: 8,
    height: 8,
    borderRadius: 4,
    backgroundColor: '#ff0000',
  },
  tabItem: {
    paddingStart: 10,
    paddingEnd: 10,
    paddingBottom: 8,
    borderBottomWidth: 2,
    borderBottomColor: '#eee',
    marginEnd: 7,
  },
  tabItemSelected: {
    paddingStart: 10,
    paddingEnd: 10,
    paddingBottom: 8,
    borderBottomWidth: 2,
    borderBottomColor: Colors.main,
    marginEnd: 7,
  },
  tabItemText: {
    color: 'black',
    fontSize: 16,
    lineHeight: 20,
  },
  tabItemTextSelected: {
    color: 'black',
    fontWeight: 'bold',
    fontSize: 16,
  },
  tabContainer: {
    paddingStart: 19,
    paddingTop: 14,
    flexDirection: 'row',
  },
  mr7: {marginEnd: 7},
  mt3: {
    marginTop: 3,
  },
  mt5: {
    marginTop: 5,
  },
  mt7: {
    marginTop: 7,
  },
  mt18: {
    marginTop: 18,
  },
  mt33: {
    marginTop: 33,
  },
  dropDown: {
    width: '100%',
    backgroundColor: 'white',
    borderWidth: 1,
    borderColor: 'rgb(61, 80, 183)',
    height: 30,
  },
  downDownText: {
    color: 'black',
    fontSize: 11,
  },
  emptyContainer: {
    flex: 1,
    flexGrow: 1,
    justifyContent: 'center',
    alignItems: 'center',
    paddingVertical: 40,
    opacity: 0.5,
  },
  emptyListText: {
    fontSize: 14,
    color: 'black',
  },
  headerLogoImg: {width: 45, height: 45, borderRadius: 25},
  dropDownRowText: {fontSize: 13, height: 50, lineHeight: 50},
};

export default styles;

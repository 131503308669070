import React, {useState, useEffect, useRef} from 'react';
import {
  StyleSheet,
  View,
  Dimensions,
  Modal,
  Text,
  Image,
  TouchableOpacity,
  TextInput,
  Alert,
  Platform,
} from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import api from '../../common/api';
import styles from '../../common/styles';
import {useSafeAreaInsets} from 'react-native-safe-area-context';
import Colors from '../../common/colors';
import SelectDropdown from 'react-native-select-dropdown';
import alert from '../alert';

const ApplyPopup = props => {
  const insets = useSafeAreaInsets();
  const [teamId, setTeamId] = useState();
  const [subLeagueName, setSubLeagueName] = useState();
  const [season, setSeason] = useState();
  const [seasonId, setSeasonId] = useState();
  const [seasonList, setSeasonList] = useState();
  const [teamList, setTeamList] = useState();
  const accessToken = useRef();

  useEffect(() => {
    AsyncStorage.getItem('@accessToken', (err, result) => {
      accessToken.current = result;
      getData();
    });
  }, []);

  const getData = () => {
    api
      .get(`leagues/${props.league.id}/seasons`, {
        headers: {
          Authorization: `Bearer ${accessToken.current}`,
        },
      })
      .then(function (response) {
        setSeasonList(response.data);
        for (let i = 0; i < response.data.length; i++) {
          if (response.data[i].active) {
            setSeasonId(response.data[i].id);
            setSeason(response.data[i]);
          }
        }
      })
      .catch(function (error) {
        console.log(error.response);
        alert('요청에 실패했습니다.');
      });
    api
      .get('teams/managed-by-me', {
        headers: {
          Authorization: `Bearer ${accessToken.current}`,
        },
      })
      .then(function (response) {
        setTeamList(response.data);
      })
      .catch(function (error) {
        console.log(error.response);
        alert('요청에 실패했습니다.');
      });
  };

  const save = () => {
    alert('신청', '신청 하시겠습니까?', [
      {
        text: '예',
        onPress: () => {
          if (!seasonId) {
            alert('시즌을 선택해 주세요.');
            return;
          }
          if (!subLeagueName) {
            alert('참여희망조를 입력해 주세요.');
            return;
          }
          if (!teamId) {
            alert('팀을 선택해 주세요.');
            return;
          }

          api
            .post(
              'season-team-requests',
              {
                seasonId: seasonId,
                subLeagueName: subLeagueName,
                teamId: teamId,
              },
              {
                headers: {
                  Authorization: `Bearer ${accessToken.current}`,
                },
              },
            )
            .then(function (response) {
              alert('요청에 성공했습니다.');
              props.closePopup();
            })
            .catch(function (error) {
              console.log(error.response);
              alert('요청에 실패했습니다.');
            });
        },
      },
      {
        text: '아니오',
      },
    ]);
  };

  const cancel = () => {
    props.closePopup();
  };

  return (
    <Modal transparent={true} overFullScreen={true}>
      <View style={[pageStyle.container]}>
        <View style={[pageStyle.innerContainer, {marginTop: insets.top + 67}]}>
          <View style={[styles.headerCommon, pageStyle.header]}>
            <View style={[styles.row, styles.verticalAlign, pageStyle.back]}>
              <TouchableOpacity onPress={() => props.closePopup()}>
                <Image
                  source={require('../../assets/images/icon_back.png')}
                  style={[styles.headerBackButton]}
                />
              </TouchableOpacity>
            </View>
            <Text style={[pageStyle.title]}>리그 가입 신청</Text>
          </View>
          <View style={[pageStyle.contentsContainer]}>
            <View>
              <View>
                <Text>팀 관리자(감독)만 신청 할 수 있습니다.</Text>
              </View>
              <Text style={[pageStyle.leagueName]}>{props.league?.name}</Text>
              <View style={[styles.row, styles.mt33]}>
                <View style={[styles.col]}>
                  <Text style={[pageStyle.label]}>시즌</Text>
                </View>
                <View style={[styles.col2]}>
                  <View style={[pageStyle.dropDownContainer]}>
                    <SelectDropdown
                      defaultButtonText="참여 시즌 선택"
                      defaultValue={season}
                      buttonStyle={pageStyle.dropDown}
                      buttonTextStyle={pageStyle.downDownText}
                      rowTextStyle={styles.dropDownRowText}
                      renderDropdownIcon={() => (
                        <Image
                          source={require('../../assets/images/select_arr.png')}
                        />
                      )}
                      data={seasonList}
                      onSelect={(selectedItem, index) => {
                        console.log(selectedItem, index);
                        setSeasonId(selectedItem.id);
                      }}
                      buttonTextAfterSelection={(selectedItem, index) => {
                        return `${selectedItem.year}년`;
                      }}
                      rowTextForSelection={(item, index) => {
                        return `${item.year}년`;
                      }}
                    />
                  </View>
                </View>
              </View>
              <View style={[styles.row, styles.mt18]}>
                <View style={[styles.col]}>
                  <Text style={[pageStyle.label]}>참여희망조</Text>
                </View>
                <View style={[styles.col2]}>
                  <View style={[pageStyle.dropDownContainer]}>
                    <TextInput
                      placeholder={'ex) 토요 루키조 희망합니다.'}
                      placeholderTextColor={'#aaa'}
                      style={[pageStyle.input]}
                      onChangeText={value => setSubLeagueName(value)}
                    />
                  </View>
                </View>
              </View>
              <View style={[styles.row, styles.mt18]}>
                <View style={[styles.col]}>
                  <Text style={[pageStyle.label]}>참여팀</Text>
                </View>
                <View style={[styles.col2]}>
                  <View style={[pageStyle.dropDownContainer]}>
                    <SelectDropdown
                      defaultButtonText="리그 참여 팀 선택"
                      buttonStyle={pageStyle.dropDown}
                      buttonTextStyle={pageStyle.downDownText}
                      rowTextStyle={styles.dropDownRowText}
                      renderDropdownIcon={() => (
                        <Image
                          source={require('../../assets/images/select_arr.png')}
                        />
                      )}
                      data={teamList}
                      onSelect={(selectedItem, index) => {
                        console.log(selectedItem, index);
                        setTeamId(selectedItem.id);
                      }}
                      buttonTextAfterSelection={(selectedItem, index) => {
                        return selectedItem.name;
                      }}
                      rowTextForSelection={(item, index) => {
                        return item.name;
                      }}
                    />
                  </View>
                </View>
              </View>
            </View>
            <View
              style={[
                styles.row,
                pageStyle.buttonContainer,
                Platform.OS === 'web' && {width: 400},
              ]}>
              <TouchableOpacity
                onPress={() => save()}
                style={[pageStyle.saveBtn]}>
                <Text style={[pageStyle.btnText]}>신청서 제출</Text>
              </TouchableOpacity>
              <TouchableOpacity
                onPress={() => cancel()}
                style={[pageStyle.cancelBtn]}>
                <Text style={[pageStyle.btnText, pageStyle.cancelBtnTxt]}>
                  취소
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </View>
    </Modal>
  );
};

export default ApplyPopup;

const pageStyle = StyleSheet.create({
  container: {
    zIndex: 100,
    width: '100%',
    height: Dimensions.get('window').height,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
  },
  innerContainer: {
    width: Platform.OS === 'web' ? 500 : '100%',
    backgroundColor: 'white',
    flex: 1,
    marginTop: 67,
  },
  input: {
    width: 132,
    borderBottomWidth: 1,
    borderBottomColor: 'black',
    textAlign: 'left',
    fontSize: 12,
    paddingBottom: 5,
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  back: {
    zIndex: 2,
  },
  leagueName: {
    color: 'black',
    fontWeight: '600',
    fontSize: 16,
    marginTop: 43,
    textAlign: 'center',
  },
  title: {
    color: Colors.main,
    fontSize: 24,
    fontWeight: '600',
    position: 'absolute',
    start: 0,
    textAlign: 'center',
    fontStyle: 'italic',
    width: Platform.OS === 'web' ? 500 : '100%',
  },
  imageInfoTxt: {
    color: '#ff0000',
    fontSize: 10,
    fontWeight: '500',
    marginTop: 5,
  },
  createTeamBtn: {
    width: 60,
    height: 22,
    borderRadius: 12,
    backgroundColor: Colors.main,
    justifyContent: 'center',
    alignItems: 'center',
    marginEnd: 14,
  },
  createTeamBtnTxt: {
    color: 'white',
    fontSize: 11,
  },
  contentsContainer: {
    alignItems: 'center',
    alignContent: 'center',
    flexDirection: 'column',
    display: 'flex',
    justifyContent: 'space-between',
    flex: 1,
  },
  contentsInnerContainer: {
    alignItems: 'center',
    marginTop: -100,
    paddingHorizontal: 51,
  },
  tainer: {
    width: 80,
    height: 80,
    backgroundColor: '#777',
    borderRadius: 40,
    alignItems: 'center',
  },
  plusIcon: {
    marginTop: 28,
    width: 21,
    height: 21,
  },
  registerBtnText: {
    color: 'white',
    fontSize: 10,
    marginTop: 6,
    fontWeight: '500',
  },
  inputContainer: {
    position: 'relative',
  },
  pencilIcn: {
    position: 'absolute',
    right: 0,
    bottom: 5,
  },
  homeTownTxt: {
    fontSize: 15,
    color: 'black',
    marginTop: 17,
  },
  createDate: {
    fontSize: 15,
    color: 'black',
    marginTop: 27,
  },
  saveBtn: {
    flex: 1,
    height: 34,
    backgroundColor: Colors.main,
    borderRadius: 17,
    alignItems: 'center',
    alignContent: 'center',
    justifyContent: 'center',
    marginTop: 65,
    marginEnd: 8,
  },
  cancelBtn: {
    flex: 1,
    height: 34,
    backgroundColor: '#ccc',
    borderRadius: 17,
    alignItems: 'center',
    alignContent: 'center',
    justifyContent: 'center',
    marginTop: 65,
    marginStart: 8,
  },
  cancelBtnTxt: {
    color: '#555',
  },
  btnText: {
    color: 'white',
    fontSize: 18,
  },
  dropdownRowContainer: {
    display: 'flex',
    width: 222,
    marginTop: 6,
    flexDirection: 'row',
  },
  dropDownItemContainer: {
    marginHorizontal: 3,
    flex: 1,
  },
  dropDown: {
    width: '100%',
    backgroundColor: 'white',
    borderWidth: 1,
    borderColor: 'rgb(61, 80, 183)',
    height: 30,
  },
  downDownText: {
    color: 'black',
    fontSize: 11,
  },
  newTeamIcn: {
    width: 162,
    height: 162,
  },
  teamCreatedTxt: {
    color: '#3f51b5',
    fontSize: 21,
    fontWeight: '600',
    marginTop: 28,
  },
  buttonContainer: {
    paddingHorizontal: 50,
    marginBottom: 80,
  },
  label: {
    color: 'black',
    fontWeight: '600',
    fontSize: 13,
    lineHeight: 22,
  },
});

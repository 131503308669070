import React, {useState, useEffect, useRef, useMemo} from 'react';
import {
  Image,
  SafeAreaView,
  ScrollView,
  Text,
  TouchableOpacity,
  View,
  StyleSheet,
  KeyboardAvoidingView,
  FlatList,
  TextInput,
  Platform,
  Dimensions,
  useWindowDimensions,
  Alert,
  DeviceEventEmitter,
  Modal,
} from 'react-native';
import {Shadow} from 'react-native-shadow-2';
import styles from '../common/styles';
import RenderHtml, {
  HTMLElementModel,
  HTMLContentModel,
  defaultHTMLElementModels,
} from 'react-native-render-html';
import AsyncStorage from '@react-native-async-storage/async-storage';
import api from '../common/api';
import * as Progress from 'react-native-progress';
import {useHeaderHeight} from '@react-navigation/elements';
import moment from 'moment';
import 'moment/locale/ko';
import Clipboard from '@react-native-clipboard/clipboard';
import Colors from '../common/colors';
import {useSafeAreaInsets} from 'react-native-safe-area-context';
import alert from '../component/alert';
import {ReactNativeZoomableView} from '@openspacelabs/react-native-zoomable-view';
var DomParser = require('react-native-html-parser').DOMParser;

const CommunityDetailView = ({navigation, route}) => {
  const {itemId} = route.params;
  const [item, setItem] = useState(route.params.item);
  const [board, setBoard] = useState({});
  const [commentPage, setCommentPage] = useState({});
  const [userData, setUserData] = useState({});
  const {width} = useWindowDimensions();
  const [progressShown, setProgressShown] = useState(false);
  const [tourMode, setTourMode] = useState();

  const accessToken = useRef();
  let pageLoading = useRef();
  let commentPageLoading = useRef();

  const height = useHeaderHeight();

  const [alarmUnread, setAlarmUnread] = useState(false);

  const insets = useSafeAreaInsets();

  useEffect(() => {
    const unsubscribe = DeviceEventEmitter.addListener(
      'communityListRefresh',
      function (data) {
        if (data && data.id && data.id === itemId) {
          getData();
          checkAlarmUnRead();
        }
      },
    );
    const unsubscribe2 = DeviceEventEmitter.addListener(
      '@commentRefresh',
      function (data) {
        console.log('@commentRefresh#1');
        getData();
        callGetCommentListApi();
      },
    );
    return () => {
      unsubscribe.remove();
      unsubscribe2.remove();
    };
  }, [item?.id]);

  const showNeedLoginAlert = () => {
    alert(
      '',
      '해당 페이지는 로그인 후 이용해주세요',
      [
        {
          text: '계속 둘러보기',
          onPress: () => {},
          style: 'cancel',
        },
        {
          text: '확인',
          onPress: () => {
            navigation?.reset({
              index: 0,
              routes: [{name: 'Login'}],
            });
          },
        },
      ],
      {
        cancelable: true,
        onDismiss: () => {},
      },
    );
  };

  const checkAlarmUnRead = () => {
    if (!accessToken.current) {
      return;
    }
    api
      .get('/users/exists-unread-alarm', {
        headers: {
          Authorization: `Bearer ${accessToken.current}`,
        },
      })
      .then(function (response) {
        setAlarmUnread(response.data);
      })
      .catch(function (error) {
        console.error(error.response);
      });
  };

  const getData = () => {
    if (pageLoading.current) {
      return;
    }
    pageLoading.current = true;
    setProgressShown(true);
    api
      .get(`/app-league/leagues/boards/${itemId}?fromUser=true`, {
        headers: {
          Authorization: `Bearer ${accessToken.current}`,
        },
      })
      .then(function (response) {
        setBoard(response.data);
        pageLoading.current = false;
        setProgressShown(false);
        // callGetCommentListApi();
      })
      .catch(function (error) {
        console.error(error);
      })
      .finally(() => {
        pageLoading.current = false;
        setProgressShown(false);
      });
  };

  const callGetCommentListApi = () => {
    if (commentPageLoading.current) {
      return;
    }
    setProgressShown(true);
    commentPageLoading.current = true;
    api
      .get(`/boards/${itemId}/comments`, {
        headers: {
          Authorization: `Bearer ${accessToken.current}`,
        },
      })
      .then(function (response) {
        setCommentPage(response.data);
      })
      .catch(function (error) {
        console.error(error);
      })
      .finally(() => {
        commentPageLoading.current = false;
        setProgressShown(false);
      });
  };

  useEffect(() => {
    AsyncStorage.getItem('@accessToken', (err, result) => {
      accessToken.current = result;
      setTourMode(!result);
      getData();
      checkAlarmUnRead();
    });
    AsyncStorage.getItem('@userInfo', (err, result) => {
      if (!result) {
        return;
      }
      setUserData(JSON.parse(result));
    });
  }, []);

  const callDeleteApi = () => {
    if (!accessToken.current) {
      return;
    }
    api
      .delete(`app-league/leagues/boards/${board.id}`, {
        headers: {
          Authorization: `Bearer ${accessToken.current}`,
        },
      })
      .then(function (response) {
        alert('요청에 성공하였습니다.');
        DeviceEventEmitter.emit('communityListRefresh');
        Platform.OS === 'web' ? history.back() : navigation.goBack();
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  const handleDelete = () => {
    if (!accessToken.current) {
      return;
    }
    alert('게시글', '삭제 하시겠습니까?', [
      {
        text: '예',
        onPress: () => callDeleteApi(),
      },
      {
        text: '아니오',
      },
    ]);
  };

  const handleModify = () => {
    if (!accessToken.current) {
      return;
    }
    alert('게시글', '수정 하시겠습니까?', [
      {
        text: '예',
        onPress: () => {
          setMenuOpened(false);
          if (!accessToken.current) {
            showNeedLoginAlert();
            return;
          }
          navigation.navigate('CommunityWrite', {
            item: board,
            admin: false,
          });
        },
      },
      {
        text: '아니오',
      },
    ]);
  };

  const getProfileImageUrl = item => {
    if (item.user?.profileImageThumbUrl) {
      return {uri: item.user?.profileImageThumbUrl};
    }
    if (item.user?.profileImageUrl) {
      return {uri: item.user?.profileImageUrl};
    }
    return require('../assets/images/icn_profile_img.png');
  };

  const [menuOpened, setMenuOpened] = useState(false);
  const toggleMenu = () => {
    setMenuOpened(!menuOpened);
  };

  const handlePageLoadMore = () => {
    if (commentPageLoading.current) {
      return;
    }
    if (
      commentPage.currentPage &&
      commentPage.totalPage <= commentPage.currentPage
    ) {
      return;
    }
    setProgressShown(true);
    commentPageLoading.current = true;
    api
      .get(
        `boards/${itemId}/comments?pageNo=${
          commentPage.currentPage
            ? parseInt(commentPage.currentPage, 10) + 1
            : 1
        }&pageSize=20`,
        {
          headers: {
            Authorization: `Bearer ${accessToken.current}`,
          },
        },
      )
      .then(function (response) {
        response.data.items = [
          ...(commentPage.items ? commentPage.items : []),
          ...response.data.items,
        ];
        setCommentPage(response.data);
      })
      .catch(function (error) {
        console.error(error);
      })
      .finally(() => {
        setProgressShown(false);
        commentPageLoading.current = false;
      });
  };

  const parseDate = date => {
    if (date.indexOf('T') > -1) {
      return moment(date).format('YYYY.MM.DD   HH:mm');
    }
    return moment(date, 'YYYY.MM.DD').locale('ko').format('MM/DD(dd)');
  };

  const handleCopyUrl = () => {
    if (Platform.OS === 'web') {
      window.navigator.clipboard
        .writeText(
          `https://service-api.unique-play.com/share/community/${itemId}`,
        )
        .then(() => {
          alert('공유 링크가 복사되었습니다!');
        });
    } else {
      Clipboard.setString(
        `https://service-api.unique-play.com/share/community/${itemId}`,
      );
      alert('공유 링크가 복사되었습니다!');
    }
  };

  const handleBlockUser = targetUserId => {
    if (!accessToken.current) {
      return;
    }
    Alert.alert('사용자 차단', '사용자 차단 하시겠습니까?', [
      {
        text: '예',
        onPress: () => {
          api
            .post(
              `/users/block-users`,
              {
                targetUserId: targetUserId,
              },
              {
                headers: {
                  Authorization: `Bearer ${accessToken.current}`,
                },
              },
            )
            .then(function (response) {
              setMenuOpened(false);
              alert('차단 신청이 완료되었습니다.');
            })
            .catch(function (error) {
              console.error(error);
            });
        },
      },
      {
        text: '아니오',
      },
    ]);
  };

  const customHTMLElementModels = {
    img: defaultHTMLElementModels.img.extend({
      contentModel: HTMLContentModel.mixed,
    }),
  };

  useEffect(() => {
    if (!board.contents) {
      return;
    }
    let doc = null;
    try {
      doc = new DomParser().parseFromString(board.contents, 'text/html');
    } catch (e) {
      return;
    }
    const imageLength = doc?.getElementsByTagName('img')?.length;
    console.log('imageLength', imageLength);
  }, [board.contents]);

  const fullWidth = useMemo(() => {
    if (Platform.OS === 'web') {
      const windowWidth = Dimensions.get('window').width;
      if (windowWidth < 550) {
        return windowWidth;
      } else {
        return 550;
      }
    }
    return width - 24;
  }, []);

  const [modalImage, setModalImage] = useState('');
  const [imageSize, setImageSize] = useState({});

  const handleModalImage = (uri, imageSize) => {
    if (Platform.OS === 'android') {
      navigation.navigate('Image', {uri: uri, imageSize: imageSize});
    } else {
      setImageSize(imageSize);
      setModalImage(uri);
      console.log(imageSize);
    }
  };

  const RenderHTMLImage = ({uri, onPress, imagesMaxWidth}) => {
    const [imageSize, setImageSize] = useState({});

    useEffect(() => {
      Image.getSize(uri, (width, height) => {
        setImageSize({
          width,
          height,
          aspectRatio: Number((width / height).toFixed(2)),
        });
      });
    }, []);
    return (
      <View
        style={{
          width:
            imageSize.width > imagesMaxWidth ? imagesMaxWidth : imageSize.width,
        }}>
        <TouchableOpacity onPress={() => onPress(uri, imageSize)}>
          <Image
            source={{uri}}
            style={{
              width:
                imageSize.width > imagesMaxWidth
                  ? imagesMaxWidth
                  : imageSize.width,
              aspectRatio: imageSize.aspectRatio,
              resizeMode: 'cover',
            }}
          />
        </TouchableOpacity>
      </View>
    );
  };

  return (
    <SafeAreaView nativeID="common-page-container" style={[styles.container]}>
      <Shadow
        offset={[0, 2]}
        style={[{width: '100%'}]}
        containerStyle={[{marginTop: 0}]}
        distance={7}
        sides={{bottom: true, top: false, start: false, end: false}}>
        <View style={[styles.headerCommon]}>
          <View style={[styles.row, styles.verticalAlign]}>
            <TouchableOpacity
              onPress={() =>
                Platform.OS === 'web' ? history.back() : navigation.goBack()
              }>
              <Image
                source={require('../assets/images/icon_back.png')}
                style={[styles.headerBackButton]}
              />
            </TouchableOpacity>
            <Text style={[styles.headerText]}>커뮤니티</Text>
          </View>
          <View style={[styles.row, styles.verticalAlign]}>
            <TouchableOpacity
              style={[styles.alarmContainer]}
              onPress={() => {
                if (!accessToken.current) {
                  showNeedLoginAlert();
                  return;
                }
                navigation.navigate('MyAlarmList');
              }}>
              <Image
                resizeMode="contain"
                style={[styles.imgAlarm]}
                source={require('../assets/images/icn_alarm.png')}
              />
              {alarmUnread && <View style={[styles.alarmDot]} />}
            </TouchableOpacity>
          </View>
        </View>
      </Shadow>
      <View
        style={[
          {
            marginTop: 10,
            position: 'relative',
            flex: 1,
          },
        ]}>
        <FlatList
          removeClippedSubviews={false}
          style={[pageStyle.list]}
          showsVerticalScrollIndicator={false}
          ListFooterComponent={() => <View style={[{height: 40}]} />}
          ListEmptyComponent={() => (
            <View style={[styles.emptyContainer]}>
              <Image source={require('../assets/images/logo2.png')} />
            </View>
          )}
          ListHeaderComponent={() => {
            return (
              <View style={[pageStyle.headerContainer]}>
                <View style={[styles.row, {justifyContent: 'space-between'}]}>
                  <Text style={[pageStyle.type]}>{board.type}</Text>
                  {!tourMode && (
                    <TouchableOpacity onPress={toggleMenu}>
                      <Image
                        style={[{width: 20, height: 20}]}
                        source={require('../assets/images/icn_dot_menu.png')}
                      />
                    </TouchableOpacity>
                  )}
                </View>
                <Text style={[pageStyle.title]} multiLine={true}>
                  {board.title}
                </Text>
                <View
                  style={[
                    styles.row,
                    pageStyle.row,
                    styles.mt7,
                    pageStyle.profileContainer,
                  ]}>
                  <TouchableOpacity
                    onPress={() => {
                      if (!accessToken.current) {
                        showNeedLoginAlert();
                        return;
                      }
                      navigation.navigate('MemberPage', {
                        item: board.user,
                        itemId: board.user.uid,
                      });
                    }}
                    style={[styles.row, pageStyle.center]}>
                    <Image
                      style={[
                        {
                          width: 46,
                          height: 46,
                          resizeMode: 'cover',
                          borderRadius: 23,
                        },
                      ]}
                      source={getProfileImageUrl(board)}
                    />
                    <View
                      style={[
                        {alignContent: 'center', justifyContent: 'center'},
                      ]}>
                      <Text style={[pageStyle.username]}>
                        {board.user?.name}
                      </Text>
                      <View style={[pageStyle.center, styles.row, styles.mt5]}>
                        <Text style={[pageStyle.label]}>
                          {moment(board.createdAt).format('YY.MM.DD HH:mm')}
                        </Text>
                        <View>
                          <View style={[styles.row]}>
                            <Text style={[pageStyle.label]}>
                              조회 {board.hitCount?.toLocaleString()}회
                            </Text>
                          </View>
                        </View>
                      </View>
                    </View>
                  </TouchableOpacity>
                </View>
                {board.contents && (
                  <View style={[pageStyle.contentsContainer]}>
                    <RenderHtml
                      tagsStyles={contentHtmlStyles}
                      contentWidth={fullWidth}
                      source={{
                        html: board.contents,
                      }}
                      // customHTMLElementModels={customHTMLElementModels}
                      renderers={{
                        img: (attribs, children, objectCSS, contentProps) => (
                          <RenderHTMLImage
                            uri={attribs.tnode.init.domNode.attribs.src}
                            onPress={(uri, imageSize) =>
                              handleModalImage(uri, imageSize)
                            }
                            imagesMaxWidth={fullWidth}
                          />
                        ),
                      }}
                    />
                  </View>
                )}
                <View style={[pageStyle.border]} />
                {board?.type !== '유니크플레이 공지사항' && (
                  <TouchableOpacity
                    onPress={() => {
                      if (!accessToken.current) {
                        showNeedLoginAlert();
                        return;
                      }
                      navigation.navigate('Comment', {
                        boardId: itemId,
                        boardType: board.type,
                        userData: userData,
                        boardWriter: board.user,
                        commentCount: board.commentCount,
                      });
                    }}
                    style={[
                      styles.row,
                      {alignItems: 'center', marginTop: 12, marginBottom: 12},
                    ]}>
                    <Text style={[pageStyle.commentSubtitle]}>
                      댓글{' '}
                      {board?.commentCount
                        ? board?.commentCount?.toLocaleString()
                        : '0'}
                    </Text>
                    <Image
                      style={[pageStyle.rightArr]}
                      source={require('../assets/images/chevron-right.png')}
                    />
                  </TouchableOpacity>
                )}
              </View>
            );
          }}
          nestedScrollEnabled={true}
          keyExtractor={item => itemId.toString()}
          data={commentPage.items}
          renderItem={({item}) => (
            <>
              <View style={[pageStyle.commentItemContainer]}>
                <Image
                  style={[
                    {
                      width: 40,
                      height: 40,
                      resizeMode: 'cover',
                      borderRadius: 20,
                    },
                  ]}
                  source={getProfileImageUrl(item)}
                />

                <View style={[pageStyle.rightContainer]}>
                  <TouchableOpacity
                    onPress={() => {
                      if (!accessToken.current) {
                        showNeedLoginAlert();
                        return;
                      }
                      navigation.navigate('MemberPage', {
                        item: {uid: item.user.uid},
                        itemId: item.user.uid,
                      });
                    }}
                    style={[styles.row, {alignItems: 'center'}]}>
                    <Text style={[pageStyle.userName]}>{item.user.name}</Text>
                    {item?.user?.id === board?.user?.id && (
                      <Text style={[pageStyle.myComment]}>작성자</Text>
                    )}
                  </TouchableOpacity>
                  <Text
                    style={[
                      pageStyle.commentContent,
                      item.deleted && {color: '#888'},
                    ]}>
                    {item.deleted ? '삭제된 댓글입니다' : item.contents}
                  </Text>
                  {item.imageUrl && (
                    <Image
                      style={[pageStyle.commentImage]}
                      source={{uri: item.imageUrl}}
                    />
                  )}
                  <View style={[styles.row, {alignItems: 'center'}]}>
                    <Text style={[pageStyle.date]}>
                      {parseDate(item.createdAt)}
                    </Text>
                    <TouchableOpacity
                      onPress={() => {
                        if (!accessToken.current) {
                          showNeedLoginAlert();
                          return;
                        }
                        navigation.navigate('CommentChild', {
                          parentId: item.id,
                          targetCommentId: item.id,
                          boardId: board.id,
                          boardType: board.type,
                          comment: item,
                          userData: userData,
                          boardWriter: board.user,
                        });
                      }}
                      style={[{marginStart: 12}]}>
                      <Text style={[pageStyle.date]}>답글쓰기</Text>
                    </TouchableOpacity>
                  </View>
                </View>
              </View>
              <View>
                {item.childs &&
                  item.childs.map((child, index) => {
                    return (
                      <View style={[pageStyle.commentItemContainer]}>
                        <View style={[pageStyle.childLeft]} />
                        <Image
                          style={[
                            {
                              width: 40,
                              height: 40,
                              resizeMode: 'cover',
                              borderRadius: 20,
                            },
                          ]}
                          source={getProfileImageUrl(child)}
                        />

                        <View style={[pageStyle.rightContainer]}>
                          <TouchableOpacity
                            onPress={() => {
                              if (!accessToken.current) {
                                showNeedLoginAlert();
                                return;
                              }
                              navigation.navigate('MemberPage', {
                                item: {uid: child.user.uid},
                                itemId: child.user.uid,
                              });
                            }}
                            style={[styles.row, {alignItems: 'center'}]}>
                            <Text style={[pageStyle.userName]}>
                              {child.user.name}
                            </Text>
                            {child.user?.id === board.user?.id && (
                              <Text style={[pageStyle.myComment]}>작성자</Text>
                            )}
                          </TouchableOpacity>
                          <Text
                            style={[
                              pageStyle.commentContent,
                              child.deleted && {color: '#888'},
                            ]}>
                            <Text
                              style={[
                                {
                                  color: Colors.main,
                                  fontSize: 15,
                                  fontWeight: 'bold',
                                  fontFamily: 'Pretendard-Bold',
                                },
                              ]}>
                              {child.targetUser ? child.targetUser.name : ''}{' '}
                            </Text>
                            {child.deleted
                              ? '삭제된 댓글입니다.'
                              : child.contents}
                          </Text>
                          {child.imageUrl && (
                            <Image
                              style={[pageStyle.commentImage]}
                              source={{uri: child.imageUrl}}
                            />
                          )}
                          <View style={[styles.row, {alignItems: 'center'}]}>
                            <Text style={[pageStyle.date]}>
                              {parseDate(child.createdAt)}
                            </Text>
                            <TouchableOpacity
                              onPress={() => {
                                if (!accessToken.current) {
                                  showNeedLoginAlert();
                                  return;
                                }
                                navigation.navigate('CommentChild', {
                                  parentId: item.id,
                                  targetCommentId: item.id,
                                  boardId: board.id,
                                  boardType: board.type,
                                  comment: item,
                                  userData: userData,
                                  boardWriter: board.user,
                                  targetUserId: child.user.id,
                                  targetCommentId: child.id,
                                });
                              }}
                              style={[{marginStart: 12}]}>
                              <Text style={[pageStyle.date]}>답글쓰기</Text>
                            </TouchableOpacity>
                          </View>
                        </View>
                      </View>
                    );
                  })}
              </View>
            </>
          )}
          onEndReachedThreshold={1}
          onEndReached={handlePageLoadMore}
        />
      </View>
      <Shadow
        style={[
          pageStyle.bottomView,
          styles.row,
          {
            height: 45 + (Platform.OS === 'ios' ? insets.bottom : 0),
            marginBottom: Platform.OS === 'ios' ? -insets.bottom : 0,
          },
          Platform.OS === 'ios' && {
            paddingTop: 12,
            alignContent: 'flex-start',
            alignItems: 'flex-start',
          },
        ]}>
        <TouchableOpacity
          onPress={() =>
            Platform.OS === 'web' ? history.back() : navigation.goBack()
          }
          style={[styles.row]}>
          <Image
            style={[pageStyle.icnListImg]}
            resizeMode="cover"
            source={require('../assets/images/icn_list_dot.png')}
          />
          <Text style={[pageStyle.listTxt]}>목록으로</Text>
        </TouchableOpacity>
        <View style={[styles.row]}>
          <TouchableOpacity onPress={handleCopyUrl}>
            <Image
              style={[pageStyle.icnShare]}
              resizeMode="cover"
              source={require('../assets/images/icn_share.png')}
            />
          </TouchableOpacity>
          {board?.type !== '유니크플레이 공지사항' && (
            <TouchableOpacity
              onPress={() => {
                if (!accessToken.current) {
                  showNeedLoginAlert();
                  return;
                }
                navigation.navigate('Comment', {
                  boardId: itemId,
                  boardType: board?.type,
                  userData: userData,
                  boardWriter: board?.user,
                  commentCount: board?.commentCount,
                });
              }}
              style={[styles.row]}>
              <Image
                style={[pageStyle.icnComment]}
                resizeMode="cover"
                source={require('../assets/images/icn_comment.png')}
              />
              <Text style={[pageStyle.commentCount]}>
                {board?.commentCount
                  ? board?.commentCount.toLocaleString()
                  : '0'}
              </Text>
            </TouchableOpacity>
          )}
        </View>
      </Shadow>
      {progressShown && (
        <View style={[styles.progressContainer]}>
          <Progress.Circle
            fill="transparent"
            size={70}
            borderWidth={3}
            indeterminate={true}
          />
        </View>
      )}
      {menuOpened && (
        <Modal transparent={true} overFullScreen={true}>
          {userData?.uid !== item?.user?.uid && (
            <>
              <TouchableOpacity
                style={[pageStyle.popupContainer]}
                onPress={toggleMenu}>
                <View style={[pageStyle.popupInnerContainer]}>
                  <TouchableOpacity
                    onPress={() => {
                      if (!accessToken.current) {
                        showNeedLoginAlert();
                        return;
                      }
                      navigation.navigate('Report', {
                        userName: board.user.name,
                        boardId: board.id,
                        boardTitle: board.title,
                      });
                      setMenuOpened(false);
                    }}>
                    <Text style={[pageStyle.menuTxt]}>신고하기</Text>
                  </TouchableOpacity>
                  <TouchableOpacity
                    onPress={() => handleBlockUser(item.user.uid)}>
                    <Text style={[pageStyle.menuTxt]}>차단하기</Text>
                  </TouchableOpacity>
                  <TouchableOpacity
                    onPress={() => {
                      if (!accessToken.current) {
                        showNeedLoginAlert();
                        return;
                      }
                      setMenuOpened(false);
                      navigation.navigate('MemberPage', {
                        item: item.user,
                        itemId: item.user.uid,
                      });
                    }}>
                    <Text style={[pageStyle.menuTxt]}>프로필보기</Text>
                  </TouchableOpacity>
                </View>
              </TouchableOpacity>
            </>
          )}
          {userData?.uid === item?.user?.uid && (
            <>
              <TouchableOpacity
                style={[pageStyle.popupContainer]}
                onPress={toggleMenu}>
                <View style={[pageStyle.popupInnerContainer]}>
                  <TouchableOpacity onPress={handleModify}>
                    <Text style={[pageStyle.menuTxt]}>수정하기</Text>
                  </TouchableOpacity>
                  <TouchableOpacity onPress={handleDelete}>
                    <Text style={[pageStyle.menuTxt]}>삭제하기</Text>
                  </TouchableOpacity>
                </View>
              </TouchableOpacity>
            </>
          )}
        </Modal>
      )}
      {modalImage && (
        <Modal transparent={true} overFullScreen={true}>
          <View
            style={[
              pageStyle.popupContainer,
              Platform.OS === 'web' && {
                alignItems: 'center',
                alignContent: 'center',
              },
            ]}>
            <View
              style={[
                pageStyle.popupInnerContainer2,
                {
                  flex: 1,
                },
                Platform.OS === 'web' && {width: fullWidth},
              ]}>
              <ReactNativeZoomableView
                maxZoom={3}
                // Give these to the zoomable view so it can apply the boundaries around the actual content.
                // Need to make sure the content is actually centered and the width and height are
                // dimensions when it's rendered naturally. Not the intrinsic size.
                // For example, an image with an intrinsic size of 400x200 will be rendered as 300x150 in this case.
                // Therefore, we'll feed the zoomable view the 300x150 size.
                contentWidth={fullWidth}
                contentHeight={fullWidth / imageSize.aspectRatio}>
                <Image
                  source={{uri: modalImage}}
                  style={{
                    width: fullWidth,
                    aspectRatio: imageSize.aspectRatio,
                    resizeMode: 'cover',
                  }}
                />
              </ReactNativeZoomableView>
              <TouchableOpacity
                onPress={() => setModalImage('')}
                style={[
                  {
                    position: 'absolute',
                    top: 50,
                    right: 10,
                    backgroundColor: 'rgba(255, 255, 255, 0.8)',
                    borderRadius: 20,
                  },
                ]}>
                <Image
                  source={require('../assets/images/close.png')}
                  style={[{opacity: 0.8, width: 40, height: 40}]}
                />
              </TouchableOpacity>
            </View>
          </View>
        </Modal>
      )}
    </SafeAreaView>
  );
};

export default CommunityDetailView;

const contentHtmlStyles = StyleSheet.create({
  table: {
    borderTopWidth: 1,
    borderLeftWidth: 1,
    borderColor: '#ccc',
    marginBottom: 7,
  },
  tr: {
    borderBottomWidth: 1,
    borderColor: '#ccc',
  },
  td: {
    borderRightWidth: 1,
    borderColor: '#ccc',
    padding: 5,
  },
});

const pageStyle = StyleSheet.create({
  headerCommon: {},
  container: {
    borderWidth: 5,
    borderColor: '#ddd',
    opacity: 0.8,
    height: 200,
  },
  type: {
    color: Colors.main,
    fontSize: 15,
    fontFamily: 'Pretendard',
  },
  title: {
    paddingVertical: 4,
    fontSize: 20,
    marginTop: 16,
    fontWeight: '600',
    fontFamily: 'Pretendard-SemiBold',
  },
  profileContainer: {
    borderTopWidth: 1,
    borderTopColor: '#ddd',
    borderBottomWidth: 1,
    borderBottomColor: '#ddd',
    paddingVertical: 16,
    marginHorizontal: -12,
    paddingHorizontal: 12,
  },
  headerContainer: {paddingHorizontal: 12},
  contentsContainer: {
    marginVertical: 8,
    paddingTop: 16,
    paddingBottom: 40,
    marginHorizontal: -12,
    paddingHorizontal: 12,
  },
  border: {
    borderTopWidth: 1,
    borderTopColor: '#ddd',
    marginHorizontal: -12,
    paddingHorizontal: 12,
  },
  commentContainer: {marginVertical: 12, paddingHorizontal: 8},
  commentSubtitle: {
    fontSize: 14,
    color: 'black',
    fontWeight: '600',
    fontFamily: 'Pretendard-SemiBold',
  },
  commentInputContainer: {flexDirection: 'row', marginTop: 8},
  commentInput: {
    flex: 1,
    height: 45,
    padding: 12,
    borderWidth: 1,
    borderColor: '#ddd',
  },
  commentBtn: {
    height: 45,
    marginStart: 8,
    lineHeight: 45,
    borderWidth: 1,
    borderColor: '#ddd',
  },
  commentBtnTxt: {
    height: 45,
    lineHeight: 45,
    fontFamily: 'Pretendard',
    fontSize: 12,
    textAlign: 'center',
    width: 55,
    color: 'black',
  },
  commentItemContainer: {
    borderBottomWidth: 1,
    borderBottomColor: '#ddd',
    paddingVertical: 12,
    paddingHorizontal: 8,
    flexDirection: 'row',
  },
  commentContent: {
    fontFamily: 'Pretendard',
    fontSize: 15,
    color: 'black',
    marginTop: 7,
  },
  rightArr: {
    width: 20,
    height: 20,
    marginStart: 2,
  },
  myComment: {
    width: 44,
    height: 20,
    textAlign: 'center',
    color: Colors.main,
    fontFamily: 'Pretendard',
    fontSize: 11,
    borderWidth: 1,
    borderColor: Colors.main,
    paddingHorizontal: 4,
    borderRadius: 10,
    marginStart: 8,
    justifyContent: 'center',
    alignItems: 'center',
    lineHeight: 18,
  },
  userName: {
    fontSize: 13,
    color: '#111',
    fontWeight: 'bold',
    fontFamily: 'Pretendard-Bold',
  },
  rightContainer: {
    marginStart: 8,
    flex: 1,
  },
  date: {
    fontFamily: 'Pretendard',
    fontSize: 11,
    color: '#666',
    marginTop: 5,
  },
  replyBtn: {
    opacity: 0.4,
    width: 20,
    justifyContent: 'flex-end',
  },
  deleteBtn: {
    opacity: 0.4,
    width: 20,
    justifyContent: 'flex-end',
  },
  replyImg: {
    width: 20,
    height: 20,
    resizeMode: 'cover',
  },
  deleteImg: {
    width: 20,
    height: 20,
    resizeMode: 'cover',
  },
  childLeft: {width: 40},
  list: {
    height:
      Dimensions.get('window').height - (Platform.OS === 'ios' ? 200 : 100),
  },
  buttonContainer: {
    marginTop: 25,
    flexDirection: 'row',
    marginVertical: 8,
  },
  buttonItem: {
    flex: 1,
    height: 30,
    alignItems: 'center',
    alignContent: 'center',
  },
  buttonItemTxt: {
    textAlign: 'center',
    fontFamily: 'Pretendard',
    fontSize: 13,
    color: 'white',
  },
  deleteItemTxt: {
    color: 'red',
  },
  commonBtn: {
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 5,
    height: 30,
    marginStart: 8,
    backgroundColor: 'rgb(61, 80, 183)',
  },
  boardDeleteBtn: {
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 5,
    height: 30,
    borderWidth: 1,
    borderColor: 'red',
    backgroundColor: 'white',
    opacity: 0.5,
  },
  username: {
    fontFamily: 'Pretendard',
    fontSize: 16,
    color: 'black',
    marginStart: 10,
  },
  label: {
    color: '#7c7c7c',
    fontSize: 13,
    fontFamily: 'Pretendard',
    marginStart: 10,
    lineHeight: 13,
  },
  hit: {
    color: '#7c7c7c',
    fontSize: 13,
    fontFamily: 'Pretendard',
    lineHeight: 15,
  },
  bottomView: {
    width: '100%',
    justifyContent: 'space-between',
    height: 40,
    alignContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 12,
    backgroundColor: 'white',
  },
  icnListImg: {
    marginStart: 10,
    width: 25,
    height: (25 / 21) * 12,
  },
  icnShare: {
    width: 23,
    height: 23,
    marginEnd: 30,
  },
  icnComment: {
    width: 23,
    height: 23,
    marginEnd: 12,
  },
  listTxt: {
    fontFamily: 'Pretendard',
    fontSize: 14,
    color: 'black',
    alignSelf: 'center',
    marginStart: 8,
  },
  commentCount: {
    fontSize: 14,
    color: 'black',
    fontFamily: 'Pretendard-SemiBold',
    fontWeight: '600',
    alignSelf: 'center',
    marginEnd: 12,
  },
  popupContainer: {
    zIndex: 100,
    width: '100%',
    height: Dimensions.get('window').height,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    position: 'relative',
  },
  popupInnerContainer: {
    position: 'absolute',
    backgroundColor: 'white',
    width: 100,
    right:
      Platform.OS === 'web'
        ? (Dimensions.get('window').width - 550) / 2 + 40
        : 40,
    top: 150,
    textAlign: 'center',
    paddingVertical: 5,
    borderWidth: 1,
    borderColor: '#ddd',
  },

  popupInnerContainer2: {
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
    textAlign: 'center',
    paddingVertical: 5,
    borderWidth: 1,
    borderColor: '#ddd',
    borderRadius: 10,
  },
  menuTxt: {
    fontFamily: 'Pretendard',
    fontSize: 14,
    color: 'black',
    textAlign: 'center',
    paddingVertical: 8,
  },
  commentImage: {
    width: 200,
    height: 200,
    marginTop: 8,
  },
});

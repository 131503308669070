/* eslint-disable react-native/no-inline-styles */
import React, {useEffect, useMemo, useRef, useState} from 'react';
import {
  StyleSheet,
  View,
  Dimensions,
  Modal,
  Text,
  Image,
  TouchableOpacity,
  Platform,
  ImageBackground,
  ScrollView,
  Alert,
} from 'react-native';
import {Shadow} from 'react-native-shadow-2';
import styles from '../../common/styles';
import {useSafeAreaInsets} from 'react-native-safe-area-context';
import Colors from '../../common/colors';
import SelectDropdown from 'react-native-select-dropdown';
import moment from 'moment';
import 'moment/locale/ko';
import AsyncStorage from '@react-native-async-storage/async-storage';
import api from '../../common/api';
import DraggableFlatList, {
  ScaleDecorator,
} from 'react-native-draggable-flatlist';
import {gestureHandlerRootHOC} from 'react-native-gesture-handler';

const LineUpFormPopup = ({data, closePopup, navigation}) => {
  const accessToken = useRef();
  const insets = useSafeAreaInsets();
  const [currentTeamId, setCurrentTeamId] = useState('');
  const [extraData, setExtraData] = useState(false);
  const [teamLineUps, setTeamLineUps] = useState([]);
  const [teamMembers, setTeamMembers] = useState([]);

  const firstTeamLineUps = useRef();
  const lastTeamLineUps = useRef();
  const firstTeamCandidates = useRef();
  const lastTeamCandidates = useRef();
  const firstTeamCandidatesWidthUserData = useRef();
  const lastTeamCandidatesWithUserData = useRef();
  const firstTeamMembers = useRef();
  const lastTeamMembers = useRef();

  const modifiedTeamLineUps = useRef([]);

  const candidateMemberRefs = useRef([]);

  const modifiedCandidateMembers = useRef([]);

  const lineupBattingMembers = useRef([]);
  const lineupPitchingMembers = useRef('');

  useEffect(() => {
    AsyncStorage.getItem('@accessToken', (err, result) => {
      accessToken.current = result;
      getData(result);
    });
  }, []);

  const getMembers = teamId => {
    api
      .get(
        `/teams/${teamId}/members?onlyRegisteredInLeague=true&pageSize=200&gameId=${data?.id}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken.current}`,
          },
        },
      )
      .then(function (response) {
        if (data.batFirstTeam.id === teamId) {
          firstTeamMembers.current = [{text: '선택'}, ...response.data.items];
        } else if (data.batLastTeam.id === teamId) {
          lastTeamMembers.current = [{text: '선택'}, ...response.data.items];
        }
        if (data.batFirstTeamManagedByMe) {
          setTeamMembers(firstTeamMembers.current);
          selectFirstBatTeam();
        } else if (data.batLastTeamManagedByMe) {
          setTeamMembers(lastTeamMembers.current);
          selectLastBatTeam();
        }
      })
      .catch(function (error) {
        console.log(error.response);
      });
  };

  const getData = token => {
    api
      .get(`/lineups?temp=true&gameId=${data.id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(function (response) {
        const lineUps = response.data;
        let localBatFirstTeamLineUps = [];
        let localBatLastTeamLineUps = [];
        let localBatFirstTeamCandidates = [];
        let localBatLastTeamCandidates = [];
        let localBatFirstTeamCandidatesWithData = [];
        let localBatLastTeamCandidatesWithData = [];
        for (let i = 0; i < lineUps.length; i++) {
          const item = lineUps[i];
          if (item.member) {
            item.memberId = item.member.id;
          }
          if (item.team) {
            item.teamId = item.team.id;
          }
          if (item.battingOrder || item.pitchingOrder) {
            if (item.team.id === data.batFirstTeam.id) {
              localBatFirstTeamLineUps.push(item);
            } else if (item.team.id === data.batLastTeam.id) {
              localBatLastTeamLineUps.push(item);
            }
          }
        }
        for (let i = 0; i < lineUps.length; i++) {
          const item = lineUps[i];
          if (!item.battingOrder && !item.pitchingOrder) {
            if (
              item.team.id === data.batFirstTeam.id &&
              !localBatFirstTeamLineUps
                .map(i => i.member)
                .map(i => i.id)
                .includes(item.member.id)
            ) {
              localBatFirstTeamCandidates.push(item.member.id);
              localBatFirstTeamCandidatesWithData.push(item.member);
            } else if (
              item.team.id === data.batLastTeam.id &&
              !localBatLastTeamLineUps
                .map(i => i.member)
                .map(i => i.id)
                .includes(item.member.id)
            ) {
              localBatLastTeamCandidates.push(item.member.id);
              localBatLastTeamCandidatesWithData.push(item.member);
            }
          }
        }
        localBatFirstTeamLineUps.sort((i, j) => {
          if (!!i.pitchingOrder && !j.pitchingOrder) {
            return 1;
          } else if (!i.pitchingOrder && j.pitchingOrder) {
            return -1;
          } else if (!!i.pitchingOrder && !!j.pitchingOrder) {
            return parseInt(i.pitchingOrder, 10) > parseInt(j.pitchingOrder, 10)
              ? 1
              : -1;
          }
          return i.battingOrder !== j.battingOrder
            ? parseInt(i.battingOrder, 10) > parseInt(j.battingOrder, 10)
              ? 1
              : -1
            : i.battingSubOrder > j.battingSubOrder
            ? 1
            : -1;
        });
        localBatLastTeamLineUps.sort((i, j) => {
          if (!!i.pitchingOrder && !j.pitchingOrder) {
            return 1;
          } else if (!i.pitchingOrder && j.pitchingOrder) {
            return -1;
          } else if (!!i.pitchingOrder && !!j.pitchingOrder) {
            return parseInt(i.pitchingOrder, 10) > parseInt(j.pitchingOrder, 10)
              ? 1
              : -1;
          }
          return i.battingOrder !== j.battingOrder
            ? parseInt(i.battingOrder, 10) > parseInt(j.battingOrder, 10)
              ? 1
              : -1
            : i.battingSubOrder > j.battingSubOrder
            ? 1
            : -1;
        });
        firstTeamLineUps.current = localBatFirstTeamLineUps;
        lastTeamLineUps.current = localBatLastTeamLineUps;
        firstTeamCandidates.current = localBatFirstTeamCandidates;
        lastTeamCandidates.current = localBatLastTeamCandidates;
        firstTeamCandidatesWidthUserData.current =
          localBatFirstTeamCandidatesWithData;
        lastTeamCandidatesWithUserData.current =
          localBatLastTeamCandidatesWithData;

        if (data.batFirstTeamManagedByMe) {
          getMembers(data.batFirstTeam.id);
        }
        if (data.batLastTeamManagedByMe) {
          getMembers(data.batLastTeam.id);
        }
      })
      .catch(function (error) {
        console.log(error.response);
      });
  };

  const addHitter = () => {
    const localTeamLineUps = [];
    let lastBattingOrder = 0;
    for (let i = 0; i < modifiedTeamLineUps.current.length; i++) {
      const lineUp = modifiedTeamLineUps.current[i];
      localTeamLineUps.push(lineUp);
      if (lineUp.battingOrder) {
        lastBattingOrder = lineUp.battingOrder;
      }
    }
    localTeamLineUps.push({
      battingOrder: parseInt(lastBattingOrder, 10) + 1,
      position: null,
      pitchingOrder: null,
      memberId: null,
      teamId: null,
      member: null,
      team: {},
    });

    localTeamLineUps.sort((i, j) => {
      if (!!i.pitchingOrder && !j.pitchingOrder) {
        return 1;
      } else if (!i.pitchingOrder && j.pitchingOrder) {
        return -1;
      } else if (!!i.pitchingOrder && !!j.pitchingOrder) {
        return parseInt(i.pitchingOrder, 10) > parseInt(j.pitchingOrder, 10)
          ? 1
          : -1;
      }
      return i.battingOrder !== j.battingOrder
        ? parseInt(i.battingOrder, 10) > parseInt(j.battingOrder, 10)
          ? 1
          : -1
        : i.battingSubOrder > j.battingSubOrder
        ? 1
        : -1;
    });

    // console.log('localTeamLineUps', localTeamLineUps);

    modifiedTeamLineUps.current = localTeamLineUps;
    setExtraData(!extraData);
    setTeamLineUps(modifiedTeamLineUps.current);
    reSetCandidateMembers(modifiedTeamLineUps.current, teamMembers);
  };

  const parseDate = date => {
    if (date.indexOf('T') > -1) {
      return moment(date.substring(0, date.indexOf('T')), 'YYYY-MM-DD').format(
        'MM/DD',
      );
    }
    return moment(date, 'YYYY-MM-DD').locale('ko').format('MM/DD(dd)');
  };

  const parseTime = time => {
    if (time.indexOf(':', 4) > -1) {
      return time.substring(0, time.indexOf(':', 4));
    }
    return time;
  };

  const selectFirstBatTeam = () => {
    // if (!data.batFirstTeamManagedByMe) {
    //   return;
    // }
    setCurrentTeamId(data.batFirstTeam.id);
    const lineUpsTemplate = [];
    if (firstTeamLineUps.current.length < 10) {
      for (let i = 1; i <= 10; i++) {
        if (
          firstTeamLineUps.current.filter(item => item.battingOrder == i)
            .length > 0
        ) {
          const lineUpItem = firstTeamLineUps.current.filter(
            item => item.battingOrder == i,
          )[0];
          if (lineUpItem.member) {
            lineUpItem.memberId = lineUpItem.member.id;
          }
          if (lineUpItem) {
            lineUpsTemplate.push(lineUpItem);
          }
        } else {
          lineUpsTemplate.push({
            battingOrder: i === 10 ? null : i,
            position: i === 10 ? '선발투수' : null,
            pitchingOrder: i === 10 ? 1 : null,
            memberId: null,
            teamId: null,
            member: null,
            team: {},
          });
        }
      }
      modifiedTeamLineUps.current = lineUpsTemplate;
    } else {
      modifiedTeamLineUps.current = firstTeamLineUps.current;
    }
    setTeamLineUps(modifiedTeamLineUps.current);
    modifiedCandidateMembers.current = firstTeamCandidates.current;
    reSetCandidateMembers(
      modifiedTeamLineUps.current,
      firstTeamMembers.current,
    );
    if (candidateMemberRefs.current) {
      for (let i = 0; i < 9; i++) {
        if (!candidateMemberRefs.current[2 * i]) {
          continue;
        }
        candidateMemberRefs.current[2 * i].disabled = true;
        candidateMemberRefs.current[2 * i + 1].disabled = true;
      }
    }
  };

  const selectLastBatTeam = () => {
    // if (!data.batLastTeamManagedByMe) {
    //   return;
    // }
    setCurrentTeamId(data.batLastTeam.id);
    const lineUpsTemplate = [];
    if (lastTeamLineUps.current.length < 10) {
      for (let i = 1; i <= 10; i++) {
        if (
          lastTeamLineUps.current.filter(item => item.battingOrder == i)
            .length > 0
        ) {
          const lineUpItem = lastTeamLineUps.current.filter(
            item => item.battingOrder == i,
          )[0];
          if (lineUpItem.member) {
            lineUpItem.memberId = lineUpItem.member.id;
          }
          if (lineUpItem) {
            lineUpsTemplate.push(lineUpItem);
          }
        } else {
          lineUpsTemplate.push({
            battingOrder: i === 10 ? null : i,
            position: i === 10 ? '선발투수' : null,
            pitchingOrder: i === 10 ? 1 : null,
            memberId: null,
            teamId: null,
            member: null,
            team: {},
          });
        }
      }
      modifiedTeamLineUps.current = lineUpsTemplate;
    } else {
      modifiedTeamLineUps.current = lastTeamLineUps.current;
    }
    setTeamLineUps(modifiedTeamLineUps.current);
    modifiedCandidateMembers.current = lastTeamCandidates.current;
    reSetCandidateMembers(modifiedTeamLineUps.current, lastTeamMembers.current);
  };

  const [candidateMemberOptions, setCandidateMemberOptions] = useState([]);

  const reSetCandidateMembers = (teamLineUps, teamMembers) => {
    if (!teamLineUps || teamLineUps.length == 0) {
      setCandidateMemberOptions(teamMembers);
      return;
    }
    let ret = teamMembers
      ?.filter(
        i =>
          !teamLineUps
            ?.filter(i => !!i.member)
            .map(i => i.member)
            .map(i => i.id)
            .includes(i.id),
      )
      .filter(i => !!i);
    setCandidateMemberOptions(ret);
  };

  function removeCandidateMembers(index) {
    let newCandidateMembers = [];
    for (let i = 0; i < modifiedCandidateMembers.current.length; i++) {
      if (i == index) {
        continue;
      }
      newCandidateMembers.push(modifiedCandidateMembers.current[i]);
    }
    return newCandidateMembers;
  }

  const getSelectedCandidate = index => {
    if (modifiedCandidateMembers.current.length < index) {
      return 0;
    }
    let memberId = '';
    for (let i = 0; i < modifiedCandidateMembers.current.length; i++) {
      if (i === index) {
        memberId = modifiedCandidateMembers.current[i];
        break;
      }
    }
    if (!memberId) {
      return 0;
    }
    for (let i = 0; i < candidateMemberOptions?.length; i++) {
      const candidateMemberOption = candidateMemberOptions[i];
      if (candidateMemberOption.id === memberId) {
        return i;
      }
    }
    return 0;
  };

  const getTeamMemberMemberIndex = lineUpItem => {
    if (!lineUpItem) {
      return '';
    }
    if (!lineUpItem.member) {
      return '';
    }
    for (let i = 0; i < teamMembers?.length; i++) {
      if (teamMembers[i].id === lineUpItem.member.id) {
        return i;
      }
    }
    return '';
  };

  const getTeamMemberIndexFromMemberId = memberId => {
    if (!memberId) {
      return '';
    }
    for (let i = 0; i < teamMembers?.length; i++) {
      if (teamMembers[i].id === memberId) {
        return i;
      }
    }
    return '';
  };

  const save = temp => {
    const positionSet = new Set();
    const memberIdSet = new Set();
    const batterMemberIds = [];
    const pitcherMemberIds = [];
    const batterMemberIdSet = new Set();
    const pitcherMemberIdSet = new Set();

    const requestItems = [];

    for (let i = 0; i < modifiedTeamLineUps.current.length; i++) {
      const teamLineUpItem = modifiedTeamLineUps.current[i];
      teamLineUpItem.teamId = currentTeamId;
      if (teamLineUpItem.battingOrder) {
        if (lineupBattingMembers.current[teamLineUpItem.battingOrder]) {
          teamLineUpItem.memberId =
            lineupBattingMembers.current[teamLineUpItem.battingOrder];
        } else if (teamLineUpItem.member) {
          teamLineUpItem.memberId = teamLineUpItem.member.id;
        }
        batterMemberIds.push(teamLineUpItem.memberId);
        batterMemberIdSet.add(teamLineUpItem.memberId);
      } else if (teamLineUpItem.pitchingOrder) {
        if (lineupPitchingMembers.current) {
          teamLineUpItem.memberId = lineupPitchingMembers.current;
        } else if (teamLineUpItem.member) {
          teamLineUpItem.memberId = teamLineUpItem.member.id;
        }
        pitcherMemberIds.push(teamLineUpItem.memberId);
        pitcherMemberIdSet.add(teamLineUpItem.memberId);
      }
      if (!teamLineUpItem.position || !teamLineUpItem.memberId) {
        alert('필수 값을 입력해 주세요.');
        return;
      }
      if (teamLineUpItem.position !== '미지정') {
        positionSet.add(teamLineUpItem.position);
      }
      memberIdSet.add(teamLineUpItem.memberId);
      requestItems.push(teamLineUpItem);
    }
    for (let i = 0; i < modifiedCandidateMembers.current.length; i++) {
      if (!modifiedCandidateMembers.current[i]) {
        continue;
      }
      const lineUpItem = {
        teamId: currentTeamId,
        memberId: modifiedCandidateMembers.current[i],
      };
      requestItems.push(lineUpItem);
    }
    if (
      positionSet.size !==
      modifiedTeamLineUps.current.filter(i => i.position !== '미지정').length
    ) {
      console.log(
        positionSet.size,
        modifiedTeamLineUps.current.filter(i => i.position !== '미지정').length,
      );
      console.log(modifiedTeamLineUps.current);
      console.log(positionSet);
      alert('중복된 포지션이 있습니다.');
      return;
    }
    if (
      batterMemberIdSet.size !== batterMemberIds.length ||
      pitcherMemberIdSet.size !== pitcherMemberIds.length
    ) {
      console.log(
        batterMemberIdSet.size,
        batterMemberIds.length,
        pitcherMemberIdSet.size,
        pitcherMemberIds.length,
      );
      alert('중복된 선수가 있습니다.');
      return;
    }
    const body = {
      gameId: data.id,
      items: requestItems,
      lineUpSubmit: true,
      temp: temp ? temp : false,
    };
    console.log('body', body);
    api
      .post('/lineups', body, {
        headers: {
          Authorization: `Bearer ${accessToken.current}`,
        },
      })
      .then(function (response) {
        alert('요청에 성공했습니다.');
        closePopup();
      })
      .catch(function (error) {
        console.log(error.response);
        alert(
          error.response.data?.message
            ? error.response.data?.message
            : '요청에 실패했습니다.',
        );
      });
  };

  const deleteExtraBat = battingOrder => {
    console.log(battingOrder);
    if (!battingOrder || battingOrder <= 9) {
      return;
    }
    let lastBattingOrder = 0;
    for (let i = 0; i < modifiedTeamLineUps.current.length; i++) {
      const lineUp = modifiedTeamLineUps.current[i];
      if (lineUp.battingOrder) {
        lastBattingOrder = lineUp.battingOrder;
      }
    }
    const localTeamLineUps = modifiedTeamLineUps.current.filter(
      i => i.battingOrder !== lastBattingOrder,
    );

    setExtraData(!extraData);
    modifiedTeamLineUps.current = localTeamLineUps;
    setTeamLineUps(modifiedTeamLineUps.current);
    reSetCandidateMembers(modifiedTeamLineUps.current, teamMembers);
  };

  const selectMember = (battingOrder, pitchingOrder, member) => {
    const newData = [];

    console.log(battingOrder, pitchingOrder);
    // batter
    for (let i = 0; i < modifiedTeamLineUps.current.length; i++) {
      const item = JSON.parse(JSON.stringify(modifiedTeamLineUps.current[i]));
      if (!item.battingOrder) {
        continue;
      }
      if (battingOrder == item.battingOrder) {
        item.member = member;
        item.memberId = member.id;
      }
      newData.push(item);
    }

    // pitcher
    for (let i = 0; i < modifiedTeamLineUps.current.length; i++) {
      const item = JSON.parse(JSON.stringify(modifiedTeamLineUps.current[i]));
      if (item.battingOrder) {
        continue;
      }
      if (pitchingOrder == item.pitchingOrder) {
        item.member = member;
        item.memberId = member.id;
      }
      newData.push(item);
    }
    modifiedTeamLineUps.current = newData;

    for (let i = 0; i < 11; i++) {
      if (candidateMemberRefs.current[i * 2]) {
        if (member.id === modifiedCandidateMembers.current[i * 2]) {
          modifiedCandidateMembers.current[i * 2] = null;
          candidateMemberRefs.current[i * 2].reset();
        }
      }
      if (candidateMemberRefs.current[i * 2 + 1]) {
        if (member.id === modifiedCandidateMembers.current[i * 2 + 1]) {
          candidateMemberRefs.current[i * 2 + 1].reset();
          modifiedCandidateMembers.current[i * 2 + 1] = null;
        }
      }
    }
  };

  const selectPosition = (battingOrder, position) => {
    const newData = [];

    // batter
    for (let i = 0; i < modifiedTeamLineUps.current.length; i++) {
      const item = JSON.parse(JSON.stringify(modifiedTeamLineUps.current[i]));
      if (!item.battingOrder) {
        continue;
      }
      if (battingOrder == item.battingOrder) {
        item.position = position;
      }
      newData.push(item);
    }

    // pitcher
    for (let i = 0; i < modifiedTeamLineUps.current.length; i++) {
      const item = JSON.parse(JSON.stringify(modifiedTeamLineUps.current[i]));
      if (item.battingOrder) {
        continue;
      }
      newData.push(item);
    }
    modifiedTeamLineUps.current = newData;
  };

  const editable = useMemo(() => {
    if (currentTeamId === data?.batFirstTeam.id) {
      return data?.batFirstTeamManagedByMe;
    } else if (currentTeamId === data?.batLastTeam.id) {
      return data?.batLastTeamManagedByMe;
    }
    return false;
  }, [currentTeamId, data]);

  const candidateMembersWithUserData = useMemo(() => {
    if (currentTeamId === data?.batFirstTeam.id) {
      return firstTeamCandidatesWidthUserData.current;
    } else if (currentTeamId === data?.batLastTeam.id) {
      return lastTeamCandidatesWithUserData.current;
    }
  }, [currentTeamId, data]);

  const insertAllCandidates = () => {
    const memberIdsInLineUp = modifiedTeamLineUps.current?.map(i => i.memberId);
    modifiedCandidateMembers.current = [];

    for (let i = 0; i < 11; i++) {
      if (candidateMemberRefs.current[i * 2]) {
        candidateMemberRefs.current[i * 2].reset();
      }
      if (candidateMemberRefs.current[i * 2 + 1]) {
        candidateMemberRefs.current[i * 2 + 1].reset();
      }
    }

    let pushIndex = 0;
    for (let i = 0; i < teamMembers?.length; i++) {
      if (!teamMembers[i].id) {
        continue;
      }
      if (memberIdsInLineUp.includes(teamMembers[i].id)) {
        continue;
      }
      if (candidateMemberRefs.current[pushIndex]) {
        modifiedCandidateMembers.current.push(teamMembers[i].id);
        candidateMemberRefs.current[pushIndex].selectIndex(
          getTeamMemberIndexFromMemberId(teamMembers[i].id),
        );
        pushIndex++;
      }
    }
  };

  const addRemainCandidates = () => {
    Alert.alert(
      '대기명단 추가',
      '라인업과 대기명단에 추가되지 않은 남은 회원을 모두 대기명단에 추가하시겠습니까?',
      [
        {
          text: '예',
          onPress: () => {
            insertAllCandidates();
          },
        },
        {
          text: '아니오',
        },
      ],
    );
  };

  const renderHeader = () => {
    return (
      <View>
        <View>
          <Text style={[pageStyle.date]}>
            {parseDate(data?.date)} {parseTime(data?.time)}
          </Text>
          <ImageBackground
            resizeMode="cover"
            style={[{height: 40, width: '100%', marginTop: 8}]}
            imageStyle={[{width: '100%'}]}
            source={require('../../assets/images/belt_big.png')}>
            <View style={[pageStyle.teamContainer]}>
              <Text style={[pageStyle.homeAwayTxt]}>AWAY</Text>
              <TouchableOpacity
                onPress={selectFirstBatTeam}
                style={[styles.col, pageStyle.teamLeft]}>
                <Image
                  style={[
                    pageStyle.leftMarker,
                    currentTeamId !== data.batFirstTeam.id && {
                      display: 'none',
                    },
                  ]}
                  source={require('../../assets/images/icn_current_team.png')}
                />
                <Shadow offset={[3, 4]} distance={4}>
                  <View style={[pageStyle.logoContainer]}>
                    <Image
                      style={[
                        pageStyle.logoImg,
                        !data.batFirstTeamLogoImageUrl && {
                          opacity: 0.4,
                        },
                      ]}
                      source={
                        !data?.batFirstTeamLogoImageUrl
                          ? require('../../assets/images/nologo.png')
                          : {uri: data?.batFirstTeamLogoImageUrl}
                      }
                      resizeMode={'cover'}
                    />
                  </View>
                </Shadow>
              </TouchableOpacity>
              <View style={[{width: '40%'}]}>
                <View style={[pageStyle.teamTextContainer]}>
                  <Text
                    numberOfLines={1}
                    style={[
                      pageStyle.teamTxt,
                      currentTeamId !== data.batFirstTeam.id &&
                        pageStyle.inactive,
                    ]}
                  />
                  <Text style={[pageStyle.teamVs]} />
                  <Text
                    numberOfLines={1}
                    style={[
                      pageStyle.teamTxt,
                      currentTeamId !== data.batLastTeam.id &&
                        pageStyle.inactive,
                    ]}
                  />
                </View>
              </View>
              <TouchableOpacity
                onPress={selectLastBatTeam}
                style={[styles.col, pageStyle.teamRight]}>
                <Image
                  style={[
                    pageStyle.rightMarker,
                    currentTeamId !== data.batLastTeam.id && {
                      display: 'none',
                    },
                  ]}
                  source={require('../../assets/images/icn_current_team.png')}
                />
                <Shadow offset={[3, 4]} distance={4}>
                  <View style={[pageStyle.logoContainer]}>
                    <Image
                      style={[
                        pageStyle.logoImg,
                        !data.batLastTeamLogoImageUrl && {opacity: 0.4},
                      ]}
                      source={
                        !data?.batLastTeamLogoImageUrl
                          ? require('../../assets/images/nologo.png')
                          : {uri: data?.batLastTeamLogoImageUrl}
                      }
                      resizeMode={'cover'}
                    />
                  </View>
                </Shadow>
              </TouchableOpacity>
              <Text style={[pageStyle.homeAwayTxt]}>HOME</Text>
            </View>
          </ImageBackground>
          <ImageBackground
            resizeMode="cover"
            style={[
              {
                justifyContent: 'center',
                alignItems: 'center',
                height: 30,
                marginTop: 10,
              },
            ]}
            imageStyle={[{width: '100%'}]}
            source={require('../../assets/images/belt_small.png')}>
            <View style={[pageStyle.teamContainer]}>
              <Text
                numberOfLines={1}
                style={[
                  {flex: 1, textAlign: 'center', marginTop: 12},
                  pageStyle.teamTxt,
                  currentTeamId !== data.batFirstTeam.id && pageStyle.inactive,
                ]}>
                {data?.batFirstTeamName}
              </Text>
              <Text
                numberOfLines={1}
                style={[
                  {flex: 1, textAlign: 'center', marginTop: 12},
                  pageStyle.teamTxt,
                  currentTeamId !== data.batLastTeam.id && pageStyle.inactive,
                ]}>
                {data?.batLastTeamName}
              </Text>
            </View>
          </ImageBackground>
          <View style={[pageStyle.locationInfo]}>
            <Text style={[pageStyle.locationInfoTxt]}>
              {data?.leagueName} {data?.subLeagueName}
              {'\n'}
              {data?.stadium?.name}
            </Text>
          </View>
        </View>
        <View style={[styles.row, pageStyle.tableHeader]}>
          <Text style={[pageStyle.headerNumber]} />
          <Text style={[pageStyle.headerTxt, pageStyle.teamAway]}>
            {currentTeamId === data.batFirstTeam.id ? 'AWAY TEAM' : 'HOME TEAM'}
          </Text>
          <View />
        </View>
        <View style={[styles.row, {marginStart: 24, marginEnd: 22 + 34}]}>
          <Text style={[pageStyle.colHeader, pageStyle.colBattingOrder]}>
            타순
          </Text>
          <Text style={[pageStyle.colHeader, pageStyle.colPosition]}>
            포지션
          </Text>
          <Text style={[pageStyle.colHeader, pageStyle.colPlayerName]}>
            선수명
          </Text>
        </View>
      </View>
    );
  };

  const renderFooter = () => {
    return (
      <View style={{marginHorizontal: 24, marginBottom: insets.bottom + 30}}>
        {editable && (
          <View style={[{marginTop: 12, marginBottom: 50}]}>
            <View style={[styles.row, {justifyContent: 'space-between'}]}>
              <TouchableOpacity onPress={addHitter}>
                <Text
                  style={[
                    {color: Colors.main, fontSize: 14, fontWeight: 'bold'},
                  ]}>
                  +타자추가
                </Text>
              </TouchableOpacity>
              <TouchableOpacity onPress={addRemainCandidates}>
                <Text
                  style={[
                    {color: Colors.main, fontSize: 14, fontWeight: 'bold'},
                  ]}>
                  잔여 인원 일괄 추가
                </Text>
              </TouchableOpacity>
            </View>
            <Text
              style={[
                {
                  paddingVertical: 6,
                  paddingHorizontal: 8,
                  backgroundColor: Colors.main,
                  color: 'white',
                  fontSize: 14,
                  marginTop: 12,
                  fontWeight: '600',
                  textAlign: 'center',
                },
              ]}>
              대기명단
            </Text>
            <View style={[]}>
              {Array.from({length: 11}, (item, rowIndex) => {
                return (
                  <View
                    style={[styles.row]}
                    key={`candidate_row_${2 * rowIndex}`}>
                    <View
                      style={[
                        styles.col2,
                        pageStyle.td,
                        pageStyle.tdLeftFirst,
                      ]}>
                      <SelectDropdown
                        ref={el =>
                          (candidateMemberRefs.current[2 * rowIndex] = el)
                        }
                        defaultButtonText="선택"
                        defaultValueByIndex={getSelectedCandidate(2 * rowIndex)}
                        buttonStyle={pageStyle.dropDown}
                        buttonTextStyle={pageStyle.downDownText}
                        rowTextStyle={styles.dropDownRowText}
                        renderDropdownIcon={() => (
                          <Image
                            resizeMode="cover"
                            source={require('../../assets/images/select_arr.png')}
                          />
                        )}
                        data={teamMembers}
                        onSelect={(selectedItem, index) => {
                          if (selectedItem.text === '선택') {
                            modifiedCandidateMembers.current =
                              removeCandidateMembers(2 * rowIndex);
                            return;
                          }
                          if (
                            modifiedCandidateMembers.current.includes(
                              selectedItem.id,
                            ) ||
                            modifiedTeamLineUps.current
                              ?.map(i => i.memberId)
                              ?.includes(selectedItem.id)
                          ) {
                            return;
                          }
                          if (
                            modifiedCandidateMembers.current?.length >
                            2 * rowIndex
                          ) {
                            const newArray = [
                              ...modifiedCandidateMembers.current,
                            ];
                            newArray.splice(2 * rowIndex, 1, selectedItem.id);
                            modifiedCandidateMembers.current = newArray;
                          } else {
                            modifiedCandidateMembers.current = [
                              ...modifiedCandidateMembers.current,
                              selectedItem.id,
                            ];
                          }
                        }}
                        buttonTextAfterSelection={(selectedItem, index) => {
                          if (selectedItem.text === '선택') {
                            return '선택';
                          }
                          let localSelectedMemberId =
                            modifiedCandidateMembers.current[2 * rowIndex];
                          if (!localSelectedMemberId) {
                            return '선택';
                          }
                          let localSelectedItem = teamMembers.filter(
                            i => i.id == localSelectedMemberId,
                          )[0];
                          if (!localSelectedItem) {
                            return '';
                          }
                          return localSelectedItem.number
                            ? `${localSelectedItem.user.name} (${localSelectedItem.number})`
                            : `${localSelectedItem.user.name}`;
                        }}
                        rowTextForSelection={(item, index) => {
                          if (item.text === '선택') {
                            return '선택';
                          }
                          return item.number
                            ? `${item.user.name} (${item.number})`
                            : `${item.user.name}`;
                        }}
                      />
                    </View>

                    <View style={[styles.col2, pageStyle.td]}>
                      <SelectDropdown
                        ref={el =>
                          (candidateMemberRefs.current[2 * rowIndex + 1] = el)
                        }
                        defaultButtonText="선택"
                        defaultValueByIndex={getSelectedCandidate(
                          2 * rowIndex + 1,
                        )}
                        buttonStyle={pageStyle.dropDown}
                        buttonTextStyle={pageStyle.downDownText}
                        rowTextStyle={styles.dropDownRowText}
                        renderDropdownIcon={() => (
                          <Image
                            source={require('../../assets/images/select_arr.png')}
                          />
                        )}
                        data={teamMembers}
                        onSelect={(selectedItem, index) => {
                          if (selectedItem.text === '선택') {
                            modifiedCandidateMembers.current =
                              removeCandidateMembers(2 * rowIndex + 1);
                            return;
                          }
                          if (
                            modifiedCandidateMembers.current.includes(
                              selectedItem.id,
                            ) ||
                            modifiedTeamLineUps.current
                              ?.map(i => i.memberId)
                              ?.includes(selectedItem.id)
                          ) {
                            return;
                          }
                          if (
                            modifiedCandidateMembers.current?.length >
                            2 * rowIndex + 1
                          ) {
                            const newArray = [
                              ...modifiedCandidateMembers.current,
                            ];
                            newArray.splice(
                              2 * rowIndex + 1,
                              1,
                              selectedItem.id,
                            );
                            modifiedCandidateMembers.current = newArray;
                          } else {
                            modifiedCandidateMembers.current = [
                              ...modifiedCandidateMembers.current,
                              selectedItem.id,
                            ];
                          }
                        }}
                        buttonTextAfterSelection={(selectedItem, index) => {
                          if (selectedItem.text === '선택') {
                            return '선택';
                          }
                          let localSelectedMemberId =
                            modifiedCandidateMembers.current[2 * rowIndex + 1];
                          let localSelectedItem = teamMembers.filter(
                            i => i.id == localSelectedMemberId,
                          )[0];
                          if (!localSelectedMemberId) {
                            return '선택';
                          }
                          return localSelectedItem.number
                            ? `${localSelectedItem.user.name} (${localSelectedItem.number})`
                            : `${localSelectedItem.user.name}`;
                        }}
                        rowTextForSelection={(item, index) => {
                          if (item.text === '선택') {
                            return '선택';
                          }
                          return item.number
                            ? `${item.user.name} (${item.number})`
                            : `${item.user.name}`;
                        }}
                      />
                    </View>
                  </View>
                );
              })}
            </View>
          </View>
        )}
        {!editable && (
          <View style={[{paddingBottom: 180}]}>
            <Text
              style={[
                {
                  paddingVertical: 6,
                  paddingHorizontal: 8,
                  backgroundColor: Colors.main,
                  color: 'white',
                  fontSize: 14,
                  marginTop: 12,
                  fontWeight: '600',
                  textAlign: 'center',
                },
              ]}>
              대기명단
            </Text>
            <View>
              {candidateMembersWithUserData &&
                candidateMembersWithUserData.map((item, index) => {
                  return (
                    <View
                      key={`candidate_index_${index}`}
                      style={[
                        styles.col,
                        pageStyle.td,
                        pageStyle.tdLeftFirst,
                        {
                          flexDirection: 'row',
                          paddingVertical: 4,
                          alignItems: 'center',
                          justifyContent: 'center',
                        },
                      ]}>
                      <Text style={[pageStyle.tdTxt, {textAlign: 'center'}]}>
                        {item?.user?.name} ({item?.number})
                      </Text>
                    </View>
                  );
                })}
            </View>
          </View>
        )}
        {editable && (
          <View style={[styles.row, pageStyle.buttonContainer]}>
            <TouchableOpacity
              onPress={() => save(true)}
              style={[pageStyle.cancelBtn]}>
              <Text style={[pageStyle.btnText, pageStyle.cancelBtnTxt]}>
                임시저장
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => save(false)}
              style={[pageStyle.saveBtn]}>
              <Text style={[pageStyle.btnText]}>라인업 제출</Text>
            </TouchableOpacity>
          </View>
        )}
      </View>
    );
  };

  const renderItem = gestureHandlerRootHOC(({item, drag, isActive}) => (
    <ScaleDecorator>
      {editable && (
        <TouchableOpacity
          onLongPress={drag}
          disabled={isActive && item.battingOrder <= 9}
          style={[
            styles.row,
            {marginStart: 24, marginEnd: 22 + 34, position: 'relative'},
          ]}>
          <View style={[styles.col, pageStyle.td, pageStyle.tdLeftFirst]}>
            <Text style={[pageStyle.number]}>
              {item.battingOrder ? item.battingOrder : '투수'}
            </Text>
          </View>
          <View style={[styles.col2, pageStyle.td]}>
            {item.battingOrder && (
              <SelectDropdown
                defaultButtonText="선택"
                defaultValue={item.position}
                value={item.position}
                buttonStyle={pageStyle.dropDown}
                buttonTextStyle={pageStyle.downDownText}
                rowTextStyle={styles.dropDownRowText}
                renderDropdownIcon={() => (
                  <Image
                    source={require('../../assets/images/select_arr.png')}
                  />
                )}
                data={[
                  '투수',
                  '포수',
                  '1루수',
                  '2루수',
                  '3루수',
                  '유격수',
                  '좌익수',
                  '중견수',
                  '우익수',
                  '지명타자',
                  '미지정',
                ]}
                onSelect={(selectedItem, index) => {
                  selectPosition(item.battingOrder, selectedItem);
                }}
                buttonTextAfterSelection={(selectedItem, index) => {
                  return selectedItem;
                }}
                rowTextForSelection={(item, index) => {
                  return item;
                }}
              />
            )}
            {!item.battingOrder && (
              <Text style={[pageStyle.pitcherTxt]}>선발투수</Text>
            )}
          </View>
          <View style={[styles.col3, pageStyle.td]}>
            <SelectDropdown
              defaultButtonText="선택"
              defaultValueByIndex={getTeamMemberMemberIndex(item)}
              buttonStyle={pageStyle.dropDown}
              buttonTextStyle={pageStyle.downDownText}
              rowTextStyle={styles.dropDownRowText}
              renderDropdownIcon={() => (
                <Image source={require('../../assets/images/select_arr.png')} />
              )}
              data={teamMembers}
              onSelect={(selectedItem, index) => {
                if (item.battingOrder) {
                  lineupBattingMembers.current = {
                    ...lineupBattingMembers.current,
                    [item.battingOrder]: selectedItem.id,
                  };
                } else {
                  lineupPitchingMembers.current = selectedItem.id;
                }
                selectMember(
                  item.battingOrder,
                  item.pitchingOrder,
                  selectedItem,
                );
              }}
              buttonTextAfterSelection={(selectedItem, index) => {
                if (selectedItem.text === '선택') {
                  return '선택';
                }
                return selectedItem.number
                  ? `${selectedItem.user.name} (${selectedItem.number})`
                  : `${selectedItem.user.name}`;
              }}
              rowTextForSelection={(item, index) => {
                if (item.text === '선택') {
                  return '선택';
                }
                return item.number
                  ? `${item.user.name} (${item.number})`
                  : `${item.user.name}`;
              }}
            />
          </View>
          {item.battingOrder > 9 && (
            <TouchableOpacity
              style={[{position: 'absolute', right: -30, top: 4}]}
              onPress={() => deleteExtraBat(item.battingOrder)}>
              <Image source={require('../../assets/images/close.png')} />
            </TouchableOpacity>
          )}
          {item.battingOrder && item.battingOrder <= 9 && (
            <View style={[{position: 'absolute', right: -30, top: 6}]}>
              <Image
                resizeMode="contain"
                style={[{width: 25}]}
                source={require('../../assets/images/dropdown_icn.png')}
              />
            </View>
          )}
        </TouchableOpacity>
      )}
      {!editable && (
        <View style={[styles.row, {marginStart: 24, marginEnd: 22 + 34}]}>
          <View style={[styles.col, pageStyle.td, pageStyle.tdLeftFirst]}>
            <Text
              style={[
                pageStyle.number,
                item.pitchingOrder && pageStyle.pitcher,
                {width: 50},
              ]}>
              {item.pitchingOrder ? '투수' : item.battingOrder}
            </Text>
          </View>
          <View style={[styles.col2, pageStyle.td]}>
            <Text style={[pageStyle.tdTxt]}>{item.position}</Text>
          </View>
          <View style={[styles.col3, pageStyle.td]}>
            {item.member && (
              <Text style={[pageStyle.tdTxt]}>
                {item.member?.user.name} ({item.member?.number})
              </Text>
            )}
          </View>
        </View>
      )}
    </ScaleDecorator>
  ));

  const DraggList = gestureHandlerRootHOC(() => (
    <DraggableFlatList
      showsVerticalScrollIndicator={false}
      style={[pageStyle.listContainer]}
      extraData={extraData}
      ListHeaderComponent={renderHeader}
      ListFooterComponent={renderFooter}
      keyExtractor={(item, index) =>
        item.battingOrder
          ? `item_batter_${item.battingOrder}_${index}`
          : `item_pitcher_${item.pitchingOrder}_${index}`
      }
      data={teamLineUps}
      renderItem={renderItem}
      onDragEnd={({data}) => reArrangeLineUps(data)}
    />
  ));

  const reArrangeLineUps = data => {
    const reArrangedData = JSON.parse(JSON.stringify(data));
    let battingOrderCount = 0;
    const newData = [];

    // batter
    for (let i = 0; i < reArrangedData.length; i++) {
      const item = reArrangedData[i];
      if (!item.battingOrder) {
        continue;
      }
      const matchedLineUps = modifiedTeamLineUps.current.filter(
        i => i.battingOrder == item.battingOrder,
      );
      if (matchedLineUps && matchedLineUps.length > 0) {
        item.position = matchedLineUps[0].position;
        item.member = matchedLineUps[0].member;
        item.memberId = matchedLineUps[0].memberId;
      }
      battingOrderCount++;
      item.battingOrder = battingOrderCount;
      newData.push(item);
    }

    // pitcher
    for (let i = 0; i < reArrangedData.length; i++) {
      const item = reArrangedData[i];
      if (item.battingOrder) {
        continue;
      }
      newData.push(item);
    }
    modifiedTeamLineUps.current = newData;
    setTeamLineUps(modifiedTeamLineUps.current);
    reSetCandidateMembers(modifiedTeamLineUps.current, teamMembers);
  };

  const fullPopupWidth = useMemo(() => {
    if (Platform.OS === 'web') {
      const windowWidth = Dimensions.get('window').width;
      if (windowWidth < 550) {
        return windowWidth;
      } else {
        return 500;
      }
    }
    return Dimensions.get('window').width;
  }, []);

  const pageStyle = StyleSheet.create({
    container: {
      zIndex: 100,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      justifyContent: 'center',
      alignItems: 'center',
      alignContent: 'center',
    },
    innerContainer: {
      backgroundColor: 'white',
      flex: 1,
      marginTop: 67,
      width: fullPopupWidth,
    },
    header: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    back: {
      zIndex: 2,
    },
    title: {
      color: Colors.main,
      fontSize: 24,
      fontWeight: '600',
      position: 'absolute',
      start: 0,
      textAlign: 'center',
      width: fullPopupWidth,
      fontStyle: 'italic',
    },
    contentsContainer: {
      flexDirection: 'column',
      flexGrow: 1,
      justifyContent: 'space-between',
    },
    contentsInnerContainer: {
      flex: 1,
    },
    date: {
      color: 'black',
      fontSize: 16,
      fontWeight: '600',
      textAlign: 'center',
    },
    locationInfo: {marginTop: 10, position: 'relative'},
    locationInfoTxt: {
      color: 'black',
      fontSize: 14,
      fontWeight: '300',
      lineHeight: 18,
      textAlign: 'center',
    },
    teamContainer: {
      marginTop: 5,
      marginBottom: 20,
      flexDirection: 'row',
      height: 29,
      width: '100%',
      alignItems: 'center',
      alignContent: 'center',
    },
    teamLeft: {
      flexDirection: 'row',
      justifyContent: 'flex-end',
      marginTop: -8,
      position: 'relative',
    },
    rightMarker: {
      position: 'absolute',
      top: -18,
      left: 18,
    },
    leftMarker: {
      position: 'absolute',
      top: -18,
      right: 18,
    },
    teamRight: {
      flexDirection: 'row',
      justifyContent: 'flex-start',
      marginTop: -8,
      position: 'relative',
    },
    teamTextContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      marginStart: 10,
      marginEnd: 10,
      alignItems: 'center',
    },
    teamTxt: {color: 'white', fontSize: 18, lineHeight: 18, fontWeight: '800'},
    inactive: {opacity: 0.4},
    teamVs: {
      fontSize: 23,
      fontWeight: 'bold',
      color: 'white',
      marginStart: 10,
      marginEnd: 10,
    },
    logoContainer: {
      width: 54,
      height: 54,
      borderRadius: 27,
      overflow: 'hidden',
      backgroundColor: 'white',
    },
    logoImg: {
      width: 54,
      height: 54,
    },
    homeAwayTxt: {
      color: 'white',
      fontSize: 14,
      marginHorizontal: 8,
      fontStyle: 'italic',
    },
    listContainer: {},
    td: {
      paddingVertical: 4,
      borderEndColor: Colors.main,
      borderEndWidth: 1,
      borderBottomColor: Colors.main,
      borderBottomWidth: 1,
      textAlign: 'center',
      alignItems: 'center',
      justifyContent: 'center',
      height: 38,
    },
    tdLeftFirst: {
      borderStartColor: Colors.main,
      borderStartWidth: 1,
    },
    tableHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      paddingBottom: 15,
      marginTop: 25,
    },
    col0dot3: {
      flex: 0.3,
    },
    colHeader: {
      backgroundColor: Colors.main,
      color: 'white',
      padding: 5,
      fontWeight: '500',
    },
    colBattingOrder: {
      flex: 1,
      textAlign: 'center',
      backgroundColor: Colors.main,
    },
    colPosition: {
      flex: 2,
      textAlign: 'center',
      backgroundColor: Colors.main,
    },
    colPlayerName: {
      flex: 3,
      textAlign: 'center',
      backgroundColor: Colors.main,
    },
    headerNumber: {
      color: 'black',
      fontSize: 13,
      fontWeight: '300',
    },
    headerTxt: {
      fontSize: 18,
      fontWeight: '600',
    },
    teamAway: {
      color: Colors.main,
    },
    teamHome: {
      color: Colors.red,
    },
    number: {
      alignItems: 'center',
      color: 'black',
      fontSize: 15,
      fontWeight: '600',
      justifyContent: 'center',
      lineHeight: 30,
      textAlign: 'center',
    },
    buttonContainer: {
      marginStart: 51,
      marginEnd: 51,
      paddingVertical: 12,
    },
    saveBtn: {
      flex: 1,
      height: 34,
      backgroundColor: Colors.main,
      borderRadius: 17,
      alignItems: 'center',
      alignContent: 'center',
      justifyContent: 'center',
      marginStart: 8,
    },
    cancelBtn: {
      flex: 1,
      height: 34,
      backgroundColor: '#ccc',
      borderRadius: 17,
      alignItems: 'center',
      alignContent: 'center',
      justifyContent: 'center',
      marginEnd: 8,
    },
    cancelBtnTxt: {
      color: 'red',
    },
    btnText: {
      color: 'white',
      fontSize: 18,
    },
    dropdownRowContainer: {
      display: 'flex',
      width: 222,
      marginTop: 6,
      flexDirection: 'row',
    },
    dropDownItemContainer: {
      marginHorizontal: 3,
      flex: 1,
    },
    dropDown: {
      width: '100%',
      backgroundColor: 'transparent',
      height: 30,
    },
    downDownText: {
      color: 'black',
      fontSize: 15,
    },
    pitcherTxt: {
      color: 'black',
      width: '100%',
      height: 24,
      lineHeight: 24,
      fontSize: 15,
      textAlign: 'center',
    },
    selectArr: {
      height: 8,
    },
  });

  return (
    <Modal transparent={true} overFullScreen={true}>
      <View style={[pageStyle.container]}>
        <View
          style={[
            Platform.OS === 'web' && {
              height: Dimensions.get('window').height - (insets.top + 67),
            },
          ]}
          showsVerticalScrollIndicator={Platform.OS === 'web'}>
          <View
            style={[pageStyle.innerContainer, {marginTop: insets.top + 67}]}>
            <View style={[styles.headerCommon, pageStyle.header]}>
              <View style={[styles.row, styles.verticalAlign, pageStyle.back]}>
                <TouchableOpacity onPress={() => closePopup()}>
                  <Image
                    source={require('../../assets/images/icon_back.png')}
                    style={[styles.headerBackButton]}
                  />
                </TouchableOpacity>
              </View>
              <Text style={[pageStyle.title]}>LINE-UP</Text>
            </View>
            <DraggList />
          </View>
        </View>
      </View>
    </Modal>
  );
};

export default LineUpFormPopup;

import React, {useEffect, useState, useRef, useCallback, useMemo} from 'react';
import {
  Image,
  SafeAreaView,
  DeviceEventEmitter,
  Text,
  TouchableOpacity,
  View,
  StyleSheet,
  FlatList,
  TextInput,
  Modal,
  Alert,
  Dimensions,
  KeyboardAvoidingView,
  Platform,
} from 'react-native';
import styles from '../common/styles';
import {Shadow} from 'react-native-shadow-2';
import AsyncStorage from '@react-native-async-storage/async-storage';
import api from '../common/api';
import * as Progress from 'react-native-progress';
import moment from 'moment';
import Colors from '../common/colors';
import {launchImageLibrary} from 'react-native-image-picker';
import {useSafeAreaInsets} from 'react-native-safe-area-context';
import alert from '../component/alert';
import {title} from '../common/util';

const CommentView = ({navigation, route}) => {
  const {boardId, boardType, userData, boardWriter} = route.params;
  const [commentCount, setCommentCount] = useState(route.params.commentCount);
  const [page, setPage] = useState({});
  const [progressShown, setProgressShown] = useState(false);

  const [menuOpened, setMenuOpened] = useState(false);

  const [currentIsMine, setCurrentIsMine] = useState(false);

  const [alarmUnread, setAlarmUnread] = useState(false);
  const [commentContents, setCommentContents] = useState('');

  const [commentImage, setCommentImage] = useState();

  const commentContentsRef = useRef();
  let pageLoading = useRef(false);

  let accessToken = useRef();
  let subscription = useRef();

  const insets = useSafeAreaInsets();

  useEffect(() => {
    AsyncStorage.getItem('@accessToken', (err, result) => {
      if (err) {
        return;
      }
      accessToken.current = result;
      getData();
      checkAlarmUnRead();
    });

    if (!subscription.current) {
      subscription.current = DeviceEventEmitter.addListener(
        '@commentRefresh',
        function (data) {
          console.log('@commentRefresh#3');
          getData();
        },
      );
    } else {
      subscription.current.remove();
      subscription.current = DeviceEventEmitter.addListener(
        '@commentRefresh',
        function (data) {
          console.log('@commentRefresh#4');
          getData();
        },
      );
    }
  }, []);

  const openImageLibrary = () => {
    if (commentImage) {
      alert('이미지 첨부는 1개만 가능합니다.');
      return;
    }
    launchImageLibrary(
      {
        mediaType: 'photo',
        includeBase64: true,
        maxHeight: 500,
        maxWidth: 500,
      },
      response => {
        if (
          !response.errorCode &&
          response.assets &&
          response.assets.length > 0 &&
          response.assets[0] &&
          response.assets[0].base64
        ) {
          setCommentImage('data:image/png;base64,' + response.assets[0].base64);
        }
      },
    );
  };

  const getData = () => {
    if (!accessToken.current) {
      return;
    }
    if (pageLoading.current) {
      return;
    }
    pageLoading.current = true;
    setProgressShown(true);
    api
      .get(`/boards/${boardId}/comments`, {
        headers: {
          Authorization: `Bearer ${accessToken.current}`,
        },
      })
      .then(function (response) {
        setPage(response.data);
      })
      .catch(function (error) {
        console.log(error);
      })
      .finally(() => {
        pageLoading.current = false;
        setProgressShown(false);
      });
  };

  const handlePageLoadMore = () => {
    if (pageLoading.current) {
      return;
    }
    if (page.currentPage && page.totalPage <= page.currentPage) {
      return;
    }
    setProgressShown(true);
    pageLoading.current = true;
    api
      .get(
        `boards/${boardId}/comments?pageNo=${
          page.currentPage ? parseInt(page.currentPage, 10) + 1 : 1
        }&pageSize=20`,
        {
          headers: {
            Authorization: `Bearer ${accessToken.current}`,
          },
        },
      )
      .then(function (response) {
        response.data.items = [
          ...(page.items ? page.items : []),
          ...response.data.items,
        ];
        setPage(response.data);
      })
      .catch(function (error) {
        console.log(error);
      })
      .finally(() => {
        setProgressShown(false);
        pageLoading.current = false;
      });
  };

  const checkAlarmUnRead = () => {
    if (!accessToken.current) {
      return;
    }
    api
      .get('/users/exists-unread-alarm', {
        headers: {
          Authorization: `Bearer ${accessToken.current}`,
        },
      })
      .then(function (response) {
        setAlarmUnread(response.data);
      })
      .catch(function (error) {
        console.error(error.response);
      });
  };

  const currentCommentItem = useRef();

  const modifyComment = () => {
    setMenuOpened(false);
    navigation.navigate('EditComment', {
      commentId: currentCommentItem.current.id,
      contents: currentCommentItem.current.contents,
      imageUrl: currentCommentItem.current.imageUrl,
      boardType: boardType,
    });
  };

  const deleteComment = () => {
    alert('댓글', '삭제 하시겠습니까?', [
      {
        text: '예',
        onPress: () => callDeleteCommentListApi(),
      },
      {
        text: '아니오',
      },
    ]);
  };

  const writeComment = () => {
    alert('댓글', '작성 하시겠습니까?', [
      {
        text: '예',
        onPress: () => callWriteCommentApi(),
      },
      {
        text: '아니오',
      },
    ]);
  };

  const callDeleteCommentListApi = () => {
    const id = currentCommentItem.current.id;
    console.log(`/boards/comments/${id}`);
    api
      .delete(`/boards/comments/${id}`, {
        headers: {
          Authorization: `Bearer ${accessToken.current}`,
        },
      })
      .then(function (response) {
        DeviceEventEmitter.emit('@commentRefresh');
        DeviceEventEmitter.emit('communityListRefresh');
        setCommentCount(commentCount - 1 > 0 ? commentCount - 1 : 0);
        setMenuOpened(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const callWriteCommentApi = () => {
    api
      .post(
        `/boards/${boardId}/comments`,
        {
          contents: commentContentsRef.current,
          imageUrl: commentImage,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken.current}`,
          },
        },
      )
      .then(function (response) {
        setCommentContents('');
        setCommentImage('');
        DeviceEventEmitter.emit('@commentRefresh');
        DeviceEventEmitter.emit('communityListRefresh');
        setCommentCount(commentCount + 1 > 0 ? commentCount + 1 : 0);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getProfileImageUrl = item => {
    if (item.user?.profileImageThumbUrl) {
      return {uri: item.user?.profileImageThumbUrl};
    }
    if (item.user?.profileImageUrl) {
      return {uri: item.user?.profileImageUrl};
    }
    return require('../assets/images/icn_profile_img.png');
  };

  const parseDate = date => {
    if (date.indexOf('T') > -1) {
      return moment(date).format('YY.MM.DD   HH:mm');
    }
    return moment(date, 'YYYY-MM-DD').locale('ko').format('MM/DD(dd)');
  };

  const toggleMenu = (commentItem, targetCommentId, targetUserId) => {
    currentCommentItem.current = commentItem;
    if (targetCommentId) {
      currentCommentItem.current.targetCommentId = targetUserId;
    }
    if (targetUserId) {
      currentCommentItem.current.targetUserId = targetUserId;
    }
    setCurrentIsMine(commentItem.user.id === userData.id);
    setMenuOpened(true);
  };

  const deleteCommentImage = () => {
    setCommentImage(null);
  };

  const handleBlockUser = targetUserId => {
    Alert.alert('사용자 차단', '사용자 차단 하시겠습니까?', [
      {
        text: '예',
        onPress: () => {
          api
            .post(
              `/users/block-users`,
              {
                targetUserId: targetUserId,
              },
              {
                headers: {
                  Authorization: `Bearer ${accessToken.current}`,
                },
              },
            )
            .then(function (response) {
              setMenuOpened(false);
              alert('차단 신청이 완료되었습니다.');
            })
            .catch(function (error) {
              console.log(error);
            });
        },
      },
      {
        text: '아니오',
      },
    ]);
  };

  return (
    <>
      <SafeAreaView nativeID="common-page-container" style={[styles.container]}>
        <Shadow
          offset={[0, 2]}
          style={[{width: '100%'}]}
          containerStyle={[{marginTop: 0}]}
          distance={7}
          sides={{bottom: true, top: false, start: false, end: false}}>
          <View style={[styles.headerCommon]}>
            <View style={[styles.row, styles.verticalAlign]}>
              <TouchableOpacity
                onPress={() =>
                  Platform.OS === 'web' ? history.back() : navigation.goBack()
                }>
                <Image
                  source={require('../assets/images/icon_back.png')}
                  style={[styles.headerBackButton]}
                />
              </TouchableOpacity>
              <View style={[pageStyle.commentHeaderContainer]}>
                <Text style={[styles.headerText]}>
                  댓글 {commentCount?.toLocaleString()}
                </Text>
                <Text style={[pageStyle.boardType]}>{boardType}</Text>
              </View>
            </View>
            <View style={[styles.row, styles.verticalAlign]}>
              <TouchableOpacity
                style={[styles.alarmContainer]}
                onPress={() => navigation.navigate('MyAlarmList')}>
                <Image
                  resizeMode="contain"
                  style={[styles.imgAlarm]}
                  source={require('../assets/images/icn_alarm.png')}
                />
                {alarmUnread && <View style={[styles.alarmDot]} />}
              </TouchableOpacity>
            </View>
          </View>
        </Shadow>
        {Platform.OS === 'android' && (
          <>
            <View
              style={[
                {
                  marginTop: 10,
                  position: 'relative',
                  flex: 1,
                },
              ]}>
              <FlatList
                nestedScrollEnabled={true}
                style={[pageStyle.list]}
                keyExtractor={(item, index) => `comment_${index.toString()}`}
                data={page.items}
                onEndReachedThreshold={1}
                onEndReached={handlePageLoadMore}
                renderItem={({item}) => (
                  <View
                    style={[
                      item.deleted &&
                        item.childs &&
                        item.childs.length === 0 && {display: 'none'},
                    ]}>
                    <View style={[pageStyle.commentItemContainer]}>
                      <Image
                        style={[
                          {
                            width: 40,
                            height: 40,
                            resizeMode: 'cover',
                            borderRadius: 20,
                          },
                        ]}
                        source={getProfileImageUrl(item)}
                      />

                      <View style={[pageStyle.rightContainer]}>
                        <TouchableOpacity
                          onPress={() =>
                            navigation.navigate('MemberPage', {
                              item: {uid: item.user.uid},
                              itemUid: item.user.uid,
                            })
                          }
                          style={[
                            styles.row,
                            {justifyContent: 'space-between'},
                          ]}>
                          <View style={[styles.row, {alignItems: 'center'}]}>
                            <Text style={[pageStyle.userName]}>
                              {item.user.name}
                            </Text>
                            {item.user.id === boardWriter.id && (
                              <Text style={[pageStyle.myComment]}>작성자</Text>
                            )}
                          </View>
                          <TouchableOpacity onPress={() => toggleMenu(item)}>
                            <Image
                              style={[{width: 20, height: 20}]}
                              source={require('../assets/images/icn_dot_menu.png')}
                            />
                          </TouchableOpacity>
                        </TouchableOpacity>
                        <Text
                          style={[
                            pageStyle.commentContent,
                            item.deleted && {color: '#888'},
                          ]}>
                          {item.deleted ? '삭제된 댓글입니다.' : item.contents}
                        </Text>
                        {item.imageUrl && (
                          <Image
                            style={[pageStyle.commentImage]}
                            source={{uri: item.imageUrl}}
                          />
                        )}
                        <View style={[styles.row, {alignItems: 'center'}]}>
                          <Text style={[pageStyle.date]}>
                            {parseDate(item.createdAt)}
                          </Text>
                          <TouchableOpacity
                            onPress={() =>
                              navigation.navigate('CommentChild', {
                                parentId: item.id,
                                targetCommentId: item.id,
                                boardId: boardId,
                                boardType: boardType,
                                comment: item,
                                userData: userData,
                                boardWriter: boardWriter,
                              })
                            }
                            style={[{marginStart: 12}]}>
                            <Text style={[pageStyle.date]}>답글쓰기</Text>
                          </TouchableOpacity>
                        </View>
                      </View>
                    </View>
                    <View>
                      {item.childs &&
                        item.childs.map((child, index) => {
                          return (
                            <View
                              style={[
                                pageStyle.commentItemContainer,
                                child.deleted && {display: 'none'},
                              ]}>
                              <View style={[pageStyle.childLeft]} />
                              <Image
                                style={[
                                  {
                                    width: 40,
                                    height: 40,
                                    resizeMode: 'cover',
                                    borderRadius: 20,
                                  },
                                ]}
                                source={getProfileImageUrl(child)}
                              />

                              <View style={[pageStyle.rightContainer]}>
                                <View
                                  style={[
                                    styles.row,
                                    {justifyContent: 'space-between'},
                                  ]}>
                                  <TouchableOpacity
                                    onPress={() =>
                                      navigation.navigate('MemberPage', {
                                        item: {uid: child.user.uid},
                                        itemId: child.user.uid,
                                      })
                                    }
                                    style={[
                                      styles.row,
                                      {alignItems: 'center'},
                                    ]}>
                                    <Text style={[pageStyle.userName]}>
                                      {child.user.name}
                                    </Text>
                                    {child.user.id === boardWriter.id && (
                                      <Text style={[pageStyle.myComment]}>
                                        작성자
                                      </Text>
                                    )}
                                  </TouchableOpacity>
                                  <TouchableOpacity
                                    onPress={() =>
                                      toggleMenu(child, child.id, child.user.id)
                                    }>
                                    <Image
                                      style={[{width: 20, height: 20}]}
                                      source={require('../assets/images/icn_dot_menu.png')}
                                    />
                                  </TouchableOpacity>
                                </View>
                                <Text
                                  style={[
                                    pageStyle.commentContent,
                                    child.deleted && {color: '#888'},
                                  ]}>
                                  <Text
                                    style={[
                                      {
                                        color: Colors.main,
                                        fontSize: 15,
                                        fontWeight: 'bold',
                                        fontFamily: 'Pretendard-Bold',
                                      },
                                    ]}>
                                    {child.targetUser
                                      ? child.targetUser.name
                                      : ''}{' '}
                                  </Text>
                                  {child.deleted
                                    ? '삭제된 댓글입니다.'
                                    : child.contents}
                                </Text>
                                {child.imageUrl && (
                                  <Image
                                    style={[pageStyle.commentImage]}
                                    source={{uri: child.imageUrl}}
                                  />
                                )}
                                <View
                                  style={[styles.row, {alignItems: 'center'}]}>
                                  <Text style={[pageStyle.date]}>
                                    {parseDate(child.createdAt)}
                                  </Text>
                                  <TouchableOpacity
                                    onPress={() =>
                                      navigation.navigate('CommentChild', {
                                        parentId: item.id,
                                        targetCommentId: child.id,
                                        boardId: boardId,
                                        boardType: boardType,
                                        comment: child,
                                        userData: userData,
                                        boardWriter: boardWriter,
                                        targetUserId: child.user.id,
                                        targetCommentId: child.id,
                                      })
                                    }
                                    style={[{marginStart: 12}]}>
                                    <Text style={[pageStyle.date]}>
                                      답글쓰기
                                    </Text>
                                  </TouchableOpacity>
                                </View>
                              </View>
                            </View>
                          );
                        })}
                    </View>
                  </View>
                )}
                ListEmptyComponent={() => (
                  <View style={[styles.emptyContainer]}>
                    <Image source={require('../assets/images/logo2.png')} />
                  </View>
                )}
              />
            </View>
            <Shadow
              style={[
                pageStyle.bottomView,
                {
                  height:
                    45 +
                    (Platform.OS === 'ios' ? insets.bottom : 0) +
                    (commentImage ? 250 : 0),
                  marginBottom: Platform.OS === 'ios' ? -insets.bottom : 0,
                },
                Platform.OS === 'ios' &&
                  commentImage && {paddingBottom: insets.bottom + 5},
              ]}>
              {commentImage && (
                <View style={[pageStyle.commentImageContainer]}>
                  <Image
                    style={[pageStyle.commentImage]}
                    source={{uri: commentImage}}
                  />
                  <TouchableOpacity
                    style={[pageStyle.deleteCommentBtn]}
                    onPress={deleteCommentImage}>
                    <Image
                      style={[pageStyle.deleteCommentImage]}
                      source={require('../assets/images/close.png')}
                    />
                  </TouchableOpacity>
                </View>
              )}
              <View style={[styles.row, pageStyle.commentInputContainer]}>
                <TextInput
                  style={[pageStyle.commentInput]}
                  placeholder="댓글을 남겨보세요"
                  placeholderTextColor={'#aaa'}
                  multiline={true}
                  value={commentContents}
                  onChangeText={value => {
                    setCommentContents(value);
                    commentContentsRef.current = value;
                  }}
                />
                <TouchableOpacity onPress={openImageLibrary}>
                  <Image
                    style={[pageStyle.icnCamera]}
                    resizeMode="cover"
                    source={require('../assets/images/icn_camera.png')}
                  />
                </TouchableOpacity>
                <TouchableOpacity
                  onPress={writeComment}
                  style={[pageStyle.btn]}>
                  <Text style={[pageStyle.btnTxt]}>등록</Text>
                </TouchableOpacity>
              </View>
            </Shadow>
          </>
        )}
        {(Platform.OS === 'ios' || Platform.OS === 'web') && (
          <KeyboardAvoidingView
            keyboardVerticalOffset={Platform.OS === 'ios' ? 0 : 30}
            behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
            style={{flexGrow: 1}}>
            <View
              style={[
                {
                  marginTop: 10,
                  position: 'relative',
                  flex: 1,
                },
              ]}>
              <FlatList
                nestedScrollEnabled={true}
                style={[pageStyle.list]}
                keyExtractor={(item, index) => `comment_${index.toString()}`}
                data={page.items}
                onEndReachedThreshold={1}
                onEndReached={handlePageLoadMore}
                renderItem={({item}) => (
                  <View
                    style={[
                      item.deleted &&
                        item.childs &&
                        item.childs.length === 0 && {display: 'none'},
                    ]}>
                    <View style={[pageStyle.commentItemContainer]}>
                      <Image
                        style={[
                          {
                            width: 40,
                            height: 40,
                            resizeMode: 'cover',
                            borderRadius: 20,
                          },
                        ]}
                        source={getProfileImageUrl(item)}
                      />

                      <View style={[pageStyle.rightContainer]}>
                        <TouchableOpacity
                          onPress={() =>
                            navigation.navigate('MemberPage', {
                              item: {uid: item.user.uid},
                              itemId: item.user.uid,
                            })
                          }
                          style={[
                            styles.row,
                            {justifyContent: 'space-between'},
                          ]}>
                          <View style={[styles.row, {alignItems: 'center'}]}>
                            <Text style={[pageStyle.userName]}>
                              {item.user.name}
                            </Text>
                            {item.user.id === boardWriter.id && (
                              <Text style={[pageStyle.myComment]}>작성자</Text>
                            )}
                          </View>
                          <TouchableOpacity onPress={() => toggleMenu(item)}>
                            <Image
                              style={[{width: 20, height: 20}]}
                              source={require('../assets/images/icn_dot_menu.png')}
                            />
                          </TouchableOpacity>
                        </TouchableOpacity>
                        <Text
                          style={[
                            pageStyle.commentContent,
                            item.deleted && {color: '#888'},
                          ]}>
                          {item.deleted ? '삭제된 댓글입니다.' : item.contents}
                        </Text>
                        {item.imageUrl && (
                          <Image
                            style={[pageStyle.commentImage]}
                            source={{uri: item.imageUrl}}
                          />
                        )}
                        <View style={[styles.row, {alignItems: 'center'}]}>
                          <Text style={[pageStyle.date]}>
                            {parseDate(item.createdAt)}
                          </Text>
                          <TouchableOpacity
                            onPress={() =>
                              navigation.navigate('CommentChild', {
                                parentId: item.id,
                                targetCommentId: item.id,
                                boardId: boardId,
                                boardType: boardType,
                                comment: item,
                                userData: userData,
                                boardWriter: boardWriter,
                              })
                            }
                            style={[{marginStart: 12}]}>
                            <Text style={[pageStyle.date]}>답글쓰기</Text>
                          </TouchableOpacity>
                        </View>
                      </View>
                    </View>
                    <View>
                      {item.childs &&
                        item.childs.map((child, index) => {
                          return (
                            <View
                              style={[
                                pageStyle.commentItemContainer,
                                child.deleted && {display: 'none'},
                              ]}>
                              <View style={[pageStyle.childLeft]} />
                              <Image
                                style={[
                                  {
                                    width: 40,
                                    height: 40,
                                    resizeMode: 'cover',
                                    borderRadius: 20,
                                  },
                                ]}
                                source={getProfileImageUrl(child)}
                              />

                              <View style={[pageStyle.rightContainer]}>
                                <View
                                  style={[
                                    styles.row,
                                    {justifyContent: 'space-between'},
                                  ]}>
                                  <TouchableOpacity
                                    onPress={() =>
                                      navigation.navigate('MemberPage', {
                                        item: {uid: child.user.uid},
                                        itemId: child.user.uid,
                                      })
                                    }
                                    style={[
                                      styles.row,
                                      {alignItems: 'center'},
                                    ]}>
                                    <Text style={[pageStyle.userName]}>
                                      {child.user.name}
                                    </Text>
                                    {child.user.id === boardWriter.id && (
                                      <Text style={[pageStyle.myComment]}>
                                        작성자
                                      </Text>
                                    )}
                                  </TouchableOpacity>
                                  <TouchableOpacity
                                    onPress={() =>
                                      toggleMenu(child, child.id, child.user.id)
                                    }>
                                    <Image
                                      style={[{width: 20, height: 20}]}
                                      source={require('../assets/images/icn_dot_menu.png')}
                                    />
                                  </TouchableOpacity>
                                </View>
                                <Text
                                  style={[
                                    pageStyle.commentContent,
                                    child.deleted && {color: '#888'},
                                  ]}>
                                  <Text
                                    style={[
                                      {
                                        color: Colors.main,
                                        fontSize: 15,
                                        fontWeight: 'bold',
                                        fontFamily: 'Pretendard-Bold',
                                      },
                                    ]}>
                                    {child.targetUser
                                      ? child.targetUser.name
                                      : ''}{' '}
                                  </Text>
                                  {child.deleted
                                    ? '삭제된 댓글입니다.'
                                    : child.contents}
                                </Text>
                                {child.imageUrl && (
                                  <Image
                                    style={[pageStyle.commentImage]}
                                    source={{uri: child.imageUrl}}
                                  />
                                )}
                                <View
                                  style={[styles.row, {alignItems: 'center'}]}>
                                  <Text style={[pageStyle.date]}>
                                    {parseDate(child.createdAt)}
                                  </Text>
                                  <TouchableOpacity
                                    onPress={() =>
                                      navigation.navigate('CommentChild', {
                                        parentId: item.id,
                                        targetCommentId: child.id,
                                        boardId: boardId,
                                        boardType: boardType,
                                        comment: child,
                                        userData: userData,
                                        boardWriter: boardWriter,
                                        targetUserId: child.user.id,
                                        targetCommentId: child.id,
                                      })
                                    }
                                    style={[{marginStart: 12}]}>
                                    <Text style={[pageStyle.date]}>
                                      답글쓰기
                                    </Text>
                                  </TouchableOpacity>
                                </View>
                              </View>
                            </View>
                          );
                        })}
                    </View>
                  </View>
                )}
                ListEmptyComponent={() => (
                  <View style={[styles.emptyContainer]}>
                    <Image source={require('../assets/images/logo2.png')} />
                  </View>
                )}
              />
            </View>
            <Shadow
              style={[
                pageStyle.bottomView,
                {
                  height:
                    45 +
                    (Platform.OS === 'ios' ? insets.bottom : 0) +
                    (commentImage ? 250 : 0),
                  marginBottom: Platform.OS === 'ios' ? -insets.bottom : 0,
                },
                Platform.OS === 'ios' &&
                  commentImage && {paddingBottom: insets.bottom + 5},
              ]}>
              {commentImage && (
                <View style={[pageStyle.commentImageContainer]}>
                  <Image
                    style={[pageStyle.commentImage]}
                    source={{uri: commentImage}}
                  />
                  <TouchableOpacity
                    style={[pageStyle.deleteCommentBtn]}
                    onPress={deleteCommentImage}>
                    <Image
                      style={[pageStyle.deleteCommentImage]}
                      source={require('../assets/images/close.png')}
                    />
                  </TouchableOpacity>
                </View>
              )}
              <View style={[styles.row, pageStyle.commentInputContainer]}>
                <TextInput
                  multiline={true}
                  style={[pageStyle.commentInput]}
                  placeholder="댓글을 남겨보세요"
                  placeholderTextColor={'#aaa'}
                  value={commentContents}
                  onChangeText={value => {
                    setCommentContents(value);
                    commentContentsRef.current = value;
                  }}
                />
                <TouchableOpacity onPress={openImageLibrary}>
                  <Image
                    style={[pageStyle.icnCamera]}
                    resizeMode="cover"
                    source={require('../assets/images/icn_camera.png')}
                  />
                </TouchableOpacity>
                <TouchableOpacity
                  onPress={writeComment}
                  style={[pageStyle.btn]}>
                  <Text style={[pageStyle.btnTxt]}>등록</Text>
                </TouchableOpacity>
              </View>
            </Shadow>
          </KeyboardAvoidingView>
        )}
        {progressShown && (
          <View style={[styles.progressContainer]}>
            <Progress.Circle
              fill="transparent"
              size={70}
              borderWidth={3}
              indeterminate={true}
            />
          </View>
        )}
        {menuOpened && (
          <Modal transparent={true} overFullScreen={true}>
            <TouchableOpacity
              onPress={() => setMenuOpened(false)}
              style={[
                pageStyle.popupContainer,
                Platform.OS === 'web' && {
                  alignItems: 'center',
                  alignContent: 'center',
                },
              ]}>
              <View
                style={[
                  pageStyle.popupInnerContainer,
                  {
                    marginTop: Dimensions.get('window').height / 2 - 50,
                  },
                  Platform.OS === 'web' && {width: 550 - 48 * 2},
                ]}>
                {currentIsMine && (
                  <>
                    <TouchableOpacity
                      onPress={modifyComment}
                      style={[pageStyle.popupMenuItem]}>
                      <Text style={[pageStyle.menuTxt]}>수정</Text>
                    </TouchableOpacity>
                    <TouchableOpacity
                      onPress={deleteComment}
                      style={[pageStyle.popupMenuItem]}>
                      <Text style={[pageStyle.menuTxt]}>삭제</Text>
                    </TouchableOpacity>
                  </>
                )}
                {!currentIsMine && (
                  <>
                    <TouchableOpacity
                      onPress={() => {
                        navigation.navigate('CommentChild', {
                          parentId: currentCommentItem.current.id,
                          targetCommentId: currentCommentItem.current.id,
                          boardId: boardId,
                          boardType: boardType,
                          comment: currentCommentItem.current,
                          userData: userData,
                          boardWriter: boardWriter,
                          targetUserId: currentCommentItem.current.targetUserId,
                          targetCommentId:
                            currentCommentItem.current.targetCommentId,
                        });
                        setMenuOpened(false);
                      }}
                      style={[pageStyle.popupMenuItem]}>
                      <Text style={[pageStyle.menuTxt]}>댓글 답글쓰기</Text>
                    </TouchableOpacity>
                    <TouchableOpacity
                      onPress={() => {
                        setMenuOpened(false);
                        navigation.navigate('MemberPage', {
                          item: currentCommentItem.current.user,
                          itemId: currentCommentItem.current.user.uid,
                        });
                      }}
                      style={[pageStyle.popupMenuItem]}>
                      <Text style={[pageStyle.menuTxt]}>
                        작성자 프로필 보기
                      </Text>
                    </TouchableOpacity>
                    <TouchableOpacity
                      onPress={() => {
                        navigation.navigate('Report', {
                          userName: currentCommentItem.current.user.name,
                          commentId: currentCommentItem.current.id,
                          commentContents: currentCommentItem.current.contents,
                        });
                        setMenuOpened(false);
                      }}
                      style={[pageStyle.popupMenuItem]}>
                      <Text style={[pageStyle.menuTxt]}>신고하기</Text>
                    </TouchableOpacity>
                    <TouchableOpacity
                      onPress={() => {
                        handleBlockUser(currentCommentItem.current.user.uid);
                      }}
                      style={[pageStyle.popupMenuItem]}>
                      <Text style={[pageStyle.menuTxt]}>작성자 차단하기</Text>
                    </TouchableOpacity>
                  </>
                )}
              </View>
            </TouchableOpacity>
          </Modal>
        )}
      </SafeAreaView>
    </>
  );
};

const pageStyle = StyleSheet.create({
  list: {
    paddingTop: 10,
    paddingBottom: 80,
  },
  commentHeaderContainer: {
    marginStart: 4,
  },
  boardType: {
    color: '#767676',
    fontFamily: 'Pretendard',
    fontSize: 12,
    marginTop: 4,
  },
  keywordTxt: {
    color: '#666',
    fontFamily: 'Pretendard',
    fontSize: 14,
    marginStart: 12,
  },
  filterContainer: {
    flexDirection: 'row',
  },
  dropDownContainer: {
    flexDirection: 'row',
    paddingStart: 22,
    marginEnd: 8,
    marginTop: 10,
    flex: 3,
  },
  commentImageContainer: {
    position: 'relative',
    padding: 12,
  },
  commentImage: {
    width: 200,
    height: 200,
    marginTop: 8,
  },
  deleteCommentBtn: {
    position: 'absolute',
    top: 0,
    right: -20,
  },
  deleteCommentImage: {},
  bottomView: {
    width: '100%',
    justifyContent: 'space-between',
    alignContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 12,
    paddingVertical: 8,
    backgroundColor: 'white',
  },
  icnCamera: {
    width: 31,
    height: 29,
    marginStart: 12,
    opacity: 0.6,
  },
  commentInputContainer: {
    width: '100%',
    alignItems: 'center',
  },
  commentInput: {
    flex: 1,
  },
  commentBtn: {
    height: 45,
    marginStart: 8,
    lineHeight: 45,
    borderWidth: 1,
    borderColor: '#ddd',
  },
  commentBtnTxt: {
    height: 45,
    lineHeight: 45,
    fontFamily: 'Pretendard',
    fontSize: 12,
    textAlign: 'center',
    width: 55,
    color: 'black',
  },
  commentItemContainer: {
    borderBottomWidth: 1,
    borderBottomColor: '#ddd',
    paddingVertical: 12,
    paddingHorizontal: 8,
    flexDirection: 'row',
  },
  commentContent: {
    fontFamily: 'Pretendard',
    fontSize: 15,
    color: 'black',
    marginTop: 2,
  },
  rightArr: {
    width: 20,
    height: 20,
    marginStart: 2,
  },
  myComment: {
    width: 44,
    height: 20,
    textAlign: 'center',
    color: Colors.main,
    fontFamily: 'Pretendard',
    fontSize: 11,
    borderWidth: 1,
    borderColor: Colors.main,
    paddingHorizontal: 4,
    borderRadius: 10,
    marginStart: 8,
    justifyContent: 'center',
    alignItems: 'center',
    lineHeight: 18,
  },
  userName: {
    fontSize: 13,
    color: '#111',
    fontWeight: 'bold',
    fontFamily: 'Pretendard-Bold',
  },
  rightContainer: {
    marginStart: 8,
    flex: 1,
  },
  date: {
    fontFamily: 'Pretendard',
    fontSize: 12,
    color: '#666',
    marginTop: 5,
  },
  btn: {
    backgroundColor: Colors.main,
    borderRadius: 15,
    height: 30,
    width: 60,
    alignItems: 'center',
    justifyContent: 'center',
    marginStart: 16,
  },
  btnTxt: {
    color: 'white',
    fontFamily: 'Pretendard',
    fontSize: 16,
  },
  childLeft: {width: 40},
  popupContainer: {
    zIndex: 100,
    width: '100%',
    height: Dimensions.get('window').height,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  popupInnerContainer: {
    backgroundColor: 'white',
    textAlign: 'center',
    paddingVertical: 5,
    borderWidth: 1,
    borderColor: '#ddd',
    marginHorizontal: 48,
    borderRadius: 10,
  },
  popupMenuItem: {
    paddingVertical: 8,
  },
  menuTxt: {
    fontFamily: 'Pretendard',
    fontSize: 14,
    color: 'black',
    textAlign: 'center',
    paddingVertical: 8,
  },
});

export default CommentView;

import React, {useEffect, useMemo, useState} from 'react';
import {Image, StyleSheet, Text, View} from 'react-native';
import moment from 'moment';
import 'moment/locale/ko';
import styles from '../common/styles';
import api from '../common/api';
import {TouchableOpacity} from 'react-native-gesture-handler';

const FavoriteUserItem = props => {
  useEffect(() => {}, [props.data]);
  const [alarmOn, setAlarmOn] = useState(props.data.alarmOn);

  const toggleAlarm = data => {
    api
      .post(
        `/app-user/${data}/favorites/alarm?alarmOn=${!alarmOn}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${props.accessToken}`,
          },
        },
      )
      .then(function (response) {
        setAlarmOn(!alarmOn);
      })
      .catch(function (error) {
        console.error(error.response);
      });
  };

  return (
    <TouchableOpacity
      onPress={() =>
        props.navigation.navigate('MemberPage', {
          item: props.data.targetUser,
          itemId: props.data.targetUser.uid,
        })
      }
      style={[styles.row, pageStyle.row]}>
      <View
        style={[styles.row, {alignContent: 'center', alignItems: 'center'}]}>
        <Image
          style={[pageStyle.profileImage]}
          resizeMode="cover"
          source={
            props.data.targetUser?.profileImageThumbUrl
              ? {uri: props.data.targetUser?.profileImageThumbUrl}
              : require('../assets/images/icn_profile_img.png')
          }
        />
        <Text style={[pageStyle.name]}>{props.data.targetUser?.name}</Text>
      </View>
      <Text style={[pageStyle.teamName]}>
        {props.data.targetUser?.teamName}
      </Text>
      <TouchableOpacity onPress={() => toggleAlarm(props.data.targetUser?.uid)}>
        <Image
          resizeMode="contain"
          style={[pageStyle.alarmImg]}
          source={
            alarmOn
              ? require('../assets/images/icn_alarm_on.png')
              : require('../assets/images/icn_alarm_off.png')
          }
        />
      </TouchableOpacity>
    </TouchableOpacity>
  );
};

const pageStyle = StyleSheet.create({
  logoImage: {
    width: 55,
    height: 55,
    borderRadius: 27,
  },
  profileImage: {
    width: 45,
    height: 45,
    borderRadius: 23,
  },
  row: {
    borderBottomWidth: 1,
    borderBottomColor: '#e6e6e6',
    paddingVertical: 12,
    paddingHorizontal: 24,
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  name: {
    fontFamily: 'Pretendard',
    fontSize: 14,
    color: 'black',
    marginStart: 8,
  },
  teamName: {
    fontFamily: 'Pretendard',
    fontSize: 16,
    color: 'black',
    marginStart: 16,
    flex: 1,
  },
  alarmImg: {
    width: 28,
    height: 30,
  },
});

export default FavoriteUserItem;

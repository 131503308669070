import React, {Image, StyleSheet, Text, View} from 'react-native';
import {Shadow} from 'react-native-shadow-2';
import styles from '../common/styles';

const TeamItem = props => {
  const getLogoImageUrl = item => {
    if (item?.logoImageThumbUrl) {
      return {uri: item?.logoImageThumbUrl};
    }
    if (item?.logoImageUrl) {
      return {uri: item?.logoImageUrl};
    }
    return require('../assets/images/nologo.png');
  };

  return (
    <View style={[pageStyle.itemContainer]}>
      <Shadow offset={[3, 4]} distance={4}>
        <View style={[pageStyle.logoContainer]}>
          <Image
            style={[
              pageStyle.logoImg,
              !props.data.logoImageUrl && {opacity: 0.4},
            ]}
            source={getLogoImageUrl(props.data)}
            resizeMode={'cover'}
          />
        </View>
      </Shadow>
      <View style={[pageStyle.contentsContainer]}>
        <View>
          <Text style={[pageStyle.teamName]} numberOfLines={1}>
            {props.data.name}
          </Text>
          <View style={[pageStyle.detailContainer]}>
            <View style={[styles.row, pageStyle.row]}>
              <View style={[pageStyle.leftDetailContainer]}>
                <Text style={[pageStyle.subtitle]}>창단일</Text>
                <Text style={[pageStyle.subvalue]}>
                  {props.data.foundationDay}
                </Text>
              </View>
              <View style={[pageStyle.rightDetailContainer]}>
                <Text style={[pageStyle.subtitle]}>선수인원</Text>
                <Text style={[pageStyle.subvalue]}>
                  {props.data.totalMembers}
                </Text>
              </View>
            </View>
            <View style={[styles.row, pageStyle.row]}>
              <View style={[pageStyle.leftDetailContainer]}>
                <Text style={[pageStyle.subtitle]}>연고지</Text>
                <Text style={[pageStyle.subvalue]}>
                  {props.data.hometown?.sido} {props.data.hometown?.name}
                </Text>
              </View>
              <View style={[pageStyle.rightDetailContainer]}>
                <Text style={[pageStyle.subtitle]}>평균연령</Text>
                <Text style={[pageStyle.subvalue]}>{props.data.avgAge}</Text>
              </View>
            </View>
            <View style={[styles.row, pageStyle.row]}>
              <View style={[pageStyle.leftDetailContainer]}>
                <Text style={[pageStyle.subtitle]}>마스터</Text>
                <Text style={[pageStyle.subvalue]}>
                  {props.data.masterUser?.name}
                </Text>
              </View>
              <View style={[pageStyle.rightDetailContainer]}></View>
            </View>
          </View>
        </View>
      </View>
    </View>
  );
};

const pageStyle = StyleSheet.create({
  itemContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 10,
    marginBottom: 10,
    paddingHorizontal: 4,
  },
  logoContainer: {
    width: 85,
    height: 85,
    borderRadius: 42.5,
    overflow: 'hidden',
    backgroundColor: 'white',
  },
  logoImg: {
    width: 85,
    height: 85,
  },
  contentsContainer: {
    flex: 1,
    marginStart: 20,
    paddingTop: 13,
    alignContent: 'center',
  },
  teamName: {
    fontFamily: 'Pretendard-SemiBold',
    fontSize: 13,
    fontWeight: '600',
    color: 'black',
    maxWidth: 185,
  },
  detailContainer: {
    marginTop: 7,
  },
  leftDetailContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: 110,
    flex: 3,
  },
  rightDetailContainer: {
    display: 'flex',
    flexDirection: 'row',
    flex: 2,
  },
  row: {},
  subrow: {
    marginTop: 3,
  },
  subtitle: {
    fontFamily: 'Pretendard-Light',
    fontSize: 10,
    fontWeight: '300',
    color: 'black',
    marginEnd: 9,
    lineHeight: 12,
  },
  subvalue: {
    fontSize: 10,
    fontWeight: '500',
    fontFamily: 'Pretendard-Medium',
    color: '#3f51b5',
    lineHeight: 12,
  },
});

export default TeamItem;

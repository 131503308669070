import React, {useState, useRef, useEffect, useMemo} from 'react';
import {
  Image,
  SafeAreaView,
  Text,
  TouchableOpacity,
  View,
  StyleSheet,
  ImageBackground,
  Dimensions,
  FlatList,
  Platform,
} from 'react-native';
import styles from '../common/styles';
import LeagueHome from '../component/league/LeagueHome';
import LeagueIntroduction from '../component/league/LeagueIntroduction';
import GameScheduleList from '../component/league/GameScheduleList';
import LeagueRecord from '../component/league/LeagueRecord';
import LeagueTeamList from '../component/league/LeagueTeamList';
import Community from '../component/league/Community';
import LeagueApplyPopup from '../component/league/LeagueApplyPopup';
import AsyncStorage from '@react-native-async-storage/async-storage';
import api from '../common/api';
import Colors from '../common/colors';
import {title} from '../common/util';

const LeagueDetailView = ({navigation, route}) => {
  const {itemId} = route.params;

  const [tabIndex, setTabIndex] = useState(0);
  const [league, setLeague] = useState({});
  const [applyPopupOpened, setApplyPopupOpened] = useState(false);
  const [scheduleMenuOpened, setScheduleMenuOpened] = useState(false);
  const [scheduleType, setScheduleType] = useState();
  const [communityMenuOpened, setCommunityMenuOpened] = useState(false);
  const [tourMode, setTourMode] = useState();

  const tabList = useMemo(
    () =>
      !tourMode
        ? [
            {index: 0, text: '홈'},
            {index: 1, text: '리그소개'},
            {index: 2, text: '일정결과'},
            {index: 3, text: '기록'},
            {index: 4, text: '가입팀'},
            {index: 5, text: '커뮤니티'},
          ]
        : [
            {index: 0, text: '홈'},
            {index: 1, text: '리그소개'},
            {index: 5, text: '커뮤니티'},
          ],
    [tourMode],
  );

  let accessToken = useRef();

  useEffect(() => {
    AsyncStorage.getItem('@accessToken', (err, result) => {
      accessToken.current = result;
      setTourMode(!result);
      getData(result);
    });
  }, []);

  const selectTabIndex = index => {
    if (index === 5) {
      setCommunityMenuOpened(true);
    } else if (index === 2) {
      setScheduleMenuOpened(true);
    }
    setTabIndex(index);
  };

  const getData = token => {
    api
      .get(`/leagues/${itemId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(function (response) {
        setLeague(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getImageUrl = data => {
    if (data.imageThumbUrl) {
      return {uri: data.imageThumbUrl};
    } else if (data.imageUrl) {
      return {uri: data.imageUrl};
    }
    return require('../assets/images/icon_uniqueplay.png');
  };

  return (
    <SafeAreaView nativeID="common-page-container" style={[styles.container]}>
      <View style={[styles.headerCommon, pageStyle.headerCommon]}>
        <ImageBackground
          resizeMode={'cover'}
          source={require('../assets/images/nav_team_bg.png')}
          style={[pageStyle.navBgImage]}
          imageStyle={[pageStyle.navBgImage]}
        />
        <View
          style={[
            styles.row,
            styles.verticalAlign,
            Platform.OS === 'web' && {marginStart: 18},
            Platform.OS !== 'web' && {paddingStart: 18},
          ]}>
          <TouchableOpacity
            onPress={() =>
              Platform.OS === 'web' ? history.back() : navigation.goBack()
            }>
            <Image
              source={require('../assets/images/icon_back_wh.png')}
              style={[styles.headerBackButton]}
            />
          </TouchableOpacity>
          <Text style={[styles.headerText, pageStyle.headerText]}>
            {league.name}
          </Text>
        </View>
        <View
          style={[
            styles.row,
            styles.verticalAlign,
            Platform.OS === 'web' && {marginEnd: 18},
            Platform.OS !== 'web' && {paddingEnd: 18},
          ]}>
          {!tourMode && (
            <TouchableOpacity
              style={[pageStyle.manageTeamBtn]}
              onPress={() => setApplyPopupOpened(true)}>
              <Text style={[pageStyle.manageTeamBtnTxt]}>참가신청</Text>
            </TouchableOpacity>
          )}
          <Image style={[styles.headerLogoImg]} source={getImageUrl(league)} />
        </View>
      </View>
      <View style={[pageStyle.container]}>
        <FlatList
          style={[styles.tabContainer]}
          showsHorizontalScrollIndicator={Platform.OS === 'web'}
          horizontal={true}
          data={tabList}
          renderItem={({item}) => {
            return (
              <TouchableOpacity
                onPress={() => selectTabIndex(item.index)}
                style={[
                  tabIndex === item.index && styles.tabItemSelected,
                  tabIndex !== item.index && styles.tabItem,
                  tabList.length === item.index + 1 && {marginEnd: 20},
                ]}>
                <Text
                  style={[
                    tabIndex === item.index && styles.tabItemTextSelected,
                    tabIndex !== item.index && styles.tabItemText,
                  ]}>
                  {item.text}
                </Text>
              </TouchableOpacity>
            );
          }}
        />
        {tabIndex === 0 && (
          <LeagueHome
            itemId={itemId}
            tourMode={tourMode}
            navigation={navigation}
          />
        )}
        {tabIndex === 1 && (
          <LeagueIntroduction
            league={league}
            item={league}
            navigation={navigation}
          />
        )}
        {tabIndex === 2 && (
          <>
            <GameScheduleList
              item={league}
              navigation={navigation}
              scheduleType={scheduleType}
            />

            {scheduleMenuOpened && (
              <View style={[pageStyle.communityMenuContainer]}>
                <TouchableOpacity
                  onPress={() => {
                    setScheduleType('PENDING');
                    setScheduleMenuOpened(false);
                  }}
                  style={[pageStyle.communityItemMenuContainer]}>
                  <Text style={[pageStyle.communityItemMenuTxt]}>게임일정</Text>
                </TouchableOpacity>
                <TouchableOpacity
                  onPress={() => {
                    setScheduleType('COMPLETED');
                    setScheduleMenuOpened(false);
                  }}
                  style={[pageStyle.communityItemMenuContainer]}>
                  <Text style={[pageStyle.communityItemMenuTxt]}>게임결과</Text>
                </TouchableOpacity>
                <TouchableOpacity
                  onPress={() => {
                    setScheduleType('ALL');
                    setScheduleMenuOpened(false);
                  }}
                  style={[pageStyle.communityItemMenuContainer]}>
                  <Text style={[pageStyle.communityItemMenuTxt]}>전체보기</Text>
                </TouchableOpacity>
                <TouchableOpacity
                  onPress={() => {
                    setScheduleType('PLAYOFF');
                    setScheduleMenuOpened(false);
                  }}
                  style={[pageStyle.communityItemMenuContainer]}>
                  <Text style={[pageStyle.communityItemMenuTxt]}>
                    플레이오프
                  </Text>
                </TouchableOpacity>
              </View>
            )}
          </>
        )}
        {tabIndex === 3 && (
          <LeagueRecord item={league} navigation={navigation} />
        )}
        {tabIndex === 4 && (
          <LeagueTeamList item={league} navigation={navigation} />
        )}
        {tabIndex === 5 && (
          <>
            <Community league={league} item={league} navigation={navigation} />
          </>
        )}
      </View>
      {applyPopupOpened && (
        <LeagueApplyPopup
          league={league}
          closePopup={() => setApplyPopupOpened(false)}
          navigation={navigation}
        />
      )}
    </SafeAreaView>
  );
};

export default LeagueDetailView;

const pageStyle = StyleSheet.create({
  headerCommon: {
    paddingStart: 0,
    paddingEnd: 0,
  },
  navBgImage: {
    marginStart: 0,
    position: 'absolute',
    width: '100%',
    height: '100%',
  },
  headerText: {color: 'white'},
  communityMenuContainer: {
    position: 'absolute',
    top: 45,
    width: '100%',
  },
  communityItemMenuContainer: {
    backgroundColor: Colors.main,
    opacity: 0.9,
    paddingVertical: 12,
    borderTopWidth: 1,
    borderTopColor: 'rgba(255, 255, 255, 0.4)',
  },
  communityItemMenuTxt: {
    color: 'white',
    fontFamily: 'Pretendard',
    fontSize: 14,
    textAlign: 'center',
  },
  manageTeamBtn: {
    width: 60,
    height: 22,
    borderRadius: 12,
    borderWidth: 1,
    borderColor: 'white',
    backgroundColor: 'transparent',
    justifyContent: 'center',
    alignItems: 'center',
    marginEnd: 14,
  },
  manageTeamBtnTxt: {
    color: 'white',
    fontFamily: 'Pretendard',
    fontSize: 11,
  },
  container: {},
});

import React, {useEffect, useState, useRef, useCallback, useMemo} from 'react';
import {
  Image,
  SafeAreaView,
  DeviceEventEmitter,
  Text,
  TouchableOpacity,
  View,
  StyleSheet,
  FlatList,
  TextInput,
  Modal,
  Alert,
  Platform,
  KeyboardAvoidingView,
} from 'react-native';
import styles from '../common/styles';
import {Shadow} from 'react-native-shadow-2';
import AsyncStorage from '@react-native-async-storage/async-storage';
import api from '../common/api';
import * as Progress from 'react-native-progress';
import moment from 'moment';
import Colors from '../common/colors';
import {launchImageLibrary} from 'react-native-image-picker';
import {useSafeAreaInsets} from 'react-native-safe-area-context';
import alert from '../component/alert';
import {title} from '../common/util';

const CommentChildView = ({navigation, route}) => {
  const {
    boardId,
    userData,
    boardType,
    boardWriter,
    targetCommentId,
    targetUserId,
    parentId,
  } = route.params;
  const [page, setPage] = useState({});
  const [progressShown, setProgressShown] = useState(false);

  const [comment, setComment] = useState(route.params.comment);
  const [commentInput, setCommentInput] = useState('');
  const [alarmUnread, setAlarmUnread] = useState(false);

  const [commentImage, setCommentImage] = useState();

  const commentContentsRef = useRef();
  let pageLoading = useRef(false);
  let commentPageLoading = useRef(false);

  let accessToken = useRef();

  const insets = useSafeAreaInsets();

  useEffect(() => {
    AsyncStorage.getItem('@accessToken', (err, result) => {
      if (err) {
        return;
      }
      accessToken.current = result;
      checkAlarmUnRead();
      getComment();
      getData();
    });

    const unsubscribe = DeviceEventEmitter.addListener(
      '@commentRefresh',
      function (data) {
        console.log('@commentRefresh#5');
        if (data && data?.commentId !== comment.id) {
          return;
        }
        getData(accessToken.current);
        getComment();
      },
    );

    return () => {
      unsubscribe.remove();
    };
  }, []);

  const getComment = () => {
    if (!accessToken.current) {
      return;
    }
    if (commentPageLoading.current) {
      return;
    }
    setProgressShown(true);
    api
      .get(`/boards/comments/${targetCommentId}`, {
        headers: {
          Authorization: `Bearer ${accessToken.current}`,
        },
      })
      .then(function (response) {
        setComment(response.data);
        commentPageLoading.current = false;
        setProgressShown(false);
      })
      .catch(function (error) {
        console.log(error);
      })
      .finally(() => {
        commentPageLoading.current = false;
        setProgressShown(false);
      });
  };

  const getData = () => {
    console.log('getData');
    if (!accessToken.current) {
      return;
    }
    if (pageLoading.current) {
      return;
    }
    pageLoading.current = true;
    console.log(`/boards/comments/${targetCommentId}/children`);
    api
      .get(`/boards/comments/${targetCommentId}/children`, {
        headers: {
          Authorization: `Bearer ${accessToken.current}`,
        },
      })
      .then(function (response) {
        setPage(response.data);
      })
      .catch(function (error) {
        console.error(error.response);
      })
      .finally(() => {
        pageLoading.current = false;
      });
  };

  const handlePageLoadMore = () => {
    if (!accessToken.current) {
      return;
    }
    if (commentPageLoading.current) {
      return;
    }
    if (page.currentPage && page.totalPage <= page.currentPage) {
      return;
    }
    setProgressShown(true);
    commentPageLoading.current = true;
    api
      .get(
        `boards/comments/${targetCommentId}/children?pageNo=${
          page.currentPage ? parseInt(page.currentPage, 10) + 1 : 1
        }&pageSize=20`,
        {
          headers: {
            Authorization: `Bearer ${accessToken.current}`,
          },
        },
      )
      .then(function (response) {
        response.data.items = [
          ...(page.items ? page.items : []),
          ...response.data.items,
        ];
        setPage(response.data);
      })
      .catch(function (error) {
        console.error(error);
      })
      .finally(() => {
        setProgressShown(false);
        commentPageLoading.current = false;
      });
  };

  const checkAlarmUnRead = () => {
    if (!accessToken.current) {
      return;
    }
    api
      .get('/users/exists-unread-alarm', {
        headers: {
          Authorization: `Bearer ${accessToken.current}`,
        },
      })
      .then(function (response) {
        setAlarmUnread(response.data);
      })
      .catch(function (error) {
        console.error(error.response);
      });
  };

  const deleteComment = id => {
    alert('댓글', '삭제 하시겠습니까?', [
      {
        text: '예',
        onPress: () => callDeleteCommentListApi(id),
      },
      {
        text: '아니오',
      },
    ]);
  };

  const writeComment = () => {
    alert('댓글', '작성 하시겠습니까?', [
      {
        text: '예',
        onPress: () => callWriteCommentApi(),
      },
      {
        text: '아니오',
      },
    ]);
  };

  const callDeleteCommentListApi = id => {
    api
      .delete(`/boards/comments/${id}`, {
        headers: {
          Authorization: `Bearer ${accessToken.current}`,
        },
      })
      .then(function (response) {
        getData();
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const callWriteCommentApi = () => {
    api
      .post(
        `/boards/${boardId}/comments`,
        {
          parentId: parentId,
          contents: commentContentsRef.current,
          targetUserId: targetUserId,
          imageUrl: commentImage,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken.current}`,
          },
        },
      )
      .then(function (response) {
        commentContentsRef.current = null;
        setCommentInput('');
        setCommentImage('');
        getData();
        DeviceEventEmitter.emit('@commentRefresh');
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getProfileImageUrl = item => {
    if (item?.user?.profileImageThumbUrl) {
      return {uri: item?.user?.profileImageThumbUrl};
    }
    if (item?.user?.profileImageUrl) {
      return {uri: item?.user?.profileImageUrl};
    }
    return require('../assets/images/icn_profile_img.png');
  };

  const parseDate = date => {
    if (!date) {
      return null;
    }
    if (date.indexOf('T') > -1) {
      return moment(date).format('YY.MM.DD   HH:mm');
    }
    return moment(date, 'YYYY-MM-DD').locale('ko').format('MM/DD(dd)');
  };

  const openImageLibrary = () => {
    if (commentImage) {
      alert('이미지 첨부는 1개만 가능합니다.');
      return;
    }
    launchImageLibrary(
      {
        mediaType: 'photo',
        includeBase64: true,
        maxHeight: 500,
        maxWidth: 500,
      },
      response => {
        if (
          !response.errorCode &&
          response.assets &&
          response.assets.length > 0 &&
          response.assets[0] &&
          response.assets[0].base64
        ) {
          setCommentImage('data:image/png;base64,' + response.assets[0].base64);
        }
      },
    );
  };

  const deleteCommentImage = () => {
    setCommentImage(null);
  };

  return (
    <>
      <SafeAreaView nativeID="common-page-container" style={[styles.container]}>
        <Shadow
          offset={[0, 2]}
          style={[{width: '100%'}]}
          containerStyle={[{marginTop: 0}]}
          distance={7}
          sides={{bottom: true, top: false, start: false, end: false}}>
          <View style={[styles.headerCommon]}>
            <View style={[styles.row, styles.verticalAlign]}>
              <TouchableOpacity
                onPress={() =>
                  Platform.OS === 'web' ? history.back() : navigation.goBack()
                }>
                <Image
                  source={require('../assets/images/icon_back.png')}
                  style={[styles.headerBackButton]}
                />
              </TouchableOpacity>
              <View style={[pageStyle.commentHeaderContainer]}>
                <Text style={[styles.headerText]}>답글쓰기</Text>
                <Text style={[pageStyle.boardType]}>{boardType}</Text>
              </View>
            </View>
            <View style={[styles.row, styles.verticalAlign]}>
              <TouchableOpacity
                style={[styles.alarmContainer]}
                onPress={() => navigation.navigate('MyAlarmList')}>
                <Image
                  resizeMode="contain"
                  style={[styles.imgAlarm]}
                  source={require('../assets/images/icn_alarm.png')}
                />
                {alarmUnread && <View style={[styles.alarmDot]} />}
              </TouchableOpacity>
            </View>
          </View>
        </Shadow>
        {Platform.OS === 'android' && (
          <>
            <View
              style={[
                {
                  marginTop: 10,
                  position: 'relative',
                  flex: 1,
                },
              ]}>
              <View style={[pageStyle.commentItemContainer]}>
                <Image
                  style={[
                    {
                      width: 40,
                      height: 40,
                      resizeMode: 'cover',
                      borderRadius: 20,
                    },
                  ]}
                  source={getProfileImageUrl(comment)}
                />
                <View style={[pageStyle.rightContainer]}>
                  <View style={[styles.row, {justifyContent: 'space-between'}]}>
                    <TouchableOpacity
                      onPress={() =>
                        navigation.navigate('MemberPage', {
                          item: {uid: comment.user?.uid},
                          itemId: comment.user?.uid,
                        })
                      }
                      style={[styles.row, {alignItems: 'center'}]}>
                      <Text style={[pageStyle.userName]}>
                        {comment.user?.name}
                      </Text>
                      {comment.user?.id === boardWriter.id && (
                        <Text style={[pageStyle.myComment]}>작성자</Text>
                      )}
                    </TouchableOpacity>
                  </View>
                  <Text
                    style={[
                      pageStyle.commentContent,
                      comment.deleted && {color: '#888'},
                    ]}>
                    {comment.deleted ? '삭제된 댓글입니다.' : comment.contents}
                  </Text>
                  {comment.imageUrl && (
                    <Image
                      style={[pageStyle.commentImage]}
                      source={{uri: comment.imageUrl}}
                    />
                  )}
                  <View style={[styles.row, {alignItems: 'center'}]}>
                    <Text style={[pageStyle.date]}>
                      {parseDate(comment.createdAt)}
                    </Text>
                  </View>
                </View>
              </View>
              <FlatList
                nestedScrollEnabled={true}
                style={[pageStyle.list]}
                keyExtractor={(item, index) => `comment_${index.toString()}`}
                data={page.items}
                onEndReachedThreshold={1}
                onEndReached={handlePageLoadMore}
                renderItem={({item}) => (
                  <View
                    style={[
                      pageStyle.commentItemContainer,
                      targetCommentId === item.id && {backgroundColor: '#eee'},
                      item.deleted && {display: 'none'},
                    ]}>
                    <View style={[pageStyle.childLeft]} />
                    <Image
                      style={[
                        {
                          width: 40,
                          height: 40,
                          resizeMode: 'cover',
                          borderRadius: 20,
                        },
                      ]}
                      source={getProfileImageUrl(item)}
                    />

                    <View style={[pageStyle.rightContainer]}>
                      <View
                        style={[styles.row, {justifyContent: 'space-between'}]}>
                        <TouchableOpacity
                          onPress={() =>
                            navigation.navigate('MemberPage', {
                              item: {uid: item.user.uid},
                              itemId: item.user.uid,
                            })
                          }
                          style={[styles.row, {alignItems: 'center'}]}>
                          <Text style={[pageStyle.userName]}>
                            {item?.user?.name}
                          </Text>
                          {item?.user.id === boardWriter.id && (
                            <Text style={[pageStyle.myComment]}>작성자</Text>
                          )}
                        </TouchableOpacity>
                      </View>
                      <Text
                        style={[
                          pageStyle.commentContent,
                          item.deleted && {color: '#888'},
                        ]}>
                        <Text
                          style={[
                            {
                              color: Colors.main,
                              fontSize: 15,
                              fontWeight: 'bold',
                              fontFamily: 'Pretendard-Bold',
                            },
                          ]}>
                          {item.targetUser ? item.targetUser.name : ''}{' '}
                        </Text>
                        {item.deleted ? '삭제된 댓글입니다.' : item.contents}
                      </Text>
                      {item.imageUrl && (
                        <Image
                          style={[pageStyle.commentImage]}
                          source={{uri: item.imageUrl}}
                        />
                      )}
                      <Text style={[pageStyle.date]}>
                        {parseDate(item.createdAt)}
                      </Text>
                    </View>
                  </View>
                )}
                ListEmptyComponent={() => (
                  <View style={[styles.emptyContainer]}>
                    <Image source={require('../assets/images/logo2.png')} />
                  </View>
                )}
              />
            </View>
            <Shadow
              style={[
                pageStyle.bottomView,
                {
                  height:
                    45 +
                    (Platform.OS === 'ios' ? insets.bottom : 0) +
                    (commentImage ? 250 : 0),
                  marginBottom: Platform.OS === 'ios' ? -insets.bottom : 0,
                },
                Platform.OS === 'ios' &&
                  commentImage && {paddingBottom: insets.bottom + 5},
              ]}>
              {commentImage && (
                <View style={[pageStyle.commentImageContainer]}>
                  <Image
                    style={[pageStyle.commentImage]}
                    source={{uri: commentImage}}
                  />
                  <TouchableOpacity
                    style={[pageStyle.deleteCommentBtn]}
                    onPress={deleteCommentImage}>
                    <Image
                      style={[pageStyle.deleteCommentImage]}
                      source={require('../assets/images/close.png')}
                    />
                  </TouchableOpacity>
                </View>
              )}
              <View style={[styles.row, pageStyle.commentInputContainer]}>
                <TextInput
                  multiline={true}
                  value={commentInput}
                  style={[pageStyle.commentInput]}
                  placeholder="댓글을 남겨보세요"
                  placeholderTextColor={'#aaa'}
                  onChangeText={value => {
                    setCommentInput(value);
                    commentContentsRef.current = value;
                  }}
                />
                <TouchableOpacity onPress={openImageLibrary}>
                  <Image
                    style={[pageStyle.icnCamera]}
                    resizeMode="cover"
                    source={require('../assets/images/icn_camera.png')}
                  />
                </TouchableOpacity>
                <TouchableOpacity
                  onPress={() => writeComment()}
                  style={[pageStyle.btn]}>
                  <Text style={[pageStyle.btnTxt]}>등록</Text>
                </TouchableOpacity>
              </View>
            </Shadow>
          </>
        )}
        {(Platform.OS === 'ios' || Platform.OS === 'web') && (
          <KeyboardAvoidingView
            keyboardVerticalOffset={Platform.OS === 'ios' ? 0 : 30}
            behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
            style={{flexGrow: 1}}>
            <View
              style={[
                {
                  marginTop: 10,
                  position: 'relative',
                  flex: 1,
                },
              ]}>
              <View style={[pageStyle.commentItemContainer]}>
                <Image
                  style={[
                    {
                      width: 40,
                      height: 40,
                      resizeMode: 'cover',
                      borderRadius: 20,
                    },
                  ]}
                  source={getProfileImageUrl(comment)}
                />
                <View style={[pageStyle.rightContainer]}>
                  <View style={[styles.row, {justifyContent: 'space-between'}]}>
                    <TouchableOpacity
                      onPress={() =>
                        navigation.navigate('MemberPage', {
                          item: {uid: comment.user?.uid},
                          itemId: comment.user?.uid,
                        })
                      }
                      style={[styles.row, {alignItems: 'center'}]}>
                      <Text style={[pageStyle.userName]}>
                        {comment.user?.name}
                      </Text>
                      {comment.user?.id === boardWriter.id && (
                        <Text style={[pageStyle.myComment]}>작성자</Text>
                      )}
                    </TouchableOpacity>
                  </View>
                  <Text
                    style={[
                      pageStyle.commentContent,
                      comment.deleted && {color: '#888'},
                    ]}>
                    {comment.deleted ? '삭제된 댓글입니다.' : comment.contents}
                  </Text>
                  {comment.imageUrl && (
                    <Image
                      style={[pageStyle.commentImage]}
                      source={{uri: comment.imageUrl}}
                    />
                  )}
                  <View style={[styles.row, {alignItems: 'center'}]}>
                    <Text style={[pageStyle.date]}>
                      {parseDate(comment.createdAt)}
                    </Text>
                  </View>
                </View>
              </View>
              <FlatList
                nestedScrollEnabled={true}
                style={[pageStyle.list]}
                keyExtractor={(item, index) => `comment_${index.toString()}`}
                data={page.items}
                onEndReachedThreshold={1}
                onEndReached={handlePageLoadMore}
                renderItem={({item}) => (
                  <View
                    style={[
                      pageStyle.commentItemContainer,
                      targetCommentId === item.id && {backgroundColor: '#eee'},
                      item.deleted && {display: 'none'},
                    ]}>
                    <View style={[pageStyle.childLeft]} />
                    <Image
                      style={[
                        {
                          width: 40,
                          height: 40,
                          resizeMode: 'cover',
                          borderRadius: 20,
                        },
                      ]}
                      source={getProfileImageUrl(item)}
                    />

                    <View style={[pageStyle.rightContainer]}>
                      <View
                        style={[styles.row, {justifyContent: 'space-between'}]}>
                        <TouchableOpacity
                          onPress={() =>
                            navigation.navigate('MemberPage', {
                              item: {uid: item.user.uid},
                              itemId: item.user.uid,
                            })
                          }
                          style={[styles.row, {alignItems: 'center'}]}>
                          <Text style={[pageStyle.userName]}>
                            {item?.user?.name}
                          </Text>
                          {item?.user.id === boardWriter.id && (
                            <Text style={[pageStyle.myComment]}>작성자</Text>
                          )}
                        </TouchableOpacity>
                      </View>
                      <Text
                        style={[
                          pageStyle.commentContent,
                          item.deleted && {color: '#888'},
                        ]}>
                        <Text
                          style={[
                            {
                              color: Colors.main,
                              fontSize: 15,
                              fontWeight: 'bold',
                              fontFamily: 'Pretendard-Bold',
                            },
                          ]}>
                          {item.targetUser ? item.targetUser.name : ''}{' '}
                        </Text>
                        {item.deleted ? '삭제된 댓글입니다.' : item.contents}
                      </Text>
                      {item.imageUrl && (
                        <Image
                          style={[pageStyle.commentImage]}
                          source={{uri: item.imageUrl}}
                        />
                      )}
                      <Text style={[pageStyle.date]}>
                        {parseDate(item.createdAt)}
                      </Text>
                    </View>
                  </View>
                )}
                ListEmptyComponent={() => (
                  <View style={[styles.emptyContainer]}>
                    <Image source={require('../assets/images/logo2.png')} />
                  </View>
                )}
              />
            </View>
            <Shadow
              style={[
                pageStyle.bottomView,
                {
                  height:
                    45 +
                    (Platform.OS === 'ios' ? insets.bottom : 0) +
                    (commentImage ? 250 : 0),
                  marginBottom: Platform.OS === 'ios' ? -insets.bottom : 0,
                },
                Platform.OS === 'ios' &&
                  commentImage && {paddingBottom: insets.bottom + 5},
              ]}>
              {commentImage && (
                <View style={[pageStyle.commentImageContainer]}>
                  <Image
                    style={[pageStyle.commentImage]}
                    source={{uri: commentImage}}
                  />
                  <TouchableOpacity
                    style={[pageStyle.deleteCommentBtn]}
                    onPress={deleteCommentImage}>
                    <Image
                      style={[pageStyle.deleteCommentImage]}
                      source={require('../assets/images/close.png')}
                    />
                  </TouchableOpacity>
                </View>
              )}
              <View style={[styles.row, pageStyle.commentInputContainer]}>
                <TextInput
                  multiline={true}
                  value={commentInput}
                  style={[pageStyle.commentInput]}
                  placeholder="댓글을 남겨보세요"
                  placeholderTextColor={'#aaa'}
                  onChangeText={value => {
                    setCommentInput(value);
                    commentContentsRef.current = value;
                  }}
                />
                <TouchableOpacity onPress={openImageLibrary}>
                  <Image
                    style={[pageStyle.icnCamera]}
                    resizeMode="cover"
                    source={require('../assets/images/icn_camera.png')}
                  />
                </TouchableOpacity>
                <TouchableOpacity
                  onPress={() => writeComment()}
                  style={[pageStyle.btn]}>
                  <Text style={[pageStyle.btnTxt]}>등록</Text>
                </TouchableOpacity>
              </View>
            </Shadow>
          </KeyboardAvoidingView>
        )}
        {progressShown && (
          <View style={[styles.progressContainer]}>
            <Progress.Circle
              fill="transparent"
              size={70}
              borderWidth={3}
              indeterminate={true}
            />
          </View>
        )}
      </SafeAreaView>
    </>
  );
};

const pageStyle = StyleSheet.create({
  list: {
    paddingTop: 10,
    paddingBottom: 80,
  },
  commentHeaderContainer: {
    marginStart: 4,
  },
  boardType: {
    color: '#767676',
    fontSize: 12,
    marginTop: 6,
    fontFamily: 'Pretendard',
  },
  keywordTxt: {
    color: '#666',
    fontSize: 14,
    marginStart: 12,
    fontFamily: 'Pretendard',
  },
  filterContainer: {
    flexDirection: 'row',
  },
  dropDownContainer: {
    flexDirection: 'row',
    paddingStart: 22,
    marginEnd: 8,
    marginTop: 10,
    flex: 3,
  },
  commentImageContainer: {
    position: 'relative',
    padding: 12,
    backgroundColor: 'white',
  },
  commentImage: {
    width: 200,
    height: 200,
    marginTop: 8,
  },
  deleteCommentBtn: {
    position: 'absolute',
    top: 0,
    right: -20,
  },
  deleteCommentImage: {},
  bottomView: {
    width: '100%',
    justifyContent: 'space-between',
    alignContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 12,
    paddingVertical: 8,
    backgroundColor: 'white',
  },
  icnCamera: {
    width: 31,
    height: 29,
    marginStart: 12,
    opacity: 0.6,
  },
  commentInputContainer: {
    width: '100%',
    alignItems: 'center',
  },
  commentInput: {
    flex: 1,
  },
  commentBtn: {
    height: 45,
    marginStart: 8,
    lineHeight: 43,
    borderWidth: 1,
    borderColor: '#ddd',
  },
  commentBtnTxt: {
    height: 45,
    lineHeight: 43,
    fontFamily: 'Pretendard',
    fontSize: 12,
    textAlign: 'center',
    width: 55,
    color: 'black',
  },
  commentItemContainer: {
    borderBottomWidth: 1,
    borderBottomColor: '#ddd',
    paddingVertical: 12,
    paddingHorizontal: 8,
    flexDirection: 'row',
  },
  commentContent: {
    fontFamily: 'Pretendard',
    fontSize: 15,
    color: 'black',
    marginTop: 4,
  },
  rightArr: {
    width: 20,
    height: 20,
    marginStart: 2,
  },
  myComment: {
    width: 44,
    height: 20,
    textAlign: 'center',
    color: Colors.main,
    fontFamily: 'Pretendard',
    fontSize: 11,
    borderWidth: 1,
    borderColor: Colors.main,
    paddingHorizontal: 4,
    borderRadius: 10,
    marginStart: 8,
    justifyContent: 'center',
    alignItems: 'center',
    lineHeight: 18,
  },
  userName: {
    fontSize: 13,
    color: '#111',
    fontWeight: 'bold',
    fontFamily: 'Pretendard-Bold',
  },
  rightContainer: {
    marginStart: 8,
    flex: 1,
  },
  date: {
    fontFamily: 'Pretendard',
    fontSize: 12,
    color: '#666',
    marginTop: 5,
  },
  btn: {
    backgroundColor: Colors.main,
    borderRadius: 15,
    height: 30,
    width: 60,
    alignItems: 'center',
    justifyContent: 'center',
    marginStart: 16,
  },
  btnTxt: {
    fontFamily: 'Pretendard',
    color: 'white',
    fontSize: 16,
  },
  childLeft: {width: 40},
});

export default CommentChildView;

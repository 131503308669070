import React, {useState, useEffect, useRef, useMemo} from 'react';
import {
  Image,
  StyleSheet,
  FlatList,
  Text,
  View,
  TextInput,
  TouchableOpacity,
  Dimensions,
  Platform,
} from 'react-native';
import Colors from '../../common/colors';
import styles from '../../common/styles';
import SelectDropdown from 'react-native-select-dropdown';
import CategoryRecordItem from '../../component/CategoryRecordItem';
import RecordItem from '../RecordItem';
import AsyncStorage from '@react-native-async-storage/async-storage';
import api from '../../common/api';
import {ScrollView} from 'react-native-gesture-handler';

const TeamRecord = ({teamData, navigation}) => {
  const [applyRegulatedIn, setApplyRegulatedIn] = useState(true);
  const [pitcherBatterToggle, setPitcherBatterToggle] = useState(1);
  const [primaryRecordTable, setPrimaryRecordTable] = useState();
  const [rankingList, setRankingList] = useState([]);
  const [currentRank, setCurrentRank] = useState('');
  const [subLeagues, setSubLeagues] = useState();
  const [seasonOptions, setSeasonOptions] = useState([]);
  const [seasonYear, setSeasonYear] = useState();
  const [subLeagueId, setSubLeagueId] = useState();
  const [matchedIndex, setMatchedIndex] = useState();
  const accessToken = useRef();
  const lastOrderDir = useRef();
  const pageNo = useRef();
  const loading = useRef();
  const reachEnd = useRef();
  const lastRanking = useRef(1);
  const lastRankingValue = useRef();

  const subLeagueDropDownRef = useRef();
  const rankingCategoryList = [
    {type: 0, text: 'ERA', orderBy: 'era', orderDir: 'ASC'},
    {type: 0, text: '경기수', orderBy: 'pgame', orderDir: 'DESC'},
    {type: 0, text: '이닝', orderBy: 'innings', orderDir: 'DESC'},
    {type: 0, text: '승', orderBy: 'win', orderDir: 'DESC'},
    {type: 0, text: '패', orderBy: 'lose', orderDir: 'DESC'},
    {type: 0, text: '세이브', orderBy: 'save', orderDir: 'DESC'},
    {type: 0, text: '홀드', orderBy: 'hold', orderDir: 'DESC'},
    {type: 0, text: '삼진', orderBy: 'so', orderDir: 'DESC'},
    {type: 0, text: '피안타', orderBy: 'ph', orderDir: 'DESC'},
    {type: 0, text: '피홈런', orderBy: 'phr', orderDir: 'DESC'},
    {type: 0, text: '실점', orderBy: 'r', orderDir: 'DESC'},
    {type: 0, text: '볼넷', orderBy: 'pbb', orderDir: 'DESC'},
    {type: 0, text: '사구', orderBy: 'phitByPitch', orderDir: 'DESC'},
    {type: 0, text: '승률', orderBy: 'wrate', orderDir: 'DESC'},
    {type: 0, text: 'WHIP', orderBy: 'whip', orderDir: 'ASC'},
    {type: 1, text: '타율', orderBy: 'havg', orderDir: 'DESC'},
    {type: 1, text: '경기수', orderBy: 'hgame', orderDir: 'DESC'},
    {type: 1, text: '타석', orderBy: 'htb', orderDir: 'DESC'},
    {type: 1, text: '타수', orderBy: 'hab', orderDir: 'DESC'},
    {type: 1, text: '총안타', orderBy: 'h', orderDir: 'DESC'},
    {type: 1, text: '1루타', orderBy: 'h1', orderDir: 'DESC'},
    {type: 1, text: '2루타', orderBy: 'h2', orderDir: 'DESC'},
    {type: 1, text: '3루타', orderBy: 'h3', orderDir: 'DESC'},
    {type: 1, text: '홈런', orderBy: 'hr', orderDir: 'DESC'},
    {type: 1, text: '타점', orderBy: 'rbi', orderDir: 'DESC'},
    {type: 1, text: '득점', orderBy: 'her', orderDir: 'DESC'},
    {type: 1, text: '도루', orderBy: 'sb', orderDir: 'DESC'},
    {type: 1, text: '볼넷', orderBy: 'hbb', orderDir: 'DESC'},
    {type: 1, text: '삼진', orderBy: 'hso', orderDir: 'DESC'},
    {type: 1, text: '출루율', orderBy: 'obrate', orderDir: 'DESC'},
    {type: 1, text: '장타율', orderBy: 'srate', orderDir: 'DESC'},
    {type: 1, text: 'OPS', orderBy: 'ops', orderDir: 'DESC'},
  ];
  useEffect(() => {
    AsyncStorage.getItem('@accessToken', (err, result) => {
      if (err) {
        return;
      }
      accessToken.current = result;
      getSeasonList();
      getSubLeagues();
    });
  }, []);

  useEffect(() => {
    if (seasonOptions.length > 0) {
      setSeasonYear(seasonOptions[seasonOptions.length - 1]);
    }
  }, [seasonOptions]);

  useEffect(() => {
    if (!seasonYear) {
      return;
    }
    subLeagueDropDownRef?.current?.reset();
    getSubLeagues(accessToken.current);
  }, [seasonYear]);

  useEffect(() => {
    if (pitcherBatterToggle === 0) {
      getPersonalRankingData('era', 'asc', false, false);
    } else {
      getPersonalRankingData('havg', 'desc', true, false);
    }
  }, [pitcherBatterToggle]);

  useEffect(() => {
    getPrimaryRecordTable();
    getPersonalRankingData('havg', 'desc', true, false);
    setPitcherBatterToggle(1);
  }, [subLeagueId]);

  useEffect(() => {
    getPersonalRankingData(
      currentRank,
      lastOrderDir.current,
      pitcherBatterToggle === 1,
      false,
    );
  }, [applyRegulatedIn]);

  const getPrimaryRecordTable = () => {
    let url = `games/records/primary?teamId=${teamData.id}`;
    if (subLeagueId) {
      url += `&subLeagueId=${subLeagueId}`;
    }
    if (seasonYear) {
      url += `&seasonYear=${seasonYear}`;
    }
    url += `&regulatedIn=${applyRegulatedIn}`;
    console.log('url', url);
    api
      .get(url, {
        headers: {
          Authorization: `Bearer ${accessToken.current}`,
        },
      })
      .then(function (response) {
        setPrimaryRecordTable(response.data);
      })
      .catch(function (error) {
        console.log(error.response);
      });
  };

  const getPersonalRankingData = (orderBy, orderDir, batter, loadMore) => {
    if (loading.current) {
      return;
    }

    lastOrderDir.current = orderDir;

    if (loadMore) {
      pageNo.current++;
    } else {
      setRankingList([]);
      reachEnd.current = false;
      pageNo.current = 0;
      lastRanking.current = 1;
      lastRankingValue.current = null;
    }
    if (reachEnd.current) {
      return;
    }
    loading.current = true;
    let url = `games/records/ranking?teamId=${
      teamData.id
    }&orderBy=${orderBy}&orderDir=${orderDir}&pageNo=${
      loadMore ? pageNo.current : 0
    }&batter=${batter ? batter : false}`;
    if (subLeagueId) {
      url += `&subLeagueId=${subLeagueId}`;
    }
    if (seasonYear) {
      url += `&seasonYear=${seasonYear}`;
    }
    url += `&regulatedIn=${applyRegulatedIn}`;
    console.log('url', url);
    api
      .get(url, {
        headers: {
          Authorization: `Bearer ${accessToken.current}`,
        },
      })
      .then(function (response) {
        setCurrentRank(orderBy);
        if (!response.data || response.data.length === 0) {
          reachEnd.current = true;
          return;
        }
        for (let i = 0; i < response.data.length; i++) {
          if (lastRankingValue.current === response.data[i][orderBy]) {
            response.data[i].ranking = lastRanking.current;
          } else {
            response.data[i].ranking =
              i + 1 + (pageNo.current ? pageNo.current * 20 : 0 * 20);
            lastRanking.current =
              i + 1 + (pageNo.current ? pageNo.current * 20 : 0 * 20);
          }
          lastRankingValue.current = response.data[i][orderBy];
        }
        if (loadMore) {
          setRankingList([...rankingList, ...response.data]);
        } else {
          setRankingList(response.data);
        }
      })
      .catch(function (error) {
        console.log(error.response);
      })
      .finally(function () {
        loading.current = false;
      });
  };

  const getSubLeagues = () => {
    if (!seasonYear) {
      return;
    }
    let url = `app-team/my-sub-leagues?teamId=${teamData.id}&seasonYear=${seasonYear}`;
    api
      .get(url, {
        headers: {
          Authorization: `Bearer ${accessToken.current}`,
        },
      })
      .then(function (response) {
        setSubLeagues(response.data);
        if (response.data && response.data?.length > 0) {
          setSubLeagueId(response.data[0].id);
        }
      })
      .catch(function (error) {
        console.log(error.response);
      });
  };

  const getProfileImageUrl = item => {
    if (item.user?.profileImageThumbUrl) {
      return {uri: item.user?.profileImageThumbUrl};
    }
    if (item.user?.profileImageUrl) {
      return {uri: item.user?.profileImageUrl};
    }
    return require('../../assets/images/icn_profile_img.png');
  };

  const scrollRef = useRef();
  const [matchedValue, setMatchedValue] = useState();

  const goToRow = value => {
    const matched = rankingList.filter(i => i.user.name === value);
    let matchedIndex = 0;
    for (let i = 0; i < rankingList.length; i++) {
      if (value === rankingList[i].user.name) {
        matchedIndex = i;
      }
    }
    if (!matched || matched.length === 0) {
      setMatchedValue('');
      return;
    }
    setMatchedValue(value);
    scrollRef.current?.scrollTo({y: 300 + matchedIndex * 30});
  };

  const subLeaguesOptions = useMemo(() => {
    if (!subLeagues) {
      return [];
    }
    return subLeagues;
  }, [subLeagues]);

  const getSeasonList = () => {
    api
      .get(`app-team/my-seasons?teamId=${teamData.id}`, {
        headers: {
          Authorization: `Bearer ${accessToken.current}`,
        },
      })
      .then(function (response) {
        let seasonOptionList = [];
        for (let i = 0; i < response.data.length; i++) {
          seasonOptionList.push(response.data[i].year);
        }
        setSeasonOptions(seasonOptionList);
      })
      .catch(function (error) {
        console.log(error.response);
        alert('요청에 실패했습니다.');
      });
  };

  const subLeagueName = useMemo(() => {
    for (let i = 0; i < subLeagues?.length; i++) {
      if (subLeagues[i].id === subLeagueId) {
        console.log('subLeagues[i].name', subLeagues[i].name);
        return subLeagues[i].name;
      }
    }
    return '';
  }, [subLeagueId]);

  return (
    <ScrollView
      style={[
        Platform.OS === 'web' && {
          height: Dimensions.get('window').height - 100,
        },
      ]}
      scrollEventThrottle={100}
      onScroll={e => {
        let paddingToBottom = 1;
        paddingToBottom += e.nativeEvent.layoutMeasurement.height;
        // console.log(Math.floor(paddingToBottom) + "-" + Math.floor(e.nativeEvent.contentOffset.y) + "-" + Math.floor(e.nativeEvent.contentSize.height));
        if (
          e.nativeEvent.contentOffset.y + paddingToBottom >=
          e.nativeEvent.contentSize.height
        ) {
          getPersonalRankingData(
            currentRank,
            lastOrderDir.current,
            pitcherBatterToggle === 1,
            true,
          );
        }
      }}
      ref={scrollRef}>
      <View style={[pageStyle.innerContainer]}>
        <View style={[styles.row, pageStyle.filterContainer]}>
          <View style={[styles.col, styles.mr7]}>
            <SelectDropdown
              defaultValue={seasonYear}
              defaultButtonText="시즌"
              buttonStyle={styles.dropDown}
              buttonTextStyle={styles.downDownText}
              rowTextStyle={styles.dropDownRowText}
              data={seasonOptions}
              onSelect={(selectedItem, index) => {
                if (selectedItem === '전체') {
                  setSeasonYear('');
                } else {
                  setSeasonYear(selectedItem);
                }
              }}
              rowTextForSelection={(item, index) => {
                return `${item}시즌`;
              }}
              buttonTextAfterSelection={(selectedItem, index) => {
                return `${selectedItem}시즌`;
              }}
              renderDropdownIcon={() => (
                <Image source={require('../../assets/images/select_arr.png')} />
              )}
            />
          </View>
          <View style={[styles.col]}>
            <SelectDropdown
              ref={subLeagueDropDownRef}
              defaultValue={subLeagueName}
              defaultButtonText={subLeagueName}
              buttonStyle={pageStyle.dropDown}
              buttonTextStyle={pageStyle.downDownText}
              rowTextStyle={styles.dropDownRowText}
              renderDropdownIcon={() => (
                <Image source={require('../../assets/images/select_arr.png')} />
              )}
              data={subLeaguesOptions}
              onSelect={(selectedItem, index) => {
                setSubLeagueId(selectedItem.id);
              }}
              buttonTextAfterSelection={(selectedItem, index) => {
                if (!selectedItem.id) {
                  return '선택 ';
                }
                return `${selectedItem.name}`;
              }}
              rowTextForSelection={(item, index) => {
                if (!item.id) {
                  return '선택 ';
                }
                return `${item.name}`;
              }}
            />
          </View>
        </View>
        <View>
          <View style={[styles.col2, pageStyle.searchInputContainer]}>
            <TextInput
              style={[pageStyle.searchInput]}
              placeholder={'선수 검색'}
              placeholderTextColor={'#aaa'}
              onChangeText={value => goToRow(value)}
            />
            <Image
              style={[pageStyle.searchIcon]}
              source={require('../../assets/images/icn_search.png')}
            />
          </View>
        </View>
        <Text style={[pageStyle.subtitle]}>주요부분순위</Text>
        {Platform.OS === 'web' && (
          <ScrollView
            horizontal={true}
            style={[pageStyle.rankBg, {paddingHorizontal: 25}]}
            showsHorizontalScrollIndicator={Platform.OS === 'web'}>
            {primaryRecordTable?.map(item => (
              <CategoryRecordItem data={item} navigation={navigation} />
            ))}
          </ScrollView>
        )}
        {Platform.OS !== 'web' && (
          <FlatList
            showsHorizontalScrollIndicator={false}
            showsVerticalScrollIndicator={false}
            ListHeaderComponent={<View style={[pageStyle.rankPadding]} />}
            horizontal={true}
            style={[pageStyle.rankBg]}
            keyExtractor={item => `rank_${item.category.toString()}`}
            data={primaryRecordTable}
            renderItem={({item}) => (
              <CategoryRecordItem data={item} navigation={navigation} />
            )}
            ListEmptyComponent={() => (
              <View style={[styles.emptyContainer]}>
                <Image source={require('../../assets/images/logo2.png')} />
              </View>
            )}
          />
        )}
        <View style={[pageStyle.tabSelectorContainer]}>
          <TouchableOpacity
            style={[
              applyRegulatedIn && pageStyle.buttonActive,
              !applyRegulatedIn && pageStyle.buttonInactive,
            ]}
            onPress={() => setApplyRegulatedIn(true)}>
            <Text
              style={[
                pageStyle.tabItem,
                pageStyle.buttonText,
                applyRegulatedIn && pageStyle.buttonTextSelected,
              ]}>
              규정 IN
            </Text>
          </TouchableOpacity>
          <Text style={[pageStyle.tabItem, pageStyle.divider]}>|</Text>
          <TouchableOpacity
            style={[
              !applyRegulatedIn && pageStyle.buttonActive,
              applyRegulatedIn && pageStyle.buttonInactive,
            ]}
            onPress={() => setApplyRegulatedIn(false)}>
            <Text
              style={[
                pageStyle.tabItem,
                pageStyle.buttonText,
                !applyRegulatedIn && pageStyle.buttonTextSelected,
              ]}>
              규정 OUT
            </Text>
          </TouchableOpacity>
        </View>
        <View
          style={[
            {
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            },
          ]}>
          <View style={[pageStyle.tabSelectorContainer]}>
            <TouchableOpacity onPress={() => setPitcherBatterToggle(1)}>
              <Text
                style={[
                  pageStyle.tabItem,
                  pitcherBatterToggle === 1 && pageStyle.tabItemSelected,
                ]}>
                타자순위
              </Text>
            </TouchableOpacity>
            <Text style={[pageStyle.tabItem, pageStyle.divider]}>|</Text>
            <TouchableOpacity onPress={() => setPitcherBatterToggle(0)}>
              <Text
                style={[
                  pageStyle.tabItem,
                  pitcherBatterToggle === 0 && pageStyle.tabItemSelected,
                ]}>
                투수순위
              </Text>
            </TouchableOpacity>
          </View>
          <Text
            style={[
              pageStyle.tabItem,
              {fontSize: 12, marginTop: 12, marginEnd: 12},
            ]}>
            규정이닝{' '}
            <Text
              style={[
                pageStyle.tabItem,
                {fontSize: 12, marginTop: 12, marginEnd: 12, color: 'red'},
              ]}>
              {teamData?.minimumInnings}
            </Text>{' '}
            / 규정타석{' '}
            <Text
              style={[
                pageStyle.tabItem,
                {fontSize: 12, marginTop: 12, marginEnd: 12, color: 'red'},
              ]}>
              {teamData?.minimumAtBat}
            </Text>
          </Text>
        </View>
        <View style={[{flexDirection: 'row'}, pageStyle.tableHeader]}>
          <View style={[{}]}>
            <View style={[{height: 25}]} />
            {rankingList.map((item, index) => {
              return (
                <TouchableOpacity
                  onPress={() =>
                    navigation.navigate('MemberPage', {
                      item: item.user,
                      itemId: item.user.uid,
                    })
                  }
                  style={[pageStyle.rowContainer]}>
                  <Text style={[pageStyle.rank]}>{item.ranking}</Text>
                  <View style={[pageStyle.td]}>
                    <Image
                      style={[
                        {
                          width: 30,
                          height: 30,
                          resizeMode: 'cover',
                          borderRadius: 15,
                        },
                      ]}
                      source={getProfileImageUrl(item)}
                    />
                  </View>
                  <Text style={[pageStyle.td2]}>{item.user.name}</Text>
                </TouchableOpacity>
              );
            })}
          </View>
          <ScrollView
            horizontal={true}
            showsHorizontalScrollIndicator={Platform.OS === 'web'}>
            <View>
              <View style={[styles.row, {height: 25}]}>
                {rankingCategoryList.map(item => {
                  return (
                    pitcherBatterToggle === item.type && (
                      <TouchableOpacity
                        onPress={() =>
                          getPersonalRankingData(
                            item.orderBy,
                            item.orderDir,
                            pitcherBatterToggle === 1,
                            false,
                          )
                        }>
                        <Text
                          style={[
                            pageStyle.th,
                            currentRank === item.orderBy && pageStyle.blue,
                          ]}>
                          {item.text} ▼
                        </Text>
                      </TouchableOpacity>
                    )
                  );
                })}
              </View>
              {rankingList.map((item, index) => {
                return (
                  <TouchableOpacity onPress={() => setMatchedIndex(index)}>
                    <RecordItem
                      data={item}
                      index={index}
                      exclude={['tgame']}
                      currentRank={currentRank}
                      pitcherBatterToggle={pitcherBatterToggle}
                      showIndicator={matchedIndex === index}
                    />
                  </TouchableOpacity>
                );
              })}
            </View>
          </ScrollView>
          <FlatList
            showsHorizontalScrollIndicator={Platform.OS === 'web'}
            showsVerticalScrollIndicator={false}
            horizontal={true}
            ListHeaderComponent={
              <View style={[styles.row, pageStyle.tableHeader]}>
                <View style={[{width: 35}]} />
                <View style={[pageStyle.th]} />
                <View style={[pageStyle.th2]} />
                {rankingCategoryList.map(item => {
                  return (
                    pitcherBatterToggle === item.type && (
                      <TouchableOpacity
                        onPress={() =>
                          getPersonalRankingData(
                            item.orderBy,
                            item.orderDir,
                            pitcherBatterToggle === 1,
                            false,
                          )
                        }>
                        <Text
                          style={[
                            pageStyle.th,
                            currentRank === item.orderBy && pageStyle.blue,
                          ]}>
                          {item.text} ▼
                        </Text>
                      </TouchableOpacity>
                    )
                  );
                })}
              </View>
            }
            style={[{flex: 1}]}
            keyExtractor={item => `rank_type2_${item.id.toString()}`}
            data={rankingList}
            renderItem={({item, index}) => (
              <TouchableOpacity onPress={() => setMatchedIndex(index)}>
                <RecordItem
                  data={item}
                  index={index}
                  exclude={['tgame']}
                  pitcherBatterToggle={pitcherBatterToggle}
                  showIndicator={matchedIndex === index}
                />
              </TouchableOpacity>
            )}
            ListEmptyComponent={() => (
              <View style={[styles.emptyContainer]}>
                <Image source={require('../../assets/images/logo2.png')} />
              </View>
            )}
          />
        </View>
      </View>
    </ScrollView>
  );
};

export default TeamRecord;

const pageStyle = StyleSheet.create({
  tableHeader: {
    marginTop: 19,
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  innerContainer: {
    flex: 1,
    paddingBottom: 100,
  },
  filterContainer: {
    marginTop: 10,
    marginHorizontal: 24,
  },
  subtitle: {
    marginTop: 13,
    color: Colors.main,
    fontWeight: '500',
    marginHorizontal: 30,
  },
  searchInput: {
    borderWidth: 1,
    borderColor: '#ccc',
    borderRadius: 13,
    fontSize: 12,
    height: 30,
    padding: 0,
    paddingEnd: 4.5,
    color: 'black',
    textAlign: 'left',
    paddingStart: 35,
  },
  searchInputContainer: {
    flex: 2,
    marginTop: 4,
    position: 'relative',
    marginHorizontal: 24,
  },
  searchIcon: {
    position: 'absolute',
    top: 6,
    left: 8,
    width: 17,
    height: 17,
  },
  keyworkInput: {},
  th: {
    width: 47,
    textAlign: 'center',
    fontSize: 10,
    fontWeight: '300',
    color: 'black',
    justifyContent: 'center',
    alignItems: 'center',
  },
  th2: {
    width: 65,
    textAlign: 'center',
    fontSize: 10,
    fontWeight: '300',
    color: 'black',
    justifyContent: 'center',
    alignItems: 'center',
  },
  dropDown: {
    width: '100%',
    backgroundColor: 'white',
    borderWidth: 1,
    borderColor: 'rgb(61, 80, 183)',
    height: 30,
  },
  downDownText: {
    color: 'black',
    fontSize: 11,
  },
  rankBg: {
    backgroundColor: 'rgb(236, 240, 255)',
    marginTop: 17,
  },
  rankPadding: {width: 25},
  tabSelectorContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 12,
    marginHorizontal: 30,
    alignItems: 'center',
  },
  tabItemSelected: {
    color: Colors.main,
    fontWeight: '500',
  },
  tabItem: {
    Color: '#9fa0a0',
    fontWeight: '300',
  },
  buttonText: {
    color: Colors.main,
  },
  buttonTextSelected: {
    color: 'white',
  },
  buttonActive: {
    backgroundColor: Colors.main,
    borderRadius: 20,
    height: 25,
    flex: 1,
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
  },
  buttonInactive: {
    borderColor: Colors.main,
    borderWidth: 1,
    backgroundColor: 'white',
    borderRadius: 20,
    height: 25,
    flex: 1,
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
  },
  divider: {
    color: '#aaa',
    marginHorizontal: 8,
  },
  blue: {color: Colors.main, fontWeight: 'bold'},
  rowContainer: {
    flexDirection: 'row',
    paddingVertical: 7,
    height: 45,
    borderBottomWidth: 1,
    borderBottomColor: 'rgba(0, 0, 0, 0.1)',
  },
  rank: {
    width: 40,
    paddingStart: 10,
    alignItems: 'center',
    justifyContent: 'center',
    alignContent: 'center',
    color: 'black',
    alignSelf: 'center',
    textAlign: 'center',
  },
  td: {
    width: 45,
    textAlign: 'center',
    color: 'black',
    fontSize: 12,
    fontWeight: '300',
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'center',
  },
  td2: {
    width: 65,
    color: 'black',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'center',
  },
});

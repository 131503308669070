import React, {useEffect, useState, useRef, useCallback, useMemo} from 'react';
import {
  Image,
  SafeAreaView,
  DeviceEventEmitter,
  Text,
  View,
  StyleSheet,
  FlatList,
  TextInput,
  Modal,
  TouchableOpacity,
  Platform,
} from 'react-native';
import styles from '../common/styles';
import {Shadow} from 'react-native-shadow-2';
import BoardMetaItem from '../component/BoardMetaItem';
import AsyncStorage from '@react-native-async-storage/async-storage';
import api from '../common/api';
import * as Progress from 'react-native-progress';
import {debounce} from 'lodash';
import moment from 'moment';
import Colors from '../common/colors';
import BoardCommentMetaItem from '../component/BoardCommentMetaItem';
import {title} from '../common/util';

const MyBoardView = ({navigation}) => {
  const [tabIndex, setTabIndex] = useState(0);
  const [boardPage, setBoardPage] = useState({});
  const [commentPage, setCommentPage] = useState({});
  const [userInfo, setUserInfo] = useState({});
  const [keyword, setKeyword] = useState([]);
  const [progressShown, setProgressShown] = useState(false);

  let pageLoading = useRef(false);

  let accessToken = useRef();

  useEffect(() => {
    AsyncStorage.getItem('@accessToken', (err, result) => {
      accessToken.current = result;
      getData(result);
    });
  }, []);

  const getUserInfo = token => {
    api
      .get('/app-user/me', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(function (response) {
        setUserInfo(response.data);
      })
      .catch(function (error) {
        console.log(error.response);
        if (error.response.status === 401) {
          alert('탈퇴처리된 계정입니다.');
          AsyncStorage.removeItem('@accessToken', () => {
            navigation.reset({
              index: 0,
              routes: [{name: 'Login'}],
            });
          });
        }
      });
  };
  useEffect(() => {
    AsyncStorage.getItem('@accessToken', (err, result) => {
      accessToken.current = result;
      getUserInfo(result);
      getData(result);
    });
  }, []);

  const activePage = useMemo(() => {
    if (tabIndex === 0) {
      return boardPage;
    } else if (tabIndex === 1) {
      return commentPage;
    }
    return boardPage;
  }, [tabIndex]);

  useEffect(() => {
    getData(accessToken.current);
  }, [tabIndex]);

  const url = useMemo(() => {
    if (tabIndex === 0) {
      return '/app-board/my-list';
    } else if (tabIndex === 1) {
      return '/app-board/my-comments';
    }
    return '/app-board/my-list';
  }, [tabIndex]);

  const getData = token => {
    setProgressShown(true);
    api
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(function (response) {
        if (tabIndex === 0) {
          setBoardPage(response.data);
        } else if (tabIndex === 1) {
          setCommentPage(response.data);
        }
        setProgressShown(false);
      })
      .catch(function (error) {
        console.log(error);
        setProgressShown(false);
      });
  };

  const handlePageLoadMore = () => {
    if (pageLoading.current) {
      return;
    }
    if (
      activePage.currentPage &&
      activePage.totalPage <= activePage.currentPage
    ) {
      return;
    }
    pageLoading.current = true;
    api
      .get(
        `${url}?pageNo=${
          activePage.currentPage ? parseInt(activePage.currentPage, 10) + 1 : 1
        }&pageSize=20`,
        {
          headers: {
            Authorization: `Bearer ${accessToken.current}`,
          },
        },
      )
      .then(function (response) {
        response.data.items = [
          ...(activePage.items ? activePage.items : []),
          ...response.data.items,
        ];
        if (tabIndex === 0) {
          setBoardPage(response.data);
        } else if (tabIndex === 1) {
          setCommentPage(response.data);
        }
        pageLoading.current = false;
        setProgressShown(false);
      })
      .catch(function (error) {
        console.log(error);
        pageLoading.current = false;
        setProgressShown(false);
      });
  };

  const getProfileImageStyle = user => {
    if (user?.profileImageThumbUrl) {
      return pageStyle.profileImage;
    }
    if (user?.profileImageUrl) {
      return pageStyle.profileImage;
    }
    return {
      position: 'relative',
      width: 60,
      height: 60,
      resizeMode: 'cover',
      borderRadius: 30,
    };
  };

  const getProfileImageUrl = user => {
    if (user?.profileImageThumbUrl) {
      return {uri: user?.profileImageThumbUrl};
    }
    if (user?.profileImageUrl) {
      return {uri: user?.profileImageUrl};
    }
    return require('../assets/images/icn_profile_img.png');
  };

  return (
    <>
      <SafeAreaView nativeID="common-page-container" style={[styles.container]}>
        <Shadow
          offset={[0, 2]}
          style={[{width: '100%'}]}
          containerStyle={[{marginTop: 0}]}
          distance={7}
          sides={{bottom: true, top: false, start: false, end: false}}>
          <View style={[styles.headerCommon]}>
            <View style={[styles.row, styles.verticalAlign]}>
              <TouchableOpacity
                onPress={() =>
                  Platform.OS === 'web' ? history.back() : navigation.goBack()
                }>
                <Image
                  source={require('../assets/images/icon_back.png')}
                  style={[styles.headerBackButton]}
                />
              </TouchableOpacity>
              <Text style={[styles.headerText]}>내 글 보기</Text>
            </View>
            <View style={[styles.row, styles.verticalAlign]} />
          </View>
        </Shadow>
        <View
          style={[
            {
              marginTop: 10,
              position: 'relative',
              height: '90%',
            },
          ]}>
          <View
            style={[styles.scrollViewContainer, pageStyle.scrollViewContainer]}>
            <View style={[styles.row, pageStyle.userInfoRow]}>
              <View style={[pageStyle.userInfoTxtContainer]}>
                <Text style={[pageStyle.userNameTxt]}>{userInfo.name}</Text>
                <Text style={[pageStyle.txt2]}>
                  {userInfo.pitchingAndHitting}
                  {' / '}
                  {moment().diff(moment(userInfo?.birth, 'YYYY-MM-DD'), 'y')}세
                </Text>
              </View>
              <View style={[pageStyle.profileContainer]}>
                <Image
                  source={getProfileImageUrl(userInfo)}
                  style={getProfileImageStyle(userInfo)}
                  resizeMode={'cover'}
                />
              </View>
            </View>
            <View style={[styles.tabContainer, pageStyle.buttonContainer]}>
              <TouchableOpacity
                onPress={() => setTabIndex(0)}
                style={[pageStyle.btn, tabIndex === 0 && pageStyle.btnActive]}>
                <Text
                  style={[
                    pageStyle.btnTxt,
                    tabIndex === 0 && pageStyle.btnActiveTxt,
                  ]}>
                  작성한 글
                </Text>
              </TouchableOpacity>
              <TouchableOpacity
                onPress={() => setTabIndex(1)}
                style={[pageStyle.btn, tabIndex === 1 && pageStyle.btnActive]}>
                <Text
                  style={[
                    pageStyle.btnTxt,
                    tabIndex === 1 && pageStyle.btnActiveTxt,
                  ]}>
                  작성한 댓글
                </Text>
              </TouchableOpacity>
            </View>
            {tabIndex === 0 && (
              <FlatList
                nestedScrollEnabled={true}
                style={[pageStyle.list]}
                keyExtractor={item => `board_${item.id.toString()}`}
                data={boardPage.items}
                onEndReachedThreshold={1}
                onEndReached={handlePageLoadMore}
                renderItem={({item}) => (
                  <TouchableOpacity
                    key={`overall_${item.id}`}
                    onPress={() => {
                      navigation.navigate('CommunityDetail', {
                        item: item,
                        itemId: item.id,
                      });
                    }}>
                    <BoardMetaItem data={item} />
                  </TouchableOpacity>
                )}
                ListEmptyComponent={() => (
                  <View style={[styles.emptyContainer]}>
                    <Image source={require('../assets/images/logo2.png')} />
                  </View>
                )}
              />
            )}
            {tabIndex === 1 && (
              <View>
                <FlatList
                  nestedScrollEnabled={true}
                  style={[pageStyle.list]}
                  keyExtractor={item => `free_${item.id.toString()}`}
                  data={commentPage.items}
                  onEndReachedThreshold={1}
                  onEndReached={handlePageLoadMore}
                  renderItem={({item}) => (
                    <TouchableOpacity
                      key={`comment_${item.id}`}
                      onPress={() => {
                        navigation.navigate('CommunityDetail', {
                          item: item.board,
                          itemId: item.board?.id,
                        });
                      }}>
                      <BoardCommentMetaItem data={item} />
                    </TouchableOpacity>
                  )}
                  ListFooterComponent={() => <View style={[{height: 200}]} />}
                  ListEmptyComponent={() => (
                    <View style={[styles.emptyContainer]}>
                      <Image source={require('../assets/images/logo2.png')} />
                    </View>
                  )}
                />
              </View>
            )}
          </View>
          {progressShown && (
            <View style={[styles.progressContainer]}>
              <Progress.Circle
                fill="transparent"
                size={70}
                borderWidth={3}
                indeterminate={true}
              />
            </View>
          )}
        </View>
      </SafeAreaView>
    </>
  );
};

const pageStyle = StyleSheet.create({
  list: {
    paddingTop: 10,
    paddingBottom: 80,
    marginBottom: 150,
  },
  userInfoRow: {
    justifyContent: 'space-between',
    padding: 24,
  },
  userInfoTxtContainer: {},
  userNameTxt: {
    fontFamily: 'Pretendard-Medium',
    fontWeight: '600',
    color: 'black',
    fontSize: 18,
  },
  txt2: {
    color: '#7c7c7c',
    fontFamily: 'Pretendard',
    fontSize: 14,
    marginTop: 8,
  },
  profileImage: {
    height: 60,
    width: 60,
    borderRadius: 30,
  },
  buttonContainer: {
    paddingTop: 0,
    flexDirection: 'row',
    width: '100%',
    borderBottomColor: '#e6e6e6',
    borderBottomWidth: 1,
    paddingBottom: 12,
  },
  btn: {
    flex: 1,
    marginHorizontal: 8,
    height: 30,
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    borderColor: Colors.main,
    borderWidth: 1,
    borderRadius: 15,
  },
  btnActive: {
    backgroundColor: Colors.main,
    borderWidth: 0,
  },
  btnTxt: {
    color: Colors.main,
    fontFamily: 'Pretendard',
    fontSize: 13,
    lineHeight: 30,
  },
  btnActiveTxt: {
    color: 'white',
  },
});

export default MyBoardView;

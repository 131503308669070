import React, {useState, useEffect, useRef} from 'react';
import {
  Image,
  SafeAreaView,
  ScrollView,
  Text,
  TouchableOpacity,
  View,
  StyleSheet,
  FlatList,
  TextInput,
  Dimensions,
  Platform,
} from 'react-native';
import styles from '../../common/styles';
import Colors from '../../common/colors';
import TeamItem from '../../component/TeamItem';
import SelectDropdown from 'react-native-select-dropdown';
import AsyncStorage from '@react-native-async-storage/async-storage';
import api from '../../common/api';
import * as Progress from 'react-native-progress';
import {useSafeAreaInsets} from 'react-native-safe-area-context';
const LeagueTeamList = ({navigation, item}) => {
  const [teamPage, setTeamPage] = useState({});
  const insets = useSafeAreaInsets();

  const [seasonId, setSeasonId] = useState();
  const [subLeagueId, setSubLeagueId] = useState();
  const [seasonList, setSeasonList] = useState();
  const [subLeagueList, setSubLeagueList] = useState();
  const [progressShown, setProgressShown] = useState(false);
  const [keyword, setKeyword] = useState('');
  const accessToken = useRef();
  let teamPageLoading = useRef();

  useEffect(() => {
    AsyncStorage.getItem('@accessToken', (err, result) => {
      accessToken.current = result;
      getData(result);
    });
  }, []);

  useEffect(() => {
    getSubLeagues(seasonId);
  }, [seasonId]);

  useEffect(() => {
    getTeams(accessToken.current);
  }, [seasonId, subLeagueId, keyword]);

  const getTeams = token => {
    if (!(token ? token : accessToken.current)) {
      return;
    }
    if (teamPageLoading.current) {
      return;
    }
    teamPageLoading.current = true;
    setProgressShown(true);
    api
      .get(
        `season-teams?leagueId=${item.id}&distinctTeam=true&subLeagueId=${
          subLeagueId ? subLeagueId : ''
        }&seasonId=${seasonId ? seasonId : ''}&keyword=${
          keyword ? keyword : ''
        }&pageNo=1&pageSize=10`,
        {
          headers: {
            Authorization: `Bearer ${token ? token : accessToken.current}`,
          },
        },
      )
      .then(function (response) {
        setTeamPage(response.data);
      })
      .catch(function (error) {
        console.log(error.response);
        alert('요청에 실패했습니다.');
      })
      .finally(() => {
        teamPageLoading.current = false;
        setProgressShown(false);
      });
  };

  const getData = token => {
    getTeams(token);
    api
      .get(`leagues/${item.id}/seasons`, {
        headers: {
          Authorization: `Bearer ${accessToken.current}`,
        },
      })
      .then(function (response) {
        setSeasonList(response.data);
      })
      .catch(function (error) {
        console.log(error.response);
        alert('요청에 실패했습니다.');
      });
  };
  const getSubLeagues = seasonId => {
    if (!accessToken.current) {
      return;
    }
    if (!seasonId) {
      return;
    }
    api
      .get(`sub-leagues?seasonId=${seasonId}`, {
        headers: {
          Authorization: `Bearer ${accessToken.current}`,
        },
      })
      .then(function (response) {
        let optionList = [];
        optionList = [{id: '', name: '전체'}, ...response.data];
        setSubLeagueList(optionList);
      })
      .catch(function (error) {
        console.log(error.response);
        alert('요청에 실패했습니다.');
      });
  };

  const handleTeamPageLoadMore = () => {
    if (teamPageLoading.current) {
      return;
    }
    if (teamPage.currentPage && teamPage.totalPage <= teamPage.currentPage) {
      return;
    }
    setProgressShown(true);
    teamPageLoading.current = true;
    api
      .get(
        `season-teams?leagueId=${item.id}&distinctTeam=true&subLeagueId=${
          subLeagueId ? subLeagueId : ''
        }&seasonId=${seasonId ? seasonId : ''}&keyword=${
          keyword ? keyword : ''
        }&pageNo=${
          teamPage.currentPage ? parseInt(teamPage.currentPage, 10) + 1 : 1
        }&pageSize=10`,
        {
          headers: {
            Authorization: `Bearer ${accessToken.current}`,
          },
        },
      )
      .then(function (response) {
        response.data.items = [
          ...(teamPage.items ? teamPage.items : []),
          ...response.data.items,
        ];
        setTeamPage(response.data);
      })
      .catch(function (error) {
        console.log(error);
      })
      .finally(() => {
        setProgressShown(false);
        teamPageLoading.current = false;
      });
  };
  return (
    <View
      style={[
        pageStyle.container,
        {
          height:
            Dimensions.get('window').height -
            (Platform.OS === 'ios' ? 130 + insets.bottom : 120),
        },
      ]}>
      <View style={[{}]}>
        <View style={[pageStyle.dropdownRowContainer]}>
          <View style={[pageStyle.dropDownItemContainer]}>
            <SelectDropdown
              defaultButtonText="시즌선택"
              buttonStyle={pageStyle.dropDown}
              buttonTextStyle={pageStyle.downDownText}
              rowTextStyle={styles.dropDownRowText}
              renderDropdownIcon={() => (
                <Image source={require('../../assets/images/select_arr.png')} />
              )}
              data={seasonList}
              onSelect={(selectedItem, index) => {
                setSeasonId(selectedItem.id);
              }}
              buttonTextAfterSelection={(selectedItem, index) => {
                return `${selectedItem.year}년`;
              }}
              rowTextForSelection={(item, index) => {
                return `${item.year}년`;
              }}
            />
          </View>
          <View style={[pageStyle.dropDownItemContainer]}>
            <SelectDropdown
              defaultButtonText="조리그분류"
              buttonStyle={pageStyle.dropDown}
              buttonTextStyle={pageStyle.downDownText}
              rowTextStyle={styles.dropDownRowText}
              renderDropdownIcon={() => (
                <Image source={require('../../assets/images/select_arr.png')} />
              )}
              data={subLeagueList}
              onSelect={(selectedItem, index) => {
                setSubLeagueId(selectedItem.id);
              }}
              buttonTextAfterSelection={(selectedItem, index) => {
                return `${selectedItem.name}`;
              }}
              rowTextForSelection={(item, index) => {
                return `${item.name}`;
              }}
            />
          </View>
        </View>
        <View style={[styles.row, pageStyle.filterContainer]}>
          <View style={[styles.col, {marginTop: 22}]}>
            <Text
              style={{
                paddingStart: 22,
                paddingTop: 9,
                fontSize: 12,
                color: 'black',
                fontWeight: '600',
              }}>
              참가팀: {item.totalTeams} 팀
            </Text>
          </View>
          <View style={[styles.col2, pageStyle.searchInputContainer]}>
            <TextInput
              style={[pageStyle.searchInput]}
              placeholder={'팀 검색'}
              placeholderTextColor={'#aaa'}
              value={keyword}
              onChangeText={value => {
                setKeyword(value);
              }}
            />
            <Image
              style={[pageStyle.searchIcon]}
              source={require('../../assets/images/icn_search.png')}
            />
          </View>
        </View>
      </View>
      <FlatList
        ListFooterComponent={() => (
          <View style={[{height: Platform.OS === 'web' ? 0 : 80}]} />
        )}
        ListEmptyComponent={() => (
          <View style={[styles.emptyContainer]}>
            <Image source={require('../../assets/images/logo2.png')} />
          </View>
        )}
        onEndReachedThreshold={1}
        onEndReached={handleTeamPageLoadMore}
        nestedScrollEnabled={true}
        style={[pageStyle.list]}
        keyExtractor={item => item.id.toString()}
        data={teamPage.items}
        renderItem={({item}) => (
          <TouchableOpacity
            onPress={() =>
              navigation.navigate('TeamDetail', {item: item, itemId: item?.id})
            }>
            <TeamItem data={item.team} />
          </TouchableOpacity>
        )}
      />
      {progressShown && (
        <View style={[styles.progressContainer]}>
          <Progress.Circle
            fill="transparent"
            size={70}
            borderWidth={3}
            indeterminate={true}
          />
        </View>
      )}
    </View>
  );
};

export default LeagueTeamList;

const pageStyle = StyleSheet.create({
  container: {},
  list: {
    paddingStart: 26,
    paddingEnd: 26,
    paddingTop: 10,
    marginTop: 8,
  },
  dropdownRowContainer: {
    display: 'flex',
    marginTop: 6,
    flexDirection: 'row',
    marginHorizontal: 23,
  },
  dropDownItemContainer: {
    marginHorizontal: 3,
    flex: 1,
  },
  dropDown: {
    width: '100%',
    backgroundColor: 'white',
    borderWidth: 1,
    borderColor: 'rgb(61, 80, 183)',
    height: 30,
  },
  downDownText: {
    color: 'black',
    fontSize: 11,
  },
  searchInput: {
    padding: 0,
    borderWidth: 1,
    borderColor: '#ccc',
    borderRadius: 13,
    fontSize: 12,
    height: 30,
    paddingEnd: 4.5,
    color: 'black',
    textAlign: 'left',
    paddingStart: 35,
  },
  searchInputContainer: {
    position: 'relative',
    marginHorizontal: 28,
    marginTop: 22,
  },
  searchIcon: {
    position: 'absolute',
    top: 7,
    left: 8,
    width: 17,
    height: 17,
  },
});

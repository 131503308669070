import React, {useState, useRef, useEffect, useCallback} from 'react';
import {
  Image,
  StyleSheet,
  FlatList,
  Text,
  View,
  TextInput,
  DeviceEventEmitter,
  Dimensions,
  Platform,
} from 'react-native';
import styles from '../../common/styles';
import MemberInfoItem from '../../component/team/MemberInfoItem';
import AsyncStorage from '@react-native-async-storage/async-storage';
import api from '../../common/api';
import * as Progress from 'react-native-progress';
import {debounce} from 'lodash';

const MemberList = props => {
  const [memberPage, setMemberPage] = useState({});
  const [progressShown, setProgressShown] = useState(false);
  const [input, setInput] = useState('');
  let memberPageLoading = useRef();
  let accessToken = useRef();
  const getData = (token, recentInput) => {
    if (!token) {
      return;
    }
    if (memberPageLoading.current) {
      return;
    }
    setProgressShown(true);
    memberPageLoading.current = true;
    api
      .get(
        `/app-team/teams/${props.teamData.id}/members?keyword=${
          recentInput ? recentInput : input ? input : ''
        }&pageNo=1`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then(function (response) {
        setMemberPage(response.data);
      })
      .catch(function (error) {
        console.log(error);
      })
      .finally(() => {
        setProgressShown(false);
        memberPageLoading.current = false;
      });
  };

  useEffect(() => {
    AsyncStorage.getItem('@accessToken', (err, result) => {
      accessToken.current = result;
      getData(result);
    });

    const unsubscribe = DeviceEventEmitter.addListener(
      'memberListRefresh',
      function (data) {
        getData(accessToken.current);
      },
    );

    return () => {
      unsubscribe.remove();
    };
  }, [props.teamData.id]);

  const debounceSearch = useCallback(
    debounce(recentKeyword => {
      getData(accessToken.current, recentKeyword);
    }, 250),
    [],
  );

  useEffect(() => {
    if (!input) {
      getData(accessToken.current, null);
      return;
    }
    debounceSearch(input);
  }, [input]);

  const handleMemberPageLoadMore = () => {
    if (memberPageLoading.current) {
      return;
    }
    if (
      memberPage.currentPage &&
      memberPage.totalPage <= memberPage.currentPage
    ) {
      return;
    }
    setProgressShown(true);
    memberPageLoading.current = true;
    api
      .get(
        `/app-team/teams/${props.teamData.id}/members?keyword=${
          input ? input : ''
        }&pageNo=${
          memberPage.currentPage ? parseInt(memberPage.currentPage, 10) + 1 : 1
        }&pageSize=20`,
        {
          headers: {
            Authorization: `Bearer ${accessToken.current}`,
          },
        },
      )
      .then(function (response) {
        response.data.items = [
          ...(memberPage.items ? memberPage.items : []),
          ...response.data.items,
        ];
        setMemberPage(response.data);
      })
      .catch(function (error) {
        console.log(error);
      })
      .finally(() => {
        setProgressShown(false);
        memberPageLoading.current = false;
      });
  };

  return (
    <View style={[pageStyle.container]}>
      <FlatList
        ListHeaderComponent={
          <View>
            <View style={[pageStyle.searchInputContainer]}>
              <TextInput
                style={[pageStyle.searchInput]}
                placeholder={'선수 검색'}
                placeholderTextColor={'#aaa'}
                value={input}
                onChangeText={value => setInput(value)}
              />
              <Image
                style={[pageStyle.searchIcon]}
                source={require('../../assets/images/icn_search.png')}
              />
            </View>
            <View style={[styles.row, pageStyle.tableHeader]}>
              <Text style={[pageStyle.th, {width: 38}]}>
                총 {memberPage.totalCount}명
              </Text>
              <Text style={[styles.col1dot5, pageStyle.th]} />
              <Text style={[styles.coldot8, pageStyle.th]}>게임수</Text>
              <Text style={[styles.col, pageStyle.th]}>등급</Text>
              <Text style={[styles.col1dot2, pageStyle.th]}>출신학교</Text>
              <Text style={[styles.col1dot2, pageStyle.th]}>가입일</Text>
              <Text style={[styles.col1dot2, pageStyle.th]}>생년월일</Text>
            </View>
          </View>
        }
        onEndReachedThreshold={1}
        onEndReached={handleMemberPageLoadMore}
        style={[pageStyle.list]}
        keyExtractor={item => `member_${item.id.toString()}`}
        data={memberPage.items}
        renderItem={({item}) => (
          <MemberInfoItem data={item} navigation={props.navigation} />
        )}
        ListEmptyComponent={() => (
          <View style={[styles.emptyContainer]}>
            <Image source={require('../../assets/images/logo2.png')} />
          </View>
        )}
      />
      {progressShown && (
        <View style={[styles.progressContainer]}>
          <Progress.Circle
            fill="transparent"
            size={70}
            borderWidth={3}
            indeterminate={true}
          />
        </View>
      )}
    </View>
  );
};

export default MemberList;

const pageStyle = StyleSheet.create({
  tableHeader: {
    marginTop: 19,
    marginHorizontal: 20,
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  searchInput: {
    marginTop: 11,
    borderWidth: 1,
    borderColor: '#ccc',
    borderRadius: 13,
    height: 30,
    fontSize: 12,
    padding: 0,
    paddingEnd: 4.5,
    color: 'black',
    textAlign: 'left',
    paddingStart: 35,
  },
  searchInputContainer: {
    flex: 2,
    position: 'relative',
    marginTop: 10,
    marginHorizontal: 23,
  },
  searchIcon: {
    position: 'absolute',
    top: 16,
    left: 8,
    width: 17,
    height: 17,
  },
  keyworkInput: {},
  th: {
    textAlign: 'center',
    fontSize: 10,
    fontWeight: '300',
    color: 'black',
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    height:
      Dimensions.get('window').height -
      (Platform.OS === 'ios' ? 170 : Platform.OS === 'android' ? 150 : 130),
    paddingBottom: Platform.OS === 'web' ? 0 : 20,
  },
});

import React, {useState, useRef, useEffect} from 'react';
import {
  SafeAreaView,
  StyleSheet,
  View,
  Dimensions,
  Modal,
  Text,
  Image,
  TouchableOpacity,
  FlatList,
  Platform,
} from 'react-native';
import SelectDropdown from 'react-native-select-dropdown';
import styles from '../../common/styles';
import GameMetaItem from '../GameMetaItem';
import AsyncStorage from '@react-native-async-storage/async-storage';
import api from '../../common/api';
import * as Progress from 'react-native-progress';
import moment from 'moment';
import {padStart} from 'lodash';

const GameList = props => {
  const [gamePage, setGamePage] = useState({});
  const [progressShown, setProgressShown] = useState(false);
  const [selectedYear, setSelectedYear] = useState();
  const [selectedMonth, setSelectedMonth] = useState();
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [yearList, setYearList] = useState([]);
  let accessToken = useRef();
  let loading = useRef();
  let yearRef = useRef();
  let monthRef = useRef();

  const getData = token => {
    if (loading.current) {
      return;
    }
    if (!token) {
      return;
    }
    if (!props.scheduleType) {
      return;
    }
    loading.current = true;
    setProgressShown(true);

    let requestUri = `/app-team/games?teamId=${props.teamData.id}&pageNo=1&pageSize=10`;

    if (props.scheduleType === 'PENDING') {
      requestUri += '&gameStatus=경기전&orderDir=ASC';
      if (!fromDate) {
        requestUri += `&fromDate=${moment().format('YYYY-MM-DD')}`;
      }
    } else if (props.scheduleType === 'COMPLETED') {
      if (!toDate) {
        requestUri += `&toDate=${moment()
          .add(-1, 'd')
          .format('YYYY-MM-DD')}&orderDir=DESC`;
      }
    }

    if (fromDate) {
      requestUri += `&fromDate=${fromDate}`;
    }
    if (toDate) {
      requestUri += `&toDate=${toDate}`;
    }

    console.log('requestUri', requestUri);
    api
      .get(requestUri, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(function (response) {
        setGamePage(response.data);
      })
      .catch(function (error) {
        console.log(error);
      })
      .finally(() => {
        loading.current = false;
        setProgressShown(false);
      });
  };

  const handleLoadMore = () => {
    if (loading.current) {
      return;
    }
    if (gamePage.currentPage && gamePage.totalPage <= gamePage.currentPage) {
      return;
    }
    if (!props.scheduleType) {
      return;
    }
    setProgressShown(true);
    loading.current = true;
    let requestUri = `/app-team/games?teamId=${props.teamData.id}&pageNo=${
      gamePage.currentPage ? parseInt(gamePage.currentPage, 10) + 1 : 1
    }&pageSize=10&orderDir=DESC`;
    if (props.scheduleType === 'PENDING') {
      requestUri += '&gameStatus=경기전';
      if (!fromDate) {
        requestUri += `&fromDate=${moment().format('YYYY-MM-DD')}`;
      }
    } else if (props.scheduleType === 'COMPLETED') {
      if (!toDate) {
        requestUri += `&toDate=${moment().add(-1, 'd').format('YYYY-MM-DD')}`;
      }
      requestUri += '&orderDir=DESC';
    }
    if (fromDate) {
      requestUri += `&fromDate=${fromDate}`;
    }
    if (toDate) {
      requestUri += `&toDate=${toDate}`;
    }
    api
      .get(requestUri, {
        headers: {
          Authorization: `Bearer ${accessToken.current}`,
        },
      })
      .then(function (response) {
        response.data.items = [
          ...(gamePage.items ? gamePage.items : []),
          ...response.data.items,
        ];
        setGamePage(response.data);
        loading.current = false;
      })
      .catch(function (error) {
        console.log(error);
      })
      .finally(() => {
        setProgressShown(false);
      });
  };

  useEffect(() => {
    if (!selectedYear) {
      setFromDate('');
      setToDate('');
      setSelectedMonth('');
      monthRef.current.reset();
    }
    if (selectedYear && !selectedMonth) {
      setFromDate(`${selectedYear}-01-01`);
      setToDate(
        moment(`${selectedYear}-01-01`, 'YYYY-MM-DD')
          .add(1, 'y')
          .format('YYYY-MM-DD'),
      );
    } else if (selectedYear && selectedMonth) {
      setFromDate(`${selectedYear}-${padStart(selectedMonth, 2, 0)}-01`);
      setToDate(
        moment(
          `${selectedYear}-${padStart(selectedMonth, 2, 0)}-01`,
          'YYYY-MM-DD',
        )
          .add(1, 'M')
          .format('YYYY-MM-DD'),
      );
    }
  }, [selectedYear, selectedMonth]);

  useEffect(() => {
    getData(accessToken.current);
  }, [toDate]);

  useEffect(() => {
    AsyncStorage.getItem('@accessToken', (err, result) => {
      accessToken.current = result;
      getData(result);
    });

    const candidateYearList = [{text: '전체', value: ''}];
    for (let i = 2022; i <= moment().add(1, 'Y').format('YYYY'); i++) {
      candidateYearList.push({text: i, value: i});
    }
    setYearList(candidateYearList);
  }, [props.teamData.id]);

  useEffect(() => {
    if (!props.scheduleType) {
      return;
    }
    setSelectedMonth('');
    setSelectedYear('');
    setFromDate('');
    const needGetData = !toDate;
    setToDate('');
    yearRef.current.reset();
    monthRef.current.reset();
    if (needGetData) {
      getData(accessToken.current);
    }
  }, [props.scheduleType]);

  return (
    <>
      <FlatList
        ListHeaderComponent={
          <View style={[styles.row, pageStyle.filter]}>
            <View style={[styles.col, pageStyle.dropDownItemContainer]}>
              <SelectDropdown
                ref={yearRef}
                defaultButtonText="년도"
                buttonStyle={pageStyle.dropDown}
                buttonTextStyle={pageStyle.downDownText}
                rowTextStyle={styles.dropDownRowText}
                renderDropdownIcon={() => (
                  <Image
                    source={require('../../assets/images/select_arr.png')}
                  />
                )}
                data={yearList}
                onSelect={(selectedItem, index) => {
                  setSelectedYear(selectedItem.value);
                }}
                buttonTextAfterSelection={(selectedItem, index) => {
                  return selectedItem.text;
                }}
                rowTextForSelection={(item, index) => {
                  return item.text;
                }}
              />
            </View>
            <View style={[styles.col, pageStyle.dropDownItemContainer]}>
              <SelectDropdown
                ref={monthRef}
                defaultButtonText="월"
                buttonStyle={pageStyle.dropDown}
                buttonTextStyle={pageStyle.downDownText}
                rowTextStyle={styles.dropDownRowText}
                renderDropdownIcon={() => (
                  <Image
                    source={require('../../assets/images/select_arr.png')}
                  />
                )}
                data={[
                  {text: '전체', value: ''},
                  {text: '1월', value: 1},
                  {text: '2월', value: 2},
                  {text: '3월', value: 3},
                  {text: '4월', value: 4},
                  {text: '5월', value: 5},
                  {text: '6월', value: 6},
                  {text: '7월', value: 7},
                  {text: '8월', value: 8},
                  {text: '9월', value: 9},
                  {text: '10월', value: 10},
                  {text: '11월', value: 11},
                  {text: '12월', value: 12},
                ]}
                onSelect={(selectedItem, index) => {
                  setSelectedMonth(selectedItem.value);
                }}
                buttonTextAfterSelection={(selectedItem, index) => {
                  return selectedItem.text;
                }}
                rowTextForSelection={(item, index) => {
                  return item.text;
                }}
              />
            </View>
          </View>
        }
        onEndReached={handleLoadMore}
        onEndReachedThreshold={1}
        data={gamePage.items}
        renderItem={({item, index}) => (
          <GameMetaItem
            data={item}
            index={index}
            myGrade={props.myGrade}
            navigation={props.navigation}
          />
        )}
        ListEmptyComponent={() => (
          <View style={[styles.emptyContainer]}>
            <Image source={require('../../assets/images/logo2.png')} />
          </View>
        )}
        ListFooterComponent={
          <View style={{height: Platform.OS === 'android' ? 50 : 0}} />
        }
      />
      {progressShown && (
        <View style={[styles.progressContainer]}>
          <Progress.Circle
            fill="transparent"
            size={70}
            borderWidth={3}
            indeterminate={true}
          />
        </View>
      )}
    </>
  );
};

export default GameList;

const pageStyle = StyleSheet.create({
  filter: {
    paddingStart: 78,
    paddingEnd: 78,
    marginTop: 17,
    marginBottom: 12,
  },
  dropdownRowContainer: {
    display: 'flex',
    width: 222,
    marginTop: 6,
    flexDirection: 'row',
  },
  dropDownItemContainer: {
    marginHorizontal: 3,
    flex: 1,
  },
  dropDown: {
    width: '100%',
    backgroundColor: 'white',
    borderWidth: 1,
    borderColor: 'rgb(61, 80, 183)',
    height: 30,
  },
  downDownText: {
    color: 'black',
    fontSize: 11,
  },
});

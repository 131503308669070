import React, {useState, useEffect, useRef, useMemo} from 'react';
import {
  Button,
  Image,
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  TextInput,
  Platform,
} from 'react-native';
import {SafeAreaView} from 'react-native-safe-area-context';
import api from '../common/api';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {
  login,
  getProfile as getKakaoProfile,
} from '@react-native-seoul/kakao-login';
import * as Google from 'expo-auth-session/providers/google';
import * as AppleAuthentication from 'expo-apple-authentication';
import {title} from '../common/util';
import * as Linking from 'expo-linking';
import * as WebBrowser from 'expo-web-browser';
import Colors from '../common/colors';

WebBrowser.maybeCompleteAuthSession();
const LoginView = ({navigation}) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const urlOrigin = useRef();
  const kakaoRestApiKey = '8e37331e98957b98cc7df980b8a63948';

  let googleAuthParams = {};
  // const redirectUrl = useMemo(() => {
  //   googleAuthParams.redirectUri = `${urlOrigin.current}/login`;
  //   console.log('redirectUri', googleAuthParams.redirectUri);
  //   return `${urlOrigin.current}/login`;
  // }, [urlOrigin.current]);

  let [request, response, promptAsync] = [];
  if (Platform.OS !== 'android') {
    googleAuthParams = {
      iosClientId:
        '1047066786377-6ntj62mjrv2lp7fm3d348mhdkovd9b0k.apps.googleusercontent.com',
      androidClientId:
        '1047066786377-el6u0e4hvl2pt84ru5t8tof4ssuscafi.apps.googleusercontent.com',
      webClientId:
        '1047066786377-0sj0vdr5164qes4pemogmfkunqnfje1v.apps.googleusercontent.com',
      redirectUri: 'https://unique-play.com/login',
    };

    [request, response, promptAsync] = Google.useAuthRequest(googleAuthParams);
  }

  if (Platform.OS === 'web') {
  }

  // 토큰을 이용하여 유저 정보를 가져오는 함수
  const getUserInfo = async token => {
    if (!token) return;
    try {
      const response = await fetch(
        'https://www.googleapis.com/oauth2/v3/userinfo',
        {
          headers: {Authorization: `Bearer ${token}`},
        },
      );
      const userInfo = await response.json();

      authOrSignUp(userInfo.sub, '구글', userInfo.email);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    console.log('response', response);
    if (response?.type === 'success') {
      getUserInfo(response.authentication?.accessToken);
    }
  }, [response]);

  useEffect(() => {
    if (Platform.OS === 'web') {
      Linking.getInitialURL().then(async url => {
        let domain = new URL(url);
        urlOrigin.current = domain.origin;
        const code = domain.searchParams.get('code');
        console.log('domain', domain);
        if (code) {
          const token = await login({
            restApiKeyWeb: kakaoRestApiKey,
            redirectUrlWeb: 'https://unique-play.com/login',
            codeWeb: code,
            clientSecret: '48aEIwJMWr7pU3GhNKvy6Dq3n1y0od6P',
          });
          console.log('kakaoToken', token);
          const profile = await getKakaoProfile(token.access_token);
          console.log('profile', profile);
          if (!profile.id) {
            alert('카카오 인증에 실패했습니다.');
            return;
          }
          authOrSignUp(profile.id, '카카오', profile.email);
        }
      });
    }
  }, []);

  const auth = body => {
    api
      .post('/users/authentications', body)
      .then(function (response) {
        console.log(response.data, response.headers);
        try {
          AsyncStorage.setItem(
            '@accessToken',
            response.headers['access-token'],
          );
          AsyncStorage.setItem(
            '@userInfo',
            JSON.stringify(response.data),
            () => {
              navigation.reset({
                index: 0,
                routes: [{name: 'Main'}],
              });
            },
          );
        } catch (e) {
          // saving error
        }
      })
      .catch(function (error) {
        console.log(error.response);
        if (error.response.status === 401) {
          alert('이메일 비밀번호가 일치하지 않습니다.');
        } else {
          alert('요청에 실패했습니다.');
        }
      });
  };

  const handleLogin = () => {
    if (!email || !password) {
      alert('필수 값을 입력해 주세요.');
      return;
    }
    auth({
      email: email,
      password: password,
      authType: '이메일',
    });
  };

  const authOrSignUp = (thirdPartyUserId, authType, email) => {
    console.log(thirdPartyUserId, authType, email);
    api
      .post('/users/exists/check', {
        thirdPartyUserId: thirdPartyUserId,
        authType: authType,
        email: email,
      })
      .then(function (response) {
        if (!response.data) {
          navigation.navigate('Certification', {
            userCode: 'imp66015233',
            data: {
              merchant_uid: `mid_${new Date().getTime()}`, // 주문번호
              company: '(주) 유니크플레이', // 회사명 또는 URL
            },
            callback: {
              screen: 'SignUp',
              data: {
                thirdPartyUserId: thirdPartyUserId,
                email: email,
                authType: authType,
              },
            },
          });
        } else {
          auth({
            thirdPartyUserId: thirdPartyUserId,
            authType: authType,
            email: email,
          });
        }
      })
      .catch(function (error) {
        console.log(error.response);
      });
  };

  const appleLogin = async () => {
    try {
      const credential = await AppleAuthentication.signInAsync({
        requestedScopes: [
          AppleAuthentication.AppleAuthenticationScope.FULL_NAME,
          AppleAuthentication.AppleAuthenticationScope.EMAIL,
        ],
      });
      console.log('credential', credential);
      authOrSignUp(credential.user, '애플', credential.email);
    } catch (e) {
      if (e.code === 'ERR_REQUEST_CANCELED') {
        // handle that the user canceled the sign-in flow
      } else {
        // handle other errors
        console.error(e);
        alert('애플 로그인 시도 중 문제가 발생했습니다.');
      }
    }
  };

  useEffect(() => {
    console.log('response', response);
  }, [response]);

  const googleLogin = async () => {
    if (Platform.OS !== 'android') {
      try {
        promptAsync();
      } catch (e) {
        console.error(e);
      }
    }
  };

  const kakaoLogin = async () => {
    if (Platform.OS !== 'web') {
      const token = await login();
      console.log('kakaoToken', token);
      const profile = await getKakaoProfile();
      console.log('profile', profile);
      authOrSignUp(profile.id, '카카오', profile.email);
    } else {
      const url = `https://kauth.kakao.com/oauth/authorize?client_id=${kakaoRestApiKey}&redirect_uri=${encodeURIComponent(
        'https://unique-play.com/login',
      )}&client_secret=${'48aEIwJMWr7pU3GhNKvy6Dq3n1y0od6P'}&grant_type=${'authorization_code'}&response_type=code`;
      Linking.openURL(url);
    }
  };

  const navigateToSignUpView = () => {
    // navigation.navigate('SignUp', {user: {authType: '이메일'}});
    navigation.navigate('Certification', {
      userCode: 'imp66015233',
      data: {
        merchant_uid: `mid_${new Date().getTime()}`, // 주문번호
        company: '(주) 유니크플레이', // 회사명 또는 URL
      },
      callback: {
        screen: 'SignUp',
        data: {
          authType: '이메일',
        },
      },
    });
  };

  const goToVideoRequestWebView = () => {
    Linking.openURL('https://www.unique-play.com/apply');
  };

  const handleGoToTour = () => {
    navigation.navigate('Main', {tourMode: true});
  };

  return (
    <SafeAreaView nativeID="common-page-container" style={pageStyle.container}>
      <Image
        style={pageStyle.logo}
        source={require('../assets/images/logo.png')}
      />
      <View style={pageStyle.formContainer}>
        <Text style={[pageStyle.subtitle]}>이메일</Text>
        <TextInput
          style={[pageStyle.input]}
          type="email"
          onChangeText={value => {
            setEmail(value);
          }}
          placeholder={'이메일'}
          placeholderTextColor={'#aaa'}
        />
        <Text style={[pageStyle.subtitle, pageStyle.mt16]}>비밀번호</Text>
        <TextInput
          style={[pageStyle.input]}
          secureTextEntry={true}
          placeholder={'비밀번호'}
          placeholderTextColor={'#aaa'}
          onChangeText={value => {
            setPassword(value);
          }}
          onSubmitEditing={handleLogin}
        />
        <TouchableOpacity style={[pageStyle.loginBtn]} onPress={handleLogin}>
          <Text style={[pageStyle.buttonTxt]}>로그인</Text>
        </TouchableOpacity>
        <TouchableOpacity
          onPress={kakaoLogin}
          style={[pageStyle.loginKakaoBtn]}>
          <View style={[pageStyle.btnIconContainer]}>
            <Image
              style={[pageStyle.btnIcon]}
              source={require('../assets/images/icn_kakao.png')}
            />
          </View>
          <Text style={[pageStyle.buttonTxt, pageStyle.kakaoLoginTxt]}>
            카카오로 로그인
          </Text>
        </TouchableOpacity>
        <TouchableOpacity style={[pageStyle.loginNaverBtn, {display: 'none'}]}>
          <View style={[pageStyle.btnIconContainer]}>
            <Image
              style={[pageStyle.btnNaverIcon]}
              source={require('../assets/images/icn_naver.png')}
            />
          </View>
          <Text style={[pageStyle.buttonTxt, pageStyle.naverLoginTxt]}>
            네이버로 로그인
          </Text>
        </TouchableOpacity>
        <TouchableOpacity
          onPress={() => googleLogin()}
          style={[pageStyle.loginGoogleBtn]}>
          <View style={[pageStyle.btnIconContainer]}>
            <Image
              style={[pageStyle.btnIcon]}
              source={require('../assets/images/icn_google.png')}
            />
          </View>
          <Text style={[pageStyle.buttonTxt, pageStyle.googleLoginTxt]}>
            구글로 로그인
          </Text>
        </TouchableOpacity>
        {Platform.OS === 'ios' && (
          <TouchableOpacity
            onPress={appleLogin}
            style={[pageStyle.loginAppleBtn]}>
            <View style={[pageStyle.btnIconContainer]}>
              <Image
                source={require('../assets/images/icon_apple.png')}
                style={[pageStyle.btnIcon]}
              />
            </View>
            <Text style={[pageStyle.buttonTxt, pageStyle.appleLoginTxt]}>
              {Platform.OS === 'ios' ? 'Apple로 로그인' : 'Apple'}
            </Text>
          </TouchableOpacity>
        )}
        <View style={[pageStyle.bottomContainer]}>
          <TouchableOpacity
            style={[pageStyle.bottomContainerItem]}
            onPress={navigateToSignUpView}>
            <Text style={[pageStyle.bottomContainerItemText]}>회원가입</Text>
          </TouchableOpacity>
          <Text style={[pageStyle.bottomContainerItemText]}>|</Text>
          <TouchableOpacity
            onPress={() => navigation.navigate('FindId')}
            style={[pageStyle.bottomContainerItem]}>
            <Text style={[pageStyle.bottomContainerItemText]}>아이디찾기</Text>
          </TouchableOpacity>
          <Text style={[pageStyle.bottomContainerItemText]}>|</Text>
          <TouchableOpacity
            onPress={() => navigation.navigate('FindPwd')}
            style={[pageStyle.bottomContainerItem]}>
            <Text style={[pageStyle.bottomContainerItemText]}>
              비밀번호찾기
            </Text>
          </TouchableOpacity>
        </View>
        <TouchableOpacity
          onPress={() => goToVideoRequestWebView()}
          style={[pageStyle.movieRequestButton]}>
          <Text style={[pageStyle.movieRequestButtonTxt]}>
            로그인 없이 영상신청 바로가기
          </Text>
          <Image
            source={require('../assets/images/icn_movie_request_wh.png')}
          />
        </TouchableOpacity>
        <TouchableOpacity
          onPress={handleGoToTour}
          style={[pageStyle.goToTourBtn]}>
          <Text style={[pageStyle.goToTourTxt]}>둘러보기</Text>
          <Image
            source={require('../assets/images/icn_movie_request_wh.png')}
          />
        </TouchableOpacity>
      </View>
    </SafeAreaView>
  );
};

const pageStyle = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'rgb(23, 53, 100)',
    alignItems: 'center',
    paddingHorizontal: 30,
    paddingVertical: 80,
  },
  logo: {
    width: 100,
    height: 75,
  },
  formContainer: {
    width: '100%',
    backgroundColor: 'white',
    marginTop: 40,
    borderRadius: 20,
    padding: 25,
  },
  subtitle: {
    fontSize: 13,
    color: 'black',
  },
  input: {
    fontSize: 14,
    borderBottomColor: '#909090',
    borderBottomWidth: 1,
    marginTop: 8,
    paddingBottom: 4,
  },
  mt16: {
    marginTop: 16,
  },
  loginBtn: {
    marginTop: 25,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 15,
    height: 35,
    backgroundColor: 'rgb(61, 80, 183)',
  },
  loginKakaoBtn: {
    marginTop: 25,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 15,
    height: 35,
    backgroundColor: 'rgb(255, 232, 0)',
  },
  loginNaverBtn: {
    marginTop: 9,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 15,
    height: 35,
    backgroundColor: 'rgb(24, 201, 0)',
  },
  loginGoogleBtn: {
    marginTop: 9,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 15,
    borderColor: '#a0a0a0',
    borderWidth: 1,
    height: 35,
    backgroundColor: 'white',
    position: 'relative',
  },
  loginAppleBtn: {
    marginTop: 9,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 15,
    borderColor: '#b3b3b3',
    borderWidth: 0,
    height: 35,
    backgroundColor: 'black',
    position: 'relative',
  },
  buttonTxt: {
    color: 'white',
    fontWeight: '500',
  },
  kakaoLoginTxt: {
    color: 'black',
  },
  naverLoginTxt: {
    color: 'white',
  },
  googleLoginTxt: {
    color: 'black',
  },
  appleLoginTxt: {
    color: 'white',
  },
  btnIconContainer: {
    width: '50%',
    height: '100%',
    position: 'absolute',
    left: 0,
  },
  btnIcon: {
    top: 6,
    left: 15,
    position: 'absolute',
    width: 20,
    height: 20,
  },
  btnNaverIcon: {
    top: 7.5,
    right: 48,
    position: 'absolute',
    width: 13.5,
    height: 13.5,
  },
  bottomContainer: {
    marginTop: 18,
    flexDirection: 'row',
    display: 'flex',
  },
  bottomContainerItem: {
    flex: 1,
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
  },
  bottomContainerItemText: {
    fontSize: 13,
    color: 'black',
  },
  movieRequestButton: {
    marginTop: 43,
    height: 31,
    color: 'white',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 15,
    backgroundColor: 'rgb(110, 136, 215)',
  },
  movieRequestButtonTxt: {
    color: 'white',
    marginEnd: 11,
  },
  goToTourBtn: {
    marginTop: 32,
    justifyContent: 'center',
    alignItems: 'center',
  },
  goToTourTxt: {
    fontSize: 12,
    color: Colors.main,
    fontWeight: 'bold',
    fontFamily: 'Pretendard-SemiBold',
  },
});

export default LoginView;

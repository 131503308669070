import React, {useState, useEffect, useRef, useMemo} from 'react';
import {
  Image,
  SafeAreaView,
  ScrollView,
  Text,
  TouchableOpacity,
  View,
  StyleSheet,
  FlatList,
  TextInput,
  Linking,
  DeviceEventEmitter,
  Dimensions,
  Platform,
  Modal,
} from 'react-native';
import styles from '../common/styles';
import {Shadow} from 'react-native-shadow-2';
import GameMetaItem from '../component/GameMetaItem';
import AsyncStorage from '@react-native-async-storage/async-storage';
import api from '../common/api';
import moment from 'moment';
import Colors from '../common/colors';
import RenderHTML from 'react-native-render-html';
import {useSafeAreaInsets} from 'react-native-safe-area-context';
import Carousel from 'react-native-reanimated-carousel';
import ScaledImage from '../component/ScaledImage';

const HomeView = ({navigation}) => {
  const windowWidth = Dimensions.get('window').width;
  const [thisYearData, setThisYearData] = useState({});
  const [userInfo, setUserInfo] = useState({});
  const [homeData, setHomeData] = useState({
    thisYearBattingRecord: {},
    thisYearPitchingRecord: {},
    recentGames: [],
  });
  const [banners, setBanners] = useState([]);

  const insets = useSafeAreaInsets();

  const [inReview, setInReview] = useState(false);
  const [alarmUnread, setAlarmUnread] = useState(false);

  const accessToken = useRef();
  const getData = token => {
    getHomeData(token);
    getUserInfo(token);
    getRecord(token);
    getBanner(token);
  };

  const getBanner = token => {
    if (!token) {
      return;
    }

    api
      .get(`/banners`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(function (response) {
        setBanners(response.data);
      })
      .catch(function (error) {
        console.log(error.response);
      });
  };

  const getRecord = token => {
    if (!token) {
      return;
    }

    api
      .get(`/games/records/by-user?seasonYear=${moment().year()}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(function (response) {
        if (response.data) {
          setThisYearData(response.data);
        } else {
          setThisYearData({
            hab: 0,
            havg: '.000',
            hr: 0,
            rbi: 0,
            sb: 0,
            ops: '0.000',
            pgame: 0,
            win: 0,
            lose: 0,
            era: '0.00',
            innings: 0,
            so: 0,
            whip: '0.00',
          });
        }
      })
      .catch(function (error) {
        console.log(error.response);
      });
  };
  const getHomeData = token => {
    api
      .get('/home', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(function (response) {
        console.log('success');
        setHomeData(response.data);
      })
      .catch(function (error) {
        console.log(error.response);
      });
  };

  const requestCertify = () => {
    /* 2. 본인인증 데이터 정의하기 */
    console.log('requestCertify in HomeView');
    const data = {
      merchant_uid: `mid_${new Date().getTime()}`, // 주문번호
      company: '(주) 유니크플레이', // 회사명 또는 URL
    };
    navigation?.navigate('Certification', {
      userCode: 'imp66015233',
      // userCode: 'iamport',
      data: data,
      accessToken: accessToken.current,
      callback: {
        screen: 'Main',
        data: {},
      },
    });
  };
  const getUserInfo = token => {
    api
      .get('/app-user/me', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(function (response) {
        setUserInfo(response.data);
        setAlarmUnread(response.data.alarmRead);
        if (!response.data.certificationId) {
          console.log('in HomeView');
          requestCertify();
        }
      })
      .catch(function (error) {
        console.log(error.response);
        if (error.response.status === 401) {
          alert('탈퇴처리된 계정입니다.');
          AsyncStorage.removeItem('@accessToken', () => {
            navigation.reset({
              index: 0,
              routes: [{name: 'Login'}],
            });
          });
        }
      });
  };

  useEffect(() => {
    AsyncStorage.getItem('@accessToken', (err, result) => {
      accessToken.current = result;
      getData(result);
      handleDeepLink();
    });

    const unsubscribe = DeviceEventEmitter.addListener(
      '@deepLinkUpdated',
      () => {
        handleDeepLink();
      },
    );

    return () => {
      unsubscribe.remove();
    };
  }, []);

  const handleDeepLink = () => {
    AsyncStorage.getItem('@deeplink', (err, result) => {
      if (!result) {
        return;
      }
      console.log(JSON.parse(result));
      const gameId = JSON.parse(result).gameId;
      const communityId = JSON.parse(result).communityId;
      const playerCardId = JSON.parse(result).playerCardId;
      if (gameId) {
        const time = JSON.parse(result).time;
        const cam = JSON.parse(result).cam;

        console.log(gameId, time, accessToken.current);

        AsyncStorage.removeItem('@deeplink', () => {
          api
            .get(`/games/${gameId}`, {
              headers: {
                Authorization: `Bearer ${accessToken.current}`,
              },
            })
            .then(function (response) {
              navigation.navigate('Video', {
                itemId: response.data.id,
                game: response.data,
                time: time,
                cam: cam,
              });
            })
            .catch(function (error) {
              console.error(error);
            });
        });
      } else if (communityId) {
        AsyncStorage.removeItem('@deeplink', () => {
          navigation.navigate('CommunityDetail', {
            itemId: communityId,
          });
        });
      } else if (playerCardId) {
        AsyncStorage.removeItem('@deeplink', () => {
          navigation.navigate('PlayerCardDetail', {
            itemId: playerCardId,
          });
        });
      }
    });
  };

  const [modalOpened, setModalOpened] = useState(false);

  const [notice, setNotice] = useState();

  const notExposeIds = useRef([]);

  const getTopNotice = () => {
    api
      .get('/app-board/top-expose-notice', {})
      .then(function (response) {
        setNotice(response.data);
        if (
          response.data &&
          !notExposeIds.current.includes(response.data?.id)
        ) {
          setModalOpened(true);
        }
      })
      .catch(function (error) {
        console.error(error.response);
      });
  };

  const checkAlarmUnRead = () => {
    if (!accessToken.current) {
      return;
    }
    api
      .get('/users/exists-unread-alarm', {
        headers: {
          Authorization: `Bearer ${accessToken.current}`,
        },
      })
      .then(function (response) {
        setAlarmUnread(response.data);
      })
      .catch(function (error) {
        console.error(error.response);
      });
  };

  const refreshTopExposeMain = () => {
    AsyncStorage.getItem('notExposeIds', (err, result) => {
      if (err) {
        console.error(err);
        return;
      }
      if (result) {
        notExposeIds.current = JSON.parse(result);
      }
      getTopNotice();
    });
  };

  useEffect(() => {
    refreshTopExposeMain();

    if (Platform.OS === 'ios') {
      api
        .get('/home/in-review')
        .then(function (response) {
          setInReview(response.data.inReview);
        })
        .catch(function (error) {
          console.error(error);
        });
    }
  }, []);

  const notExposeNoticeAnymore = async () => {
    if (!notice || !notice?.id) {
      return;
    }
    if (!notExposeIds.current.includes(notice?.id)) {
      notExposeIds.current.push(notice?.id);
      await AsyncStorage.setItem(
        'notExposeIds',
        JSON.stringify(notExposeIds.current),
      );
    }
    setModalOpened(false);
  };

  useEffect(() => {
    const unsubscribe = DeviceEventEmitter.addListener(
      'homeTabPress',
      function (data) {
        refreshTopExposeMain();
        checkAlarmUnRead();
      },
    );

    return () => {
      unsubscribe.remove();
    };
  }, []);

  const increateBannerHitCount = id => {
    api
      .post(
        `/banners/${id}/hit-count`,
        {},
        {
          headers: {
            Authorization: `Bearer ${accessToken.current}`,
          },
        },
      )
      .then(function (response) {})
      .catch(function (error) {
        console.error(error.response);
      });
  };

  const fullWidth = useMemo(() => {
    if (Platform.OS === 'web') {
      const windowWidth = Dimensions.get('window').width;
      if (windowWidth < 550) {
        return windowWidth;
      } else {
        return 550;
      }
    }
    return '100%';
  }, []);

  const [carouselHeight, setCarouselHeight] = useState(150);

  const pageStyle = StyleSheet.create({
    list: {
      paddingStart: 26,
      paddingEnd: 26,
      paddingTop: 10,
      paddingBottom: 50,
    },
    header: {
      marginTop: 8,
      paddingVertical: 15,
      paddingHorizontal: 33,
    },
    welcomeTxt: {
      color: '#3f51b5',
      fontFamily: 'Pretendard-Medium',
      fontWeight: '500',
      fontSize: 16,
    },
    username: {
      color: '#3f51b5',
      fontFamily: 'Pretendard-Bold',
      fontWeight: '700',
      fontSize: 18,
    },
    subtitle: {
      marginTop: 16,
      marginStart: 10,
      color: 'black',
      fontFamily: 'Pretendard-Bold',
      fontSize: 14,
      fontWeight: '700',
    },
    row: {
      flexDirection: 'row',
    },
    firstRow: {
      marginTop: 10,
    },
    col: {
      flex: 1,
      marginStart: -0.5,
    },
    th: {
      backgroundColor: '#3f51b5',
      padding: 4,
      lineHeight: 13,
      fontFamily: 'Pretendard-Bold',
      fontSize: 13,
      color: 'white',
      paddingVertical: 5,
      alignContent: 'center',
      alignItems: 'center',
      fontWeight: '700',
      textAlign: 'center',
    },
    td: {
      padding: 4,
      paddingVertical: 10,
      lineHeight: 13,
      fontFamily: 'Pretendard-Bold',
      fontSize: 13,
      color: 'black',
      fontWeight: '700',
      textAlign: 'center',
    },
    even: {
      backgroundColor: 'rgb(236, 236, 236)',
    },
    requestBtn: {
      width: 66,
      height: 22,
      borderWidth: 0.8,
      borderColor: '#1d3864',
      borderRadius: 12,
      justifyContent: 'center',
      alignItems: 'center',
      marginEnd: 14,
      textAlign: 'center',
    },
    requestBtnTxt: {
      // color: '#1d3864',
      fontFamily: 'Pretendard',
      fontSize: 12,
      lineHeight: 20,
    },
    popupContainer: {
      zIndex: 100,
      width: '100%',
      height: Dimensions.get('window').height,
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      justifyContent: 'flex-end',
      alignItems: 'center',
      alignContent: 'flex-start',
    },
    popupInnerContainer: {
      backgroundColor: 'rgb(35, 55, 97)',
      textAlign: 'flex-start',
      paddingTop: 28,
      alignItems: 'flex-start',
      width: fullWidth,
      borderTopLeftRadius: 18,
      borderTopRightRadius: 18,
    },
    popupTxt: {
      color: 'black',
      fontFamily: 'Pretendard',
      fontSize: 16,
    },
    popupTitle: {
      color: 'white',
      fontFamily: 'Pretendard-Bold',
      fontWeight: 'bold',
      fontSize: 16,
      marginBottom: 18,
      marginHorizontal: 24,
    },
    dateTxt: {
      color: 'white',
      marginStart: 8,
      fontFamily: 'Pretendard',
      fontSize: 14,
    },
    strong: {
      fontFamily: 'Pretendard-Bold',
      fontWeight: 'bold',
      color: Colors.main,
      textDecorationLine: 'underline',
    },
    btn: {
      width: 130,
      height: 34,
      backgroundColor: Colors.main,
      borderRadius: 17,
      alignItems: 'center',
      alignContent: 'center',
      justifyContent: 'center',
      marginTop: 65,
    },
    btnText: {
      color: 'white',
      fontSize: 18,
      fontWeight: 'bold',
      fontFamily: 'Pretendard-Bold',
    },
    detailTxt: {
      fontFamily: 'Pretendard-Bold',
      fontSize: 16,
      color: 'white',
      fontWeight: 'bold',
      textDecorationLine: 'underline',
      textDecorationStyle: 'solid',
      textDecorationColor: 'white',
      marginHorizontal: 24,
      marginBottom: 24,
    },
    popupBtn: {
      backgroundColor: 'white',
      paddingVertical: 12,
      alignItems: 'center',
      alignContent: 'center',
      justifyContent: 'center',
      marginTop: 5,
    },
    popupBtnTxt: {
      color: 'black',
      fontFamily: 'Pretendard-Bold',
      fontSize: 17,
      fontWeight: 'bold',
      textAlign: 'center',
    },
  });

  return (
    <>
      <SafeAreaView style={[styles.container]}>
        <Shadow
          offset={[0, 2]}
          containerStyle={[{marginTop: 0, width: '100%'}]}
          style={[{width: '100%'}]}
          distance={7}
          sides={{bottom: true, top: false, start: false, end: false}}>
          <View style={[styles.headerCommon]}>
            <View style={[styles.row, styles.verticalAlign]}>
              <TouchableOpacity
                onPress={() =>
                  Platform.OS === 'web' ? history.back() : navigation.goBack()
                }>
                <Image
                  source={require('../assets/images/icon_back.png')}
                  style={[styles.headerBackButton]}
                />
              </TouchableOpacity>
              <Text style={[styles.headerText]}>홈</Text>
            </View>
            <View style={[styles.row, styles.verticalAlign]}>
              {(Platform.OS !== 'ios' || !inReview) && (
                <TouchableOpacity
                  style={[pageStyle.requestBtn]}
                  onPress={() =>
                    Linking.openURL('https://www.unique-play.com/apply')
                  }>
                  <Text style={[pageStyle.requestBtnTxt]}>영상신청</Text>
                </TouchableOpacity>
              )}
              <TouchableOpacity
                style={[styles.alarmContainer]}
                onPress={() => navigation.navigate('MyAlarmList')}>
                <Image
                  resizeMode="contain"
                  style={[styles.imgAlarm]}
                  source={require('../assets/images/icn_alarm.png')}
                />
                {alarmUnread && <View style={[styles.alarmDot]} />}
              </TouchableOpacity>
            </View>
          </View>
        </Shadow>
        <View>
          <FlatList
            keyExtractor={item => `game_${item.id.toString()}`}
            data={homeData.recentGames}
            ListHeaderComponent={
              <View style={[{marginBottom: 8}]}>
                <View style={[pageStyle.header]}>
                  <Text style={[pageStyle.welcomeTxt]}>
                    <Text style={[pageStyle.username]}>{userInfo?.name}</Text>{' '}
                    님 환영합니다.
                  </Text>
                  <Text style={[pageStyle.subtitle]}>올해 기록</Text>
                  <View style={[pageStyle.row, pageStyle.firstRow]}>
                    <View style={[pageStyle.col]}>
                      <Text style={[pageStyle.th]}>타수</Text>
                      <Text style={[pageStyle.td]}>{thisYearData?.hab}</Text>
                    </View>
                    <View style={[pageStyle.col]}>
                      <Text style={[pageStyle.th]}>타율</Text>
                      <Text style={[pageStyle.td, pageStyle.even]}>
                        {thisYearData?.havg}
                      </Text>
                    </View>
                    <View style={[pageStyle.col]}>
                      <Text style={[pageStyle.th]}>홈런</Text>
                      <Text style={[pageStyle.td]}>{thisYearData?.hr}</Text>
                    </View>
                    <View style={[pageStyle.col]}>
                      <Text style={[pageStyle.th]}>타점</Text>
                      <Text style={[pageStyle.td, pageStyle.even]}>
                        {thisYearData?.rbi}
                      </Text>
                    </View>
                    <View style={[pageStyle.col]}>
                      <Text style={[pageStyle.th]}>도루</Text>
                      <Text style={[pageStyle.td]}>{thisYearData?.sb}</Text>
                    </View>
                    <View style={[pageStyle.col]}>
                      <Text style={[pageStyle.th]}>OPS</Text>
                      <Text style={[pageStyle.td, pageStyle.even]}>
                        {thisYearData?.ops}
                      </Text>
                    </View>
                  </View>
                  <View style={[pageStyle.row]}>
                    <View style={[pageStyle.col]}>
                      <Text style={[pageStyle.th]}>경기</Text>
                      <Text style={[pageStyle.td]}>{thisYearData?.pgame}</Text>
                    </View>
                    <View style={[pageStyle.col]}>
                      <Text style={[pageStyle.th]}>승-패</Text>
                      <Text style={[pageStyle.td, pageStyle.even]}>
                        {thisYearData?.win}-{thisYearData?.lose}
                      </Text>
                    </View>
                    <View style={[pageStyle.col]}>
                      <Text style={[pageStyle.th]} numberOfLines={1}>
                        ERA
                      </Text>
                      <Text style={[pageStyle.td]}>{thisYearData?.era}</Text>
                    </View>
                    <View style={[pageStyle.col]}>
                      <Text style={[pageStyle.th]}>이닝</Text>
                      <Text style={[pageStyle.td, pageStyle.even]}>
                        {thisYearData?.innings}
                      </Text>
                    </View>
                    <View style={[pageStyle.col]}>
                      <Text style={[pageStyle.th]}>탈삼진</Text>
                      <Text style={[pageStyle.td]}>{thisYearData?.so}</Text>
                    </View>
                    <View style={[pageStyle.col]}>
                      <Text style={[pageStyle.th]}>WHIP</Text>
                      <Text style={[pageStyle.td, pageStyle.even]}>
                        {thisYearData?.whip}
                      </Text>
                    </View>
                  </View>
                </View>
                {banners && banners.length > 0 && (
                  <Carousel
                    loop
                    width={windowWidth}
                    height={carouselHeight}
                    autoPlayInterval={4000}
                    autoPlay={banners?.length > 1}
                    data={banners}
                    scrollAnimationDuration={1000}
                    pagingEnabled={true}
                    // onSnapToItem={(index) => console.log('current index:', index)}
                    renderItem={({index}) => (
                      <TouchableOpacity
                        onPress={() => {
                          if (!banners[index].linkUrl) {
                            return;
                          }
                          increateBannerHitCount(banners[index].id);
                          Linking.openURL(banners[index].linkUrl);
                        }}
                        style={{
                          flex: 1,
                          justifyContent: 'center',
                        }}>
                        <ScaledImage
                          width={
                            Platform.OS === 'web'
                              ? fullWidth
                              : Dimensions.get('window').width
                          }
                          setHeight={setCarouselHeight}
                          uri={banners[index].imageUrl}
                        />
                      </TouchableOpacity>
                    )}
                  />
                )}
                <Text style={[pageStyle.subtitle]}>최근 3경기</Text>
              </View>
            }
            renderItem={({item, index}) => (
              <GameMetaItem
                data={item}
                key={`game_index_${index}`}
                index={index}
                navigation={navigation}
              />
            )}
            ListEmptyComponent={() => (
              <View style={[styles.emptyContainer]}>
                <Image source={require('../assets/images/logo2.png')} />
              </View>
            )}
            ListFooterComponent={() => <View style={[{height: 100}]} />}
          />
        </View>
        {modalOpened && notice && (
          <Modal transparent={true} overFullScreen={true}>
            <View style={[pageStyle.popupContainer]}>
              <View style={[pageStyle.popupInnerContainer]}>
                <Text style={[pageStyle.popupTitle]}>
                  {notice?.title}{' '}
                  <Text style={[pageStyle.dateTxt]}>
                    ({moment(notice?.createdAt).format('YY.MM.DD')})
                  </Text>
                </Text>
                <View style={{marginHorizontal: 24}}>
                  <RenderHTML
                    tagsStyles={contentHtmlStyles}
                    contentWidth={Dimensions.get('window').width - 24 * 2}
                    source={{html: notice?.shortContents}}
                  />
                </View>
                <TouchableOpacity
                  style={[{marginTop: 24}]}
                  onPress={() => {
                    setModalOpened(false);
                    navigation.navigate('Notice', {id: notice?.id});
                  }}>
                  <Text
                    style={[
                      pageStyle.detailTxt,
                      {textDecorationLine: 'underline'},
                    ]}>
                    자세히보기
                  </Text>
                </TouchableOpacity>
                <View
                  style={[
                    {
                      flexDirection: 'row',
                      paddingBottom: insets.bottom,
                      backgroundColor: 'white',
                      width: '100%',
                      height: 85,
                      alignContent: 'center',
                      alignItems: 'center',
                    },
                  ]}>
                  <View style={{flex: 1, justifyContent: 'center'}}>
                    <TouchableOpacity
                      onPress={notExposeNoticeAnymore}
                      style={[pageStyle.popupBtn]}>
                      <Text style={[pageStyle.popupBtnTxt]}>다시보지 않기</Text>
                    </TouchableOpacity>
                  </View>
                  <View style={{flex: 1}}>
                    <TouchableOpacity
                      onPress={() => setModalOpened(false)}
                      style={[pageStyle.popupBtn]}>
                      <Text style={[pageStyle.popupBtnTxt]}>닫기</Text>
                    </TouchableOpacity>
                  </View>
                </View>
              </View>
            </View>
          </Modal>
        )}
      </SafeAreaView>
    </>
  );
};

export default HomeView;

const contentHtmlStyles = StyleSheet.create({
  table: {
    borderTopWidth: 1,
    borderLeftWidth: 1,
    borderColor: '#ccc',
    marginBottom: 7,
  },
  tr: {
    borderBottomWidth: 1,
    borderColor: '#ccc',
  },
  td: {
    borderRightWidth: 1,
    borderColor: '#ccc',
    padding: 5,
  },
});

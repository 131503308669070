import {useState} from 'react';
import React, {
  Image,
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
} from 'react-native';
import Colors from '../../common/colors';
import styles from '../../common/styles';
import moment from 'moment';
import 'moment/locale/ko';

const MemberInfoItem = props => {
  return (
    <TouchableOpacity
      onPress={() =>
        props.navigation.navigate('MemberPage', {
          item: props.data.user,
          itemId: props.data.user.uid,
        })
      }
      style={[pageStyle.itemContainer]}>
      <View style={[{paddingEnd: 8}]}>
        <Image
          style={[
            {width: 30, height: 30, resizeMode: 'cover', borderRadius: 15},
          ]}
          source={
            props.data.user.profileImageUrl
              ? {uri: props.data.user.profileImageUrl}
              : require('../../assets/images/icn_profile_img.png')
          }
        />
      </View>
      <View style={[pageStyle.td, styles.col1dot5, pageStyle.name]}>
        <Text style={[pageStyle.label]} numberOfLines={1}>
          {props.data.user.name} ({props.data.number ? props.data.number : '-'})
        </Text>
      </View>
      <View style={[pageStyle.td, styles.coldot8]}>
        <Text style={[pageStyle.label]}>{props.data.totalGames}</Text>
      </View>
      <View style={[pageStyle.td, styles.col]}>
        <Text style={[pageStyle.label, {textAlign: 'center'}]}>
          {props.data.grade}
        </Text>
      </View>
      <View style={[pageStyle.td, styles.col1dot2]}>
        <Text style={[pageStyle.label]} numberOfLines={1}>
          {props.data.user.school}
        </Text>
      </View>
      <View style={[pageStyle.td, styles.col1dot2]}>
        <Text style={[pageStyle.label]} numberOfLines={1}>
          {props.data.registeredInTeamDate
            ? moment(props.data.registeredInTeamDate, 'YYYY-MM-DD').format(
                'YY.MM.DD',
              )
            : '-'}
        </Text>
      </View>
      <View style={[pageStyle.td, styles.col1dot2]}>
        <Text style={[pageStyle.label]} numberOfLines={1}>
          {!props.data.user.hideBirth
            ? moment(props.data.user.birth, 'YYYY-MM-DD').format('YY.MM.DD')
            : '비공개'}
        </Text>
      </View>
    </TouchableOpacity>
  );
};

export default MemberInfoItem;
const pageStyle = StyleSheet.create({
  itemContainer: {
    display: 'flex',
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: 'rgba(0, 0, 0, 0.15)',
    paddingVertical: 12,
    marginHorizontal: 24,
  },
  name: {
    alignItems: 'flex-start',
    justifyContent: 'center',
  },
  label: {
    color: 'black',
    fontSize: 12,
    fontWeight: '300',
  },
  td: {
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

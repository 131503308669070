import React, {useState, useEffect, useRef} from 'react';
import {
  FlatList,
  SafeAreaView,
  Text,
  TouchableOpacity,
  View,
  StyleSheet,
  ImageBackground,
  Dimensions,
  Image,
} from 'react-native';
import GameMetaItem from '../GameMetaItem';
import Colors from '../../common/colors';
import styles from '../../common/styles';
import SelectDropdown from 'react-native-select-dropdown';
import AsyncStorage from '@react-native-async-storage/async-storage';
import api from '../../common/api';
import moment from 'moment';
import * as Progress from 'react-native-progress';
const GameScheduleList = ({item, navigation, scheduleType}) => {
  const [gamePage, setGamePage] = useState({items: []});
  const [seasonId, setSeasonId] = useState();
  const [subLeagueId, setSubLeagueId] = useState();
  const [teamId, setTeamId] = useState();
  const [seasonList, setSeasonList] = useState();
  const [subLeagueList, setSubLeagueList] = useState();
  const [teamPage, setTeamPage] = useState();
  const [teamOptionList, setTeamOptionList] = useState([]);
  const [progressShown, setProgressShown] = useState(false);

  const accessToken = useRef();
  let teamDropDownRef = useRef();
  let selectedRegion = useRef();

  let gamePageLoading = useRef();

  useEffect(() => {
    AsyncStorage.getItem('@accessToken', (err, result) => {
      accessToken.current = result;
      getData(result);
      getTeams(result);
      getGameData(result);
      console.log('width', Dimensions.get('window').width);
    });
  }, [item.id]);

  useEffect(() => {
    getSubLeagues(seasonId);
  }, [seasonId]);

  useEffect(() => {
    getGameData(accessToken.current);
  }, [seasonId]);

  useEffect(() => {
    setTeamId('');
    getGameData(accessToken.current);
    getTeams();
    teamDropDownRef.current.reset();
  }, [subLeagueId]);

  useEffect(() => {
    getGameData(accessToken.current, teamId);
  }, [teamId]);

  useEffect(() => {
    getGameData(accessToken.current);
  }, [scheduleType]);

  const getData = token => {
    api
      .get(`leagues/${item.id}/seasons`, {
        headers: {
          Authorization: `Bearer ${accessToken.current}`,
        },
      })
      .then(function (response) {
        setSeasonList([{id: ''}, ...response.data]);
      })
      .catch(function (error) {
        console.log(error.response);
        alert('요청에 실패했습니다.');
      });
  };
  const getSubLeagues = seasonId => {
    if (!accessToken.current) {
      return;
    }
    if (!seasonId) {
      return;
    }
    api
      .get(`sub-leagues?seasonId=${seasonId}`, {
        headers: {
          Authorization: `Bearer ${accessToken.current}`,
        },
      })
      .then(function (response) {
        setSubLeagueList([{id: '', name: '전체 조'}, ...response.data]);
      })
      .catch(function (error) {
        console.log(error.response);
        alert('요청에 실패했습니다.');
      });
  };
  const getTeams = () => {
    if (!accessToken.current) {
      return;
    }
    api
      .get(
        `app-team/filter?leagueId=${item.id}&seasonId=${
          seasonId ? seasonId : ''
        }&subLeagueId=${subLeagueId ? subLeagueId : ''}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken.current}`,
          },
        },
      )
      .then(function (response) {
        setTeamPage(response.data);
        setTeamOptionList([{id: '', name: '전체 팀'}, ...response.data?.items]);
      })
      .catch(function (error) {
        console.log(error.response);
        alert('요청에 실패했습니다.');
      });
  };

  const getGameData = (token, teamId) => {
    if (!token) {
      return;
    }
    if (!scheduleType) {
      return;
    }
    callGamePage(token, false, teamId);
  };

  const callGamePage = (token, loadMore, teamId) => {
    if (gamePageLoading.current) {
      return;
    }
    if (
      loadMore &&
      gamePage.currentPage &&
      gamePage.totalPage <= gamePage.currentPage
    ) {
      return;
    }
    if (!scheduleType) {
      return;
    }
    gamePageLoading.current = true;

    setProgressShown(true);
    let requestUri = `games?seasonId=${seasonId ? seasonId : ''}&leagueId=${
      item.id
    }&regionId=${
      selectedRegion.value && selectedRegion.value.id
        ? selectedRegion.value.id
        : ''
    }&pageNo=${
      gamePage.currentPage && loadMore
        ? parseInt(gamePage.currentPage, 10) + 1
        : 1
    }&pageSize=8&customOrderBy=LEAGUE_GAME`;
    if (subLeagueId) {
      requestUri += `&subLeagueId=${subLeagueId}`;
    }
    if (teamId) {
      requestUri += `&teamId=${teamId}`;
    }
    // if (firstCall) {
    //   requestUri +=
    //     '&fromDate=' + moment().startOf('month').format('YYYY-MM-DD');
    //   requestUri += '&fromDate=' + moment().endOf('month').format('YYYY-MM-DD');
    // }
    if (scheduleType === 'PLAYOFF') {
      requestUri += '&playoff=true';
    } else if (scheduleType === 'PENDING') {
      requestUri += '&gameStatus=경기전';
      requestUri += `&fromDate=${moment().format('YYYY-MM-DD')}`;
    } else if (scheduleType === 'COMPLETED') {
      requestUri += `&toDate=${moment().add(-1, 'd').format('YYYY-MM-DD')}`;
    }
    console.log('requestUri', requestUri);
    api
      .get(requestUri, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(function (response) {
        response.data.items = [
          ...(gamePage.items && loadMore ? gamePage.items : []),
          ...response.data.items,
        ];
        setGamePage(response.data);
      })
      .catch(function (error) {
        console.log(error);
      })
      .finally(() => {
        setProgressShown(false);
        gamePageLoading.current = false;
      });
  };
  return (
    <View>
      <View style={[pageStyle.dropdownRowContainer]}>
        <View style={[pageStyle.dropDownItemContainer]}>
          <SelectDropdown
            defaultButtonText="전체 시즌"
            buttonStyle={pageStyle.dropDown}
            buttonTextStyle={pageStyle.downDownText}
            rowTextStyle={styles.dropDownRowText}
            renderDropdownIcon={() => (
              <Image source={require('../../assets/images/select_arr.png')} />
            )}
            data={seasonList}
            onSelect={(selectedItem, index) => {
              setSeasonId(selectedItem.id);
            }}
            buttonTextAfterSelection={(selectedItem, index) => {
              if (!selectedItem.id) {
                return '전체 시즌';
              }
              return `${selectedItem.year}년`;
            }}
            rowTextForSelection={(item, index) => {
              if (!item.id) {
                return '전체 시즌';
              }
              return `${item.year}년`;
            }}
          />
        </View>
        <View style={[pageStyle.dropDownItemContainer]}>
          <SelectDropdown
            defaultButtonText="조별일정확인"
            buttonStyle={pageStyle.dropDown}
            buttonTextStyle={pageStyle.downDownText}
            rowTextStyle={styles.dropDownRowText}
            renderDropdownIcon={() => (
              <Image source={require('../../assets/images/select_arr.png')} />
            )}
            data={subLeagueList}
            onSelect={(selectedItem, index) => {
              setSubLeagueId(selectedItem.id);
            }}
            buttonTextAfterSelection={(selectedItem, index) => {
              return `${selectedItem.name}`;
            }}
            rowTextForSelection={(item, index) => {
              return `${item.name}`;
            }}
          />
        </View>
      </View>
      <View style={[pageStyle.dropdownRowContainer, {paddingBottom: 8}]}>
        <View style={[pageStyle.dropDownItemContainer]}>
          <SelectDropdown
            ref={teamDropDownRef}
            defaultButtonText="팀별일정확인"
            buttonStyle={pageStyle.dropDown}
            buttonTextStyle={pageStyle.downDownText}
            rowTextStyle={styles.dropDownRowText}
            renderDropdownIcon={() => (
              <Image source={require('../../assets/images/select_arr.png')} />
            )}
            data={teamOptionList}
            onSelect={(selectedItem, index) => {
              setTeamId(selectedItem.id);
            }}
            buttonTextAfterSelection={(selectedItem, index) => {
              return `${selectedItem.name}`;
            }}
            rowTextForSelection={(item, index) => {
              return `${item.name}`;
            }}
          />
        </View>
      </View>
      <View style={[styles.row]} />
      <FlatList
        style={[pageStyle.list]}
        ListHeaderComponent={
          <View style={[pageStyle.dateHeader]}>
            {/* <Text style={[pageStyle.dateTxt]}>2022.08.08 (월)</Text> */}
          </View>
        }
        ListFooterComponent={() => <View style={[{height: 80}]} />}
        onEndReachedThreshold={1}
        onEndReached={() => callGamePage(accessToken.current, true, teamId)}
        keyExtractor={item => `game_${item.id.toString()}`}
        data={gamePage.items}
        renderItem={({item, index}) => (
          <GameMetaItem data={item} index={index} navigation={navigation} />
        )}
        ListEmptyComponent={() => (
          <View style={[styles.emptyContainer]}>
            <Image source={require('../../assets/images/logo2.png')} />
          </View>
        )}
      />
      {progressShown && (
        <View style={[styles.progressContainer]}>
          <Progress.Circle
            fill="transparent"
            size={70}
            borderWidth={3}
            indeterminate={true}
          />
        </View>
      )}
    </View>
  );
};

export default GameScheduleList;

const pageStyle = StyleSheet.create({
  headerCommon: {},
  list: {
    height: Dimensions.get('window').height - 200,
  },
  dropdownRowContainer: {
    display: 'flex',
    marginTop: 6,
    flexDirection: 'row',
    marginHorizontal: 23,
  },
  dropDownItemContainer: {
    marginHorizontal: 3,
    flex: 1,
  },
  dropDown: {
    width: '100%',
    backgroundColor: 'white',
    borderWidth: 1,
    borderColor: 'rgb(61, 80, 183)',
    height: 30,
  },
  downDownText: {
    color: 'black',
    fontSize: 11,
  },
  dateHeader: {
    marginTop: 3,
    paddingVertical: 4,
  },
  dateTxt: {
    color: '#444',
    fontSize: 10,
    textAlign: 'center',
  },
  communityMenuContainer: {
    position: 'absolute',
    top: 45,
    width: '100%',
  },
  communityItemMenuContainer: {
    backgroundColor: Colors.main,
    opacity: 0.9,
    paddingVertical: 12,
    borderTopWidth: 1,
    borderTopColor: 'rgba(255, 255, 255, 0.4)',
  },
  communityItemMenuTxt: {
    color: 'white',
    fontSize: 14,
    textAlign: 'center',
  },
});

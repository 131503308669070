import React, {useEffect, useState, useRef, useMemo} from 'react';
import {
  Image,
  StyleSheet,
  Text,
  View,
  TextInput,
  TouchableOpacity,
  Linking,
} from 'react-native';
import Colors from '../../common/colors';
import styles from '../../common/styles';
import SelectDropdown from 'react-native-select-dropdown';
import moment from 'moment';

const MemberItem = props => {
  const memberId = useRef(props.data.id);
  const [memberGrade, setMemberGrade] = useState(props.data.grade);
  const [backNumber, setBackNumber] = useState(
    props.data.number ? String(props.data.number) : '',
  );
  const memberOptions = useMemo(() => {
    if (props.myGrade !== '팀마스터') {
      return ['구단주', '감독', '총무', '단장', '코치', '선수'];
    }
    return ['팀마스터', '구단주', '감독', '총무', '단장', '코치', '선수'];
  });
  return (
    <View style={[pageStyle.itemContainer]}>
      <View style={[pageStyle.col]}>
        <Text style={[pageStyle.label]}>{props.data.user.name}</Text>
      </View>
      <View style={[pageStyle.col]}>
        <Text style={[pageStyle.label]}>{props.data.user.gender}</Text>
      </View>
      <View style={[pageStyle.col]}>
        <Text style={[pageStyle.label]}>
          {props.data.user.hideBirth
            ? '비공개'
            : moment().diff(moment(props.data.user.birth, 'YYYY-MM-DD'), 'y')}
        </Text>
      </View>
      <View style={[pageStyle.col2]}>
        <SelectDropdown
          defaultButtonText="선택"
          defaultValue={memberGrade}
          buttonStyle={pageStyle.dropDown}
          buttonTextStyle={pageStyle.downDownText}
          rowTextStyle={styles.dropDownRowText}
          renderDropdownIcon={() => (
            <Image source={require('../../assets/images/select_arr.png')} />
          )}
          data={memberOptions}
          onSelect={(selectedItem, index) => {
            setMemberGrade(selectedItem);
            props.memberDataChange({
              id: memberId.current,
              number: backNumber,
              grade: selectedItem,
            });
          }}
          buttonTextAfterSelection={(selectedItem, index) => {
            return selectedItem;
          }}
          rowTextForSelection={(item, index) => {
            return item;
          }}
        />
      </View>
      <View style={[pageStyle.col]}>
        <TextInput
          style={[pageStyle.input]}
          keyboardType="number-pad"
          value={backNumber}
          maxLength={4}
          placeholder={'입력'}
          placeholderTextColor={'#aaa'}
          onChangeText={value => {
            setBackNumber(value.replace(/[^0-9]/g, ''));
            props.memberDataChange({
              id: memberId.current,
              number: value.replace(/[^0-9]/g, ''),
              grade: memberGrade,
            });
          }}
        />
      </View>
      <View style={[pageStyle.col]}>
        <TouchableOpacity
          onPress={() => {
            if (props.data.user.hideContact) {
              alert('비공개 회원입니다.');
              return;
            }
            Linking.openURL(`tel:${props.data.user.cellphone}`);
          }}>
          <Image
            style={{width: 21, height: 21}}
            source={require('../../assets/images/icn_phone.png')}
          />
        </TouchableOpacity>
      </View>
    </View>
  );
};
export default MemberItem;
const pageStyle = StyleSheet.create({
  itemContainer: {
    display: 'flex',
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: 'rgba(0, 0, 0, 0.15)',
    paddingVertical: 12,
  },
  col: {
    flex: 1,
    paddingHorizontal: 4,
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
  },
  col2: {
    flex: 2,
    paddingHorizontal: 4,
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
  },
  label: {
    color: 'black',
    fontSize: 12,
    fontWeight: '300',
  },
  input: {
    padding: 0,
    color: 'black',
    width: '100%',
    height: 30,
    borderWidth: 1,
    borderColor: Colors.main,
    textAlign: 'center',
  },
  dropdownRowContainer: {
    display: 'flex',
    width: 222,
    marginTop: 6,
    flexDirection: 'row',
  },
  dropDownItemContainer: {
    marginHorizontal: 3,
    flex: 1,
  },
  dropDown: {
    width: '100%',
    backgroundColor: 'white',
    borderWidth: 1,
    borderColor: 'rgb(61, 80, 183)',
    height: 30,
  },
  downDownText: {
    color: 'black',
    fontSize: 11,
  },
});

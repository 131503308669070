import React, {useState, useEffect, useRef} from 'react';
import {
  Image,
  SafeAreaView,
  Text,
  TouchableOpacity,
  View,
  StyleSheet,
  ImageBackground,
  Dimensions,
  Linking,
  Platform,
} from 'react-native';
import styles from '../common/styles';
import {Shadow} from 'react-native-shadow-2';
import {title} from '../common/util';

const CustomerService = ({navigation, route}) => {
  return (
    <SafeAreaView
      nativeID="common-page-container"
      style={[styles.container, pageStyle.container]}>
      <Shadow
        offset={[0, 2]}
        style={[{width: '100%'}]}
        containerStyle={[{marginTop: 0}]}
        distance={7}
        sides={{bottom: true, top: false, start: false, end: false}}>
        <View style={[styles.headerCommon]}>
          <View style={[styles.row, styles.verticalAlign]}>
            <TouchableOpacity
              onPress={() =>
                Platform.OS === 'web' ? history.back() : navigation.goBack()
              }>
              <Image
                source={require('../assets/images/icon_back.png')}
                style={[styles.headerBackButton]}
              />
            </TouchableOpacity>
            <Text style={[styles.headerText]}>고객센터</Text>
          </View>
          <View style={[styles.row, styles.verticalAlign]} />
        </View>
      </Shadow>
      <View style={[pageStyle.innerContainer]}>
        <View>
          <TouchableOpacity
            style={[pageStyle.kakaoRow]}
            onPress={() => Linking.openURL('http://pf.kakao.com/_usYyT')}>
            <Image
              resizeMode="contain"
              style={[pageStyle.kakaoBtn]}
              source={require('../assets/images/btn_kakao_chat.png')}
            />
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => Linking.openURL('https://unique-play.com/terms')}
            style={[pageStyle.row]}>
            <Text style={[pageStyle.txt]}>이용약관</Text>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => Linking.openURL('https://unique-play.com/terms')}
            style={[pageStyle.row]}>
            <Text style={[pageStyle.txt]}>위치기반서비스 이용약관</Text>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => Linking.openURL('https://unique-play.com/privacy')}
            style={[pageStyle.row]}>
            <Text style={[pageStyle.txt]}>개인정보처리방침</Text>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() =>
              Linking.openURL(
                'https://uniqueplay-service.s3.ap-northeast-2.amazonaws.com/community_operation_terms_231014.pdf',
              )
            }
            style={[pageStyle.row]}>
            <Text style={[pageStyle.txt]}>커뮤니티 서비스 이용약관</Text>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => Linking.openURL('https://unique-play.com/terms')}
            style={[pageStyle.row]}>
            <Text style={[pageStyle.txt]}>청소년보호정책</Text>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => Linking.openURL('https://unique-play.com/terms')}
            style={[pageStyle.row]}>
            <Text style={[pageStyle.txt]}>맞춤형 광고 안내</Text>
          </TouchableOpacity>
        </View>
        <View style={[pageStyle.footer]}>
          <Text style={[pageStyle.logoTxt]}>(주)유니크플레이</Text>
          <Text style={[pageStyle.footerTxt]}>
            대표: 최성일 &nbsp;&nbsp;&nbsp;&nbsp;사업자등록번호: 661-88-02887
          </Text>
          <Text style={[pageStyle.footerTxt]}>
            주소: 서울특별시 종로구 새문안로3길 36 용비어천가 1336호
          </Text>
        </View>
      </View>
    </SafeAreaView>
  );
};

const pageStyle = StyleSheet.create({
  headerCommon: {},
  headerText: {color: 'white'},
  manageTeamBtn: {
    width: 60,
    height: 22,
    borderRadius: 12,
    borderWidth: 1,
    borderColor: 'white',
    backgroundColor: 'transparent',
    justifyContent: 'center',
    alignItems: 'center',
    marginEnd: 14,
  },
  container: {
    height: '100%',
    paddingBottom: Platform.OS === 'web' ? 40 : 80,
    justifyContent: 'space-between',
  },
  innerContainer: {
    flex: 1,
    justifyContent: 'space-between',
  },
  kakaoRow: {
    borderBottomColor: '#e6e6e6',
    borderBottomWidth: 1,
  },
  kakaoBtn: {
    width: 300,
    height: 100,
    alignSelf: 'center',
    marginVertical: 24,
  },
  row: {
    flexDirection: 'row',
    alignContent: 'center',
    alignItems: 'center',
    borderBottomColor: '#e6e6e6',
    borderBottomWidth: 1,
    paddingVertical: 20,
    paddingHorizontal: 36,
  },
  txt: {
    fontFamily: 'Pretendard',
    fontSize: 18,
    color: 'black',
  },
  footer: {
    paddingHorizontal: 36,
  },
  footerTxt: {
    color: '#7c7c7c',
    fontFamily: 'Pretendard',
    fontSize: 12,
    marginTop: 2,
  },
  logoTxt: {
    fontFamily: 'Pretendard-Bold',
    fontWeight: 'bold',
    color: 'black',
    fontSize: 13,
    marginBottom: 6,
  },
});

export default CustomerService;

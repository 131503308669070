import React, {useState, useEffect, useRef} from 'react';
import {
  FlatList,
  Text,
  View,
  StyleSheet,
  Dimensions,
  Image,
  Platform,
} from 'react-native';
import GameMetaItem from './GameMetaItem';
import styles from '../common/styles';
import SelectDropdown from 'react-native-select-dropdown';
import AsyncStorage from '@react-native-async-storage/async-storage';
import api from '../common/api';
import * as Progress from 'react-native-progress';
import moment from 'moment';
const MyGameScheduleList = ({item, navigation}) => {
  const [gamePage, setGamePage] = useState({items: []});
  const [seasonYear, setSeasonYear] = useState(moment().format('YYYY'));
  const [month, setMonth] = useState(moment().format('MM'));
  const [subLeagueId, setSubLeagueId] = useState();
  const [league, setLeagueId] = useState();
  const [teamId, setTeamId] = useState();
  const [seasonList, setSeasonList] = useState();
  const [teamOptions, setTeamOptions] = useState([]);
  const [progressShown, setProgressShown] = useState(false);
  const accessToken = useRef();
  let teamDropDownRef = useRef();
  let selectedRegion = useRef();

  let gamePageLoading = useRef();

  const yearRef = useRef();
  const monthRef = useRef();

  useEffect(() => {
    AsyncStorage.getItem('@accessToken', (err, result) => {
      accessToken.current = result;
      // getData(result);
      getGameData(result);
      getMyTeams(result);
    });
    let localSeasonList = [{text: '전체', value: ''}];
    for (let i = 2023; i <= moment().add(1, 'Y').format('YYYY'); i++) {
      localSeasonList.push({text: `${i}년`, value: i});
    }
    setSeasonList(localSeasonList);
  }, []);

  useEffect(() => {
    setSeasonYear(moment().format('YYYY'));
    setMonth(moment().format('MM'));
  }, [seasonList]);

  useEffect(() => {
    setSeasonYear(moment().format('YYYY'));
  }, [seasonList]);

  useEffect(() => {
    getGameData(accessToken.current);
  }, [seasonYear, month]);

  useEffect(() => {
    setTeamId('');
    getGameData(accessToken.current);
    teamDropDownRef.current?.reset();
  }, [subLeagueId]);

  useEffect(() => {
    getGameData(accessToken.current);
  }, [teamId]);

  const getMyTeams = token => {
    if (!token) {
      return;
    }
    api
      .get('/app-team/my-teams?pageSize=100', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(function (response) {
        const teamOptionCandidate = [];
        const teams = response.data.items;
        teamOptionCandidate.push({value: '', text: '전체 팀'});
        for (let i = 0; i < teams.length; i++) {
          teamOptionCandidate.push({value: teams[i].id, text: teams[i].name});
        }
        setTeamOptions(teamOptionCandidate);
      })
      .catch(function (error) {
        console.log(error.response);
      });
  };

  const getGameData = token => {
    if (!token) {
      return;
    }
    callGamePage(token, false);
  };

  const callGamePage = (token, loadMore) => {
    if (gamePageLoading.current) {
      return;
    }
    if (
      loadMore &&
      gamePage.currentPage &&
      gamePage.totalPage <= gamePage.currentPage
    ) {
      return;
    }
    gamePageLoading.current = true;

    setProgressShown(true);
    let requestUri = `games/my?seasonYear=${
      seasonYear ? seasonYear : ''
    }&month=${month ? month : ''}&leagueId=${
      league ? league.id : ''
    }&regionId=${
      selectedRegion.value && selectedRegion.value.id
        ? selectedRegion.value.id
        : ''
    }&pageNo=${
      gamePage.currentPage && loadMore
        ? parseInt(gamePage.currentPage, 10) + 1
        : 1
    }&pageSize=8&customOrderBy=MY_GAME`;
    if (subLeagueId) {
      requestUri += `&subLeagueId=${subLeagueId}`;
    }
    if (teamId) {
      requestUri += `&teamId=${teamId}`;
    }

    console.log('requestUri', requestUri);
    api
      .get(requestUri, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(function (response) {
        response.data.items = [
          ...(gamePage.items && loadMore ? gamePage.items : []),
          ...response.data.items,
        ];
        setGamePage(response.data);
      })
      .catch(function (error) {
        console.log(error);
      })
      .finally(() => {
        setProgressShown(false);
        gamePageLoading.current = false;
      });
  };
  return (
    <View>
      <View style={[pageStyle.dropdownRowContainer]}>
        <View style={[pageStyle.dropDownItemContainer]}>
          <SelectDropdown
            defaultButtonText={`${seasonYear}년`}
            buttonStyle={pageStyle.dropDown}
            buttonTextStyle={pageStyle.downDownText}
            rowTextStyle={styles.dropDownRowText}
            renderDropdownIcon={() => (
              <Image source={require('../assets/images/select_arr.png')} />
            )}
            data={seasonList}
            onSelect={(selectedItem, index) => {
              setSeasonYear(selectedItem.value);
            }}
            buttonTextAfterSelection={(selectedItem, index) => {
              return selectedItem.text;
            }}
            rowTextForSelection={(item, index) => {
              return item.text;
            }}
          />
        </View>
        <View style={[pageStyle.dropDownItemContainer]}>
          <SelectDropdown
            defaultButtonText={`${moment().format('MM')}월`}
            buttonStyle={pageStyle.dropDown}
            buttonTextStyle={pageStyle.downDownText}
            rowTextStyle={styles.dropDownRowText}
            renderDropdownIcon={() => (
              <Image source={require('../assets/images/select_arr.png')} />
            )}
            data={[
              {text: '전체', value: ''},
              {text: '1월', value: 1},
              {text: '2월', value: 2},
              {text: '3월', value: 3},
              {text: '4월', value: 4},
              {text: '5월', value: 5},
              {text: '6월', value: 6},
              {text: '7월', value: 7},
              {text: '8월', value: 8},
              {text: '9월', value: 9},
              {text: '10월', value: 10},
              {text: '11월', value: 11},
              {text: '12월', value: 12},
            ]}
            onSelect={(selectedItem, index) => {
              setMonth(selectedItem.value);
            }}
            buttonTextAfterSelection={(selectedItem, index) => {
              return selectedItem.text;
            }}
            rowTextForSelection={(item, index) => {
              return item.text;
            }}
          />
        </View>
        <View style={[pageStyle.dropDownItemContainer]}>
          <SelectDropdown
            defaultValue={teamId}
            defaultButtonText="팀"
            buttonStyle={pageStyle.dropDown}
            buttonTextStyle={styles.downDownText}
            rowTextStyle={styles.dropDownRowText}
            data={teamOptions}
            onSelect={(selectedItem, index) => {
              if (selectedItem === '전체') {
                setTeamId('');
              } else {
                setTeamId(selectedItem.value);
              }
            }}
            buttonTextAfterSelection={(selectedItem, index) => {
              return selectedItem.text;
            }}
            rowTextForSelection={(item, index) => {
              return item.text;
            }}
            renderDropdownIcon={() => (
              <Image source={require('../assets/images/select_arr.png')} />
            )}
          />
        </View>
      </View>
      <View style={[styles.row]} />
      <FlatList
        showsVerticalScrollIndicator={false}
        style={[pageStyle.list]}
        ListFooterComponent={() => <View style={[{height: 80}]} />}
        onEndReachedThreshold={1}
        onEndReached={() => callGamePage(accessToken.current, true, teamId)}
        keyExtractor={item => `game_${item.id.toString()}`}
        data={gamePage.items}
        renderItem={({item, index}) => (
          <GameMetaItem data={item} index={index} navigation={navigation} />
        )}
        ListEmptyComponent={() => (
          <View style={[styles.emptyContainer]}>
            <Image source={require('../assets/images/logo2.png')} />
          </View>
        )}
      />
      {progressShown && (
        <View style={[styles.progressContainer]}>
          <Progress.Circle
            fill="transparent"
            size={70}
            borderWidth={3}
            indeterminate={true}
          />
        </View>
      )}
    </View>
  );
};

export default MyGameScheduleList;

const pageStyle = StyleSheet.create({
  headerCommon: {},
  list: {
    height:
      Dimensions.get('window').height - (Platform.OS === 'ios' ? 230 : 200),
  },
  dropdownRowContainer: {
    display: 'flex',
    marginTop: 6,
    flexDirection: 'row',
    marginHorizontal: 23,
    paddingBottom: 8,
  },
  dropDownItemContainer: {
    marginHorizontal: 3,
    flex: 1,
  },
  dropDown: {
    width: '100%',
    backgroundColor: 'white',
    borderWidth: 1,
    borderColor: 'rgb(61, 80, 183)',
    height: 30,
  },
  downDownText: {
    color: 'black',
    fontFamily: 'Pretendard',
    fontSize: 11,
  },
  dateHeader: {
    marginTop: 13,
    backgroundColor: '#ddd',
    paddingVertical: 4,
  },
  dateTxt: {
    color: '#444',
    fontFamily: 'Pretendard',
    fontSize: 10,
    textAlign: 'center',
  },
});

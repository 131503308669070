import React, {useEffect, useState, useRef, useMemo} from 'react';
import {
  Image,
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  DeviceEventEmitter,
  Dimensions,
  Platform,
} from 'react-native';
import {FlatList} from 'react-native-gesture-handler';
import styles from '../common/styles';
import api from '../common/api';
import Colors from '../common/colors';
import FavoriteUserItem from './FavoriteUserItem';
import FavoriteTeamItem from './FavoriteTeamItem';

const MyFavoriteComponent = ({item, userInfo, navigation, accessToken}) => {
  const [type, setType] = useState('팀');
  const [page, setPage] = useState({});

  let typeRef = useRef('팀');

  useEffect(() => {
    const unsubscribe = DeviceEventEmitter.addListener(
      '@favoriteListRefresh',
      function (data) {
        console.log(typeRef.current, type);
        if (typeRef.current !== type) {
          setType(typeRef.current);
        }
        getData();
      },
    );
    return () => {
      unsubscribe.remove();
    };
  }, []);

  const getData = () => {
    console.log('getData', accessToken);
    if (!accessToken) {
      return;
    }
    api
      .get(
        `/app-user/favorites?type=${encodeURIComponent(
          typeRef.current,
        )}&pageNo=1&pageSize=20`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      )
      .then(function (response) {
        setPage(response.data);
      })
      .catch(function (error) {
        console.error(error.response);
      });
  };

  useEffect(() => {
    typeRef.current = type;
    getData();
  }, [type]);

  const fullWidth = useMemo(() => {
    if (Platform.OS === 'web') {
      const windowWidth = Dimensions.get('window').width;
      if (windowWidth < 550) {
        return windowWidth;
      } else {
        return 550;
      }
    }
    return '100%';
  }, []);

  const pageStyle = StyleSheet.create({
    container: {
      flex: 1,
      alignItems: 'center',
      backgroundColor: 'rgb(23, 53, 100)',
    },
    scrollView: {
      width: '100%',
    },
    scrollViewContainer: {
      paddingHorizontal: 32,
      paddingBottom: 37,
      alignItems: 'center',
    },
    tabContainer: {
      paddingHorizontal: 12,
      paddingVertical: 12,
    },
    buttonText: {
      color: Colors.main,
    },
    buttonTextSelected: {
      color: 'white',
    },
    buttonActive: {
      marginHorizontal: 4,
      backgroundColor: Colors.main,
      borderRadius: 20,
      height: 35,
      flex: 1,
      justifyContent: 'center',
      alignContent: 'center',
      alignItems: 'center',
    },
    buttonInactive: {
      marginHorizontal: 4,
      borderColor: Colors.main,
      borderWidth: 1,
      backgroundColor: 'white',
      borderRadius: 20,
      height: 35,
      flex: 1,
      justifyContent: 'center',
      alignContent: 'center',
      alignItems: 'center',
    },
    list: {
      borderTopWidth: 1,
      borderTopColor: '#e6e6e6',
    },
    noImage: {
      width: Platform.OS === 'web' ? fullWidth : '100%',
      height:
        Platform.OS === 'web'
          ? (1280 * fullWidth) / 1080
          : (1281 * Dimensions.get('window').width) / 1080,
    },
    logoImage: {
      width: 55,
      height: 55,
      borderRadius: 27,
    },
    profileImage: {
      width: 45,
      height: 45,
      borderRadius: 23,
    },
    row: {
      borderBottomWidth: 1,
      borderBottomColor: '#e6e6e6',
      paddingVertical: 12,
      paddingHorizontal: 24,
      alignContent: 'center',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    name: {
      fontFamily: 'Pretendard',
      fontSize: 14,
      color: 'black',
      marginStart: 8,
    },
    teamName: {
      fontFamily: 'Pretendard',
      fontSize: 16,
      color: 'black',
      marginStart: 16,
    },
    alarmImg: {
      width: 28,
      height: 30,
    },
  });

  return (
    <View>
      <View style={[styles.row, pageStyle.tabContainer]}>
        <TouchableOpacity
          style={[
            type === '팀' && pageStyle.buttonActive,
            type !== '팀' && pageStyle.buttonInactive,
          ]}
          onPress={() => {
            setType('팀');
            typeRef.current = '팀';
          }}>
          <Text
            style={[
              pageStyle.tabItem,
              pageStyle.buttonText,
              type === '팀' && pageStyle.buttonTextSelected,
            ]}>
            관심 팀
          </Text>
        </TouchableOpacity>
        <TouchableOpacity
          style={[
            type === '유저' && pageStyle.buttonActive,
            type !== '유저' && pageStyle.buttonInactive,
          ]}
          onPress={() => {
            setType('유저');
            typeRef.current = '유저';
          }}>
          <Text
            style={[
              pageStyle.tabItem,
              pageStyle.buttonText,
              type === '유저' && pageStyle.buttonTextSelected,
            ]}>
            관심 선수
          </Text>
        </TouchableOpacity>
      </View>
      <FlatList
        keyExtractor={item => `item_${item.id.toString()}`}
        data={page.items}
        ListEmptyComponent={() => (
          <View
            style={[styles.emptyContainer, {paddingTop: 0, marginTop: -20}]}>
            {page.totalCount === 0 && type === '유저' && (
              <Image
                resizeMode="cover"
                style={[pageStyle.noImage]}
                source={require('../assets/images/favorite_no_user.jpeg')}
              />
            )}
            {page.totalCount === 0 && type === '팀' && (
              <Image
                resizeMode="cover"
                style={[pageStyle.noImage]}
                source={require('../assets/images/favorite_no_team.jpeg')}
              />
            )}
          </View>
        )}
        style={[pageStyle.list]}
        renderItem={({item, index}) =>
          type === '유저' ? (
            <FavoriteUserItem
              data={item}
              accessToken={accessToken}
              navigation={navigation}
            />
          ) : (
            <FavoriteTeamItem
              data={item}
              accessToken={accessToken}
              navigation={navigation}
            />
          )
        }
      />
    </View>
  );
};

export default MyFavoriteComponent;

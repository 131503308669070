import React, {useEffect, useState, useRef, useCallback, useMemo} from 'react';
import {
  Image,
  SafeAreaView,
  DeviceEventEmitter,
  Text,
  TouchableOpacity,
  View,
  StyleSheet,
  FlatList,
  TextInput,
  Modal,
  Alert,
  KeyboardAvoidingView,
  Platform,
} from 'react-native';
import styles from '../common/styles';
import {Shadow} from 'react-native-shadow-2';
import AsyncStorage from '@react-native-async-storage/async-storage';
import api from '../common/api';
import * as Progress from 'react-native-progress';
import moment from 'moment';
import Colors from '../common/colors';
import alert from '../component/alert';
import {title} from '../common/util';

const EditCommentView = ({navigation, route}) => {
  const {commentId} = route.params;
  const [progressShown, setProgressShown] = useState(false);

  const [commentData, setCommentData] = useState({});
  const [comment, setComment] = useState(route.params.contents);

  let pageLoading = useRef(false);

  let accessToken = useRef();

  useEffect(() => {
    AsyncStorage.getItem('@accessToken', (err, result) => {
      if (err) {
        return;
      }
      accessToken.current = result;
      getComment();
    });
  }, []);

  const getComment = () => {
    if (!accessToken.current) {
      return;
    }
    if (pageLoading.current) {
      return;
    }
    pageLoading.current = true;
    setProgressShown(true);
    api
      .get(`/boards/comments/${commentId}`, {
        headers: {
          Authorization: `Bearer ${accessToken.current}`,
        },
      })
      .then(function (response) {
        setCommentData(response.data);
        pageLoading.current = false;
        setProgressShown(false);
      })
      .catch(function (error) {
        console.log(error);
      })
      .finally(() => {
        pageLoading.current = false;
        setProgressShown(false);
      });
  };

  const callWriteCommentApi = () => {
    if (pageLoading.current) {
      return;
    }
    if (progressShown) {
      return;
    }
    setProgressShown(true);
    pageLoading.current = true;
    api
      .post(
        `/boards/comments/${commentData.id}`,
        {
          contents: comment,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken.current}`,
          },
        },
      )
      .then(function (response) {
        DeviceEventEmitter.emit('@commentRefresh', {commentId: commentData.id});
        alert('수정이 완료되었습니다.');
        Platform.OS === 'web' ? history.back() : navigation.goBack();
      })
      .catch(function (error) {
        console.error(error);
      })
      .finally(() => {
        setProgressShown(false);
        pageLoading.current = false;
      });
  };

  const writeComment = () => {
    alert('댓글', '수정 하시겠습니까?', [
      {
        text: '예',
        onPress: () => callWriteCommentApi(),
      },
      {
        text: '아니오',
      },
    ]);
  };

  const cancelComment = () => {
    Platform.OS === 'web' ? history.back() : navigation.goBack();
  };

  const handleCamera = () => {};

  return (
    <>
      <SafeAreaView style={[styles.container]}>
        <Shadow
          offset={[0, 2]}
          style={[{width: '100%'}]}
          containerStyle={[{marginTop: 0}]}
          distance={7}
          sides={{bottom: true, top: false, start: false, end: false}}>
          <View style={[styles.headerCommon]}>
            <View style={[styles.row, styles.verticalAlign]}>
              <TouchableOpacity style={[styles.btn]} onPress={cancelComment}>
                <Text style={[pageStyle.btnTxt]}>취소</Text>
              </TouchableOpacity>
            </View>
            <View style={[pageStyle.commentHeaderContainer]}>
              <Text style={[pageStyle.headerText]}>댓글 수정</Text>
              <Text style={[pageStyle.boardType]}>
                {commentData.board?.boardType}
              </Text>
            </View>
            <View style={[styles.row, styles.verticalAlign]}>
              <TouchableOpacity style={[styles.btn]} onPress={writeComment}>
                <Text style={[pageStyle.btnTxt]}>확인</Text>
              </TouchableOpacity>
            </View>
          </View>
        </Shadow>
        <KeyboardAvoidingView
          behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
          style={{flex: 1}}>
          <View
            style={[
              {
                marginTop: 10,
                position: 'relative',
                flex: 1,
                justifyContent: 'space-between',
              },
            ]}>
            <TextInput
              style={[pageStyle.input]}
              value={comment}
              onChangeText={value => setComment(value)}
              onSubmitEditing={writeComment}
            />
            <View style={[pageStyle.cameraRow]}>
              <TouchableOpacity onPress={handleCamera}>
                <Image
                  style={[pageStyle.icnCamera]}
                  source={require('../assets/images/icn_camera.png')}
                />
              </TouchableOpacity>
            </View>
          </View>
        </KeyboardAvoidingView>
        {progressShown && (
          <View style={[styles.progressContainer]}>
            <Progress.Circle
              fill="transparent"
              size={70}
              borderWidth={3}
              indeterminate={true}
            />
          </View>
        )}
      </SafeAreaView>
    </>
  );
};

const pageStyle = StyleSheet.create({
  list: {
    paddingTop: 10,
    paddingBottom: 80,
  },
  commentHeaderContainer: {
    marginStart: 4,
  },
  headerText: {
    fontSize: 20,
    color: 'black',
    fontWeight: '600',
    fontFamily: 'Pretendard-SemiBold',
  },
  boardType: {
    alignSelf: 'center',
    color: 'black',
    fontFamily: 'Pretendard',
    fontSize: 12,
    marginTop: 6,
  },
  input: {
    fontFamily: 'Pretendard',
    fontSize: 18,
    color: 'black',
    margin: 12,
  },
  btn: {
    backgroundColor: Colors.main,
    borderRadius: 15,
    height: 30,
    width: 60,
    alignItems: 'center',
    justifyContent: 'center',
    marginStart: 16,
  },
  btnTxt: {
    color: Colors.main,
    fontFamily: 'Pretendard',
    fontSize: 16,
  },
  cameraRow: {
    paddingVertical: 12,
    paddingHorizontal: 24,
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  icnCamera: {
    width: 31,
    height: 29,
    marginStart: 12,
    opacity: 0.6,
  },
});

export default EditCommentView;

import React, {useState, useEffect, useRef} from 'react';
import {
  Image,
  SafeAreaView,
  ScrollView,
  Text,
  TouchableOpacity,
  View,
  StyleSheet,
  FlatList,
  DeviceEventEmitter,
  KeyboardAvoidingView,
  Alert,
  Platform,
} from 'react-native';
import styles from '../common/styles';
import {Shadow} from 'react-native-shadow-2';
import api from '../common/api';
import AsyncStorage from '@react-native-async-storage/async-storage';
import moment from 'moment';
import MyInfoSummary from '../component/MyInfoSummary';
import MyRecordComponent from '../component/MyRecordComponent';
import Colors from '../common/colors';
import {useFonts} from 'expo-font';
import * as Font from 'expo-font';

const MyPageView = ({navigation, route}) => {
  const [tabIndex, setTabIndex] = useState(0);
  const {item, itemId, from, gameId} = route.params;
  const [age, setAge] = useState(0);
  const [userInfo, setUserInfo] = useState({});

  const [alarmUnread, setAlarmUnread] = useState(false);
  const tabList = [
    {index: 0, text: '요약'},
    {index: 1, text: '기록실'},
  ];
  let accessToken = useRef();

  const [loadedFonts] = useFonts({
    Pretendard: require('../assets/font/PretendardVariable.ttf'),
  });

  useEffect(() => {
    AsyncStorage.getItem('@accessToken', (err, result) => {
      accessToken.current = result;
      getUserInfo(result);
      checkAlarmUnRead();
    });
  }, []);

  const checkAlarmUnRead = () => {
    if (!accessToken.current) {
      return;
    }
    api
      .get('/users/exists-unread-alarm', {
        headers: {
          Authorization: `Bearer ${accessToken.current}`,
        },
      })
      .then(function (response) {
        setAlarmUnread(response.data);
      })
      .catch(function (error) {
        console.error(error.response);
      });
  };

  const getUserInfo = token => {
    api
      .get(`/app-user/${itemId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(function (response) {
        setUserInfo(response.data);
        setAge(moment().diff(moment(response.data.birth, 'YYYY-MM-DD'), 'y'));
      })
      .catch(function (error) {
        console.error(error.response);
      });
  };

  const getProfileImageUrl = user => {
    if (user?.profileImageThumbUrl) {
      return {uri: user?.profileImageThumbUrl};
    }
    if (user?.profileImageUrl) {
      return {uri: user?.profileImageUrl};
    }
    return require('../assets/images/icn_profile_img.png');
  };

  const getProfileImageStyle = user => {
    if (user?.profileImageThumbUrl) {
      return pageStyle.profileImage;
    }
    if (user?.profileImageUrl) {
      return pageStyle.profileImage;
    }
    return {
      position: 'relative',
      width: 60,
      height: 60,
      resizeMode: 'cover',
      borderRadius: 30,
    };
  };

  const toggleFavorite = () => {
    api
      .post(
        `/app-user/${itemId}/favorites`,
        {},
        {
          headers: {
            Authorization: `Bearer ${accessToken.current}`,
          },
        },
      )
      .then(function (response) {
        getUserInfo(accessToken.current);
        setTimeout(() => {
          DeviceEventEmitter.emit('@favoriteListRefresh');
        }, 200);
      })
      .catch(function (error) {
        console.error(error.response);
      });
  };

  const goToMyplayView = () => {
    navigation.navigate('MyPlay', {user: userInfo});
  };

  return (
    <>
      <KeyboardAvoidingView
        behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
        style={pageStyle.container}>
        <SafeAreaView
          nativeID="common-page-container"
          style={[styles.container]}>
          <Shadow
            offset={[0, 2]}
            style={[{width: '100%'}]}
            containerStyle={[{marginTop: 0}]}
            distance={7}
            sides={{bottom: true, top: false, start: false, end: false}}>
            <View style={[styles.headerCommon]}>
              <View style={[styles.row, styles.verticalAlign]}>
                <TouchableOpacity
                  onPress={() => {
                    Platform.OS === 'web'
                      ? history.back()
                      : navigation.goBack();
                    if (from === 'lineUpPopup') {
                      DeviceEventEmitter.emit('@showLineUpPopup', {
                        gameId: gameId,
                      });
                    }
                  }}>
                  <Image
                    source={require('../assets/images/icon_back.png')}
                    style={[styles.headerBackButton]}
                  />
                </TouchableOpacity>
                <Text style={[styles.headerText]}>{userInfo?.name}</Text>
              </View>
              <View style={[styles.row, styles.verticalAlign]}>
                <TouchableOpacity
                  style={[styles.alarmContainer]}
                  onPress={() => navigation.navigate('MyAlarmList')}>
                  <Image
                    resizeMode="contain"
                    style={[styles.imgAlarm]}
                    source={require('../assets/images/icn_alarm.png')}
                  />
                  {alarmUnread && <View style={[styles.alarmDot]} />}
                </TouchableOpacity>
              </View>
            </View>
          </Shadow>
          <ScrollView style={[{marginTop: 0}]}>
            <View
              style={[
                styles.scrollViewContainer,
                pageStyle.scrollViewContainer,
              ]}>
              <View style={[pageStyle.profileHeader]}>
                <Image
                  source={require('../assets/images/mypage_bg.png')}
                  style={[pageStyle.profileBg]}
                />
                <View style={[pageStyle.profileContainer]}>
                  <TouchableOpacity style={[pageStyle.profileLeftContainer]}>
                    <Image
                      source={getProfileImageUrl(userInfo)}
                      style={getProfileImageStyle(userInfo)}
                      resizeMode={'cover'}
                    />
                  </TouchableOpacity>
                  <View style={[pageStyle.profileRightContainer]}>
                    <Text style={[pageStyle.username]}>{userInfo.name}</Text>
                    <Text style={[pageStyle.otherInfo]} numberOfLines={1}>
                      {userInfo.pitchingAndHitting}/
                      {userInfo.hideBirth ? '비공개' : `${age}세`}
                    </Text>
                  </View>
                  <TouchableOpacity
                    onPress={toggleFavorite}
                    style={[
                      pageStyle.favoriteContainer,
                      userInfo.favorited && {right: 16, top: -34},
                    ]}>
                    <Image
                      resizeMode="contain"
                      style={[pageStyle.favoriteImage]}
                      source={
                        userInfo.favorited
                          ? require('../assets/images/icn_favorite_on.png')
                          : require('../assets/images/icn_favorite.png')
                      }
                    />
                    <Text
                      style={[
                        pageStyle.favoriteTxt,
                        userInfo.favorited && pageStyle.favoritedTxt,
                      ]}>
                      관심선수
                    </Text>
                  </TouchableOpacity>
                </View>
                <TouchableOpacity
                  onPress={goToMyplayView}
                  style={[pageStyle.myplayIcnContainer]}>
                  <Image
                    resizeMode="cover"
                    style={[pageStyle.myplayIcn]}
                    source={require('../assets/images/myplay.png')}
                  />
                </TouchableOpacity>
              </View>
              <View style={[styles.tabContainer]}>
                {tabList.map(tab => (
                  <TouchableOpacity
                    key={`tab_${tab.index}`}
                    onPress={() => setTabIndex(tab.index)}
                    style={[
                      tabIndex == tab.index && styles.tabItemSelected,
                      tabIndex != tab.index && styles.tabItem,
                    ]}>
                    <Text
                      style={[
                        tabIndex == tab.index && styles.tabItemTextSelected,
                        tabIndex != tab.index && styles.tabItemText,
                      ]}>
                      {tab.text}
                    </Text>
                  </TouchableOpacity>
                ))}
              </View>
              {tabIndex === 0 && (
                <MyInfoSummary navigation={navigation} userInfo={userInfo} />
              )}
              {tabIndex === 1 && (
                <MyRecordComponent
                  userInfo={userInfo}
                  navigation={navigation}
                />
              )}
            </View>
          </ScrollView>
        </SafeAreaView>
      </KeyboardAvoidingView>
    </>
  );
};

const pageStyle = StyleSheet.create({
  container: {flex: 1},
  list: {
    paddingStart: 26,
    paddingEnd: 26,
    paddingTop: 10,
    paddingBottom: 50,
  },
  header: {
    paddingVertical: 15,
    paddingHorizontal: 33,
  },
  profileHeader: {
    position: 'relative',
    height: 139,
    // marginTop: -5,
    zIndex: 10,
  },
  profileBg: {
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    position: 'absolute',
  },
  profileContainer: {
    flexDirection: 'row',
    height: 80,
    marginStart: 33,
    marginTop: 40,
    position: 'relative',
  },
  profileLeftContainer: {
    width: 60,
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  profileImage: {
    height: 80,
    width: 60,
    position: 'absolute',
    top: 0,
    left: 0,
  },
  profileTitle: {
    color: '#9fa0a0',
    fontFamily: 'Pretendard',
    fontSize: 11,
    textAlign: 'center',
    position: 'absolute',
    bottom: 10,
    left: 7,
  },
  profileRightContainer: {
    paddingStart: 22,
    paddingTop: 25,
    paddingBottom: 10,
  },
  username: {
    color: 'white',
    fontFamily: 'Pretendard-Bold',
    fontSize: 21,
    fontFamily: 'letter',
    fontWeight: '700',
    fontStyle: 'italic',
  },
  otherInfo: {
    marginTop: 6,
    fontFamily: 'Pretendard',
    fontSize: 13,
    color: 'white',
    fontStyle: 'italic',
  },
  favoriteContainer: {
    position: 'absolute',
    top: -30,
    right: 20,
    alignItems: 'center',
    alignContent: 'center',
  },
  favoriteImage: {
    width: 32,
    height: 50,
  },
  favoriteTxt: {
    color: 'white',
    fontFamily: 'Pretendard',
    fontSize: 12,
    textAlign: 'center',
  },
  favoritedTxt: {
    color: 'white',
    fontFamily: 'Pretendard-Bold',
    fontWeight: 'bold',
    padding: 4,
    borderColor: 'black',
    opacity: 0.8,
    backgroundColor: Colors.main,
  },
  myplayIcnContainer: {
    position: 'absolute',
    bottom: 15,
    right: 15,
  },
  myplayIcn: {
    width: 130,
    height: 130 / (117 / 28),
  },
  innerContainer: {
    paddingHorizontal: 29,
  },
  subtitle: {
    marginTop: 16,
    marginStart: 10,
    color: 'black',
    fontFamily: 'Pretendard-Bold',
    fontSize: 16,
    fontWeight: '700',
  },
  row: {
    flexDirection: 'row',
  },
  firstRow: {
    marginTop: 10,
  },
  col: {
    flex: 1,
  },
  th: {
    backgroundColor: '#3f51b5',
    padding: 4,
    lineHeight: 13,
    fontFamily: 'Pretendard-Bold',
    fontSize: 13,
    color: 'white',
    fontWeight: '700',
    textAlign: 'center',
  },
  td: {
    padding: 4,
    paddingVertical: 10,
    lineHeight: 13,
    fontFamily: 'Pretendard-Bold',
    fontSize: 13,
    color: 'black',
    fontWeight: '700',
    textAlign: 'center',
  },
  even: {
    backgroundColor: 'rgb(236, 236, 236)',
  },
});

export default MyPageView;

import React, {useEffect, useState, useRef, useMemo} from 'react';
import {
  Button,
  Image,
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  TextInput,
  KeyboardAvoidingView,
  Platform,
  Linking,
  ImageBackground,
  Dimensions,
  Modal,
} from 'react-native';
import {ScrollView} from 'react-native-gesture-handler';
import {SafeAreaView} from 'react-native-safe-area-context';
import SelectDropdown from 'react-native-select-dropdown';
import styles from '../common/styles';
import api from '../common/api';
import '@expo/match-media';
import {useMediaQuery} from 'react-responsive';
import YoutubePlayer from 'react-native-youtube-iframe';
import moment from 'moment';

const WebApplyView = ({navigation, route}) => {
  const isTabletOrMobileDevice = useMediaQuery({
    maxDeviceWidth: 830,
  });
  const [youtubeVideoId, setYoutubeVideoId] = useState('');

  const youtubeRef = useRef();
  const dateRef = useRef();
  const hourRef = useRef();
  const minuteRef = useRef();
  const stadiumRef = useRef();
  const positionRef = useRef();

  const [videoType, setVideoType] = useState(1);

  const [date, setDate] = useState('');
  const [hour, setHour] = useState('');
  const [minute, setMinute] = useState('');
  const [stadium, setStadium] = useState('수원대학교야구장');
  const [name, setName] = useState('');
  const [contact, setContact] = useState('');

  const [teamName, setTeamName] = useState('');
  const [playerName, setPlayerName] = useState('');
  const [position, setPosition] = useState('P');
  const [inningInfo, setInningInfo] = useState('');
  const [description, setDescription] = useState('');
  const [battingOrder, setBattingOrder] = useState(null);
  const [backNumber, setBackNumber] = useState(null);
  const [open, setOpen] = useState(true);

  const [detailType, setDetailType] = useState(1);

  const btnText = useMemo(() => {
    if (videoType === 1) {
      return '신청 (3,000원)';
    }
    if (detailType === 2 || detailType === 3) {
      return '신청 (10,000원)';
    }
    return '신청 (5,000원)';
  }, [videoType, detailType]);

  const dates = useMemo(() => {
    const result = [];
    for (let i = 0; i <= 14; i++) {
      result.push(
        `${moment()
          .add(-14 + i, 'd')
          .format('YYYY-MM-DD')}`,
      );
    }
    return result;
  }, []);

  const hours = useMemo(() => {
    const result = [];
    for (let i = 0; i < 24; i++) {
      if (i < 10) {
        result.push(`0${i}`);
      } else {
        result.push(`${i}`);
      }
    }
    return result;
  }, []);

  const minutes = useMemo(() => {
    const result = [];
    for (let i = 0; i < 6; i++) {
      if (i * 10 < 10) {
        result.push(`0${i * 10}`);
      } else {
        result.push(`${i * 10}`);
      }
    }
    return result;
  }, []);

  useEffect(() => {
    setDate(`${moment().add(-14, 'd').format('YYYY-MM-DD')}`);
    setHour('00');
    setMinute('00');
  }, []);

  const handleSubmit = () => {
    if (!date || !minute || !hour || !stadium || !name || !contact) {
      alert('신청서를 빠짐 없이 작성해주세요!');
      return;
    }
    if (videoType === 2) {
      if (
        detailType === 1 &&
        (!teamName || !playerName || !position || !inningInfo || !description)
      ) {
        alert('신청서를 빠짐 없이 작성해주세요!');
        return;
      } else if (
        (detailType === 2 || detailType === 3) &&
        (!teamName ||
          !playerName ||
          !position ||
          !battingOrder ||
          !backNumber ||
          !description)
      ) {
      }
    }

    const body = {
      applyType: videoType === 1 ? 'full' : 'solo',
      applySubType:
        detailType === 1 ? 'single' : detailType === 2 ? 'all' : 'pitcher',
      applyPlayDate: `${date} ${hour}:${minute}:00`,
      applyField: stadium,
      applyTeamName: teamName,
      applyUserName: playerName,
      applyUserPosition: position,
      applyInningInfo: inningInfo,
      applyBattingOrer: battingOrder,
      applyUserNumber: backNumber,
      applyDescription: description,
      applyDepositorName: name,
      applyDepositorPhoneNumber: contact,
      applyIsPublic: open ? 'admit' : 'disadmit',
      applyPrice:
        videoType === 1
          ? 3000
          : detailType === 2 || detailType === 3
          ? 10000
          : 5000,
    };

    api
      .post('/video-request/apply', body)
      .then(function (response) {
        setVideoType(1);
        setDetailType(1);
        setDate(`${moment().add(-14, 'd').format('YYYY-MM-DD')}`);
        setHour('00');
        setMinute('00');
        setStadium('수원대학교야구장');
        setTeamName('');
        setPlayerName('');
        setPosition('P');
        setInningInfo('');
        setDescription('');
        setName('');
        setContact('');
        setOpen(true);

        alert('영상 신청이 등록되었습니다.');

        dateRef.current.reset();
        hourRef.current.reset();
        minuteRef.current.reset();
        stadiumRef.current.reset();
        positionRef.current.reset();
      })
      .catch(function (error) {
        console.log(error.response);
        alert('요청에 실패했습니다.');
      })
      .finally(() => {});
  };
  return (
    <SafeAreaView>
      <ScrollView>
        <View>
          <View style={[pageStyle.step1]}>
            <ImageBackground
              resizeMode="cover"
              style={[pageStyle.step1Bg, {height: videoType === 1 ? 550 : 850}]}
              source={require('../assets/images/pc_apply_img.jpg')}>
              <TouchableOpacity
                style={[
                  pageStyle.logoContainer,
                  isTabletOrMobileDevice && pageStyle.logoContainerMobile,
                ]}
                onPress={() => navigation.navigate('WebMain')}>
                <Image
                  style={[pageStyle.logoImg]}
                  source={require('../assets/images/logo_pc_colored.png')}
                />
              </TouchableOpacity>
              <View
                style={[
                  pageStyle.formContainer,
                  isTabletOrMobileDevice && pageStyle.formContainerMobile,
                ]}>
                <Text
                  style={[
                    pageStyle.subtitle,
                    isTabletOrMobileDevice && pageStyle.subtitleMobile,
                  ]}>
                  영상종류
                </Text>
                <View style={[styles.row, styles.mt7]}>
                  <TouchableOpacity
                    style={{flex: 1}}
                    onPress={() => setVideoType(1)}>
                    <View
                      style={[
                        styles.row,
                        {alignContent: 'center', alignItems: 'center'},
                      ]}>
                      <Image
                        style={[pageStyle.icnCheck]}
                        source={
                          videoType === 1
                            ? require('../assets/images/icn_checked.png')
                            : require('../assets/images/icn_unchecked.png')
                        }
                      />
                      <Text
                        style={[
                          pageStyle.value,
                          isTabletOrMobileDevice && pageStyle.valueMobile,
                        ]}>
                        풀 영상
                      </Text>
                    </View>
                  </TouchableOpacity>
                  <TouchableOpacity
                    style={{flex: 1}}
                    onPress={() => setVideoType(2)}>
                    <View
                      style={[
                        styles.row,
                        {alignContent: 'center', alignItems: 'center'},
                      ]}>
                      <Image
                        style={[pageStyle.icnCheck]}
                        source={
                          videoType === 2
                            ? require('../assets/images/icn_checked.png')
                            : require('../assets/images/icn_unchecked.png')
                        }
                      />
                      <Text
                        style={[
                          pageStyle.value,
                          isTabletOrMobileDevice && pageStyle.valueMobile,
                        ]}>
                        개인 영상
                      </Text>
                    </View>
                  </TouchableOpacity>
                  <View style={{flex: 1}} />
                </View>
                {videoType === 2 && (
                  <>
                    <Text
                      style={[
                        pageStyle.subtitle,
                        isTabletOrMobileDevice && pageStyle.subtitleMobile,
                      ]}>
                      세부 종류
                    </Text>
                    <View style={[styles.row, styles.mt7]}>
                      <TouchableOpacity
                        style={{flex: 1}}
                        onPress={() => setDetailType(1)}>
                        <View
                          style={[
                            styles.row,
                            {alignContent: 'center', alignItems: 'center'},
                          ]}>
                          <Image
                            style={[pageStyle.icnCheck]}
                            source={
                              detailType === 1
                                ? require('../assets/images/icn_checked.png')
                                : require('../assets/images/icn_unchecked.png')
                            }
                          />
                          <Text
                            style={[
                              pageStyle.value,
                              isTabletOrMobileDevice && pageStyle.valueMobile,
                            ]}>
                            한 타석
                          </Text>
                        </View>
                      </TouchableOpacity>
                      <TouchableOpacity
                        style={{flex: 1}}
                        onPress={() => setDetailType(2)}>
                        <View
                          style={[
                            styles.row,
                            {alignContent: 'center', alignItems: 'center'},
                          ]}>
                          <Image
                            style={[pageStyle.icnCheck]}
                            source={
                              detailType === 2
                                ? require('../assets/images/icn_checked.png')
                                : require('../assets/images/icn_unchecked.png')
                            }
                          />
                          <Text
                            style={[
                              pageStyle.value,
                              isTabletOrMobileDevice && pageStyle.valueMobile,
                            ]}>
                            전 타석
                          </Text>
                        </View>
                      </TouchableOpacity>
                      <TouchableOpacity
                        style={{flex: 1}}
                        onPress={() => setDetailType(3)}>
                        <View
                          style={[
                            styles.row,
                            {alignContent: 'center', alignItems: 'center'},
                          ]}>
                          <Image
                            style={[pageStyle.icnCheck]}
                            source={
                              detailType === 3
                                ? require('../assets/images/icn_checked.png')
                                : require('../assets/images/icn_unchecked.png')
                            }
                          />
                          <Text
                            style={[
                              pageStyle.value,
                              isTabletOrMobileDevice && pageStyle.valueMobile,
                            ]}>
                            투수
                          </Text>
                        </View>
                      </TouchableOpacity>
                    </View>
                  </>
                )}
                <Text
                  style={[
                    pageStyle.subtitle,
                    isTabletOrMobileDevice && pageStyle.subtitleMobile,
                  ]}>
                  날짜/시간
                </Text>
                <View style={[styles.row]}>
                  <View style={[styles.col2]}>
                    <SelectDropdown
                      ref={dateRef}
                      defaultButtonText="날짜선택"
                      defaultValue={moment().add(-7, 'd').format('YYYY-MM-DD')}
                      buttonStyle={[
                        pageStyle.dropDown,
                        isTabletOrMobileDevice && pageStyle.dropDownMobile,
                        {borderEndWidth: 0},
                      ]}
                      buttonTextStyle={[
                        pageStyle.downDownText,
                        isTabletOrMobileDevice && pageStyle.downDownTextMobile,
                      ]}
                      dropdownStyle={pageStyle.dropDownStyle}
                      rowTextStyle={pageStyle.dropDownRowText}
                      renderDropdownIcon={() => (
                        <Image
                          style={[pageStyle.dropdownArr]}
                          source={require('../assets/images/select_arr.png')}
                        />
                      )}
                      data={dates}
                      onSelect={(selectedItem, index) => {
                        setDate(selectedItem);
                      }}
                      buttonTextAfterSelection={(selectedItem, index) => {
                        return moment(selectedItem, 'YYYY-MM-DD').format(
                          'YYYY년 MM월 DD일',
                        );
                      }}
                      rowTextForSelection={(item, index) => {
                        return moment(item, 'YYYY-MM-DD').format(
                          'YYYY년 MM월 DD일',
                        );
                      }}
                    />
                  </View>
                  <View style={[styles.col]}>
                    <SelectDropdown
                      ref={hourRef}
                      defaultButtonText="시간선택"
                      defaultValue={'00'}
                      buttonStyle={[
                        pageStyle.dropDown,
                        isTabletOrMobileDevice && pageStyle.dropDownMobile,
                        {borderEndWidth: 0},
                      ]}
                      buttonTextStyle={[
                        pageStyle.downDownText,
                        isTabletOrMobileDevice && pageStyle.downDownTextMobile,
                      ]}
                      dropdownStyle={pageStyle.dropDownStyle}
                      rowTextStyle={pageStyle.dropDownRowText}
                      renderDropdownIcon={() => (
                        <Image
                          style={[pageStyle.dropdownArr]}
                          source={require('../assets/images/select_arr.png')}
                        />
                      )}
                      data={hours}
                      onSelect={(selectedItem, index) => {
                        setHour(selectedItem);
                      }}
                      buttonTextAfterSelection={(selectedItem, index) => {
                        return `${selectedItem}시`;
                      }}
                      rowTextForSelection={(item, index) => {
                        return `${item}시`;
                      }}
                    />
                  </View>
                  <View style={[styles.col]}>
                    <SelectDropdown
                      ref={minuteRef}
                      defaultButtonText="분선택"
                      defaultValue={'00'}
                      buttonStyle={[
                        pageStyle.dropDown,
                        isTabletOrMobileDevice && pageStyle.dropDownMobile,
                      ]}
                      buttonTextStyle={[
                        pageStyle.downDownText,
                        isTabletOrMobileDevice && pageStyle.downDownTextMobile,
                      ]}
                      dropdownStyle={pageStyle.dropDownStyle}
                      rowTextStyle={pageStyle.dropDownRowText}
                      renderDropdownIcon={() => (
                        <Image
                          style={[pageStyle.dropdownArr]}
                          source={require('../assets/images/select_arr.png')}
                        />
                      )}
                      data={minutes}
                      onSelect={(selectedItem, index) => {
                        setMinute(selectedItem);
                      }}
                      buttonTextAfterSelection={(selectedItem, index) => {
                        return `${selectedItem}분`;
                      }}
                      rowTextForSelection={(item, index) => {
                        return `${item}분`;
                      }}
                    />
                  </View>
                </View>
                <Text
                  style={[
                    pageStyle.subtitle,
                    isTabletOrMobileDevice && pageStyle.subtitleMobile,
                  ]}>
                  구장명
                </Text>
                <SelectDropdown
                  ref={stadiumRef}
                  defaultButtonText="야구장선택"
                  buttonStyle={[
                    pageStyle.dropDown,
                    isTabletOrMobileDevice && pageStyle.dropDownMobile,
                  ]}
                  buttonTextStyle={[
                    pageStyle.downDownText,
                    isTabletOrMobileDevice && pageStyle.downDownTextMobile,
                  ]}
                  dropdownStyle={pageStyle.dropDownStyle}
                  rowTextStyle={pageStyle.dropDownRowText}
                  renderDropdownIcon={() => (
                    <Image
                      style={[pageStyle.dropdownArr]}
                      source={require('../assets/images/select_arr.png')}
                    />
                  )}
                  data={[
                    '수원대학교야구장',
                    'M.C베이스볼파크',
                    '파워리그구장',
                    '경희대학교야구장',
                    '유신고등학교 야구장',
                    '청학광형베이스볼파크',
                    'JS베이스볼파크',
                    '동탄야구장',
                    '우리은행야구장',
                    '훼릭스1구장',
                    '훼릭스2구장',
                    '독고탁2구장',
                    '오산시야구장',
                  ]}
                  onSelect={(selectedItem, index) => {
                    setStadium(selectedItem);
                  }}
                  buttonTextAfterSelection={(selectedItem, index) => {
                    return selectedItem;
                  }}
                  rowTextForSelection={(item, index) => {
                    return item;
                  }}
                />
                {videoType === 2 && (
                  <>
                    <View style={[styles.row]}>
                      <View style={[styles.col2]}>
                        <Text
                          style={[
                            pageStyle.subtitle,
                            isTabletOrMobileDevice && pageStyle.subtitleMobile,
                          ]}>
                          팀명
                        </Text>
                        <TextInput
                          value={teamName}
                          onChangeText={value => setTeamName(value)}
                          style={[
                            pageStyle.textInput,
                            isTabletOrMobileDevice && pageStyle.textInputMobile,
                            {borderEndWidth: 0},
                          ]}
                          placeholder={'LA다저스'}
                          placeholderTextColor={'#bbb'}
                        />
                      </View>
                      <View style={[styles.col2]}>
                        <Text
                          style={[
                            pageStyle.subtitle,
                            isTabletOrMobileDevice && pageStyle.subtitleMobile,
                          ]}>
                          이름
                        </Text>
                        <TextInput
                          value={playerName}
                          onChangeText={value => setPlayerName(value)}
                          style={[
                            pageStyle.textInput,
                            isTabletOrMobileDevice && pageStyle.textInputMobile,
                            {borderEndWidth: 0},
                          ]}
                          placeholder={'류현진'}
                          placeholderTextColor={'#bbb'}
                        />
                      </View>
                      <View style={[styles.col]}>
                        <Text
                          style={[
                            pageStyle.subtitle,
                            isTabletOrMobileDevice && pageStyle.subtitleMobile,
                          ]}>
                          포지션
                        </Text>
                        <SelectDropdown
                          ref={positionRef}
                          defaultButtonText="포지션"
                          defaultValue={'P'}
                          buttonStyle={[
                            pageStyle.dropDown,
                            {borderEndWidth: 0},
                          ]}
                          buttonTextStyle={[
                            pageStyle.downDownText,
                            isTabletOrMobileDevice &&
                              pageStyle.downDownTextMobile,
                          ]}
                          dropdownStyle={pageStyle.dropDownStyle}
                          rowTextStyle={pageStyle.dropDownRowText}
                          renderDropdownIcon={() => (
                            <Image
                              style={[pageStyle.dropdownArr]}
                              source={require('../assets/images/select_arr.png')}
                            />
                          )}
                          data={[
                            'P',
                            'C',
                            '1B',
                            '2B',
                            '3B',
                            'SS',
                            'LF',
                            'CF',
                            'RF',
                            'DH',
                          ]}
                          onSelect={(selectedItem, index) => {
                            setPosition(selectedItem);
                          }}
                          buttonTextAfterSelection={(selectedItem, index) => {
                            return selectedItem;
                          }}
                          rowTextForSelection={(item, index) => {
                            return item;
                          }}
                        />
                      </View>
                      {detailType === 1 && (
                        <View style={[styles.col2]}>
                          <Text
                            style={[
                              pageStyle.subtitle,
                              isTabletOrMobileDevice &&
                                pageStyle.subtitleMobile,
                            ]}>
                            이닝 정보
                          </Text>
                          <TextInput
                            value={inningInfo}
                            onChangeText={value => setInningInfo(value)}
                            style={[
                              pageStyle.textInput,
                              isTabletOrMobileDevice &&
                                pageStyle.textInputMobile,
                            ]}
                            placeholder={'4회말 2아웃'}
                            placeholderTextColor={'#bbb'}
                          />
                        </View>
                      )}
                      {(detailType === 2 || detailType === 3) && (
                        <>
                          <View style={[styles.col]}>
                            <Text
                              style={[
                                pageStyle.subtitle,
                                isTabletOrMobileDevice &&
                                  pageStyle.subtitleMobile,
                              ]}>
                              타순
                            </Text>
                            <TextInput
                              value={battingOrder}
                              onChangeText={value => setBattingOrder(value)}
                              style={[
                                pageStyle.textInput,
                                isTabletOrMobileDevice &&
                                  pageStyle.textInputMobile,
                                {borderEndWidth: 0},
                              ]}
                              placeholder={'4'}
                              placeholderTextColor={'#bbb'}
                            />
                          </View>
                          <View style={[styles.col]}>
                            <Text
                              style={[
                                pageStyle.subtitle,
                                isTabletOrMobileDevice &&
                                  pageStyle.subtitleMobile,
                              ]}>
                              등번호
                            </Text>
                            <TextInput
                              value={backNumber}
                              onChangeText={value => setBackNumber(value)}
                              style={[
                                pageStyle.textInput,
                                isTabletOrMobileDevice &&
                                  pageStyle.textInputMobile,
                              ]}
                              placeholder={'99'}
                              placeholderTextColor={'#bbb'}
                            />
                          </View>
                        </>
                      )}
                    </View>
                  </>
                )}
                {videoType === 2 && (
                  <>
                    <Text
                      style={[
                        pageStyle.subtitle,
                        isTabletOrMobileDevice && pageStyle.subtitleMobile,
                      ]}>
                      {detailType === 3 ? '투구 이닝 설명' : '타석 설명'}
                    </Text>
                    <TextInput
                      value={description}
                      onChangeText={value => setDescription(value)}
                      style={[
                        pageStyle.textInput,
                        isTabletOrMobileDevice && pageStyle.textInputMobile,
                      ]}
                      placeholder={
                        detailType === 3
                          ? '투구 이닝, 시간, 인상착의 등을 적어주세요!'
                          : '타석 결과, 시간, 인상착의 등을 적어주세요!'
                      }
                      placeholderTextColor={'#bbb'}
                    />
                  </>
                )}
                <View style={[styles.row]}>
                  <View style={[styles.col]}>
                    <Text
                      style={[
                        pageStyle.subtitle,
                        isTabletOrMobileDevice && pageStyle.subtitleMobile,
                      ]}>
                      입금자명
                    </Text>
                    <TextInput
                      value={name}
                      onChangeText={value => setName(value)}
                      style={[
                        pageStyle.textInput,
                        isTabletOrMobileDevice && pageStyle.textInputMobile,
                        {borderEndWidth: 0},
                      ]}
                      placeholder={'류현진'}
                      placeholderTextColor={'#bbb'}
                    />
                  </View>
                  <View style={[styles.col2]}>
                    <Text
                      style={[
                        pageStyle.subtitle,
                        isTabletOrMobileDevice && pageStyle.subtitleMobile,
                      ]}>
                      연락처
                    </Text>
                    <TextInput
                      value={contact}
                      onChangeText={value => setContact(value)}
                      style={[
                        pageStyle.textInput,
                        isTabletOrMobileDevice && pageStyle.textInputMobile,
                      ]}
                      placeholder={'010-1234-5678'}
                      placeholderTextColor={'#bbb'}
                    />
                  </View>
                </View>
                {videoType === 2 && (
                  <>
                    <Text
                      style={[
                        pageStyle.subtitle,
                        isTabletOrMobileDevice && pageStyle.subtitleMobile,
                      ]}>
                      공개여부
                    </Text>
                    <View style={[styles.row, styles.mt7]}>
                      <TouchableOpacity
                        style={{flex: 1}}
                        onPress={() => setOpen(true)}>
                        <View
                          style={[
                            styles.row,
                            {alignContent: 'center', alignItems: 'center'},
                          ]}>
                          <Image
                            style={[pageStyle.icnCheck]}
                            source={
                              open
                                ? require('../assets/images/icn_checked.png')
                                : require('../assets/images/icn_unchecked.png')
                            }
                          />
                          <Text
                            style={[
                              pageStyle.value,
                              isTabletOrMobileDevice && pageStyle.valueMobile,
                            ]}>
                            공개
                          </Text>
                        </View>
                      </TouchableOpacity>
                      <TouchableOpacity
                        style={{flex: 1}}
                        onPress={() => setOpen(false)}>
                        <View
                          style={[
                            styles.row,
                            {alignContent: 'center', alignItems: 'center'},
                          ]}>
                          <Image
                            style={[pageStyle.icnCheck]}
                            source={
                              !open
                                ? require('../assets/images/icn_checked.png')
                                : require('../assets/images/icn_unchecked.png')
                            }
                          />
                          <Text
                            style={[
                              pageStyle.value,
                              isTabletOrMobileDevice && pageStyle.valueMobile,
                            ]}>
                            비공개
                          </Text>
                        </View>
                      </TouchableOpacity>
                      <View style={{flex: 1}} />
                    </View>
                  </>
                )}
                <TouchableOpacity
                  onPress={handleSubmit}
                  style={[pageStyle.btn]}>
                  <Text style={[pageStyle.btnTxt]}>{btnText}</Text>
                </TouchableOpacity>
              </View>
            </ImageBackground>
          </View>

          <View style={[pageStyle.step4]} nativeID="main-step3">
            <Text style={[pageStyle.step4Title]}>영상 알아보기</Text>
            <View
              style={[
                styles.row,
                pageStyle.step4ValueContainer,
                isTabletOrMobileDevice && pageStyle.step4ValueContainerMobile,
              ]}>
              <TouchableOpacity
                style={[pageStyle.step4Item]}
                onPress={() => setYoutubeVideoId('QPizgFKXMRI')}>
                <ImageBackground
                  resizeMode="cover"
                  source={require('../assets/images/pc_img_1.png')}
                  style={[
                    pageStyle.step4ItemContainer,
                    isTabletOrMobileDevice &&
                      pageStyle.step4ItemContainerMobile,
                  ]}></ImageBackground>
                <Text style={[pageStyle.step4ItemTxt]}>개인 영상</Text>
              </TouchableOpacity>
              <TouchableOpacity
                style={[pageStyle.step4Item]}
                onPress={() => setYoutubeVideoId('lRAnoYb1vxA')}>
                <ImageBackground
                  resizeMode="cover"
                  imageStyle={{
                    resizeMode: 'cover',
                    alignSelf: 'center',
                  }}
                  source={require('../assets/images/pc_img_3.png')}
                  style={[
                    pageStyle.step4ItemContainer,
                    isTabletOrMobileDevice &&
                      pageStyle.step4ItemContainerMobile,
                  ]}></ImageBackground>
                <Text style={[pageStyle.step4ItemTxt]}>풀 영상</Text>
              </TouchableOpacity>
              <TouchableOpacity
                style={[pageStyle.step4Item]}
                onPress={() =>
                  Linking.openURL(
                    'https://www.youtube.com/channel/UCFh7NmzRNK3mtD6-oc4NwnQ',
                  )
                }>
                <View
                  style={[
                    pageStyle.step4ItemContainer,
                    isTabletOrMobileDevice &&
                      pageStyle.step4ItemContainerMobile,
                  ]}>
                  <Image
                    resizeMode="cover"
                    style={{width: 120, height: 80, alignSelf: 'center'}}
                    source={require('../assets/images/pc_img_5.png')}
                  />
                </View>
                <Text style={[pageStyle.step4ItemTxt]}>영상 더보기</Text>
              </TouchableOpacity>
            </View>
          </View>
          <View style={[pageStyle.footer]}>
            <View style={[styles.row]}>
              <TouchableOpacity onPress={() => navigation.navigate('Terms')}>
                <Text style={[pageStyle.termTxt]}>이용약관</Text>
              </TouchableOpacity>
              <Text style={[pageStyle.termTxt]}>|</Text>
              <TouchableOpacity onPress={() => navigation.navigate('Privacy')}>
                <Text style={[pageStyle.termTxt]}>개인정보처리방침</Text>
              </TouchableOpacity>
            </View>
            <View style={[styles.row]}>
              <TouchableOpacity
                onPress={() =>
                  Linking.openURL(
                    'https://www.instagram.com/uniqueplay_official',
                  )
                }
                style={[pageStyle.snsItem]}>
                <Image
                  style={[pageStyle.snsImg]}
                  source={require('../assets/images/icn_circle_instagram.png')}
                />
              </TouchableOpacity>
              <TouchableOpacity
                onPress={() =>
                  Linking.openURL(
                    'https://www.youtube.com/channel/UCFh7NmzRNK3mtD6-oc4NwnQ',
                  )
                }
                style={[pageStyle.snsItem]}>
                <Image
                  style={[pageStyle.snsImg]}
                  source={require('../assets/images/icn_circle_youtube.png')}
                />
              </TouchableOpacity>
              <TouchableOpacity
                onPress={() => Linking.openURL('http://pf.kakao.com/_usYyT')}
                style={[pageStyle.snsItem]}>
                <Image
                  style={[pageStyle.snsImg]}
                  source={require('../assets/images/icn_circle_kakao.png')}
                />
              </TouchableOpacity>
            </View>
            <View>
              <Text style={[pageStyle.footerTxt]}>
                2023 UniquePlay. All rights reserved. {'\n\n'}
                유니크플레이{'\n'}
                서울특별시 종로구 새문안로3길 36 용비어천가 1336호{'\n'}
                대표 : 최성일 | 개인정보관리책임자 : 최성일{'\n'}
                사업자등록번호 : 611-88-02887{'\n'}
                통신판매업신고 : 2023-서울종로-0192호{'\n'}
                문의메일 : support@unique-play.com{'\n'}
                문의전화 : 070-4739-5678
              </Text>
            </View>
          </View>
        </View>
      </ScrollView>

      {youtubeVideoId && (
        <Modal transparent={true} overFullScreen={true}>
          <TouchableOpacity
            onPress={() => setYoutubeVideoId(null)}
            style={[pageStyle.popupContainer]}>
            <YoutubePlayer
              apiKey="AIzaSyCWW2izAo6FdSIozEmuZUMzFeHB4SftJuM"
              showinfo={false}
              controls={1}
              ref={youtubeRef}
              videoId={youtubeVideoId} // The YouTube video ID
              play={true} // control playback of video with true/false
              fullscreen // control whether the video should play in fullscreen or inline
              height={!isTabletOrMobileDevice ? 529 : 400}
              width={
                !isTabletOrMobileDevice ? 940 : Dimensions.get('window').width
              }
              style={{
                alignSelf: 'stretch',
                width: !isTabletOrMobileDevice
                  ? 940
                  : Dimensions.get('window').width,
                height: !isTabletOrMobileDevice ? 529 : 400,
              }}
            />
          </TouchableOpacity>
        </Modal>
      )}
      <TouchableOpacity
        onPress={() => Linking.openURL('http://pf.kakao.com/_usYyT/chat')}
        style={[pageStyle.kakaoIcnContainer]}>
        <Image
          style={[pageStyle.kakaoIcn]}
          source={require('../assets/images/kakao_inquiry.png')}
        />
      </TouchableOpacity>
    </SafeAreaView>
  );
};

const pageStyle = StyleSheet.create({
  logoContainer: {
    marginStart: '20%',
  },
  logoContainerMobile: {
    marginStart: 24,
  },
  logoImg: {
    width: 175,
    height: 31,
    marginTop: 24,
  },
  step1: {
    width: '100%',
  },
  step1Bg: {
    width: '100%',
    alignContent: 'center',
    alignItems: 'center',
    flexDirection: 'flex-start',
    alignItems: 'flex-start',
  },
  formContainer: {
    backgroundColor: 'white',
    width: 550,
    borderRadius: 6,
    padding: 24,
    marginStart: '20%',
    marginTop: 24,
  },
  formContainerMobile: {
    width: '100%',
    marginStart: 0,
  },
  subtitle: {
    fontSize: 20,
    fontWeight: '500',
    marginTop: 16,
  },
  subtitleMobile: {
    fontSize: 16,
  },
  textInput: {
    height: 40,
    backgroundColor: 'white',
    borderWidth: 1,
    borderColor: '#aaa',
    paddingHorizontal: 12,
    paddingVertical: 6,
    marginTop: 6,
    fontSize: 18,
  },
  textInputMobile: {
    fontSize: 15,
  },
  dropDown: {
    width: '100%',
    height: 40,
    backgroundColor: 'white',
    borderWidth: 1,
    borderColor: '#aaa',
    paddingHorizontal: 12,
    paddingVertical: 6,
    marginTop: 6,
    fontSize: 18,
    textAlign: 'left',
  },
  dropDownMobile: {
    width: '100%',
    height: 40,
    backgroundColor: 'white',
    borderWidth: 1,
    borderColor: '#aaa',
    paddingHorizontal: 6,
    paddingVertical: 3,
    marginTop: 6,
    fontSize: 15,
    marginHorizontal: 2,
    textAlign: 'left',
  },
  dropDownStyle: {
    height: 328,
  },
  downDownText: {
    textAlign: 'left',
    fontSize: 18,
  },
  downDownTextMobile: {
    fontSize: 15,
    marginHorizontal: 2,
  },
  dropDownRowText: {
    textAlign: 'left',
    paddingVertical: 12,
    alignSelf: 'center',
    fontSize: 16,
    color: 'black',
  },
  dropdownArr: {
    width: 8,
    height: 9,
  },
  btn: {
    backgroundColor: '#3f51b5',
    width: '100%',
    paddingVertical: 8,
    borderRadius: 3,
    marginTop: 24,
  },
  btnTxt: {
    color: 'white',
    textAlign: 'center',
    fontSize: 16,
  },
  value: {
    fontSize: 18,
    fontWeight: '400',
  },
  valueMobile: {
    fontSize: 15,
    fontWeight: '400',
  },
  icnCheck: {
    width: 25,
    height: 25,
    marginHorizontal: 12,
  },
  step4: {
    paddingTop: 36,
    width: '100%',
    backgroundColor: 'white',
    marginBottom: 120,
  },
  step4Title: {
    color: '#1d3864',
    fontSize: 34,
    fontWeight: '700',
    alignSelf: 'center',
  },
  step4ValueContainer: {
    marginTop: 36,
    paddingBottom: 62,
    justifyContent: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
  },
  step4ValueContainerMobile: {
    flexDirection: 'column',
  },
  step4Item: {
    marginHorizontal: 8,
    borderWidth: 1,
    borderColor: '#ccc',
    marginTop: 20,
  },
  step4ItemContainer: {
    width: 260,
    height: 260,
    justifyContent: 'center',
  },
  step4ItemContainerMobile: {
    width: '100%',
    height: 250,
  },
  step4ItemInnerContainer: {
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  step4ItemTxt: {
    color: 'black',
    fontSize: 20,
    fontWeight: 500,
    textAlign: 'center',
    paddingVertical: 8,
  },
  step4TxtImg: {
    width: 138,
    height: 87,
  },
  footer: {
    paddingTop: 30,
    paddingBottom: 48,
    alignContent: 'center',
    alignItems: 'center',
    width: '100%',
    backgroundColor: 'white',
  },
  termTxt: {
    fontSize: 20,
    color: 'black',
    fontWeight: '600',
    textAlign: 'center',
    marginHorizontal: 8,
  },
  snsItem: {
    marginHorizontal: 8,
    marginTop: 12,
  },
  snsImg: {
    width: 54,
    height: 57,
  },
  footerTxt: {
    color: 'black',
    fontSize: 14,
    textAlign: 'center',
    lineHeight: 20,
    marginTop: 18,
  },
  popupContainer: {
    zIndex: 100,
    width: '100%',
    height: Dimensions.get('window').height,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    alignItems: 'center',
    alignContent: 'center',
    justifyContent: 'center',
  },
  kakaoIcnContainer: {
    position: 'fixed',
    bottom: 25,
    right: 25,
  },
  kakaoIcn: {
    width: 120,
    height: 50,
  },
});

export default WebApplyView;

import React, {useState} from 'react';
import {
  StyleSheet,
  View,
  Dimensions,
  Modal,
  Text,
  Image,
  TouchableOpacity,
  TextInput,
  Linking,
} from 'react-native';
import styles from '../common/styles';
import {useSafeAreaInsets} from 'react-native-safe-area-context';
import Colors from '../common/colors';

const VideoSelectionPopup = props => {
  const insets = useSafeAreaInsets();
  return (
    <Modal transparent={true} overFullScreen={true}>
      <TouchableOpacity
        style={[pageStyle.container]}
        onPress={props.closePopup}>
        <View style={[pageStyle.innerContainer, {marginTop: insets.top + 67}]}>
          <Image
            style={[pageStyle.logo]}
            resizeMode={'contain'}
            source={require('../assets/images/logo_colored.png')}
          />
          <View style={[pageStyle.buttonContainer]}>
            <TouchableOpacity
              disabled={!props.fullVideoUrl}
              onPress={() => Linking.openURL(props.fullVideoUrl)}
              style={[
                pageStyle.button,
                pageStyle.full,
                !props.fullVideoUrl && pageStyle.inactive,
              ]}>
              <Text style={[pageStyle.buttonText]}>풀영상</Text>
            </TouchableOpacity>
            <TouchableOpacity
              disabled={!props.hotClipVideoUrl}
              onPress={() => Linking.openURL(props.hotClipVideoUrl)}
              style={[
                pageStyle.button,
                pageStyle.clip,
                !props.hotClipVideoUrl && pageStyle.inactive,
              ]}>
              <Text style={[pageStyle.buttonText]}>핫클립</Text>
            </TouchableOpacity>
          </View>
        </View>
      </TouchableOpacity>
    </Modal>
  );
};

const pageStyle = StyleSheet.create({
  container: {
    zIndex: 100,
    width: '100%',
    height: Dimensions.get('window').height,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  innerContainer: {
    backgroundColor: 'white',
    width: 218,
    height: 65,
    marginHorizontal: Dimensions.get('window').width / 2 - 109,
    top: Dimensions.get('window').height / 2 - 150,
    textAlign: 'center',
    padding: 11,
    alignItems: 'center',
  },
  logo: {
    height: 14,
  },
  buttonContainer: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 10,
  },
  button: {
    height: 21,
    flex: 1,
    borderRadius: 10.5,
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
  },
  full: {
    marginEnd: 4,
    backgroundColor: Colors.main,
  },
  clip: {
    marginStart: 4,
    backgroundColor: 'rgb(110, 135, 215)',
  },
  buttonText: {
    color: 'white',
    fontFamily: 'Pretendard',
    fontSize: 11,
  },
  inactive: {
    backgroundColor: '#ccc',
  },
});

export default VideoSelectionPopup;

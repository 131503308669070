import React, {useState, useEffect, useRef, useMemo} from 'react';
import {
  Image,
  StyleSheet,
  FlatList,
  Text,
  View,
  TextInput,
  TouchableOpacity,
  Dimensions,
  ScrollView,
  Platform,
} from 'react-native';
import Colors from '../../common/colors';
import styles from '../../common/styles';
import SelectDropdown from 'react-native-select-dropdown';
import CategoryRecordItem from '../../component/CategoryRecordItem';
import RecordItem from '../RecordItem';
import TeamRecordItem from '../TeamRecordItem';
import AsyncStorage from '@react-native-async-storage/async-storage';
import api from '../../common/api';
import * as Progress from 'react-native-progress';

const LeagueRecord = ({navigation, item}) => {
  const [season, setSeason] = useState();
  const [seasonId, setSeasonId] = useState();
  const [subLeague, setSubLeague] = useState({});
  const [subLeagueId, setSubLeagueId] = useState();
  const [personTeamToggle, setPersonTeamToggle] = useState(0);
  const [pitcherBatterToggle, setPitcherBatterToggle] = useState(1);
  const [applyRegulatedIn, setApplyRegulatedIn] = useState(true);
  const [seasonList, setSeasonList] = useState();
  const [subLeagueList, setSubLeagueList] = useState([]);
  const [primaryRecordTable, setPrimaryRecordTable] = useState();
  const [rankingList, setRankingList] = useState([]);
  const [teamRankingList, setTeamRankingList] = useState([]);
  const [teamRankingType, setTeamRankingType] = useState();
  const [currentRank, setCurrentRank] = useState('');
  const [ruleInfo, setRuleInfo] = useState({});
  const [progressShown, setProgressShown] = useState(false);
  const [matchedValue, setMatchedValue] = useState();
  const [matchedIndex, setMatchedIndex] = useState();
  const accessToken = useRef();
  const currentOrderDir = useRef();
  const pageNo = useRef();
  const loading = useRef();
  const reachEnd = useRef();
  const lastRankingForTeam = useRef(1);
  const lastRankingForPersonal = useRef(1);
  const lastRankingValueForTeam = useRef();
  const lastRankingValueForPersonal = useRef();

  const [rankingTeamCategoryList, setRankingTeamCategoryList] = useState([
    {text: '게임수', orderBy: 'game', orderDir: 'DESC'},
    {text: '승', orderBy: 'win', orderDir: 'DESC'},
    {text: '패', orderBy: 'lose', orderDir: 'DESC'},
    {text: '무', orderBy: 'draw', orderDir: 'DESC'},
    {text: '승률', orderBy: 'wrate', orderDir: 'DESC'},
    {text: '승점', orderBy: 'point', orderDir: 'DESC'},
    {text: '게임차', orderBy: 'gameDiff', orderDir: 'ASC'},
  ]);
  const rankingCategoryList = [
    {type: 0, text: 'ERA', orderBy: 'era', orderDir: 'ASC'},
    {
      type: 0,
      text: '팀게임',
      orderBy: 'tgame',
      orderDir: 'DESC',
      excludeInTeam: true,
    },
    {type: 0, text: '선수게임', orderBy: 'pgame', orderDir: 'DESC'},
    {type: 0, text: '이닝', orderBy: 'innings', orderDir: 'DESC'},
    {type: 0, text: '승', orderBy: 'win', orderDir: 'DESC'},
    {type: 0, text: '패', orderBy: 'lose', orderDir: 'DESC'},
    {type: 0, text: '세이브', orderBy: 'save', orderDir: 'DESC'},
    {type: 0, text: '홀드', orderBy: 'hold', orderDir: 'DESC'},
    {type: 0, text: '삼진', orderBy: 'so', orderDir: 'DESC'},
    {type: 0, text: '피안타', orderBy: 'ph', orderDir: 'DESC'},
    {type: 0, text: '피홈런', orderBy: 'phr', orderDir: 'DESC'},
    {type: 0, text: '실점', orderBy: 'r', orderDir: 'DESC'},
    {type: 0, text: '볼넷', orderBy: 'pbb', orderDir: 'DESC'},
    {type: 0, text: '사구', orderBy: 'phitByPitch', orderDir: 'DESC'},
    {type: 0, text: '승률', orderBy: 'wrate', orderDir: 'DESC'},
    {type: 0, text: 'WHIP', orderBy: 'whip', orderDir: 'ASC'},
    {type: 1, text: '타율', orderBy: 'havg', orderDir: 'DESC'},
    {
      type: 1,
      text: '팀게임',
      orderBy: 'tgame',
      orderDir: 'DESC',
      excludeInTeam: true,
    },
    {type: 1, text: '선수게임', orderBy: 'hgame', orderDir: 'DESC'},
    {type: 1, text: '타석', orderBy: 'htb', orderDir: 'DESC'},
    {type: 1, text: '타수', orderBy: 'hab', orderDir: 'DESC'},
    {type: 1, text: '총안타', orderBy: 'h', orderDir: 'DESC'},
    {type: 1, text: '1루타', orderBy: 'h1', orderDir: 'DESC'},
    {type: 1, text: '2루타', orderBy: 'h2', orderDir: 'DESC'},
    {type: 1, text: '3루타', orderBy: 'h3', orderDir: 'DESC'},
    {type: 1, text: '홈런', orderBy: 'hr', orderDir: 'DESC'},
    {type: 1, text: '타점', orderBy: 'rbi', orderDir: 'DESC'},
    {type: 1, text: '득점', orderBy: 'her', orderDir: 'DESC'},
    {type: 1, text: '도루', orderBy: 'sb', orderDir: 'DESC'},
    {type: 1, text: '볼넷', orderBy: 'hbb', orderDir: 'DESC'},
    {type: 1, text: '삼진', orderBy: 'hso', orderDir: 'DESC'},
    {type: 1, text: '출루율', orderBy: 'obrate', orderDir: 'DESC'},
    {type: 1, text: '장타율', orderBy: 'srate', orderDir: 'DESC'},
    {type: 1, text: 'OPS', orderBy: 'ops', orderDir: 'DESC'},
  ];
  useEffect(() => {
    AsyncStorage.getItem('@accessToken', (err, result) => {
      accessToken.current = result;
      getData(result);
      getPrimaryRecordTable(result);
    });
  }, []);

  useEffect(() => {
    if (pitcherBatterToggle === 0) {
      getPrimaryRecordTable(accessToken.current);
      setPitcherBatterToggle(1);
    } else {
      getPrimaryRecordTable(accessToken.current);
      getPersonalRankingData(accessToken.current, 'havg', 'desc', true);
    }
  }, [seasonId, subLeagueId, applyRegulatedIn]);

  useEffect(() => {
    if (personTeamToggle === 0) {
      setSubLeagueList(subLeagueList.filter(i => i.id));
      if (!subLeagueId && subLeagueList.filter(i => i.id).length > 0) {
        const length = subLeagueList.filter(i => i.id).length;
        setSubLeagueId(subLeagueList.filter(i => i.id)[0].id);
        setSubLeague(subLeagueList.filter(i => i.id)[0]);
      }
      setTeamRankingType('GENERAL');
    } else {
      setSubLeagueList([
        {id: '', name: '전체조리그'},
        ...subLeagueList.filter(i => i.id),
      ]);
      if (pitcherBatterToggle === 0) {
        getPersonalRankingData(accessToken.current, 'era', 'asc', false);
      } else {
        getPersonalRankingData(accessToken.current, 'havg', 'desc', true);
      }
    }
  }, [personTeamToggle]);

  useEffect(() => {
    let recentCurrentRank = '';
    if (teamRankingType === 'GENERAL') {
      if (ruleInfo.rankingMethod === '승점제') {
        recentCurrentRank = 'point';
        currentOrderDir.value = 'DESC';
      } else if (ruleInfo.rankingMethod === '승률제') {
        recentCurrentRank = 'wrate';
        currentOrderDir.value = 'DESC';
      } else if (ruleInfo.rankingMethod === '다승제') {
        recentCurrentRank = 'win';
        currentOrderDir.value = 'DESC';
      } else if (ruleInfo.rankingMethod === '게임차') {
        recentCurrentRank = 'gameDiff';
        currentOrderDir.value = 'ASC';
      }
    }
    if (
      !accessToken.current ||
      !(recentCurrentRank ? recentCurrentRank : currentRank) ||
      !currentOrderDir.value
    ) {
      return;
    }
    console.log(teamRankingType, recentCurrentRank, currentRank);
    setCurrentRank(recentCurrentRank ? recentCurrentRank : currentRank);
    getTeamRankingData(
      accessToken.current,
      recentCurrentRank ? recentCurrentRank : currentRank,
      currentOrderDir.value,
    );
  }, [teamRankingType, ruleInfo]);

  useEffect(() => {
    if (personTeamToggle === 0) {
      return;
    }
    if (pitcherBatterToggle === 0) {
      getPersonalRankingData(accessToken.current, 'era', 'asc', false);
    } else {
      getPersonalRankingData(accessToken.current, 'havg', 'desc', true);
    }
  }, [personTeamToggle, pitcherBatterToggle]);

  useEffect(() => {
    if (!seasonId) {
      return;
    }
    getRule(accessToken.current);
  }, [seasonId]);

  useEffect(() => {
    // let recentCurrentRank = '';
    // if (ruleInfo.rankingMethod === '승점제') {
    //   recentCurrentRank = 'point';
    //   currentOrderDir.value = 'DESC';
    // } else if (ruleInfo.rankingMethod === '승률제') {
    //   recentCurrentRank = 'wrate';
    //   currentOrderDir.value = 'DESC';
    // } else if (ruleInfo.rankingMethod === '다승제') {
    //   recentCurrentRank = 'win';
    //   currentOrderDir.value = 'DESC';
    // } else if (ruleInfo.rankingMethod === '게임차') {
    //   recentCurrentRank = 'gameDiff';
    //   currentOrderDir.value = 'ASC';
    // }
    // setCurrentRank(recentCurrentRank);
    setTeamRankingType('GENERAL');

    let recentCurrentRank = '';
    if (ruleInfo.rankingMethod === '승점제') {
      recentCurrentRank = 'point';
      currentOrderDir.value = 'DESC';
    } else if (ruleInfo.rankingMethod === '승률제') {
      recentCurrentRank = 'wrate';
      currentOrderDir.value = 'DESC';
    } else if (ruleInfo.rankingMethod === '다승제') {
      recentCurrentRank = 'win';
      currentOrderDir.value = 'DESC';
    } else if (ruleInfo.rankingMethod === '게임차') {
      recentCurrentRank = 'gameDiff';
      currentOrderDir.value = 'ASC';
    }
    getTeamRankingData(
      accessToken.current,
      recentCurrentRank ? recentCurrentRank : currentRank,
      currentOrderDir.value,
    );
  }, [subLeagueId]);

  const getPrimaryRecordTable = token => {
    if (!token) {
      return;
    }
    api
      .get(
        `games/records/primary?leagueId=${item.id}&subLeagueId=${
          subLeagueId ? subLeagueId : ''
        }&seasonId=${seasonId ? seasonId : ''}&regulatedIn=${applyRegulatedIn}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then(function (response) {
        setPrimaryRecordTable(response.data);
      })
      .catch(function (error) {
        console.log(error.response);
        // alert('요청에 실패했습니다.');
      });
  };

  const getRule = token => {
    if (!token) {
      return;
    }
    api
      .get(`rules?seasonId=${seasonId ? seasonId : ''}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(function (response) {
        setRuleInfo(response.data);
        if (response.data.rankingMethod === '다승제') {
          setRankingTeamCategoryList([
            {text: '게임수', orderBy: 'game', orderDir: 'DESC'},
            {text: '승', orderBy: 'win', orderDir: 'DESC'},
            {text: '패', orderBy: 'lose', orderDir: 'DESC'},
            {text: '무', orderBy: 'draw', orderDir: 'DESC'},
            {text: '승률', orderBy: 'wrate', orderDir: 'DESC'},
            {text: '승점', orderBy: 'point', orderDir: 'DESC'},
            {text: '게임차', orderBy: 'gameDiff', orderDir: 'ASC'},
          ]);
        } else if (response.data.rankingMethod === '승률제') {
          setRankingTeamCategoryList([
            {text: '게임수', orderBy: 'game', orderDir: 'DESC'},
            {text: '승률', orderBy: 'wrate', orderDir: 'DESC'},
            {text: '승', orderBy: 'win', orderDir: 'DESC'},
            {text: '패', orderBy: 'lose', orderDir: 'DESC'},
            {text: '무', orderBy: 'draw', orderDir: 'DESC'},
            {text: '승점', orderBy: 'point', orderDir: 'DESC'},
            {text: '게임차', orderBy: 'gameDiff', orderDir: 'ASC'},
          ]);
        } else if (response.data.rankingMethod === '승점제') {
          setRankingTeamCategoryList([
            {text: '게임수', orderBy: 'game', orderDir: 'DESC'},
            {text: '승점', orderBy: 'point', orderDir: 'DESC'},
            {text: '승', orderBy: 'win', orderDir: 'DESC'},
            {text: '패', orderBy: 'lose', orderDir: 'DESC'},
            {text: '무', orderBy: 'draw', orderDir: 'DESC'},
            {text: '승률', orderBy: 'wrate', orderDir: 'DESC'},
            {text: '게임차', orderBy: 'gameDiff', orderDir: 'ASC'},
          ]);
        } else if (response.data.rankingMethod === '게임차') {
          setRankingTeamCategoryList([
            {text: '게임수', orderBy: 'game', orderDir: 'DESC'},
            {text: '게임차', orderBy: 'gameDiff', orderDir: 'ASC'},
            {text: '승', orderBy: 'win', orderDir: 'DESC'},
            {text: '패', orderBy: 'lose', orderDir: 'DESC'},
            {text: '무', orderBy: 'draw', orderDir: 'DESC'},
            {text: '승률', orderBy: 'wrate', orderDir: 'DESC'},
            {text: '승점', orderBy: 'point', orderDir: 'DESC'},
          ]);
        }
      })
      .catch(function (error) {
        console.log(error.response);
        // alert('요청에 실패했습니다.');
      });
  };

  const getTeamRankingData = (token, orderBy, orderDir, loadMore) => {
    if (!token) {
      return;
    }
    console.log('orderBy', orderBy);
    api
      .get(
        `games/records/team-ranking?leagueId=${item.id}&orderBy=${
          orderBy ? orderBy : ''
        }&orderDir=${orderDir}&subLeagueId=${
          subLeagueId ? subLeagueId : ''
        }&seasonId=${seasonId ? seasonId : ''}&regulatedIn=${applyRegulatedIn}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then(function (response) {
        setCurrentRank(orderBy);

        for (let i = 0; i < response.data.length; i++) {
          if (lastRankingValueForTeam.value === response.data[i][orderBy]) {
            response.data[i].ranking = lastRankingForTeam.value;
          } else {
            // response.data[i].ranking =
            //   i + 1 + (pageNo.value ? pageNo.value * 20 : 0 * 20);
            // lastRanking.value =
            //   i + 1 + (pageNo.value ? pageNo.value * 20 : 0 * 20);
            response.data[i].ranking = i + 1 + 0 * 20;
            lastRankingForTeam.value = i + 1 + 0 * 20;
          }
          lastRankingValueForTeam.value = response.data[i][orderBy];
        }
        if (loadMore) {
          setTeamRankingList([...teamRankingList, ...response.data]);
        } else {
          setTeamRankingList(response.data);
        }
      })
      .catch(function (error) {
        console.log(error.response);
        // alert('요청에 실패했습니다.');
      });
  };

  const getPersonalRankingData = (
    token,
    orderBy,
    orderDir,
    batter,
    loadMore,
  ) => {
    if (!token) {
      return;
    }
    if (loading.value) {
      return;
    }
    if (loadMore) {
      pageNo.value++;
    } else {
      setRankingList([]);
      reachEnd.value = false;
      pageNo.value = 0;
      lastRankingForPersonal.value = 1;
      lastRankingValueForPersonal.value = null;
    }
    if (reachEnd.value) {
      return;
    }
    loading.value = true;
    setProgressShown(true);
    currentOrderDir.value = orderDir;
    api
      .get(
        `games/records/ranking?leagueId=${
          item.id
        }&orderBy=${orderBy}&orderDir=${orderDir}&subLeagueId=${
          subLeagueId ? subLeagueId : ''
        }&seasonId=${
          seasonId ? seasonId : ''
        }&regulatedIn=${applyRegulatedIn}&pageNo=${
          loadMore ? pageNo.value : 0
        }&batter=${batter ? batter : false}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then(function (response) {
        setCurrentRank(orderBy);
        if (!response.data || response.data.length === 0) {
          reachEnd.value = true;
          return;
        }
        for (let i = 0; i < response.data.length; i++) {
          if (personTeamToggle === 1) {
            if (
              lastRankingValueForPersonal.value === response.data[i][orderBy]
            ) {
              response.data[i].ranking = lastRankingForPersonal.value;
            } else {
              response.data[i].ranking =
                i + 1 + (pageNo.value ? pageNo.value * 20 : 0 * 20);
              lastRankingForPersonal.value =
                i + 1 + (pageNo.value ? pageNo.value * 20 : 0 * 20);
            }
            lastRankingValueForPersonal.value = response.data[i][orderBy];
          } else if (personTeamToggle === 0) {
            if (lastRankingValueForTeam.value === response.data[i][orderBy]) {
              response.data[i].ranking = lastRankingForTeam.value;
            } else {
              response.data[i].ranking =
                i + 1 + (pageNo.value ? pageNo.value * 20 : 0 * 20);
              lastRankingForTeam.value =
                i + 1 + (pageNo.value ? pageNo.value * 20 : 0 * 20);
            }
            lastRankingValueForTeam.value = response.data[i][orderBy];
          }
        }
        if (loadMore) {
          setRankingList([...rankingList, ...response.data]);
        } else {
          setRankingList(response.data);
        }

        setMatchedValue();
        setMatchedIndex();
      })
      .catch(function (error) {
        console.log(error.response);
        // alert('요청에 실패했습니다.');
      })
      .finally(() => {
        loading.value = false;
        setProgressShown(false);
      });
  };

  const getData = token => {
    api
      .get(`leagues/${item.id}/seasons`, {
        headers: {
          Authorization: `Bearer ${accessToken.current}`,
        },
      })
      .then(function (response) {
        setSeasonList(response.data);
        if (response.data && response.data.length > 0) {
          let seasonId = null;
          if (response.data.filter(i => i.active).length === 0) {
            seasonId = response.data[response.data.length - 1].id;
            setSeasonId(seasonId);
            setSeason(response.data[response.data.length - 1]);
          } else {
            seasonId = response.data.filter(i => i.active)[0].id;
            setSeasonId(seasonId);
            setSeason(response.data.filter(i => i.active)[0]);
          }
          getSubLeagues(seasonId);
        }
      })
      .catch(function (error) {
        console.log(error.response);
        // alert('요청에 실패했습니다.');
      });
  };
  const getSubLeagues = seasonId => {
    api
      .get(`sub-leagues?seasonId=${seasonId}`, {
        headers: {
          Authorization: `Bearer ${accessToken.current}`,
        },
      })
      .then(function (response) {
        setSubLeagueList(response.data);
        if (response.data && response.data.length > 0) {
          setSubLeague(response.data[0]);
          setSubLeagueId(response.data[0].id);
        }
      })
      .catch(function (error) {
        console.log(error.response);
        // alert('요청에 실패했습니다.');
      });
  };

  const getProfileImageUrl = item => {
    if (item.user?.profileImageThumbUrl) {
      return {uri: item.user?.profileImageThumbUrl};
    }
    if (item.user?.profileImageUrl) {
      return {uri: item.user?.profileImageUrl};
    }
    return require('../../assets/images/icn_profile_img.png');
  };

  const getLogoImageUrl = item => {
    if (item?.logoImageThumbUrl) {
      return {uri: item?.logoImageThumbUrl};
    }
    if (item?.logoImageUrl) {
      return {uri: item?.logoImageUrl};
    }
    return require('../../assets/images/nologo.png');
  };

  const scrollRef = useRef();

  const goToRow = value => {
    if (personTeamToggle === 1) {
      const matched = rankingList.filter(i => i.user.name === value);
      let matchedIndex = 0;
      for (let i = 0; i < rankingList.length; i++) {
        if (value === rankingList[i].user.name) {
          matchedIndex = i;
        }
      }
      if (!matched || matched.length === 0) {
        setMatchedValue('');
        setMatchedIndex('');
        return;
      }
      setMatchedValue(value);
      setMatchedIndex(matchedIndex);
      scrollRef.current?.scrollTo({y: 300 + matchedIndex * 30});
    } else {
      const matched = teamRankingList.filter(i => i.team.name === value);
      let matchedIndex = 0;
      for (let i = 0; i < teamRankingList.length; i++) {
        if (value === teamRankingList[i].team.name) {
          matchedIndex = i;
        }
      }
      if (!matched || matched.length === 0) {
        setMatchedValue('');
        setMatchedIndex('');
        return;
      }
      setMatchedValue(value);
      setMatchedIndex(matchedIndex);
      scrollRef.current?.scrollTo({y: 300 + matchedIndex * 30});
    }
  };

  return (
    <ScrollView
      ref={scrollRef}
      scrollEventThrottle={100}
      style={[
        Platform.OS === 'web' && {
          height: Dimensions.get('window').height - 100,
        },
      ]}
      onScroll={e => {
        let paddingToBottom = 1;
        paddingToBottom += e.nativeEvent.layoutMeasurement.height;
        // console.log(Math.floor(paddingToBottom) + "-" + Math.floor(e.nativeEvent.contentOffset.y) + "-" + Math.floor(e.nativeEvent.contentSize.height));
        if (
          e.nativeEvent.contentOffset.y + paddingToBottom >=
          e.nativeEvent.contentSize.height
        ) {
          getPersonalRankingData(
            accessToken.current,
            currentRank,
            currentOrderDir.value,
            pitcherBatterToggle === 1,
            true,
          );
        }
      }}>
      <View style={[pageStyle.innerContainer]}>
        <View>
          <View style={[pageStyle.dropdownRowContainer]}>
            <View style={[pageStyle.dropDownItemContainer]}>
              <SelectDropdown
                defaultButtonText="시즌선택"
                defaultValue={season}
                buttonStyle={pageStyle.dropDown}
                buttonTextStyle={pageStyle.downDownText}
                rowTextStyle={styles.dropDownRowText}
                renderDropdownIcon={() => (
                  <Image
                    source={require('../../assets/images/select_arr.png')}
                  />
                )}
                data={seasonList}
                onSelect={(selectedItem, index) => {
                  setSeasonId(selectedItem.id);
                  getSubLeagues(selectedItem.id);
                }}
                buttonTextAfterSelection={(selectedItem, index) => {
                  return `${selectedItem.year}시즌`;
                }}
                rowTextForSelection={(item, index) => {
                  return `${item.year}시즌`;
                }}
              />
            </View>
            <View style={[pageStyle.dropDownItemContainer]}>
              <SelectDropdown
                defaultButtonText="조리그분류"
                defaultValue={subLeague}
                buttonStyle={pageStyle.dropDown}
                buttonTextStyle={pageStyle.downDownText}
                rowTextStyle={styles.dropDownRowText}
                renderDropdownIcon={() => (
                  <Image
                    source={require('../../assets/images/select_arr.png')}
                  />
                )}
                data={subLeagueList}
                onSelect={(selectedItem, index) => {
                  setSubLeagueId(selectedItem.id);
                }}
                buttonTextAfterSelection={(selectedItem, index) => {
                  return `${selectedItem.name}`;
                }}
                rowTextForSelection={(item, index) => {
                  return `${item.name}`;
                }}
              />
            </View>
          </View>
          <View style={[styles.row, pageStyle.filterContainer]}>
            <View style={[]}>
              <View style={[pageStyle.tabSelectorContainer, {marginTop: 0}]}>
                <TouchableOpacity onPress={() => setPersonTeamToggle(0)}>
                  <Text
                    style={[
                      pageStyle.tabItem,
                      personTeamToggle === 0 && pageStyle.tabItemSelected,
                    ]}>
                    팀순위
                  </Text>
                </TouchableOpacity>
                <Text style={[pageStyle.tabItem, pageStyle.divider]}>|</Text>
                <TouchableOpacity onPress={() => setPersonTeamToggle(1)}>
                  <Text
                    style={[
                      pageStyle.tabItem,
                      personTeamToggle === 1 && pageStyle.tabItemSelected,
                    ]}>
                    개인순위
                  </Text>
                </TouchableOpacity>
              </View>
            </View>
            <View style={[styles.col, pageStyle.searchInputContainer]}>
              <TextInput
                style={[pageStyle.searchInput]}
                placeholder={personTeamToggle === 0 ? '팀 검색' : '선수 검색'}
                placeholderTextColor={'#aaa'}
                onChangeText={value => goToRow(value)}
              />
              <Image
                style={[pageStyle.searchIcon]}
                source={require('../../assets/images/icn_search.png')}
              />
            </View>
          </View>
        </View>
        {personTeamToggle === 0 && (
          <>
            <View style={[pageStyle.tabSelectorContainer]}>
              <TouchableOpacity
                style={[
                  teamRankingType === 'GENERAL' && pageStyle.buttonActive,
                  teamRankingType !== 'GENERAL' && pageStyle.buttonInactive,
                ]}
                onPress={() => {
                  setTeamRankingType('GENERAL');
                }}>
                <Text
                  style={[
                    pageStyle.tabItem,
                    pageStyle.buttonText,
                    teamRankingType === 'GENERAL' &&
                      pageStyle.buttonTextSelected,
                  ]}>
                  팀 순위
                </Text>
              </TouchableOpacity>
              <Text style={[pageStyle.tabItem, pageStyle.divider]}>|</Text>
              <TouchableOpacity
                style={[
                  teamRankingType === 'OFFENSE' && pageStyle.buttonActive,
                  teamRankingType !== 'OFFENSE' && pageStyle.buttonInactive,
                ]}
                onPress={() => {
                  setCurrentRank('havg');
                  currentOrderDir.value = 'DESC';
                  setTeamRankingType('OFFENSE');
                }}>
                <Text
                  style={[
                    pageStyle.tabItem,
                    pageStyle.buttonText,
                    teamRankingType === 'OFFENSE' &&
                      pageStyle.buttonTextSelected,
                  ]}>
                  팀 공격 순위
                </Text>
              </TouchableOpacity>
              <Text style={[pageStyle.tabItem, pageStyle.divider]}>|</Text>
              <TouchableOpacity
                style={[
                  teamRankingType === 'DEFENSE' && pageStyle.buttonActive,
                  teamRankingType !== 'DEFENSE' && pageStyle.buttonInactive,
                ]}
                onPress={() => {
                  setCurrentRank('era');
                  currentOrderDir.value = 'ASC';
                  setTeamRankingType('DEFENSE');
                }}>
                <Text
                  style={[
                    pageStyle.tabItem,
                    pageStyle.buttonText,
                    teamRankingType === 'DEFENSE' &&
                      pageStyle.buttonTextSelected,
                  ]}>
                  팀 수비 순위
                </Text>
              </TouchableOpacity>
            </View>
            <View style={[{flexDirection: 'row'}, pageStyle.tableHeader]}>
              <View style={[]}>
                <View style={[{height: 25}]}>
                  <Text
                    style={{
                      fontSize: 14,
                      color: Colors.main,
                      paddingStart: 20,
                    }}>
                    규정: {ruleInfo.rankingMethod}
                  </Text>
                </View>
                {teamRankingList.map((item, index) => {
                  return (
                    <View style={[pageStyle.rowContainer]}>
                      <Text style={[pageStyle.rank]}>{item.ranking}</Text>
                      <View style={[pageStyle.td]}>
                        <Image
                          style={[
                            {
                              width: 30,
                              height: 30,
                              resizeMode: 'cover',
                              borderRadius: 15,
                            },
                          ]}
                          source={getLogoImageUrl(item)}
                        />
                      </View>
                      <TouchableOpacity
                        style={{
                          textAlign: 'center',
                          alignItems: 'center',
                          justifyContent: 'center',
                          alignSelf: 'center',
                        }}
                        onPress={() =>
                          navigation.navigate('TeamDetail', {
                            item: item.team,
                            itemId: item.team?.id,
                          })
                        }>
                        <Text style={[pageStyle.td2]} numberOfLines={1}>
                          {item.team.name}
                        </Text>
                      </TouchableOpacity>
                    </View>
                  );
                })}
              </View>
              <ScrollView horizontal={true}>
                <View>
                  <View style={[styles.row, {height: 25}]}>
                    {teamRankingType === 'GENERAL' &&
                      rankingTeamCategoryList.map(item => {
                        return (
                          <TouchableOpacity
                            onPress={() =>
                              getTeamRankingData(
                                accessToken.current,
                                item.orderBy,
                                item.orderDir,
                              )
                            }>
                            <Text
                              style={[
                                pageStyle.th,
                                currentRank === item.orderBy && pageStyle.blue,
                              ]}
                              numberOfLines={1}>
                              {item.text} ▼
                            </Text>
                          </TouchableOpacity>
                        );
                      })}
                    {rankingCategoryList
                      .filter(i => !i.excludeInTeam)
                      .map(item => {
                        return (
                          ((teamRankingType === 'OFFENSE' && item.type === 1) ||
                            (teamRankingType === 'DEFENSE' &&
                              item.type === 0)) && (
                            <TouchableOpacity
                              onPress={() =>
                                getTeamRankingData(
                                  accessToken.current,
                                  item.orderBy,
                                  item.orderDir,
                                )
                              }>
                              <Text
                                numberOfLines={1}
                                style={[
                                  pageStyle.th,
                                  currentRank === item.orderBy &&
                                    pageStyle.blue,
                                ]}>
                                {personTeamToggle === 0 &&
                                item.text === '선수게임'
                                  ? '게임수'
                                  : item.text}{' '}
                                ▼
                              </Text>
                            </TouchableOpacity>
                          )
                        );
                      })}
                  </View>
                  {teamRankingList.map((item, index) => {
                    return (
                      <TeamRecordItem
                        teamRankingType={teamRankingType}
                        rankingCategoryList={rankingCategoryList}
                        rankingTeamCategoryList={rankingTeamCategoryList}
                        data={item}
                        index={index}
                        currentRank={currentRank}
                      />
                    );
                  })}
                </View>
              </ScrollView>
              <FlatList
                showsHorizontalScrollIndicator={Platform.OS === 'web'}
                showsVerticalScrollIndicator={false}
                onScrollToIndexFailed={() => {}}
                horizontal={true}
                ListHeaderComponent={
                  <View style={[styles.row, pageStyle.tableHeader]}>
                    <View style={[{width: 35}]} />
                    <View style={[pageStyle.th]} />
                    <View style={[pageStyle.th2]} />
                    {rankingCategoryList.map(item => {
                      return (
                        pitcherBatterToggle === item.type && (
                          <TouchableOpacity
                            onPress={() =>
                              getPersonalRankingData(
                                accessToken.current,
                                item.orderBy,
                                item.orderDir,
                                item.type === 1,
                              )
                            }>
                            <Text
                              style={[
                                pageStyle.th,
                                currentRank === item.orderBy && pageStyle.blue,
                              ]}>
                              {item.text} ▼
                            </Text>
                          </TouchableOpacity>
                        )
                      );
                    })}
                  </View>
                }
                style={[{flex: 1}]}
                keyExtractor={item => `rank_type2_${item.id.toString()}`}
                data={rankingList}
                renderItem={({item, index}) => (
                  <TouchableOpacity onPress={() => setMatchedIndex(index)}>
                    <RecordItem
                      data={item}
                      index={index}
                      pitcherBatterToggle={pitcherBatterToggle}
                      showIndicator={matchedIndex === index}
                    />
                  </TouchableOpacity>
                )}
              />
            </View>
            {(!teamRankingList || teamRankingList.length === 0) && (
              <View style={[styles.emptyContainer]}>
                <Image source={require('../../assets/images/logo2.png')} />
              </View>
            )}
          </>
        )}
        {personTeamToggle === 1 && (
          <>
            {Platform.OS === 'web' && (
              <ScrollView
                horizontal={true}
                style={[pageStyle.rankBg, {paddingHorizontal: 25}]}
                showsHorizontalScrollIndicator={Platform.OS === 'web'}>
                {primaryRecordTable?.map(item => (
                  <CategoryRecordItem data={item} navigation={navigation} />
                ))}
              </ScrollView>
            )}
            {Platform.OS !== 'web' && (
              <FlatList
                showsHorizontalScrollIndicator={Platform.OS === 'web'}
                showsVerticalScrollIndicator={false}
                ListHeaderComponent={<View style={[pageStyle.rankPadding]} />}
                horizontal={true}
                style={[pageStyle.rankBg]}
                keyExtractor={item => `rank_${item.category.toString()}`}
                data={primaryRecordTable}
                renderItem={({item}) => (
                  <CategoryRecordItem data={item} navigation={navigation} />
                )}
                ListEmptyComponent={() => (
                  <View style={[styles.emptyContainer]}>
                    <Image source={require('../../assets/images/logo2.png')} />
                  </View>
                )}
              />
            )}
            <View style={[pageStyle.tabSelectorContainer]}>
              <TouchableOpacity
                style={[
                  applyRegulatedIn && pageStyle.buttonActive,
                  !applyRegulatedIn && pageStyle.buttonInactive,
                ]}
                onPress={() => setApplyRegulatedIn(true)}>
                <Text
                  style={[
                    pageStyle.tabItem,
                    pageStyle.buttonText,
                    applyRegulatedIn && pageStyle.buttonTextSelected,
                  ]}>
                  규정 IN
                </Text>
              </TouchableOpacity>
              <Text style={[pageStyle.tabItem, pageStyle.divider]}>|</Text>
              <TouchableOpacity
                style={[
                  !applyRegulatedIn && pageStyle.buttonActive,
                  applyRegulatedIn && pageStyle.buttonInactive,
                ]}
                onPress={() => setApplyRegulatedIn(false)}>
                <Text
                  style={[
                    pageStyle.tabItem,
                    pageStyle.buttonText,
                    !applyRegulatedIn && pageStyle.buttonTextSelected,
                  ]}>
                  규정 OUT
                </Text>
              </TouchableOpacity>
            </View>
            <View
              style={[
                {
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                },
              ]}>
              <View style={[pageStyle.tabSelectorContainer]}>
                <TouchableOpacity onPress={() => setPitcherBatterToggle(1)}>
                  <Text
                    style={[
                      pageStyle.tabItem,
                      pitcherBatterToggle === 1 && pageStyle.tabItemSelected,
                    ]}>
                    타자순위
                  </Text>
                </TouchableOpacity>
                <Text style={[pageStyle.tabItem, pageStyle.divider]}>|</Text>
                <TouchableOpacity onPress={() => setPitcherBatterToggle(0)}>
                  <Text
                    style={[
                      pageStyle.tabItem,
                      pitcherBatterToggle === 0 && pageStyle.tabItemSelected,
                    ]}>
                    투수순위
                  </Text>
                </TouchableOpacity>
              </View>
              <Text
                style={[
                  pageStyle.tabItem,
                  {fontSize: 12, marginTop: 12, marginEnd: 12},
                ]}>
                규정이닝{' '}
                <Text
                  style={[
                    pageStyle.tabItem,
                    {fontSize: 12, marginTop: 12, marginEnd: 12, color: 'red'},
                  ]}>
                  {ruleInfo?.minimumInnings}
                </Text>{' '}
                / 규정타석{' '}
                <Text
                  style={[
                    pageStyle.tabItem,
                    {fontSize: 12, marginTop: 12, marginEnd: 12, color: 'red'},
                  ]}>
                  {ruleInfo?.minimumAtBat}
                </Text>
              </Text>
            </View>
            <View style={[{flexDirection: 'row'}, pageStyle.tableHeader]}>
              <View style={[{}]}>
                <View style={[{height: 25}]} />
                {rankingList.map((item, index) => {
                  return (
                    <TouchableOpacity
                      onPress={() =>
                        navigation.navigate('MemberPage', {
                          item: item.user,
                          itemId: item.user.uid,
                        })
                      }
                      style={[pageStyle.rowContainer]}>
                      <Text style={[pageStyle.rank]}>{item.ranking}</Text>
                      <View style={[pageStyle.td]}>
                        <Image
                          style={[
                            {
                              width: 30,
                              height: 30,
                              resizeMode: 'cover',
                              borderRadius: 15,
                            },
                          ]}
                          source={getProfileImageUrl(item)}
                        />
                      </View>
                      <View style={[pageStyle.td2]}>
                        <Text numberOfLines={1} style={{color: 'black'}}>
                          {item.user.name}
                        </Text>
                        <Text
                          numberOfLines={1}
                          style={{
                            color: 'black',
                            fontSize: 12,
                            marginTop: 2,
                          }}>
                          {item.teamNames}
                        </Text>
                      </View>
                    </TouchableOpacity>
                  );
                })}
              </View>
              <ScrollView
                horizontal={true}
                showsHorizontalScrollIndicator={Platform.OS === 'web'}>
                <View>
                  <View style={[styles.row, {height: 25}]}>
                    {rankingCategoryList.map(item => {
                      return (
                        pitcherBatterToggle === item.type && (
                          <TouchableOpacity
                            onPress={() =>
                              getPersonalRankingData(
                                accessToken.current,
                                item.orderBy,
                                item.orderDir,
                                item.type === 1,
                              )
                            }>
                            <Text
                              style={[
                                pageStyle.th,
                                currentRank === item.orderBy && pageStyle.blue,
                              ]}>
                              {item.text} ▼
                            </Text>
                          </TouchableOpacity>
                        )
                      );
                    })}
                  </View>
                  {rankingList.map((item, index) => {
                    return (
                      <TouchableOpacity onPress={() => setMatchedIndex(index)}>
                        <RecordItem
                          data={item}
                          index={index}
                          currentRank={currentRank}
                          pitcherBatterToggle={pitcherBatterToggle}
                          showIndicator={matchedIndex === index}
                        />
                      </TouchableOpacity>
                    );
                  })}
                </View>
              </ScrollView>
              <FlatList
                showsHorizontalScrollIndicator={Platform.OS === 'web'}
                showsVerticalScrollIndicator={false}
                onScrollToIndexFailed={() => {}}
                horizontal={true}
                ListHeaderComponent={
                  <View style={[styles.row, pageStyle.tableHeader]}>
                    <View style={[{width: 35}]} />
                    <View style={[pageStyle.th]} />
                    <View style={[pageStyle.th2]} />
                    {rankingCategoryList.map(item => {
                      return (
                        pitcherBatterToggle === item.type && (
                          <TouchableOpacity
                            onPress={() =>
                              getPersonalRankingData(
                                accessToken.current,
                                item.orderBy,
                                item.orderDir,
                                item.type === 1,
                              )
                            }>
                            <Text
                              style={[
                                pageStyle.th,
                                currentRank === item.orderBy && pageStyle.blue,
                              ]}>
                              {item.text} ▼
                            </Text>
                          </TouchableOpacity>
                        )
                      );
                    })}
                  </View>
                }
                style={[{flex: 1}]}
                keyExtractor={item => `rank_type2_${item.id.toString()}`}
                data={rankingList}
                renderItem={({item, index}) => (
                  <TouchableOpacity onPress={() => setMatchedIndex(index)}>
                    <RecordItem
                      data={item}
                      index={index}
                      pitcherBatterToggle={pitcherBatterToggle}
                      showIndicator={matchedIndex === index}
                    />
                  </TouchableOpacity>
                )}
                ListEmptyComponent={() => (
                  <View style={[styles.emptyContainer]}>
                    <Image source={require('../../assets/images/logo2.png')} />
                  </View>
                )}
              />
            </View>
            {(!rankingList || rankingList.length === 0) && (
              <View style={[styles.emptyContainer]}>
                <Image source={require('../../assets/images/logo2.png')} />
              </View>
            )}
          </>
        )}
      </View>
      {progressShown && (
        <View style={[styles.progressContainer]}>
          <Progress.Circle
            fill="transparent"
            size={70}
            borderWidth={3}
            indeterminate={true}
          />
        </View>
      )}
    </ScrollView>
  );
};

export default LeagueRecord;

const pageStyle = StyleSheet.create({
  tableHeader: {
    marginTop: 19,
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  innerContainer: {
    flex: 1,
    paddingBottom: 120,
  },
  filterContainer: {
    marginTop: 22,
    marginHorizontal: 1,
    alignItems: 'center',
  },
  subtitle: {
    marginTop: 13,
    color: Colors.main,
    fontWeight: '500',
    marginHorizontal: 30,
  },
  searchInput: {
    borderWidth: 1,
    borderColor: '#ccc',
    borderRadius: 13,
    fontSize: 12,
    height: 30,
    padding: 0,
    paddingEnd: 4.5,
    color: 'black',
    textAlign: 'left',
    paddingStart: 35,
  },
  searchInputContainer: {
    position: 'relative',
    marginEnd: 25,
  },
  searchIcon: {
    position: 'absolute',
    top: 6,
    left: 8,
    width: 17,
    height: 17,
  },
  keyworkInput: {},
  th: {
    width: 47,
    textAlign: 'center',
    fontSize: 9.5,
    fontWeight: '500',
    color: 'black',
    justifyContent: 'center',
    alignItems: 'center',
  },
  th2: {
    width: 65,
    textAlign: 'center',
    fontSize: 10,
    fontWeight: '500',
    color: 'black',
    justifyContent: 'center',
    alignItems: 'center',
  },
  dropdownRowContainer: {
    display: 'flex',
    marginTop: 6,
    flexDirection: 'row',
    marginHorizontal: 23,
  },
  dropDownItemContainer: {
    marginHorizontal: 3,
    flex: 1,
  },
  dropDown: {
    width: '100%',
    backgroundColor: 'white',
    borderWidth: 1,
    borderColor: 'rgb(61, 80, 183)',
    height: 30,
  },
  downDownText: {
    color: 'black',
    fontSize: 11,
  },
  rankBg: {
    backgroundColor: 'rgb(236, 240, 255)',
    marginTop: 17,
  },
  rankPadding: {width: 25},
  tabSelectorContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 12,
    marginHorizontal: 30,
    alignItems: 'center',
  },
  tabItemSelected: {
    color: Colors.main,
    fontWeight: '500',
  },
  tabItem: {
    color: '#9fa0a0',
    fontWeight: '300',
  },
  buttonText: {
    color: Colors.main,
  },
  buttonTextSelected: {
    color: 'white',
  },
  buttonActive: {
    backgroundColor: Colors.main,
    borderRadius: 20,
    height: 25,
    flex: 1,
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
  },
  buttonInactive: {
    borderColor: Colors.main,
    borderWidth: 1,
    backgroundColor: 'white',
    borderRadius: 20,
    height: 25,
    flex: 1,
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
  },
  divider: {
    color: '#aaa',
    marginHorizontal: 8,
  },
  blue: {color: Colors.main, fontWeight: 'bold'},
  rowContainer: {
    flexDirection: 'row',
    paddingVertical: 7,
    height: 45,
    borderBottomWidth: 1,
    borderBottomColor: 'rgba(0, 0, 0, 0.1)',
  },
  rank: {
    width: 40,
    paddingStart: 10,
    alignItems: 'center',
    justifyContent: 'center',
    alignContent: 'center',
    color: 'black',
    alignSelf: 'center',
    textAlign: 'center',
  },
  td: {
    width: 45,
    textAlign: 'center',
    color: 'black',
    fontSize: 12,
    fontWeight: '300',
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'center',
  },
  td2: {
    width: 65,
    color: 'black',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'center',
  },
});

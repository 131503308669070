import {useState} from 'react';
import React, {
  Image,
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
} from 'react-native';
import Colors from '../common/colors';

const TeamRecordItem = ({
  data,
  currentRank,
  rankingCategoryList,
  rankingTeamCategoryList,
  teamRankingType,
}) => {
  return (
    <View style={[pageStyle.container]}>
      <>
        {teamRankingType === 'GENERAL' &&
          rankingTeamCategoryList.map(category => {
            return (
              <Text
                style={[
                  pageStyle.td,
                  currentRank === category.orderBy && pageStyle.blue,
                ]}>
                {category.orderBy === 'wrate'
                  ? parseFloat(data[category.orderBy] / 100).toFixed(2)
                  : data[category.orderBy]}
              </Text>
            );
          })}
        {teamRankingType === 'DEFENSE' && (
          <>
            <Text
              style={[pageStyle.td, currentRank === 'era' && pageStyle.blue]}>
              {data.era}
            </Text>
            <Text
              style={[pageStyle.td, currentRank === 'pgame' && pageStyle.blue]}>
              {data.game}
            </Text>
            <Text
              style={[
                pageStyle.td,
                currentRank === 'innings' && pageStyle.blue,
              ]}>
              {data.innings}
            </Text>
            <Text
              style={[pageStyle.td, currentRank === 'win' && pageStyle.blue]}>
              {data.win}
            </Text>
            <Text
              style={[pageStyle.td, currentRank === 'lose' && pageStyle.blue]}>
              {data.lose}
            </Text>
            <Text
              style={[pageStyle.td, currentRank === 'save' && pageStyle.blue]}>
              {data.save}
            </Text>
            <Text
              style={[pageStyle.td, currentRank === 'hold' && pageStyle.blue]}>
              {data.hold}
            </Text>
            <Text
              style={[pageStyle.td, currentRank === 'so' && pageStyle.blue]}>
              {data.so}
            </Text>
            <Text
              style={[pageStyle.td, currentRank === 'ph' && pageStyle.blue]}>
              {data.ph}
            </Text>
            <Text
              style={[pageStyle.td, currentRank === 'phr' && pageStyle.blue]}>
              {data.phr}
            </Text>
            <Text style={[pageStyle.td, currentRank === 'r' && pageStyle.blue]}>
              {data.r}
            </Text>
            <Text
              style={[pageStyle.td, currentRank === 'pbb' && pageStyle.blue]}>
              {data.pbb}
            </Text>
            <Text
              style={[
                pageStyle.td,
                currentRank === 'phitByPitch' && pageStyle.blue,
              ]}>
              {data.phitByPitch}
            </Text>
            <Text
              style={[pageStyle.td, currentRank === 'wrate' && pageStyle.blue]}>
              {parseFloat(data.wrate / 100).toFixed(2)}
            </Text>
            <Text
              style={[pageStyle.td, currentRank === 'whip' && pageStyle.blue]}>
              {data.whip}
            </Text>
          </>
        )}
        {teamRankingType === 'OFFENSE' && (
          <>
            <Text
              style={[pageStyle.td, currentRank === 'havg' && pageStyle.blue]}>
              {data.havg}
            </Text>
            <Text
              style={[pageStyle.td, currentRank === 'hgame' && pageStyle.blue]}>
              {data.hgame}
            </Text>
            <Text
              style={[pageStyle.td, currentRank === 'htb' && pageStyle.blue]}>
              {data.htb}
            </Text>
            <Text
              style={[pageStyle.td, currentRank === 'hab' && pageStyle.blue]}>
              {data.hab}
            </Text>
            <Text style={[pageStyle.td, currentRank === 'h' && pageStyle.blue]}>
              {data.h}
            </Text>
            <Text
              style={[pageStyle.td, currentRank === 'h1' && pageStyle.blue]}>
              {data.h1}
            </Text>
            <Text
              style={[pageStyle.td, currentRank === 'h2' && pageStyle.blue]}>
              {data.h2}
            </Text>
            <Text
              style={[pageStyle.td, currentRank === 'h3' && pageStyle.blue]}>
              {data.h3}
            </Text>
            <Text
              style={[pageStyle.td, currentRank === 'hr' && pageStyle.blue]}>
              {data.hr}
            </Text>
            <Text
              style={[pageStyle.td, currentRank === 'rbi' && pageStyle.blue]}>
              {data.rbi}
            </Text>
            <Text
              style={[pageStyle.td, currentRank === 'her' && pageStyle.blue]}>
              {data.her}
            </Text>
            <Text
              style={[pageStyle.td, currentRank === 'sb' && pageStyle.blue]}>
              {data.sb}
            </Text>
            <Text
              style={[pageStyle.td, currentRank === 'hbb' && pageStyle.blue]}>
              {data.hbb}
            </Text>
            <Text
              style={[pageStyle.td, currentRank === 'hso' && pageStyle.blue]}>
              {data.hso}
            </Text>
            <Text
              style={[
                pageStyle.td,
                currentRank === 'obrate' && pageStyle.blue,
              ]}>
              {data.obrate}
            </Text>
            <Text
              style={[pageStyle.td, currentRank === 'srate' && pageStyle.blue]}>
              {data.srate}
            </Text>
            <Text
              style={[pageStyle.td, currentRank === 'ops' && pageStyle.blue]}>
              {data.ops}
            </Text>
          </>
        )}
      </>
    </View>
  );
};
export default TeamRecordItem;

const pageStyle = StyleSheet.create({
  container: {
    height: 45,
    flexDirection: 'row',
    paddingVertical: 7,
    borderBottomWidth: 1,
    borderBottomColor: 'rgba(0, 0, 0, 0.1)',
  },
  rank: {
    width: 40,
    paddingStart: 10,
    alignItems: 'center',
    justifyContent: 'center',
    alignContent: 'center',
    color: 'black',
    alignSelf: 'center',
    textAlign: 'center',
  },
  td: {
    width: 47,
    textAlign: 'center',
    color: 'black',
    fontFamily: 'Pretendard-Light',
    fontSize: 12,
    fontWeight: '300',
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'center',
  },
  td2: {
    width: 65,
    color: 'black',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'center',
  },
  blue: {
    color: Colors.main,
    fontFamily: 'Pretendard-Bold',
    fontWeight: 'bold',
  },
});

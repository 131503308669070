import React, {useEffect, useMemo, useState} from 'react';
import {Image, StyleSheet, Text, View} from 'react-native';
import moment from 'moment';
import 'moment/locale/ko';
import styles from '../common/styles';
import api from '../common/api';
import {TouchableOpacity} from 'react-native-gesture-handler';

const FavoriteTeamItem = props => {
  useEffect(() => {}, [props.data]);
  const [alarmOn, setAlarmOn] = useState(props.data.alarmOn);

  const toggleAlarm = data => {
    api
      .post(
        `/app-team/${data}/favorites/alarm?alarmOn=${!alarmOn}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${props.accessToken}`,
          },
        },
      )
      .then(function (response) {
        setAlarmOn(!alarmOn);
      })
      .catch(function (error) {
        console.error(error.response);
      });
  };

  return (
    <TouchableOpacity
      onPress={() => {
        props.navigation.navigate('TeamDetail', {
          item: props.data.team,
          itemId: props.data.team?.id,
        });
      }}
      style={[styles.row, pageStyle.row]}>
      <Image
        style={[pageStyle.logoImage]}
        resizeMode="contain"
        source={
          props.data.team?.logoImageThumbUrl
            ? {uri: props.data.team?.logoImageThumbUrl}
            : require('../assets/images/nologo.png')
        }
      />
      <Text style={[pageStyle.teamName]}>{props.data.team?.name}</Text>
      <TouchableOpacity onPress={() => toggleAlarm(props.data.team?.id)}>
        <Image
          resizeMode="contain"
          style={[pageStyle.alarmImg]}
          source={
            alarmOn
              ? require('../assets/images/icn_alarm_on.png')
              : require('../assets/images/icn_alarm_off.png')
          }
        />
      </TouchableOpacity>
    </TouchableOpacity>
  );
};

const pageStyle = StyleSheet.create({
  logoImage: {
    width: 55,
    height: 55,
    borderRadius: 27,
  },
  profileImage: {
    width: 45,
    height: 45,
    borderRadius: 23,
  },
  row: {
    borderBottomWidth: 1,
    borderBottomColor: '#e6e6e6',
    paddingVertical: 12,
    paddingHorizontal: 24,
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  name: {
    fontFamily: 'Pretendard',
    fontSize: 14,
    color: 'black',
    marginStart: 8,
  },
  teamName: {
    fontFamily: 'Pretendard',
    fontSize: 16,
    color: 'black',
    marginStart: 16,
  },
  alarmImg: {
    width: 28,
    height: 30,
  },
});

export default FavoriteTeamItem;

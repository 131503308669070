import React, {useEffect, useMemo} from 'react';
import {Image, StyleSheet, Text, View} from 'react-native';
import moment from 'moment';
import 'moment/locale/ko';
import styles from '../common/styles';

const BoardMetaItem = props => {
  useEffect(() => {}, [props.data]);

  const getImageUrl = data => {
    if (data.firstImageThumbUrl) {
      return {uri: data.firstImageThumbUrl};
    } else if (data.firstImageUrl) {
      return {uri: data.firstImageUrl};
    }
    return require('../assets/images/nologo.png');
  };

  const timeFormatted = useMemo(() => {
    const now = moment();
    const target = moment(props.data.createdAt);
    const seconds = now.diff(target, 'seconds');
    const minutes = now.diff(target, 'minutes');
    const hours = now.diff(target, 'hours');
    if (seconds < 60) {
      return '방금전';
    } else if (minutes < 60) {
      return `${minutes}분전`;
    } else if (hours < 24) {
      return `${hours}시간전`;
    }
    return moment(props.data.createdAt).format('YY.MM.DD');
  }, [props.data]);

  const hitCountFormatted = useMemo(() => {
    if (props.data.hitCount < 10000) {
      return props.data.hitCount?.toLocaleString();
    }
    return `${parseInt(props.data.hitCount / 10000, 10)}.${parseInt(
      (props.data.hitCount % 10000) / 1000,
      10,
    )}만`;
  }, [props.data]);

  return (
    <View style={[pageStyle.itemContainer]}>
      <View style={[pageStyle.leftContainer]}>
        <View style={[pageStyle.contentsContainer]}>
          <Text
            style={[
              pageStyle.title,
              props.data.fixed && {
                fontFamily: 'Pretendard-Bold',
                fontWeight: 'bold',
              },
            ]}
            numberOfLines={2}>
            {props.data.title}
          </Text>
          <Text style={[pageStyle.smallTxt]}>
            {props.data.user?.name}&nbsp;&nbsp;&nbsp;{timeFormatted}{' '}
            &nbsp;&nbsp;&nbsp;조회&nbsp;&nbsp;
            {hitCountFormatted}회
          </Text>
        </View>
        {props.data.firstImageUrl && (
          <Image
            style={[
              {
                width: 65,
                height: 65,
                resizeMode: 'cover',
                borderRadius: 5,
              },
            ]}
            source={getImageUrl(props.data)}
          />
        )}
      </View>
      <View style={[pageStyle.rightContainer]}>
        <Text style={[pageStyle.commentCntTxt]}>{props.data.commentCount}</Text>
        <Text style={[pageStyle.commentTxt]}>댓글</Text>
      </View>
    </View>
  );
};

const pageStyle = StyleSheet.create({
  itemContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 3,
    backgroundColor: 'white',
    position: 'relative',
    borderBottomColor: '#e6e6e6',
    borderBottomWidth: 1,
    paddingVertical: 10,
    height: 85,
    paddingHorizontal: 12,
  },
  title: {
    color: '#000000',
    fontFamily: 'Pretendard',
    fontSize: 14,
    lineHeight: 20,
  },
  leftContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingEnd: 12,
  },
  rightContainer: {
    borderRadius: 5,
    width: 40,
    backgroundColor: '#ececec',
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'center',
  },
  commentCntTxt: {
    fontFamily: 'Pretendard-SemiBold',
    fontWeight: '600',
    color: '#000000',
    fontSize: 16,
  },
  commentTxt: {
    color: '#7c7c7c',
    fontFamily: 'Pretendard',
    fontSize: 12,
    marginTop: 3,
  },
  smallTxt: {
    color: '#7c7c7c',
    fontFamily: 'Pretendard',
    fontSize: 12,
    marginTop: 8,
  },
  contentsContainer: {
    paddingTop: 0,
    flex: 1,
  },
});

export default BoardMetaItem;

import React, {useState, useEffect, useRef, useMemo} from 'react';
import {
  Image,
  SafeAreaView,
  ScrollView,
  Text,
  TouchableOpacity,
  View,
  StyleSheet,
  FlatList,
  DeviceEventEmitter,
  KeyboardAvoidingView,
  Alert,
  Platform,
} from 'react-native';
import styles from '../common/styles';
import {Shadow} from 'react-native-shadow-2';
import api from '../common/api';
import AsyncStorage from '@react-native-async-storage/async-storage';
import moment from 'moment';
import MyInfoSummary from '../component/MyInfoSummary';
import MyRecordComponent from '../component/MyRecordComponent';
import MyFavoriteComponent from '../component/MyFavoriteComponent';
import {launchImageLibrary} from 'react-native-image-picker';
import alert from '../component/alert';
import {useFonts} from 'expo-font';
import * as Font from 'expo-font';

const MyPageView = ({navigation}) => {
  const [tabIndex, setTabIndex] = useState(0);
  const [age, setAge] = useState(0);
  const [userInfo, setUserInfo] = useState({});
  const [homeData, setHomeData] = useState({
    thisYearBattingRecord: {},
    thisYearPitchingRecord: {},
    recentGames: [],
  });
  const [alarmUnread, setAlarmUnread] = useState(false);

  const [profileImageUri, setProfileImageUri] = useState('');
  const [profileImageData, setProfileImageData] = useState();

  const tabList = [
    {index: 0, text: '요약'},
    {index: 1, text: '기록실'},
    {index: 2, text: '관심팀&선수'},
  ];
  let accessToken = useRef();

  useEffect(() => {
    AsyncStorage.getItem('@accessToken', (err, result) => {
      accessToken.current = result;
      getUserInfo(result);
      getHomeData(result);
      checkAlarmUnRead();
    });

    const unsubscribe = DeviceEventEmitter.addListener(
      'mypageInfoRefresh',
      function (data) {
        getUserInfo(accessToken.current);
        getHomeData(accessToken.current);
        checkAlarmUnRead();
      },
    );

    return () => {
      unsubscribe.remove();
    };
  }, []);

  useEffect(() => {
    const unsubscribe = DeviceEventEmitter.addListener(
      'userInfoRefresh',
      function (data) {
        getUserInfo(accessToken.current);
      },
    );

    return () => {
      unsubscribe.remove();
    };
  }, []);

  const openImageLibrary = () => {
    launchImageLibrary(
      {
        mediaType: 'photo',
        includeBase64: true,
        maxHeight: 500,
        maxWidth: 500,
      },
      response => {
        if (
          !response.errorCode &&
          response.assets &&
          response.assets.length > 0 &&
          response.assets[0].base64
        ) {
          alert('프로필 이미지', '프로필 이미지를 변경하시겠습니까?', [
            {
              text: '예',
              onPress: () => {
                setProfileImageUri(response.assets[0].uri);
                setProfileImageData(
                  'data:image/png;base64,' + response.assets[0].base64,
                );
                setUserInfo({
                  ...userInfo,
                  ['profileImageThumbUrl']: response.assets[0].uri,
                });
                api
                  .post(
                    '/users/me/profile-image',
                    {
                      profileImageData:
                        'data:image/png;base64,' + response.assets[0].base64,
                    },
                    {
                      headers: {
                        Authorization: `Bearer ${accessToken.current}`,
                      },
                    },
                  )
                  .then(function (response) {
                    // console.log(response);
                  })
                  .catch(function (error) {
                    console.log(error.response);
                  });
              },
            },
            {
              text: '아니오',
            },
          ]);
        }
      },
    );
  };

  const checkAlarmUnRead = () => {
    if (!accessToken.current) {
      return;
    }
    api
      .get('/users/exists-unread-alarm', {
        headers: {
          Authorization: `Bearer ${accessToken.current}`,
        },
      })
      .then(function (response) {
        setAlarmUnread(response.data);
      })
      .catch(function (error) {
        console.error(error.response);
      });
  };

  const getHomeData = token => {
    api
      .get('/home', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(function (response) {
        setHomeData(response.data);
      })
      .catch(function (error) {
        console.log(error.response);
      });
  };

  const requestCertify = () => {
    console.log('requestCertify in MyPageView');
    /* 2. 본인인증 데이터 정의하기 */
    const data = {
      merchant_uid: `mid_${new Date().getTime()}`, // 주문번호
      company: '(주) 유니크플레이', // 회사명 또는 URL
    };
    navigation?.navigate('Certification', {
      userCode: 'imp66015233',
      // userCode: 'iamport',
      data: data,
      accessToken: accessToken.current,
      callback: {
        screen: 'Main',
        data: {},
      },
    });
  };

  const getUserInfo = token => {
    if (!token) {
      return;
    }
    api
      .get('/app-user/me', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(function (response) {
        setUserInfo(response.data);
        setAge(moment().diff(moment(response.data.birth, 'YYYY-MM-DD'), 'y'));
        console.log('userInfo in MyPageView', response.data);
        if (!response.data.certificationId) {
          console.log('in MyPageView');
          requestCertify();
        }
      })
      .catch(function (error) {
        console.log(error.response);
        if (error.response.status === 401) {
          alert('탈퇴처리된 계정입니다.');
          AsyncStorage.removeItem('@accessToken', () => {
            navigation.reset({
              index: 0,
              routes: [{name: 'Login'}],
            });
          });
        }
      });
  };

  const profileImageUrl = useMemo(() => {
    if (userInfo?.profileImageThumbUrl) {
      return {uri: userInfo?.profileImageThumbUrl};
    }
    if (userInfo?.profileImageUrl) {
      return {uri: userInfo?.profileImageUrl};
    }
    return require('../assets/images/icn_profile_img.png');
  }, [userInfo]);

  const goToMyplayView = () => {
    navigation.navigate('MyPlay', {user: userInfo});
  };

  return (
    <>
      <KeyboardAvoidingView
        behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
        style={pageStyle.container}>
        <SafeAreaView style={[styles.container]}>
          <Shadow
            offset={[0, 2]}
            style={[{width: '100%'}]}
            containerStyle={[{marginTop: 0}]}
            distance={7}
            sides={{bottom: true, top: false, start: false, end: false}}>
            <View style={[styles.headerCommon]}>
              <View style={[styles.row, styles.verticalAlign]}>
                <TouchableOpacity
                  onPress={() =>
                    Platform.OS === 'web' ? history.back() : navigation.goBack()
                  }>
                  <Image
                    source={require('../assets/images/icon_back.png')}
                    style={[styles.headerBackButton]}
                  />
                </TouchableOpacity>
                <Text style={[styles.headerText]}>마이페이지</Text>
              </View>
              <View style={[styles.row, styles.verticalAlign]}>
                <TouchableOpacity
                  onPress={() => navigation.navigate('Setting')}>
                  <Image
                    resizeMode="contain"
                    style={[styles.imgSetting]}
                    source={require('../assets/images/icn_setting.png')}
                  />
                </TouchableOpacity>
                <TouchableOpacity
                  style={[styles.alarmContainer]}
                  onPress={() => navigation.navigate('MyAlarmList')}>
                  <Image
                    resizeMode="contain"
                    style={[styles.imgAlarm]}
                    source={require('../assets/images/icn_alarm.png')}
                  />
                  {alarmUnread && <View style={[styles.alarmDot]} />}
                </TouchableOpacity>
              </View>
            </View>
          </Shadow>
          <ScrollView style={[{marginTop: 0}]}>
            <View
              style={[
                styles.scrollViewContainer,
                pageStyle.scrollViewContainer,
              ]}>
              <View style={[pageStyle.profileHeader]}>
                <Image
                  source={require('../assets/images/mypage_bg.png')}
                  style={[pageStyle.profileBg]}
                />
                <View style={[pageStyle.profileContainer]}>
                  <TouchableOpacity
                    onPress={() => openImageLibrary()}
                    style={[pageStyle.profileLeftContainer]}>
                    {userInfo.profileImageUrl && (
                      <Image
                        source={profileImageUrl}
                        style={[pageStyle.profileImage]}
                        resizeMode={'cover'}
                      />
                    )}
                    {!userInfo.profileImageUrl && (
                      <>
                        <Text style={[pageStyle.profileTitle]}>PROFILE</Text>
                        <Image
                          source={require('../assets/images/plus_grey.png')}
                          style={[pageStyle.profileIcn]}
                        />
                      </>
                    )}
                  </TouchableOpacity>
                  <TouchableOpacity
                    onPress={() => navigation.navigate('MyBoard')}
                    style={[pageStyle.profileRightContainer]}>
                    <View style={[styles.row, {alignItems: 'center'}]}>
                      <Text style={[pageStyle.username]}>{userInfo.name}</Text>
                      <Image
                        style={[pageStyle.rightArr]}
                        source={require('../assets/images/chevron-right-wh.png')}
                      />
                    </View>
                    <Text style={[pageStyle.otherInfo]} numberOfLines={1}>
                      {userInfo.pitchingAndHitting}/{age}세
                    </Text>
                  </TouchableOpacity>
                </View>
                <TouchableOpacity
                  onPress={goToMyplayView}
                  style={[pageStyle.myplayIcnContainer]}>
                  <Image
                    resizeMode="cover"
                    style={[pageStyle.myplayIcn]}
                    source={require('../assets/images/myplay.png')}
                  />
                </TouchableOpacity>
              </View>
              <View style={[styles.tabContainer]}>
                {tabList.map(tab => (
                  <TouchableOpacity
                    key={`tab_${tab.index}`}
                    onPress={() => setTabIndex(tab.index)}
                    style={[
                      tabIndex == tab.index && styles.tabItemSelected,
                      tabIndex != tab.index && styles.tabItem,
                    ]}>
                    <Text
                      style={[
                        tabIndex == tab.index && styles.tabItemTextSelected,
                        tabIndex != tab.index && styles.tabItemText,
                      ]}>
                      {tab.text}
                    </Text>
                  </TouchableOpacity>
                ))}
              </View>
              {tabIndex === 0 && (
                <MyInfoSummary navigation={navigation} userInfo={userInfo} />
              )}
              {tabIndex === 1 && (
                <MyRecordComponent
                  userInfo={userInfo}
                  navigation={navigation}
                />
              )}
              {tabIndex === 2 && (
                <MyFavoriteComponent
                  data={homeData}
                  userInfo={userInfo}
                  navigation={navigation}
                  accessToken={accessToken.current}
                />
              )}
            </View>
          </ScrollView>
        </SafeAreaView>
      </KeyboardAvoidingView>
    </>
  );
};

const pageStyle = StyleSheet.create({
  container: {flex: 1},
  list: {
    paddingStart: 26,
    paddingEnd: 26,
    paddingTop: 10,
    paddingBottom: 50,
  },
  header: {
    paddingVertical: 15,
    paddingHorizontal: 33,
  },
  profileHeader: {
    position: 'relative',
    height: 139,
    // marginTop: -5,
    zIndex: 10,
  },
  profileBg: {
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    position: 'absolute',
  },
  profileContainer: {
    flexDirection: 'row',
    height: 80,
    marginStart: 33,
    marginTop: 40,
  },
  profileLeftContainer: {
    width: 60,
    backgroundColor: 'rgb(238, 238, 238)',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  profileImage: {
    height: 80,
    width: 60,
    position: 'absolute',
    top: 0,
    left: 0,
  },
  profileTitle: {
    color: '#9fa0a0',
    fontSize: 11,
    textAlign: 'center',
    position: 'absolute',
    bottom: 10,
    left: 7,
  },
  profileRightContainer: {
    paddingStart: 22,
    paddingTop: 25,
    paddingBottom: 10,
  },
  username: {
    color: 'white',
    fontFamily: 'Pretendard-Bold',
    fontSize: 21,
    fontWeight: 'bold',
    fontStyle: 'italic',
  },
  otherInfo: {
    marginTop: 6,
    fontFamily: 'Pretendard',
    fontSize: 13,
    color: 'white',
    fontStyle: 'italic',
  },
  myplayIcnContainer: {
    position: 'absolute',
    bottom: 20,
    right: 15,
  },
  myplayIcn: {
    width: 130,
    height: 130 / (117 / 28),
  },
  innerContainer: {
    paddingHorizontal: 29,
  },
  subtitle: {
    marginTop: 16,
    marginStart: 10,
    color: 'black',
    fontFamily: 'Pretendard-Bold',
    fontSize: 16,
    fontWeight: '700',
  },
  row: {
    flexDirection: 'row',
  },
  firstRow: {
    marginTop: 10,
  },
  col: {
    flex: 1,
  },
  th: {
    backgroundColor: '#3f51b5',
    padding: 4,
    lineHeight: 13,
    fontSize: 13,
    color: 'white',
    fontWeight: '700',
    fontFamily: 'Pretendard-Bold',
    textAlign: 'center',
  },
  td: {
    padding: 4,
    paddingVertical: 10,
    lineHeight: 13,
    fontSize: 13,
    color: 'black',
    fontWeight: '700',
    fontFamily: 'Pretendard-Bold',
    textAlign: 'center',
  },
  even: {
    backgroundColor: 'rgb(236, 236, 236)',
  },
  rightArr: {
    width: 10,
    height: (10 / 79) * 128,
    marginStart: 12,
  },
});

export default MyPageView;

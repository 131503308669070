import React, {useEffect, useState, useRef} from 'react';
import {
  Button,
  Image,
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  TextInput,
  Dimensions,
  Platform,
} from 'react-native';
import {ScrollView} from 'react-native-gesture-handler';
import {SafeAreaView} from 'react-native-safe-area-context';
import SelectDropdown from 'react-native-select-dropdown';
import styles from '../common/styles';
import api from '../common/api';
import {useSafeAreaInsets} from 'react-native-safe-area-context';
import {Modal} from 'react-native';
import {title} from '../common/util';

const FindIdView = ({navigation, route}) => {
  const insets = useSafeAreaInsets();
  const [modalOpened, setModalOpened] = useState(false);
  const [authType, setAuthType] = useState();
  const [email, setEmail] = useState();
  const [inputs, setInputs] = useState({
    cellphone: '',
    code: '',
  });

  useEffect(() => {}, []);

  const onChangeCellphone = value => {
    let digit = value.replace(/[^0-9]/g, '');
    setInputs({
      ...inputs,
      ['cellphone']: digit,
    });
  };

  const onChangeCode = value => {
    let digit = value.replace(/[^0-9]/g, '');
    setInputs({
      ...inputs,
      ['code']: digit,
    });
  };

  const requestCode = () => {
    if (
      !inputs.cellphone ||
      (inputs.cellphone.length !== 11 && inputs.cellphone.length !== 10)
    ) {
      return;
    }

    api
      .post('/certification/code', {cellphone: inputs.cellphone})
      .then(function (response) {
        alert('요청에 성공했습니다.');
      })
      .catch(function (error) {
        console.log(error.response);
        alert('요청에 실패했습니다.');
      });
  };

  const validateInputs = () => {
    if (!inputs.cellphone || !inputs.code) {
      alert('필수 값을 입력해 주세요.');
      return false;
    }

    return true;
  };

  const findId = () => {
    if (!validateInputs()) {
      return;
    }
    api
      .get(`/users/find-id?cellphone=${inputs.cellphone}&code=${inputs.code}`)
      .then(function (response) {
        if (!response.data.authType) {
          alert('계정 정보가 없습니다.');
        } else {
          setAuthType(response.data.authType);
          setEmail(response.data.email);
          setModalOpened(true);
        }
      })
      .catch(function (error) {
        console.log(error.response, error);
        alert(error.response.data.message);
      });
  };

  const hidePopup = () => {
    setModalOpened(false);
  };
  return (
    <SafeAreaView nativeID="common-page-container" style={pageStyle.container}>
      <ScrollView style={pageStyle.scrollView}>
        <View style={[styles.headerCommon, {backgroundColor: 'transparent'}]}>
          <TouchableOpacity
            onPress={() =>
              Platform.OS === 'web' ? history.back() : navigation.goBack()
            }>
            <Image
              source={require('../assets/images/icon_back_wh.png')}
              style={[styles.headerBackButton]}
            />
          </TouchableOpacity>
        </View>
        <View style={[pageStyle.scrollViewContainer]}>
          <Image
            style={pageStyle.logo}
            source={require('../assets/images/logo.png')}
          />
          <View style={pageStyle.formContainer}>
            <View>
              <Text style={[pageStyle.subtitle]}>휴대전화</Text>
              <View style={[pageStyle.row, {alignItems: 'flex-end'}]}>
                <View style={[pageStyle.flex1]}>
                  <TextInput
                    style={[pageStyle.input]}
                    placeholder="전화번호입력"
                    placeholderTextColor={'#aaa'}
                    maxLength={11}
                    value={inputs.cellphone}
                    onChangeText={onChangeCellphone}
                  />
                </View>
                <TouchableOpacity
                  style={[pageStyle.requestCodeBtn]}
                  onPress={requestCode}>
                  <Text style={[pageStyle.buttonSmallTxt]}>인증번호받기</Text>
                </TouchableOpacity>
              </View>
              <View style={[pageStyle.row, {alignItems: 'flex-end'}]}>
                <View style={[pageStyle.flex1]}>
                  <TextInput
                    style={[pageStyle.input, pageStyle.mt16]}
                    placeholder="인증번호 입력"
                    placeholderTextColor={'#aaa'}
                    maxLength={8}
                    value={inputs.code}
                    onChangeText={onChangeCode}
                  />
                </View>
              </View>
            </View>
            <TouchableOpacity style={[pageStyle.commonBtn]} onPress={findId}>
              <Text style={[pageStyle.buttonSmallTxt]}>아이디찾기</Text>
            </TouchableOpacity>
          </View>
        </View>
        {modalOpened && (
          <Modal transparent={true} overFullScreen={true}>
            <View style={[pageStyle.popupContainer]}>
              <View
                style={[
                  pageStyle.innerContainer,
                  {marginTop: insets.top + 67},
                ]}>
                <Text style={[pageStyle.subtitle]}>가입방법</Text>
                <Text style={[pageStyle.value]}>{authType}</Text>
                <Text style={[pageStyle.subtitle]}>이메일</Text>
                <Text style={[pageStyle.value]}>{email}</Text>
                <TouchableOpacity
                  onPress={hidePopup}
                  style={[pageStyle.commonBtn, {width: 80}]}>
                  <Text style={[pageStyle.buttonTxt]}>확인</Text>
                </TouchableOpacity>
              </View>
            </View>
          </Modal>
        )}
      </ScrollView>
    </SafeAreaView>
  );
};

const pageStyle = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    backgroundColor: 'rgb(23, 53, 100)',
  },
  scrollView: {
    width: '100%',
  },
  scrollViewContainer: {
    paddingHorizontal: 32,
    paddingBottom: 37,
    alignItems: 'center',
  },
  popupContainer: {
    zIndex: 100,
    width: '100%',
    height: Dimensions.get('window').height,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  innerContainer: {
    backgroundColor: 'white',
    width: 300,
    marginHorizontal: Dimensions.get('window').width / 2 - 150,
    top: Dimensions.get('window').height / 2 - 150,
    textAlign: 'center',
    padding: 11,
    alignItems: 'center',
  },
  logo: {},
  formContainer: {
    width: '100%',
    backgroundColor: 'white',
    marginTop: 23,
    borderRadius: 20,
    padding: 25,
  },
  subtitleContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  subtitle: {
    fontFamily: 'Pretendard',
    fontSize: 13,
    color: 'black',
    lineHeight: 15,
    marginTop: 8,
  },
  value: {
    fontFamily: 'Pretendard-Bold',
    fontSize: 15,
    color: 'black',
    marginTop: 3,
    fontWeight: 'bold',
  },
  warningTxt: {
    paddingTop: 2,
    color: '#ff0000',
    fontFamily: 'Pretendard',
    fontSize: 8,
    paddingStart: 5,
  },
  input: {
    fontFamily: 'Pretendard',
    fontSize: 14,
    borderBottomColor: '#909090',
    borderBottomWidth: 1,
    marginTop: 8,
    paddingBottom: 4,
  },
  mt16: {
    marginTop: 16,
  },
  mt18: {
    marginTop: 18,
  },
  mh7: {
    marginHorizontal: 7,
  },
  ms7: {
    marginStart: 7,
  },
  passwordInputContainer: {
    position: 'relative',
  },
  inputIconContainer: {
    display: 'flex',
    position: 'absolute',
    alignContent: 'center',
    alignItems: 'center',
    right: 8,
    flexDirection: 'row',
    bottom: 6,
  },
  invisibleIcn: {
    marginEnd: 6,
    width: 20,
    height: 15,
    resizeMode: 'cover',
  },
  commonBtn: {
    marginTop: 25,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 15,
    height: 30,
    backgroundColor: 'rgb(61, 80, 183)',
  },
  buttonTxt: {
    fontFamily: 'Pretendard',
    fontSize: 13,
    color: 'white',
  },
  buttonSmallTxt: {
    fontFamily: 'Pretendard',
    fontSize: 10,
    color: 'white',
  },
  dropDownContainer: {
    position: 'relative',
  },
  dropDownMonth: {
    width: '100%',
    backgroundColor: 'white',
    borderWidth: 1,
    borderColor: 'rgb(61, 80, 183)',
    height: 24,
    position: 'absolute',
    bottom: 0,
    marginTop: 10,
  },
  dropDown: {
    width: '100%',
    backgroundColor: 'white',
    borderWidth: 1,
    borderColor: 'rgb(61, 80, 183)',
    height: 24,
    marginTop: 10,
  },
  downDownText: {
    color: 'black',
    fontFamily: 'Pretendard',
    fontSize: 11,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
  col: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  col2: {
    flex: 2,
    display: 'flex',
    flexDirection: 'column',
  },
  flex1: {
    flex: 1,
  },
  requestCodeBtn: {
    width: 94,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 15,
    height: 25,
    backgroundColor: 'rgb(61, 80, 183)',
  },
});

export default FindIdView;

import React, {useState, useEffect, useRef, useMemo} from 'react';
import {
  Image,
  SafeAreaView,
  ScrollView,
  Text,
  TouchableOpacity,
  View,
  StyleSheet,
  FlatList,
  TextInput,
  Dimensions,
  RefreshControl,
  Platform,
  DeviceEventEmitter,
} from 'react-native';
import styles from '../common/styles';
import {Shadow} from 'react-native-shadow-2';
import AsyncStorage from '@react-native-async-storage/async-storage';
import api from '../common/api';
import Colors, {red} from '../common/colors';
import LineUpPopup from '../component/team/LineUpPopup';
import VideoSelectionPopup from '../component/VideoSelectionPopup';
import {
  isHr,
  isH2,
  isH3,
  isSb,
  isDo,
  filterNaN,
  getOrDefault,
} from '../common/util';
import moment from 'moment';
import 'moment/locale/ko';
import * as Progress from 'react-native-progress';
import {title} from '../common/util';
import {useSafeAreaInsets} from 'react-native-safe-area-context';

const BoxScoreView = ({navigation, route}) => {
  const accessToken = useRef();
  const {itemId} = route.params;
  const [data, setData] = useState(route.params.data);
  const [commentPage, setCommentPage] = useState({});
  const [lineUpPopupOpened, setLineUpPopupOpened] = useState(false);
  const [currentTeamId, setCurrentTeamId] = useState('');
  const [teamBatterLineUps, setTeamBatterLineUps] = useState([]);
  const [teamPitcherLineUps, setTeamPitcherLineUps] = useState([]);
  const [currentTeamRecords, setCurrentTeamRecords] = useState({});
  const [firstTeamStartingPitcher, setFirstTeamStartingPitcher] = useState({});
  const [lastTeamStartingPitcher, setLastTeamStartingPitcher] = useState({});
  const [firstTeamResultPitcher, setFirstTeamResultPitcher] = useState({});
  const [lastTeamResultPitcher, setLastTeamResultPitcher] = useState({});

  const [refreshing, setRefreshing] = useState(false);
  const [progressShown, setProgressShown] = useState(false);

  const [summary, setSummary] = useState({});

  const [totalInnings, setTotalInnings] = useState(0);
  const [totalHits, setTotalHits] = useState(0);
  const [totalRs, setTotalRs] = useState(0);
  const [totalEra, setTotalEra] = useState(0);
  const [totalErs, setTotalErs] = useState(0);
  const [totalBbs, setTotalBbs] = useState(0);
  const [totalHbps, setTotalHbps] = useState(0);
  const [totalSos, setTotalSos] = useState(0);

  const firstTeamBatterLineUps = useRef();
  const lastTeamBatterLineUps = useRef();
  const firstTeamPitcherLineUps = useRef();
  const lastTeamPitcherLineUps = useRef();

  const loading = useRef();
  const commentPageLoading = useRef();

  const insets = useSafeAreaInsets();

  const [userData, setUserData] = useState({});

  useEffect(() => {
    AsyncStorage.getItem('@accessToken', (err, result) => {
      accessToken.current = result;
      refreshGameData(accessToken.current);
      callGetCommentListApi();
    });

    AsyncStorage.getItem('@userInfo', (err, result) => {
      if (!result) {
        return;
      }
      setUserData(JSON.parse(result));
    });
  }, [itemId]);

  const onRefresh = React.useCallback(() => {
    setRefreshing(true);
    refreshGameData(accessToken.current);
    callGetCommentListApi();
  }, []);
  const isFirstTeam = useMemo(() => {
    return currentTeamId === data?.batFirstTeamId;
  }, [currentTeamId, data?.batFirstTeamId]);

  const getBattingOrder = batterItem => {
    if (batterItem?.battingOrder && batterItem?.battingSubOrder) {
      return `${batterItem.battingOrder}_${batterItem.battingSubOrder}`;
    }
    return batterItem.battingOrder;
  };

  const getPositionName = name => {
    switch (name) {
      case '1루수':
        return '1루';
      case '2루수':
        return '2루';
      case '3루수':
        return '3루';
      case '유격수':
        return '유격';
      case '좌익수':
        return '좌익';
      case '중견수':
        return '중견';
      case '우익수':
        return '우익';
      case '포수':
        return '포수';
      case '선발투수':
        return '선발';
      case '투수':
        return '투수';
      case '지명타자':
        return '지타';
      case '미지정':
        return '미정';
      default:
        return '미정';
    }
  };

  const calculatePercentage = (value1, value2) => {
    let total = 0;
    if (!value1 && !value2) {
      return '0%';
    } else if (!value1 && value2) {
      total = value2;
    } else if (value1 && !value2) {
      total = value1;
    } else {
      total = value1 + value2;
    }
    return `${100 * (value1 / total)}%`;
  };

  const refreshGameData = token => {
    api
      .get(`/games/${itemId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(function (response) {
        setData(response.data);
        getData(accessToken.current, response.data);
      })
      .catch(function (error) {
        console.log(error);
        setRefreshing(false);
      });
  };

  const getData = (token, gameData) => {
    if (loading.value) {
      return;
    }
    loading.value = true;
    setProgressShown(true);
    console.log(`/lineups?gameId=${itemId}`);
    api
      .get(`/lineups?gameId=${itemId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(function (response) {
        const lineUps = response.data;
        let localBatFirstTeamBatterLineUps = [];
        let localBatLastTeamBatterLineUps = [];
        let localBatFirstTeamPitcherLineUps = [];
        let localBatLastTeamPitcherLineUps = [];
        for (let i = 0; i < lineUps.length; i++) {
          const item = lineUps[i];
          if (item.team.id === gameData.batFirstTeamId) {
            if (item.battingOrder) {
              localBatFirstTeamBatterLineUps.push(item);
            } else if (item.pitchingOrder) {
              localBatFirstTeamPitcherLineUps.push(item);
              if (
                ['승', '패'].includes(
                  JSON.parse(gameData?.records)?.batFirstTeam?.pitchingRecord[
                    item.pitchingOrder
                  ]?.result,
                )
              ) {
                setFirstTeamResultPitcher({
                  item: item,
                  result: JSON.parse(gameData?.records)?.batFirstTeam
                    ?.pitchingRecord[item.pitchingOrder]?.result,
                });
              }
            }
          } else if (item.team.id === gameData.batLastTeamId) {
            if (item.battingOrder) {
              localBatLastTeamBatterLineUps.push(item);
            } else if (item.pitchingOrder) {
              localBatLastTeamPitcherLineUps.push(item);
              if (
                ['승', '패'].includes(
                  JSON.parse(gameData?.records)?.batLastTeam?.pitchingRecord[
                    item.pitchingOrder
                  ]?.result,
                )
              ) {
                setLastTeamResultPitcher({
                  item: item,
                  result: JSON.parse(gameData?.records)?.batLastTeam
                    ?.pitchingRecord[item.pitchingOrder]?.result,
                });
              }
            }
          }
        }
        localBatFirstTeamBatterLineUps.sort((i, j) => {
          return i.battingOrder !== j.battingOrder
            ? parseInt(i.battingOrder, 10) > parseInt(j.battingOrder, 10)
              ? 1
              : -1
            : i.battingSubOrder > j.battingSubOrder
            ? 1
            : -1;
        });
        localBatFirstTeamPitcherLineUps.sort((i, j) => {
          if (!!i.pitchingOrder && !j.pitchingOrder) {
            return 1;
          } else if (!i.pitchingOrder && j.pitchingOrder) {
            return -1;
          } else if (!!i.pitchingOrder && !!j.pitchingOrder) {
            return parseInt(i.pitchingOrder, 10) > parseInt(j.pitchingOrder, 10)
              ? 1
              : -1;
          }
          return 0;
        });
        localBatLastTeamBatterLineUps.sort((i, j) => {
          return i.battingOrder !== j.battingOrder
            ? parseInt(i.battingOrder, 10) > parseInt(j.battingOrder, 10)
              ? 1
              : -1
            : i.battingSubOrder > j.battingSubOrder
            ? 1
            : -1;
        });
        localBatLastTeamPitcherLineUps.sort((i, j) => {
          if (!!i.pitchingOrder && !j.pitchingOrder) {
            return 1;
          } else if (!i.pitchingOrder && j.pitchingOrder) {
            return -1;
          } else if (!!i.pitchingOrder && !!j.pitchingOrder) {
            return parseInt(i.pitchingOrder, 10) > parseInt(j.pitchingOrder, 10)
              ? 1
              : -1;
          }
          return 0;
        });
        firstTeamBatterLineUps.current = localBatFirstTeamBatterLineUps;
        lastTeamBatterLineUps.current = localBatLastTeamBatterLineUps;
        firstTeamPitcherLineUps.current = localBatFirstTeamPitcherLineUps;
        lastTeamPitcherLineUps.current = localBatLastTeamPitcherLineUps;
        if (
          localBatFirstTeamPitcherLineUps &&
          localBatFirstTeamPitcherLineUps.length > 0
        ) {
          setFirstTeamStartingPitcher(localBatFirstTeamPitcherLineUps[0]);
        }
        if (
          localBatLastTeamPitcherLineUps &&
          localBatLastTeamPitcherLineUps.length > 0
        ) {
          setLastTeamStartingPitcher(localBatLastTeamPitcherLineUps[0]);
        }
        setSummaryInfo(JSON.parse(gameData?.records));
        selectFirstBatTeam(gameData);
      })
      .catch(function (error) {
        console.log(error);
      })
      .finally(function () {
        setRefreshing(false);
        setProgressShown(false);
        loading.value = false;
      });
  };

  const setSummaryInfo = records => {
    let localSummary = {};
    for (let i = 0; i < firstTeamBatterLineUps.current.length; i++) {
      let batter = firstTeamBatterLineUps.current[i];
      if (!batter.battingOrder) {
        continue;
      }
      let battingOrder = batter.battingSubOrder
        ? `${batter.battingOrder}_${batter.battingSubOrder}`
        : batter.battingOrder;
      const batterRecords = records?.batFirstTeam?.battingRecords[battingOrder];
      if (!batterRecords) {
        continue;
      }
      for (let j = 0; j < Object.keys(batterRecords).length; j++) {
        const key = Object.keys(batterRecords)[j];
        if (!Array.isArray(batterRecords[key])) {
          continue;
        }
        for (let k = 0; k < Object.keys(batterRecords[key]).length; k++) {
          if (isHr(batterRecords[key][k].name)) {
            if (localSummary['홈런']) {
              localSummary['홈런'].push(
                `${batter?.member?.user?.name}(${key}회)`,
              );
            } else {
              localSummary['홈런'] = [
                `${batter?.member?.user?.name}(${key}회)`,
              ];
            }
          }
          if (isH2(batterRecords[key][k].name)) {
            if (localSummary['2루타']) {
              localSummary['2루타'].push(
                `${batter?.member?.user?.name}(${key}회)`,
              );
            } else {
              localSummary['2루타'] = [
                `${batter?.member?.user?.name}(${key}회)`,
              ];
            }
          }
          if (isH3(batterRecords[key][k].name)) {
            if (localSummary['3루타']) {
              localSummary['3루타'].push(
                `${batter?.member?.user?.name}(${key}회)`,
              );
            } else {
              localSummary['3루타'] = [
                `${batter?.member?.user?.name}(${key}회)`,
              ];
            }
          }
          // if (isSb(batterRecords[key][k].name)) {
          //   if (localSummary['도루']) {
          //     localSummary['도루'].push(
          //       `${batter?.member?.user?.name}(${key}회)`,
          //     );
          //   } else {
          //     localSummary['도루'] = [
          //       `${batter?.member?.user?.name}(${key}회)`,
          //     ];
          //   }
          // }
          if (isDo(batterRecords[key][k].name)) {
            if (localSummary['병살타']) {
              localSummary['병살타'].push(
                `${batter?.member?.user?.name}(${key}회)`,
              );
            } else {
              localSummary['병살타'] = [
                `${batter?.member?.user?.name}(${key}회)`,
              ];
            }
          }
        }
      }
    }

    for (let i = 0; i < lastTeamBatterLineUps.current.length; i++) {
      let batter = lastTeamBatterLineUps.current[i];
      if (!batter.battingOrder) {
        continue;
      }
      let battingOrder = batter.battingSubOrder
        ? `${batter.battingOrder}_${batter.battingSubOrder}`
        : batter.battingOrder;
      const batterRecords = records?.batLastTeam?.battingRecords[battingOrder];
      for (let j = 0; j < Object.keys(batterRecords).length; j++) {
        const key = Object.keys(batterRecords)[j];
        if (!Array.isArray(batterRecords[key])) {
          continue;
        }
        for (let k = 0; k < Object.keys(batterRecords[key]).length; k++) {
          if (isHr(batterRecords[key][k].name)) {
            if (localSummary['홈런']) {
              localSummary['홈런'].push(
                `${batter?.member?.user?.name} (${key}회)`,
              );
            } else {
              localSummary['홈런'] = [
                `${batter?.member?.user?.name} (${key}회)`,
              ];
            }
          }
          if (isH2(batterRecords[key][k].name)) {
            if (localSummary['2루타']) {
              localSummary['2루타'].push(
                `${batter?.member?.user?.name} (${key}회)`,
              );
            } else {
              localSummary['2루타'] = [
                `${batter?.member?.user?.name} (${key}회)`,
              ];
            }
          }
          if (isH3(batterRecords[key][k].name)) {
            if (localSummary['3루타']) {
              localSummary['3루타'].push(
                `${batter?.member?.user?.name} (${key}회)`,
              );
            } else {
              localSummary['3루타'] = [
                `${batter?.member?.user?.name} (${key}회)`,
              ];
            }
          }
          // if (isSb(batterRecords[key][k].name)) {
          //   if (localSummary['도루']) {
          //     localSummary['도루'].push(
          //       `${batter?.member?.user?.name} (${key}회)`,
          //     );
          //   } else {
          //     localSummary['도루'] = [
          //       `${batter?.member?.user?.name} (${key}회)`,
          //     ];
          //   }
          // }
          if (isDo(batterRecords[key][k].name)) {
            if (localSummary['병살타']) {
              localSummary['병살타'].push(
                `${batter?.member?.user?.name} (${key}회)`,
              );
            } else {
              localSummary['병살타'] = [
                `${batter?.member?.user?.name} (${key}회)`,
              ];
            }
          }
        }
      }
    }
    setSummary(localSummary);
  };

  const callGetCommentListApi = () => {
    if (commentPageLoading.current) {
      return;
    }
    console.log('itemId', itemId);
    setProgressShown(true);
    commentPageLoading.current = true;
    api
      .get(`/games/${itemId}/comments`, {
        headers: {
          Authorization: `Bearer ${accessToken.current}`,
        },
      })
      .then(function (response) {
        setCommentPage(response.data);
      })
      .catch(function (error) {
        console.error(error);
      })
      .finally(() => {
        commentPageLoading.current = false;
        setProgressShown(false);
      });
  };

  const handlePageLoadMore = () => {
    if (commentPageLoading.current) {
      return;
    }
    if (
      commentPage.currentPage &&
      commentPage.totalPage <= commentPage.currentPage
    ) {
      return;
    }
    setProgressShown(true);
    commentPageLoading.current = true;
    api
      .get(
        `games/${itemId}/comments?pageNo=${
          commentPage.currentPage
            ? parseInt(commentPage.currentPage, 10) + 1
            : 1
        }&pageSize=20`,
        {
          headers: {
            Authorization: `Bearer ${accessToken.current}`,
          },
        },
      )
      .then(function (response) {
        response.data.items = [
          ...(commentPage.items ? commentPage.items : []),
          ...response.data.items,
        ];
        setCommentPage(response.data);
      })
      .catch(function (error) {
        console.error(error);
      })
      .finally(() => {
        setProgressShown(false);
        commentPageLoading.current = false;
      });
  };

  const getInning = innings => {
    let point = innings - parseInt(innings, 10);
    point = Math.round(point * 10) / 10;
    return parseInt(innings, 10) + point * 0.3333 * 10;
  };

  const lastInning = useMemo(() => {
    const inningScoreLength = currentTeamRecords?.inningScore?.length;
    const serializedRecords = currentTeamRecords?.serializedRecords;
    const endInningOfSR = !serializedRecords
      ? 0
      : parseInt(serializedRecords[serializedRecords.length - 1]?.inning);
    if (inningScoreLength >= endInningOfSR) {
      return inningScoreLength;
    } else {
      return endInningOfSR;
    }
  }, [currentTeamRecords]);

  const selectFirstBatTeam = gameData => {
    setCurrentTeamId(gameData ? gameData.batFirstTeamId : data.batFirstTeamId);
    setTeamBatterLineUps(firstTeamBatterLineUps.current);
    setTeamPitcherLineUps(firstTeamPitcherLineUps.current);
    setCurrentTeamRecords(
      gameData
        ? JSON.parse(gameData?.records)?.batFirstTeam
        : JSON.parse(data?.records)?.batFirstTeam,
    );
    const pitchingRecord = gameData
      ? JSON.parse(gameData?.records)?.batFirstTeam.pitchingRecord
      : JSON.parse(data?.records)?.batFirstTeam.pitchingRecord;
    if (!pitchingRecord) {
      return;
    }

    let localTotalInnings = 0;
    let localTotalHits = 0;
    let localTotalRs = 0;
    let localTotalErs = 0;
    let localTotalBbs = 0;
    let localTotalHbps = 0;
    let localTotalSos = 0;
    for (let i = 0; i < Object.keys(pitchingRecord).length; i++) {
      const key = Object.keys(pitchingRecord)[i];
      let record = pitchingRecord[key];
      if (!record) {
        continue;
      }
      if (
        !firstTeamPitcherLineUps.current ||
        firstTeamPitcherLineUps.current?.length <= i ||
        !firstTeamPitcherLineUps.current[i]
      ) {
        continue;
      }
      if (record.inning) {
        localTotalInnings += parseFloat(record.inning);
        let point = localTotalInnings - parseInt(localTotalInnings, 10);
        point = Math.round(point * 10) / 10;
        if (point >= 0.3) {
          localTotalInnings = localTotalInnings + 1;
          localTotalInnings = localTotalInnings - 0.3;
        }
      }
      if (record.hits) {
        localTotalHits += record.hits;
      }
      if (record.r) {
        localTotalRs += record.r;
      }
      if (record.bb) {
        localTotalBbs += record.bb;
      }
      if (record.er) {
        localTotalErs += record.er;
      }
      if (record.hbp) {
        localTotalHbps += record.hbp;
      }
      if (record.so) {
        localTotalSos += record.so;
      }
    }
    if (localTotalInnings) {
      setTotalInnings(parseFloat(localTotalInnings).toFixed(1));
      setTotalEra(
        ((localTotalErs * 7) / getInning(localTotalInnings)).toFixed(2),
      );
    }
    setTotalHits(localTotalHits);
    setTotalRs(localTotalRs);
    setTotalErs(localTotalErs);
    setTotalBbs(localTotalBbs);
    setTotalHbps(localTotalHbps);
    setTotalSos(localTotalSos);
  };

  const selectLastBatTeam = () => {
    setCurrentTeamId(data.batLastTeamId);
    setTeamBatterLineUps(lastTeamBatterLineUps.current);
    setTeamPitcherLineUps(lastTeamPitcherLineUps.current);
    setCurrentTeamRecords(JSON.parse(data?.records)?.batLastTeam);
    const pitchingRecord = JSON.parse(data?.records)?.batLastTeam
      .pitchingRecord;
    if (!pitchingRecord) {
      return;
    }
    let localTotalInnings = 0;
    let localTotalHits = 0;
    let localTotalRs = 0;
    let localTotalErs = 0;
    let localTotalBbs = 0;
    let localTotalHbps = 0;
    let localTotalSos = 0;
    for (let i = 0; i < Object.keys(pitchingRecord).length; i++) {
      const key = Object.keys(pitchingRecord)[i];
      let record = pitchingRecord[key];
      if (!record) {
        continue;
      }
      if (
        !lastTeamPitcherLineUps.current ||
        !lastTeamPitcherLineUps.current[i]
      ) {
        continue;
      }
      if (record.inning) {
        localTotalInnings += parseFloat(record.inning);
        let point = localTotalInnings - parseInt(localTotalInnings, 10);
        point = Math.round(point * 10) / 10;
        if (point >= 0.3) {
          localTotalInnings = localTotalInnings + 1;
          localTotalInnings = localTotalInnings - 0.3;
        }
      }
      if (record.hits) {
        localTotalHits += record.hits;
      }
      if (record.r) {
        localTotalRs += record.r;
      }
      if (record.er) {
        localTotalErs += record.er;
      }
      if (record.bb) {
        localTotalBbs += record.bb;
      }
      if (record.hbp) {
        localTotalHbps += record.hbp;
      }
      if (record.so) {
        localTotalSos += record.so;
      }
    }
    if (localTotalInnings) {
      setTotalInnings(parseFloat(localTotalInnings).toFixed(1));
      setTotalEra(
        ((localTotalErs * 7) / getInning(localTotalInnings)).toFixed(2),
      );
    }
    setTotalHits(localTotalHits);
    setTotalRs(localTotalRs);
    setTotalErs(localTotalErs);
    setTotalBbs(localTotalBbs);
    setTotalHbps(localTotalHbps);
    setTotalSos(localTotalSos);
  };
  const parseDate = date => {
    if (date && date.indexOf('T') > -1) {
      return moment(date.substring(0, date.indexOf('T')), 'YYYY-MM-DD').format(
        'MM.DD',
      );
    }
    return moment(date, 'YYYY-MM-DD').format('MM.DD');
  };

  const parseTime = time => {
    if (time && time.indexOf(':', 4) > -1) {
      return time.substring(0, time.indexOf(':', 4));
    }
    return time;
  };

  const getProfileImageUrl = user => {
    if (user?.profileImageThumbUrl) {
      return {uri: user?.profileImageThumbUrl};
    }
    if (user?.profileImageUrl) {
      return {uri: user?.profileImageUrl};
    }
    return require('../assets/images/icn_profile_img.png');
  };

  const goToVideoView = () => {
    navigation.navigate('Video', {game: data, itemId: data.id});
  };

  const getSeasonAvg = value => {
    if (data.playoff) {
      return '0.000';
    }
    return value;
  };

  const existsVideo = useMemo(() => {
    if (!data?.fullVideoUrl) {
      return false;
    }
    if (data?.fullVideoUrl.indexOf('channel') > -1) {
      return false;
    }
    return true;
  }, [data?.fullVideoUrl]);

  useEffect(() => {
    const unsubscribe = DeviceEventEmitter.addListener(
      '@gameCommentRefresh',
      function (data) {
        callGetCommentListApi();
      },
    );
    return () => {
      unsubscribe.remove();
    };
  }, [itemId]);

  return (
    <>
      <SafeAreaView
        nativeID="common-page-container"
        style={[styles.container, pageStyle.container]}>
        <Shadow
          offset={[0, 2]}
          style={[{width: '100%'}]}
          containerStyle={[{marginTop: 0}]}
          distance={7}
          sides={{bottom: true, top: false, start: false, end: false}}>
          <View style={[styles.headerCommon]}>
            <View style={[styles.row, styles.verticalAlign]}>
              <TouchableOpacity
                onPress={() =>
                  Platform.OS === 'web' ? history.back() : navigation.goBack()
                }>
                <Image
                  source={require('../assets/images/icon_back.png')}
                  style={[styles.headerBackButton]}
                />
              </TouchableOpacity>
              <Text style={[styles.headerText]}>박스스코어</Text>
            </View>
            <View style={[styles.row, styles.verticalAlign]}>
              <Image source={require('../assets/images/icon_uniqueplay.png')} />
            </View>
          </View>
        </Shadow>
        <FlatList
          refreshControl={
            <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
          }
          onEndReachedThreshold={1}
          onEndReached={handlePageLoadMore}
          renderItem={item => (
            <>
              <View style={[pageStyle.commentItemContainer]}>
                <Image
                  style={[
                    {
                      width: 40,
                      height: 40,
                      resizeMode: 'cover',
                      borderRadius: 20,
                    },
                  ]}
                  source={getProfileImageUrl(item)}
                />

                <View style={[pageStyle.rightContainer]}>
                  <TouchableOpacity
                    onPress={() => {
                      if (!accessToken.current) {
                        showNeedLoginAlert();
                        return;
                      }
                      navigation.navigate('MemberPage', {
                        item: {uid: item.user.uid},
                        itemId: item.user.uid,
                      });
                    }}
                    style={[styles.row, {alignItems: 'center'}]}>
                    <Text style={[pageStyle.userName]}>{item?.user?.name}</Text>
                  </TouchableOpacity>
                  <Text
                    style={[
                      pageStyle.commentContent,
                      item?.deleted && {color: '#888'},
                    ]}>
                    {item.deleted ? '삭제된 댓글입니다' : item.contents}
                  </Text>
                  {item.imageUrl && (
                    <Image
                      style={[pageStyle.commentImage]}
                      source={{uri: item.imageUrl}}
                    />
                  )}
                  <View style={[styles.row, {alignItems: 'center'}]}>
                    <Text style={[pageStyle.date]}>
                      {parseDate(item.createdAt)}
                    </Text>
                    <TouchableOpacity
                      onPress={() => {
                        if (!accessToken.current) {
                          showNeedLoginAlert();
                          return;
                        }
                        navigation.navigate('GameCommentChild', {
                          parentId: item.id,
                          targetCommentId: item.id,
                          gameId: itemId,
                          comment: item,
                          userData: userData,
                          gameInfo: `${data?.batFirstTeamName} vs ${
                            data?.batLastTeamName
                          }, ${parseDate(data?.date)} / ${parseTime(
                            data?.time,
                          )}`,
                        });
                      }}
                      style={[{marginStart: 12}]}>
                      <Text style={[pageStyle.date]}>답글쓰기</Text>
                    </TouchableOpacity>
                  </View>
                </View>
              </View>
              <View>
                {item.childs &&
                  item.childs.map((child, index) => {
                    return (
                      <View style={[pageStyle.commentItemContainer]}>
                        <View style={[pageStyle.childLeft]} />
                        <Image
                          style={[
                            {
                              width: 40,
                              height: 40,
                              resizeMode: 'cover',
                              borderRadius: 20,
                            },
                          ]}
                          source={getProfileImageUrl(child)}
                        />

                        <View style={[pageStyle.rightContainer]}>
                          <TouchableOpacity
                            onPress={() => {
                              if (!accessToken.current) {
                                showNeedLoginAlert();
                                return;
                              }
                              navigation.navigate('MemberPage', {
                                item: {uid: child.user.uid},
                                itemId: child.user.uid,
                              });
                            }}
                            style={[styles.row, {alignItems: 'center'}]}>
                            <Text style={[pageStyle.userName]}>
                              {child.user.name}
                            </Text>
                          </TouchableOpacity>
                          <Text
                            style={[
                              pageStyle.commentContent,
                              child.deleted && {color: '#888'},
                            ]}>
                            <Text
                              style={[
                                {
                                  color: Colors.main,
                                  fontSize: 15,
                                  fontWeight: 'bold',
                                  fontFamily: 'Pretendard-Bold',
                                },
                              ]}>
                              {child.targetUser ? child.targetUser.name : ''}{' '}
                            </Text>
                            {child.deleted
                              ? '삭제된 댓글입니다.'
                              : child.contents}
                          </Text>
                          {child.imageUrl && (
                            <Image
                              style={[pageStyle.commentImage]}
                              source={{uri: child.imageUrl}}
                            />
                          )}
                          <View style={[styles.row, {alignItems: 'center'}]}>
                            <Text style={[pageStyle.date]}>
                              {parseDate(child.createdAt)}
                            </Text>
                            <TouchableOpacity
                              onPress={() => {
                                if (!accessToken.current) {
                                  showNeedLoginAlert();
                                  return;
                                }
                                navigation.navigate('GameCommentChild', {
                                  parentId: item.id,
                                  targetCommentId: item.id,
                                  gameId: itemId,
                                  comment: item,
                                  userData: userData,
                                  targetUserId: child.user.id,
                                  targetCommentId: child.id,
                                  gameInfo: `${data?.batFirstTeamName} vs ${
                                    data?.batLastTeamName
                                  }, ${parseDate(data?.date)} / ${parseTime(
                                    data?.time,
                                  )}`,
                                });
                              }}
                              style={[{marginStart: 12}]}>
                              <Text style={[pageStyle.date]}>답글쓰기</Text>
                            </TouchableOpacity>
                          </View>
                        </View>
                      </View>
                    );
                  })}
              </View>
            </>
          )}
          ListHeaderComponent={
            <View
              style={[
                styles.scrollViewContainer,
                pageStyle.scrollViewContainer,
              ]}>
              <View style={[pageStyle.topContainer]}>
                <View style={[pageStyle.row]}>
                  <TouchableOpacity
                    onPress={() =>
                      navigation.navigate('TeamDetail', {
                        itemId: data?.batFirstTeamId,
                      })
                    }
                    style={[
                      pageStyle.teamContainer,
                      pageStyle.batFirstTeamContainer,
                    ]}>
                    <Shadow offset={[3, 4]} distance={4}>
                      <View style={[pageStyle.logoContainer]}>
                        <Image
                          style={[pageStyle.logoImg]}
                          source={
                            !data?.batFirstTeamLogoImageUrl
                              ? require('../assets/images/nologo.png')
                              : {uri: data?.batFirstTeamLogoImageUrl}
                          }
                          resizeMode={'cover'}
                        />
                      </View>
                    </Shadow>
                  </TouchableOpacity>
                  <View style={[pageStyle.meta]}>
                    <View>
                      <Text style={[pageStyle.metaTxt]}>
                        {parseDate(data?.date)} / {parseTime(data?.time)}
                      </Text>
                      <Text style={[pageStyle.metaTxt]}>
                        {data?.leagueName}
                      </Text>
                      <Text
                        style={[
                          pageStyle.metaTxt,
                          {marginTop: 2, maxWidth: 100},
                        ]}
                        numberOfLines={1}>
                        {data?.stadiumName}
                      </Text>
                      <View style={[pageStyle.scoreContainer]}>
                        <Text style={[pageStyle.scoreItemContainer]}>
                          {data?.records &&
                            JSON.parse(data?.records)?.batFirstTeam?.score}
                        </Text>
                        <Text style={[pageStyle.scoreItemContainer]}>:</Text>
                        <Text style={[pageStyle.scoreItemContainer]}>
                          {data?.records &&
                            JSON.parse(data?.records)?.batLastTeam?.score}
                        </Text>
                      </View>
                    </View>
                  </View>
                  <TouchableOpacity
                    onPress={() =>
                      navigation.navigate('TeamDetail', {
                        itemId: data?.batLastTeamId,
                      })
                    }
                    style={[
                      pageStyle.teamContainer,
                      pageStyle.batLastTeamContainer,
                    ]}>
                    <Shadow offset={[3, 4]} distance={4}>
                      <View style={[pageStyle.logoContainer]}>
                        <Image
                          style={[pageStyle.logoImg]}
                          source={
                            !data?.batLastTeamLogoImageUrl
                              ? require('../assets/images/nologo.png')
                              : {uri: data?.batLastTeamLogoImageUrl}
                          }
                          resizeMode={'cover'}
                        />
                      </View>
                    </Shadow>
                  </TouchableOpacity>
                </View>
                <View style={[pageStyle.row, pageStyle.central]}>
                  <View
                    style={[
                      pageStyle.bottomContainer,
                      pageStyle.teamContainer,
                      pageStyle.batFirstTeamContainer,
                    ]}>
                    <Text style={[pageStyle.teamName]} numberOfLines={1}>
                      {data?.batFirstTeamName}
                    </Text>
                  </View>
                  <View style={[pageStyle.meta]}>
                    <View
                      style={[
                        pageStyle.button,
                        existsVideo && pageStyle.buttonActive,
                      ]}>
                      <TouchableOpacity
                        disabled={!existsVideo}
                        onPress={goToVideoView}>
                        <Text
                          style={[
                            pageStyle.buttonText,
                            existsVideo && pageStyle.buttonActiveTxt,
                          ]}>
                          경기 영상
                        </Text>
                      </TouchableOpacity>
                    </View>
                  </View>
                  <View
                    style={[
                      pageStyle.bottomContainer,
                      pageStyle.teamContainer,
                      pageStyle.batLastTeamContainer,
                    ]}>
                    <Text style={[pageStyle.teamName]} numberOfLines={1}>
                      {data?.batLastTeamName}
                    </Text>
                  </View>
                </View>
              </View>
              <View style={[pageStyle.scoreTable]}>
                <View style={[pageStyle.trRow]}>
                  <View
                    style={[
                      pageStyle.tdContainerTeam,
                      Platform.OS === 'web' && {height: 80},
                    ]}>
                    <Text
                      style={[pageStyle.tdInning, pageStyle.tdInningFirst]}
                    />
                    <Text
                      style={[pageStyle.td, pageStyle.tdTeam]}
                      numberOfLines={1}>
                      {data?.batFirstTeamName}
                    </Text>
                    <Text
                      style={[pageStyle.td, pageStyle.tdTeam]}
                      numberOfLines={1}>
                      {data?.batLastTeamName}
                    </Text>
                  </View>
                  <ScrollView
                    horizontal={true}
                    showsHorizontalScrollIndicator={Platform.OS === 'web'}
                    style={{
                      width: ((Dimensions.get('window').width - 30) / 13) * 7,
                      flexGrow: 1,
                    }}>
                    {data &&
                      data.records &&
                      JSON.parse(data?.records)?.batFirstTeam?.inningScore.map(
                        (item, index) => {
                          return (
                            <View
                              style={[
                                pageStyle.tdContainer,
                                Platform.OS === 'web' && {height: 80},
                                pageStyle.tdInnerContainer,
                              ]}>
                              <Text style={[pageStyle.tdInning]}>
                                {index + 1}
                              </Text>
                              <Text style={[pageStyle.td]}>{item}</Text>
                              <Text style={[pageStyle.td]}>
                                {
                                  JSON.parse(data?.records)?.batLastTeam
                                    ?.inningScore[index]
                                }
                              </Text>
                            </View>
                          );
                        },
                      )}
                    {data &&
                      data.records &&
                      JSON.parse(data?.records)?.batFirstTeam?.inningScore
                        ?.length < 7 &&
                      new Array(
                        7 -
                          JSON.parse(data?.records)?.batFirstTeam?.inningScore
                            ?.length,
                      )
                        .fill()
                        .map((_, index) => {
                          return (
                            <View
                              style={[
                                pageStyle.tdContainer,
                                pageStyle.tdInnerContainer,
                                Platform.OS === 'web' && {height: 80},
                              ]}>
                              <Text style={[pageStyle.tdInning]}>
                                {JSON.parse(data?.records)?.batFirstTeam
                                  ?.inningScore?.length +
                                  index +
                                  1}
                              </Text>
                              <Text style={[pageStyle.td]} />
                              <Text style={[pageStyle.td]} />
                            </View>
                          );
                        })}
                  </ScrollView>
                  <View
                    style={[
                      pageStyle.tdContainer,
                      Platform.OS === 'web' && {height: 80},
                    ]}>
                    <Text style={[pageStyle.tdInning, pageStyle.tdInningR]}>
                      R
                    </Text>
                    <Text style={[pageStyle.td, pageStyle.tdR]}>
                      {data?.records &&
                        JSON.parse(data?.records)?.batFirstTeam?.score}
                    </Text>
                    <Text style={[pageStyle.td, pageStyle.tdR]}>
                      {data?.records &&
                        JSON.parse(data?.records)?.batLastTeam?.score}
                    </Text>
                  </View>
                  <View
                    style={[
                      pageStyle.tdContainer,
                      Platform.OS === 'web' && {height: 80},
                    ]}>
                    <Text style={[pageStyle.tdInning, pageStyle.tdInningR]}>
                      H
                    </Text>
                    <Text style={[pageStyle.td, pageStyle.tdR]}>
                      {data?.records &&
                        JSON.parse(data?.records)?.batFirstTeam?.hits}
                    </Text>
                    <Text style={[pageStyle.td, pageStyle.tdR]}>
                      {data?.records &&
                        JSON.parse(data?.records)?.batLastTeam?.hits}
                    </Text>
                  </View>
                  <View
                    style={[
                      pageStyle.tdContainer,
                      Platform.OS === 'web' && {height: 80},
                    ]}>
                    <Text style={[pageStyle.tdInning, pageStyle.tdInningR]}>
                      E
                    </Text>
                    <Text style={[pageStyle.td, pageStyle.tdR]}>
                      {data?.records &&
                        JSON.parse(data?.records)?.batFirstTeam?.error}
                    </Text>
                    <Text style={[pageStyle.td, pageStyle.tdR]}>
                      {data?.records &&
                        JSON.parse(data?.records)?.batLastTeam?.error}
                    </Text>
                  </View>
                  <View
                    style={[
                      pageStyle.tdContainer,
                      Platform.OS === 'web' && {height: 80},
                    ]}>
                    <Text style={[pageStyle.tdInning, pageStyle.tdInningR]}>
                      B
                    </Text>
                    <Text style={[pageStyle.td, pageStyle.tdR]}>
                      {data?.records &&
                        JSON.parse(data?.records)?.batFirstTeam?.bb}
                    </Text>
                    <Text style={[pageStyle.td, pageStyle.tdR]}>
                      {data?.records &&
                        JSON.parse(data?.records)?.batLastTeam?.bb}
                    </Text>
                  </View>
                </View>
              </View>
              <View style={[pageStyle.pitchingSummary]}>
                <View style={[pageStyle.firstBatPitching]}>
                  {firstTeamResultPitcher && (
                    <TouchableOpacity
                      onPress={() =>
                        navigation.navigate('MemberPage', {
                          item: firstTeamResultPitcher.item?.member?.user,
                          itemId: firstTeamResultPitcher.item?.member?.user.uid,
                        })
                      }>
                      <View style={[styles.row]}>
                        <Image
                          style={[pageStyle.starterImg]}
                          source={getProfileImageUrl(
                            firstTeamResultPitcher.item?.member?.user,
                          )}
                        />
                        <View>
                          <View style={[styles.row, styles.mt3]}>
                            <Text style={[pageStyle.pLabel]}>이닝</Text>
                            <Text style={[pageStyle.pValue]}>
                              {data?.records &&
                                JSON.parse(data?.records)?.batFirstTeam
                                  ?.pitchingRecord[
                                  firstTeamResultPitcher.item?.pitchingOrder
                                ] &&
                                JSON.parse(data?.records)?.batFirstTeam
                                  ?.pitchingRecord[
                                  firstTeamResultPitcher.item?.pitchingOrder
                                ].inning}
                            </Text>
                          </View>
                          <View style={[styles.row, styles.mt7]}>
                            <Text style={[pageStyle.pLabel]}>피안타</Text>
                            <Text style={[pageStyle.pValue]}>
                              {data?.records &&
                                JSON.parse(data?.records)?.batFirstTeam
                                  ?.pitchingRecord[
                                  firstTeamResultPitcher.item?.pitchingOrder
                                ] &&
                                JSON.parse(data?.records)?.batFirstTeam
                                  ?.pitchingRecord[
                                  firstTeamResultPitcher.item?.pitchingOrder
                                ].hits}
                            </Text>
                            <Text style={[pageStyle.pLabel]}>실점</Text>
                            <Text style={[pageStyle.pValue]}>
                              {data?.records &&
                                JSON.parse(data?.records)?.batFirstTeam
                                  ?.pitchingRecord[
                                  firstTeamResultPitcher.item?.pitchingOrder
                                ] &&
                                JSON.parse(data?.records)?.batFirstTeam
                                  ?.pitchingRecord[
                                  firstTeamResultPitcher.item?.pitchingOrder
                                ].er}
                            </Text>
                          </View>
                        </View>
                      </View>
                      <Text
                        style={[
                          pageStyle.wl,
                          firstTeamResultPitcher?.result === '패' &&
                            pageStyle.lost,
                          firstTeamResultPitcher?.result === '승' &&
                            pageStyle.win,
                        ]}>
                        {firstTeamResultPitcher?.result} -{' '}
                        {firstTeamResultPitcher?.item?.member?.user?.name}
                      </Text>
                    </TouchableOpacity>
                  )}
                </View>
                <View style={[pageStyle.lastBatPitching]}>
                  {lastTeamResultPitcher && (
                    <TouchableOpacity
                      onPress={() =>
                        navigation.navigate('MemberPage', {
                          item: lastTeamResultPitcher.item?.member?.user,
                          itemId: lastTeamResultPitcher.item?.member?.user.uid,
                        })
                      }
                      style={[pageStyle.alignR]}>
                      <View style={[styles.row]}>
                        <View>
                          <View
                            style={[styles.row, pageStyle.alignR, styles.mt3]}>
                            <Text style={[pageStyle.pLabel]}>이닝</Text>
                            <Text style={[pageStyle.pValue]}>
                              {data?.records &&
                                JSON.parse(data?.records)?.batLastTeam
                                  ?.pitchingRecord[
                                  lastTeamResultPitcher.item?.pitchingOrder
                                ] &&
                                JSON.parse(data?.records)?.batLastTeam
                                  ?.pitchingRecord[
                                  lastTeamResultPitcher.item?.pitchingOrder
                                ].inning}
                            </Text>
                          </View>
                          <View style={[styles.row, styles.mt7]}>
                            <Text style={[pageStyle.pLabel]}>피안타</Text>
                            <Text style={[pageStyle.pValue]}>
                              {data?.records &&
                                JSON.parse(data?.records)?.batLastTeam
                                  ?.pitchingRecord[
                                  lastTeamResultPitcher.item?.pitchingOrder
                                ] &&
                                JSON.parse(data?.records)?.batLastTeam
                                  ?.pitchingRecord[
                                  lastTeamResultPitcher.item?.pitchingOrder
                                ].hits}
                            </Text>
                            <Text style={[pageStyle.pLabel]}>실점</Text>
                            <Text style={[pageStyle.pValue]}>
                              {data?.records &&
                                JSON.parse(data?.records)?.batLastTeam
                                  ?.pitchingRecord[
                                  lastTeamResultPitcher.item?.pitchingOrder
                                ] &&
                                JSON.parse(data?.records)?.batLastTeam
                                  ?.pitchingRecord[
                                  lastTeamResultPitcher.item?.pitchingOrder
                                ].er}
                            </Text>
                          </View>
                        </View>
                        <Image
                          style={[pageStyle.starterImg]}
                          source={getProfileImageUrl(
                            lastTeamResultPitcher.member?.user,
                          )}
                        />
                      </View>
                      <Text
                        style={[
                          {alignSelf: 'flex-end'},
                          pageStyle.alignR,
                          pageStyle.wl,
                          lastTeamResultPitcher?.result === '패' &&
                            pageStyle.lost,
                          lastTeamResultPitcher?.result === '승' &&
                            pageStyle.win,
                        ]}>
                        {lastTeamResultPitcher?.result} -{' '}
                        {lastTeamResultPitcher?.item?.member?.user?.name}
                      </Text>
                    </TouchableOpacity>
                  )}
                </View>
              </View>
              <View style={[pageStyle.barGroupContainer]}>
                <View style={[pageStyle.barContainer]}>
                  <View
                    style={[pageStyle.graphContainer, pageStyle.firstBatGraph]}>
                    <View
                      style={[
                        pageStyle.firstBatGraphActive,
                        {
                          _width: calculatePercentage(
                            data?.records &&
                              JSON.parse(data?.records)?.batFirstTeam?.hits,
                            data?.records &&
                              JSON.parse(data?.records)?.batLastTeam?.hits,
                          ),
                          get width() {
                            return this._width;
                          },
                          set width(value) {
                            this._width = value;
                          },
                        },
                      ]}
                    />
                  </View>
                  <View style={[pageStyle.graphTextContainer]}>
                    <Text
                      style={[
                        pageStyle.graphValue,
                        pageStyle.graphLabelFirstBat,
                      ]}>
                      {data?.records &&
                        JSON.parse(data?.records)?.batFirstTeam?.hits}
                    </Text>
                    <Text style={[pageStyle.graphLabel]}>안타</Text>
                    <Text
                      style={[
                        pageStyle.graphValue,
                        pageStyle.graphLabelLastBat,
                      ]}>
                      {data?.records &&
                        JSON.parse(data?.records)?.batLastTeam?.hits}
                    </Text>
                  </View>
                  <View
                    style={[pageStyle.graphContainer, pageStyle.lastBatGraph]}>
                    <View
                      style={[
                        pageStyle.lastBatGraphActive,
                        {
                          width: calculatePercentage(
                            data?.records &&
                              JSON.parse(data?.records)?.batLastTeam?.hits,
                            data?.records &&
                              JSON.parse(data?.records)?.batFirstTeam?.hits,
                          ),
                        },
                      ]}
                    />
                  </View>
                </View>
                <View style={[pageStyle.barContainer]}>
                  <View
                    style={[pageStyle.graphContainer, pageStyle.firstBatGraph]}>
                    <View
                      style={[
                        pageStyle.firstBatGraphActive,
                        {
                          width: calculatePercentage(
                            data?.records &&
                              JSON.parse(data?.records)?.batFirstTeam?.hr,
                            data?.records &&
                              JSON.parse(data?.records)?.batLastTeam?.hr,
                          ),
                        },
                      ]}
                    />
                  </View>
                  <View style={[pageStyle.graphTextContainer]}>
                    <Text
                      style={[
                        pageStyle.graphValue,
                        pageStyle.graphLabelFirstBat,
                      ]}>
                      {data?.records &&
                        JSON.parse(data?.records)?.batFirstTeam?.hr}
                    </Text>
                    <Text style={[pageStyle.graphLabel]}>홈런</Text>
                    <Text
                      style={[
                        pageStyle.graphValue,
                        pageStyle.graphLabelLastBat,
                      ]}>
                      {data?.records &&
                        JSON.parse(data?.records)?.batLastTeam?.hr}
                    </Text>
                  </View>
                  <View
                    style={[pageStyle.graphContainer, pageStyle.lastBatGraph]}>
                    <View
                      style={[
                        pageStyle.lastBatGraphActive,
                        {
                          width: calculatePercentage(
                            data?.records &&
                              JSON.parse(data?.records)?.batLastTeam?.hr,
                            data?.records &&
                              JSON.parse(data?.records)?.batFirstTeam?.hr,
                          ),
                        },
                      ]}
                    />
                  </View>
                </View>
                <View style={[pageStyle.barContainer]}>
                  <View
                    style={[pageStyle.graphContainer, pageStyle.firstBatGraph]}>
                    <View
                      style={[
                        pageStyle.firstBatGraphActive,
                        {
                          width: calculatePercentage(
                            data?.records &&
                              JSON.parse(data?.records)?.batFirstTeam?.sb,
                            data?.records &&
                              JSON.parse(data?.records)?.batLastTeam?.sb,
                          ),
                        },
                      ]}
                    />
                  </View>
                  <View style={[pageStyle.graphTextContainer]}>
                    <Text
                      style={[
                        pageStyle.graphValue,
                        pageStyle.graphLabelFirstBat,
                      ]}>
                      {data?.records &&
                        JSON.parse(data?.records)?.batFirstTeam?.sb}
                    </Text>
                    <Text style={[pageStyle.graphLabel]}>도루</Text>
                    <Text
                      style={[
                        pageStyle.graphValue,
                        pageStyle.graphLabelLastBat,
                      ]}>
                      {data?.records &&
                        JSON.parse(data?.records)?.batLastTeam?.sb}
                    </Text>
                  </View>
                  <View
                    style={[pageStyle.graphContainer, pageStyle.lastBatGraph]}>
                    <View
                      style={[
                        pageStyle.lastBatGraphActive,
                        {
                          width: calculatePercentage(
                            data?.records &&
                              JSON.parse(data?.records)?.batLastTeam?.sb,
                            data?.records &&
                              JSON.parse(data?.records)?.batFirstTeam?.sb,
                          ),
                        },
                      ]}
                    />
                  </View>
                </View>
                <View style={[pageStyle.barContainer]}>
                  <View
                    style={[pageStyle.graphContainer, pageStyle.firstBatGraph]}>
                    <View
                      style={[
                        pageStyle.firstBatGraphActive,
                        {
                          width: calculatePercentage(
                            data?.records &&
                              JSON.parse(data?.records)?.batFirstTeam?.so,
                            data?.records &&
                              JSON.parse(data?.records)?.batLastTeam?.so,
                          ),
                        },
                      ]}
                    />
                  </View>
                  <View style={[pageStyle.graphTextContainer]}>
                    <Text
                      style={[
                        pageStyle.graphValue,
                        pageStyle.graphLabelFirstBat,
                      ]}>
                      {data?.records &&
                        JSON.parse(data?.records)?.batFirstTeam?.so}
                    </Text>
                    <Text style={[pageStyle.graphLabel]}>삼진</Text>
                    <Text
                      style={[
                        pageStyle.graphValue,
                        pageStyle.graphLabelLastBat,
                      ]}>
                      {data?.records &&
                        JSON.parse(data?.records)?.batLastTeam?.so}
                    </Text>
                  </View>
                  <View
                    style={[pageStyle.graphContainer, pageStyle.lastBatGraph]}>
                    <View
                      style={[
                        pageStyle.lastBatGraphActive,
                        {
                          width: calculatePercentage(
                            data?.records &&
                              JSON.parse(data?.records)?.batLastTeam?.so,
                            data?.records &&
                              JSON.parse(data?.records)?.batFirstTeam?.so,
                          ),
                        },
                      ]}
                    />
                  </View>
                </View>
                <View style={[pageStyle.barContainer]}>
                  <View
                    style={[pageStyle.graphContainer, pageStyle.firstBatGraph]}>
                    <View
                      style={[
                        pageStyle.firstBatGraphActive,
                        {
                          width: calculatePercentage(
                            data?.records &&
                              JSON.parse(data?.records)?.batFirstTeam?.error,
                            data?.records &&
                              JSON.parse(data?.records)?.batLastTeam?.error,
                          ),
                        },
                      ]}
                    />
                  </View>
                  <View style={[pageStyle.graphTextContainer]}>
                    <Text
                      style={[
                        pageStyle.graphValue,
                        pageStyle.graphLabelFirstBat,
                      ]}>
                      {data?.records &&
                        JSON.parse(data?.records)?.batFirstTeam?.error}
                    </Text>
                    <Text style={[pageStyle.graphLabel]}>실책</Text>
                    <Text
                      style={[
                        pageStyle.graphValue,
                        pageStyle.graphLabelLastBat,
                      ]}>
                      {data?.records &&
                        JSON.parse(data?.records)?.batLastTeam?.error}
                    </Text>
                  </View>
                  <View
                    style={[pageStyle.graphContainer, pageStyle.lastBatGraph]}>
                    <View
                      style={[
                        pageStyle.lastBatGraphActive,
                        {
                          width: calculatePercentage(
                            data?.records &&
                              JSON.parse(data?.records)?.batLastTeam?.error,
                            data?.records &&
                              JSON.parse(data?.records)?.batFirstTeam?.error,
                          ),
                        },
                      ]}
                    />
                  </View>
                </View>
                <View style={[pageStyle.barContainer]}>
                  <View
                    style={[pageStyle.graphContainer, pageStyle.firstBatGraph]}>
                    <View
                      style={[
                        pageStyle.firstBatGraphActive,
                        {
                          width: calculatePercentage(
                            data?.records &&
                              JSON.parse(data?.records)?.batFirstTeam?.bb,
                            data?.records &&
                              JSON.parse(data?.records)?.batLastTeam?.bb,
                          ),
                        },
                      ]}
                    />
                  </View>
                  <View style={[pageStyle.graphTextContainer]}>
                    <Text
                      style={[
                        pageStyle.graphValue,
                        pageStyle.graphLabelFirstBat,
                      ]}>
                      {data?.records &&
                        JSON.parse(data?.records)?.batFirstTeam?.bb}
                    </Text>
                    <Text style={[pageStyle.graphLabel]}>볼넷</Text>
                    <Text
                      style={[
                        pageStyle.graphValue,
                        pageStyle.graphLabelLastBat,
                      ]}>
                      {data?.records &&
                        JSON.parse(data?.records)?.batLastTeam?.bb}
                    </Text>
                  </View>
                  <View
                    style={[pageStyle.graphContainer, pageStyle.lastBatGraph]}>
                    <View
                      style={[
                        pageStyle.lastBatGraphActive,
                        {
                          width: calculatePercentage(
                            data?.records &&
                              JSON.parse(data?.records)?.batLastTeam?.bb,
                            data?.records &&
                              JSON.parse(data?.records)?.batFirstTeam?.bb,
                          ),
                        },
                      ]}
                    />
                  </View>
                </View>
              </View>
              <View style={[pageStyle.border]} />
              <View style={[pageStyle.battingGroupContainer]}>
                {Object.keys(summary).map((key, index) => {
                  return (
                    <View style={[pageStyle.battingItemRow, styles.row]}>
                      <Text style={[pageStyle.battingLabel]}>{key}</Text>
                      <Text style={[pageStyle.battingValue]}>
                        {summary[key].join(',')}
                      </Text>
                    </View>
                  );
                })}
              </View>
              <View style={[pageStyle.teamToggleContainer]}>
                <TouchableOpacity
                  onPress={() => selectFirstBatTeam(data)}
                  style={[
                    pageStyle.teamToggle,
                    pageStyle.teamToggleLeftContainer,
                    isFirstTeam && pageStyle.teamToggleSelected,
                  ]}>
                  <Text
                    style={[
                      pageStyle.teamToggleTxt,
                      isFirstTeam && pageStyle.teamToggleTextSelected,
                    ]}>
                    {data?.batFirstTeamName}
                  </Text>
                </TouchableOpacity>
                <TouchableOpacity
                  onPress={selectLastBatTeam}
                  style={[
                    pageStyle.teamToggle,
                    pageStyle.teamToggleRightContainer,
                    !isFirstTeam && pageStyle.teamToggleSelected,
                  ]}>
                  <Text
                    style={[
                      pageStyle.teamToggleTxt,
                      !isFirstTeam && pageStyle.teamToggleTextSelected,
                    ]}>
                    {data?.batLastTeamName}
                  </Text>
                </TouchableOpacity>
              </View>
              <View style={[pageStyle.battingTable]}>
                <View style={[pageStyle.battingInnerThContainer]}>
                  <Text style={[pageStyle.th, pageStyle.firstTd]}>타자</Text>
                  {teamBatterLineUps?.map((item, index) => {
                    return (
                      <TouchableOpacity
                        onPress={() =>
                          navigation.navigate('MemberPage', {
                            item: item.member.user,
                            itemId: item.member.user.uid,
                          })
                        }>
                        <Text
                          numberOfLines={1}
                          style={[
                            pageStyle.th,
                            pageStyle.firstTd,
                            pageStyle.td2,
                            index % 2 === 0 && pageStyle.odd,
                            {textAlign: 'left', paddingStart: 8},
                          ]}>
                          {item.battingOrder}
                          &nbsp;
                          <Text style={[pageStyle.position]}>
                            {getPositionName(item.position)}
                          </Text>
                          &nbsp;
                          {item.member.user?.name}
                          &nbsp;
                          <Text
                            style={[
                              pageStyle.position,
                              {
                                fontWeight: 'normal',
                                fontSize: 11,
                                fontFamily: 'Pretendard',
                              },
                            ]}>
                            ({item.member.number})
                          </Text>
                        </Text>
                      </TouchableOpacity>
                    );
                  })}
                  <Text
                    style={[pageStyle.th, pageStyle.firstTd, pageStyle.td2]}>
                    합계
                  </Text>
                </View>
                {/* <View style={[{width: 40}]}>
                  <Text style={[pageStyle.th, {width: 40}]}>등번호</Text>
                  {teamBatterLineUps.map((item, index) => {
                    return (
                      <Text
                        numberOfLines={1}
                        style={[
                          pageStyle.th,
                          pageStyle.td2,
                          index % 2 === 0 && pageStyle.odd,
                          {
                            width: 40,
                            fontWeight: 'normal',
                            textAlign: 'center',
                          },
                        ]}>
                        {item.member.number}
                      </Text>
                    );
                  })}
                  <Text
                    style={[
                      pageStyle.th,
                      pageStyle.td2,
                      teamBatterLineUps.length % 2 === 0 && pageStyle.odd,
                      {width: 45},
                    ]}
                  />
                </View> */}
                <ScrollView
                  showsHorizontalScrollIndicator={Platform.OS === 'web'}
                  horizontal={true}
                  style={{
                    width:
                      6 *
                      ((Platform.OS === 'web'
                        ? 550
                        : Dimensions.get('window').width - 30) /
                        8),
                  }}>
                  {Array.from({length: lastInning})?.map((item, index) => {
                    return (
                      <View style={[pageStyle.battingInnerTdContainer]}>
                        <Text style={[pageStyle.th]}>{index + 1}</Text>
                        {teamBatterLineUps?.map((batterItem, batterIndex) => {
                          return (
                            <View
                              style={[
                                pageStyle.td2Container,
                                batterIndex % 2 === 0 && pageStyle.odd,
                              ]}>
                              <Text
                                numberOfLines={2}
                                adjustsFontSizeToFit={true}
                                style={[
                                  pageStyle.td2Value,
                                  batterIndex % 2 === 0 && pageStyle.odd,
                                ]}>
                                {currentTeamRecords?.battingRecords &&
                                  currentTeamRecords?.battingRecords[
                                    getBattingOrder(batterItem)
                                  ] &&
                                  currentTeamRecords?.battingRecords[
                                    getBattingOrder(batterItem)
                                  ][index + 1] &&
                                  currentTeamRecords?.battingRecords &&
                                  currentTeamRecords?.battingRecords[
                                    getBattingOrder(batterItem)
                                  ][index + 1]
                                    .map(i => i.name)
                                    .join(',')}
                              </Text>
                            </View>
                          );
                        })}
                        <Text
                          style={[
                            pageStyle.td2,
                            teamBatterLineUps?.length % 2 === 0 &&
                              pageStyle.odd,
                          ]}
                        />
                      </View>
                    );
                  })}
                  <View style={[pageStyle.battingInnerTdContainer]}>
                    <Text style={[pageStyle.th]}>타수</Text>
                    {teamBatterLineUps?.map((batterItem, batterIndex) => {
                      return (
                        <Text
                          style={[
                            pageStyle.td2,
                            batterIndex % 2 === 0 && pageStyle.odd,
                          ]}>
                          {currentTeamRecords?.battingRecords &&
                            currentTeamRecords?.battingRecords[
                              !batterItem.battingSubOrder
                                ? batterItem.battingOrder
                                : `${batterItem.battingOrder}_${batterItem.battingSubOrder}`
                            ] &&
                            currentTeamRecords?.battingRecords[
                              !batterItem.battingSubOrder
                                ? batterItem.battingOrder
                                : `${batterItem.battingOrder}_${batterItem.battingSubOrder}`
                            ].ab | 0}
                        </Text>
                      );
                    })}
                    <Text
                      style={[
                        pageStyle.td2,
                        teamBatterLineUps?.length % 2 === 0 && pageStyle.odd,
                      ]}>
                      {currentTeamRecords?.ab}
                    </Text>
                  </View>
                  <View style={[pageStyle.battingInnerTdContainer]}>
                    <Text style={[pageStyle.th]}>안타</Text>
                    {teamBatterLineUps?.map((batterItem, batterIndex) => {
                      return (
                        <Text
                          style={[
                            pageStyle.td2,
                            batterIndex % 2 === 0 && pageStyle.odd,
                          ]}>
                          {currentTeamRecords?.battingRecords &&
                            currentTeamRecords?.battingRecords[
                              !batterItem.battingSubOrder
                                ? batterItem.battingOrder
                                : `${batterItem.battingOrder}_${batterItem.battingSubOrder}`
                            ] &&
                            currentTeamRecords?.battingRecords[
                              !batterItem.battingSubOrder
                                ? batterItem.battingOrder
                                : `${batterItem.battingOrder}_${batterItem.battingSubOrder}`
                            ].hit | 0}
                        </Text>
                      );
                    })}
                    <Text
                      style={[
                        pageStyle.td2,
                        teamBatterLineUps?.length % 2 === 0 && pageStyle.odd,
                      ]}>
                      {currentTeamRecords?.hits}
                    </Text>
                  </View>
                  <View style={[pageStyle.battingInnerTdContainer]}>
                    <Text style={[pageStyle.th]}>타점</Text>
                    {teamBatterLineUps?.map((batterItem, batterIndex) => {
                      return (
                        <Text
                          style={[
                            pageStyle.td2,
                            batterIndex % 2 === 0 && pageStyle.odd,
                          ]}>
                          {currentTeamRecords?.battingRecords &&
                            currentTeamRecords?.battingRecords[
                              !batterItem.battingSubOrder
                                ? batterItem.battingOrder
                                : `${batterItem.battingOrder}_${batterItem.battingSubOrder}`
                            ] &&
                            currentTeamRecords?.battingRecords[
                              !batterItem.battingSubOrder
                                ? batterItem.battingOrder
                                : `${batterItem.battingOrder}_${batterItem.battingSubOrder}`
                            ].rbi | 0}
                        </Text>
                      );
                    })}
                    <Text
                      style={[
                        pageStyle.td2,
                        teamBatterLineUps?.length % 2 === 0 && pageStyle.odd,
                      ]}>
                      {currentTeamRecords?.rbis}
                    </Text>
                  </View>
                  <View style={[pageStyle.battingInnerTdContainer]}>
                    <Text style={[pageStyle.th]}>도루</Text>
                    {teamBatterLineUps?.map((batterItem, batterIndex) => {
                      return (
                        <Text
                          style={[
                            pageStyle.td2,
                            batterIndex % 2 === 0 && pageStyle.odd,
                          ]}>
                          {currentTeamRecords?.battingRecords &&
                            currentTeamRecords?.battingRecords[
                              !batterItem.battingSubOrder
                                ? batterItem.battingOrder
                                : `${batterItem.battingOrder}_${batterItem.battingSubOrder}`
                            ] &&
                            currentTeamRecords?.battingRecords[
                              !batterItem.battingSubOrder
                                ? batterItem.battingOrder
                                : `${batterItem.battingOrder}_${batterItem.battingSubOrder}`
                            ].sb | 0}
                        </Text>
                      );
                    })}
                    <Text
                      style={[
                        pageStyle.td2,
                        teamBatterLineUps?.length % 2 === 0 && pageStyle.odd,
                      ]}>
                      {currentTeamRecords?.sb}
                    </Text>
                  </View>
                  <View style={[pageStyle.battingInnerTdContainer]}>
                    <Text style={[pageStyle.th]}>득점</Text>
                    {teamBatterLineUps?.map((batterItem, batterIndex) => {
                      return (
                        <Text
                          style={[
                            pageStyle.td2,
                            batterIndex % 2 === 0 && pageStyle.odd,
                          ]}>
                          {currentTeamRecords?.battingRecords &&
                            currentTeamRecords?.battingRecords[
                              !batterItem.battingSubOrder
                                ? batterItem.battingOrder
                                : `${batterItem.battingOrder}_${batterItem.battingSubOrder}`
                            ] &&
                            currentTeamRecords?.battingRecords[
                              !batterItem.battingSubOrder
                                ? batterItem.battingOrder
                                : `${batterItem.battingOrder}_${batterItem.battingSubOrder}`
                            ].r | 0}
                        </Text>
                      );
                    })}
                    <Text
                      style={[
                        pageStyle.td2,
                        teamBatterLineUps?.length % 2 === 0 && pageStyle.odd,
                      ]}>
                      {currentTeamRecords?.r}
                    </Text>
                  </View>
                  <View style={[pageStyle.battingInnerTdContainer]}>
                    <Text style={[pageStyle.th]}>타율</Text>
                    {teamBatterLineUps?.map((batterItem, batterIndex) => {
                      return (
                        <Text
                          style={[
                            pageStyle.td2,
                            batterIndex % 2 === 0 && pageStyle.odd,
                          ]}>
                          {currentTeamRecords?.battingRecords &&
                            currentTeamRecords?.battingRecords[
                              !batterItem.battingSubOrder
                                ? batterItem.battingOrder
                                : `${batterItem.battingOrder}_${batterItem.battingSubOrder}`
                            ] &&
                            filterNaN(
                              currentTeamRecords?.battingRecords[
                                !batterItem.battingSubOrder
                                  ? batterItem.battingOrder
                                  : `${batterItem.battingOrder}_${batterItem.battingSubOrder}`
                              ].avg,
                              '0.000',
                            )}
                        </Text>
                      );
                    })}
                    <Text
                      style={[
                        pageStyle.td2,
                        teamBatterLineUps?.length % 2 === 0 && pageStyle.odd,
                      ]}>
                      {currentTeamRecords?.avg}
                    </Text>
                  </View>
                  <View style={[pageStyle.battingInnerTdContainer]}>
                    <Text style={[pageStyle.th]} numberOfLines={1}>
                      시즌타율
                    </Text>
                    {teamBatterLineUps?.map((batterItem, batterIndex) => {
                      return (
                        <Text
                          style={[
                            pageStyle.td2,
                            batterIndex % 2 === 0 && pageStyle.odd,
                          ]}>
                          {getSeasonAvg(
                            getOrDefault(
                              currentTeamRecords?.battingRecords &&
                                currentTeamRecords?.battingRecords[
                                  !batterItem.battingSubOrder
                                    ? batterItem.battingOrder
                                    : `${batterItem.battingOrder}_${batterItem.battingSubOrder}`
                                ] &&
                                currentTeamRecords?.battingRecords[
                                  !batterItem.battingSubOrder
                                    ? batterItem.battingOrder
                                    : `${batterItem.battingOrder}_${batterItem.battingSubOrder}`
                                ]?.seasonAvg,
                              currentTeamRecords?.battingRecords &&
                                filterNaN(
                                  parseFloat(
                                    currentTeamRecords?.battingRecords[
                                      !batterItem.battingSubOrder
                                        ? batterItem.battingOrder
                                        : `${batterItem.battingOrder}_${batterItem.battingSubOrder}`
                                    ]?.seasonAvg,
                                  ).toFixed(3),
                                  '0.000',
                                ),
                              '0.000',
                            ),
                          )}
                        </Text>
                      );
                    })}
                    <Text
                      style={[
                        pageStyle.td2,
                        teamBatterLineUps?.length % 2 === 0 && pageStyle.odd,
                      ]}
                    />
                  </View>
                </ScrollView>
              </View>
              <View style={[pageStyle.pitchingTable]}>
                <View style={[pageStyle.battingInnerThContainer]}>
                  <Text style={[pageStyle.th, pageStyle.firstTd]}>투수</Text>
                  {teamPitcherLineUps?.map((item, index) => {
                    return (
                      <TouchableOpacity
                        onPress={() =>
                          navigation.navigate('MemberPage', {
                            item: item.member.user,
                            itemId: item.member.user?.uid,
                          })
                        }>
                        <Text
                          style={[
                            pageStyle.th,
                            pageStyle.firstTd,
                            pageStyle.td2,
                            index % 2 === 0 && pageStyle.odd,
                          ]}>
                          {item.member.user?.name}
                          <Text
                            style={[
                              pageStyle.position,
                              {
                                fontWeight: 'normal',
                                fontSize: 11,
                                fontFamily: 'Pretendard',
                              },
                            ]}>
                            ({item.member.number}){' '}
                          </Text>
                          <Text style={[pageStyle.position]}>
                            {currentTeamRecords &&
                              currentTeamRecords.pitchingRecord &&
                              currentTeamRecords.pitchingRecord[
                                item.pitchingOrder
                              ] &&
                              currentTeamRecords.pitchingRecord[
                                item.pitchingOrder
                              ].result}
                          </Text>
                        </Text>
                      </TouchableOpacity>
                    );
                  })}
                  <Text
                    style={[
                      pageStyle.th,
                      pageStyle.firstTd,
                      pageStyle.td2,
                      pageStyle.odd,
                    ]}>
                    합계
                  </Text>
                </View>
                <ScrollView
                  showsHorizontalScrollIndicator={Platform.OS === 'web'}
                  horizontal={true}
                  style={{
                    width:
                      6 *
                      ((Platform.OS === 'web'
                        ? 550
                        : Dimensions.get('window').width - 30) /
                        8),
                  }}>
                  <View style={[pageStyle.pitchingInnerTdContainer]}>
                    <Text style={[pageStyle.th]}>이닝</Text>
                    {teamPitcherLineUps?.map((item, index) => {
                      return (
                        <Text
                          style={[
                            pageStyle.td2,
                            index % 2 === 0 && pageStyle.odd,
                          ]}>
                          {currentTeamRecords &&
                            currentTeamRecords.pitchingRecord &&
                            currentTeamRecords.pitchingRecord[
                              item.pitchingOrder
                            ] &&
                            currentTeamRecords.pitchingRecord[
                              item.pitchingOrder
                            ]?.inning}
                        </Text>
                      );
                    })}
                    <Text
                      style={[
                        pageStyle.td2,
                        teamPitcherLineUps?.length % 2 === 0 && pageStyle.odd,
                      ]}>
                      {totalInnings}
                    </Text>
                  </View>
                  <View style={[pageStyle.pitchingInnerTdContainer]}>
                    <Text style={[pageStyle.th]}>피안타</Text>
                    {teamPitcherLineUps?.map((item, index) => {
                      return (
                        <Text
                          style={[
                            pageStyle.td2,
                            index % 2 === 0 && pageStyle.odd,
                          ]}>
                          {currentTeamRecords &&
                            currentTeamRecords.pitchingRecord &&
                            currentTeamRecords.pitchingRecord[
                              item.pitchingOrder
                            ] &&
                            currentTeamRecords.pitchingRecord[
                              item.pitchingOrder
                            ]?.hits | 0}
                        </Text>
                      );
                    })}
                    <Text
                      style={[
                        pageStyle.td2,
                        teamPitcherLineUps?.length % 2 === 0 && pageStyle.odd,
                      ]}>
                      {totalHits}
                    </Text>
                  </View>
                  <View style={[pageStyle.pitchingInnerTdContainer]}>
                    <Text style={[pageStyle.th]}>실점</Text>
                    {teamPitcherLineUps?.map((item, index) => {
                      return (
                        <Text
                          style={[
                            pageStyle.td2,
                            index % 2 === 0 && pageStyle.odd,
                          ]}>
                          {currentTeamRecords &&
                            currentTeamRecords.pitchingRecord &&
                            currentTeamRecords.pitchingRecord[
                              item.pitchingOrder
                            ] &&
                            currentTeamRecords.pitchingRecord[
                              item.pitchingOrder
                            ]?.r | 0}
                        </Text>
                      );
                    })}
                    <Text
                      style={[
                        pageStyle.td2,
                        teamPitcherLineUps?.length % 2 === 0 && pageStyle.odd,
                      ]}>
                      {totalRs}
                    </Text>
                  </View>
                  <View style={[pageStyle.pitchingInnerTdContainer]}>
                    <Text style={[pageStyle.th]}>자책</Text>
                    {teamPitcherLineUps?.map((item, index) => {
                      return (
                        <Text
                          style={[
                            pageStyle.td2,
                            index % 2 === 0 && pageStyle.odd,
                          ]}>
                          {currentTeamRecords &&
                            currentTeamRecords.pitchingRecord &&
                            currentTeamRecords.pitchingRecord[
                              item.pitchingOrder
                            ] &&
                            currentTeamRecords.pitchingRecord[
                              item.pitchingOrder
                            ].er | 0}
                        </Text>
                      );
                    })}
                    <Text
                      style={[
                        pageStyle.td2,
                        teamPitcherLineUps?.length % 2 === 0 && pageStyle.odd,
                      ]}>
                      {totalErs}
                    </Text>
                  </View>
                  <View style={[pageStyle.pitchingInnerTdContainer]}>
                    <Text style={[pageStyle.th]}>4사구</Text>
                    {teamPitcherLineUps?.map((item, index) => {
                      return (
                        <Text
                          style={[
                            pageStyle.td2,
                            index % 2 === 0 && pageStyle.odd,
                          ]}>
                          {currentTeamRecords &&
                            currentTeamRecords.pitchingRecord &&
                            (currentTeamRecords.pitchingRecord[
                              item.pitchingOrder
                            ]?.hbp +
                              currentTeamRecords.pitchingRecord[
                                item.pitchingOrder
                              ]?.bb) |
                              0}
                        </Text>
                      );
                    })}
                    <Text
                      style={[
                        pageStyle.td2,
                        teamPitcherLineUps?.length % 2 === 0 && pageStyle.odd,
                      ]}>
                      {totalHbps + totalBbs}
                    </Text>
                  </View>
                  <View style={[pageStyle.pitchingInnerTdContainer]}>
                    <Text style={[pageStyle.th]}>삼진</Text>
                    {teamPitcherLineUps?.map((item, index) => {
                      return (
                        <Text
                          style={[
                            pageStyle.td2,
                            index % 2 === 0 && pageStyle.odd,
                          ]}>
                          {currentTeamRecords &&
                            currentTeamRecords.pitchingRecord &&
                            currentTeamRecords.pitchingRecord[
                              item.pitchingOrder
                            ]?.so | 0}
                        </Text>
                      );
                    })}
                    <Text
                      style={[
                        pageStyle.td2,
                        teamPitcherLineUps?.length % 2 === 0 && pageStyle.odd,
                      ]}>
                      {totalSos}
                    </Text>
                  </View>
                  <View style={[pageStyle.pitchingInnerTdContainer]}>
                    <Text style={[pageStyle.th]}>방어율</Text>
                    {teamPitcherLineUps?.map((item, index) => {
                      return (
                        <Text
                          style={[
                            pageStyle.td2,
                            index % 2 === 0 && pageStyle.odd,
                          ]}>
                          {currentTeamRecords &&
                            currentTeamRecords.pitchingRecord &&
                            filterNaN(
                              currentTeamRecords.pitchingRecord[
                                item.pitchingOrder
                              ]?.era,
                              '0.00',
                            )}
                        </Text>
                      );
                    })}
                    <Text
                      style={[
                        pageStyle.td2,
                        teamPitcherLineUps?.length % 2 === 0 && pageStyle.odd,
                      ]}>
                      {totalEra}
                    </Text>
                  </View>
                </ScrollView>
              </View>
              {commentPage?.items?.map(item => (
                <>
                  <View style={[pageStyle.commentItemContainer]}>
                    <Image
                      style={[
                        {
                          width: 40,
                          height: 40,
                          resizeMode: 'cover',
                          borderRadius: 20,
                        },
                      ]}
                      source={getProfileImageUrl(item)}
                    />

                    <View style={[pageStyle.rightContainer]}>
                      <TouchableOpacity
                        onPress={() => {
                          if (!accessToken.current) {
                            showNeedLoginAlert();
                            return;
                          }
                          navigation.navigate('MemberPage', {
                            item: {uid: item.user.uid},
                            itemId: item.user.uid,
                          });
                        }}
                        style={[styles.row, {alignItems: 'center'}]}>
                        <Text style={[pageStyle.userName]}>
                          {item?.user?.name}
                        </Text>
                      </TouchableOpacity>
                      <Text
                        style={[
                          pageStyle.commentContent,
                          item?.deleted && {color: '#888'},
                        ]}>
                        {item.deleted ? '삭제된 댓글입니다' : item.contents}
                      </Text>
                      {item.imageUrl && (
                        <Image
                          style={[pageStyle.commentImage]}
                          source={{uri: item.imageUrl}}
                        />
                      )}
                      <View style={[styles.row, {alignItems: 'center'}]}>
                        <Text style={[pageStyle.date]}>
                          {parseDate(item.createdAt)}
                        </Text>
                        <TouchableOpacity
                          onPress={() => {
                            if (!accessToken.current) {
                              showNeedLoginAlert();
                              return;
                            }
                            navigation.navigate('GameCommentChild', {
                              parentId: item.id,
                              targetCommentId: item.id,
                              gameId: itemId,
                              comment: item,
                              userData: userData,
                              gameInfo: `${data?.batFirstTeamName} vs ${
                                data?.batLastTeamName
                              }, ${parseDate(data?.date)} / ${parseTime(
                                data?.time,
                              )}`,
                            });
                          }}
                          style={[{marginStart: 12}]}>
                          <Text style={[pageStyle.date]}>답글쓰기</Text>
                        </TouchableOpacity>
                      </View>
                    </View>
                  </View>
                  <View>
                    {item.childs &&
                      item.childs.map((child, index) => {
                        return (
                          <View style={[pageStyle.commentItemContainer]}>
                            <View style={[pageStyle.childLeft]} />
                            <Image
                              style={[
                                {
                                  width: 40,
                                  height: 40,
                                  resizeMode: 'cover',
                                  borderRadius: 20,
                                },
                              ]}
                              source={getProfileImageUrl(child)}
                            />

                            <View style={[pageStyle.rightContainer]}>
                              <TouchableOpacity
                                onPress={() => {
                                  if (!accessToken.current) {
                                    showNeedLoginAlert();
                                    return;
                                  }
                                  navigation.navigate('MemberPage', {
                                    item: {uid: child.user.uid},
                                    itemId: child.user.uid,
                                  });
                                }}
                                style={[styles.row, {alignItems: 'center'}]}>
                                <Text style={[pageStyle.userName]}>
                                  {child.user.name}
                                </Text>
                              </TouchableOpacity>
                              <Text
                                style={[
                                  pageStyle.commentContent,
                                  child.deleted && {color: '#888'},
                                ]}>
                                <Text
                                  style={[
                                    {
                                      color: Colors.main,
                                      fontSize: 15,
                                      fontWeight: 'bold',
                                      fontFamily: 'Pretendard-Bold',
                                    },
                                  ]}>
                                  {child.targetUser
                                    ? child.targetUser.name
                                    : ''}{' '}
                                </Text>
                                {child.deleted
                                  ? '삭제된 댓글입니다.'
                                  : child.contents}
                              </Text>
                              {child.imageUrl && (
                                <Image
                                  style={[pageStyle.commentImage]}
                                  source={{uri: child.imageUrl}}
                                />
                              )}
                              <View
                                style={[styles.row, {alignItems: 'center'}]}>
                                <Text style={[pageStyle.date]}>
                                  {parseDate(child.createdAt)}
                                </Text>
                                <TouchableOpacity
                                  onPress={() => {
                                    if (!accessToken.current) {
                                      showNeedLoginAlert();
                                      return;
                                    }
                                    navigation.navigate('GameCommentChild', {
                                      parentId: item.id,
                                      targetCommentId: item.id,
                                      gameId: itemId,
                                      comment: item,
                                      userData: userData,
                                      targetUserId: child.user.id,
                                      targetCommentId: child.id,
                                      gameInfo: `${data?.batFirstTeamName} vs ${
                                        data?.batLastTeamName
                                      }, ${parseDate(data?.date)} / ${parseTime(
                                        data?.time,
                                      )}`,
                                    });
                                  }}
                                  style={[{marginStart: 12}]}>
                                  <Text style={[pageStyle.date]}>답글쓰기</Text>
                                </TouchableOpacity>
                              </View>
                            </View>
                          </View>
                        );
                      })}
                  </View>
                </>
              ))}
            </View>
          }
        />
        <Shadow
          style={[
            pageStyle.bottomView,
            styles.row,
            {
              height: 45 + (Platform.OS === 'ios' ? insets.bottom : 0),
              marginBottom: Platform.OS === 'ios' ? -insets.bottom : 0,
            },
            Platform.OS === 'ios' && {
              paddingTop: 12,
              alignContent: 'flex-start',
              alignItems: 'flex-start',
            },
          ]}>
          <View />
          <View style={[styles.row]}>
            <View />
            <TouchableOpacity
              onPress={() => {
                if (!accessToken.current) {
                  showNeedLoginAlert();
                  return;
                }
                navigation.navigate('GameComment', {
                  gameId: itemId,
                  userData: userData,
                  commentCount: commentPage?.totalCountIncludeChild,
                  gameInfo: `${data?.batFirstTeamName} vs ${
                    data?.batLastTeamName
                  }, ${parseDate(data?.date)} / ${parseTime(data?.time)}`,
                });
              }}
              style={[styles.row]}>
              <Image
                style={[pageStyle.icnComment]}
                resizeMode="cover"
                source={require('../assets/images/icn_comment.png')}
              />
              <Text style={[pageStyle.commentCount]}>
                {commentPage && commentPage?.totalCountIncludeChild
                  ? commentPage?.totalCountIncludeChild?.toLocaleString()
                  : '0'}
              </Text>
            </TouchableOpacity>
          </View>
        </Shadow>
        {progressShown && (
          <View style={[styles.progressContainer]}>
            <Progress.Circle
              fill="transparent"
              size={70}
              borderWidth={3}
              indeterminate={true}
            />
          </View>
        )}
      </SafeAreaView>
    </>
  );
};

const pageStyle = StyleSheet.create({
  tdInnerContainer: {
    width:
      (Platform.OS === 'web' ? 550 : Dimensions.get('window').width - 30) / 13,
  },
  battingInnerThContainer: {
    width:
      2.5 *
      ((Platform.OS === 'web' ? 550 : Dimensions.get('window').width - 30) / 8),
  },
  battingInnerTdContainer: {
    width:
      (Platform.OS === 'web' ? 550 : Dimensions.get('window').width - 30) / 8,
  },
  pitchingInnerTdContainer: {
    width:
      (Platform.OS === 'web' ? 550 : Dimensions.get('window').width - 30) / 8,
  },
  container: {},
  header: {width: '100%'},
  scrollViewContainer: {},
  list: {
    paddingStart: 26,
    paddingEnd: 26,
    paddingTop: 10,
    paddingBottom: 50,
  },
  bottomContainer: {
    textAlign: 'center',
    marginHorizontal: 10,
  },
  teamContainer: {
    flex: 1,
    paddingHorizontal: 10,
  },
  topContainer: {
    paddingTop: 10,
    paddingBottom: 26,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
  meta: {
    width: 105,
    justifyContent: 'center',
    alignItems: 'center',
  },
  metaTxt: {
    fontSize: 11,
    color: 'black',
    fontWeight: '400',
    fontFamily: 'Pretendard',
    textAlign: 'center',
  },
  scoreContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: 7,
  },
  scoreItemContainer: {
    marginHorizontal: 7,
    fontSize: 28,
    color: 'black',
    fontWeight: '600',
    fontFamily: 'Pretendard-Bold',
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'center',
  },
  batFirstTeamContainer: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  batLastTeamContainer: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  logoContainer: {
    width: 77,
    height: 77,
    borderRadius: 38.5,
    overflow: 'hidden',
    backgroundColor: 'white',
  },
  logoImg: {
    width: 77,
    height: 77,
  },
  central: {
    marginTop: 9,
  },
  teamName: {
    color: 'black',
    fontFamily: 'Pretendard-Bold',
    fontWeight: '600',
    fontSize: 13,
  },
  wl: {
    marginTop: 5,
    fontSize: 12,
    fontFamily: 'Pretendard',
  },
  lost: {
    color: '#c93232',
  },
  win: {
    color: '#3f51b5',
  },
  statusBox: {
    width: 44,
    height: 15,
    fontSize: 10,
    color: 'white',
    fontWeight: '400',
    fontFamily: 'Pretendard',
    borderRadius: 7.5,
    textAlign: 'center',
    lineHeight: 14,
  },
  buttonContainer: {
    marginTop: 8,
  },
  button: {
    width: 95,
    height: 25,
    borderWidth: 1,
    borderColor: '#ddd',
    alignItems: 'center',
    borderRadius: 12.5,
    display: 'flex',
    justifyContent: 'center',
  },
  buttonText: {
    color: 'black',
    fontSize: 13,
    fontFamily: 'Pretendard',
    textAlign: 'center',
  },
  buttonActive: {
    backgroundColor: Colors.main,
  },
  buttonActiveTxt: {
    color: 'white',
  },
  scoreTable: {
    padding: Platform.OS === 'web' ? 0 : 15,
    backgroundColor: 'rgb(230, 234, 241)',
  },
  trRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  td: {
    height: 21,
    lineHeight: 21,
    flex: 1,
    textAlign: 'center',
    color: 'white',
    fontSize: 11,
    backgroundColor: 'rgb(17, 32, 56)',
    alignContent: 'center',
    alignItems: 'center',
    fontWeight: '600',
    fontFamily: 'Pretendard-Bold',
    borderWidth: 0.5,
    borderColor: 'rgba(255, 255, 255, 0.7)',
  },
  tdTeam: {
    flex: 2,
  },
  tdContainerTeam: {
    width:
      2 *
      ((Platform.OS === 'web' ? 550 : Dimensions.get('window').width - 30) /
        13),
  },
  tdContainer: {
    width:
      (Platform.OS === 'web' ? 550 : Dimensions.get('window').width - 30) / 13,
  },
  tdInning: {
    fontFamily: 'Pretendard-Bold',
    fontWeight: '600',
    flex: 1,
    textAlign: 'center',
    color: '#18253b',
    fontSize: 10,
    height: 21,
    lineHeight: 21,
  },
  tdInningFirst: {
    flex: 2,
    color: '#18253b',
    fontFamily: 'Pretendard',
    fontSize: 10,
    height: 21,
    lineHeight: 21,
  },
  tdInningR: {
    color: '#8093e0',
  },
  pitchingSummary: {
    marginTop: 11,
    display: 'flex',
    flexDirection: 'row',
  },
  firstBatPitching: {
    flex: 1,
    paddingStart: 38,
    justifyContent: 'flex-start',
    flexDirection: 'row',
  },
  lastBatPitching: {
    flex: 1,
    paddingEnd: 38,
    justifyContent: 'flex-end',
    flexDirection: 'row',
  },
  alignR: {
    justifyContent: 'flex-end',
  },
  pLabel: {
    color: 'black',
    fontFamily: 'Pretendard',
    fontSize: 12,
    lineHeight: 14,
    marginEnd: 3,
  },
  pValue: {
    lineHeight: 14,
    color: '#c93232',
    fontFamily: 'Pretendard-Medium',
    fontSize: 12,
    fontWeight: '500',
    marginEnd: 6,
  },
  starterImg: {
    marginHorizontal: 9,
    width: 41,
    height: 41,
    resizeMode: 'cover',
    borderRadius: 20.5,
  },
  barGroupContainer: {marginTop: 13},
  barContainer: {flexDirection: 'row', paddingHorizontal: 33, marginTop: 12},
  graphTextContainer: {
    flexDirection: 'row',
    width: 75,
    textAlign: 'center',
    justifyContent: 'center',
  },
  graphLabelFirstBat: {
    position: 'absolute',
    left: 6,
    width: 18,
    textAlign: 'center',
  },
  graphLabelLastBat: {
    position: 'absolute',
    right: 6,
    width: 18,
    textAlign: 'center',
  },
  graphContainer: {
    flex: 1,
    backgroundColor: 'rgb(237, 237, 237)',
    height: 6,
    borderRadius: 3,
    alignSelf: 'center',
  },
  firstBatGraph: {
    width: '100%',
    height: 6,
    marginEnd: 4,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  lastBatGraph: {
    width: '100%',
    height: 6,
    marginStart: 4,
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  firstBatGraphActive: {
    borderRadius: 3,
    height: 6,
    backgroundColor: '#3f51b5',
  },
  lastBatGraphActive: {
    borderRadius: 3,
    height: 6,
    backgroundColor: '#c93232',
  },
  graphLabel: {
    fontFamily: 'Pretendard',
    fontSize: 11,
    lineHeight: 12,
    color: 'black',
    marginHorizontal: 9,
  },
  graphValue: {
    fontFamily: 'Pretendard',
    fontSize: 11,
    lineHeight: 12,
    color: 'black',
  },
  border: {
    width: '100%',
    height: 1,
  },
  battingGroupContainer: {
    marginTop: 24,
    marginHorizontal: 31,
    borderTopWidth: 1,
    borderTopColor: '#ccc',
    borderBottomWidth: 1,
    borderBottomColor: '#ccc',
    paddingStart: 12,
    paddingBottom: 12,
  },
  battingItemRow: {marginTop: 8},
  battingLabel: {
    width: 40,
    color: 'black',
    fontSize: 11,
    lineHeight: 12,
    fontWeight: '600',
    fontFamily: 'Pretendard-Bold',
  },
  battingValue: {
    flex: 1,
    marginStart: 17,
    color: 'black',
    fontFamily: 'Pretendard',
    fontSize: 11,
    lineHeight: 12,
  },
  teamToggleContainer: {
    marginTop: 31,
    marginHorizontal: 31,
    flexDirection: 'row',
  },
  teamToggle: {
    flex: 1,
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    height: 29,
    borderWidth: 1,
    borderColor: '#aaa',
  },
  teamToggleLeftContainer: {
    borderTopStartRadius: 14.5,
    borderBottomStartRadius: 14.5,
  },
  teamToggleRightContainer: {
    borderTopEndRadius: 14.5,
    borderBottomEndRadius: 14.5,
  },
  teamToggleTxt: {
    color: 'rgba(159, 160, 160, 0.8)',
  },
  teamToggleSelected: {
    backgroundColor: Colors.main,
    borderWidth: 0,
  },
  teamToggleTextSelected: {
    color: 'white',
    fontWeight: '600',
    fontFamily: 'Pretendard-Bold',
  },
  battingTable: {
    margin: 13,
    flexDirection: 'row',
  },
  tr: {
    flexDirection: 'row',
  },
  firstTd: {
    minWidth: 100,
  },
  th: {
    color: 'black',
    fontFamily: 'Pretendard-Bold',
    fontWeight: '600',
    flex: 1,
    textAlign: 'center',
    height: Platform.OS === 'web' ? 35 : 30,
    lineHeight: Platform.OS === 'web' ? 35 : 30,
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    borderWidth: 0.5,
    borderColor: 'rgba(0, 0, 0, 0.15)',
    borderTopColor: 'rgba(0, 0, 0, 0.15)',
    borderTopWidth: 0.5,
    fontSize: 11.5,
    maxHeight: Platform.OS === 'web' ? 35 : 50,
  },
  td2: {
    display: 'flex',
    textAlign: 'center',
    height: 35,
    lineHeight: 35,
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    borderWidth: 0.5,
    borderColor: 'rgba(0, 0, 0, 0.15)',
    color: 'black',
    fontFamily: 'Pretendard',
    fontSize: 12.5,
    maxHeight: Platform.OS === 'web' ? 35 : 50,
  },
  td2Container: {
    height: 35,
    borderWidth: 0.25,
    borderColor: 'rgba(0, 0, 0, 0.15)',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
  },
  td2Value: {
    textAlign: 'center',
    color: 'black',
    fontFamily: 'Pretendard',
    fontSize: 12.5,
  },
  position: {
    fontFamily: 'Pretendard',
    fontSize: 9,
  },
  odd: {
    backgroundColor: '#eee',
  },
  firstTr: {
    borderTopColor: 'black',
    borderTopWidth: 1,
  },
  pitchingTable: {
    margin: 13,
    flexDirection: 'row',
  },
  commentContainer: {marginVertical: 12, paddingHorizontal: 8},
  commentSubtitle: {
    fontSize: 14,
    color: 'black',
    fontWeight: '600',
    fontFamily: 'Pretendard-SemiBold',
  },
  commentInputContainer: {flexDirection: 'row', marginTop: 8},
  commentInput: {
    flex: 1,
    height: 45,
    padding: 12,
    borderWidth: 1,
    borderColor: '#ddd',
  },
  commentBtn: {
    height: 45,
    marginStart: 8,
    lineHeight: 45,
    borderWidth: 1,
    borderColor: '#ddd',
  },
  commentBtnTxt: {
    height: 45,
    lineHeight: 45,
    fontFamily: 'Pretendard',
    fontSize: 12,
    textAlign: 'center',
    width: 55,
    color: 'black',
  },
  commentItemContainer: {
    borderBottomWidth: 1,
    borderBottomColor: '#ddd',
    paddingVertical: 12,
    paddingHorizontal: 8,
    flexDirection: 'row',
  },
  commentContent: {
    fontFamily: 'Pretendard',
    fontSize: 15,
    color: 'black',
    marginTop: 7,
  },
  rightArr: {
    width: 20,
    height: 20,
    marginStart: 2,
  },
  myComment: {
    width: 44,
    height: 20,
    textAlign: 'center',
    color: Colors.main,
    fontFamily: 'Pretendard',
    fontSize: 11,
    borderWidth: 1,
    borderColor: Colors.main,
    paddingHorizontal: 4,
    borderRadius: 10,
    marginStart: 8,
    justifyContent: 'center',
    alignItems: 'center',
    lineHeight: 18,
  },
  userName: {
    fontSize: 13,
    color: '#111',
    fontWeight: 'bold',
    fontFamily: 'Pretendard-Bold',
  },
  rightContainer: {
    marginStart: 8,
    flex: 1,
  },
  date: {
    fontFamily: 'Pretendard',
    fontSize: 11,
    color: '#666',
    marginTop: 5,
  },
  replyBtn: {
    opacity: 0.4,
    width: 20,
    justifyContent: 'flex-end',
  },
  deleteBtn: {
    opacity: 0.4,
    width: 20,
    justifyContent: 'flex-end',
  },
  replyImg: {
    width: 20,
    height: 20,
    resizeMode: 'cover',
  },
  childLeft: {width: 40},
  list: {
    height:
      Dimensions.get('window').height - (Platform.OS === 'ios' ? 200 : 100),
  },
  bottomView: {
    width: '100%',
    justifyContent: 'space-between',
    height: 40,
    alignContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 12,
    backgroundColor: 'white',
  },
  commentCount: {
    fontSize: 14,
    color: 'black',
    fontFamily: 'Pretendard-SemiBold',
    fontWeight: '600',
    alignSelf: 'center',
    marginEnd: 12,
  },
  icnComment: {
    width: 23,
    height: 23,
    marginEnd: 12,
  },
});

export default BoxScoreView;

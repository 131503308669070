import React, {useEffect, useState, useRef, useCallback} from 'react';
import {
  Image,
  SafeAreaView,
  DeviceEventEmitter,
  Text,
  TouchableOpacity,
  View,
  StyleSheet,
  FlatList,
  TextInput,
  Modal,
  Platform,
  Dimensions,
  Alert,
} from 'react-native';
import styles from '../common/styles';
import Colors from '../common/colors';
import {Shadow} from 'react-native-shadow-2';
import LeagueMetaItem from '../component/LeagueMetaItem';
import SelectDropdown from 'react-native-select-dropdown';
import AsyncStorage from '@react-native-async-storage/async-storage';
import api from '../common/api';
import * as Progress from 'react-native-progress';
import {debounce} from 'lodash';
import alert from '../component/alert';

const LeagueView = ({navigation, route}) => {
  const [tabIndex, setTabIndex] = useState(0);
  const [myLeaguePage, setMyLeaguePage] = useState([]);
  const [totalLeaguePage, setTotalLeaguePage] = useState([]);
  const [totalCompetitionPage, setTotalCompetitionPage] = useState([]);
  const [keyword, setKeyword] = useState([]);
  const [topRegions, setTopRegions] = useState([]);
  const [secondRegions, setSecondRegions] = useState([]);
  const [progressShown, setProgressShown] = useState(false);

  const [alarmUnread, setAlarmUnread] = useState(false);
  const [parentRegionId, setParentRegionId] = useState(null);
  const [regionId, setRegionId] = useState(null);
  const [tourMode, setTourMode] = useState();

  let totalLeaguePageLoading = useRef();
  let totalCompetitionPageLoading = useRef();

  const tabList = tourMode
    ? [
        {index: 1, text: '전체 리그'},
        {index: 2, text: '전체 대회'},
      ]
    : [
        {index: 0, text: '가입리그 · 대회'},
        {index: 1, text: '전체 리그'},
        {index: 2, text: '전체 대회'},
      ];

  let accessToken = useRef();
  let subscription = useRef();

  const checkAlarmUnRead = () => {
    if (!accessToken.current) {
      return;
    }
    api
      .get('/users/exists-unread-alarm', {
        headers: {
          Authorization: `Bearer ${accessToken.current}`,
        },
      })
      .then(function (response) {
        setAlarmUnread(response.data);
      })
      .catch(function (error) {
        console.error(error.response);
      });
  };

  useEffect(() => {
    AsyncStorage.getItem('@accessToken', (err, result) => {
      if (err) {
        console.error(err);
        return;
      }
      accessToken.current = result;
      setTourMode(!result);
      if (!result) {
        setTabIndex(1);
      }
      getData(result);
      checkAlarmUnRead();
    });
  }, []);

  useEffect(() => {
    const unsubscribe = DeviceEventEmitter.addListener(
      'leagueTabPress',
      function (data) {
        getData(accessToken.current);
        checkAlarmUnRead();
      },
    );
    return () => {
      unsubscribe.remove();
    };
  }, []);

  useEffect(() => {
    getRegionData(parentRegionId);
    handleFilterChange();
  }, [parentRegionId]);
  useEffect(() => {
    handleFilterChange();
  }, [regionId]);

  const debounceSearch = useCallback(
    debounce(recentKeyword => {
      handleFilterChange(recentKeyword);
    }, 250),
    [],
  );

  useEffect(() => {
    debounceSearch(keyword);
  }, [keyword]);

  const getData = token => {
    setProgressShown(true);
    if (token) {
      api
        .get('/app-league/my-leagues', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(function (response) {
          console.log('my-leageus', response.data);
          setMyLeaguePage(response.data);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
    api
      .get(
        `/app-league/filter?regionId=${
          regionId && regionId ? regionId : ''
        }&competition=false&parentRegionId=${
          parentRegionId && parentRegionId ? parentRegionId : ''
        }&keyword=${keyword}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then(function (response) {
        setTotalLeaguePage(response.data);
      })
      .catch(function (error) {
        console.log(error);
      })
      .finally(() => {
        setProgressShown(false);
      });
    api
      .get(
        `/app-league/filter?regionId=${
          regionId && regionId ? regionId : ''
        }&competition=true&parentRegionId=${
          parentRegionId && parentRegionId ? parentRegionId : ''
        }&keyword=${keyword}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then(function (response) {
        setTotalCompetitionPage(response.data);
      })
      .catch(function (error) {
        console.log(error);
      })
      .finally(() => {
        setProgressShown(false);
      });
    api
      .get('/regions', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(function (response) {
        setTopRegions([{id: null, sido: '전체지역'}, ...response.data]);
        setSecondRegions([{id: null, sigungu: '전체지역'}]);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getRegionData = parentId => {
    api
      .get(`/regions?parentId=${parentId}`, {
        headers: {
          Authorization: `Bearer ${accessToken.current}`,
        },
      })
      .then(function (response) {
        setSecondRegions([{id: null, sigungu: '전체지역'}, ...response.data]);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleFilterChange = recentKeyword => {
    setProgressShown(true);
    totalLeaguePageLoading.current = true;
    api
      .get(
        `/app-league/filter?regionId=${
          regionId && regionId ? regionId : ''
        }&competition=false&parentRegionId=${
          parentRegionId && parentRegionId ? parentRegionId : ''
        }&keyword=${recentKeyword ? recentKeyword : keyword}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken.current}`,
          },
        },
      )
      .then(function (response) {
        setTotalLeaguePage(response.data);
      })
      .catch(function (error) {
        console.log(error);
      })
      .finally(() => {
        setProgressShown(false);
        totalLeaguePageLoading.current = false;
      });
  };

  const handleTotalLeaguePageLoadMore = () => {
    if (totalLeaguePageLoading.current) {
      return;
    }
    if (
      totalLeaguePage.currentPage &&
      totalLeaguePage.totalPage <= totalLeaguePage.currentPage
    ) {
      return;
    }
    totalLeaguePageLoading.current = true;
    api
      .get(
        `/app-league/filter?regionId=${
          regionId && regionId ? regionId : ''
        }&competition=false&keyword=${keyword}&pageNo=${
          totalLeaguePage.currentPage
            ? parseInt(totalLeaguePage.currentPage, 10) + 1
            : 1
        }&pageSize=20`,
        {
          headers: {
            Authorization: `Bearer ${accessToken.current}`,
          },
        },
      )
      .then(function (response) {
        response.data.items = [
          ...(totalLeaguePage.items ? totalLeaguePage.items : []),
          ...response.data.items,
        ];
        setTotalLeaguePage(response.data);
        totalLeaguePageLoading.current = false;
      })
      .catch(function (error) {
        console.log(error);
        totalLeaguePageLoading.current = false;
      });
  };

  const handleTotalCompetitionPageLoadMore = () => {
    if (totalCompetitionPageLoading.current) {
      return;
    }
    if (
      totalCompetitionPage.currentPage &&
      totalCompetitionPage.totalPage <= totalCompetitionPage.currentPage
    ) {
      return;
    }
    totalCompetitionPageLoading.current = true;
    api
      .get(
        `/app-league/filter?regionId=${
          regionId && regionId ? regionId : ''
        }&competition=true&keyword=${keyword}&pageNo=${
          totalCompetitionPage.currentPage
            ? parseInt(totalCompetitionPage.currentPage, 10) + 1
            : 1
        }&pageSize=20`,
        {
          headers: {
            Authorization: `Bearer ${accessToken.current}`,
          },
        },
      )
      .then(function (response) {
        response.data.items = [
          ...(totalCompetitionPage.items ? totalCompetitionPage.items : []),
          ...response.data.items,
        ];
        setTotalCompetitionPage(response.data);
        totalCompetitionPageLoading.current = false;
      })
      .catch(function (error) {
        console.log(error);
        totalCompetitionPageLoading.current = false;
      });
  };

  return (
    <>
      <SafeAreaView style={[styles.container]}>
        <Shadow
          offset={[0, 2]}
          style={[{width: '100%'}]}
          containerStyle={[{marginTop: 0}]}
          distance={7}
          sides={{bottom: true, top: false, start: false, end: false}}>
          <View style={[styles.headerCommon]}>
            <View style={[styles.row, styles.verticalAlign]}>
              <TouchableOpacity
                onPress={() => {
                  if (!accessToken.current) {
                    return;
                  }
                  Platform.OS === 'web' ? history.back() : navigation.goBack();
                }}>
                <Image
                  source={require('../assets/images/icon_back.png')}
                  style={[styles.headerBackButton]}
                />
              </TouchableOpacity>
              <Text style={[styles.headerText]}>리그페이지</Text>
            </View>
            <View style={[styles.row, styles.verticalAlign]}>
              <TouchableOpacity
                style={[styles.alarmContainer]}
                onPress={() => {
                  if (!accessToken.current) {
                    alert(
                      '',
                      '해당 페이지는 로그인 후 이용해주세요',
                      [
                        {
                          text: '계속 둘러보기',
                          onPress: () => {},
                          style: 'cancel',
                        },
                        {
                          text: '확인',
                          onPress: () => {
                            props?.navigation?.reset({
                              index: 0,
                              routes: [{name: 'Login'}],
                            });
                          },
                        },
                      ],
                      {
                        cancelable: true,
                        onDismiss: () => {},
                      },
                    );
                    return;
                  }
                  navigation.navigate('MyAlarmList');
                }}>
                <Image
                  resizeMode="contain"
                  style={[styles.imgAlarm]}
                  source={require('../assets/images/icn_alarm.png')}
                />
                {alarmUnread && <View style={[styles.alarmDot]} />}
              </TouchableOpacity>
            </View>
          </View>
        </Shadow>
        <View style={[{marginTop: 10}]}>
          <View
            style={[styles.scrollViewContainer, pageStyle.scrollViewContainer]}>
            <View style={[styles.tabContainer]}>
              {tabList.map(tab => (
                <TouchableOpacity
                  key={`tab_${tab.index}`}
                  onPress={() => setTabIndex(tab.index)}
                  style={[
                    tabIndex == tab.index && styles.tabItemSelected,
                    tabIndex != tab.index && styles.tabItem,
                  ]}>
                  <Text
                    style={[
                      tabIndex == tab.index && styles.tabItemTextSelected,
                      tabIndex != tab.index && styles.tabItemText,
                    ]}>
                    {tab.text}
                  </Text>
                </TouchableOpacity>
              ))}
            </View>
            {tabIndex === 0 && (
              <FlatList
                nestedScrollEnabled={true}
                style={[pageStyle.list]}
                keyExtractor={item => `my_league_${item.id.toString()}`}
                data={myLeaguePage.items}
                renderItem={({item}) => (
                  <TouchableOpacity
                    key={`my_league_${item.id}`}
                    onPress={() => {
                      navigation.navigate('LeagueDetail', {
                        item: item,
                        itemId: item?.id,
                      });
                    }}>
                    <LeagueMetaItem data={item} />
                  </TouchableOpacity>
                )}
                ListEmptyComponent={() => (
                  <View style={[styles.emptyContainer]}>
                    <Image source={require('../assets/images/logo2.png')} />
                  </View>
                )}
              />
            )}
            {tabIndex === 1 && (
              <View>
                <View style={[pageStyle.filterContainer]}>
                  <View style={[pageStyle.dropDownContainer]}>
                    <View style={[pageStyle.dropDownItemContainer]}>
                      <SelectDropdown
                        defaultButtonText="광역시/도"
                        buttonStyle={pageStyle.dropDown}
                        buttonTextStyle={pageStyle.downDownText}
                        rowTextStyle={styles.dropDownRowText}
                        renderDropdownIcon={() => (
                          <Image
                            source={require('../assets/images/select_arr.png')}
                          />
                        )}
                        data={topRegions}
                        onSelect={(selectedItem, index) => {
                          setParentRegionId(selectedItem.id);
                        }}
                        buttonTextAfterSelection={(selectedItem, index) => {
                          return selectedItem.sido;
                        }}
                        rowTextForSelection={(item, index) => {
                          return item.sido;
                        }}
                      />
                    </View>
                    <View style={[pageStyle.dropDownItemContainer]}>
                      <SelectDropdown
                        defaultButtonText="시/군/구"
                        buttonStyle={pageStyle.dropDown}
                        buttonTextStyle={pageStyle.downDownText}
                        rowTextStyle={styles.dropDownRowText}
                        renderDropdownIcon={() => (
                          <Image
                            source={require('../assets/images/select_arr.png')}
                          />
                        )}
                        data={secondRegions}
                        onSelect={(selectedItem, index) => {
                          setRegionId(selectedItem.id);
                        }}
                        buttonTextAfterSelection={(selectedItem, index) => {
                          return selectedItem.sigungu;
                        }}
                        rowTextForSelection={(item, index) => {
                          return item.sigungu;
                        }}
                      />
                    </View>
                  </View>
                  <View style={[pageStyle.searchInputContainer]}>
                    <TextInput
                      value={keyword}
                      style={[pageStyle.searchInput]}
                      placeholder={'검색'}
                      placeholderTextColor={'#aaa'}
                      onChangeText={value => {
                        setKeyword(value);
                      }}
                    />
                    <Image
                      style={[pageStyle.searchIcon]}
                      source={require('../assets/images/icn_search.png')}
                    />
                  </View>
                </View>
                <FlatList
                  nestedScrollEnabled={true}
                  style={[
                    pageStyle.list,
                    Platform.OS === 'web' && {
                      height: Dimensions.get('window').height - 10,
                    },
                  ]}
                  keyExtractor={item => `total_league_${item.id.toString()}`}
                  data={totalLeaguePage.items}
                  onEndReachedThreshold={1}
                  onEndReached={handleTotalLeaguePageLoadMore}
                  renderItem={({item}) => (
                    <TouchableOpacity
                      key={`total_league_${item.id}`}
                      onPress={() => {
                        navigation.navigate('LeagueDetail', {
                          item: item,
                          itemId: item?.id,
                        });
                      }}>
                      <LeagueMetaItem data={item} />
                    </TouchableOpacity>
                  )}
                  ListFooterComponent={() => <View style={[{height: 200}]} />}
                  ListEmptyComponent={() => (
                    <View style={[styles.emptyContainer]}>
                      <Image source={require('../assets/images/logo2.png')} />
                    </View>
                  )}
                />
              </View>
            )}
            {tabIndex === 2 && (
              <FlatList
                nestedScrollEnabled={true}
                style={[
                  pageStyle.list,
                  Platform.OS === 'web' && {
                    height: Dimensions.get('window').height - 10,
                  },
                ]}
                keyExtractor={item => `total_competition_${item.id.toString()}`}
                data={totalCompetitionPage.items}
                onEndReachedThreshold={1}
                onEndReached={handleTotalCompetitionPageLoadMore}
                renderItem={({item}) => (
                  <TouchableOpacity
                    key={`total_competition_${item.id}`}
                    onPress={() => {
                      navigation.navigate('LeagueDetail', {
                        item: item,
                        itemId: item?.id,
                      });
                    }}>
                    <LeagueMetaItem data={item} />
                  </TouchableOpacity>
                )}
                ListFooterComponent={() => <View style={[{height: 200}]} />}
                ListEmptyComponent={() => (
                  <View style={[styles.emptyContainer]}>
                    <Image source={require('../assets/images/logo2.png')} />
                  </View>
                )}
              />
            )}
          </View>
          {progressShown && (
            <View style={[styles.progressContainer]}>
              <Progress.Circle
                fill="transparent"
                size={70}
                borderWidth={3}
                indeterminate={true}
              />
            </View>
          )}
        </View>
      </SafeAreaView>
    </>
  );
};

const pageStyle = StyleSheet.create({
  list: {
    paddingStart: 26,
    paddingEnd: 26,
    paddingTop: 10,
    paddingBottom: 80,
    marginBottom: 150,
  },
  filterContainer: {
    flexDirection: 'row',
  },
  dropDownContainer: {
    flexDirection: 'row',
    paddingStart: 22,
    marginEnd: 8,
    marginTop: 10,
    flex: 3,
  },
  dropDownItemContainer: {
    marginHorizontal: 3,
    flex: 1,
  },
  dropDown: {
    width: '100%',
    backgroundColor: 'white',
    borderWidth: 1,
    borderColor: 'rgb(61, 80, 183)',
    height: 30,
  },
  downDownText: {
    color: 'black',
    fontFamily: 'Pretendard',
    fontSize: 11,
  },
  searchInput: {
    marginTop: 9,
    borderWidth: 1,
    borderColor: '#ccc',
    borderRadius: 13,
    height: 28,
    fontFamily: 'Pretendard',
    fontSize: 12,
    padding: 4.5,
    color: 'black',
    textAlign: 'left',
    paddingStart: 30,
  },
  searchInputContainer: {
    flex: 2,
    position: 'relative',
    marginEnd: 27,
  },
  searchIcon: {
    position: 'absolute',
    top: 16,
    left: 8,
    width: 17,
    height: 17,
  },
});

export default LeagueView;

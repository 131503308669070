import React, {useState, useRef, useEffect, useMemo} from 'react';
import {
  StyleSheet,
  View,
  Dimensions,
  Modal,
  Text,
  Image,
  TouchableOpacity,
  TextInput,
  Alert,
  DeviceEventEmitter,
  Platform,
} from 'react-native';
import styles from '../../common/styles';
import {useSafeAreaInsets} from 'react-native-safe-area-context';
import Colors from '../../common/colors';
import SelectDropdown from 'react-native-select-dropdown';
import AsyncStorage from '@react-native-async-storage/async-storage';
import api from '../../common/api';
import moment from 'moment';
import alert from '../alert';

const TeamInfoPopup = props => {
  const insets = useSafeAreaInsets();

  const [myGrade, setMyGrade] = useState();
  const [profileImageUri, setProfileImageUri] = useState('');
  const [profileImageData, setProfileImageData] = useState(
    props.teamData.logoImageUrl,
  );
  const [data, setData] = useState(props.teamData);
  const [favorited, setFavorited] = useState(props.teamData.favorited);

  let accessToken = useRef();
  useEffect(() => {
    AsyncStorage.getItem('@accessToken', (err, result) => {
      accessToken.current = result;
      getTeamData();
    });
  }, []);

  const dateFormatted = useMemo(() => {
    if (!data || !data.foundationDay) {
      return '';
    }
    return moment(data.foundationDay, 'YYYY-MM-DD').format('YYYY. MM. DD');
  }, [data]);

  const getTeamData = () => {
    if (!data) {
      return;
    }
    api
      .get(`/app-team/teams/${data.id}`, {
        headers: {
          Authorization: `Bearer ${accessToken.current}`,
        },
      })
      .then(function (response) {
        setData(response.data);
        setFavorited(response.data.favorited);
      })
      .catch(function (error) {
        console.log(error);
      });
    api
      .get(`/teams/${data.id}/my-grade`, {
        headers: {
          Authorization: `Bearer ${accessToken.current}`,
        },
      })
      .then(function (response) {
        console.log('myGrade', response.data);
        setMyGrade(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const toggleFavorite = () => {
    api
      .post(
        `/app-team/${data.id}/favorites`,
        {},
        {
          headers: {
            Authorization: `Bearer ${accessToken.current}`,
          },
        },
      )
      .then(function (response) {
        setFavorited(!favorited);
        setData(data);

        setTimeout(() => {
          DeviceEventEmitter.emit('@favoriteListRefresh');
        }, 200);
      })
      .catch(function (error) {
        console.log(error.response);
      });
  };

  const requestLeave = () => {
    if (myGrade !== '선수') {
      alert(
        '선수이외의 등급의 회원님은 회원 등급을 "선수" 등급으로 변경해야 탈퇴 가능하며, 팀마스터 등급의 회원님은 팀마스터를 다른 분에게 양도한 후 탈퇴가 가능합니다.',
      );
      return;
    }
    console.log(`/teams/${props.teamData.id}/member`, accessToken.current);
    alert(
      '탈퇴',
      '팀 탈퇴시 해당 팀의 선수명단에서 제외됩니다. 정말 탈퇴하시겠습니까?',
      [
        {
          text: '예',
          onPress: () => {
            api
              .delete(`/teams/${props.teamData.id}/member`, {
                headers: {
                  Authorization: `Bearer ${accessToken.current}`,
                },
              })
              .then(function (response) {
                alert('성공적으로 요청하였습니다.');
                props.closePopup();
              })
              .catch(function (error) {
                console.error(error);
              });
          },
        },
        {
          text: '아니오',
        },
      ],
    );
  };

  return (
    <Modal transparent={true} overFullScreen={true}>
      <View style={[pageStyle.container]}>
        <View style={[pageStyle.innerContainer, {marginTop: insets.top + 67}]}>
          <View style={[styles.headerCommon, pageStyle.header]}>
            <View style={[styles.row, styles.verticalAlign, pageStyle.back]}>
              <TouchableOpacity onPress={() => props.closePopup()}>
                <Image
                  source={require('../../assets/images/icon_back.png')}
                  style={[styles.headerBackButton]}
                />
              </TouchableOpacity>
            </View>
            <Text style={[pageStyle.title]}>팀 정보</Text>
            <TouchableOpacity
              style={[pageStyle.favoriteBtn]}
              onPress={toggleFavorite}>
              <Image
                resizeMode="contain"
                style={[pageStyle.favoriteImage]}
                source={
                  favorited
                    ? require('../../assets/images/icn_favorite_on.png')
                    : require('../../assets/images/icn_favorite_grey.png')
                }
              />
              <Text
                style={[
                  pageStyle.favoriteTxt,
                  favorited && {color: Colors.main},
                ]}>
                관심 팀
              </Text>
            </TouchableOpacity>
          </View>
          <View style={[pageStyle.contentsContainer]}>
            <View style={[pageStyle.contentsInnerContainer]}>
              <View style={[pageStyle.logoContainer]}>
                <Image
                  style={[pageStyle.logoImg]}
                  source={!profileImageData ? {} : {uri: profileImageData}}
                  resizeMode={'cover'}
                />
                <Text style={[pageStyle.teamName]}>{data.name}</Text>
              </View>
              <View
                style={[
                  styles.row,
                  {marginTop: 24},
                  Platform.OS === 'web' && {width: 300},
                ]}>
                <View style={[pageStyle.item]}>
                  <Text style={[pageStyle.subtitle]}>팀 연고</Text>
                  <Text style={[pageStyle.value]}>
                    {data.hometown?.sido} {data.hometown?.name}
                  </Text>
                </View>
                <View style={[pageStyle.item]}>
                  <Text style={[pageStyle.subtitle]}>창단일</Text>
                  <Text style={[pageStyle.value]}>{dateFormatted}</Text>
                </View>
              </View>
              <View
                style={[
                  styles.row,
                  {marginTop: 24},
                  Platform.OS === 'web' && {width: 300},
                ]}>
                <View style={[pageStyle.item]}>
                  <Text style={[pageStyle.subtitle]}>선수인원</Text>
                  <Text style={[pageStyle.value]}>{data.totalMembers}명</Text>
                </View>
                <View style={[pageStyle.item]}>
                  <Text style={[pageStyle.subtitle]}>평균연령</Text>
                  <Text style={[pageStyle.value]}>{data.avgAge}세</Text>
                </View>
              </View>
              <View
                style={[
                  styles.row,
                  {marginTop: 24},
                  Platform.OS === 'web' && {width: 300},
                ]}>
                <View style={[pageStyle.item]}>
                  <Text style={[pageStyle.subtitle]}>팀마스터</Text>
                  <Text style={[pageStyle.value]}>{data.masterUser?.name}</Text>
                </View>
              </View>
              <View style={[styles.row]}>
                <View />
                {!!myGrade && myGrade !== '신청 대기 중' && (
                  <TouchableOpacity
                    onPress={requestLeave}
                    style={[pageStyle.cancelBtn]}>
                    <Text style={[pageStyle.btnText, pageStyle.cancelBtnTxt]}>
                      탈퇴하기
                    </Text>
                  </TouchableOpacity>
                )}
                <View />
              </View>
            </View>
          </View>
        </View>
      </View>
    </Modal>
  );
};

export default TeamInfoPopup;
const pageStyle = StyleSheet.create({
  container: {
    zIndex: 100,
    width: '100%',
    height: Dimensions.get('window').height,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
  },
  innerContainer: {
    width: Platform.OS === 'web' ? 500 : '100%',
    backgroundColor: 'white',
    flex: 1,
    marginTop: 67,
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  back: {
    zIndex: 2,
  },
  title: {
    color: Colors.main,
    fontSize: 24,
    fontWeight: '600',
    position: 'absolute',
    start: 0,
    textAlign: 'center',
    width: Platform.OS === 'web' ? 550 : Dimensions.get('window').width,
  },
  favoriteBtn: {
    alignContent: 'center',
    alignItems: 'center',
  },
  favoriteImage: {
    width: 24,
    height: 36,
  },
  favoriteTxt: {
    color: '#999',
    fontSize: 10,
    fontWeight: '600',
  },
  imageInfoTxt: {
    color: '#ff0000',
    fontSize: 10,
    fontWeight: '500',
    marginTop: 5,
  },
  logoImg: {
    width: 100,
    height: 100,
    borderRadius: 50,
    backgroundColor: 'black',
  },
  contentsContainer: {
    alignItems: 'center',
    alignContent: 'center',
    flexDirection: 'column',
    display: 'flex',
    justifyContent: 'center',
    flex: 1,
  },
  contentsInnerContainer: {
    alignItems: 'center',
    marginTop: -100,
    paddingHorizontal: Platform.OS === 'web' ? 10 : 51,
  },
  logoContainer: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  teamName: {
    color: 'black',
    fontWeight: 'bold',
    fontSize: 16,
    marginTop: 12,
    alignSelf: 'center',
  },
  item: {
    flex: 1,
    alignItems: 'center',
  },
  subtitle: {
    fontWeight: 'bold',
    fontSize: 14,
    lineHeight: 20,
  },
  value: {
    fontSize: 14,
    lineHeight: 20,
    marginTop: 5,
  },
  cancelBtn: {
    width: 100,
    height: 34,
    backgroundColor: '#ccc',
    borderRadius: 17,
    alignItems: 'center',
    alignContent: 'center',
    justifyContent: 'center',
    marginTop: 65,
    marginStart: 8,
  },
  cancelBtnTxt: {
    color: '#555',
  },
  btnText: {
    color: 'white',
    fontSize: 18,
  },
});

import React, {useEffect} from 'react';
import {Image, StyleSheet, Text, View} from 'react-native';
import styles from '../common/styles';

const LeagueMetaItem = props => {
  useEffect(() => {}, [props.data]);

  const getImageUrl = data => {
    if (data.imageThumbUrl) {
      return {uri: data.imageThumbUrl};
    } else if (data.imageUrl) {
      return {uri: data.imageUrl};
    }
    return require('../assets/images/nologo.png');
  };
  return (
    <View style={[pageStyle.itemContainer]}>
      <View
        style={[pageStyle.teamLogo, !props.data.imageUrl && {opacity: 0.4}]}>
        <Image
          style={[
            {
              width: 53,
              height: 53,
              borderRadius: 27,
              resizeMode: 'cover',
            },
          ]}
          source={getImageUrl(props.data)}
        />
      </View>
      <View style={[pageStyle.contentsContainer]}>
        <Text style={[pageStyle.teamName]}>{props.data.name}</Text>
        <Text style={[pageStyle.regionName]}>
          {props.data.regionSido} {props.data.regionSigungu}
        </Text>
      </View>
      {(props.data.videoServiceEnabled ||
        props.data.videoServiceEnabled === 1) && (
        <Image
          style={[pageStyle.playIcn, props.data.videoServiceEnabled && {}]}
          source={require('../assets/images/icn_play.png')}
        />
      )}
    </View>
  );
};

const pageStyle = StyleSheet.create({
  itemContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 10,
    backgroundColor: 'rgb(120, 137, 163)',
    borderRadius: 14,
    padding: 12,
    position: 'relative',
  },
  teamLogo: {
    width: 53,
    height: 53,
    overflow: 'hidden',
    borderRadius: 27,
    backgroundColor: 'white',
  },
  contentsContainer: {
    paddingStart: 13,
    paddingTop: 9,
  },
  teamName: {
    color: 'white',
    fontFamily: 'Pretendard-Bold',
    fontWeight: '700',
    fontSize: 14,
  },
  regionName: {
    color: 'white',
    fontFamily: 'Pretendard-SemiBold',
    fontWeight: '600',
    fontSize: 11,
    marginTop: 5,
  },
  teamCount: {
    color: 'white',
    fontFamily: 'Pretendard-SemiBold',
    fontWeight: '600',
    fontSize: 11,
  },
  playIcn: {
    position: 'absolute',
    right: 10,
    top: 10,
    width: 16,
    height: 21,
  },
});

export default LeagueMetaItem;

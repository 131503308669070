import React, {useState, useRef, useEffect} from 'react';
import {
  StyleSheet,
  View,
  Dimensions,
  Modal,
  Text,
  Image,
  TouchableOpacity,
  TextInput,
  Alert,
  DeviceEventEmitter,
  Platform,
} from 'react-native';
import styles from '../../common/styles';
import {useSafeAreaInsets} from 'react-native-safe-area-context';
import Colors from '../../common/colors';
import SelectDropdown from 'react-native-select-dropdown';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {launchImageLibrary} from 'react-native-image-picker';
import api from '../../common/api';
import alert from '../alert';

const TeamManagementPopup = props => {
  const insets = useSafeAreaInsets();

  const [topRegions, setTopRegions] = useState([]);
  const [secondRegions, setSecondRegions] = useState([]);

  const [name, setName] = useState(props.teamData.name);
  const [defaultParentRegionIndex, setDefaultParentRegionIndex] = useState();
  const [defaultRegionIndex, setDefaultRegionIndex] = useState();
  const [profileImageUri, setProfileImageUri] = useState(
    props.teamData.logoImageUrl,
  );
  const [profileImageData, setProfileImageData] = useState();
  const [data, setData] = useState(props.teamData);
  const [foundationDay, setFoundaionDay] = useState(
    props.teamData.foundationDay,
  );
  const selectedRegion = useRef({id: props.teamData.hometown.id});
  const [selectedInnings, setSelectedInnings] = useState(
    props.teamData.minimumInnings,
  );
  const [selectedAbs, setSelectedAbs] = useState(props.teamData.minimumAtBat);

  const abOptions = [0, 1, 2, 3, 4, 5];
  const inningOptions = [
    0.0, 0.1, 0.2, 1, 1.1, 1.2, 2, 2.1, 2.2, 3, 3.1, 3.2, 4, 4.1, 4.2, 5,
  ];

  let accessToken = useRef();
  useEffect(() => {
    AsyncStorage.getItem('@accessToken', (err, result) => {
      getData(result);
      accessToken.current = result;
    });
  }, []);

  const save = () => {
    if (!name) {
      alert('이름을 입력해 주세요.');
      return;
    }
    if (!selectedRegion.current) {
      console.log(selectedRegion.current);
      alert('팀 연고를 선택해 주세요.');
      return;
    }

    if (!foundationDay) {
      alert('창단일을 입력해 주세요.');
      return;
    }

    let body = {
      name: name,
      regionId: selectedRegion.current.id,
      foundationDay: foundationDay,
      minimumInnings: selectedInnings,
      minimumAtBat: selectedAbs,
    };

    if (profileImageData) {
      body.imageData = profileImageData;
    }

    api
      .post(`/teams/${data.id}/modification`, body, {
        headers: {
          Authorization: `Bearer ${accessToken.current}`,
        },
      })
      .then(function (response) {
        alert('요청에 성공했습니다.');
        DeviceEventEmitter.emit('teamTabInfoRefresh', {});
        props.closePopup();
        Platform.OS === 'web' ? history.back() : props.navigation.goBack();
      })
      .catch(function (error) {
        console.log(error.response);
        alert('요청에 실패했습니다.');
      });
  };

  const cancel = () => {
    props.closePopup();
  };

  const deleteItem = () => {
    alert(
      '삭제',
      '팀 마스터 이외의 모든 선수를 탈퇴 처리한 이후 팀 삭제가 가능합니다. 팀을 삭제하시겠습니까?',
      [
        {
          text: '예',
          onPress: () => {
            api
              .delete(`/teams/${data.id}`, {
                headers: {
                  Authorization: `Bearer ${accessToken.current}`,
                },
              })
              .then(function (response) {
                alert('삭제 처리 되었습니다.');
                props.closePopup();
                Platform.OS === 'web'
                  ? history.back()
                  : props.navigation.goBack();
                setTimeout(() => {
                  DeviceEventEmitter.emit('homeTabPress', {});
                  DeviceEventEmitter.emit('teamTabInfoRefresh', {});
                }, 1000);
              })
              .catch(function (error) {
                console.log(error.response);
                alert(error.response.data.message);
              });
          },
        },
        {
          text: '아니오',
        },
      ],
    );
  };

  const onDateChange = text => {
    let dateFormatted = '';
    let onlyNumber = text.replaceAll('-', '');
    if (onlyNumber.length > 6) {
      dateFormatted = `${onlyNumber.substring(0, 4)}-${onlyNumber.substring(
        4,
        6,
      )}-${onlyNumber.substring(6, 8)}`;
    } else if (onlyNumber.length > 4) {
      dateFormatted = `${onlyNumber.substring(0, 4)}-${onlyNumber.substring(
        4,
        6,
      )}`;
    } else {
      dateFormatted = onlyNumber.substring(0, 4);
    }
    setFoundaionDay(dateFormatted);
  };

  const moveToUserManagement = () => {
    props.navigation.navigate('TeamMemberList', {
      itemId: data?.id,
      teamData: data,
      myGrade: props.myGrade,
    });
    props.closePopup();
  };

  const getData = token => {
    api
      .get('/regions', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(function (response) {
        setTopRegions(response.data);
        getRegionData(props.teamData.hometown.parent.id);
        let index = 0;
        for (let i = 0; i < response.data.length; i++) {
          if (response.data[i].id === props.teamData.hometown.parent.id) {
            break;
          }
          index++;
        }
        setDefaultParentRegionIndex(index);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getRegionData = parentId => {
    api
      .get(`/regions?parentId=${parentId}`, {
        headers: {
          Authorization: `Bearer ${accessToken.current}`,
        },
      })
      .then(function (response) {
        setSecondRegions(response.data);
        let index = 0;
        for (let i = 0; i < response.data.length; i++) {
          if (response.data[i].id === props.teamData.hometown.id) {
            break;
          }
          index++;
        }
        setDefaultRegionIndex(index);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const openImageLibrary = () => {
    launchImageLibrary(
      {
        mediaType: 'photo',
        includeBase64: true,
        maxHeight: 500,
        maxWidth: 500,
      },
      response => {
        if (
          !response.errorCode &&
          response.assets &&
          response.assets.length > 0 &&
          response.assets[0] &&
          response.assets[0].base64
        ) {
          alert('프로필 이미지', '프로필 이미지를 변경하시겠습니까?', [
            {
              text: '예',
              onPress: () => {
                setProfileImageData(
                  'data:image/png;base64,' + response.assets[0].base64,
                );
              },
            },
            {
              text: '아니오',
            },
          ]);
        }
      },
    );
  };

  return (
    <Modal transparent={true} overFullScreen={true}>
      <View style={[pageStyle.container]}>
        <View style={[pageStyle.innerContainer, {marginTop: insets.top + 67}]}>
          <View style={[styles.headerCommon, pageStyle.header]}>
            <View style={[styles.row, styles.verticalAlign, pageStyle.back]}>
              <TouchableOpacity onPress={() => props.closePopup()}>
                <Image
                  source={require('../../assets/images/icon_back.png')}
                  style={[styles.headerBackButton]}
                />
              </TouchableOpacity>
            </View>
            <Text style={[pageStyle.title]}>팀 관리</Text>
            <TouchableOpacity
              style={[pageStyle.createTeamBtn]}
              onPress={() => moveToUserManagement(true)}>
              <Text style={[pageStyle.createTeamBtnTxt]}>회원관리</Text>
            </TouchableOpacity>
          </View>
          <View style={[pageStyle.contentsContainer]}>
            <View style={[pageStyle.contentsInnerContainer]}>
              <TouchableOpacity
                onPress={() => openImageLibrary()}
                style={[pageStyle.logoContainer]}>
                <Image
                  style={[pageStyle.logoImg]}
                  source={
                    !profileImageUri && !profileImageData
                      ? {}
                      : {
                          uri: profileImageData
                            ? profileImageData
                            : profileImageUri,
                        }
                  }
                  resizeMode={'cover'}
                />
                <Text style={[pageStyle.registerBtnText]}>팀 로고</Text>
              </TouchableOpacity>
              <View style={[pageStyle.inputContainer]}>
                <TextInput
                  placeholder={'팀명 입력'}
                  placeholderTextColor={'#aaa'}
                  style={[pageStyle.input]}
                  value={name}
                  onChangeText={value => setName(value)}
                />
                <Image
                  style={[pageStyle.pencilIcn]}
                  source={require('../../assets/images/icn_pencil.png')}
                />
              </View>
              <Text style={[pageStyle.homeTownTxt]}>팀 연고</Text>
              <View style={[pageStyle.dropdownRowContainer]}>
                <View style={[pageStyle.dropDownItemContainer]}>
                  <SelectDropdown
                    defaultButtonText="광역시/도"
                    defaultValueByIndex={defaultParentRegionIndex}
                    buttonStyle={pageStyle.dropDown}
                    buttonTextStyle={pageStyle.downDownText}
                    rowTextStyle={styles.dropDownRowText}
                    renderDropdownIcon={() => (
                      <Image
                        source={require('../../assets/images/select_arr.png')}
                      />
                    )}
                    data={topRegions}
                    onSelect={(selectedItem, index) => {
                      getRegionData(selectedItem.id);
                    }}
                    buttonTextAfterSelection={(selectedItem, index) => {
                      return selectedItem.sido;
                    }}
                    rowTextForSelection={(item, index) => {
                      return item.sido;
                    }}
                  />
                </View>
                <View style={[pageStyle.dropDownItemContainer]}>
                  <SelectDropdown
                    defaultValueByIndex={defaultRegionIndex}
                    defaultButtonText="시/군/구"
                    buttonStyle={pageStyle.dropDown}
                    buttonTextStyle={pageStyle.downDownText}
                    rowTextStyle={styles.dropDownRowText}
                    renderDropdownIcon={() => (
                      <Image
                        source={require('../../assets/images/select_arr.png')}
                      />
                    )}
                    data={secondRegions}
                    onSelect={(selectedItem, index) => {
                      selectedRegion.current = selectedItem;
                    }}
                    buttonTextAfterSelection={(selectedItem, index) => {
                      return selectedItem.sigungu;
                    }}
                    rowTextForSelection={(item, index) => {
                      return item.sigungu;
                    }}
                  />
                </View>
              </View>
              <Text style={[pageStyle.createDate]}>창단일</Text>
              <View style={[pageStyle.inputContainer]}>
                <TextInput
                  placeholder={'창단일 입력'}
                  placeholderTextColor={'#aaa'}
                  style={[pageStyle.input, styles.mt18]}
                  value={foundationDay}
                  onChangeText={onDateChange}
                />
                <Image
                  style={[pageStyle.pencilIcn]}
                  source={require('../../assets/images/icn_pencil.png')}
                />
              </View>
              <Text style={[pageStyle.homeTownTxt]}>기록 규정</Text>
              <View style={[pageStyle.dropdownRowContainer]}>
                <View style={[pageStyle.dropDownItemContainer]}>
                  <SelectDropdown
                    defaultButtonText="0타석"
                    defaultValue={props.teamData?.minimumAtBat}
                    buttonStyle={pageStyle.dropDown}
                    buttonTextStyle={pageStyle.downDownText}
                    rowTextStyle={styles.dropDownRowText}
                    renderDropdownIcon={() => (
                      <Image
                        source={require('../../assets/images/select_arr.png')}
                      />
                    )}
                    data={abOptions}
                    onSelect={(selectedItem, index) => {
                      setSelectedAbs(selectedItem);
                    }}
                    buttonTextAfterSelection={(selectedItem, index) => {
                      return `${selectedItem}타석`;
                    }}
                    rowTextForSelection={(item, index) => {
                      return `${item}타석`;
                    }}
                  />
                </View>
                <View style={[pageStyle.dropDownItemContainer]}>
                  <SelectDropdown
                    defaultButtonText="0.0이닝"
                    defaultValue={props.teamData?.minimumInnings}
                    buttonStyle={pageStyle.dropDown}
                    buttonTextStyle={pageStyle.downDownText}
                    rowTextStyle={styles.dropDownRowText}
                    renderDropdownIcon={() => (
                      <Image
                        source={require('../../assets/images/select_arr.png')}
                      />
                    )}
                    data={inningOptions}
                    onSelect={(selectedItem, index) => {
                      setSelectedInnings(selectedItem);
                    }}
                    buttonTextAfterSelection={(selectedItem, index) => {
                      return `${selectedItem}이닝`;
                    }}
                    rowTextForSelection={(item, index) => {
                      return `${item}이닝`;
                    }}
                  />
                </View>
              </View>
              <View style={[styles.row, Platform.OS === 'web' && {width: 300}]}>
                <TouchableOpacity
                  onPress={() => save()}
                  style={[pageStyle.saveBtn]}>
                  <Text style={[pageStyle.btnText]}>저장</Text>
                </TouchableOpacity>
                <TouchableOpacity
                  onPress={() => cancel()}
                  style={[pageStyle.cancelBtn]}>
                  <Text style={[pageStyle.btnText, pageStyle.cancelBtnTxt]}>
                    취소
                  </Text>
                </TouchableOpacity>
                {props.myGrade === '팀마스터' && (
                  <TouchableOpacity
                    onPress={() => deleteItem()}
                    style={[pageStyle.deleteBtn]}>
                    <Text style={[pageStyle.btnText, pageStyle.deleteBtnTxt]}>
                      삭제
                    </Text>
                  </TouchableOpacity>
                )}
              </View>
            </View>
          </View>
        </View>
      </View>
    </Modal>
  );
};

export default TeamManagementPopup;
const pageStyle = StyleSheet.create({
  container: {
    zIndex: 100,
    width: '100%',
    height: Dimensions.get('window').height,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
  },
  innerContainer: {
    width: Platform.OS === 'web' ? 500 : '100%',
    backgroundColor: 'white',
    flex: 1,
    marginTop: 67,
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  back: {
    zIndex: 2,
  },
  title: {
    color: Colors.main,
    fontSize: 24,
    fontWeight: '600',
    position: 'absolute',
    start: 0,
    textAlign: 'center',
    width: Platform.OS === 'web' ? 500 : '100%',
  },
  imageInfoTxt: {
    color: '#ff0000',
    fontSize: 10,
    fontWeight: '500',
    marginTop: 5,
  },
  logoImg: {
    width: 100,
    height: 100,
    borderRadius: 50,
    backgroundColor: 'black',
  },
  createTeamBtn: {
    width: 60,
    height: 22,
    borderRadius: 12,
    backgroundColor: Colors.main,
    justifyContent: 'center',
    alignItems: 'center',
    marginEnd: 14,
  },
  createTeamBtnTxt: {
    color: 'white',
    fontSize: 11,
  },
  contentsContainer: {
    alignItems: 'center',
    alignContent: 'center',
    flexDirection: 'column',
    display: 'flex',
    justifyContent: 'center',
    flex: 1,
  },
  contentsInnerContainer: {
    alignItems: 'center',
    paddingHorizontal: 51,
  },
  tainer: {
    width: 80,
    height: 80,
    backgroundColor: '#777',
    borderRadius: 40,
    alignItems: 'center',
  },
  plusIcon: {
    marginTop: 28,
    width: 21,
    height: 21,
  },
  registerBtnText: {
    color: 'white',
    fontSize: 10,
    marginTop: 6,
    fontWeight: '500',
  },
  inputContainer: {
    position: 'relative',
  },
  pencilIcn: {
    position: 'absolute',
    right: 0,
    bottom: 5,
  },
  input: {
    width: 132,
    borderBottomWidth: 1,
    borderBottomColor: 'black',
    textAlign: 'center',
    marginTop: 12,
    fontSize: 12,
    paddingBottom: 5,
  },
  homeTownTxt: {
    fontSize: 15,
    color: 'black',
    marginTop: 12,
  },
  createDate: {
    fontSize: 15,
    color: 'black',
    marginTop: 18,
  },
  saveBtn: {
    flex: 1,
    height: 34,
    backgroundColor: Colors.main,
    borderRadius: 17,
    alignItems: 'center',
    alignContent: 'center',
    justifyContent: 'center',
    marginTop: 35,
    marginEnd: 8,
  },
  cancelBtn: {
    flex: 1,
    height: 34,
    backgroundColor: '#ccc',
    borderRadius: 17,
    alignItems: 'center',
    alignContent: 'center',
    justifyContent: 'center',
    marginTop: 35,
    marginStart: 8,
  },
  cancelBtnTxt: {
    color: '#555',
  },
  deleteBtn: {
    flex: 1,
    height: 34,
    backgroundColor: 'red',
    borderRadius: 17,
    alignItems: 'center',
    alignContent: 'center',
    justifyContent: 'center',
    marginTop: 35,
    marginStart: 8,
    opacity: 0.7,
  },
  deleteBtnTxt: {
    color: 'white',
  },
  btnText: {
    color: 'white',
    fontSize: 18,
  },
  dropdownRowContainer: {
    display: 'flex',
    width: 222,
    marginTop: 6,
    flexDirection: 'row',
  },
  dropDownItemContainer: {
    marginHorizontal: 3,
    flex: 1,
  },
  dropDown: {
    width: '100%',
    backgroundColor: 'white',
    borderWidth: 1,
    borderColor: 'rgb(61, 80, 183)',
    height: 30,
  },
  downDownText: {
    color: 'black',
    fontSize: 11,
  },
  newTeamIcn: {
    width: 162,
    height: 162,
  },
  teamCreatedTxt: {
    color: '#3f51b5',
    fontSize: 21,
    fontWeight: '600',
    marginTop: 18,
  },
});

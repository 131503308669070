import {Alert, Platform} from 'react-native';

const alertPolyfill = (title, description, options, extra) => {
  if (!options) {
    window.alert(title);
    return;
  }
  const result = title
    ? description
      ? window.confirm(`[${title}] ${description}`)
      : window.confirm(title)
    : window.confirm(`${description}`);

  if (result) {
    const confirmOption = options.find(({style}) => style !== 'cancel');
    confirmOption && confirmOption.onPress();
  } else {
    const cancelOption = options.find(({style}) => style === 'cancel');
    cancelOption && cancelOption.onPress();
  }
};

const alert = Platform.OS === 'web' ? alertPolyfill : Alert.alert;

export default alert;

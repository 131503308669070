import {
  DeviceEventEmitter,
  Image,
  KeyboardAvoidingView,
  Platform,
  ScrollView,
  StyleSheet,
  Text,
  TextInput,
  View,
} from 'react-native';
import {Dimensions} from 'react-native';
import styles from '../common/styles';
import {SafeAreaView} from 'react-native-safe-area-context';
import {Shadow} from 'react-native-shadow-2';
import {TouchableOpacity} from 'react-native-gesture-handler';
import {useEffect, useRef, useState} from 'react';
import Colors from '../common/colors';
import SelectDropdown from 'react-native-select-dropdown';
import api from '../common/api';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {title} from '../common/util';

const PlayerInfoView = ({navigation}) => {
  const [userInfo, setUserInfo] = useState({});
  const [inputs, setInputs] = useState({
    pitchingAndHitting: userInfo.pitchingAndHitting,
    formerAthlete: userInfo.formerAthlete,
    position: userInfo.position,
    hideBirth: userInfo.hideBirth,
    hideContact: userInfo.hideContact,
    school: userInfo.school,
  });

  let accessToken = useRef();

  useEffect(() => {
    AsyncStorage.getItem('@accessToken', (err, result) => {
      accessToken.current = result;
      getUserInfo();
    });
  }, []);

  const getUserInfo = () => {
    if (!accessToken.current) {
      return;
    }
    api
      .get('/app-user/me', {
        headers: {
          Authorization: `Bearer ${accessToken.current}`,
        },
      })
      .then(function (response) {
        setUserInfo(response.data);
        setInputs({
          pitchingAndHitting: response.data.pitchingAndHitting,
          formerAthlete: response.data.formerAthlete,
          position: response.data.position,
          hideBirth: response.data.hideBirth ? '비공개' : '공개',
          hideContact: response.data.hideContact ? '비공개' : '공개',
          school: response.data.school,
        });
      })
      .catch(function (error) {
        console.error(error.response);
        if (error.response.status === 401) {
          alert('탈퇴처리된 계정입니다.');
          AsyncStorage.removeItem('@accessToken', () => {
            navigation.reset({
              index: 0,
              routes: [{name: 'Login'}],
            });
          });
        }
      });
  };

  const validateInputs = () => {
    if (
      !inputs.pitchingAndHitting ||
      !inputs.formerAthlete ||
      !inputs.position
    ) {
      alert('필수 값을 입력해 주세요.');
      return false;
    }

    return true;
  };

  const confirm = () => {
    if (!validateInputs()) {
      return;
    }
    const body = JSON.parse(JSON.stringify(inputs));
    if (!body.authType) {
      body.authType = '이메일';
    }
    body.hideBirth = body.hideBirth === '비공개';
    body.hideContact = body.hideContact === '비공개';

    api
      .post('/users/me', body, {
        headers: {
          Authorization: `Bearer ${accessToken.current}`,
        },
      })
      .then(function (response) {
        DeviceEventEmitter.emit('userInfoRefresh', {});
        alert('수정이 완료되었습니다.');
      })
      .catch(function (error) {
        console.error(error.response, error);
        alert('요청에 실패했습니다.');
      });
  };
  return (
    <SafeAreaView nativeID="common-page-container" style={[styles.container]}>
      <Shadow
        offset={[0, 2]}
        style={[{width: '100%'}]}
        containerStyle={[{marginTop: 0}]}
        distance={7}
        sides={{bottom: true, top: false, start: false, end: false}}>
        <View style={[styles.headerCommon]}>
          <View style={[styles.row, styles.verticalAlign]}>
            <TouchableOpacity
              onPress={() =>
                Platform.OS === 'web' ? history.back() : navigation.goBack()
              }>
              <Image
                source={require('../assets/images/icon_back.png')}
                style={[styles.headerBackButton]}
              />
            </TouchableOpacity>
            <Text style={[styles.headerText]}>선수 정보 관리</Text>
          </View>
          <View style={[styles.row, styles.verticalAlign]} />
        </View>
      </Shadow>
      <View style={[pageStyle.container]}>
        <KeyboardAvoidingView
          keyboardVerticalOffset={Platform.OS === 'ios' ? 0 : 30}
          behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
          style={{flexGrow: 1}}>
          <ScrollView showsVerticalScrollIndicator={false}>
            <View style={[{paddingBottom: 80}]}>
              <Text style={[pageStyle.subtitle]}>선출여부</Text>
              <View style={[pageStyle.dropDownContainer]}>
                <SelectDropdown
                  defaultValue={inputs.formerAthlete}
                  defaultButtonText="선택"
                  buttonStyle={styles.dropDown}
                  buttonTextStyle={styles.downDownText}
                  rowTextStyle={styles.dropDownRowText}
                  renderDropdownIcon={() => (
                    <Image
                      source={require('../assets/images/select_arr.png')}
                    />
                  )}
                  data={['비선출', '중출', '고출이상']}
                  onSelect={(selectedItem, index) => {
                    setInputs({
                      ...inputs,
                      ['formerAthlete']: selectedItem,
                    });
                  }}
                  buttonTextAfterSelection={(selectedItem, index) => {
                    return selectedItem;
                  }}
                  rowTextForSelection={(item, index) => {
                    return item;
                  }}
                />
              </View>
              <Text style={[pageStyle.subtitle]}>투타</Text>
              <View style={[pageStyle.dropDownContainer]}>
                <SelectDropdown
                  defaultValue={inputs.pitchingAndHitting}
                  defaultButtonText="선택"
                  buttonStyle={styles.dropDown}
                  buttonTextStyle={styles.downDownText}
                  rowTextStyle={styles.dropDownRowText}
                  renderDropdownIcon={() => (
                    <Image
                      source={require('../assets/images/select_arr.png')}
                    />
                  )}
                  data={[
                    '우투우타',
                    '우투좌타',
                    '우투양타',
                    '좌투우타',
                    '좌투좌타',
                    '좌투양타',
                  ]}
                  onSelect={(selectedItem, index) => {
                    setInputs({
                      ...inputs,
                      ['pitchingAndHitting']: selectedItem,
                    });
                  }}
                  buttonTextAfterSelection={(selectedItem, index) => {
                    return selectedItem;
                  }}
                  rowTextForSelection={(item, index) => {
                    return item;
                  }}
                />
              </View>
              <Text style={[pageStyle.subtitle]}>선호 포지션</Text>
              <View style={[pageStyle.dropDownContainer]}>
                <SelectDropdown
                  defaultValue={inputs.position}
                  defaultButtonText="선택"
                  buttonStyle={styles.dropDown}
                  buttonTextStyle={styles.downDownText}
                  rowTextStyle={styles.dropDownRowText}
                  renderDropdownIcon={() => (
                    <Image
                      source={require('../assets/images/select_arr.png')}
                    />
                  )}
                  data={[
                    '투수',
                    '포수',
                    '1루수',
                    '2루수',
                    '유격수',
                    '3루수',
                    '좌익수',
                    '중견수',
                    '우익수',
                  ]}
                  onSelect={(selectedItem, index) => {
                    setInputs({
                      ...inputs,
                      ['position']: selectedItem,
                    });
                  }}
                  buttonTextAfterSelection={(selectedItem, index) => {
                    return selectedItem;
                  }}
                  rowTextForSelection={(item, index) => {
                    return item;
                  }}
                />
              </View>
              <Text style={[pageStyle.subtitle]}>생년월일 공개여부</Text>
              <View style={[pageStyle.dropDownContainer]}>
                <SelectDropdown
                  defaultValue={inputs.hideBirth}
                  defaultButtonText="선택"
                  buttonStyle={styles.dropDown}
                  buttonTextStyle={styles.downDownText}
                  rowTextStyle={styles.dropDownRowText}
                  renderDropdownIcon={() => (
                    <Image
                      source={require('../assets/images/select_arr.png')}
                    />
                  )}
                  data={['공개', '비공개']}
                  onSelect={(selectedItem, index) => {
                    setInputs({
                      ...inputs,
                      ['hideBirth']: selectedItem,
                    });
                  }}
                  buttonTextAfterSelection={(selectedItem, index) => {
                    return selectedItem;
                  }}
                  rowTextForSelection={(item, index) => {
                    return item;
                  }}
                />
              </View>
              <Text style={[pageStyle.subtitle]}>출신학교</Text>
              <View style={[pageStyle.dropDownContainer]}>
                <TextInput
                  style={[styles.dropDown, {paddingHorizontal: 12}]}
                  type="text"
                  placeholder="출신학교"
                  placeholderTextColor={'#aaa'}
                  value={inputs.school}
                  onChangeText={value =>
                    setInputs({
                      ...inputs,
                      ['school']: value,
                    })
                  }
                />
              </View>
              <Text style={[pageStyle.subtitle]}>연락처 공개여부</Text>
              <View style={[pageStyle.dropDownContainer]}>
                <SelectDropdown
                  defaultValue={inputs.hideContact}
                  defaultButtonText="선택"
                  buttonStyle={styles.dropDown}
                  buttonTextStyle={styles.downDownText}
                  rowTextStyle={styles.dropDownRowText}
                  renderDropdownIcon={() => (
                    <Image
                      source={require('../assets/images/select_arr.png')}
                    />
                  )}
                  data={['공개', '비공개']}
                  onSelect={(selectedItem, index) => {
                    setInputs({
                      ...inputs,
                      ['hideContact']: selectedItem,
                    });
                  }}
                  buttonTextAfterSelection={(selectedItem, index) => {
                    return selectedItem;
                  }}
                  rowTextForSelection={(item, index) => {
                    return item;
                  }}
                />
              </View>
              <TouchableOpacity style={[pageStyle.button]} onPress={confirm}>
                <Text style={[pageStyle.buttonTxt]}>확인</Text>
              </TouchableOpacity>
            </View>
          </ScrollView>
        </KeyboardAvoidingView>
      </View>
    </SafeAreaView>
  );
};

const pageStyle = StyleSheet.create({
  headerCommon: {},
  headerText: {color: 'white'},
  container: {
    paddingVertical: 24,
    paddingHorizontal: 32,
  },
  row: {
    width: '100%',
    flexDirection: 'row',
    alignContent: 'center',
    alignItems: 'center',
    paddingVertical: 12,
    paddingHorizontal: 36,
    justifyContent: 'space-between',
  },
  subtitle: {
    fontFamily: 'Pretendard-Bold',
    fontSize: 16,
    color: 'black',
    fontWeight: 'bold',
  },
  dropDownContainer: {
    marginTop: 12,
    marginBottom: 24,
  },
  button: {
    marginTop: 42,
    height: 50,
    borderRadius: 25,
    backgroundColor: Colors.main,
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonTxt: {
    color: 'white',
    fontFamily: 'Pretendard',
    fontSize: 18,
  },
});

export default PlayerInfoView;

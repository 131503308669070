import React, {useEffect, useState, useRef} from 'react';
import {
  Button,
  Image,
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  TextInput,
  Linking,
  ImageBackground,
  FlatList,
  Dimensions,
  SafeAreaView,
  Modal,
} from 'react-native';
import styles from '../common/styles';
import api from '../common/api';
import AsyncStorage from '@react-native-async-storage/async-storage';
import '@expo/match-media';
import {useMediaQuery} from 'react-responsive';
import YoutubePlayer from 'react-native-youtube-iframe';

const WebMainView = ({navigation, route}) => {
  const isTabletOrMobileDevice = useMediaQuery({
    maxDeviceWidth: 830,
  });

  useEffect(() => {
    console.log('isTabletOrMobileDevice', isTabletOrMobileDevice);
  }, [isTabletOrMobileDevice]);

  const scrollRef = useRef();
  const seeMore = () => {
    document
      .getElementById('main-step3')
      .scrollIntoView({behavior: 'smooth'}, true);
  };

  const handleGoToService = () => {
    AsyncStorage.getItem('@accessToken', (err, result) => {
      if (result) {
        navigation.reset({
          index: 0,
          routes: [{name: 'Main'}],
        });
      } else {
        navigation.reset({
          index: 0,
          routes: [{name: 'Login'}],
        });
      }
    });
  };

  const [name, setName] = useState('');
  const [leagueName, setLeagueName] = useState('');
  const [contact, setContact] = useState('');
  const [contents, setContents] = useState('');

  const youtubeRef = useRef();

  const handleSubmit = () => {
    if (!name || !leagueName || !contact || !contents) {
      alert('필수 값을 입력해 주세요');
      return;
    }

    api
      .post('/leagues/request', {
        name: name,
        leagueName: leagueName,
        contact: contact,
        contents: contents,
      })
      .then(function (response) {
        setName('');
        setLeagueName('');
        setContact('');
        setContents('');
        alert('가맹 문의가 등록되었습니다.');
      })
      .catch(function (error) {
        console.log(error.response);
        alert('요청에 실패했습니다.');
      })
      .finally(() => {});
  };

  const handleClickLeague = index => {
    let linkUrl = null;
    switch (index) {
      case 1:
        linkUrl = 'https://www.youtube.com/@user-lz1mh9nc7l';
        break;
      case 2:
        linkUrl = null;
        break;
      case 3:
        linkUrl = 'https://www.youtube.com/@mcbaseball2020';
        break;
      case 4:
        linkUrl = 'https://www.youtube.com/channel/UCFfJX_M0UeOvC9QaJ3hBdxg';
        break;
      case 5:
        linkUrl = null;
        break;
      case 6:
        linkUrl = null;
        break;
      case 7:
        linkUrl = null;
        break;
      case 8:
        linkUrl = null;
        break;
      case 9:
        linkUrl = 'https://www.youtube.com/channel/UCGmblcAVrqbiZUR8k2NuQEQ';
        break;
      case 10:
        linkUrl = 'https://www.youtube.com/channel/UCi2jfpKH_29_jo2Ucoti22w';
        break;
      case 11:
        linkUrl = 'https://www.youtube.com/channel/UCE-lTamg7OAzzR2-VwIOK4g';
        break;
      case 12:
        linkUrl = 'https://www.youtube.com/channel/UCSL43esaGcAoMciXrawPoeg';
        break;
      case 13:
        linkUrl = 'https://www.youtube.com/channel/UC6X_1RYNBN17bueRNURKmDg';
        break;
      case 14:
        linkUrl = 'https://www.youtube.com/channel/UCvhHgvZK9uQ4YSkEx7mlv4Q';
        break;
      case 15:
        linkUrl = null;
        break;
      case 16:
        linkUrl = null;
        break;
    }
    if (!linkUrl) {
      return;
    }
    Linking.openURL(linkUrl);
  };

  const [youtubeVideoId, setYoutubeVideoId] = useState('');

  return (
    <SafeAreaView>
      <FlatList
        nativeID="web-container"
        style={[pageStyle.list]}
        ref={scrollRef}
        horizontal={false}
        vertical={true}
        onScrollToIndexFailed={() => {
          console.log('failed');
        }}
        initialNumToRender={9}
        data={[0, 1, 2, 3, 4, 5, 6, 7, 8]}
        renderItem={({item, index}) => (
          <>
            {index === 0 && (
              <View style={[pageStyle.step1]}>
                <ImageBackground
                  resizeMode="cover"
                  style={[pageStyle.step1Bg]}
                  source={require('../assets/images/pc_img_8.png')}>
                  <View style={{width: '100%'}}>
                    <View
                      style={[
                        pageStyle.step1TopBg,
                        isTabletOrMobileDevice && pageStyle.step1TopBgMobile,
                      ]}>
                      <Image
                        style={[pageStyle.logo]}
                        source={require('../assets/images/logo.png')}
                      />
                      <View
                        style={[
                          styles.row,
                          pageStyle.step1BtnContainer,
                          isTabletOrMobileDevice &&
                            pageStyle.step1BtnContainerMobile,
                        ]}>
                        <TouchableOpacity
                          onPress={() => navigation.navigate('WebApply')}
                          style={[
                            pageStyle.btn2,
                            isTabletOrMobileDevice && pageStyle.btnMobile,
                          ]}>
                          <Text
                            style={[
                              pageStyle.btnTxt2,
                              isTabletOrMobileDevice && pageStyle.btnTxtMobile,
                            ]}>
                            영상신청
                          </Text>
                        </TouchableOpacity>
                        <TouchableOpacity
                          onPress={handleGoToService}
                          style={[
                            pageStyle.btn,
                            isTabletOrMobileDevice && pageStyle.btnMobile,
                          ]}>
                          <Text
                            style={[
                              pageStyle.btnTxt,
                              isTabletOrMobileDevice && pageStyle.btnTxtMobile,
                            ]}>
                            기록서비스 (회원용)
                          </Text>
                        </TouchableOpacity>
                        <TouchableOpacity
                          onPress={() =>
                            Linking.openURL('https://manage.unique-play.com')
                          }
                          style={[
                            pageStyle.btn2,
                            isTabletOrMobileDevice && pageStyle.btnMobile,
                          ]}>
                          <Text
                            style={[
                              pageStyle.btnTxt2,
                              isTabletOrMobileDevice && pageStyle.btnTxtMobile,
                            ]}>
                            리그 관리자
                          </Text>
                        </TouchableOpacity>
                      </View>
                    </View>
                    <Image
                      resizeMode="contain"
                      style={[
                        pageStyle.step1Txt,
                        isTabletOrMobileDevice && pageStyle.step1TxtMobile,
                      ]}
                      source={require('../assets/images/pc_txt_1.png')}
                    />
                    {/* {isTabletOrMobileDevice && (
                    <View>
                      <Text style={[pageStyle.step1Txt1Mobile]}>
                        차별화된 영상 서비스
                      </Text>
                      <Text style={[pageStyle.step1Txt2Mobile]}>
                        리그 선수들의 불만,{'\n'}
                        비디오 판독 요구,{'\n'}
                        제자리 걸음인 리그 규모{'\n'}
                        ‘영상 서비스’로 해결하세요
                      </Text>
                    </View>
                  )} */}
                    <TouchableOpacity
                      onPress={seeMore}
                      style={[pageStyle.step1Btn]}>
                      <Text style={[pageStyle.step1BtnTxt]}>더 알아보기</Text>
                    </TouchableOpacity>
                  </View>
                </ImageBackground>
              </View>
            )}
            {index === 1 && (
              <View style={[pageStyle.step2, styles.row]}>
                <View
                  style={[
                    pageStyle.step2Item,
                    isTabletOrMobileDevice && pageStyle.step2ItemMobile,
                  ]}>
                  <Text
                    style={[
                      pageStyle.step2Title,
                      isTabletOrMobileDevice && pageStyle.step2TitleMobile,
                    ]}>
                    영상조회수
                  </Text>
                  <Text
                    style={[
                      pageStyle.step2Value,
                      isTabletOrMobileDevice && pageStyle.step2ValueMobile,
                    ]}>
                    1,401,754회
                  </Text>
                </View>
                <View
                  style={[
                    pageStyle.step2Item,
                    isTabletOrMobileDevice && pageStyle.step2ItemMobile,
                  ]}>
                  <Text
                    style={[
                      pageStyle.step2Title,
                      isTabletOrMobileDevice && pageStyle.step2TitleMobile,
                    ]}>
                    신규팀 가입
                  </Text>
                  <Text
                    style={[
                      pageStyle.step2Value,
                      isTabletOrMobileDevice && pageStyle.step2ValueMobile,
                    ]}>
                    160%로 증가
                  </Text>
                </View>
                <View
                  style={[
                    pageStyle.step2Item,
                    isTabletOrMobileDevice && pageStyle.step2ItemMobile,
                  ]}>
                  <Text
                    style={[
                      pageStyle.step2Title,
                      isTabletOrMobileDevice && pageStyle.step2TitleMobile,
                    ]}>
                    설치비
                  </Text>
                  <Text
                    style={[
                      pageStyle.step2Value,
                      isTabletOrMobileDevice && pageStyle.step2ValueMobile,
                    ]}>
                    0원
                  </Text>
                </View>
              </View>
            )}
            {index === 2 && (
              <View style={[pageStyle.step3]}>
                <Text style={[pageStyle.step3Title]}>
                  유니크 플레이 서비스 소개
                </Text>
                <View
                  style={[
                    pageStyle.step3IntroContainer,
                    styles.row,
                    isTabletOrMobileDevice &&
                      pageStyle.step3IntroContainerMobile,
                  ]}>
                  <View style={[pageStyle.step3Item]}>
                    <View style={[pageStyle.step3ImgContainer]}>
                      <Image
                        style={[pageStyle.step3Img1]}
                        source={require('../assets/images/pc_img_7.png')}
                      />
                    </View>
                    <Text style={[pageStyle.step3SubTitle]}>
                      프로야구급 고화질 영상
                    </Text>
                    <Text style={[pageStyle.step3Value]}>
                      FHD(1080p)급 고화질 영상과{'\n'} 스포츠 영상에 필수적임
                      끊김없는
                      {'\n'}
                      60프레임 영상으로 리그의 수준을{'\n'} 한단계 업그레이드
                      하실 수 있습니다!
                    </Text>
                  </View>
                  <View style={[pageStyle.step3Item]}>
                    <View style={[pageStyle.step3ImgContainer]}>
                      <Image
                        style={[pageStyle.step3Img2]}
                        source={require('../assets/images/pc_img_5.png')}
                      />
                    </View>
                    <Text style={[pageStyle.step3SubTitle]}>
                      리그 유튜브 채널개설
                    </Text>
                    <Text style={[pageStyle.step3Value]}>
                      리그 선수들은 편리하게 영상을{'\n'} 시청하고 리그홍보에
                      활용 할 수 있는{'\n'} 리그 전용 유튜브 채널을 개설해{'\n'}{' '}
                      영상을 자동으로 업로드 해 드립니다!
                    </Text>
                  </View>
                  <View style={[pageStyle.step3Item]}>
                    <View style={[pageStyle.step3ImgContainer]}>
                      <Image
                        style={[pageStyle.step3Img3]}
                        source={require('../assets/images/pc_img_6.png')}
                      />
                    </View>
                    <Text style={[pageStyle.step3SubTitle]}>기록 플랫폼</Text>
                    <Text style={[pageStyle.step3Value]}>
                      현장의 목소리를 반영한{'\n'} 사용자 친화적 기록 시스템을
                      {'\n'} 제공합니다!
                    </Text>
                  </View>
                </View>
                <View style={[pageStyle.infoImgContainer]}>
                  <Image
                    resizeMode="contain"
                    style={[
                      pageStyle.step3Img4,
                      isTabletOrMobileDevice && pageStyle.step3Img4Mobile,
                    ]}
                    source={require('../assets/images/pc_img_4.png')}
                  />
                </View>
              </View>
            )}
            {index === 3 && (
              <View style={[pageStyle.step4]} nativeID="main-step3">
                <Text style={[pageStyle.step4Title]}>서비스 영상</Text>
                <View
                  style={[
                    styles.row,
                    pageStyle.step4ValueContainer,
                    isTabletOrMobileDevice &&
                      pageStyle.step4ValueContainerMobile,
                  ]}>
                  <TouchableOpacity
                    onPress={() => setYoutubeVideoId('lRAnoYb1vxA')}>
                    <ImageBackground
                      resizeMode="cover"
                      imageStyle={{
                        resizeMode: 'cover',
                        alignSelf: 'center',
                      }}
                      source={require('../assets/images/pc_img_3.png')}
                      style={[
                        pageStyle.step4ItemContainer,
                        isTabletOrMobileDevice &&
                          pageStyle.step4ItemContainerMobile,
                      ]}>
                      <View style={[pageStyle.step4ItemInnerContainer]}>
                        <Image
                          source={require('../assets/images/pc_step4_1.png')}
                          style={[pageStyle.step4TxtImg]}
                        />
                      </View>
                    </ImageBackground>
                  </TouchableOpacity>
                  <TouchableOpacity
                    onPress={() => setYoutubeVideoId('ynIh-AKthHc')}>
                    <ImageBackground
                      resizeMode="cover"
                      source={require('../assets/images/pc_img_2.png')}
                      style={[
                        pageStyle.step4ItemContainer,
                        isTabletOrMobileDevice &&
                          pageStyle.step4ItemContainerMobile,
                      ]}>
                      <View style={[pageStyle.step4ItemInnerContainer]}>
                        <Image
                          source={require('../assets/images/pc_step4_2.png')}
                          style={[pageStyle.step4TxtImg]}
                        />
                      </View>
                    </ImageBackground>
                  </TouchableOpacity>
                  <TouchableOpacity
                    onPress={() => setYoutubeVideoId('QPizgFKXMRI')}>
                    <ImageBackground
                      resizeMode="cover"
                      source={require('../assets/images/pc_img_1.png')}
                      style={[
                        pageStyle.step4ItemContainer,
                        isTabletOrMobileDevice &&
                          pageStyle.step4ItemContainerMobile,
                      ]}>
                      <View style={[pageStyle.step4ItemInnerContainer]}>
                        <Image
                          source={require('../assets/images/pc_step4_3.png')}
                          style={[pageStyle.step4TxtImg]}
                        />
                      </View>
                    </ImageBackground>
                  </TouchableOpacity>
                </View>
              </View>
            )}
            {index === 4 && (
              <View style={[pageStyle.step5]}>
                <Image
                  style={[
                    pageStyle.step5Img,
                    isTabletOrMobileDevice && pageStyle.step5ImgMobile,
                  ]}
                  source={require('../assets/images/step5_txt.png')}
                />
              </View>
            )}
            {index === 5 && (
              <View style={[pageStyle.step6]}>
                <Text style={[pageStyle.step6Title]}>파트너</Text>
                <View
                  style={[
                    pageStyle.step6OuterContainer,
                    isTabletOrMobileDevice &&
                      pageStyle.step6OuterContainerMobile,
                  ]}>
                  <View
                    style={[
                      pageStyle.step6InnerContainer,
                      isTabletOrMobileDevice &&
                        pageStyle.step6InnerContainerMobile,
                    ]}>
                    <View style={[styles.row]}>
                      <TouchableOpacity
                        onPress={() => handleClickLeague(1)}
                        style={[
                          pageStyle.step6Item,
                          isTabletOrMobileDevice && pageStyle.step6ItemMobile,
                        ]}>
                        <View style={[pageStyle.step6ImgContainer]}>
                          <Image
                            resizeMode="cover"
                            style={[pageStyle.leagueLogoImg]}
                            source={require('../assets/images/league_logo_3.png')}
                          />
                        </View>
                        <Text
                          style={[
                            pageStyle.leagueNameTxt,
                            isTabletOrMobileDevice &&
                              pageStyle.leagueNameTxtMobile,
                          ]}>
                          수원베이스볼리그
                        </Text>
                      </TouchableOpacity>
                      <TouchableOpacity
                        onPress={() => handleClickLeague(2)}
                        style={[
                          pageStyle.step6Item,
                          isTabletOrMobileDevice && pageStyle.step6ItemMobile,
                        ]}>
                        <View style={[pageStyle.step6ImgContainer]}>
                          <Image
                            style={[pageStyle.leagueLogoImg2]}
                            source={require('../assets/images/league_logo_9.png')}
                          />
                        </View>
                        <Text
                          style={[
                            pageStyle.leagueNameTxt,
                            isTabletOrMobileDevice &&
                              pageStyle.leagueNameTxtMobile,
                          ]}>
                          SC고양리그
                        </Text>
                      </TouchableOpacity>
                      <TouchableOpacity
                        onPress={() => handleClickLeague(3)}
                        style={[
                          pageStyle.step6Item,
                          isTabletOrMobileDevice && pageStyle.step6ItemMobile,
                        ]}>
                        <View style={[pageStyle.step6ImgContainer]}>
                          <Image
                            style={[pageStyle.leagueLogoImg3]}
                            source={require('../assets/images/league_logo_4.png')}
                          />
                        </View>
                        <Text
                          style={[
                            pageStyle.leagueNameTxt,
                            isTabletOrMobileDevice &&
                              pageStyle.leagueNameTxtMobile,
                          ]}>
                          MC베이스볼리그
                        </Text>
                      </TouchableOpacity>
                      <TouchableOpacity
                        onPress={() => handleClickLeague(4)}
                        style={[
                          pageStyle.step6Item,
                          isTabletOrMobileDevice && pageStyle.step6ItemMobile,
                        ]}>
                        <View style={[pageStyle.step6ImgContainer]}>
                          <Image
                            style={[pageStyle.leagueLogoImg4]}
                            source={require('../assets/images/league_logo_12.png')}
                          />
                        </View>
                        <Text
                          style={[
                            pageStyle.leagueNameTxt,
                            isTabletOrMobileDevice &&
                              pageStyle.leagueNameTxtMobile,
                          ]}>
                          남양주JS리그
                        </Text>
                      </TouchableOpacity>
                    </View>
                    <View style={[styles.row]}>
                      <TouchableOpacity
                        onPress={() => handleClickLeague(5)}
                        style={[
                          pageStyle.step6Item,
                          isTabletOrMobileDevice && pageStyle.step6ItemMobile,
                        ]}>
                        <View style={[pageStyle.step6ImgContainer]}>
                          <Image
                            style={[pageStyle.leagueLogoImg5]}
                            source={require('../assets/images/league_logo_14.png')}
                          />
                        </View>
                        <Text
                          style={[
                            pageStyle.leagueNameTxt,
                            isTabletOrMobileDevice &&
                              pageStyle.leagueNameTxtMobile,
                          ]}>
                          안산 한양대리그
                        </Text>
                      </TouchableOpacity>
                      <TouchableOpacity
                        onPress={() => handleClickLeague(6)}
                        style={[
                          pageStyle.step6Item,
                          isTabletOrMobileDevice && pageStyle.step6ItemMobile,
                        ]}>
                        <View style={[pageStyle.step6ImgContainer]}>
                          <Image
                            style={[pageStyle.leagueLogoImg6]}
                            source={require('../assets/images/league_logo_22.png')}
                          />
                        </View>
                        <Text
                          style={[
                            pageStyle.leagueNameTxt,
                            isTabletOrMobileDevice &&
                              pageStyle.leagueNameTxtMobile,
                          ]}>
                          IPA볼파크리그
                        </Text>
                      </TouchableOpacity>
                      <TouchableOpacity
                        onPress={() => handleClickLeague(7)}
                        style={[
                          pageStyle.step6Item,
                          isTabletOrMobileDevice && pageStyle.step6ItemMobile,
                        ]}>
                        <View style={[pageStyle.step6ImgContainer]}>
                          <Image
                            style={[pageStyle.leagueLogoImg7]}
                            source={require('../assets/images/league_logo_15.png')}
                          />
                        </View>
                        <Text
                          style={[
                            pageStyle.leagueNameTxt,
                            isTabletOrMobileDevice &&
                              pageStyle.leagueNameTxtMobile,
                          ]}>
                          아라베이스볼
                        </Text>
                      </TouchableOpacity>
                      <TouchableOpacity
                        onPress={() => handleClickLeague(8)}
                        style={[
                          pageStyle.step6Item,
                          isTabletOrMobileDevice && pageStyle.step6ItemMobile,
                        ]}>
                        <View style={[pageStyle.step6ImgContainer]}>
                          <Image
                            style={[pageStyle.leagueLogoImg8]}
                            source={require('../assets/images/league_logo_19.png')}
                          />
                        </View>
                        <Text
                          style={[
                            pageStyle.leagueNameTxt,
                            isTabletOrMobileDevice &&
                              pageStyle.leagueNameTxtMobile,
                          ]}>
                          화성리그
                        </Text>
                      </TouchableOpacity>
                    </View>
                    <View style={[styles.row]}>
                      <TouchableOpacity
                        onPress={() => handleClickLeague(9)}
                        style={[
                          pageStyle.step6Item,
                          isTabletOrMobileDevice && pageStyle.step6ItemMobile,
                        ]}>
                        <View style={[pageStyle.step6ImgContainer]}>
                          <Image
                            style={[pageStyle.leagueLogoImg9]}
                            source={require('../assets/images/league_logo_5.png')}
                          />
                        </View>
                        <Text
                          style={[
                            pageStyle.leagueNameTxt,
                            isTabletOrMobileDevice &&
                              pageStyle.leagueNameTxtMobile,
                          ]}>
                          파워리그
                        </Text>
                      </TouchableOpacity>
                      <TouchableOpacity
                        onPress={() => handleClickLeague(10)}
                        style={[
                          pageStyle.step6Item,
                          isTabletOrMobileDevice && pageStyle.step6ItemMobile,
                        ]}>
                        <View style={[pageStyle.step6ImgContainer]}>
                          <Image
                            style={[pageStyle.leagueLogoImg10]}
                            source={require('../assets/images/league_logo_6.png')}
                          />
                        </View>
                        <Text
                          style={[
                            pageStyle.leagueNameTxt,
                            isTabletOrMobileDevice &&
                              pageStyle.leagueNameTxtMobile,
                          ]}>
                          경희빅리그
                        </Text>
                      </TouchableOpacity>
                      <TouchableOpacity
                        onPress={() => handleClickLeague(11)}
                        style={[
                          pageStyle.step6Item,
                          isTabletOrMobileDevice && pageStyle.step6ItemMobile,
                        ]}>
                        <View style={[pageStyle.step6ImgContainer]}>
                          <Image
                            style={[pageStyle.leagueLogoImg11]}
                            source={require('../assets/images/league_logo_10.png')}
                          />
                        </View>
                        <Text
                          style={[
                            pageStyle.leagueNameTxt,
                            isTabletOrMobileDevice &&
                              pageStyle.leagueNameTxtMobile,
                          ]}>
                          우수별내리그
                        </Text>
                      </TouchableOpacity>
                      <TouchableOpacity
                        onPress={() => handleClickLeague(12)}
                        style={[
                          pageStyle.step6Item,
                          isTabletOrMobileDevice && pageStyle.step6ItemMobile,
                        ]}>
                        <View style={[pageStyle.step6ImgContainer]}>
                          <Image
                            style={[pageStyle.leagueLogoImg12]}
                            source={require('../assets/images/league_logo_18.png')}
                          />
                        </View>
                        <Text
                          style={[
                            pageStyle.leagueNameTxt,
                            isTabletOrMobileDevice &&
                              pageStyle.leagueNameTxtMobile,
                          ]}>
                          유신워너비리그
                        </Text>
                      </TouchableOpacity>
                    </View>
                    <View style={[styles.row]}>
                      <TouchableOpacity
                        onPress={() => handleClickLeague(13)}
                        style={[
                          pageStyle.step6Item,
                          isTabletOrMobileDevice && pageStyle.step6ItemMobile,
                        ]}>
                        <View style={[pageStyle.step6ImgContainer]}>
                          <Image
                            style={[pageStyle.leagueLogoImg13]}
                            source={require('../assets/images/league_logo_16.png')}
                          />
                        </View>
                        <Text
                          style={[
                            pageStyle.leagueNameTxt,
                            isTabletOrMobileDevice &&
                              pageStyle.leagueNameTxtMobile,
                          ]}>
                          금융단리그
                        </Text>
                      </TouchableOpacity>
                      <TouchableOpacity
                        onPress={() => handleClickLeague(14)}
                        style={[
                          pageStyle.step6Item,
                          isTabletOrMobileDevice && pageStyle.step6ItemMobile,
                        ]}>
                        <View style={[pageStyle.step6ImgContainer]}>
                          <Image
                            style={[pageStyle.leagueLogoImg14]}
                            source={require('../assets/images/league_logo_nbl.png')}
                          />
                        </View>
                        <Text
                          style={[
                            pageStyle.leagueNameTxt,
                            isTabletOrMobileDevice &&
                              pageStyle.leagueNameTxtMobile,
                          ]}>
                          나노시티베이스볼리그
                        </Text>
                      </TouchableOpacity>
                      <TouchableOpacity
                        onPress={() => handleClickLeague(15)}
                        style={[
                          pageStyle.step6Item,
                          isTabletOrMobileDevice && pageStyle.step6ItemMobile,
                        ]}>
                        <View style={[pageStyle.step6ImgContainer]}>
                          <Image
                            style={[pageStyle.leagueLogoImg15]}
                            source={require('../assets/images/league_logo_ydp.png')}
                          />
                        </View>
                        <Text
                          style={[
                            pageStyle.leagueNameTxt,
                            isTabletOrMobileDevice &&
                              pageStyle.leagueNameTxtMobile,
                          ]}>
                          영등포리그
                        </Text>
                      </TouchableOpacity>
                      <TouchableOpacity
                        onPress={() => handleClickLeague(16)}
                        style={[
                          pageStyle.step6Item,
                          isTabletOrMobileDevice && pageStyle.step6ItemMobile,
                        ]}>
                        <View style={[pageStyle.step6ImgContainer]}>
                          <Image
                            style={[pageStyle.leagueLogoImg16]}
                            source={require('../assets/images/league_logo_231.png')}
                          />
                        </View>
                        <Text
                          style={[
                            pageStyle.leagueNameTxt,
                            isTabletOrMobileDevice &&
                              pageStyle.leagueNameTxtMobile,
                          ]}>
                          239-1리그
                        </Text>
                      </TouchableOpacity>
                    </View>
                  </View>
                </View>
              </View>
            )}
            {index === 6 && (
              <View style={[pageStyle.step7]}>
                <Text style={[pageStyle.step7Title]}>가맹문의</Text>
                <View style={[pageStyle.step7OuterContainer]}>
                  <View
                    style={[
                      pageStyle.step7InnerContainer,
                      isTabletOrMobileDevice &&
                        pageStyle.step7InnerContainerMobile,
                    ]}>
                    <Text style={[pageStyle.step7SubTitle]}>이름</Text>
                    <TextInput
                      value={name}
                      onChangeText={value => setName(value)}
                      style={[pageStyle.step7Input]}
                      placeholder="000"
                      placeholderTextColor={'rgba(255, 255, 255, 0.2)'}
                    />
                    <Text style={[pageStyle.step7SubTitle]}>리그이름</Text>
                    <TextInput
                      value={leagueName}
                      onChangeText={value => setLeagueName(value)}
                      placeholderTextColor={'rgba(255, 255, 255, 0.2)'}
                      style={[pageStyle.step7Input]}
                      placeholder="0000리그"
                    />
                    <Text style={[pageStyle.step7SubTitle]}>연락처</Text>
                    <TextInput
                      value={contact}
                      onChangeText={value => setContact(value)}
                      placeholderTextColor={'rgba(255, 255, 255, 0.2)'}
                      style={[pageStyle.step7Input]}
                      placeholder="010-0000-0000"
                    />
                    <Text style={[pageStyle.step7SubTitle]}>문의사항</Text>
                    <TextInput
                      value={contents}
                      onChangeText={value => setContents(value)}
                      placeholderTextColor={'rgba(255, 255, 255, 0.2)'}
                      style={[pageStyle.step7InputMulti]}
                      multiline={true}
                      placeholder=""
                    />
                    <TouchableOpacity
                      onPress={handleSubmit}
                      style={[pageStyle.step7Btn]}>
                      <Text style={[pageStyle.step7BtnTxt]}>보내기</Text>
                    </TouchableOpacity>
                  </View>
                </View>
              </View>
            )}
            {index === 7 && (
              <View style={[pageStyle.step8]}>
                <Text style={[pageStyle.step8Title]}>문의전화</Text>
                <Text style={[pageStyle.step8Value]}>070-4739-5678</Text>
              </View>
            )}
            {index === 8 && (
              <View style={[pageStyle.footer]}>
                <View style={[styles.row]}>
                  <TouchableOpacity
                    onPress={() => navigation.navigate('Terms')}>
                    <Text style={[pageStyle.termTxt]}>이용약관</Text>
                  </TouchableOpacity>
                  <Text style={[pageStyle.termTxt]}>|</Text>
                  <TouchableOpacity
                    onPress={() => navigation.navigate('Privacy')}>
                    <Text style={[pageStyle.termTxt]}>개인정보처리방침</Text>
                  </TouchableOpacity>
                </View>
                <View style={[styles.row]}>
                  <TouchableOpacity
                    onPress={() =>
                      Linking.openURL(
                        'https://www.instagram.com/uniqueplay_official',
                      )
                    }
                    style={[pageStyle.snsItem]}>
                    <Image
                      style={[pageStyle.snsImg]}
                      source={require('../assets/images/icn_circle_instagram.png')}
                    />
                  </TouchableOpacity>
                  <TouchableOpacity
                    onPress={() =>
                      Linking.openURL(
                        'https://www.youtube.com/channel/UCFh7NmzRNK3mtD6-oc4NwnQ',
                      )
                    }
                    style={[pageStyle.snsItem]}>
                    <Image
                      style={[pageStyle.snsImg]}
                      source={require('../assets/images/icn_circle_youtube.png')}
                    />
                  </TouchableOpacity>
                  <TouchableOpacity
                    onPress={() =>
                      Linking.openURL('http://pf.kakao.com/_usYyT')
                    }
                    style={[pageStyle.snsItem]}>
                    <Image
                      style={[pageStyle.snsImg]}
                      source={require('../assets/images/icn_circle_kakao.png')}
                    />
                  </TouchableOpacity>
                </View>
                <View>
                  <Text style={[pageStyle.footerTxt]}>
                    2023 UniquePlay. All rights reserved. {'\n\n'}
                    유니크플레이{'\n'}
                    서울특별시 종로구 새문안로3길 36 용비어천가 1336호{'\n'}
                    대표 : 최성일 | 개인정보관리책임자 : 최성일{'\n'}
                    사업자등록번호 : 611-88-02887{'\n'}
                    통신판매업신고 : 2023-서울종로-0192호{'\n'}
                    문의메일 : support@unique-play.com{'\n'}
                    문의전화 : 070-4739-5678
                  </Text>
                </View>
              </View>
            )}
          </>
        )}
      />

      {youtubeVideoId && (
        <Modal transparent={true} overFullScreen={true}>
          <TouchableOpacity
            onPress={() => setYoutubeVideoId(null)}
            style={[pageStyle.popupContainer]}>
            <YoutubePlayer
              apiKey="AIzaSyCWW2izAo6FdSIozEmuZUMzFeHB4SftJuM"
              showinfo={false}
              controls={1}
              ref={youtubeRef}
              videoId={youtubeVideoId} // The YouTube video ID
              play={true} // control playback of video with true/false
              fullscreen // control whether the video should play in fullscreen or inline
              height={!isTabletOrMobileDevice ? 529 : 400}
              width={
                !isTabletOrMobileDevice ? 940 : Dimensions.get('window').width
              }
              style={{
                alignSelf: 'stretch',
                width: !isTabletOrMobileDevice
                  ? 940
                  : Dimensions.get('window').width,
                height: !isTabletOrMobileDevice ? 529 : 400,
              }}
            />
          </TouchableOpacity>
        </Modal>
      )}
    </SafeAreaView>
  );
};

const pageStyle = StyleSheet.create({
  list: {
    flex: 1,
  },
  innerContainer: {
    alignContent: 'center',
    alignItems: 'center',
    backgroundColor: 'white',
  },
  step1: {
    width: '100%',
  },
  step1Bg: {
    width: '100%',
    height: 600,
    alignContent: 'center',
    alignItems: 'center',
  },
  step1TopBg: {
    marginTop: 24,
    paddingVertical: 18,
    width: '100%',
    backgroundColor: 'rgba(24, 72, 120, 0.6)',
  },
  step1TopBgMobile: {
    marginTop: 0,
  },
  logo: {
    width: 100,
    height: 75,
    alignSelf: 'center',
  },
  step1Txt: {
    width: 738,
    height: 188,
    alignSelf: 'center',
    marginTop: 60,
  },
  step1TxtMobile: {
    width: '90%',
    alignSelf: 'center',
  },
  step1Txt1Mobile: {
    color: 'white',
  },
  step1Txt2Mobile: {},
  step1BtnContainer: {
    alignSelf: 'center',
    marginTop: 24,
  },
  step1BtnContainerMobile: {
    width: '100%',
  },
  btn: {
    width: 160,
    backgroundColor: 'white',
    marginHorizontal: 36,
    borderRadius: 15,
    paddingVertical: 5,
  },
  btn2: {
    width: 160,
    backgroundColor: '#1076bc',
    marginHorizontal: 36,
    borderRadius: 15,
    paddingVertical: 5,
  },
  btnMobile: {
    width: 'inherit',
    flex: 1,
    marginHorizontal: 6,
    borderRadius: 20,
    height: 40,
  },
  btnTxt: {
    color: '#184878',
    fontSize: 18,
    fontWeight: '600',
    textAlign: 'center',
    letterSpacing: -1.2,
  },
  btnTxt2: {
    color: 'white',
    fontSize: 18,
    fontWeight: '600',
    textAlign: 'center',
    letterSpacing: -1.2,
  },
  btnTxtMobile: {
    lineHeight: 31,
    fontSize: 16,
  },
  step1Btn: {
    width: 160,
    backgroundColor: '#1076bc',
    alignSelf: 'center',
    marginTop: 24,
    paddingVertical: 9,
    paddingHorizontal: 14,
  },
  step1BtnTxt: {
    textAlign: 'center',
    color: 'white',
    fontSize: 24,
  },
  step2: {
    width: '100%',
    backgroundColor: '#184878',
    paddingVertical: 18,
    justifyContent: 'center',
  },
  step2Item: {
    width: 210,
    alignSelf: 'center',
    marginHorizontal: 48,
  },
  step2ItemMobile: {
    flex: 1,
    marginHorizontal: 6,
  },
  step2Title: {
    textAlign: 'center',
    fontSize: 30,
    color: 'white',
    fontWeight: '600',
  },
  step2TitleMobile: {
    fontSize: 24,
  },
  step2Value: {
    textAlign: 'center',
    fontSize: 30,
    color: '#fbed20',
    fontWeight: '600',
    marginTop: 3,
  },
  step2ValueMobile: {
    fontSize: 22,
  },
  step3: {
    width: '100%',
    backgroundColor: 'white',
    paddingTop: 200,
    paddingBottom: 80,
  },
  step3Title: {
    color: '#1d3864',
    fontSize: 34,
    fontWeight: '700',
    alignSelf: 'center',
  },
  step3IntroContainer: {
    width: 600,
    maxWidth: 600,
    flexDirection: 'row',
    justifyContent: 'center',
    alignSelf: 'center',
    marginTop: 40,
    alignItems: 'center',
  },
  step3IntroContainerMobile: {
    flexDirection: 'column',
  },
  step3Item: {
    alignContent: 'center',
    alignItems: 'center',
    marginHorizontal: 36,
    marginBottom: 30,
    width: 260,
  },
  step3ItemMobile: {},
  step3Img1: {
    width: 146,
    height: 111,
  },
  step3Img2: {
    width: 121,
    height: 85,
  },
  step3Img3: {
    width: 134,
    height: 134,
  },
  step3ImgContainer: {
    height: 170,
    justifyContent: 'center',
  },
  step3SubTitle: {
    color: '#1d3864',
    fontSize: 25,
    fontWeight: '600',
    alignSelf: 'center',
    textAlign: 'center',
    marginTop: 12,
  },
  step3Value: {
    fontWeight: '300',
    fontSize: 16,
    lineHeight: 22,
    color: 'black',
    alignSelf: 'center',
    textAlign: 'center',
    marginTop: 12,
  },
  infoImgContainer: {},
  step3Img4: {
    width: 831,
    height: 1050,
    alignSelf: 'center',
    marginTop: 100,
  },
  step3Img4Mobile: {
    alignSelf: 'center',
    width: '90%',
    height: (Dimensions.get('window').width / 3) * 4,
    marginHorizontal: 20,
  },
  step4: {
    marginTop: 0,
    width: '100%',
    backgroundColor: 'white',
    marginBottom: 120,
  },
  step4Title: {
    color: '#1d3864',
    fontSize: 34,
    fontWeight: '700',
    alignSelf: 'center',
  },
  step4ValueContainer: {
    marginTop: 36,
    marginBottom: 62,
    justifyContent: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
  },
  step4ValueContainerMobile: {
    flexDirection: 'column',
  },
  step4ItemContainer: {
    width: 400,
    height: 499,
  },
  step4ItemContainerMobile: {
    width: '100%',
    height: 300,
  },
  step4ItemInnerContainer: {
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  step4TxtImg: {
    width: 138,
    height: 87,
  },
  step5: {backgroundColor: '#184878', width: '100%', paddingVertical: 30},
  step5Img: {
    width: 370,
    height: 131,
    alignSelf: 'center',
  },
  step5ImgMobile: {
    width: 200,
    height: 70,
  },
  step6: {
    width: '100%',
    paddingTop: 100,
  },
  step6Title: {
    color: '#1d3864',
    fontSize: 34,
    fontWeight: '700',
    alignSelf: 'center',
  },
  step6OuterContainer: {
    marginTop: 40,
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
    paddingBottom: 40,
  },
  step6OuterContainerMobile: {
    width: '100%',
  },
  step6InnerContainer: {
    alignSelf: 'center',
    paddingVertical: 40,
  },
  step6InnerContainerMobile: {
    width: '100%',
  },
  step6Item: {
    marginTop: 36,
    width: 180,
    alignContent: 'center',
    alignItems: 'center',
  },
  step6ItemMobile: {
    width: 'inherit',
    flex: 1,
  },
  step6ImgContainer: {
    height: 90,
    justifyContent: 'center',
  },
  step6ImgContainerMobile: {},
  leagueLogoImg: {
    width: 74,
    height: 81,
  },
  leagueLogoImg2: {
    width: 85,
    height: 85,
  },
  leagueLogoImg3: {
    width: 111,
    height: 83,
  },
  leagueLogoImg4: {
    width: 72,
    height: 72,
  },
  leagueLogoImg5: {
    width: 71,
    height: 71,
  },
  leagueLogoImg6: {
    width: 97,
    height: 72,
  },
  leagueLogoImg7: {
    width: 118,
    height: 79,
  },
  leagueLogoImg8: {
    width: 71,
    height: 71,
  },
  leagueLogoImg9: {
    width: 88,
    height: 88,
  },
  leagueLogoImg10: {
    width: 87,
    height: 87,
  },
  leagueLogoImg11: {
    width: 79,
    height: 79,
  },
  leagueLogoImg12: {
    width: 79,
    height: 79,
  },
  leagueLogoImg13: {
    width: 71,
    height: 71,
  },
  leagueLogoImg14: {
    width: 109,
    height: 51,
  },
  leagueLogoImg15: {
    width: 72,
    height: 72,
  },
  leagueLogoImg16: {
    width: 72,
    height: 72,
  },
  leagueNameTxt: {
    color: 'black',
    fontSize: 18,
    marginTop: 4,
    textAlign: 'center',
  },
  leagueNameTxtMobile: {
    fontSize: 16,
  },
  step7: {
    width: '100%',
    paddingTop: 200,
  },
  step7Title: {
    color: '#1d3864',
    fontSize: 34,
    fontWeight: '700',
    alignSelf: 'center',
  },
  step7OuterContainer: {
    width: '100%',
    backgroundColor: '#184878',
    marginTop: 36,
    paddingBottom: 60,
  },
  step7InnerContainer: {
    width: 760,
    marginTop: 36,
    alignSelf: 'center',
  },
  step7InnerContainerMobile: {
    width: 350,
  },
  step7SubTitle: {
    color: 'white',
    fontSize: 20,
    textAlign: 'center',
    marginTop: 20,
  },
  step7Input: {
    borderBlockColor: 'white',
    borderBottomWidth: 1,
    backgroundColor: 'transparent',
    marginTop: 12,
    fontSize: 20,
    textAlign: 'center',
    paddingVertical: 4,
    color: 'white',
  },
  step7InputMulti: {
    backgroundColor: 'rgba(255, 255, 255, 0.2)',
    marginTop: 12,
    height: 120,
    padding: 8,
    fontSize: 20,
    color: 'white',
  },
  step7Btn: {
    width: '100%',
    backgroundColor: 'white',
    paddingVertical: 8,
    marginTop: 50,
  },
  step7BtnTxt: {
    fontSize: 20,
    color: 'black',
    textAlign: 'center',
  },
  step8: {
    width: '100%',
    paddingVertical: 80,
  },
  step8Title: {
    color: '#1d3864',
    fontSize: 42,
    fontWeight: 'bold',
    opacity: 0.67,
    textAlign: 'center',
  },
  step8Value: {
    color: '#1d3864',
    fontSize: 42,
    fontWeight: 'bold',
    textAlign: 'center',
    marginTop: 12,
  },
  footer: {
    paddingTop: 30,
    paddingBottom: 48,
    alignContent: 'center',
    alignItems: 'center',
    width: '100%',
    backgroundColor: '#18253b',
  },
  termTxt: {
    fontSize: 20,
    color: 'white',
    fontWeight: '600',
    textAlign: 'center',
    marginHorizontal: 8,
  },
  snsItem: {
    marginHorizontal: 8,
    marginTop: 12,
  },
  snsImg: {
    width: 54,
    height: 57,
  },
  footerTxt: {
    color: '#a0a0a0',
    fontSize: 14,
    textAlign: 'center',
    lineHeight: 20,
    marginTop: 18,
  },
  popupContainer: {
    zIndex: 100,
    width: '100%',
    height: Dimensions.get('window').height,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    alignItems: 'center',
    alignContent: 'center',
    justifyContent: 'center',
  },
});

export default WebMainView;
